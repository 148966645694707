import { EditOutlined, WarningOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { AppContext } from 'App.context';
import Colors from 'constants/Colors';
import React, { useContext, useState } from 'react';
import styles from './EditableInput.module.css';

export default function EditableInput({
  item,
  hint,
  value,
  onValueChanged,
  hasMissingTranslations,
}) {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const [focus, setFocus] = useState();

  const showEditIcon = !focus && !value;
  const showLiteralsWarning = hasMissingTranslations;

  const warningPopover = <div>{translate('guestTable.translationMissing')}</div>;

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div style={{ flex: 1 }}>
        <div>
          <input
            style={{
              flex: 1,
              borderBottom: value || focus ? `1px solid ${Colors.layoutContainerBg}` : null,
            }}
            value={value}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            className={styles.inputText}
            required
            type="text"
            onChange={e =>
              onValueChanged({
                id: item?.id,
                fieldHotelId: item?.fieldHotelId,
                customText: e.target.value,
                customTranslationId: item?.customTranslationId,
                field: item?.field,
              })
            }
          />
          <div className={styles.floatingLabel}>
            {hint}
            {showEditIcon && (
              <EditOutlined
                style={{
                  color: Colors.grayText,
                  marginLeft: 6,
                  padding: 2,
                  backgroundColor: Colors.grayBackground,
                  border: `1px solid ${Colors.lightGray}`,
                  borderRadius: 2,
                  fontSize: 10,
                }}
              />
            )}
          </div>
        </div>
      </div>
      {showLiteralsWarning && (
        <Popover content={warningPopover} trigger="hover">
          <WarningOutlined
            style={{
              color: Colors.yellowButton,
              marginLeft: 6,
              padding: 4,
              border: `1px solid ${Colors.yellowButton}`,
              borderRadius: 2,
              fontSize: 14,
            }}
          />
        </Popover>
      )}
    </div>
  );
}
