export default {
  credentialsContainer: {
    display: 'flex',
    gap: 16,
    marginTop: 16,
    marginBottom: 16,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'right',
    flex: 1,
    marginTop: 16,
    gap: 16,
  },
};
