import React, { useContext } from 'react';
import { AppContext } from '../../../../App.context';
import WelcomeLayout from '../WelcomeLayout/WelcomeLayout';
import WelcomeInput from '../WelcomeLayout/WelcomeInput/WelcomeInput';
import { MailOutlined } from '@ant-design/icons';
import Space from '../../Space/Space';
import WelcomeButton from '../WelcomeLayout/WelcomeButton/WelcomeButton';
import useRecoverPasswordHook from '../RecoverPassword/RecoverPassword.hook';
import Loading from 'Components/common/Loader/Loading';

export default function RecoverPassword() {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useRecoverPasswordHook();

  return (
    <WelcomeLayout title={translate('recover.header')}>
      <WelcomeInput
        icon={<MailOutlined />}
        value={state.email}
        placeholder={translate('login.emailPlaceholder')}
        onChange={value => {
          actions.setEmail(value);
        }}
      />
      <Space height={32} />
      {state.isLoading && <Loading />}
      <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
        <WelcomeButton
          text={translate('register.goBack')}
          onClick={() => actions.goBack()}
          secondary={true}
        />
        <WelcomeButton
          style={{ flex: 1 }}
          text={translate('recover.send')}
          onClick={() => actions.validateEmailAndRecover()}
        />
      </div>
      <Space height={16} />
    </WelcomeLayout>
  );
}
