import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../App.context';
import { capitalize } from '../../../utils/TextUtils';

const useSaveButtonHook = enableAllHotels => {
  const {
    appState,
    appActions: { translate, getSelectedHotel },
  } = useContext(AppContext);

  const [state, setState] = useState({
    applyToAllHotels: false,
    currentlySelectedHotel: null,
    selectedHotelLabel: null,
    showPopConfirm: false,
  });

  useEffect(() => {
    if (appState.hotelList) {
      const copy = JSON.parse(JSON.stringify(appState.hotelList));
      updateState({ hotels: parseHotelList(copy) });
    }
  }, [, appState.hotelList]);

  useEffect(() => {
    if (getSelectedHotel()) {
      updateState({ currentlySelectedHotel: getSelectedHotel() });
    }
  }, [appState.selectedHotel]);

  const actions = {
    getDefaultValue: () => {
      if (getSelectedHotel()) {
        return capitalize(getSelectedHotel()?.value);
      }
      if (enableAllHotels) return translate('hotelSelector.allHotels');
      if (appState?.hotelList?.length) {
        return capitalize(appState?.hotelList[0]?.name);
      }

      return null;
    },

    setApplyToCurrentHotel: () => {
      updateState({ applyToAllHotels: false });
    },

    setApplyToAllHotels: () => {
      updateState({ applyToAllHotels: true });
      // if (apply) {
      //   onHotelSelected(null);
      // } else {
      //   if (state.currentlySelectedHotel) {
      //     onHotelSelected(state.currentlySelectedHotel);
      //   } else {
      //     const firstHotel = appActions.getFirstHotel();
      //     if (firstHotel) {
      //       onHotelSelected(firstHotel);
      //       console.log('Set first hotel to ', firstHotel);
      //       actions.setCurrentlySelectedHotel(firstHotel);
      //     }
      //   }
      // }
    },

    setCurrentlySelectedHotel: id => {
      updateState({ currentlySelectedHotel: id });
    },

    getSelectedHotelLabel: () => {
      if (!state.currentlySelectedHotel) {
        if (appState.hotelList && appState.hotelList.length > 0) {
          const hotel = appState?.hotelList[0];
          if (hotel.name) {
            return capitalize(hotel.name);
          } else {
            return '';
          }
        } else {
          return '';
        }
      }
      if (state.currentlySelectedHotel) {
        return state.currentlySelectedHotel?.value;
      }
    },

    setShowPopConfirm: show => {
      updateState({ showPopConfirm: show });
    },
  };

  function parseHotelList(list) {
    const newList = list;
    if (newList && enableAllHotels) {
      newList.unshift({ id: null, name: translate('hotelSelector.allHotels') });
    }

    return newList;
  }

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
};

export default useSaveButtonHook;
