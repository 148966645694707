import axios from 'axios';
import apiConfig from '../api.config';

export default function pmsConfigHotelRequest({ hotelId, pmsId, pmsName, code, mode }) {
  const {
    host,
    URIs: { pmsConfigHotel },
  } = apiConfig;

  const uri = `${host}${pmsConfigHotel}/${hotelId}`;

  return axios
    .put(uri, JSON.stringify({ pmsName, pmsId, code, mode }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
