import { slotTempId } from 'Sell/CrossSell/utils';
import { getInitialState } from './utils';

export const actionTypes = {
  BASIC_UPDATE: 'BASIC_UPDATE',
  UPDATE_TEXTS: 'UPDATE_TEXTS',
  CREATE_SLOT: 'CREATE_SLOT',
  LOAD_SLOT: 'LOAD_SLOT',
  UPDATE_SLOT_DAY: 'UPDATE_SLOT_DAY',
  DELETE_SLOT: 'DELETE_SLOT',
  ADD_SLOT_DAY: 'ADD_SLOT_DAY',
  DELETE_SLOT_DAY: 'DELETE_SLOT_DAY',
  UPDATE_SLOT_TEXTS: 'UPDATE_SLOT_TEXTS',
  REORDER_IMAGE: 'REORDER_IMAGE',
};

export const itemReducer = (state, action) => {
  const daysKey = action.payload?.isReturn ? 'returnDays' : 'days';

  switch (action.type) {
    case actionTypes.BASIC_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.UPDATE_TEXTS:
      return {
        ...state,
        texts: {
          ...state.texts,
          [action.payload.language]: {
            ...state.texts[action.payload.language],
            [action.payload.type]: action.payload.value,
          },
        },
      };
    case actionTypes.REORDER_IMAGE:
      return {
        ...state,
        itemGalleries: action.payload.map((item, index) => {
          return { ...item, order: index + 1 };
        }),
      };
    case actionTypes.UPDATE_SLOT_DAY:
      return {
        ...state,
        slot: {
          ...state.slot,
          [daysKey]: state.slot[daysKey].map(day =>
            action.payload.id === day.id ? { ...day, ...action.payload } : day
          ),
        },
      };
    case actionTypes.ADD_SLOT_DAY:      
      return {
        ...state,
        slot: {
          ...state.slot,
          [daysKey]: [
            ...state.slot[daysKey],
            {
              ...action.payload,
              id: `${action.payload.name}-${state.slot[daysKey].length + 1}`,
            },
          ],
        },
      }; 
    case actionTypes.UPDATE_SLOT_TEXTS:
      return {
        ...state,
        slot: {
          ...state.slot,
          texts: {
            ...state.slot.texts,
            [action.payload.language]: {
              ...state.slot.texts[action.payload.language],
              [action.payload.type]: action.payload.value,
            },
          },
        },
      };
    case actionTypes.CREATE_SLOT:
      if (state.slot.id) {
        return {
          ...state,
          slots: state.slots.map(slot => (slot.id === state.slot.id ? state.slot : slot)),
          slot: getInitialState().slot,
        };
      }

      return {
        ...state,
        slots: [...state.slots, { ...state.slot, id: `${slotTempId}${state.slots.length + 1}` }],
        slot: getInitialState().slot,
      };
    case actionTypes.LOAD_SLOT:
      return {
        ...state,
        slot: action.payload,
      };
    case actionTypes.DELETE_SLOT:
      return {
        ...state,
        slots: state.slots.filter(slot => slot.id !== action.payload.id),
        slot: state.slot.id === action.payload.id ? getInitialState().slot : state.slot,
      };
    default:
      return state;
  }
};
