export default {
  termsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 6,
  },

  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
  },
};
