import axios from 'axios';
import apiConfig from '../api.config';

export default function crossSellSendEmailToGuestRequest({ hotelSlug, pmsId, schedule, options }) {
  const {
    guestlinkHost,
    URIs: { crossSellSendEmail },
  } = apiConfig;
  // workaround: Guestlink does not support htts rigth now on PROD
  const uri = `https://cross-sell-api.civitfun.com/${crossSellSendEmail}`;

  return axios
    .post(uri, JSON.stringify({ hotelSlug, pmsId, schedule, options }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
