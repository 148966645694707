import Colors from 'constants/Colors';

export default {
  splitGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    padding: '0px',
    margin: '0px',
    boxSizing: 'border-box',
    gap: 8,
  },
  borderStyle: {
    border: `1px solid ${Colors.lightGray}`,
    borderRadius: 8,
  },
  subField: {
    fontSize: 12,
    color: Colors.grayText,
  },
  tooltipIcon: {
    color: 'rgba(0,0,0,.45)',
  },
};
