import Colors from 'constants/Colors';

export default {
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 48,
    paddingBottom: 48,
  },
  button: {
    width: '22%',
    backgroundColor: Colors.blueIntense,
    color: Colors.white,
  },
  category: {
    fontWeight: 'bold',
    marginTop: 8,
  },
  title: {
    fontSize: '24px',
    marginTop: '20px',
    fontWeight: 'bold',
    color: '#333',
  },
  subtitle: {
    marginBottom: '20px',
    color: '#666',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: '20px',
  },
  leftSection: {
    flexBasis: '285px', // Establece el ancho base
    flexShrink: 1, // Permite que el div se haga más pequeño si es necesario
    minWidth: '200px',
  },
  subtitleBooking: {
    marginBottom: '8px',
    fontSize: '14px',
    color: '#333',
  },
  inputBox: {
    width: '100%',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
  },
  spinContainer: {
    position: 'absolute',
    top: '400px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
  },
  buttonTestPayment: {
    width: '65%',
    backgroundColor: Colors.blueColor,
    color: Colors.white,
    padding: '5px 10px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '20px',
  },
  urlContainer: {
    paddingLeft: '150px',
    paddingRight: '50px',
  },
};
