import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppContext } from '../App.context';

export default function PrivateRoute({ children, ...rest }) {
  const { appState } = useContext(AppContext);
  return (
    <Route
      {...rest}
      element={children}
      render={() => (appState.accessToken ? children : <Redirect to="/login" />)}
    />
  );
  // return <Route {...rest} render={() => children} />;
}
