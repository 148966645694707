import React, { useContext } from 'react';
import styles from './MailLanguageSelector.styles.js';
import { Select } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { AppContext } from 'App.context.js';
import AppSelector from 'Components/common/AppSelector/AppSelector.jsx';

const { Option } = Select;

export default function MailLanguageSelector({
  languages,
  selectedLanguage,
  onLanguageSelected,
  isLoading,
  applyToAllLanguages,
  setApplyToAllLanguages,
}) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <div style={{ flex: 1, width: '100%' }}>
      <div style={styles.title}>{translate('mailTemplates.language')}</div>
      <div style={styles.container}>
        <div style={{ flex: 1 }}>
          <AppSelector
            value={selectedLanguage}
            onOptionSelected={lang => onLanguageSelected(lang)}
            isLoading={isLoading}
            menu={getOptions()}
          />
        </div>
        <Checkbox value={applyToAllLanguages} onChange={setApplyToAllLanguages}>
          <div style={styles.desc}>{translate('mailTemplates.autofillLanguages')}</div>
        </Checkbox>
      </div>
    </div>
  );

  function getOptions() {
    return languages?.map(lang => {
      return (
        <Option value={lang.code}>
          <div style={styles.option}>
            <img
              style={{ height: 20 }}
              src={require(`../../../../../assets/images/icons/language/${lang.code}.png`)}
            />
            <div>{`${lang.label}`}</div>
          </div>
        </Option>
      );
    });
  }
}
