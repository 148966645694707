export default {
  priceType: {
    width: 104,
    display: 'flex',
    justifyContent: 'center',
    padding: '13px 16px',
    borderRadius: 8,
    background: 'var(--light-greyscale-bg-darken, #F8F8F8)',
  },
  image: {
    width: 96,
    height: 89,
  },
};
