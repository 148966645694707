export default {
  container: {
    display: 'flex',
    gap: 8,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  text: {
    marginRight: 8,
    fontWeight: 'bold',
    color: '#3d3d3d',
  },
  selector: {
    backgroundColor: 'white',
    minWidth: 248,
    borderRadius: 6,
    // boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
  },
  autocomplete: {
    width: '100%',
  },
};
