import React, { useEffect } from 'react';
import useTemplateEditorHook from './TemplateEditor.hook.js';
import JoditEditor from 'jodit-react';
import TagInserter from '../../TagInserter/TagInserter.jsx';
import styles from './TemplateEditor.styles';

function TemplateEditor({ value, disabled, onValueChange, tagValues }) {
  const { actions } = useTemplateEditorHook({ tagValues });

  return (
    <div style={styles.container}>
      <div style={{ marginBottom: 16 }}>
        <TagInserter onInsertClicked={actions.insertTag} hideTitle scope={'email_template'} />
      </div>
      <JoditEditor
        ref={ref => actions.setTextAreaRef(ref)}
        editorRef={ref => actions.setEditorRef(ref)}
        value={value}
        config={actions.getEditorConfig(disabled)}
        onChange={v => onValueChange('value', v)}
      />
    </div>
  );
}

export default React.memo(
  TemplateEditor,
  (oldProps, newProps) => oldProps.value === newProps.value
);
