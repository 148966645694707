import Colors from '../../../../../constants/Colors';

export default {
  container: {
    flex: 1,
    flexDirection: 'column',
    marginBottom: 8,
  },
  title: {
    fontSize: 14,
    color: Colors.grayText,
    textTransform: 'capitalize',
  },
  inputContainer: {
    border: `1px solid ${Colors.lightGray}`,
    borderRadius: 6,
    padding: 4,
    color: Colors.darkText,
  },
};
