import { useQuery, useMutation, QueryCache } from 'react-query';
import {
  createItem,
  deleteResource,
  findAll,
  findById,
  findHotelConfig,
  uploadImages,
  upsert,
} from 'Sell/CrossSell/API/api.config';

const queryCache = new QueryCache({});

export const useItemQuery = (filters, selectedHotel) => {
  const defaultFilters = {};

  return useQuery(['items', JSON.stringify(filters), selectedHotel], () =>
    findAll('items', { ...defaultFilters, ...filters })
  );
};

export const useAmenitiesQuery = filters => {
  const defaultFilters = {};
  return useQuery(['amenities', JSON.stringify(filters)], () =>
    findAll('amenities', { ...defaultFilters, ...filters })
  );
};

export const useRoomFeaturesQuery = filters => {
  const defaultFilters = {};
  return useQuery(['room-features', JSON.stringify(filters)], () =>
    findAll('room-features', { ...defaultFilters, ...filters })
  );
};

export const useGetItemQuery = data => {
  const result = useQuery(['room', data.id], () => findById('items', data), {
    enabled: !!data.id,
    refetchOnWindowFocus: false,
  });
  if (!data.id) {
    return { isLoading: false, data: null, remove: () => {} };
  }

  return result;
};

export const useRoomCreation = options =>
  useMutation({ mutationFn: data => createItem('items', data), ...options });

export const useItemMutation = options => {
  return useMutation({ mutationFn: data => upsert('items', data), ...options });
};

export const useItemSlotMutation = options => {
  return useMutation({
    mutationFn: slot => upsert('slots', slot),
    ...options,
  });
};

export const useDeleteItem = options =>
  useMutation({ mutationFn: data => deleteResource('items', data), ...options });

export const useImageUploader = () => {
  const uploadFiles = async data => await uploadImages('images/upload', data);

  return { uploadFiles };
};
