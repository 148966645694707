import Colors from 'constants/Colors';

const guestStyles = {
  stepDetails: {
    padding: '16px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: '20px',
  },
  leftSection: {
    flexBasis: '285px',
    flexShrink: 1,
    minWidth: '250px',
  },
  rightSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    alignItems: 'flex-start',
  },
  dynamicFormContainer: {
    flex: '0 1 400px', // Ocupa 350px pero puede reducirse si es necesario
  },
  responseTableContainer: {
    flex: '0 1 440px', // Ocupa 480px pero puede reducirse si es necesario
  },
  subtitle: {
    marginBottom: '8px',
    fontSize: '14px',
    color: '#333',
  },
  switchText: {
    marginBottom: '8px',
    fontSize: '14px',
    color: '#333',
    marginTop: '8px',
  },
  inputBox: {
    width: '100%',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
  },
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '8px',
    width: '100%',
  },
  switch: {
    position: 'relative',
    display: 'inline-block',
    width: '45px',
    height: '25px',
    marginLeft: '10px',
  },
  switchInput: {
    opacity: 0,
    width: 0,
    height: 0,
  },
  slider: {
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#ccc',
    transition: '.4s',
    borderRadius: '25px',
  },
  sliderBefore: {
    position: 'absolute',
    content: '""',
    height: '19px',
    width: '19px',
    borderRadius: '50%',
    left: '3px',
    bottom: '3px',
    backgroundColor: 'white',
    transition: '.4s',
  },
  inputCheckedSlider: {
    backgroundColor: '#2196F3',
  },
  inputCheckedSliderBefore: {
    transform: 'translateX(20px)',
  },
  roundedButton: {
    borderRadius: '25px',
    padding: '8px 16px',
    backgroundColor: '#1890ff',
    color: '#fff',
    border: 'none',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinContainer: {
    position: 'absolute',
    top: '400px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    borderBottom: '1px solid #ccc',
    paddingBottom: '5px',
    color: '#000',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 0',
  },
  keyColumn: {
    flex: 1,
    paddingRight: '10px',
    fontWeight: '600',
    color: '#333',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  valueColumn: {
    flex: 1,
    color: '#666',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '0px 0px 10px 0px',
  },
  valueColumnForm: {
    flex: 1,
    color: '#666',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '0px 0px',
  },
  inputColumn: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '4px 0',
  },
  valueColumnDiferent: {
    flex: 1,
    color: '#e42d2d',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    padding: '0px 0px 10px 0px',
    whiteSpace: 'nowrap',
  },
  button: {
    width: '22%',
    backgroundColor: Colors.blueColor,
    color: Colors.white,
    padding: '5px 35px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '20px',
  },
};
export default guestStyles;
