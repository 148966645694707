import React, { useContext } from 'react';
import styles from './DoorOpeningConfigForm.styles';
import { Form, Select, TimePicker } from 'antd';
import { AppContext } from 'App.context';
import useDoorOpeningConfigFormHook from './DoorOpeningConfigForm.hook';

export default function DoorOpeningConfigForm({
  checkinStatusOptions,
  paymentStatusOptions,
  keyTypesOptions,
  configData,
  platform,
  onValueChanged,
}) {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useDoorOpeningConfigFormHook({
    configData,
    platform,
    checkinStatusOptions,
    paymentStatusOptions,
    onValueChanged,
  });
  const selectCheckinStatusOptions = checkinStatusOptions.map(({ key, value }) => ({
    label: key,
    value,
  }));
  const selectPaymentStatusOptions = paymentStatusOptions.map(({ key, value }) => ({
    label: key,
    value,
  }));
  const selectKeyTypesOptions = keyTypesOptions.map(({ key, value }) => ({
    label: key,
    value,
  }));
  const format = 'HH:mm';
  return (
    <Form layout="vertical" style={styles.fieldsGrid}>
      <Form.Item label={translate('doorOpening.keyType')}>
        <Select
          value={configData.keyType ?? selectKeyTypesOptions[0]?.value}
          options={selectKeyTypesOptions}
          onChange={value => {
            onValueChanged('keyType', value);
          }}
        ></Select>
      </Form.Item>
      <Form.Item label={translate('doorOpening.checkinStatusLabel')}>
        <Select
          value={configData.checkinStatus ?? selectCheckinStatusOptions[0].value}
          options={selectCheckinStatusOptions}
          onChange={value => {
            onValueChanged('checkinStatus', value);
          }}
          disabled={state.checkinStatusDisabled}
        ></Select>
      </Form.Item>
      <Form.Item label={translate('doorOpening.paymentStatusLabel')}>
        <Select
          value={configData.paymentStatus ?? selectPaymentStatusOptions[0].value}
          options={selectPaymentStatusOptions}
          onChange={value => {
            onValueChanged('paymentStatus', value);
          }}
          disabled={state.paymentStatusDisabled}
        ></Select>
      </Form.Item>
      <Form.Item label={translate('doorOpening.checkinHourLabel')}>
        <TimePicker
          format={format}
          style={styles.timePicker}
          showNow={false}
          value={configData.checkinHour}
          onChange={value => {
            onValueChanged('checkinHour', value);
          }}
        />
      </Form.Item>
      <Form.Item label={translate('doorOpening.checkoutHourLabel')}>
        <TimePicker
          format={format}
          style={styles.timePicker}
          showNow={false}
          value={configData.checkoutHour}
          onChange={value => {
            onValueChanged('checkoutHour', value);
          }}
        />
      </Form.Item>
    </Form>
  );
}
