import { useQuery, useMutation } from 'react-query';
import { findAll, upsert } from 'Sell/Upsell/API/api.config';

export const useUpsellQuery = (filters, selectedHotel) => {
  const defaultFilters = {};

  return useQuery(['upsells', JSON.stringify(filters), selectedHotel], () =>
    findAll('upsells', { ...defaultFilters, ...filters })
  );
};

export const useUpsellMutation = options => {
  const mutation = useMutation({
    mutationFn: data =>
      upsert('upsells', {
        id: data.id,
        bookingId: data.bookingId,
        status: data.status,
        hotelId: data.hotel.id,
        roomUpsellToOptionId: data.roomUpsellToOptionId,
      }),
    ...options,
  });

  return mutation;
};
