import Colors from "constants/Colors";

export default {
  textComponent: {
    color: Colors.black,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: '20px',
    letterSpacing: '0.96px',
    textTransform: 'uppercase',
    marginTob: 'auto',
    marginBottom: 'auto',
  },
};
