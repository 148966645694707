import axios from 'axios';
import apiConfig from '../../api.config';
/**
 * RQ: host/guestlink/248/default-templates?schedule=checkin&scheduleName=partial&lang=en
 * RS: { lang, subject, body }
 * */
export default function getGuestLinkTemplatesAndSubjectsFromLang({ schedule: { schedule, value }, lang, cancelToken, hotelId }) {
  const {
    host,
    URIs: {
      guestlink: { getTemplatesAndSubjects },
    },
  } = apiConfig;

  const uri = `${host}${getTemplatesAndSubjects.replace(':hotelId', hotelId)}`;
  const params = {
    schedule,
    scheduleName: value,
    lang,
  }

  return axios
    .get(uri, {
      headers: {
        'Content-Type': 'application/json',
      },
      params,
      cancelToken
    })
    .then(res => {
      return res.data || '';
    }).catch(err => {
      console.error(err);
      return false;
    });
}
