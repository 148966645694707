import { useContext, useEffect, useState } from 'react';
import { notification } from 'antd';
import { ApiContext } from '../../../utils/networking/Api.context';
import { useLocation } from 'react-router-dom';
import { AppContext } from 'App.context';
import useCheckOutExportHook from '../../../utils/export/CheckOutExport.hook';
import moment from 'moment';

export default function useCheckOutListHook() {
  const { API } = useContext(ApiContext);
  const { checkoutExportActions } = useCheckOutExportHook();
  const location = useLocation();
  const {
    appState,
    appActions: { getSelectedHotel, translate },
  } = useContext(AppContext);

  const [state, setState] = useState({
    loading: true,
    dataSource: {
      items: [],
      totalItems: 0,
      totalPages: 0,
      currentPage: 0,
      pageSize: 10,
    },
    filters: location?.state?.bookingId ? { booking_Id: location?.state.bookingId } : {},
    sort: null,
    datesRange: getDefaultDateRange(),
    checkinId: null,
    subscription: '',
  });

  let activeRequests = 0;

  useEffect(() => {
    actions.loadTableData();
  }, [state.dataSource.currentPage, state.filters, state.datesRange, appState.selectedHotel]);

  useEffect(() => {
    actions.getCheckOutSubscriptionStatus();
  }, []);

  const actions = {
    getCheckOutSubscriptionStatus: () => {
      API.getCheckOutSubscriptionStatus({ hotelId: getSelectedHotel()?.key })
        .then(response => {
          updateState({ subscription: response });
        })
    },

    loadTableData: () => {
      activeRequests = activeRequests++;
      updateState({ loading: true });
      API.getCheckOuts(
        state.dataSource?.currentPage,
        state.dataSource?.pageSize,
        state.filters,
        state.sort,
        state.datesRange,
        getSelectedHotel()
      )
        .then(data => {
          activeRequests = activeRequests--;
          updateState({ dataSource: data, loading: false });
        })
        .catch(error => {
          console.warn(error);
          if (activeRequests === 1) {
            updateState({ loading: false });
          }
          activeRequests--;
        });
    },

    onChange: filters => {
      let newPage, newPageSize = -1;
      let searchFilters;
      let sortInfo;
      if (state.dataSource?.currentPage !== filters?.pagination.page) {
        newPage = filters?.pagination.page;
      }
      if (state.dataSource?.pageSize !== filters?.pagination.pageSize) {
        newPageSize = filters?.pagination.pageSize;
      }
      if (filters?.where) {
        const code = filters?.where['booking.code'];
        const hotel = filters?.where['hotel'];
        searchFilters = { code, hotel };
      }
      if (filters?.sort) {
        const order = filters.sort?.sortOrder;
        const sortField =
          typeof filters.sort?.sortField === 'string'
            ? filters.sort?.sortField
            : filters.sort?.sortField?.join('.');

        let code;
        switch (sortField) {
          case 'booking.entrance':
            code = 'entrance';
            break;
          case 'booking.departure':
            code = 'departure';
            break;
          case 'updated_at':
            code = 'updated_at';
            break;
          case 'hotel':
            code = 'hotel';
            break;
        }

        sortInfo = code && order ? `${code} ${order}` : null;
      }
      updateState({
        dataSource: {
          ...state.dataSource,
          currentPage: newPage >= 0 ? newPage : state.dataSource?.currentPage,
          pageSize: newPageSize >= 0 ? newPageSize : state.dataSource?.pageSize,
        },
        filters: searchFilters,
        sort: sortInfo,
      });
    },

    onDateChange: (_date, dateString) => {
      updateState({ datesRange: dateString });
    },

    onRowClicked: (item, _index) => {
      if (!item?.uuid) {
        notification.warn({
          message: translate('errorMessage.guestDetail'),
        });
      } else {
        updateState({ checkinId: item?.uuid });
      }
    },

    resetGuestDetail: () => {
      updateState({ checkinId: null });
    },
  };

  function getDefaultDateRange() {
    return [
      moment().subtract(1, 'day').startOf('day'),
      moment().subtract(0, 'day').endOf('day')
    ];
  }

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
