export default {
  loadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 32,
  },
  expandIcon: {
    height: 36,
    width: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
  },
};
