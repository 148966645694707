import React from 'react';
import { notification, Modal, Spin } from 'antd';
import { saveAs } from 'file-saver';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App.context';
import { ApiContext } from '../networking/Api.context';

export default function useCheckInExportHook() {
  const { API } = useContext(ApiContext);
  const {
    appActions,
    appActions: { translate },
  } = useContext(AppContext);

  const [state, setState] = useState({
    loading: false,
  });

  useEffect(() => {
    appActions.setExportingInProgress(state.loading);
  }, [state.loading]);

  const checkinExportActions = {
    export: (hotel, dateFrom, dateTo) => {
      Modal.info({
        title: <div style={{ fontWeight: 'bold' }}>{translate('checkInList.popUp.processingTitle')}</div>,
        content: (
          <div>
            <p>{translate('checkInList.popUp.processingMessage')}</p>
            <Spin tip="Cargando..." />
          </div>
        ),
        okButtonProps: {
          style: { display: 'none' }
        },
        cancelButtonProps: {
          style: { display: 'none' }
        },
        closable: false,
        centered: true,
        style: {
          borderRadius: '8px',
        },
      });

      updateState({ loading: true });
      API.checkinsExportRequest({
        hotel: hotel?.key && hotel?.key != 'null' ? hotel.key : null,
        dateFrom,
        dateTo,
        lang: appActions.getSelectedLanguage().id,
      })
        .then(data => {
          notification.success({
            message: 'Exportación de checkins completada!',
          });
          saveAs(
            new Blob([data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
            'checkins_export.xlsx'
          );
        })
        .catch(error => {
          console.warn(error);
        })
        .finally(() => {
          // Cerrar el modal después de completar la exportación
          Modal.destroyAll();
          updateState({ loading: false });
        });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { checkinExportActions };
}
