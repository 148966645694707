import Colors from 'constants/Colors';

export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    borderLeft: `1px solid ${Colors.lightGray}`,
    padding: 8,
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: Colors.grayBackground,
    fontSize: 12,
    width: '100%',
    marginBottom: 4,
    color: Colors.darkText,
  },
  guestType: {
    display: 'flex',
    fontSize: 12,
    padding: 8,
    backgroundColor: Colors.lightGray,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    marginBottom: 4,
  },
};
