import Colors from 'constants/Colors';

const INPUT_SIZE = 248;

export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: 32,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    marginBottom: 16,
  },
  screen: {
    display: 'flex',
    gap: 16,
  },
  container: {
    padding: 16,
    borderRadius: 16,
    backgroundColor: Colors.white,
  },
  sideMenu: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1 / 4,
    color: 'pink',
    gap: 16,
  },

  formSection: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,
    paddingBottom: 16,
    gap: 8,
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: Colors.darkText,
    marginTop: 16,
    marginBottom: 16,
  },
  sectionTitle: {
    fontSize: 24,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: Colors.titleColor,
  },
  inputContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    gap: 8,
    marginTop: 8,
  },
  input: {
    maxWidth: INPUT_SIZE,
    minWidth: INPUT_SIZE,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: Colors.darkText,
    marginTop: 8,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    justifyContent: 'right',
  },
  button: {
    width: 180,
    alignSelf: 'right',
  },
};
