import FIELDS from './PoliceReportFields';

export default [
  FIELDS.REFERENCE_KEY,
  FIELDS.CONTRACTDATE_KEY,
  FIELDS.ENTRANCE_KEY,
  FIELDS.DEPARTURE_KEY,
  FIELDS.NUMGUESTS_KEY,
  FIELDS.NAME_KEY,
  FIELDS.SURNAME_KEY,
  FIELDS.CONTACTVALUE_KEY,
  FIELDS.BIRTHDATE_KEY,
  FIELDS.ZIPCODE_KEY,
  FIELDS.COUNTRY_KEY,
  FIELDS.ADDRESS_KEY,
];
