export default [
  {
    id: 'cardex',
    hasReplacementTags: true,
    isVisible: true,
  },
  {
    id: 'custom_cardex_pdf',
    hasReplacementTags: true,
    isVisible: true,
    //customFileName: true,
  },
  {
    id: 'terms',
    hasReplacementTags: true,
    isVisible: true,
    //customFileName: true,
  },
  {
    id: 'data-privacy-policy',
    hasReplacementTags: false,
    isVisible: true,
  },
  {
    id: 'loyalty-program',
    hasReplacementTags: false,
    isVisible: true,
  },
  {
    id: 'data-commercial-use',
    hasReplacementTags: false,
    isVisible: true,
  },
  {
    id: 'data-privacy-policy-label',
    hasReplacementTags: false,
    isVisible: false,
  },
  {
    id: 'data-commercial-use-label',
    hasReplacementTags: false,
    isVisible: false,
  },
  {
    id: 'loyalty-program-label',
    hasReplacementTags: false,
    isVisible: false,
  },
];
