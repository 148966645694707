import React from 'react';
import { Button, Dropdown, Space, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styles from './TableActions.styles.js';

export default function TableActions({ items = [], onItemClick = () => {}, title = 'Action' }) {
  const handleButtonClick = e => {
    e.stopPropagation();
  };

  const handleMenuClick = ({ domEvent, key }) => {
    domEvent.stopPropagation();
    onItemClick(key);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Dropdown menu={menuProps} onClick={handleButtonClick}>
      <Button style={styles.button}>
        <Space>
          {title}
          <DownOutlined style={styles.icon} />
        </Space>
      </Button>
    </Dropdown>
  );
}
