import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from 'App.context';
import styles from './Selector.styles';
import usePmsConfigHook from 'Components/Dashboard/PMS/PMSConfig/PMSConfig.hook';
import Guest from 'Components/Dashboard/PMS/PMSSelector/Guest/Guest';
import { ApiContext } from '../../../../utils/networking/Api.context';
import AutomaticBookingTest from 'Components/common/ConfigurationForm/AutomaticBookingTest/AutomaticBookingTest';
import TestResult from 'Components/common/ConfigurationForm/AutomaticConfigurationTest/TestResult/TestResult';

const Tabs = ({ selectedPms, hotelId }) => {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { API } = useContext(ApiContext);
  const { state, actions } = usePmsConfigHook();
  const [activeTab, setActiveTab] = useState(translate('selector.tabs.validationSearches'));

  // Define tabs
  const tabs = [
    {
      name: translate('selector.tabs.validationSearches'),
      content: (
        <div style={styles.flexContainer}>
          {selectedPms ? (
            <>
              {/* First div with fixed width */}
              <div style={styles.leftPanel}>
                <AutomaticBookingTest
                  hotelId={hotelId}
                  serviceName={selectedPms.name}
                  buttonText={translate('pmsScreen.testBooking')}
                  buttonAction={actions.doBookingTest}
                  isTesting={state.isTestingBooking}
                  testResult={state.testBookingResult}
                  description={translate('pmsScreen.testBookingDesc')}
                  inputs={Object.values(selectedPms.configBookingSearchForm || {})[0]}
                />
              </div>

              {/* Second div that contains the rest */}
              <div style={styles.rightPanel}>
                {/* Show results directly without loading indicator */}
                {state.showResults && (
                  <div style={styles.testMessageContainer}>
                    <p style={styles.title}>{translate('selector.tabs.results')}</p>

                    {state.searchByIdResult && (
                      <div>
                        <TestResult
                          success={state.searchByIdResult.success}
                          testName={translate('searchesResults.searchByIdResult')}
                        />
                      </div>
                    )}

                    {state.parameters.bookingCode && state.searchByCodeResult && (
                      <div>
                        <TestResult
                          success={state.searchByCodeResult.success}
                          testName={translate('searchesResults.searchByCodeResult')}
                        />
                      </div>
                    )}

                    {state.parameters.entranceDate && state.searchByEntranceDateResult && (
                      <div>
                        <TestResult
                          success={state.searchByEntranceDateResult.success}
                          testName={translate('searchesResults.searchByEntranceDateResult')}
                        />
                      </div>
                    )}

                    {state.parameters.holderName && state.searchByHolderNameResult && (
                      <div>
                        <TestResult
                          success={state.searchByHolderNameResult.success}
                          testName={translate('searchesResults.searchByHolderNameResult')}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div>{translate('pmsScreen.noPmsSelected')}</div>
          )}
        </div>
      ),
    },
    {
      name: translate('selector.tabs.validationSendData'),
      content: (
        <Guest
          hotelId={hotelId}
          translate={translate}
          selectedPms={selectedPms}
          actions={actions}
          API={API}
        />
      ),
    },
    // {
    //   name: translate('selector.tabs.validationTestAll'),
    //   content: <div>Agregar contenido de Onboarding</div>,
    // },
  ];

  // Initialize active tab on mount
  useState(tabs[0] ? tabs[0].name : '');

  // Handle tab click
  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };

  return (
    <div>
      <h1 style={styles.title}>{translate('selector.tabs.onboardingTitle')}</h1>
      <p style={styles.subtitle}>{translate('selector.tabs.onboardingDescription')}</p>
      <div style={styles.tabList}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            style={{
              ...styles.tab,
              ...(activeTab === tab.name ? styles.activeTab : {}),
            }}
            onClick={() => handleTabClick(tab.name)}
          >
            {tab.name}
          </div>
        ))}
      </div>
      <div style={styles.tabContent}>
        {tabs.map((tab, index) => activeTab === tab.name && <div key={index}>{tab.content}</div>)}
      </div>
    </div>
  );
};

export default Tabs;
