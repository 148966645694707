import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import Routes from 'Sell/CrossSell/Router/routes';
import ItemList from 'Sell/CrossSell/Item/List/ItemList';
import UpsertItem from 'Sell/CrossSell/Item/Upsert/UpsertItem';
import CrossSellList from 'Sell/CrossSell/CrossSell/List/CrossSellList';
import Reports from 'Sell/CrossSell/Reports/Reports';

export default function NestedRoutes() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}${Routes.itemsList}`}>
        <ItemList />
      </Route>
      <Route path={`${path}${Routes.itemCreate}`}>
        <UpsertItem />
      </Route>
      <Route path={`${path}${Routes.itemUpsert}`}>
        <UpsertItem />
      </Route>
      <Route exact path={`${path}${Routes.crossSellsList}`}>
        <CrossSellList />
      </Route>
      <Route exact path={`${path}${Routes.reports}`}>
        <Reports />
      </Route>
      <Redirect to={`${path}${Routes.itemsList}`} />
    </Switch>
  );
}
