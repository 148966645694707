import React, { useContext } from 'react';
import { AppContext } from 'App.context';
import styles from './TestResult.styles';
import Colors from 'constants/Colors'; 

export default function TestResult({ success, testName }) {
  const { appActions: { translate } } = useContext(AppContext);

  const iconStyle = {
    marginRight: 8,
    fontSize: '20px', 
    fontWeight: 'bold', 
  };
  const successIcon = '✓'; 
  const errorIcon = '✗'; 

  return (
    <div style={styles.container}>
      {success ? (
        <span style={{ color: Colors.testOk, ...iconStyle }}>
          {successIcon}
        </span>
      ) : (
        <span style={{ color: Colors.testKo, ...iconStyle }}>
          {errorIcon}
        </span>
      )}
      {testName}
    </div>
  );
}
