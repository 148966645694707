import React, { useContext } from 'react';
import styles from './ChangePasswordForm.styles';
import AppTextInput from 'Components/common/AppTextInput/AppTextInput';
import Space from 'Components/common/Space/Space';
import useChangePasswordFormHook from './ChangePasswordForm.hook';
import { AppContext } from 'App.context';
import AppButton from 'Components/common/Forms/AppButton/AppButton';

export default function ChangePasswordForm() {
  const { state, actions } = useChangePasswordFormHook();
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <div>
      <AppTextInput
        value={state.password}
        name={translate('user.currentPassword')}
        onChange={value => actions.setField('password', value)}
        password
      />
      <Space height={8} />
      <AppTextInput
        value={state.newPassword}
        name={translate('user.newPassword')}
        onChange={value => actions.setField('newPassword', value)}
        password
      />
      <Space height={8} />
      <AppTextInput
        value={state.repeatPassword}
        name={translate('register.repeatPassword')}
        onChange={value => actions.setField('repeatPassword', value)}
        password
      />
      <div style={styles.buttonContainer}>
        <AppButton
          text={translate('save')}
          action={() => actions.validateDataAndChangePassword()}
        />
      </div>
    </div>
  );
}
