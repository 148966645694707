import axios from 'axios';
import apiConfig from '../api.config';

export default function updateCommunicationEmails({ hotelId, senderCheckinEmail, emailCheckin }) {
  const {
    host,
    URIs: { communicationEmails },
  } = apiConfig;

  const uri = `${host}${communicationEmails}`;

  return axios
    .put(
      uri,
      JSON.stringify({
        hotelId,
        sender_checkin_email: senderCheckinEmail,
        email_checkin: emailCheckin,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(res => {
      return res.data;
    });
}
