export default `<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:21px;font-family:&quot;Calibri&quot;,sans-serif;">Termos e Condições&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:18px;font-family:&quot;Calibri&quot;,sans-serif;">INFORMAÇÕES LEGAIS&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;">
    <span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">
        Para sua segurança, garantimos que os dados do seu cartão de crédito serão tratados de acordo com os padrões internacionais PCI-DSS. Autorizo o Hotel a cobrar as despesas totais da minha estadia no hotel para o cartão de crédito mencionado acima. Caso eu deixe o Hotel em uma data anterior à data final da reserva indicada neste documento, autorizo que o valor total das noites contratadas, incluindo as não utilizadas, seja cobrado no cartão de crédito.<br><br>
        No [hotel_name], estamos comprometidos com a proteção da sua privacidade. Você pode nos contatar pelo [address] ou pelo e-mail [hotel_email]. O [hotel_name] processará seus dados para gerenciar sua estadia no hotel e melhorar sua experiência como nosso hóspede, enquanto o [hotel_name] também processará seus dados para fornecer serviços de hospedagem e serviços complementares. Podemos precisar processar seus dados para cumprir as obrigações legais às quais estamos sujeitos (incluindo prevenção de fraudes). O [hotel_name] poderá comunicar seus dados a terceiros, como bancos ou outras instituições financeiras para processar pagamentos ou a agências de segurança para cumprir as obrigações impostas pela lei.<br><br>
        Se você se inscrever, o [hotel_name] processará seus dados pessoais para fornecer os serviços de fidelidade correspondentes. Nesse sentido, processaremos quaisquer pontos gerados e poderemos enviar informações sobre seus pontos e os benefícios do programa, ajustadas ao seu perfil.<br><br>
        Você pode exercer seus direitos de acesso, retificação, exclusão, objeção e/ou limitação do processamento, bem como a portabilidade dos seus dados, escrevendo para o [hotel_name] no endereço de e-mail ou postal indicado acima, comprovando sua identidade e indicando em ambos os casos (a) "Proteção de Dados" como assunto e (b) se seu pedido é direcionado ao [hotel_name]. Além disso, você pode entrar em contato a qualquer momento com a Autoridade Nacional de Supervisão competente.<br><br>
        Para mais informações, acesse [hotel_web] ou consulte na recepção do hotel.
    </span>
</p>`;
