import Colors from 'constants/Colors';

export default {
  container: {
    backgroundColor: Colors.joditBg,
    borderRadius: 8,
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    marginBottom: 16,
  },
  header: {
    fontWeight: 'bold',
    color: Colors.darkText,
  },
  input: {
    display: 'flex',
    backgroundColor: Colors.white,
    borderRadius: 8,
    alignItems: 'center',
  },
  addonAfter: {
    color: Colors.darkText,
    paddingLeft: 8,
    paddingRight: 8,
    borderLeft: `1px solid ${Colors.joditBorder}`,
  },
};
