import { EyeOutlined } from '@ant-design/icons';
import { Button, DatePicker, Modal } from 'antd';
import React, { useContext } from 'react';
import BaseTable from '../../common/BaseTable/BaseTable';
import CustomButton from '../../common/CustomButton/CustomButton';
import DefaultLayout from '../../DefaultLayout/DefaultLayout';
import useCheckInListHook from './CheckInList.hook';
import styles from './CheckInList.styles';
import CheckInDetail from './CheckInDetail/CheckInDetail';
import Colors from '../../../constants/Colors';
import IHClientTag from '../../common/IHClientTag/IHClientTag';
import { AppContext } from '../../../App.context';
import { getFormattedDate } from 'utils/DateUtils';

export default function CheckInList() {
  const { state, actions } = useCheckInListHook();
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const columns = [
    {
      title: translate('checkInList.locatorCol'),
      dataIndex: ['booking', 'code'],
      key: 'booking.code',
      searchable: true,
      sorter: false,
      render: (_, row) => {
        return <div style={{ fontWeight: 500 }}>{row.booking.code}</div>;
      },
    },
    {
      title: translate('checkInList.typeCol'),
      dataIndex: 'guestType',
      render: (val, id) => <GuestTypeTag text={val} />,
      searchable: false,
      sorter: false,
    },
    {
      title: translate('checkInList.nameCol'),
      dataIndex: ['guest', 'name'],
      key: 'guest.name',
      searchable: false,
      sorter: false,
    },
    {
      title: translate('checkInList.surnameCol'),
      dataIndex: ['guest', 'surname'],
      key: 'guest.surnname',
      searchable: false,
      sorter: false,
    },
    {
      title: 'Hotel',
      dataIndex: 'hotel',
      key: 'hotel',
      searchable: false,
      sorter: false,
    },
    {
      title: translate('checkInList.checkinDate'),
      dataIndex: ['sent_to_pms'],
      key: 'sent_to_pms',
      searchable: false,
      sorter: true,
      render: text => {
        return <div>{getFormattedDate(text)}</div>;
      },
    },
    {
      title: translate('checkInList.checkinCol'),
      dataIndex: ['booking', 'entrance'],
      key: 'booking.entrance',
      searchable: false,
      sorter: true,
    },
    {
      title: translate('checkInList.checkoutCol'),
      dataIndex: ['booking', 'departure'],
      key: 'booking.departure',
      searchable: false,
      sorter: true,
    },
    {
      title: translate('checkInList.channelCol'),
      dataIndex: 'clientId',
      key: 'clientId',
      align: 'center',
      width: '5%',
      render: text => {
        return <IHClientTag ihClientId={text} />;
      },
    },
    {
      title: translate('checkInList.actions'),
      dataIndex: null,
      key: null,
      align: 'center',
      width: '5%',
      render: (text, object) => {
        return <Button type="secondary" icon={<EyeOutlined />} />;
      },
    },
  ];

  const { RangePicker } = DatePicker;

  return (
    <DefaultLayout singleBox title={translate('checkInList.title')} enableAllHotels={true}>
      <div style={styles.mainContainer}>
        <div style={styles.header}>
          {/* <div style={styles.title}>{translate('checkInList.title')}</div> */}
          <div style={styles.buttonsContainer}>
            <RangePicker
              defaultValue={state.datesRange}
              onChange={actions.onDateChange}
              disabled={state.loading}
            />
            <CustomButton
              text={translate('checkInList.export')}
              background={Colors.exportButton}
              color={'white'}
              onClick={() => {
                actions.export();
              }}
            ></CustomButton>
          </div>
        </div>
      </div>

      <div style={styles.tableContainer}>
        <div style={styles.table}>
          <BaseTable
            data={state.dataSource}
            columns={columns}
            loading={state.loading}
            onChange={filters => actions.onChange(filters)}
            onRowClicked={actions.onRowClicked}
          />
        </div>
      </div>
      <Modal
        visible={state.checkinId !== null}
        centered
        width={'80%'}
        onCancel={actions.resetCheckinDetail}
        footer={null}
        style={{ marginTop: '24px' }}
        destroyOnClose={true}
      >
        <CheckInDetail checkinId={state.checkinId} />
      </Modal>
    </DefaultLayout>
  );
}

function GuestTypeTag(text) {
  var literal;
  switch (text.text) {
    case 'adult':
    default:
      literal = 'Adulto';
      break;
    case 'children':
      literal = 'Niño';
      break;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'start',
        color: '#3e3e3e',
      }}
    >
      {literal}
    </div>
  );
}
