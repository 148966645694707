import axios from 'axios';
import apiConfig from '../api.config';

export default function pmsConfigRequest({ pmsId, hotelId, config }) {
  const {
    host,
    URIs: { pmsConfig },
  } = apiConfig;

  const uri = `${host}${pmsConfig.replace(':pmsId', pmsId)}?hotelId=${hotelId}`;

  return axios
    .get(uri, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
