import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { AppContext } from '../../../../App.context';
import { ApiContext } from '../../../../utils/networking/Api.context';
import { notification } from 'antd';
import GuestFormFieldState from './GuestFormFieldState';

export default function useGuestFormHook({ selectedLanguage, selectedHotelId }) {
  const { API } = useContext(ApiContext);
  const { appState, appActions } = useContext(AppContext);
  const { translate, getSelectedHotel } = appActions;

  const [state, setState] = useState({
    isLoading: false,
    guestForm: null,
  });

  useEffect(() => {
    actions.loadGuestFormData(selectedHotelId);
  }, [selectedHotelId]);

  const actions = {
    loadGuestFormData: hotelId => {
      if (!hotelId) return;
      updateState({ isLoading: true });
      API.guestFormsRequest(hotelId)
        .then(res => {
          const parsedFormItems = actions.parseFormItems(res?.hotelFields);

          updateState({
            isLoading: false,
            guestForm: parsedFormItems,
          });
        })
        .catch(err => {
          updateState({ isLoading: false });
          console.warn(err);
        });
    },

    parseFormItems: formItems => {
      return formItems.map(item => {
        if (item.onlyItaly) {
          if (item.name === 'province' || item.name === 'city') {
            item.desc = translate('guestTable.italyResidencyDesc');
          } else {
            item.desc = translate('guestTable.italyDesc');
          }
          return item;
        } else return item;
      });
    },

    onFieldStateChecked: ({ id, fieldHotelId }) => {
      const updatedItem = actions.getGuestFormField({ id, fieldHotelId });

      if (updatedItem.state == GuestFormFieldState.ACTIVE) {
        updatedItem.state = GuestFormFieldState.DISABLED;
        updatedItem.required = 0;
      } else {
        updatedItem.state = GuestFormFieldState.ACTIVE;
      }

      updateState({ guestForm: state.guestForm });
    },

    onFieldRequiredChecked: ({ id, fieldHotelId }) => {
      const updatedItem = actions.getGuestFormField({ id, fieldHotelId });

      if (updatedItem.required == 0) {
        updatedItem.required = 1;
        updatedItem.state = GuestFormFieldState.ACTIVE;
      } else {
        updatedItem.required = 0;
      }

      updateState({ guestForm: state.guestForm });
    },

    onFieldTextChanged: ({ id, fieldHotelId, customText }) => {
      const updatedItem = actions.getGuestFormField({ id, fieldHotelId });
      updatedItem.customText = true;

      const formText = updatedItem?.formTexts?.find(formText => formText.lang == selectedLanguage);

      const defaultText = updatedItem?.defaultTexts?.find(text => text.lang == selectedLanguage);

      formText.value = customText ? customText : defaultText.value;

      updateState({ guestForm: state.guestForm });
    },

    getGuestFormField: ({ id, fieldHotelId }) => {
      return state.guestForm?.find(item => {
        if (fieldHotelId) {
          return item?.fieldHotelId === fieldHotelId;
        } else {
          return item.id == id;
        }
      });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
