import axios from 'axios';
import apiConfig from '../api.config';

var CancelToken = axios.CancelToken;
var cancel;

export default function purchasesList(page, size, filters, datesRange, hotel) {
  const {
    host,
    URIs: { purchasesList },
  } = apiConfig;

  var filter = { page, size, ...filters };
  if (datesRange[0] && datesRange[1]) filter.datesRange = datesRange;
  filter.hotel = hotel;

  const uri = `${host}${purchasesList}?filter=${JSON.stringify(filter)}`;

  if (cancel) cancel();

  return axios
    .get(uri, {
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      }),
    })
    .then(res => {
      return res.data;
    });
}
