import { useRef } from 'react';

export default function useMailSubjectEditorHook(onValueChange) {
  const input = useRef(null);

  const actions = {
    setInputRef: ref => {
      input.current = ref;
    },

    getInputRef: () => {
      return input;
    },

    onInsertClicked: tag => {
      if (!tag) return;
      const cursorPosition = input.current.input.selectionStart;
      const textBeforeCursorPosition = input.current.input.value.substring(0, cursorPosition);
      const textAfterCursorPosition = input.current.input.value.substring(
        cursorPosition,
        input.current.input.value.length
      );

      const finalText = `${textBeforeCursorPosition}[${tag}]${textAfterCursorPosition}`;

      actions.onTextChange(finalText);
    },

    onTextChange: value => {
      onValueChange('subject', value);
    },
  };

  return { actions };
}
