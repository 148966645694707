import axios from 'axios';
import apiConfig from 'utils/networking/api.config';

export default async ({ hotelId = null, authorization = null }) => {
  const {
    validationsHost,
    URIs: {
      validations: { connection },
    },
  } = apiConfig;

  const uri = `${validationsHost}${connection}`.replace(':hotelId', hotelId);

  return axios.get(uri).then(res => {
    return res.data;
  });
};
