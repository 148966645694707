import { AppContext } from 'App.context';
import Colors from 'constants/Colors';
import { useState, useEffect, useContext } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import { useHistory } from 'react-router';
import { notification } from 'antd';
import useBookingExportHook from '../../../utils/export/BookingExport.hook';
import moment from 'moment/moment';

export default function useBookingListHook() {
  const { API } = useContext(ApiContext);
  const { bookingExportActions } = useBookingExportHook();
  const history = useHistory();
  const {
    appState,
    appActions: { translate, getSelectedHotel },
  } = useContext(AppContext);

  const [state, setState] = useState({
    isLoading: false,
    dataSource: {
      items: [],
      totalItems: 0,
      totalPages: 0,
      currentPage: 0,
      pageSize: 10,
    },
    filters: {},
    datesRange: getDefaultDateRange(),
    sort: null,
  });

  useEffect(() => {
    actions.loadBookings();
  }, [state.dataSource.currentPage, state.filters, appState.selectedHotel, state.datesRange]);

  const actions = {
    loadBookings: () => {
      updateState({ isLoading: true });
      API.bookingsRequest(
        state.dataSource?.currentPage,
        state.dataSource?.pageSize,
        state.filters,
        state.sort,
        state.datesRange,
        getSelectedHotel()
      )
        .then(res => {
          updateState({ dataSource: res, isLoading: false });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },

    getCheckinStatusColor: (total, checkedIn) => {
      if (checkedIn == 0) {
        return Colors.disableButton;
      } else if (checkedIn == total) {
        return Colors.greenPill;
      } else {
        return Colors.yellowPill;
      }
    },

    onRowClicked: (item, _index) => {
      history.push({
        pathname: '/checkin',
        state: { bookingId: item?.id },
      });
    },

    onChange: filters => {
      var newPage,
        newPageSize = -1;
      var searchFilters;
      var sortInfo;

      if (state.dataSource?.currentPage !== filters?.pagination.page) {
        newPage = filters?.pagination.page;
      }
      if (state.dataSource?.pageSize !== filters?.pagination.pageSize) {
        newPageSize = filters?.pagination.pageSize;
      }

      if (filters?.where) {
        const code = filters?.where['code'];
        searchFilters = { code };
      }

      if (filters?.sort) {
        const sortField =
          typeof filters.sort?.sortField === 'string'
            ? filters.sort?.sortField
            : filters.sort?.sortField?.join('.');
        const order = filters.sort?.sortOrder;
        var code;

        switch (sortField) {
          case 'total':
            code = 'total';
            break;
          case 'departure':
            code = 'departure';
            break;
          case 'hotel':
            code = 'hotel';
            break;
          case 'entrance':
            code = 'entrance';
            break;
          default:
            break;
        }

        sortInfo = code && order ? `${code} ${order}` : null;
      }

      updateState({
        dataSource: {
          ...state.dataSource,
          currentPage: newPage >= 0 ? newPage : state.dataSource?.currentPage,
          pageSize: newPageSize >= 0 ? newPageSize : state.dataSource?.pageSize,
        },
        filters: searchFilters,
        sort: sortInfo,
      });
    },

    onDateChange: (_date, dateString) => {
      updateState({ datesRange: dateString });
    },

    export: () => {
      const dateFrom = state.datesRange[0];
      const dateTo = state.datesRange[1];

      if (!dateFrom || !dateTo) {
        notification.info({
          message: 'Debe seleccionar un rango de fechas para realizar la exportación',
        });
        return;
      }
      bookingExportActions.export(getSelectedHotel()?.id, dateFrom, dateTo);
    },
  };

  function getDefaultDateRange() {
    return [moment().subtract(1, 'day').startOf('day'), moment().subtract(0, 'day').endOf('day')];
  }

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
