import axios from 'axios';
import LegalDocuementsTags from 'Components/Dashboard/LegalDocuments/LegalDocuementsTags';
import apiConfig from '../api.config';

export default function getTags({ scope }) {
  const {
    host,
    URIs: { getTags },
  } = apiConfig;

  const uri = `${host}${getTags}`;

  return axios
    .get(uri, {
      params: {
        scope,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
