import { useState, useContext } from 'react';
import { useCrossSellHistory } from 'Sell/CrossSell/Router/useHistory';
import { addLikeToWhere } from 'Sell/utils/utils';
import Routes from 'Sell/CrossSell/Router/routes';
import { Modal, notification } from 'antd';
import { AppContext } from 'App.context';
import { useDeleteItem, useItemMutation, useItemQuery } from 'Sell/CrossSell/Item/Item.service';

export default function useItemList() {
  const { push } = useCrossSellHistory();
  const { confirm, info } = Modal;
  const [state, setState] = useState({ limit: 10, offset: 0 });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const {
    appState: { selectedHotel },
    appActions: { translate },
  } = useContext(AppContext);

  const { isLoading, data, refetch } = useItemQuery(state, selectedHotel);

  const { mutate } = useItemMutation({
    onSuccess: refetch,
  });
  const deleteResource = useDeleteItem({
    onSuccess: refetch,
  });

  function updateState(nextState) {
    setState(previousState => ({ ...previousState, ...nextState }));
  }

  const updateStatus = (object, ids, action) => {
    object.forEach(item => {
      if (ids.includes(item.id)) {
        switch (action) {
          case 'status_active':
            mutate(
              { ...item, status: 'active' },
              {
                onError: e => console.log(e),
              }
            );
            break;
          case 'status_disabled':
            mutate(
              { ...item, status: 'disabled' },
              {
                onError: e => console.log(e),
              }
            );
            break;
          default:
            return;
        }
      }
    });
  };

  const actions = {
    onChange: ({ where, pagination }) => {
      const limit = pagination.pageSize;
      updateState({ offset: pagination.page * limit, limit, where: addLikeToWhere(where) });
    },
    onRowClicked: item => {
      push(Routes.itemUpsert.replace(':id', item.id));
    },
    onCreateClicked: () => push(Routes.itemCreate),
    onDeleteClicked: data => {
      confirm({
        title: translate('crossSell.deleteItem.title'),
        content: translate('crossSell.deleteItem.content'),
        okText: translate('crossSell.deleteItem.okText'),
        okType: 'danger',
        onOk() {
          deleteResource.mutate(data);
        },
      });
    },
    onSelectChange: newSelectedRowKeys => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    handleActionClick: item => async action => {
      switch (action) {
        case 'detail':
          push(Routes.itemUpsert.replace(':id', item.id));
          break;
        case 'status':
          mutate({ ...item, status: item.status === 'active' ? 'disabled' : 'active' });
          break;
        case 'delete':
          actions.onDeleteClicked(item.id);
          break;
        default:
          return;
      }
    },
    handleBulkActionClick: async action => updateStatus(data?.data, selectedRowKeys, action),
  };

  return {
    dataSource: {
      items: data?.data,
      totalItems: data?.count,
      totalPages: Math.round(data?.count / state.limit),
      currentPage: state.offset / state.limit,
      pageSize: state.limit,
    },
    isLoading,
    actions,
  };
}
