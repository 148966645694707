import axios from 'axios';
import apiConfig from '../api.config';

export default function getPaperlessPushConfig({ page, size, filters }) {
  const {
    host,
    URIs: { paperlessPush },
  } = apiConfig;

  const filter = { page, size, ...filters };

  const uri = `${host}${paperlessPush}?filter=${JSON.stringify(filter)}`;

  return axios
    .get(uri, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
