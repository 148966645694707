import React, { useContext } from 'react';
import styles from './TagInserter.styles.js';
import useTagInserterHook from './TagInserter.hook.js';
import { Select } from 'antd';
import AppButton from 'Components/common/Forms/AppButton/AppButton.jsx';
import { AppContext } from 'App.context.js';

export default function TagInserter({ onInsertClicked, hint, scope }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useTagInserterHook({ scope });

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <div style={styles.selectorContainer}>
          <Select
            bordered={false}
            placeholder={hint || translate('tagInserter.hint')}
            value={state.selectedTag}
            style={styles.selector}
            showSearch
            onChange={actions.onTagSelected}
            options={state.tags}
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
            }
          />
        </div>

        {onInsertClicked && (
          <AppButton
            secondary
            action={() => onInsertClicked(state.selectedTag)}
            text={translate('tagInserter.button')}
          />
        )}
      </div>
    </div>
  );
}
