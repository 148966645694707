import React, { useContext } from 'react';
import { AppContext } from 'App.context.js';
import { BillingListingContext } from '../BillingListing.context';
import styles from '../BillingModal/BillingModal.styles';
import AppButton from 'Components/common/Forms/AppButton/AppButton';
import AppInput from 'Components/common/AppInput/AppInput.jsx';
import AppInputTypes from 'Components/common/AppInput/AppInputTypes.js';
import AppSelector from 'Components/common/AppSelector/AppSelector.jsx';
import Loading from 'Components/common/Loader/Loading';
import { CloudUploadOutlined } from '@ant-design/icons';
import { Upload, DatePicker, Select, Button } from 'antd';
import moment from 'moment';

const { Dragger } = Upload;

export default function BillingInvoiceForm() {
    const {
        appActions: { translate },
    } = useContext(AppContext);

    const { invoice, state, actions } = useContext(BillingListingContext);
    
    const invoiceIcon = require('assets/images/billing/invoice-modal-icon.png');
    const priceCurrencyError = state.invoiceErrors.includes('amount') || state.invoiceErrors.includes('currency');
    const currencySelector = (
        <Select value={invoice.currency} options={actions.getCurrencyOptions()} placeholder={translate('billingConfig.invoice.form.currency_hint')} hasError={state.invoiceErrors.includes('currency')} onChange={value => actions.onChangeInvoiceField('currency', value)} />
    );
    
    return (
        <div>
            {state.isLoading ? (
              <Loading />
            ) : (
                <>
                    <div style={styles.header}>
                        <div>
                            <img src={invoiceIcon} alt="modal-invoice" />
                        </div>
                        <div style={styles.headerTitle}>{translate('billingConfig.invoice.modal.title')}</div>
                    </div>
                    
                    <div style={styles.body}>

                        <div style={styles.formData}>
                            <div style={styles.formSection}>
                                <div style={{...styles.sectionTitle,...state.invoiceErrors.includes('file') && styles.titleWithError}}>{translate('billingConfig.invoice.form.uploadInvoice')}</div>
                                <div style={styles.dragger}>
                                    <Dragger {...actions.getUploadInvoiceProps()} maxCount={1} disabled={state.isLoading}>
                                        <p className="ant-upload-drag-icon">
                                            <CloudUploadOutlined />
                                        </p>
                                        <p className="ant-upload-text">{translate('billingConfig.invoice.fileUpload.title')}</p>
                                        <p className="ant-upload-hint">{translate('billingConfig.invoice.fileUpload.hint')}</p>
                                    </Dragger>
                                </div>
                            </div>
                        </div>

                        <div style={styles.formData}>
                        <div style={styles.formSection}>
                                <div style={{...styles.sectionTitle,...state.invoiceErrors.includes('invoiceId') && styles.titleWithError}}>{translate('billingConfig.invoice.form.invoiceId')}</div>
                                <div style={styles.inputContainer}>
                                    <AppInput
                                        value={invoice.invoiceId}
                                        name={translate('billingConfig.invoice.form.invoiceId')}
                                        hint={translate('billingConfig.invoice.form.invoiceId_hint')}
                                        onChange={value => actions.onChangeInvoiceField('invoiceId', value)}
                                        disabled={state.isLoading}
                                        hasError={state.invoiceErrors.includes('invoiceId')}
                                    />
                                </div>
                                {state.invoiceErrors.includes('invoiceId')? (<div style={styles.errorSubmessage}>*{translate('billingConfig.invoice.form.invalidInvoiceIdError')}</div>) : (<></>)}
                            </div>
                            <div style={styles.formSection}>
                                <div style={{...styles.sectionTitle,...state.invoiceErrors.includes('invoiceInternalId') && styles.titleWithError}}>{translate('billingConfig.invoice.form.invoiceInternalId')}</div>
                                <div style={styles.inputContainer}>
                                    <AppInput
                                        value={invoice.invoiceInternalId}
                                        name={translate('billingConfig.invoice.form.invoiceInternalId')}
                                        hint={translate('billingConfig.invoice.form.invoiceInternalId_hint')}
                                        onChange={value => actions.onChangeInvoiceField('invoiceInternalId', value)}
                                        disabled={state.isLoading}
                                        hasError={state.invoiceErrors.includes('invoiceInternalId')}
                                    />
                                </div>
                                {state.invoiceErrors.includes('invoiceInternalId')? (<div style={styles.errorSubmessage}>*{translate('billingConfig.invoice.form.invalidInvoiceInternalIdError')}</div>) : (<></>)}
                            </div>
                        </div>

                        <div style={styles.formData}>
                            <div style={styles.formSection}>
                                <div style={{...styles.sectionTitle,...state.invoiceErrors.includes('contractId') && styles.titleWithError}}>{translate('billingConfig.invoice.form.contractId')}</div>
                                <div style={styles.inputContainer}>
                                    <AppSelector
                                        style={styles.selector}
                                        name={translate('billingConfig.invoice.form.contractId')}
                                        hint={translate('billingConfig.invoice.form.contractId_hint')}
                                        value={invoice.contractId}
                                        options={actions.getContractOptions()}
                                        onOptionSelected={value => actions.onChangeInvoiceField('contractId', value)}
                                        disabled={state.isLoading}
                                        hasError={state.invoiceErrors.includes('contractId')}
                                    />
                                </div>
                            </div>
                            <div style={styles.formSection}>
                                <div style={{...styles.sectionTitle,...priceCurrencyError && styles.titleWithError}}>{translate('billingConfig.invoice.form.amount')}</div>
                                <div style={styles.inputContainer}>
                                    <style>{`.ant-input-group-addon {border: 0px !important;}`}</style>
                                    <AppInput
                                        value={invoice.amount}
                                        name={translate('billingConfig.invoice.form.amount')}
                                        hint={translate('billingConfig.invoice.form.amount_hint')}
                                        onChange={value => actions.onChangeInvoiceField('amount', value)}
                                        disabled={state.isLoading}
                                        addonAfter={currencySelector}
                                        hasError={priceCurrencyError}
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={styles.formData}>
                            <div style={styles.formSection}>
                                <div style={{...styles.sectionTitle,...state.invoiceErrors.includes('invoiceDate') && styles.titleWithError}}>{translate('billingConfig.invoice.form.date')}</div>
                                <div style={styles.inputContainer}>
                                    <AppInput
                                        forceValue={invoice.invoiceDate}
                                        name={translate('billingConfig.invoice.form.date')}
                                        hint={translate('billingConfig.invoice.form.date_hint')}
                                        type={AppInputTypes.DATE}
                                        onChange={(stringValue, _date) => { actions.onChangeInvoiceField('invoiceDate', moment(stringValue, 'DD-MM-YYYY')) }}
                                        disabled={state.isLoading}
                                        hasError={state.invoiceErrors.includes('invoiceDate')}
                                    />

                                </div>
                            </div>
                            <div style={styles.formSection}>
                                <div style={{...styles.sectionTitle,...state.invoiceErrors.includes('status') && styles.titleWithError}}>{translate('billingConfig.invoice.form.status')}</div>
                                <div style={styles.inputContainer}>
                                    <AppSelector
                                        style={styles.selector}
                                        name={translate('billingConfig.invoice.form.status')}
                                        hint={translate('billingConfig.invoice.form.status_hint')}
                                        value={invoice.status}
                                        options={actions.getInvoiceStatusOptions()}
                                        onOptionSelected={value => actions.onChangeInvoiceField('status', value)}
                                        disabled={state.isLoading}
                                        hasError={state.invoiceErrors.includes('status')}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div style={styles.footer}>
                        <div style={styles.modalFooterButton}>
                            <AppButton
                                secondary={true}
                                action={() => actions.closeBillingModal()}
                                text={translate('billingConfig.invoice.modal.cancel')}
                            />
                        </div>
                        {invoice.id && <>
                            <div style={styles.modalFooterButtonRed}>
                                <AppButton
                                    redButton={true}
                                    action={() => actions.deleteHotelInvoice(invoice)}
                                    text={translate('billingConfig.invoice.modal.delete')}
                                />
                            </div>
                        </>}
                        <div style={styles.modalFooterButton}>
                            <AppButton
                                action={invoice.id ? () => actions.editInvoiceForm() : () => actions.saveInvoiceForm()}
                                text={translate('billingConfig.invoice.modal.save')}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}