function priceToString(amount, currency) {
  const currencyObject = currencyPosition(currency);
  return currencyObject.position === 'before'
    ? `${currencyObject.symbol}${amount}`
    : `${amount}${currencyObject.symbol}`;
}

function currencyPosition(currency) {
  let position = 'before';
  let symbol = '';
  switch (currency) {
    case 'USD':
    case 'CAD':
    case 'AUD':
    case 'SGD':
    case 'HKD':
    case 'MXN':
    case 'COP':
      symbol = '$';
      break;
    case 'EUR':
      symbol = '€';
      position = 'after';
      break;
    case 'GBP':
      symbol = '£';
      break;
    case 'THB':
      symbol = '฿';
      break;
    case 'CNY':
    case 'JPY':
      symbol = '¥';
      break;
    default:
      symbol = '$';
      break;
  }
  return { symbol, position };
}

export { priceToString };
