import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { AppContext } from '../../../App.context';
import { ApiContext } from '../../../utils/networking/Api.context';
import { notification } from 'antd';
import SupportedLegalTypes from './SupportedLegalTypes';

function getDefaultTemplate(type, lang) {
  try {
    const defaultText = require(`./DefaultTexts/${lang}/${type}`).default;
    return defaultText || '';
  } catch (error) {
    return '';
  }
}

export default function useLegalDocumentsHook() {
  const { API } = useContext(ApiContext);
  const { appState, appActions } = useContext(AppContext);
  const { translate, getSelectedHotel } = appActions;
  const LEGAL_TEXT_TAGS = [
    'legal_cardex',
    'legal_terms_and_conditions',
    'legal_data_privacy_policy',
    'legal_data_commercial_use',
    'legal_loyalty_program',
  ];

  const DOCUMENT_TYPE = window.location.pathname.split('/').pop();

  const [state, setState] = useState({
    isLoading: false,
    selectedHotel: null,
    legalDocuments: null,
    replacementTags: null,
    previewText: null,
    languages: null,
    selectedLanguage: null,
    //customFileName: null,
  });

  useEffect(() => {
    actions.onHotelSelected(getSelectedHotel());
  }, [appState.selectedHotel]);

  const actions = {
    loadLegalDocuments: hotelId => {
      updateState({ isLoading: true });
    
      API.legalTextsRequest(hotelId, DOCUMENT_TYPE)
        .then(data => {
          const legalDocuments = data.hotelTexts.reduce((result, document) => {
            result[document.type] = result[document.type] || [];
            result[document.type].push(document);
            return result;
          }, Object.create(null));

          const filledDocuments = actions.addMissingDocuments(legalDocuments, data?.languages);
          const orderedDocuments = actions.getOrderedTypes(filledDocuments);
          
          const lang = !state.selectedLanguage ||
          !data.languages.map(l => l.code).includes(state.selectedLanguage)
          ? data.languages[0].code
          : state.selectedLanguage;
          
          updateState({
            legalDocuments: orderedDocuments,
            replacementTags: data.tags,
            languages: data.languages,
            isLoading: false,
            //customFileName: data.customFileName,
            selectedLanguage: lang,
          });
          
          // Check for legal documents with default text
          const hasDefaultTemplate = orderedDocuments.some(documentSet =>
            documentSet.some(
              document => document.lang === lang && !document.type.includes('-label') && document.value === getDefaultTemplate(document.type, document.lang)
            )
          );

          if (hasDefaultTemplate) {
            notification.warning({
              message: translate('legalDocuments.warning'),
            });
          }
        })
        .catch(error => {
          console.warn(error);
          updateState({ isLoading: false });
        });
    },

    addMissingDocuments: (legalDocuments, languages) => {
      actions.getCurrentSupportedDocuments().forEach(type => {
        const loadedDocument = legalDocuments[type.id];
        if (!loadedDocument) {
          legalDocuments[type.id] = [];
        }
      });

      languages.forEach(lang => {
        actions.getCurrentSupportedDocuments().forEach(type => {
          const alreadyExists = legalDocuments[type.id].find(
            document => document.lang == lang.code
          );
          if (!alreadyExists) {
            const defaultValue = getDefaultTemplate(type.id, lang.code);
            legalDocuments[type.id].push({
              type: type.id,
              lang: lang.code,
              value: defaultValue,
            });
          }
        });
      });
      return legalDocuments;
    },

    getOrderedTypes: legalDocuments => {
      const orderedDocuments = [];
      const keys = [
        'custom_cardex_pdf',
        'cardex',
        'terms',
        'data-privacy-policy',
        'loyalty-program',
        'data-commercial-use',
        'data-privacy-policy-label',
        'data-commercial-use-label',
        'loyalty-program-label',
      ];

      for (const key of keys) {
        if (legalDocuments[key]) {
          orderedDocuments.push(legalDocuments[key]);
        }
      }

      return orderedDocuments;
    },

    updateLegalText: (type, value, label, applyToAllHotels, newHotel, lang) => {
      let hotelId;
      if (newHotel) {
        hotelId = newHotel;
      } else {
        hotelId = !applyToAllHotels ? state.selectedHotel?.key : null;
      }
      
      if (!newHotel) {
        lang = state.selectedLanguage
      }

      if (!value.endsWith('.')) {
        value = value + ' ';
      }
      
      API.updateLegalTextRequest({
        hotelId,
        type,
        value,
        lang,
        label: label,
        //customFileName: state.customFileName,
      })
        .then(_ => {
          if (!newHotel) {
            notification.success({
              message: translate('legalDocuments.success'),
            });
          }

          if (state.selectedHotel) {
            const legalDocumentsCopy = Object.assign([], state.legalDocuments);

            actions.updateDocumentsLocally(legalDocumentsCopy, type, value, label);
            actions.onPreviewPressed(value);

            updateState({ isLoading: false, legalDocuments: legalDocumentsCopy });
          } else {
            updateState({ isLoading: false });
          }
        })
        .catch(err => {
          if (!newHotel) {
            notification.error({
              message: translate('legalDocuments.error'),
            });
          }
          updateState({ isLoading: false });
        });
    },

    updateDocumentsLocally: (legalDocumentsCopy, type, value, label) => {
      const modifiedSet = legalDocumentsCopy.find(set => set[0].type == type);
      actions.updateLocalSet({ documentsSet: modifiedSet, type, value });

      const modifiedLabelSet = legalDocumentsCopy.find(set => set[0].type == `${type}-label`);
      if (modifiedLabelSet) {
        actions.updateLocalSet({
          documentsSet: modifiedLabelSet,
          type: `${type}-label`,
          value: label,
        });
      }
    },

    updateLocalSet: ({ documentsSet, type, value }) => {
      const modifiedLabelDocument = documentsSet?.find(
        document => document.lang == state.selectedLanguage
      );

      if (modifiedLabelDocument) {
        modifiedLabelDocument.value = value;
      } else {
        documentsSet.push({
          type,
          lang: state.selectedLanguage,
          value: value,
        });
      }
    },

    onHotelSelected: hotel => {
      updateState({ selectedHotel: hotel });
      if (hotel && hotel?.key != 'null') {
        actions.loadLegalDocuments(hotel?.key);
      }
    },

    onPreviewPressed: htmlText => {
      if (state.replacementTags) {
        Object.keys(state.replacementTags).forEach(key => {
          htmlText = htmlText.replaceAll(`[${key}]`, state.replacementTags[key]);
        });

        LEGAL_TEXT_TAGS.forEach(legalTag => {
          let type = legalTag.replace('legal_', '').replaceAll('_', '-');
          if (type === 'terms-and-conditions') type = 'terms';

          const documentSet = state.legalDocuments.find(set => set[0].type == type);
          const document = documentSet?.find(doc => doc.lang == state.selectedLanguage);
          if (document?.value) {
            if (type === 'terms') type = 'terms-and-conditions';
            htmlText = htmlText.replaceAll(`[legal_${type.replaceAll('-', '_')}]`, document?.value);
          }
        });
      }

      updateState({ previewText: htmlText });
    },

    onSavePressed: (type, value, label, applyToAllHotels) => {
      actions.updateLegalText(type, value, label, applyToAllHotels);
    },

    onLanguageSelected: lang => {
      updateState({ selectedLanguage: lang });

      const isDefaultText = state.legalDocuments.some(documentSet => {
        const document = documentSet.find(doc => doc.lang === lang && !doc.type.includes('-label'));
        if (document && document.value === getDefaultTemplate(document.type, lang)) {
          return true;
        }
        return false;
      });

      if (isDefaultText) {
        notification.warning({
          message: translate('legalDocuments.warning'),
        });
      }

      updateState({ legalDocuments: state.legalDocuments });
    
      state.legalDocuments.map(documentSet => {
        const document = documentSet.find(doc => doc.lang === lang);

        // If the document exists
        if (document) {
          // If it has an empty value, we leave it empty
          if (document.value === '') {
            return [document];
          }
          // If it does not have a value, we assign the default text
          if (!document.value) {
            const defaultValue = getDefaultTemplate(document.type, lang);
            document.value = defaultValue;
          }
          return [document];
        } else {
          // If not found, set default text
          const defaultValue = getDefaultTemplate(documentSet[0].type, lang);
          return [
            {
              type: documentSet[0].type,
              lang: lang,
              value: defaultValue,
            },
          ];
        }
      });
    },    

    getCurrentSupportedDocuments: () => {
      return SupportedLegalTypes.filter(
        document => document.id === DOCUMENT_TYPE || document.id === `${DOCUMENT_TYPE}-label`
      );
    },

    // onCustomNameChange: name => {
    //   updateState({ customFileName: name });
    // },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}