import Colors from 'constants/Colors';

const title = {
  color: Colors.darkText,
  fontSize: 12,
  fontWeight: 'bold',
};

export default {
  wrapper: {
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 8,
    paddingBottom: 32,
  },
  header: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    marginTop: 8,
  },
  container: {
    flex: 1,
    display: 'flex',
    height: '100%',
    gap: 24,
  },
  leftColumn: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  rightColumn: {
    flex: 1,
    display: 'flex',
    gap: 8,
    flexDirection: 'column',
  },
  title: title,
  editorHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  testButton: {
    marginBottom: 8,
    color: Colors.white,
  },
  emailTypeContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    marginBottom: 8,
  },
  emailTypeTitle: {
    ...title,
    marginRight: 8,
    marginBottom: 4,
  },
  emailTypeCheckboxContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'left',
  },
  checkbox: {
    color: Colors.darkText,
    fontSize: 12,
  },
};
