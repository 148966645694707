import React, { useContext } from 'react';
import useGuestlinkConfigHook from './GuestlinkConfig.hook.js';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout.jsx';
import { AppContext } from 'App.context.js';
import ContentBox from 'Components/common/ContentBox/ContentBox.jsx';
import { GuestlinkConfigContext } from './GuestlinkConfig.context.js';
import SmtpConfig from './SmtpConfig/SmtpConfig.jsx';
import RequestSuscription from '../../../common/RequestSuscription/RequestSuscription.jsx';

export default function GuestlinkConfig() {
  const { state, actions } = useGuestlinkConfigHook();
  const {
    appActions: { translate },
  } = useContext(AppContext);
  return (
    <DefaultLayout>
      <GuestlinkConfigContext.Provider value={{ state, actions }}>
        <ContentBox singleBox>
          {state.suscription ? (
            <SmtpConfig />
          ) : (
            <RequestSuscription suscription='guestlink' />
          )}
        </ContentBox>
      </GuestlinkConfigContext.Provider>
    </DefaultLayout>
  );
}
