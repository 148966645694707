import React, { useMemo, useContext } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';

import { AppContext } from 'App.context';
import constants from 'utils/constants';

const { RangePicker } = DatePicker;

export default function CustomRangePicker(props) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const defaultStartDate = moment().subtract(30, 'days');
  const defaultEndDate = moment();

  const ranges = useMemo(
    () => ({
      [translate('upsell.last7Days')]: [moment().subtract(7, 'days'), moment()],
      [translate('upsell.lastMonth')]: [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
      [translate('upsell.last30Days')]: [defaultStartDate, defaultEndDate],
    }),
    []
  );

  return (
    <RangePicker ranges={ranges} {...props} allowClear={false} format={constants.dateFormat} />
  );
}
