import React from 'react';
import styles from './FormRow.styles';
import TitleComponent from 'Sell/Components/Upsert/TitleComponent/Title.jsx';

export default function FormRow({ title, children, style }) {
  return (
    <div style={styles.rowContainer}>
      <TitleComponent text={title}></TitleComponent>
      <div style={style || styles.inputRow}>{children}</div>
    </div>
  );
}
