import React, { useContext } from 'react';
import AppTextInput from 'Components/common/AppTextInput/AppTextInput';
import AppButton from 'Components/common/Forms/AppButton/AppButton';
import createPaymentMethodRequest from 'utils/networking/api/createPaymentMethod.service';
import { AppContext } from 'App.context';
import InfoView from 'Components/common/InfoView/InfoView';
import { CreditCardOutlined } from '@ant-design/icons';

export default function PaymentMethods({ paymentMethod, userId }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  function addPaymentMethod() {
    createPaymentMethodRequest({ userId })
      .then(res => (window.location.href = res.registerPaymentMethodUrl))
      .catch(error => console.error(error));
  }

  return (
    <div>
      {paymentMethod && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <img
            src={getLogoFromType(paymentMethod.type)}
            style={{ alignSelf: 'center', height: 36 }}
          />
          <AppTextInput
            name={getNameFromType(translate, paymentMethod.type)}
            value={getValueFromType(translate, paymentMethod)}
            disabled={true}
          />
        </div>
      )}
      {!paymentMethod && (
        <div>
          <InfoView
            icon={<CreditCardOutlined />}
            title={translate('paymentMethods.emptyTitle')}
            text={translate('paymentMethods.emptyDescription')}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'right',
              flex: 1,
              marginTop: 32,
            }}
          >
            <AppButton text={translate('add')} action={() => addPaymentMethod()} />
          </div>
        </div>
      )}
    </div>
  );
}

function getLogoFromType(type) {
  switch (type) {
    case 'card':
      return require('assets/images/payment-methods/credit-card.png');
    case 'sepa_debit':
      return require('assets/images/payment-methods/sepa.png');
  }
}

function getNameFromType(translate, type) {
  switch (type) {
    case 'card':
    default:
      return translate('paymentMethods.credit_card');
    case 'sepa_debit':
      return translate('paymentMethods.sepa');
  }
}

function getValueFromType(translate, paymentMethod) {
  switch (paymentMethod.type) {
    case 'card':
    default:
      return `${paymentMethod?.brand?.toUpperCase()} ···· ${paymentMethod.last4}    ${translate(
        'paymentMethods.expires'
      )} ${paymentMethod.exp_month}/${paymentMethod.exp_year}`;
    case 'sepa_debit':
      return `SEPA ···· ${paymentMethod.last4}`;
  }
}
