import React, { useContext } from 'react';
import { AppContext } from 'App.context.js';
import { BillingListingContext } from '../BillingListing.context';
import styles from '../BillingModal/BillingModal.styles';
import AppButton from 'Components/common/Forms/AppButton/AppButton';
import AppInput from 'Components/common/AppInput/AppInput.jsx';
import AppInputTypes from 'Components/common/AppInput/AppInputTypes.js';
import Loading from 'Components/common/Loader/Loading';
import { CloudUploadOutlined } from '@ant-design/icons';
import { Upload, DatePicker } from 'antd';
import moment from 'moment';

const { Dragger } = Upload;

export default function BillingContractForm() {
    const {
        appActions: { translate },
    } = useContext(AppContext);

    const { contract, state, actions } = useContext(BillingListingContext);
    
    const contractIcon = require('assets/images/billing/contract-modal-icon.png');
    
    return (
        <div>
            {state.isLoading ? (
              <Loading />
            ) : (
                <>
                    <div style={styles.header}>
                        <div>
                            <img src={contractIcon} alt="modal-contract" />
                        </div>
                        <div style={styles.headerTitle}>{translate('billingConfig.billingContract.modal.title_contract')}</div>
                    </div>
                    <div style={styles.body}>
                        
                        <div style={styles.formData}>
                            <div style={styles.formSection}>
                                <div style={{...styles.sectionTitle,...state.contractErrors.includes('file') && styles.titleWithError}}>{translate('billingConfig.billingContract.uploadContract')}</div>
                                <div style={styles.dragger}>
                                    <Dragger {...actions.getUploadContractProps()} maxCount={1} disabled={state.isLoading}>
                                        <p className="ant-upload-drag-icon">
                                            <CloudUploadOutlined />
                                        </p>
                                        <p className="ant-upload-text">{translate('billingConfig.billingContract.fileUpload.title')}</p>
                                        <p className="ant-upload-hint">{translate('billingConfig.billingContract.fileUpload.hint')}</p>
                                    </Dragger>
                                </div>
                            </div>
                        </div>
                        
                        <div style={styles.formData}>
                            <div style={styles.formSection}>
                                <div style={{...styles.sectionTitle,...state.contractErrors.includes('name') && styles.titleWithError}}>{translate('billingConfig.billingContract.contractName')}</div>
                                <div style={styles.inputContainer}>
                                    <AppInput
                                        value={contract.name}
                                        name={translate('billingConfig.billingContract.contractName')}
                                        hint={translate('billingConfig.billingContract.contractName_hint')}
                                        onChange={value => actions.onChangeContractField('name', value)}
                                        disabled={state.isLoading}
                                        hasError={state.contractErrors.includes('name')}
                                    />
                                </div>
                            </div>
                            <div style={styles.formSection}>
                                <div style={{...styles.sectionTitle,...state.contractErrors.includes('signatureDate') && styles.titleWithError}}>{translate('billingConfig.billingContract.signatureDate')}</div>
                                <div style={styles.inputContainer}>
                                    <AppInput
                                        forceValue={contract.signatureDate}
                                        name={translate('billingConfig.billingContract.signatureDate')}
                                        hint={translate('billingConfig.billingContract.signatureDate_hint')}
                                        type={AppInputTypes.DATE}
                                        onChange={(stringValue, _date) => { actions.onChangeContractField('signatureDate', moment(stringValue, 'DD-MM-YYYY')) }}
                                        disabled={state.isLoading}
                                        hasError={state.contractErrors.includes('signatureDate')}
                                    />

                                </div>
                            </div>
                        </div>

                        <div style={styles.formData}>
                            <div style={styles.formSection}>
                                <div style={{...styles.sectionTitle,...state.contractErrors.includes('orderNumber') && styles.titleWithError}}>{translate('billingConfig.billingContract.orderNumber')}</div>
                                <div style={styles.inputContainer}>
                                    <AppInput
                                        value={contract.orderNumber}
                                        name={translate('billingConfig.billingContract.orderNumber')}
                                        hint={translate('billingConfig.billingContract.orderNumber_hint')}
                                        onChange={value => actions.onChangeContractField('orderNumber', value)}
                                        disabled={state.isLoading}
                                        hasError={state.contractErrors.includes('orderNumber')}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div style={styles.footer}>
                        <div style={styles.modalFooterButton}>
                            <AppButton
                                secondary={true}
                                action={() => actions.closeBillingModal()}
                                text={translate('billingConfig.billingContract.modal.cancel')}
                            />
                        </div>
                        {contract.id && <>
                            <div style={styles.modalFooterButtonRed}>
                                <AppButton
                                    redButton={true}
                                    action={() => actions.deleteHotelContract(contract)}
                                    text={translate('billingConfig.billingContract.modal.contract.delete')}
                                />
                            </div>
                        </>}
                        <div style={styles.modalFooterButton}>
                            <AppButton
                                action={contract.id ? () => actions.editContractForm() : () => actions.saveContractForm()}
                                text={translate('billingConfig.billingContract.modal.contract.save')}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}