import axios from 'axios';
import apiConfig from '../api.config';

export default function postSendPoliceReport({ data }) {
  const {
    policeReportHost,
    URIs: { sendPoliceReport },
  } = apiConfig;

  const uri = `${policeReportHost}${sendPoliceReport.replace(':guestId', data.id)}`;

  return axios
    .put(uri, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
