import { get } from 'lodash';

function translate(literals, key) {
  let translation = key;
  try {
    translation = get(literals, key, key);
  } catch (e) {
    console.warn('No translation found for', key);
  }
  return translation;
}

export { translate };
