import axios from 'axios';
import apiConfig from '../../api.config';

export default function registerRequest({ email, password, name, phone, chain_name, lang }) {
  const {
    host,
    URIs: { auth },
  } = apiConfig;

  const uri = `${host}${auth.register}`;

  return axios
    .post(uri, JSON.stringify({ email, password, name, phone, chain_name, lang }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
