export default `<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:21px;font-family:&quot;Calibri&quot;,sans-serif;">Términos y condiciones&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:18px;font-family:&quot;Calibri&quot;,sans-serif;">INFORMACIÓN LEGAL&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;">
    <span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">
        Para su seguridad, garantizamos que los datos de su tarjeta de crédito serán tratados conforme a los estándares internacionales PCI-DSS. Autorizo al hotel a cargar los gastos totales de mi estancia en el hotel a la tarjeta de crédito mencionada anteriormente. En caso de que me retire del hotel en una fecha anterior a la indicada en la reserva que figura en este documento, autorizo a que el importe total de las noches contratadas, incluidas las no utilizadas, sea cargado a la tarjeta de crédito.<br><br>
        En [hotel_name], estamos comprometidos con la protección de su privacidad. Puede contactarnos en [address] o en el correo electrónico [hotel_email]. [hotel_name] procesará sus datos para gestionar su estancia en el hotel y mejorar su experiencia como huésped. Además, [hotel_name] procesará sus datos para proporcionar servicios de alojamiento y servicios complementarios. Podríamos necesitar procesar sus datos para cumplir con las obligaciones legales a las que estamos sujetos (incluida la prevención de fraude). [hotel_name] podrá comunicar sus datos a terceros, como bancos u otras instituciones financieras para procesar pagos o a agencias de seguridad para cumplir con las obligaciones impuestas por la ley.<br><br>
        Si se inscribe, [hotel_name] procesará sus datos personales para ofrecerle los servicios correspondientes de lealtad. En este sentido, procesaremos los puntos generados y es posible que le enviemos información relacionada con sus puntos y los beneficios del programa, ajustados a su perfil.<br><br>
        Puede ejercer sus derechos de acceso, rectificación, supresión, oposición y/o limitación del tratamiento, así como la portabilidad de sus datos, escribiendo a [hotel_name] en la dirección postal o de correo electrónico indicada anteriormente. Deberá acreditar su identidad e indicar en ambos casos (a) "Protección de Datos" como asunto y (b) si su solicitud va dirigida a [hotel_name]. Además, puede ponerse en contacto en cualquier momento con la autoridad nacional de supervisión competente.<br><br>
        Para más información, ingrese en [hotel_web] o consulte en la recepción del hotel.
    </span>
</p>`;
