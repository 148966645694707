import axios from 'axios';
import apiConfig from 'utils/networking/api.config';

export default function doHealthCheckBooking(hotelId, bookingData) {
  const {
    validationsHost,
    URIs: {
      validation: { validations },
    },
  } = apiConfig;

  const uri = `${validationsHost}${validations.onboarding}`.replace(':hotelId', hotelId);

  return axios
    .post(
      uri,
      JSON.stringify({
        search: bookingData,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          auth: 'cbmL!K4tns.DorqF1PyGHc^%r/#qAH',
        },
      }
    )
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.error('Error booking search:', error);
      return null;
    });
}
