import { useState, useRef } from 'react';

export default function useEditableTagHook({ tags, onTagsChange, max }) {
  const [state, setState] = useState({
    inputVisible: false,
    inputValue: '',
    editInputIndex: -1,
    editInputValue: '',
  });

  const INPUT_REF = useRef(null);
  const EDIT_INPUT_REF = useRef(null);

  const actions = {
    handleClose: removedTag => {
      const tagsCopy = tags.filter(tag => tag !== removedTag);
      onTagsChange(tagsCopy);
    },

    showInput: () => {
      updateState({ inputVisible: true });
      // INPUT_REF.current.focus();
    },

    handleInputChange: e => {
      updateState({ inputValue: e.target.value });
    },

    handleInputConfirm: () => {
      const inputValue = state.inputValue;
      let tagsCopy = tags;

      if (inputValue && tagsCopy.indexOf(inputValue) === -1) {
        tagsCopy = [...tagsCopy, inputValue];
      }

      onTagsChange(tagsCopy);
      updateState({
        inputVisible: false,
        inputValue: '',
      });
    },

    handleEditInputChange: e => {
      updateState({ editInputValue: e.target.value });
    },

    handleEditInputConfirm: () => {
      const newTags = [...tags];
      newTags[state.editInputIndex] = state.editInputValue;

      onTagsChange(newTags);
      updateState({
        editInputIndex: -1,
        editInputValue: '',
      });
    },

    onDoubleClick: (index, tag, event) => {
      updateState({
        editInputIndex: index,
        editInputValue: tag,
      });

      // EDIT_INPUT_REF.current.focus();
      event.preventDefault();
    },

    saveInputRef: ref => {
      INPUT_REF.current = ref;
    },

    saveEditInputRef: ref => {
      EDIT_INPUT_REF.editInput = ref;
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
