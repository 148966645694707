import axios from 'axios';
import apiConfig from '../api.config';

export default function pmsConfigRequest({ pmsId, hotelId, config }) {
  const {
    host,
    URIs: { pmsConfigDynamicForm },
  } = apiConfig;

  const uri = `${host}${pmsConfigDynamicForm.replace(':pmsId', pmsId)}`;

  return axios
    .get(uri, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
