import Colors from '../../../constants/Colors';

export default {
  container: {
    borderRadius: 6,
    paddingRight: 8,
    paddingLeft: 8,
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '600',
  },
};
