export const itemsList = '/items';
export const itemDetail = '/items/view/:id';
export const itemCreate = '/items/create';
export const itemUpsert = '/items/upsert/:id';

export const crossSellsList = '/cross-sells';
export const crossSellDetail = '/cross-sells/view/:id';

export const reports = '/reports';

export default {
  itemsList,
  itemDetail,
  itemCreate,
  itemUpsert,
  crossSellsList,
  crossSellDetail,
  reports,
};
