import React, { useContext } from 'react';
import styles from './SaveButton.styles';
import useSaveButtonHook from './SaveButton.hook';
import { AppContext } from '../../../App.context';
import CustomRadioButton from '../CustomRadioButton/CustomRadioButton';
import AppButton from '../Forms/AppButton/AppButton';
import { Popconfirm } from 'antd';

export default function SaveButton({
  text,
  action,
  enableApplyToChain = true,
  enableAllHotels = false,
  showPopConfirm = true,
  ...props
}) {
  const { appState, appActions } = useContext(AppContext);
  const { state, actions } = useSaveButtonHook(enableAllHotels);

  return appState?.hotelList && appState?.hotelList.length ? (
    <div style={styles.container}>
      <div style={styles.row}>
        {enableApplyToChain && (
          <div style={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
            <CustomRadioButton
              value={!state.applyToAllHotels}
              text={appActions.translate('hotelSelector.onlyTo')}
              subtitle={actions.getSelectedHotelLabel()}
              onClick={() => actions.setApplyToCurrentHotel()}
            />
            <CustomRadioButton
              value={state.applyToAllHotels}
              text={appActions.translate('hotelSelector.applyToAllHotels')}
              onClick={() => actions.setApplyToAllHotels()}
            />
          </div>
        )}
      </div>
      <Popconfirm
        visible={state.showPopConfirm}
        title={appActions.translate('guestTable.applyToAllWarning')}
        onConfirm={() => {
          actions.setShowPopConfirm(false);
          action && action(state.applyToAllHotels);
        }}
        onCancel={() => actions.setShowPopConfirm(false)}
        okText={appActions.translate('ok')}
        cancelText={appActions.translate('cancel')}
        placement="topRight"
      >
        <AppButton
          text={text}
          action={action}
          setShowPopConfirm={showPopConfirm && actions.setShowPopConfirm}
          applyToAllHotels={state.applyToAllHotels}
          {...props}
        ></AppButton>
      </Popconfirm>
    </div>
  ) : null;
}
