import { useQuery } from 'react-query';
import { useContext } from 'react';
import { AppContext } from 'App.context';
import { reports, counters } from 'Sell/Upsell/API/api.config';

export const useReportsQuery = (filters, selectedHotel) => {
  const { appActions } = useContext(AppContext);
  const lang = appActions.getSelectedLanguage();
  const defaultFilters = { lang: lang.id };
  return useQuery(['reports', JSON.stringify(filters), selectedHotel], () =>
    reports({ ...defaultFilters, ...filters })
  );
};

export const useCountersQuery = (filters, selectedHotel) => {
  const defaultFilters = {};

  return useQuery(['counters', JSON.stringify(filters), selectedHotel], () =>
    counters({ ...defaultFilters, ...filters })
  );
};
