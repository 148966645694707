export default {
  cardContainer: isSelected => ({
    background: 'var(--grayscale-white, #FFF)',
    borderRadius: 8,
    border: isSelected
      ? '1px solid var(--your-selected-color, #1890ff)'
      : '1px solid var(--grayscale-divider, #DFE0EB)',
    flex: 1,
  }),
  container: {
    height: 174,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 15.5,
  },
  text: isSelected => ({
    fontSize: 19,
    fontWeight: 700,
    letterSpacing: 0.4,
    textAlign: 'center',
    color: isSelected ? 'var(--main-blue, #3751FF)' : 'black',
  }),
  number: isSelected => ({
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 40,
    letterSpacing: 1,
    color: isSelected ? 'var(--main-blue, #3751FF)' : 'black',
  }),
};
