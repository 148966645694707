import React from 'react';
import styles from './PoliceReportSideMenuItem.styles.js';

export default function PoliceReportSideMenuItem({
  title,
  indicatorColor,
  header,
  number,
  desc,
  icon,
  children,
}) {
  return (
    <div style={styles.sideMenuItem}>
      {title && <div style={styles.sideItemTitle}>{title}</div>}
      <div style={styles.sideItemContent}>
        {indicatorColor && (
          <div style={{ ...styles.sideItemIndicator, backgroundColor: indicatorColor }} />
        )}
        {icon && icon}
        {number && <div style={styles.sideItemNumber}>{number}</div>}
        {header && <div style={styles.sideItemHeader}>{header}</div>}
        {desc && <div style={styles.desc}>{desc}</div>}
        {children && <div style={{ marginTop: 8 }}>{children}</div>}
      </div>
    </div>
  );
}
