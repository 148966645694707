import Colors from 'constants/Colors';

export default {
  mainContainer: {
    width: '100%',
    paddingRight: 32,
    paddingLeft: 32,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    color: Colors.titleColor,
    fontWeight: 'bold',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  tableContainer: {
    display: 'flex',
    width: '100%',
    flex: 1,
    marginTop: 16,
    marginBottom: 32,
  },
  table: {
    backgroundColor: 'white',
    marginLeft: 32,
    marginRight: 32,
    width: '100%',
    borderRadius: 14,
    padding: 14,
    minHeight: '70vh',
  },
  id: {
    color: '#2D5BFF',
    background: 'var(--light-greyscale-bg-darken, #F8F8F8)',
    padding: '10px 12px',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 700,
  },
  status: status => {
    let backgroundColor;
    let color;
    switch (status) {
      case 'rejected':
        backgroundColor = '#FFECEC';
        color = '#E03F5C';
        break;
      case 'pending':
        backgroundColor = '#FFF0CA';
        color = '#DDB03E';
        break;
      default:
        backgroundColor = '#C2CEFF';
        color = '#2D5BFF';
        break;
    }
    return {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px 12px',
      borderRadius: 8,
      backgroundColor,
      color,
      fontWeight: 700,
      textTransform: 'capitalize',
    };
  },
  toApproved: {
    color: '#69DDC1',
  },
  toRejected: {
    color: '#F98189',
  },
  seeDetails: {
    color: '#2D5BFF',
  },
  price: {
    color: '#2D5BFF',
    border: '1px solid #2D5BFF',
    borderRadius: 4,
    padding: '10px 30px',
    fontWeight: '700',
  },
};
