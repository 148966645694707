import React from 'react';
import { Layout } from 'antd';
import styles from './WelcomeLayout.module.css';
import WelcomeHeader from './WelcomeHeader/WelcomeHeader';
import LanguageSelector from '../../LanguageSelector/LanguageSelector';
import welcomeBg from '../../../../assets/images/welcomeBackground.png';
import { LoginOutlined } from '@ant-design/icons';

export default function WelcomeLayout({ children, title, showBack, background, headerLogo }) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <LanguageSelector />
      </div>

      <Layout
        className={styles.layout}
        style={{ backgroundImage: background ? `url(${background})` : `url(${welcomeBg})` }}
      >
        <div className={styles.content}>
          <WelcomeHeader title={title} showBack={showBack} headerLogo={headerLogo} />
          {children}
        </div>
      </Layout>
    </div>
  );
}
