import React from 'react';
import { Typography, Card } from 'antd';
import styles from './Contador.styles';

const { Text } = Typography;

export default function Contador({ title, number, isSelected, onClick }) {
  return (
    <Card onClick={onClick} hoverable style={styles.cardContainer(isSelected)}>
      <div style={styles.container}>
        <Text style={styles.text(isSelected)} type="secondary">
          {title}
        </Text>
        <Text style={styles.number(isSelected)}>{number}</Text>
      </div>
    </Card>
  );
}
