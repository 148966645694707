import React from 'react';
import { CopyOutlined, EyeOutlined } from '@ant-design/icons';
import AppButton from 'Components/common/Forms/AppButton/AppButton';
import HotelPmsIDInput from './HotelPmsIDInput/HotelPmsIDInput';
import IPWhitelistInput from './IPWhitelistInput/IPWhitelistInput';
import styles from './PaperlessPush.styles';
import { Tooltip } from 'antd';
import Colors from 'constants/Colors';

function getPaperlessPushColumns({
  translate,
  setPMSId,
  setIpWhitelist,
  isValidIpList,
  getExampleLink,
  goToAccountDetail,
}) {
  return [
    {
      title: translate('hotel'),
      dataIndex: 'hotel',
      key: 'hotel',
      searchable: true,
      sorter: false,
      render: (hotel, item) => {
        return (
          <div
            style={{
              ...styles.rowStyle,
              color: isEnabled(item) ? Colors.darkText : Colors.grayText,
            }}
          >
            {hotel}
          </div>
        );
      },
    },
    {
      title: translate('paperlessPush.pmsId'),
      dataIndex: 'pmsId',
      key: 'pmsId',

      render: (_, item) => {
        return (
          <div style={styles.rowStyle}>
            <HotelPmsIDInput
              disabled={!isEnabled(item)}
              key={item.hotelId}
              item={item}
              setPMSId={setPMSId}
            />
          </div>
        );
      },
    },
    {
      title: translate('paperlessPush.ipWhitelist'),
      dataIndex: 'ipWhitelist',
      key: 'ipWhitelist',
      render: (_, item) => {
        return (
          <div style={styles.rowStyle}>
            <IPWhitelistInput
              disabled={!isEnabled(item)}
              key={item.hotelId}
              item={item}
              setIpWhitelist={setIpWhitelist}
              isValidIpList={isValidIpList}
            />
          </div>
        );
      },
    },
    {
      title: translate('paperlessPush.apiKey'),
      dataIndex: 'apiKey',
      key: 'apiKey',
      align: 'center',

      render: (apiKey, item) => {
        return isEnabled(item) ? (
          <div style={styles.rowStyle}>
            <div style={styles.btnContainer}>
              <div style={{ alignSelf: 'center' }}>
                <AppButton
                  disabled={!isEnabled(item)}
                  action={() => navigator.clipboard.writeText(apiKey)}
                  secondayIcon={<CopyOutlined style={styles.copyIcon} />}
                  secondary
                />
              </div>
            </div>
          </div>
        ) : null;
      },
    },
    {
      title: translate('paperlessPush.example'),
      dataIndex: 'hotel',
      key: 'hotel',
      align: 'center',
      render: (_, item) => {
        return (
          <div style={styles.rowStyle}>
            <div style={styles.btnContainer}>
              <Tooltip
                title={getExampleLink(item)}
                trigger={isEnabled(item) ? 'click' : ''}
                color={Colors.disableButton}
                key={Colors.disableButton}
              >
                <div>
                  {isEnabled(item) ? (
                    <AppButton secondayIcon={<EyeOutlined style={styles.exampleIcon} />} />
                  ) : (
                    <AppButton
                      text={translate('paperlessPush.activateSuscription')}
                      secondary
                      action={goToAccountDetail}
                    />
                  )}
                </div>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];
}

function isEnabled(item) {
  return item?.suscription?.active === 1;
}

export default { getPaperlessPushColumns };
