export default `<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:21px;font-family:&quot;Calibri&quot;,sans-serif;">Termes et conditions&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:18px;font-family:&quot;Calibri&quot;,sans-serif;">INFORMATIONS LÉGALES&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;">
    <span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">
        Pour votre sécurité, nous garantissons que les données de votre carte de crédit seront traitées conformément aux normes internationales PCI-DSS. J'autorise l'hôtel à facturer les dépenses totales de mon séjour à l'hôtel à la carte de crédit mentionnée ci-dessus. Si je quitte l'hôtel à une date antérieure à celle indiquée dans la réservation figurant sur ce document, j'autorise que le montant total des nuits réservées, y compris celles non utilisées, soit facturé à la carte de crédit.<br><br>
        Chez [hotel_name], nous sommes engagés à protéger votre vie privée. Vous pouvez nous contacter à [address] ou à l'adresse e-mail [hotel_email]. [hotel_name] traitera vos données pour gérer votre séjour à l'hôtel et améliorer votre expérience en tant qu'invité. De plus, [hotel_name] traitera vos données pour fournir des services d'hébergement et des services complémentaires. Nous pourrions avoir besoin de traiter vos données pour respecter les obligations légales auxquelles nous sommes soumis (y compris la prévention de la fraude). [hotel_name] pourra communiquer vos données à des tiers, tels que des banques ou d'autres institutions financières pour traiter les paiements ou à des agences de sécurité pour se conformer aux obligations imposées par la loi.<br><br>
        Si vous vous inscrivez, [hotel_name] traitera vos données personnelles pour vous fournir les services de fidélité correspondants. À cet égard, nous traiterons les points générés et nous pourrions vous envoyer des informations concernant vos points et les avantages du programme, adaptés à votre profil.<br><br>
        Vous pouvez exercer vos droits d'accès, de rectification, de suppression, d'opposition et/ou de limitation du traitement, ainsi que le droit à la portabilité de vos données, en écrivant à [hotel_name] à l'adresse postale ou e-mail indiquée ci-dessus. Vous devez prouver votre identité et indiquer dans les deux cas (a) "Protection des données" comme objet et (b) si votre demande est adressée à [hotel_name]. De plus, vous pouvez contacter à tout moment l'autorité nationale de contrôle compétente.<br><br>
        Pour plus d'informations, veuillez consulter [hotel_web] ou vous renseigner à la réception de l'hôtel.
    </span>
</p>`;
