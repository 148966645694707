import Colors from 'constants/Colors';

export default {
  boxSize: {},
  formData: {
    display: 'flex',
    gap: 16,
    marginTop: 16,
    marginBottom: 16,
  },
  formSection: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'right',
    flex: 1,
    marginTop: 16,
    gap: 16,
  },
  inputContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    gap: 8,
    marginTop: 8,
  },
  sectionTitleWithErrors: {
    color: 'red',
  },
  saveButtonTransparent: {
    color: '#979797',
    backgroundColor: '#F4F4F4',
  },
  titleWithError: {
    color: 'red',
  },
  errorSubmessage: {
    color: 'rgba(172, 21, 24, 1)',
    fontSize: 12,
  },
  sectionTitle: {
    color: 'black',
    fontWeight: 500,
  },
  selector: {
    display: 'flex',
    flex: '1 1 100%',
    borderRadius: 6,
    backgroundColor: Colors.inputBg,
  },
  companyCountrySelector: {
    display: 'flex',
    flex: '1 1 100%',
    borderRadius: 6,
    backgroundColor: Colors.inputBg,
    padding: 4,
  },
  inputCompanyCountry: {
    border: 'none',
    backgroundColor: Colors.inputBg,
    outline: 'none',
    padding: '3px 3px 3px 12px',
  },
};
