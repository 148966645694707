import React from 'react';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout';

export default function Support() {
  return (
    <DefaultLayout>
      <div>Support</div>
    </DefaultLayout>
  );
}
