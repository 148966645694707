import { useEffect, useReducer } from 'react';
import { notification } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {
  useItemMutation,
  useItemSlotMutation,
  useGetItemQuery,
} from 'Sell/CrossSell/Item/Item.service';
import { actionTypes, itemReducer } from './reducer';
import { useCrossSellHistory } from 'Sell/CrossSell/Router/useHistory';
import { useApp } from 'Sell/hooks/useApp';
import { useImageUploader } from 'Sell/hooks/useImageUploader';
import { mapTextsToArray, mapTextsToObject } from 'Sell/utils/upsert';
import { useAppConfigQuery } from 'Sell/CrossSell/API/queries';
import { getInitialState, mapExistingDay, syncSlots } from './utils';
import { days } from 'Sell/CrossSell/utils';
import { itemUpsert } from 'Sell/CrossSell/Router/routes';

const useItemManager = () => {
  const params = useParams();
  const history = useCrossSellHistory();
  const { appActions, appState } = useApp();
  const selectedHotel = appActions.getSelectedHotel();
  const { translate } = appActions;
  const { files, addFiles, upload } = useImageUploader();
  const itemId = params.id;
  const { isLoading, data: itemData, remove } = useGetItemQuery({ id: itemId });
  const existingItem = itemData?.data;
  const { data: appConfig, isLoading: isAppConfigLoading } = useAppConfigQuery();
  const { mutateAsync: mutateItem, isLoading: isMutationLoading } = useItemMutation({
    onSuccess: remove,
  });
  const { mutateAsync: mutateSlot } = useItemSlotMutation({ onSuccess: remove });
  const [state, dispatch] = useReducer(
    itemReducer,
    getInitialState({
      hotelId: selectedHotel.id,
      chain: appState.chain,
      language: appState.currentLanguage.id,
    })
  );

  useEffect(() => {
    if (existingItem) {
      const item = {
        ...existingItem,
        texts: mapTextsToObject(existingItem.texts),
        slot: getInitialState().slot,
        slots: existingItem.slots.map(slot => {
          const departureDays = slot.days.filter(day => !day.isReturn);
          const returnDays = slot.days.filter(day => day.isReturn);

          return {
            ...slot,
            startDate: moment(slot.startDate),
            endDate: moment(slot.endDate),
            texts: mapTextsToObject(slot.texts),
            days: days.reduce(mapExistingDay(departureDays), []),
            returnDays: days.reduce(mapExistingDay(returnDays, { isReturn: true }), []),
          };
        }),
      };

      dispatch({ type: actionTypes.BASIC_UPDATE, payload: item });
    }
  }, [existingItem]);

  const updateState = nextState => {
    dispatch({ type: actionTypes.BASIC_UPDATE, payload: nextState });
  };

  const handleSubmit = async () => {
    if (!validateForm(state, appActions.translate)) {
      return;
    }
    const uploadedImages = await upload();

    const { slot, ...nextItem } = state;

    const slotsToMap = syncSlots({ slot, slots: state.slots });

    const nextSlots = slotsToMap.map(s => ({
      ...s,
      texts: mapTextsToArray(s.texts),
      returnDays: undefined,
      days: [...s.days, ...s.returnDays]
        ?.filter(day => day.enabled)
        .map(day => ({
          ...day,
          id: typeof day.id === 'string' ? null : day.id,
          startTime: day.startTime?.format('HH:mm') ?? null,
          endTime: day.endTime?.format('HH:mm') ?? null,
        })),
    }));

    const item = {
      ...nextItem,
      texts: mapTextsToArray(state.texts),
      slots: nextSlots.filter(s => typeof s.id === undefined),
      itemGalleries: [
        ...state.itemGalleries,
        ...uploadedImages.map(image => ({ path: image.path, order: state.itemGalleries.length })),
      ],
    };

    try {
      const { data: mutatedItem } = await mutateItem(item);

      for (const s of nextSlots) {
        await mutateSlot({ ...s, itemId: mutatedItem.id, hotelId: selectedHotel.id });
      }

      const isItemBeingUpdated = !!state.id;

      if (!isItemBeingUpdated) {
        notification.success({
          message: translate('crossSell.itemDetail.saveSuccess'),
        });
        history.push(itemUpsert.replace(':id', mutatedItem.id));
      } else {
        notification.success({
          message: translate('crossSell.itemDetail.updateSuccess'),
        });
      }
    } catch (error) {
      notification.error({
        message: translate('crossSell.itemDetail.saveError'),
      });
    }
  };

  const validateForm = (state, translate) => {
    if (!state.price) {
      notification.warning({
        message: translate('crossSell.validation.priceRequired'),
      });
      return false;
    }
    
    if (state.type === 'good' && (!state.quantity || state.quantity <= 0)) {
      notification.warning({
        message: translate('crossSell.validation.quantityRequired'),
      });
      return false;
    }

    if (['service', 'transfer'].includes(state.type)) {
      if (state.slots.length === 0) {
        notification.warning({
          message: translate('crossSell.validation.slotQuantityRequired'),
        });
        return false;
      }

      const invalidSlot = state.slots.some(slot => !slot.quantity || slot.quantity <= 0);
      if (invalidSlot) {
        notification.warning({
          message: translate('crossSell.validation.slotQuantityRequired'),
        });
        return false;
      }

      const initialState = getInitialState({
        hotelId: selectedHotel.id,
        chain: appState.chain,
        language: appState.currentLanguage.id,
      });

      const noChanges = JSON.stringify(state) === JSON.stringify(initialState);
      if (noChanges) {
        notification.warning({
          message: translate('crossSell.validation.noChangesDetected'),
        });
        return false;
      }
    }

    return true;
  };

  return {
    dataSource: {
      state,
      isLoading: isLoading || isMutationLoading || isAppConfigLoading,
      files,
      currency: appConfig?.currency,
      hotelLanguages : appState.hotelLanguages
    },
    handlers: {
      handleClickBack: () => history.goBack(),
      handleLanguageChange: language => {
        updateState({ language });
      },
      handleItemTypeChange: item => {
        updateState({ type: item.id });
      },
      handleTextChange: ({ type, value }) => {
        dispatch({
          type: actionTypes.UPDATE_TEXTS,
          payload: { type, value, language: state.language },
        });
      },
      handleFileChange: ({ fileList }) => {
        addFiles(fileList);
      },
      handleDeleteImage: imageId => {
        updateState({
          itemGalleries: state.itemGalleries.filter(i => i.id !== imageId),
        });
      },
      handleOnPhotoPositionChange: photos => {
        dispatch({
          type: actionTypes.REORDER_IMAGE,
          payload: photos,
        });
      },
      handleConfirmationChange: confirmationType => {
        updateState({ confirmationType });
      },
      handlePriceChange: price => {
        updateState({ price });
      },
      handleSubmit,
      handleSlotTripTypeChange: tripType => {
        updateState({ slot: { ...state.slot, tripType } });
      },
      handleSlotTextChange: ({ type, value }) => {
        dispatch({
          type: actionTypes.UPDATE_SLOT_TEXTS,
          payload: { type, value, language: state.language },
        });
      },
      handleQuantityChange: quantity => {
        updateState({ quantity });
      },
      handleSlotQuantityChange: quantity => {
        updateState({ slot: { ...state.slot, quantity } });
      },
      handleSlotDateChange: dates => {
        const [startDate, endDate] = dates || [];
        updateState({ slot: { ...state.slot, startDate, endDate } });
      },
      handleSlotDayStatusChange: day => {
        dispatch({
          type: actionTypes.UPDATE_SLOT_DAY,
          payload: { ...day, enabled: !day.enabled },
        });
      },
      handleSlotDayTimeChange: (day, [startTime, endTime]) => {
        dispatch({
          type: actionTypes.UPDATE_SLOT_DAY,
          payload: { ...day, startTime, endTime },
        });
      },
      handleSlotAddDay: day => {
        dispatch({
          type: actionTypes.ADD_SLOT_DAY,
          payload: {
            ...day,
            startTime:
              day.endTime ?? day.startTime ? day.startTime.clone().add(30, 'minutes') : null,
            endTime: null,
            enabled: true,
          },
        });
      },
      handleSlotDeleteDay: day => {
        dispatch({
          type: actionTypes.DELETE_SLOT_DAY,
          payload: day,
        });
      },
      handleSlotEdit: slot => {
        dispatch({
          type: actionTypes.LOAD_SLOT,
          payload: slot,
        });
      },
      handleSlotDelete: slot => {
        dispatch({
          type: actionTypes.DELETE_SLOT,
          payload: slot,
        });
      },
      handleSlotSubmit: () => {
        dispatch({
          type: actionTypes.CREATE_SLOT,
        });
      },
    },
  };
};

export default useItemManager;
