import React from 'react';
import styles from './ConfigurationForm.styles';
import ConfigurationFormInput from './ConfigurationFormInput/ConfigurationFormInput';

export default function ConfigurationForm({ fields, literals, onValueChanged, getFieldValue }) {
  const repeatedKeys = fields
    .map(item => item.key)
    .filter((key, index, keys) => keys.indexOf(key) !== index);

  return (
    <div style={styles.fieldsGrid}>
      {fields.map((field, index) => {
        const fieldName = literals[field.key] || field.key;

        return (
          <ConfigurationFormInput
            key={index}
            field={field}
            onChange={onValueChanged}
            value={getFieldValue(field.key, field?.table)}
            tooltip={field.info ? literals[`i_${field.key}`] || field.key : null}
            name={repeatedKeys.includes(field.key) ? `${fieldName} (${field.table})` : fieldName}
            literals={literals}
          />
        );
      })}
    </div>
  );
}
