import React, { useContext } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Image, Upload } from 'antd';
import useImageSliderHook from './ImageSlider.hook';
import { AppContext } from 'App.context';
import styles from './ImageSlider.styles';

export default function ImageSlider({ images }) {
  const MAX_IMAGES = 5;
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useImageSliderHook({ images });

  const uploadButton = (
    <button style={styles.upload} type="button">
      <PlusOutlined />
      <div style={styles.uploadText}>{translate(`appearence.upload`)}</div>
    </button>
  );

  return (
    <div style={{ flex: 1 }}>
      <div style={styles.description}>{translate('appearence.imageSliderDesc')}</div>
      <Upload
        action={actions.onImageAdded}
        listType="picture-card"
        fileList={state.fileList}
        onPreview={actions.handlePreview}
        onRemove={actions.removeImage}
      >
        {state.fileList.length >= MAX_IMAGES ? null : uploadButton}
      </Upload>
      {state.previewImage && (
        <Image
          wrapperStyle={{
            display: 'none',
          }}
          preview={{
            visible: state.previewOpen,
            onVisibleChange: visible => actions.setPreviewOpen(visible),
            afterOpenChange: visible => !visible && actions.setPreviewImage(''),
          }}
          src={state.previewImage}
        />
      )}
    </div>
  );
}
