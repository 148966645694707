import React, { useContext } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Table, Typography } from 'antd';
import moment from 'moment';
import constants from 'utils/constants';
import BaseTable from 'Components/common/BaseTable/BaseTable';
import TableActions from 'Sell/Components/TableActions';
import useCrossSellListHook from './CrossSellList.hook';
import styles from './CrossSellList.styles';
import { AppContext } from 'App.context';
import FilterComponent from 'Sell/Components/FilterComponent/FilterComponent';
import RangePicker from 'Sell/Components/RangePicker';

function snakeCaseToCamelCase(str) {
  return str.replace(/([-_]\w)/g, g => g[1].toUpperCase());
}

export default function RoomTypeTable() {
  const { dataSource, dates, isLoading, actions } = useCrossSellListHook();
  const getSelectors = elementStatus => STATUS_MAPPING[elementStatus] || [];

  const {
    appActions: { translate, getSelectedLanguage },
  } = useContext(AppContext);

  const STATUS_MAPPING = {
    pending: [
      {
        label: <span style={styles.toApproved}>{translate('crossSell.changeToApproved')}</span>,
        key: 'status_approved',
        icon: <CheckOutlined style={styles.toApproved} />,
      },
      {
        label: <span style={styles.toRejected}>{translate('crossSell.changeToRejected')}</span>,
        key: 'status_rejected',
        icon: <CloseOutlined style={styles.toRejected} />,
      },
    ],
    approved: [
      {
        label: <span style={styles.toRejected}>{translate('crossSell.changeToRejected')}</span>,
        key: 'status_rejected',
        icon: <CloseOutlined style={styles.toRejected} />,
      },
      ,
    ],
    rejected: [
      {
        label: <span style={styles.toApproved}>{translate('crossSell.changeToApproved')}</span>,
        key: 'status_approved',
        icon: <CheckOutlined style={styles.toApproved} />,
      },
    ],
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: ['id'],
      key: 'id',
      searchable: false,
      sorter: false,
    },
    {
      title: translate('crossSell.status'),
      dataIndex: ['status'],
      key: 'status',
      searchable: false,
      sorter: false,
      render: status => <div style={styles.status(status)}>{translate(`crossSell.${status}`)}</div>,
    },
    {
      title: translate('crossSell.itemName'),
      dataIndex: ['item', 'texts'],
      key: 'item.texts',
      searchable: false,
      sorter: false,
      render: texts => {
        return texts.find(text => text.type === 'name' && text.lang === getSelectedLanguage().id)
          ?.value;
      },
    },
    {
      title: translate('crossSell.slot'),
      searchable: false,
      sorter: false,
      render: crossSell => {
        if (crossSell.item.type === 'good') {
          return (
            <Typography.Text>
              {crossSell.quantity} {translate('crossSell.items')}
            </Typography.Text>
          );
        }

        if (crossSell.item.type === 'service') {
          const [hour, minutes] = crossSell.startTime.split(':');

          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography.Text>
                {moment(crossSell.startDate).format('DD/MM/YYYY')} {hour}:{minutes}
              </Typography.Text>
              <Typography.Text strong>
                {crossSell.quantity} {translate('crossSell.pax')}
              </Typography.Text>
            </div>
          );
        }

        if (crossSell.item.type === 'transfer') {
          const [startHour, startMinutes] = crossSell.startTime.split(':');
          const [endHour, endMinutes] = crossSell.endTime?.split(':') || [];
          const { tripType } = crossSell.itemSlot;

          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography.Text>
                {moment(crossSell.startDate).format('DD/MM/YYYY')} {startHour}:{startMinutes}{' '}
                {crossSell.itemSlot.tripType === 'round-trip' ? (
                  <>
                    {translate('crossSell.to')} {moment(crossSell.endDate).format('DD/MM/YYYY')}{' '}
                    {endHour}:{endMinutes}
                  </>
                ) : null}
              </Typography.Text>
              <Typography.Text strong>
                {crossSell.quantity} {translate('crossSell.pax')}{' '}
                {translate('crossSell.itemDetail.slot.tripType.' + snakeCaseToCamelCase(tripType))}
              </Typography.Text>
            </div>
          );
        }
      },
    },
    {
      title: translate('crossSell.locator'),
      dataIndex: ['booking', 'pmsIdentifier'],
      key: ['booking', 'pmsIdentifier' ],
      searchable: false,
      sorter: false,
    },
    {
      title: translate('crossSell.guestName'),
      dataIndex: ['booking', 'guest'],
      key: ['booking', 'guest'],
      searchable: false,
      sorter: false,
    },
    {
      title: translate('crossSell.paymentStatus'),
      dataIndex: ['booking', 'purchases'],
      key: ['booking', 'purchases'],
      render: purchases => {
        const state = purchases?.[0]?.state;
        const label = state ? `crossSell.${state}` : `crossSell.pending`;
        return <div style={styles.status(state)}>{translate(label)}</div>;
      },
      searchable: false,
      sorter: false,
    },
    {
      title: translate('crossSell.transactionCode'),
      dataIndex: ['booking', 'purchases'],
      key: ['booking', 'purchases'],
      render: purchases => purchases?.[0]?.token,
      searchable: false,
      sorter: false,
    },
    {
      title: translate('crossSell.totalPrice'),      
      key: ['item', 'id'],
      render: ({ quantity, quantityReturn, item }) => {
        return (
          <div style={styles.price}>
            {actions.multiply(item.price, quantity, quantityReturn)}
            {actions.getCurrency()}
          </div>
        );
      },
      searchable: false,
      sorter: false,
    },
    {
      title: translate('crossSell.date'),
      dataIndex: ['created_at'],
      key: 'created_at',
      searchable: false,
      sorter: false,
      render: date => {
        return moment(date).format(constants.dateFormat);
      },
    },
    Table.SELECTION_COLUMN,
    {
      title: ({ selectedKeys, confirm }) => (
        <TableActions
          title="Bulk Actions" // texto para añadir a los ficheros de traducciones
          items={STATUS_MAPPING['pending']}
          onItemClick={actions.handleBulkActionClick}
        />
      ),
      dataIndex: 'icons',
      key: 'icons',
      searchable: false,
      sorter: false,
      render: (_, row) => {
        const items = [...getSelectors(row.status)];

        if (row.status !== 'removed') {
          return (
            <TableActions
              title={translate('salesChannels.actionCol')}
              items={items}
              onItemClick={actions.handleActionClick(row)}
            />
          );
        }
      },
    },
  ];

  return (
    <>
      <div style={styles.mainContainer}>
        <div style={styles.header}>
          <div style={styles.buttonsContainer}>
            <RangePicker
              onChange={actions.onDateChange}
              defaultValue={dates}
              format={constants.dateFormat}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>

      <div style={styles.tableContainer}>
        <div style={styles.table}>
          <FilterComponent
            onFilterChange={actions.onFilterChange}
            items={dataSource.filterData?.data}
            counters={dataSource.counters}
          />
          <BaseTable
            data={dataSource}
            columns={columns}
            loading={isLoading}
            rowSelection={{
              selectedRowKeys: dataSource.selectedRowKeys,
              onChange: actions.onSelectChange,
            }}
            onChange={filters => actions.onChange(filters)}
            onRowClicked={actions.onRowClicked}
          />
        </div>
      </div>
    </>
  );
}
