import { AppContext } from 'App.context';
import Colors from 'constants/Colors';
import { useContext, useEffect, useState } from 'react';
import constants from 'utils/constants';
import { ApiContext } from '../../../../utils/networking/Api.context';
import { HotelsContext } from '../Hotels.context';

export default function useHotelListHook() {
  const { API } = useContext(ApiContext);
  const { hotelsState, hotelsActions } = useContext(HotelsContext);
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const [state, setState] = useState({
    dataSource: {
      items: [],
      totalItems: 0,
      totalPages: 0,
      currentPage: 0,
      pageSize: 10,
    },
    filters: {},
    sort: null,
    isLoading: true,
  });

  useEffect(() => {
    actions.loadHotels();
  }, [state.dataSource.currentPage, state.filters]);

  useEffect(() => {
    if (hotelsState.needsUpdateList) actions.loadHotels();
  }, [hotelsState.needsUpdateList]);

  const actions = {
    loadHotels: () => {
      updateState({ isLoading: true });
      API.hotelsRequest(
        state.dataSource?.currentPage,
        state.dataSource?.pageSize,
        state.filters,
        state.sort,
        state.datesRange
      )
        .then(res => {
          hotelsActions.setNeedsUpdateList(false);
          updateState({ dataSource: res, isLoading: false });
        })
        .catch(error => {
          hotelsActions.setNeedsUpdateList(false);
          console.warn(error);
        });
    },

    onChange: filters => {
      var newPage,
        newPageSize = -1;
      var searchFilters;
      var sortInfo;
      if (state.dataSource?.currentPage !== filters?.pagination.page) {
        newPage = filters?.pagination.page;
      }
      if (state.dataSource?.pageSize !== filters?.pagination.pageSize) {
        newPageSize = filters?.pagination.pageSize;
      }
      if (filters?.where) {
        const hotel = filters?.where['name'];
        searchFilters = { hotel };
      }
      if (filters?.sort) {
        const sortField =
          typeof filters.sort?.sortField === 'string'
            ? filters.sort?.sortField
            : filters.sort?.sortField?.join('.');
        const order = filters.sort?.sortOrder;
        var code;

        switch (sortField) {
          case 'name':
            code = 'name';
            break;
          default:
            break;
        }

        sortInfo = code && order ? `${code} ${order}` : null;
      }
      updateState({
        dataSource: {
          ...state.dataSource,
          currentPage: newPage >= 0 ? newPage : state.dataSource?.currentPage,
          pageSize: newPageSize >= 0 ? newPageSize : state.dataSource?.pageSize,
        },
        filters: searchFilters,
        sort: sortInfo,
      });
    },

    getTextColorStateFromPMS: pms => {
      if (pms != constants.PMS.NO_INTEGRATION) {
        return Colors.greenHotelIntegratedText;
      } else {
        return Colors.yellowHotelNoIntegratedText;
      }
    },

    getBackgroundColorStateFromPMS: pms => {
      if (pms != constants.PMS.NO_INTEGRATION) {
        return Colors.greenHotelIntegratedBg;
      } else {
        return Colors.yellowHotelNoIntegratedBg;
      }
    },
    
    isPMSIntegrated: pms => {
      if (pms != constants.PMS.NO_INTEGRATION) {
        return true;
      } else {
        return false;
      }
    },

    getTextStateFromPMS: pms => {
      if (pms != constants.PMS.NO_INTEGRATION) {
        return translate('hotels.integrated');
      } else {
        return translate('hotels.noIntegrated');
      }
    },

    previewCheckin: hotelSlug => {
      window.open(`https://checkin.civitfun.com/hotel/${hotelSlug}`);
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
