import Colors from 'constants/Colors';

export default {
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    color: Colors.darkText,
  },
  container: {
    flex: 1,
    borderRadius: 6,
    backgroundColor: Colors.inputBg,
    padding: 4,
  },
  shadow: {
    boxShadow: `0 1px 2px rgba(0, 0, 0, 0.18)`,
  },
  errorShadow: {
    boxShadow: `0.5px 1px 6px ${Colors.red}`,
  },
};
