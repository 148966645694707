export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
  },
  informationContainer: {
    flex: 1,
  },
  documentsContainer: {
    width: '32%',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  image: {
    width: '100%',
    maxHeight: 200,
  },
};
