export default [
  {
    id: 'checkin_template',
    hasReplacementTags: true,
    isVisible: true,
    defaultValue: {
      es: `<p>
        Hola [guest_first_name]&nbsp;Gracias por elegir [hotel_name]. Tenemos muchas ganas de recibirte.
        Aquí están los detalles de tu reserva y los documentos adjuntos:</p>
    <p>
        Número de confirmación de reserva:&nbsp;[reference_number]</p>
    <ul>
        <li>Fecha de llegada:&nbsp;[entrance_date]</li>
        <li>
            Fecha de salida:&nbsp;[departure_date]</li>
        <li> Dirección:&nbsp;[address]</li>
    </ul>
    <p>
        Si necesitas hacer cambios o requieres asistencia por favor llama [hotel_phone]&nbsp;o mándanos un email a [CIF_business_email].
        ¡Tenemos muchas ganas de recibirte en [CIF_city]&nbsp;pronto!&nbsp;</p>
    <p>[hotel_name]</p>
    <p><img src="[hotel_logo]" width="auto" height="100"><br></p>`,
      en: `<p>
    Hello [guest_first_name].&nbsp;Thank you for choosing [hotel_name]. We really want to receive you.
    Here are the details of your reservation and the attached documents: </p>
<p>
    Booking confirmation number:&nbsp;[reference_number] </p>
<ul>
    <li> Arrival date:&nbsp;[entrance_date] </li>
    <li>
        Departure date:&nbsp;[departure_date] </li>
    <li> Address:&nbsp;[address] </li>
</ul>
<p>
    If you need to make changes or require assistance please call [hotel_phone]&nbsp;or email us at [CIF_business_email].
    We look forward to welcoming you to [CIF_city]&nbsp;soon!&nbsp; </p>
<p> <img src = "[hotel_logo]" width = "200" height = "100"> <br> </p>
<p> [hotel_name] </p>`,
      ca: `<p>
Hola [guest_first_name].&nbsp;Gràcies per triar [hotel_name]. Tenim moltes ganes de rebre't.
Aquí tens els detalls de la teva reserva i els documents adjunts:</p>
<p>
Número de confirmació de reserva:&nbsp;[reference_number]</p>
<ul>
<li>Data d'arribada:&nbsp;[entrance_date]</li>
<li>
    Data de sortida:&nbsp;[departure_date]</li>
<li> Adreça:&nbsp;[address]</li>
</ul>
<p>
Si necessites fer canvis o requereixes assistència si us plau truca a [hotel_phone]&nbsp;o envia'ns un email a [CIF_business_email].
Tenim moltes ganes de rebre't a [CIF_city]&nbsp;aviat!&nbsp;</p>
<p>[hotel_name]</p>
<p><img src="[hotel_logo]" width="auto" height="100"><br></p>`,
      de: `<p>
Hallo [guest_first_name].&nbsp;Vielen Dank, dass Sie sich für [hotel_name] entschieden haben. Wir möchten Sie wirklich empfangen.
Hier sind die Details Ihrer Reservierung und die beigefügten Dokumente: </p>
<p>
Buchungsbestätigungsnummer:&nbsp;[reference_number] </p>
<ul>
<li> Ankunftsdatum:&nbsp;[entrance_date] </li>
<li>
    Abreisedatum:&nbsp;[departure_date] </li>
<li> Adresse:&nbsp;[address] </li>
</ul>
<p>
Wenn Sie Änderungen vornehmen oder Hilfe benötigen, rufen Sie bitte [hotel_phone] an oder senden Sie uns eine E-Mail an [CIF_business_email].
Wir freuen uns, Sie bald in [CIF_city] begrüßen zu dürfen!
<p> [hotel_name] </p>
<p> <img src = "[hotel_logo]" width = "200" height = "100"> <br> </p>`,
      fr: `<p>
Bonjour [guest_first_name].&nbsp;Merci d'avoir choisi [hotel_name]. Nous voulons vraiment vous recevoir.
Voici le détail de votre réservation et les documents joints: </p>
<p>
Numéro de confirmation de réservation :&nbsp;[reference_number] </p>
<ul>
<li> Date d'arrivée :&nbsp;[entrance_date] </li>
<li>
    Date de départ :&nbsp;[departure_date] </li>
<li> Adresse :&nbsp;[address] </li>
</ul>
<p>
Si vous devez apporter des modifications ou demander de l'aide, veuillez appeler [hotel_phone]&nbsp;ou nous envoyer un e-mail à [CIF_business_email].
Au plaisir de vous accueillir à [CIF_city]&nbsp;bientôt ! ​&nbsp;</p>
<p> [hotel_name] </p>
<p> <img src = "[hotel_logo]" width = "200" height = "100"> <br> </p>`,
      it: `<p>
Ciao [guest_first_name].&nbsp;Grazie per aver scelto [hotel_name]. Vogliamo davvero riceverti.
Ecco i dettagli della tua prenotazione e i documenti allegati: </p>
<p>
Numero di conferma della prenotazione:&nbsp;[reference_number] </p>
<ul>
<li> Data di arrivo:&nbsp;[entrance_date] </li>
<li>
    Data di partenza:&nbsp;[departure_date] </li>
<li> Indirizzo:&nbsp;[address] </li>
</ul>
<p>
Se hai bisogno di apportare modifiche o hai bisogno di assistenza, chiama [hotel_phone]&nbsp;o inviaci un'e-mail a [CIF_business_email].
Non vediamo l'ora di darti il ​​benvenuto a [CIF_city]&nbsp;presto!&nbsp;</p>
<p> [hotel_name] </p>
<p> <img src = "[hotel_logo]" larghezza = "200" altezza = "100"> <br> </p>`,
      pt_BR: `<p>
Olá, [guest_first_name].&nbsp; Obrigado por escolher [hotel_name]. Nós realmente queremos receber você.
Aqui estão os detalhes da sua reserva e os documentos em anexo: </p>
<p>
Número de confirmação de reserva:&nbsp; [reference_number] </p>
<ul>
<li> Data de chegada:&nbsp; [entrance_date] </li>
<li>
    Data de partida:&nbsp; [departure_date] </li>
<li> Endereço:&nbsp; [address] </li>
</ul>
<p>
Se você precisar fazer alterações ou solicitar assistência, ligue para [hotel_phone]&nbsp; ou envie um e-mail para [CIF_business_email].
Esperamos recebê-lo em [CIF_city]&nbsp; em breve!&nbsp; </p>
<p> [hotel_name] </p>
<p> <img src = "[hotel_logo]" width = "200" height = "100"> <br> </p>`,
      pt: `<p>
Olá, [guest_first_name].&nbsp; Obrigado por escolher [hotel_name]. Nós realmente queremos receber você.
Aqui estão os detalhes da sua reserva e os documentos em anexo: </p>
<p>
Número de confirmação de reserva:&nbsp; [reference_number] </p>
<ul>
<li> Data de chegada:&nbsp; [entrance_date] </li>
<li>
    Data de partida:&nbsp; [departure_date] </li>
<li> Endereço:&nbsp; [address] </li>
</ul>
<p>
Se você precisar fazer alterações ou solicitar assistência, ligue para [hotel_phone]&nbsp; ou envie um e-mail para [CIF_business_email].
Esperamos recebê-lo em [CIF_city]&nbsp; em breve!&nbsp; </p>
<p> [hotel_name] </p>
<p> <img src = "[hotel_logo]" width = "200" height = "100"> <br> </p>`,
    },
  },
  {
    id: 'checkout_template',
    hasReplacementTags: true,
    isVisible: true,
    disabled: true,
    defaultValue: {
      en: `<p>Thank you for your stay and we hope to see you again very soon.</p>
      <p> <img src = \“[hotel_logo]\” width = \“200\” height = \“100\“> <br> </p>
      <p> [hotel_name] </p>`,
    },
  },
];
