import { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import { notification } from 'antd';
import { AppContext } from 'App.context';
import { useHistory } from 'react-router-dom';

export default function usePaperlessPushHook() {
  const { API } = useContext(ApiContext);
  const history = useHistory();

  const {
    appActions: { translate },
  } = useContext(AppContext);
  const [state, setState] = useState({
    isLoading: false,
    dataSource: {
      items: [],
      totalItems: 0,
      totalPages: 0,
      currentPage: 0,
      pageSize: 10,
    },
    originalWhitelists: [],
    filters: {},
    modifiedHotels: [],
    suscriptionEnabled: false,
  });

  useEffect(() => {
    actions.loadPapelessPushConfig();
  }, [state.dataSource.currentPage, state.filters]);

  const actions = {
    loadPapelessPushConfig: () => {
      updateState({ isLoading: true });

      API.getPaperlessPushConfig({
        page: state.dataSource?.currentPage,
        size: state.dataSource?.pageSize,
        filters: state.filters,
      })
        .then(res => {
          const whitelists = res.items.map(i => {
            console.log(i);
            return {
              hotelId: i.hotelId,
              list: i?.ipWhitelist?.split(',') || null,
            };
          });

          console.log(whitelists);
          updateState({
            dataSource: res,
            isLoading: false,
            modifiedHotels: [],
            originalWhitelists: whitelists,
          });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },

    onChange: filters => {
      const { pagination, where } = filters;
      const { currentPage, pageSize } = state.dataSource;

      const newPage = pagination?.page !== currentPage ? pagination.page : undefined;
      const newPageSize = pagination?.pageSize !== pageSize ? pagination.pageSize : undefined;
      const searchFilters = where ? { hotel: where.hotel } : undefined;

      updateState({
        dataSource: {
          ...state.dataSource,
          currentPage: newPage !== undefined ? newPage : currentPage,
          pageSize: newPageSize !== undefined ? newPageSize : pageSize,
        },
        filters: searchFilters,
      });
    },

    setPMSId: (hotelId, pmsId) => {
      const items = state.dataSource.items;
      const hotel = items.find(h => h.hotelId === hotelId);

      hotel.pmsId = pmsId;

      actions.setHotelAsModified(hotelId);
      updateState({
        dataSource: {
          ...state.dataSource,
          items,
        },
      });
    },

    setHotelWhitelist: (hotelId, whitelist) => {
      const items = state.dataSource.items;
      const hotel = items.find(h => h.hotelId === hotelId);

      hotel.ipWhitelist = whitelist;

      actions.setHotelAsModified(hotelId);
      updateState({
        dataSource: {
          ...state.dataSource,
          items,
        },
      });
    },

    setHotelAsModified: hotelId => {
      if (!state.modifiedHotels.includes(hotelId)) {
        const modifiedHotels = state.modifiedHotels;
        modifiedHotels.push(hotelId);

        updateState({ modifiedHotels });
      }
    },

    isValidIpList: whitelist => {
      const ipAddressRegex = /^(\d{1,3}\.){3}\d{1,3}(,\s*(\d{1,3}\.){3}\d{1,3})*$/;
      return whitelist === '' || whitelist === null || ipAddressRegex.test(whitelist);
    },

    onSaveClicked: () => {
      const hotels = state.dataSource.items.filter(item =>
        state.modifiedHotels.includes(item.hotelId)
      );

      const whitelists = actions.getIpConfigurations(hotels);
      const data = {
        hotels,
        whitelists,
      };

      if (actions.validateHotels(data.hotels)) {
        updateState({ isLoading: true });
        API.updatePaperlessPushConfig({ data })
          .then(_ => {
            notification.success({
              message: translate('paperlessPush.updateSuccess'),
            });
            updateState({ isLoading: false });
          })
          .catch(err => {
            notification.error({
              message: translate('paperlessPush.updateError'),
            });
            updateState({ isLoading: false });
          });
      }
    },

    getExampleLink: item => {
      return `https://paperless-push.civitfun.com/${item.apiKey}/${item.pmsId}/RESERVATION_ID/GUEST_ID`;
    },

    validateHotels: hotels => {
      let noErrors = true;
      for (const hotel of hotels) {
        if (!actions.isValidIpList(hotel.ipWhitelist)) {
          notification.error({
            message: translate('paperlessPush.errorWhitelist'),
          });

          noErrors = false;
        }
      }

      return noErrors;
    },

    goToAccountDetail: () => {
      history.push('/account-detail');
    },

    getIpConfigurations: hotels => {
      const ipData = {};

      for (const hotel of hotels) {
        const currentWhitelist = hotel.ipWhitelist.split(',').map(ip => ip.trim());
        const originalIps = state.originalWhitelists
          .find(i => i.hotelId === hotel.hotelId)
          ?.list?.map(ip => ip.trim());

        if (!ipData[hotel.hotelId]) {
          ipData[hotel.hotelId] = { newIps: [], revokedIps: [] };
        }

        for (const ip of originalIps || []) {
          if (!currentWhitelist.includes(ip.trim())) {
            ipData[hotel.hotelId].revokedIps.push(ip.trim());
          }
        }

        for (const ip of currentWhitelist) {
          if (!originalIps || !originalIps?.includes(ip.trim())) {
            if (ip.length) {
              ipData[hotel.hotelId].newIps.push(ip.trim());
            }
          }
        }
      }

      return ipData;
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
