import axios from 'axios';
import apiConfig from '../api.config';

export default function checkinDetailRequest({ id, lang }) {
  const {
    host,
    URIs: { checkinDetail },
  } = apiConfig;

  const uri = `${host}${checkinDetail.replace(':uuid', id)}`;

  return axios
    .get(uri, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        lang,
      },
    })
    .then(res => {
      return res.data;
    });
}
