import axios from 'axios';
import apiConfig from '../api.config';
import constants from '../../constants';
import storage from '../../storage';

export default function getHotelInvoiceFileURL(hotelInvoiceId) {
  const {
    billingHost,
    URIs: { getHotelInvoiceFileURL },
  } = apiConfig;

  const uri = `${billingHost}${getHotelInvoiceFileURL}`.replace(':hotelInvoiceId', hotelInvoiceId);

  const storedCredentials = JSON.parse(storage.get(constants.AUTH.USER_KEY));

  return axios
    .post(uri, {
      data: {
        hotelReference: 'civitfun',
        accessToken: storedCredentials.accessToken,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
