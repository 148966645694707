import { notification } from 'antd';

export const validateRoomData = (state, translate) => {
  if (!state.currentName.value.trim()) {
    notification.warning({
      message: translate('upsell.upsertValidations.name'),
    });
    return false;
  }

  if (!state.currentTexts.value.trim()) {
    notification.warning({
      message: translate('upsell.upsertValidations.description'),
    });
    return false;
  }

  if (!state.code?.trim()) {
    notification.warning({
      message: translate('upsell.upsertValidations.missingPmsId'),
    });
    return false;
  }

  for (const text of state.texts) {
    if (!text.value.trim() && text.value !== '') {
      notification.warning({
        message: translate('upsell.upsertValidations.missingTranslation'),
      });
      return false;
    }

    if (state.texts.filter(t => t.lang === text.lang).length < 2) {
      notification.warning({
        message: translate('upsell.upsertValidations.missingTranslation'),
      });
      return false;
    }
  }

  return true;
};
export const createRoomAlert = (action, translate) => {
  const messageKey = action === 'create' ? 'upsell.upsertValidations.createRoom' : 'upsell.upsertValidations.modifiedRoom';
  notification.success({
    message: translate(messageKey),
  });
}
