import React from 'react';
import './Loading.module.css';
import { Spin, Space } from 'antd';
import Colors from 'constants/Colors';
import { LoadingOutlined } from '@ant-design/icons';

export default function Loading({ color, customIndicator, size = 'large' }) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <Space style={{ position: 'relative', zIndex: 100, alignSelf: 'center' }} size="middle">
      {customIndicator ? <Spin size={size} indicator={antIcon} /> : <Spin size={size} />}
      {/* <style jsx global>{`
        .ant-spin-dot-item {
          background-color: ${color ? color : Colors.primaryGreen};
        }
      `}</style> */}
    </Space>
  );
}
