export default {
  menu: {
    roomType: 'Habitaciones',
    status: 'Estados de Upsellings',
    report: 'Reportes',
  },
  pmsIdentifier: 'PMS ID',
  pmsIdentifierInfo: 'Indica el ID de la habitación en tu PMS para esta habitación',
  status: 'Estado',
  picture: 'Imagen',
  roomType: 'Tipo de habitación',
  roomTypeInfo: 'Introduce el nombre de la habitación',
  hotelName: 'Nombre del hotel',
  createRoomType: 'Crear habitación',
  confirmationType: 'Tipo de confirmación',
  automatic: 'Automático',
  manual: 'Manual',
  description: 'Descripción',
  descriptionIn: 'Introduce la descripción de la habitación',
  photoGallery: 'Galería de fotos',
  amenities: 'Servicios',
  roomFeatures: 'Características de la habitación',
  dragAndDrop: 'Arrastra tus imágenes aquí o haz clic para seleccionarlos',
  maxFileSize: 'Tamaño máximo de archivo: 2.5MB',
  maxFileNumber: 'El máximo permitido es de 5 imágenes.',
  uploadsuccess: 'Subido con éxito',
  uploaderror: 'Error al subir',
  dealId: 'ID',
  locator: 'Localizador',
  guestName: 'Nombre del huésped',
  date: 'Fecha',
  typesOfRoomsCreated: 'Tipos de habitaciones creadas',
  typeOfPrice: 'Tipo de precio',
  price: 'Precio',
  deleteRoomType: {
    title: '¿Estás seguro de querer eliminar esta habitación?',
    content: 'Se eliminara la habitación seleccionada',
    okText: 'Eliminar tipo de habitación',
  },
  createRoomDescription:
    'Crea todos los tipos de habitaciones que tengas disponibles en tu establecimiento para ofrecer una mejora de categoría a tus huéspedes',
  groupBy: 'Agrupado por',
  groupBy: 'Agrupados por',
  viewDetails: 'Ver detalles',
  roomDetail: {
    back: 'Volver',
    saveRoom: 'Guardar Habitación',
  },
  active: 'Activo',
  removed: 'Eliminado',
  disabled: 'Desactivado',
  maxFileUploadReached: 'Puedes cargar un máximo de 5 imágenes y organizarlas fácilmente utilizando la función de arrastrar y soltar, o eliminarlas según sea necesario. La primera imagen se mostrará como la imagen principal en un formato más grande.',
  totalUpsell: 'Total Upsellings',
  upsellRevenue: 'Ingresos de Upselling',
  upsellList: 'Lista de Upsellings',
  upsell: 'upselling',
  last7Days: 'Últimos 7 días',
  lastMonth: 'Último mes',
  last30Days: 'Últimos 30 días',
  days: 'Días',
  month: 'Mes',
  accepted: 'Aceptado',
  rejected: 'Rechazado',
  approved: 'Aprobado',
  pending: 'Pendiente',
  activate: 'Activar',
  delete: 'Eliminar',
  seeDetails: 'Ver detalles',
  changeToRejected: 'Cambiar a rechazado',
  changeToApproved: 'Cambiar a aprobado',
  disable: 'Desactivar',
  paymentStatus: 'Estado del pago',
  transactionCode: 'Transacción',
  succeed: 'Aprobado',
  previousRoom: 'Habitación anterior',
  newRoom: 'Habitación nueva',
  addUpsellWarning: 'Antes de implementar esta lógica de negocios para las mejoras de habitaciones, debe asegurarse de que se hayan creado todas las habitaciones.',
  upsertValidations: {
    name: 'El nombre de la habitación es requerido',
    description: 'La descripción de la habitación es requerida',
    missingPmsId: 'El PMS ID es requerido',
    missingTranslation: 'Falta traducción en otro idioma',
    createRoom: 'Habitación creada con éxito',
    modifiedRoom: 'Habitación modificada con éxito.',
  },
  fromRoom: 'Habitación anterior',
  toRoom: 'Habitación nueva',
  pmsTooltip: 'Es imprescindible añadir correctamente el ID de la habitación en PMS. Si un huésped ha reservado una habitación que no está registrada correctamente en nuestro sistema, no podremos recomendar una mejora de habitación.',
  confirmationTypeTooltip: "Elige entre la confirmación automática o manual de Upsellings. Automática: Los Upsells realizados por los huéspedes se aprobarán automáticamente sin necesidad de intervención por parte del hotel. Manual: Los Upsells solicitados por los huéspedes se registrarán como pendientes. Deberás aprobarlos o rechazarlos manualmente a través de nuestro panel de control."
};
