export const addLikeToWhere = where => {
  if (!where) return where;
  const newWhere = {};
  Object.keys(where).forEach(key => {
    newWhere[key] = { $like: `%${where[key]}%` };
  });
  return newWhere;
};

export function generateUniqueImageName(originalFileName) {
  const now = new Date();
  const timestamp = now.toISOString().replace(/[\W_]+/g, '');

  const randomCode = Array.from({ length: 3 }, () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return characters.charAt(Math.floor(Math.random() * characters.length));
  }).join('');

  const extension = originalFileName.split('.').pop();

  return `image_${timestamp}_${randomCode}.${extension}`;
}

export const getRoomTypeId = location => {
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const lastSegment = pathSegments[pathSegments.length - 1];
  if (!isNaN(parseInt(lastSegment, 10)) && isFinite(lastSegment)) return lastSegment;
  return false;
};

export const sumArray = arr => {
  if (!Array.isArray(arr)) return 0;
  return arr.reduce((acc, curr) => acc + curr, 0);
};
