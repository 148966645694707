import moment from 'moment';
const { notification } = require('antd');

const DAYS_LIMIT = 90;

export default function isValidDateRange(dateRange) {
  const dateFrom = moment(dateRange[0], 'YYYY-MM-DD');
  const dateTo = moment(dateRange[1], 'YYYY-MM-DD');

  const daysDiff = moment.duration(dateTo.diff(dateFrom)).asDays();

  if (daysDiff > DAYS_LIMIT) {
    notification.info({
      message: `El rango de fechas seleccionado no puede ser superior a ${DAYS_LIMIT} días`,
    });
    return false;
  } else {
    return true;
  }
}
