import Colors from 'constants/Colors';

export default {
  header: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    justifyContent: 'space-between',
    gap: 8,
    paddingRight: 32,
  },
  langSelectorContainer: {
    display: 'flex',
    marginLeft: 32,
    gap: 8,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selector: {
    marginRight: 8,
    width: 152,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
  },
  switchContainer: {
    display: 'flex',
    justifyContent: 'row',
    gap: 32,
  },
  separator: {
    width: '100%',
    height: 0.5,
    backgroundColor: Colors.lightGray,
  },
  addressTitle: {
    fontWeight: 'bold',
    fontSize: 12,
    marginBottom: 4,
    marginTop: 8,
    color: Colors.darkText,
  },
  editorContainer: {
    display: 'flex',
    gap: 32,
    flexDirection: 'column',
  },
};
