import axios from 'axios';
import apiConfig from '../api.config';

export default function checkinsExportRequest({ hotel, dateFrom, dateTo, lang }) {
  const {
    host,
    URIs: { checkinsExport },
  } = apiConfig;

  var filter = { dateFrom, dateTo };
  if (hotel) filter.hotel = hotel;

  const uri = `${host}${checkinsExport}?filter=${JSON.stringify(filter)}`;

  return axios
    .get(uri, {
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        lang,
      },
      responseType: 'arraybuffer',
      timeout: 3600000,
    })
    .then(res => {
      return res.data;
    });
}
