import React, { useContext } from 'react';
import { Select, Avatar } from 'antd';
import styles from './LangSelector.styles.js';

export default function LanguageSelector({ setSelectedLanguage, suportedlanguages, defaultLang }) {
  const lang = suportedlanguages?.map(language => (
    <Select.Option key={language.id} value={language.id} style={styles.option}>
      <img
        className="flag-icon"
        style={styles.flagIcon}
        src={require(`assets/images/icons/language/${language.id}.png`)}
        alt={`${language.name} flag`}
      />
      {language.name}
    </Select.Option>
  ));

  return (
    <div style={styles.container}>
      <Select
        defaultValue={defaultLang}
        style={styles.select}
        onChange={setSelectedLanguage}
        bordered={false}
      >
        {lang}
      </Select>
    </div>
  );
}
