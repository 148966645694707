import axios from 'axios';
import apiConfig from '../api.config';

export default function updateHotelDoorOpeningConfig({
  hotelId,
  platform,
  parameters,
  doorOpeningHotelConfig,
}) {
  const {
    host,
    URIs: { updateDoorOpeningConfig },
  } = apiConfig;

  const uri = `${host}${updateDoorOpeningConfig.replace(':hotelId', hotelId)}`;

  return axios
    .put(uri, JSON.stringify({ platform, parameters, doorOpeningHotelConfig }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
