import React from 'react';
import { Table, Input } from 'antd';
import useGuestHook from './hooks/Guest.hook';
import guestStyles from './Guest.styles';

export default function DynamicFormTable({ mock, setMock, translate }) {
  const validationGuestVariableName = translate('validation.guest.response.variableName');
  const validationGuestCivitfunData = translate('validation.guest.response.civitfunData');

  const { snakeToCamel, snakeToCapitalizedWithSpace } = useGuestHook();

  const handleInputChange = (e, key) => {
    const newValue = e.target.value;

    setMock(prevState => ({
      fields: prevState.fields.map(field =>
        field.key === key ? { ...field, value: newValue } : field
      ),
    }));
  };

  const columns = [
    {
      title: validationGuestVariableName,
      dataIndex: 'key',
      key: 'key',
      render: text => (
        <b style={guestStyles.keyColumn}>{snakeToCapitalizedWithSpace(snakeToCamel(text))}</b>
      ),
    },
    {
      title: validationGuestCivitfunData,
      dataIndex: 'value',
      key: 'value',
      render: (text, record) => (
        <div style={guestStyles.valueColumnForm}>
          <Input
            value={text}
            onChange={e => handleInputChange(e, record.key)}
            style={guestStyles.input}
          />
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={mock.fields}
      rowKey="key"
      pagination={false}
      style={guestStyles.table}
    />
  );
}
