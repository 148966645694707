import React, { useContext } from 'react';
import styles from './PMSSelector.styles';
import { Select } from 'antd';
import { AppContext } from '../../../../App.context';
import getPMSLogo from 'utils/getPMSLogo';

export default function PMSSelector({ row, pmsList, actions }) {
  const { Option } = Select;
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const pmsSuscribed = pmsList.find(pms => pms.id == row.pmsIdSuscribed)?.name;
  const pmsCheckin = row.pmsCheckin;
  const pmsSelectionName = row.pmsCheckin != 'NoIntegration' ? pmsCheckin : pmsSuscribed;
  const disabled = pmsSuscribed || row.pmsCheckin != 'NoIntegration';
  const pmsCurrentSelection = pmsList.find(pms => pms.id == actions.getSelectedPms(row.id))?.name;
  const showLogo = pmsSelectionName || pmsCurrentSelection;

  const pmsTranslation = pmsSelectionName
    ? translate(`pmsName.${pmsSelectionName.toLowerCase()}`)
    : '';
  const pmsName = pmsTranslation.includes('.') ? pmsSelectionName : pmsTranslation;

  return (
    <div style={styles.container}>
      <div style={styles.selector}>
        <Select
          onChange={option => actions.setPmsSelected(row.id, option)}
          bordered={false}
          style={{ width: '100%' }}
          disabled={disabled}
          value={
            disabled
              ? pmsName
              : actions.getSelectedPms(row.id) || translate('pmsScreen.defaultSelectorValue')
          }
        >
          {pmsList?.map((pms, index) => {
            const pmsTranslation = translate(`pmsName.${pms.name.toLowerCase()}`);
            const pmsName = pmsTranslation.includes('.') ? pms.name : pmsTranslation;

            return (
              <Option key={index} value={pms.id}>
                {pmsName}
              </Option>
            );
          })}
        </Select>
      </div>
      {showLogo && (
        <img
          src={getPMSLogo(pmsSelectionName || pmsCurrentSelection)}
          style={{ alignSelf: 'center', height: 36 }}
        />
      )}
    </div>
  );
}
