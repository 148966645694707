import React, { useContext } from 'react';
import useBillingInfoHook from './BillingInfo.hook.js';
import BillingInfoBox from './BillingInfoBox/BillingInfoBox.jsx';
import BillingStatusBox from './BillingStatusBox/BillingStatusBox.jsx';
import { AppContext } from 'App.context.js';
import { BillingInfoContext } from './BillingInfo.context';
import ContentBox from 'Components/common/ContentBox/ContentBox.jsx';

export default function BillingInfo() {
  const { state, actions } = useBillingInfoHook();
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <BillingInfoContext.Provider value={{ state, actions }}>
      <>
        <ContentBox title={translate('billingConfig.billingInfo.title')}>
          <BillingInfoBox />
        </ContentBox>
        <BillingStatusBox />
      </>
    </BillingInfoContext.Provider>
  );
}
