export default {
  rowContainer: {
    display: 'flex',
    gap: 16,
    marginTop: 16,
    marginBottom: 16,
  },
  singleRowContainer: {
    width: '50%',
  },
  port: {
    maxWidth: 100,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'right',
    flex: 1,
    marginTop: 16,
    gap: 16,
    flexDirection: 'column',
    alignItems: 'end',
  },
  extraButtonsContainer: {
    display: 'flex',
    justifyContent: 'right',
    flex: 1,
    marginTop: 16,
    gap: 16,
    flexDirection: 'row',
    alignItems: 'end',
  },
  selector: {
    width: '100%',
  }
};
