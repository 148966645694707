import { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import { useHistory, useLocation } from 'react-router-dom';

export default function useSelectPMSHook() {
  const PMS_PER_PAGE = 4;
  const { API } = useContext(ApiContext);
  const location = useLocation();
  const history = useHistory();
  const [state, setState] = useState({
    dataSet: null,
    pmsList: null,
    minValue: 0,
    maxValue: PMS_PER_PAGE,
    isLoading: true,
    currentPage: 1,
    customPms: null,
    searchInput: null,
    ota: location.state?.ota || null,
    hotelSlug: location.state?.hotelSlug || null,
  });

  useEffect(() => {
    actions.loadPMSList();
  }, []);

  const actions = {
    getPmsPerPage: () => PMS_PER_PAGE,

    loadPMSList: () => {
      API.pmsPublicListRequest()
        .then(res => {
          updateState({ dataSet: res, pmsList: res, isLoading: false });
        })
        .catch(err => {
          if (process.env.REACT_APP_ENV == 'development') {
            updateState({ dataSet: mock, pmsList: mock, isLoading: false });
          } else {
            updateState({ isLoading: false });
          }
          console.warn(err);
        });
    },

    onPageChanged: value => {
      updateState({
        minValue: (value - 1) * PMS_PER_PAGE,
        maxValue: value * PMS_PER_PAGE,
        currentPage: value,
      });
    },

    handleSearch: value => {
      const filteredPMS = state.dataSet?.filter(pms => {
        return pms.name.toUpperCase().includes(value.target.value.toUpperCase());
      });

      updateState({
        searchInput: value.target.value,
        pmsList: filteredPMS,
        currentPage: 1,
        minValue: 0,
        maxValue: PMS_PER_PAGE,
      });
    },

    setCustomPms: name => {
      updateState({ customPms: name });
    },

    onPmsSelected: pmsName => {
      updateState({ isLoading: true });
      API.addOtaMarketing({ pmsName, hotelSlug: state.hotelSlug })
        .then(res => {
          history.push({
            pathname: '/channels',
            state: {
              ota: state.ota,
              hotelSlug: state.hotelSlug,
            },
          });
          updateState({ isLoading: false });
        })
        .catch(err => {
          updateState({ isLoading: false });
          console.warn(err);
        });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}

const mock = [
  { id: 1, name: 'Civitfun' },
  { id: 2, name: 'Phantom' },
  { id: 3, name: 'Standard' },
  { id: 4, name: 'Poseidon' },
  { id: 5, name: 'Standard2' },
  { id: 6, name: 'Avalon2' },
  { id: 7, name: 'Avalon' },
  { id: 8, name: 'Cloudbeds' },
  { id: 9, name: 'Excellence' },
  { id: 10, name: 'GuestPortal' },
  { id: 11, name: 'Mews' },
  { id: 12, name: 'NoIntegration' },
  { id: 13, name: 'Noray' },
  { id: 14, name: 'Opera' },
  { id: 15, name: 'Sihot' },
  { id: 16, name: 'Tecsoft' },
  { id: 17, name: 'Timon' },
  { id: 18, name: 'UlysesCloud' },
  { id: 19, name: 'Winhotel' },
  { id: 20, name: 'Porthotels' },
  { id: 21, name: 'Protel' },
  { id: 22, name: 'Navision' },
  { id: 23, name: 'Catalonia' },
  { id: 24, name: 'RoomCloud' },
  { id: 25, name: 'Standard3' },
  { id: 26, name: 'Apaleo' },
  { id: 27, name: 'Guestline' },
  { id: 28, name: 'Stelle' },
  { id: 29, name: 'Totvs' },
  { id: 30, name: 'Ohip' },
  { id: 31, name: 'Rms' },
];
