import Colors from '../../../../constants/Colors';

export default {
  root: {
    marginTop: 24,
  },
  mainContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'row',
  },
  informationContainer: {
    flex: 1,
    paddingRight: 16,
  },
  documentsContainer: {
    width: '40%',
  },
  sectionTitle: {
    fontSize: 14,
    color: Colors.black,
    textTransform: 'uppercase',
    marginBottom: 16,
  },
};
