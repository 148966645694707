import img from 'Sell/assets/images/visor.png';

export default {
  container: {
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat', // Evita que la imagen se repita
    minWidth: 473,
    maxWidth: 500,
    minHeight: 951,
  },
  innerComponent: {
    display: 'flex',
    paddingTop: 125,
    paddingBottom: 66,
    paddingRight: 41,
    paddingLeft: 41,
  },
};
