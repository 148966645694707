import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useUpsellHistory } from 'Sell/Upsell/Router/useHistory';
import { addLikeToWhere } from 'Sell/utils/utils';
import Routes from 'Sell/Upsell/Router/routes';
import { useUpsellMutation, useUpsellQuery } from 'Sell/Upsell/Upsell/Upsell.service';
import { getDefaultDateRange } from 'utils/DateUtils';
import constants from 'utils/constants';
import { useCountersQuery } from 'Sell/Upsell/Reports/Reports.service';
import { sumArray } from 'Sell/utils/utils';
import moment from 'moment';
import { AppContext } from 'App.context';
import upsellSendEmailToHotelRequest from 'utils/networking/api/upsellSendEmailToHotelApproved.service';
import upsellSendEmailToGuestApprovedRequest from 'utils/networking/api/upsellSendEmailToGuestApproved.service';
import upsellSendEmailToGuestRejectedRequest from 'utils/networking/api/upsellSendEmailToGuestRejected.service';
import sellAddPMSNoteRequest from 'utils/networking/api/sellAddPMSNote.service';
import upsellUpdateChargesRequest from 'utils/networking/api/upsellUpdateCharges.service';

const { dateFormat } = constants;
const useQueryParams = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};
const [defaultStartDate, defaultEndDate] = getDefaultDateRange();

export default function useUpsellList() {
  const {
    appActions: { getSelectedHotel },
  } = useContext(AppContext);
  const { push } = useUpsellHistory();
  const queryParams = useQueryParams();
  const filters = JSON.parse(decodeURIComponent(queryParams.get('filters')));
  const initialState = {
    limit: 10,
    offset: 0,
    where: {
      created_at: {
        from: filters?.where?.created_at?.from || defaultStartDate.format(dateFormat),
        to: filters?.where?.created_at?.to || defaultEndDate.format(dateFormat),
      },
    },
  };

  const [state, setState] = useState(initialState);
  const [filterState, setFilterState] = useState(initialState);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { data: countersData, refetch: refetchCounters } = useCountersQuery(state);
  const { isLoading, data, refetch } = useUpsellQuery(state, getSelectedHotel());
  const { data: filterData, refetch: updateFilters } = useUpsellQuery(filterState, getSelectedHotel());
  const { mutate } = useUpsellMutation({
    onSuccess: refetch,
    refetchCounters,
  });

  function updateState(nextState) {
    setState(previousState => ({ ...previousState, ...nextState }));
  }

  function updateStatus(object, ids, action) {
    object.forEach(item => {
      if (ids.includes(item.id)) {
        switch (action) {
          case 'status_approved':
            mutate(
              { ...item, status: 'approved' },
              {
                onSuccess: e => updateFilters(),
              }
            );
            break;
          case 'status_rejected':
            mutate(
              { ...item, status: 'rejected' },
              {
                onSuccess: e => updateFilters(),
              }
            );
            break;
          default:
            return;
        }
      }
    });
  }

  const actions = {
    onChange: ({ where, pagination }) => {
      const limit = pagination.pageSize;
      updateState({ offset: pagination.page * limit, limit, where: addLikeToWhere(where) });
    },
    onSelectChange: newSelectedRowKeys => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    handleActionClick: item => async action => {
      switch (action) {
        case 'detail':
          push(Routes.upsellDetail.replace(':id', item.id));
        case 'status_approved':
          mutate(
            { ...item, status: 'approved' },
            {
              onSuccess: () => {
                refetch();
                refetchCounters();
                updateFilters();
                actions.notifyHotel(action, item);
                actions.notifyGuest(action, item);
                actions.addPMSNote(action, item);
                actions.updateUpsellCharges(action, item);
              },
            }
          );
          break;
        case 'status_rejected':
          mutate(
            { ...item, status: 'rejected' },
            {
              onSuccess: () => {
                refetch();
                refetchCounters();
                updateFilters();
                actions.notifyHotel(action, item);
                actions.notifyGuest(action, item);
                actions.addPMSNote(action, item);
                actions.updateUpsellCharges(action, item);
              },
            }
          );
          break;
        default:
          return;
      }
    },
    handleBulkActionClick: async action => {
      updateStatus(data?.data, selectedRowKeys, action);
    },
    onDateChange: (_date, dateString) => {
      const newState = {
        ...state,
        where: {
          ...state.where,
          created_at: {
            from: dateString[0] || defaultStartDate.format(dateFormat),
            to: dateString[1] || defaultEndDate.format(dateFormat),
          },
        },
      };

      updateState(newState);
      setFilterState({ ...newState, where: { ...newState.where, status: undefined } });
    },
    onFilterChange: status => {
      const newState = {
        ...state,
        where: {
          ...state.where,
          status,
        },
      };
      if (status === 'all') delete newState.where.status;
      updateState(newState);
    },

    notifyHotel: (status, item) => {
      const { upsellState, tags } = buildGuestlinkUpsellCustomTags(item, status);
      upsellSendEmailToHotelRequest({
        hotelSlug: getSelectedHotel()?.slug,
        pmsId: item?.booking?.pmsIdentifier,
        upsellState,
        tags,
      });
    },

    notifyGuest: (status, item) => {
      const { upsellState, tags } = buildGuestlinkUpsellCustomTags(item, status);
      if (status == 'status_approved') {
        upsellSendEmailToGuestApprovedRequest({
          hotelSlug: getSelectedHotel()?.slug,
          pmsId: item?.booking?.pmsIdentifier,
          upsellState,
          tags
        });
      } else if (status == 'status_rejected') {
        upsellSendEmailToGuestRejectedRequest({
          hotelSlug: getSelectedHotel()?.slug,
          pmsId: item?.booking?.pmsIdentifier,
          upsellState,
          tags
        });
      }
    },

    addPMSNote: (status, item) => {
      const room = item?.PhysicalRoomUpsellToOption?.toPhysicalRoom;
      let text = room?.texts?.find(t => t.type == 'name');

      const upsellPMSNoteData = {
        bookingCode: item.booking.pmsIdentifier,
        upsellStatus: status == 'status_approved' ? 'accepted' : 'rejected',
        paymentStatus: item.bookingCharges?.length ? 'paid' : 'no-paid',
        roomName: text?.value,
        roomId: room?.code,
        price: `${item?.booking?.bookingCharges[0]?.amount} ${item?.booking?.bookingCharges[0]?.currency}`,
      };
      sellAddPMSNoteRequest({ hotelId: item.hotelId, data: upsellPMSNoteData });
    },
    
    updateUpsellCharges: (status, item) => {
      upsellUpdateChargesRequest({ 
        hotelId: item.hotelId,
        bookingIdentifier: item.booking.pmsIdentifier,
        data: {status: status == 'status_approved' ? 'accepted' : 'rejected', bookingIdentifier: item.booking.pmsIdentifier }
      });
    },
  };

  const approved = sumArray(countersData?.data?.accepted);
  const rejected = sumArray(countersData?.data?.rejected);
  const pending = sumArray(countersData?.data?.pending);
  const all = approved + rejected + pending;

  const counters = {
    approved,
    rejected,
    pending,
    all,
  };

  const startDate = moment(state.where.created_at?.from, 'YYYY/MM/DD');
  const endDate = moment(state.where.created_at?.to, 'YYYY/MM/DD');

  return {
    dataSource: {
      items: data?.data,
      totalItems: data?.count,
      totalPages: Math.round(data?.count / state.limit),
      currentPage: state.offset / state.limit,
      pageSize: state.limit,
      filterData,
      selectedRowKeys,
      counters,
    },
    dates: [startDate, endDate],
    isLoading,
    actions,
  };

  function buildGuestlinkUpsellCustomTags(item, status) {
    const fromRoom = item?.PhysicalRoomUpsellToOption?.fromPhysicalRoom;
    let fromRoomText = fromRoom?.texts?.find(t => t.type == 'name');
    const toRoom = item?.PhysicalRoomUpsellToOption?.toPhysicalRoom;
    let toRoomText = toRoom?.texts?.find(t => t.type == 'name');

    const upsellState = status == 'status_approved' ? 'accepted' : 'rejected';
    const tags = {
      upsell_id: item?.id,
      upsell_request_date: new Date().toISOString().split('T')[0],
      upsell_state: upsellState,
      original_room_type: fromRoomText?.value,
      requested_room_type: toRoomText?.value,
      payment_status: item?.booking?.bookingCharges?.length ? 'paid' : 'no-paid',
      upsell_price: item?.booking?.bookingCharges[0] ? `${item?.booking?.bookingCharges[0]?.amount} ${item?.booking?.bookingCharges[0]?.currency}` : '-',
    };
    return { upsellState, tags };
  }
}
