function getItem(key) {
  return JSON.parse(localStorage.getItem(key));
}

function saveItem(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

function removeItem(key) {
  localStorage.removeItem(key);
}

const AppStorage = {
  KEYS: {
    SELECTED_HOTEL: 'selectedHotel',
    GUESTLINK_SELECTED_LANGUAGE: 'guestlinkSelectedLanguage',
  },
  getItem,
  saveItem,
  removeItem,
};

export default AppStorage;
