import axios from 'axios';
import apiConfig from '../api.config';

export default function upsellSendEmailToHotelApprovedRequest({
  hotelSlug,
  pmsId,
  upsellState,
  tags,
}) {
  const {
    guestlinkHost,
    URIs: { upsellSendEmailToHotel },
  } = apiConfig;

  // workaround: Guestlink does not support htts rigth now on PROD
  const uri = `https://upsell-api.civitfun.com/${upsellSendEmailToHotel}`;

  const schedule = upsellState == 'accepted' ? 'hotel-accepted' : 'hotel-rejected';

  return axios
    .post(uri, JSON.stringify({ hotelSlug, pmsId, schedule, upsellState, options: { tags } }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
