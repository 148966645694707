import { useState, useContext } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import { groupByKey } from 'utils/TextUtils';
import { notification } from 'antd';
import { AppContext } from 'App.context';

export default function usePmsConfigHook(setPmsCheckin, rowData) {
  const [state, setState] = useState({
    searchByCodeResult: null,
    searchByEntranceDateResult: null,
    searchByHolderNameResult: null,
    searchByIdResult: null,
    isLoading: false,
    data: null,
    orderedConfig: {},
    isTesting: false,
    testResult: null,
    isTestingBooking: false,
    testBookingResult: null,
    showResults: false,
    parameters: {
      bookingIdentifier: false,
      bookingCode: false,
      entranceDate: false,
      holderName: false,
    },
  });

  const { API } = useContext(ApiContext);
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const actions = {
    loadPmsConfig: async (pms, hotelId) => {
      if (pms.connectionType !== 'form') return;
      updateState({ isLoading: true });

      API.pmsConfigRequest({ pmsId: pms.id, hotelId })
        .then(res => {
          for (const field of res) {
            if (field.type === 'split') {
              field.splitValues = field.fields.map(() => '');

              if (field.value) {
                field.value.split(field.separator).forEach((subFieldValue, index) => {
                  if (subFieldValue) {
                    if ((pms.id === 21 || pms.id === 30) && field.key != 'pms_key') {
                      const splitIndex = subFieldValue.indexOf('=');
                      field.splitValues[index] =
                        splitIndex !== -1 ? subFieldValue.slice(splitIndex + 1) : subFieldValue;
                    } else {
                      field.splitValues[index] = subFieldValue;
                    }
                  }
                });
              }
            }
          }

          const orderedConfig = groupByKey(res, 'category');
          updateState({ data: res, isLoading: false, orderedConfig });
        })
        .catch(error => {
          updateState({ isLoading: false });
          console.error('Error loading PMS config:', error);
        });
    },

    onValueChanged: (key, text, splitValues, table) => {
      const dataCopy = state.data.map(field => {
        if (field.key === key && (!table || table === field.table)) {
          field.value = text;
          if (splitValues) {
            field.splitValues = splitValues;
          }
        }
        return field;
      });

      updateState({ data: dataCopy });
    },

    getFieldValue: (key, table) => {
      const index = state.data.findIndex(x => x.key === key && (table ? x.table === table : true));
      return state.data[index]?.value;
    },

    onSave: (pms, hotelId, currentPms) => {
      updateState({ isLoading: true });
      API.updatePmsConfigRequest({ hotelId, fields: state.data, pms })
        .then(_ => {
          if (!currentPms || currentPms === 'NoIntegration') {
            API.pmsConfigHotelRequest({ hotelId, pmsName: pms.name, pmsId: pms.id })
              .then(_ => {
                setPmsCheckin(hotelId, pms.name);
                actions.loadPmsConfig(pms, hotelId);
              })
              .catch(err => console.error(err));
          } else {
            actions.loadPmsConfig(pms, hotelId);
          }
        })
        .catch(_ => {
          updateState({ isLoading: false });
        });
    },

    doAutomaticTest: async hotelId => {
      const connectionResponse = await API.connectionValidationRequest({
        hotelId,
      });
      connectionResponse?.success
        ? notification.success({
            message: translate('pmsScreen.connectSuccess'),
          })
        : notification.error({ message: translate('pmsScreen.connectError') });
    },
    doAutomaticTest: async hotelId => {
      const connectionResponse = await API.connectionValidationRequest({ hotelId });
      connectionResponse?.success
        ? notification.success({ message: translate('pmsScreen.connectSuccess') })
        : notification.error({ message: translate('pmsScreen.connectError') });
    },
    doBookingTest: async (bookingData, hotelId) => {
      // Inicializar el estado antes de la prueba
      updateState({ isTestingBooking: true, showResults: false });

      try {
        // Realizar la solicitud de validación de búsqueda
        const searchesResponse = await API.searchesValidationRequest({ hotelId, bookingData });

        // Desestructurar los resultados de búsqueda de la respuesta
        const {
          searchByCodeResult,
          searchByEntranceDateResult,
          searchByHolderNameResult,
          searchByIdResult,
        } = searchesResponse;

        // Verificar si los parámetros están presentes en los datos de reserva
        const parameters = {
          bookingIdentifier: Boolean(bookingData.bookingIdentifier),
          bookingCode: Boolean(bookingData.bookingCode),
          entranceDate: Boolean(bookingData.entranceDate),
          holderName: Boolean(bookingData.holderName),
        };

        // Manejar cada resultado de búsqueda, asegurando que los errores se capturen adecuadamente
        const codeResult = parameters.bookingCode
          ? searchByCodeResult
          : { success: false, error: 'No se proporcionó bookingCode o no se pudo validar.' };

        const entranceDateResult = parameters.entranceDate
          ? searchByEntranceDateResult
          : { success: false, error: 'No se proporcionó entranceDate o no se pudo validar.' };

        const holderNameResult = parameters.holderName
          ? searchByHolderNameResult
          : { success: false, error: 'No se proporcionó holderName o no se pudo validar.' };

        const idResult = searchByIdResult || { success: false, error: 'Error al buscar por ID.' };

        // Actualizar el estado con los resultados de las búsquedas y mostrar los resultados
        updateState({
          searchByCodeResult: codeResult,
          searchByEntranceDateResult: entranceDateResult,
          searchByHolderNameResult: holderNameResult,
          searchByIdResult: idResult,
          isTestingBooking: false,
          showResults: true,
          parameters,
        });
      } catch (error) {
        // Manejo de errores en la llamada a la API
        console.error('Error during booking test:', error);
        updateState({
          isTestingBooking: false,
          showResults: true,
          searchByCodeResult: {
            success: false,
            error: 'Error de red o del servidor al buscar por código.',
          },
          searchByEntranceDateResult: {
            success: false,
            error: 'Error de red o del servidor al buscar por fecha de entrada.',
          },
          searchByHolderNameResult: {
            success: false,
            error: 'Error de red o del servidor al buscar por nombre del titular.',
          },
          searchByIdResult: {
            success: false,
            error: 'Error de red o del servidor al buscar por ID.',
          },
        });
      }
    },
  };

  // Función para actualizar el estado
  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
