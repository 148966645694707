import axios from 'axios';
import apiConfig from '../api.config';

export default function updateGuestFormRequest({ hotelId, data, hotelFields, customTranslations }) {
  const {
    host,
    URIs: { updateForms },
  } = apiConfig;

  const uri = `${host}${updateForms}`;

  const requestData = {
    hotelId,
    customFieldsUpdated: data,
    hotelFields,
    customTranslations,
  };

  return axios
    .put(uri, JSON.stringify(requestData), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
