import { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../App.context';
import Colors from '../../../constants/Colors';
import constants from '../../../utils/constants';
import moment from 'moment';
import 'moment/locale/es';
import { ApiContext } from '../../../utils/networking/Api.context';
import { notification } from 'antd';
import useCheckInStatsExportHook from 'utils/export/CheckinStatsExport.hook';
import { capitalize } from 'utils/TextUtils';
import SupportedSteps from './StepsGraph/SupportedSteps';
import isValidDateRange from 'utils/export/isValidDateRange';

const useCheckinStatsHook = () => {
  const { API } = useContext(ApiContext);
  const {
    appState,
    appActions: { translate, getSelectedLanguage, getSelectedHotel },
  } = useContext(AppContext);
  const { checkinStatsExportActions } = useCheckInStatsExportHook();

  const [state, setState] = useState({
    data: null,
    isLoading: false,
    selectedHotel: null,
    firstCall: true,
    tableDataset: null,
    datesRange: getDefaultDateRange(),
    averageCheckinDuration: null,
    conversionRate: null,
    averageAdvanceCheckinTime: null,
    mostCheckinsDay: null,
    mostCheckinsHour: null,
    devices: null,
    totalCheckins: null,
    lastStepsData: null,
  });

  useEffect(() => {
    if (state.firstCall) {
      updateState({ firstCall: false });
    } else {
      actions.getStatsData();
    }
  }, [state.datesRange, state.selectedHotel]);

  useEffect(() => {
    actions.onHotelSelected(getSelectedHotel());
  }, [appState.selectedHotel]);

  const actions = {
    getStatsData: () => {
      updateState({ isLoading: true });
      API.checkinStats({
        hotelId: getSelectedHotel()?.key || null,
        datesRange: state.datesRange || getDefaultDateRange(),
      })
        .then(res => {
          const data = actions.parseStatsData(res?.checkins);
          const tableData = actions.parseTableData(res?.checkins);

          updateState({
            isLoading: false,
            data,
            tableDataset: tableData,
            conversionRate: res?.startedCheckins ? res?.conversionRate : null,
            averageAdvanceCheckinTime: res?.averageAdvanceCheckinTime,
            mostCheckinsDay: res?.mostCheckinsDay,
            mostCheckinsHour: res?.mostCheckinsHour,
            averageCheckinDuration: res?.averageDuration,
            devices: res?.devices,
            totalCheckins: res?.finishedCheckins,
            lastStepsData:
              actions.isStepDataValid() && res?.lastStepsData
                ? actions.parseStepData(res?.lastStepsData, res?.startedCheckins)
                : null,
          });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },

    parseStatsData: response => {
      const labels = response.map(item => {
        return item.day;
      });

      const totalDataset = {
        label: translate('stats.total'),
        data: actions.getTotalDayCount(response),
        backgroundColor: Colors.totalStats,
        borderColor: Colors.totalStatsTransparent,
      };

      const civitfunDataset = {
        label: translate('ihClient.CIVITFUN'),
        data: actions.getClientData(constants.IH_CLIENT.CIVITFUN, response),
        backgroundColor: Colors.civitfunColor,
        borderColor: Colors.civitfunTransparent,
      };

      const bookingDataset = {
        label: translate('ihClient.BOOKINGDOTCOM'),
        data: actions.getClientData(constants.IH_CLIENT.BOOKINGDOTCOM, response),
        backgroundColor: Colors.bookingColor,
        borderColor: Colors.bookingTransparent,
      };

      const mtsDataset = {
        label: translate('ihClient.MTS'),
        data: actions.getClientData(constants.IH_CLIENT.MTS, response),
        backgroundColor: Colors.mtsColor,
        borderColor: Colors.mtsTransparent,
      };

      const receptionDataset = {
        label: translate('ihClient.RECEPTION'),
        data: actions.getClientData(constants.IH_CLIENT.RECEPTION, response),
        backgroundColor: Colors.greenPill,
        borderColor: Colors.greenPill,
      };

      return {
        labels,
        datasets: [totalDataset, civitfunDataset, bookingDataset, mtsDataset, receptionDataset],
      };
    },

    parseTableData: days => {
      let totalCount = 0;
      let data = [];

      days.forEach(day => {
        day.checkins.forEach(client => {
          totalCount = totalCount + client.count;

          const clientData = data.find(item => item.clientId == client.clientId);
          if (clientData) {
            clientData.count = clientData.count + client.count;
            data = data.map(savedClient =>
              savedClient.clientId !== clientData.clientId ? savedClient : clientData
            );
          } else {
            data.push({
              name: client.clientName,
              clientId: client.clientId,
              count: client.count,
            });
          }
        });
      });

      data.unshift({
        name: translate('stats.total'),
        clientId: 0,
        count: totalCount,
      });

      return data;
    },

    getClientData: (clientId, days) => {
      const clientData = [];
      days.forEach((day, index) => {
        const checkin = day.checkins.find(checkin => checkin.clientId == clientId);
        clientData[index] = checkin?.count || 0;
      });

      return clientData;
    },

    getTotalDayCount: response => {
      const totalCount = [];
      response.forEach((day, index) => {
        var dayCount = 0;
        day.checkins.forEach(checkin => {
          dayCount = dayCount + checkin.count;
        });

        totalCount[index] = dayCount;
      });

      return totalCount;
    },

    export: () => {
      const dateFrom = moment(state.datesRange[0]).format('YYYY-MM-DD');
      const dateTo = moment(state.datesRange[1]).format('YYYY-MM-DD');

      if (!dateFrom || !dateTo) {
        notification.info({
          message: 'Debe seleccionar un rango de fechas para realizar la exportación',
        });
        return;
      }

      if (isValidDateRange(state.datesRange)) {
        checkinStatsExportActions.export(state.selectedHotel?.key, dateFrom, dateTo);
      }
    },

    onDateChange: (_date, dateString) => {
      if (isValidDateRange(dateString)) {
        updateState({ datesRange: dateString });
      }
    },

    onHotelSelected: hotel => {
      updateState({ selectedHotel: hotel });
    },

    getAverage: valuesList => {
      return valuesList.reduce((p, c) => p + c, 0) / valuesList.length;
    },

    getFormattedAvgDuration: () => {
      var formattedDuration = translate('stats.avgDuration.noData');
      if (state.averageCheckinDuration) {
        var minutes = Math.floor(state.averageCheckinDuration / 60);
        var seconds = Math.round(state.averageCheckinDuration - minutes * 60);

        formattedDuration = `${minutes}m ${seconds}s`;
      }

      return formattedDuration;
    },

    getConversionDuration: () => {
      return state.conversionRate
        ? `${Math.round(state.conversionRate)}%`
        : translate('stats.conversion.noData');
    },

    getAverageAdvanceCheckinTime: () => {
      if (state.averageAdvanceCheckinTime) {
        const duration = new moment.duration(state.averageAdvanceCheckinTime);

        //Get Days and subtract from duration
        const days = duration.asDays();
        duration.subtract(moment.duration(days, 'days'));

        //Get hours and subtract from duration
        const hours = duration.hours();

        return {
          days: `${Math.trunc(days)} ${translate('stats.advance.days')}`,
          hours: `${Math.abs(hours)} ${translate('stats.advance.hours')}`,
        };
      } else {
        return translate('stats.advance.noData');
      }
    },

    getMostCheckinsDateTime: () => {
      if (
        state.mostCheckinsDay !== null &&
        !isNaN(state.mostCheckinsDay) &&
        state.mostCheckinsDay <= 6 &&
        state.mostCheckinsHour !== null &&
        !isNaN(state.mostCheckinsHour)
      ) {
        const date = moment(state.mostCheckinsDay, 'd');
        date.locale(getSelectedLanguage().id);

        return {
          date: `${capitalize(date.format('dddd'))}`,
          time: `${moment(state.mostCheckinsHour, 'h').format('HH')}:00`,
        };
      } else {
        return translate('stats.mostCheckins.noData');
      }
    },

    isStepDataValid: () => {
      const maxDate = moment('22-02-2022', 'DD-MM-YYYY');
      if (
        moment(state.datesRange[0]).isAfter(maxDate) &&
        moment(state.datesRange[1]).isAfter(maxDate)
      ) {
        return true;
      } else {
        return false;
      }
    },

    parseStepData: (lastStepsData, startedCheckins) => {
      let sortedStepData = SupportedSteps.map(supportedStep => {
        const step = lastStepsData[supportedStep.step]
          ? { name: supportedStep.step, checkins: lastStepsData[supportedStep.step] }
          : null;
        if (step) {
          step.literal = supportedStep?.literal;
          return step;
        } else {
          return { step: supportedStep.step, checkins: 0, literal: supportedStep?.literal };
        }
      }).filter(element => {
        return element !== undefined;
      });

      const totalCheckins = startedCheckins;
      let checkinsCount = startedCheckins;
      sortedStepData.forEach(step => {
        step.checkinsPassed = checkinsCount;
        checkinsCount = checkinsCount - step?.checkins;
      });

      const ret = sortedStepData.map(step => {
        return {
          name: translate(`steps.${step.literal}`) || 'Other Steps',
          percentage: Math.round((step?.checkinsPassed / totalCheckins) * 100),
          step: step.step,
        };
      });

      const hideOptionalSteps = ret.filter(obj => {
        return (
          obj.step !== 'GuestFormUpgrade' && obj.step !== 'GuestFormUpsell' && obj.name !== 'Upsell'
        );
      });

      return hideOptionalSteps;
    },
  };

  function getDefaultDateRange() {
    return [
      moment().subtract(0, 'months').startOf('month'),
      moment().subtract(0, 'months').endOf('month'),
    ];
  }

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
};

export default useCheckinStatsHook;
