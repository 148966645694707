import Colors from '../../../constants/Colors';

export default {
  container: {
    borderRadius: 8,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 4,
    paddingBottom: 4,
    color: Colors.white,
    fontWeight: 'bold',
  },
};
