import moment from 'moment';
import { days } from 'Sell/CrossSell/utils';

const getInitialStateDays = props =>
  days.map((day, index) => ({
    id: `${day}-${index}`,
    name: day,
    order: index,
    startTime: null,
    endTime: null,
    enabled: false,
    ...props,
  }));

export const getInitialState = state => ({
  status: 'active',
  confirmationType: 'automatic',
  type: 'service',
  texts: {},
  itemGalleries: [],
  slot: {
    id: null,
    texts: {},
    quantity: 1,
    startDate: null,
    endDate: null,
    days: getInitialStateDays(),
    returnDays: getInitialStateDays({ isReturn: true }),
  },
  slots: [],
  ...state,
});

export const syncSlots = ({ slot, slots }) => {
  // If the slot is new, add it to the slots array without the temp id
  if (typeof slot.id === 'string' && slots.every(s => s.id !== slot.id)) {
    return [...slots, { ...slot, id: undefined }];
  }

  // If the slot is already in the slots array, update it
  return slots.map(s => {
    if (typeof s.id === 'string') {
      return { ...s, id: undefined };
    }

    if (s.id === slot.id) {
      return slot;
    }

    return s;
  });
};

export const mapExistingDay = (days, props) => (reducer, dayName, index) => {
  const existingDays = days.filter(day => day.name === dayName);

  if (existingDays.length) {
    return [
      ...reducer,
      ...existingDays
        .map(existingDay => ({
          ...existingDay,
          startTime: moment(existingDay.startTime, 'HH:mm'),
          endTime: existingDay.endTime ? moment(existingDay.endTime, 'HH:mm') : null,
          enabled: true,
          ...props,
        }))
        .sort((a, b) => a.startTime - b.startTime)
        .map((day, idx) => ({ ...day, order: parseFloat(`${index}.${idx}`) })),
    ];
  }

  return [
    ...reducer,
    {
      id: `${dayName}-${index}`,
      name: dayName,
      enabled: false,
      startTime: moment('00:00', 'HH:mm'),
      endTime: moment('00:00', 'HH:mm'),
      order: index,
      ...props,
    },
  ];
};
