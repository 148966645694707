import axios from 'axios';
import apiConfig from '../api.config';

export default function doorOpeningUpdateToHotelRequest({
  hotelId,
  doorOpeningId,
  name,
  pmsId,
  openingId,
}) {
  const {
    host,
    URIs: { updateDoorOpeningToHotel },
  } = apiConfig;

  const uri = `${host}${updateDoorOpeningToHotel
    .replace(':hotelId', hotelId)
    .replace(':doorId', doorOpeningId)}`;

  return axios
    .put(uri, JSON.stringify({ name, pmsId, openingId }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
