import axios from 'axios';
import apiConfig from '../api.config';

export default function getDoorOpeningSuscriptionStatus({ hotelId }) {
  const {
    host,
    URIs: { doorOpeningSuscriptionStatus },
  } = apiConfig;

  const uri = `${host}${doorOpeningSuscriptionStatus.replace(':hotelId', hotelId)}`;

  return axios
    .get(uri, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data?.suscription || false;
    }).catch(err => {
      console.error(err);
      return false;
    });
}
