import { notification } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { AppContext } from 'App.context';
import {
  useAmenitiesQuery,
  useRoomFeaturesQuery,
  useRoomTypeFind,
  useRoomTypeMutation,
  useRoomTypeQuery,
} from 'Sell/Upsell/RoomType/RoomType.service';
import { actionTypes, roomReducer } from './reducer';
import { generateUniqueImageName, getRoomTypeId } from 'Sell/utils/utils';
import { uploadImages } from 'Sell/Upsell/API/api.config';
import {
  createRoomAlert,
  validateRoomData,
} from 'Sell/Upsell/RoomType/Upsert/FormRoomType/FormRoomTypeValidation';
import { useAppConfigQuery } from 'Sell/Upsell/API/queries';

const getInitialState = state => ({
  selectedLanguage: '',
  currentUpselOptions: { toPhysicalRoomTypeId: 0, price: 0, priceType: '', order: 0 },
  currentTexts: { type: 'desc', value: '' },
  currentName: { type: 'name', value: '' },
  texts: [],
  upsellOptionsFrom: [],
  roomFeatures: [],
  amenities: [],
  physicalRoomGalleries: [],
  status: 'active',
  capacity: 0,
  confirmationType: 'manual',
  ...state,
});

const useRoomTypeManager = () => {
  const history = useHistory();
  const location = useLocation();
  const { appActions, appState } = useContext(AppContext);
  const selectedHotel = appActions.getSelectedHotel();
  const [state, dispatch] = useReducer(
    roomReducer,
    getInitialState({ hotelId: selectedHotel.id, chain: appState.chain })
  );
  const [pageLoading, setPageLoading] = useState(true);
  const [fileList, setFileList] = useState([]);

  const {
    appState: {
      currentLanguage: { id: currentLang },
      hotelLanguages
    },
  } = useContext(AppContext);
  const idRoomType = getRoomTypeId(location);
  const { isLoading, data, remove } = useRoomTypeFind({ id: idRoomType });
  const { data: amenitiesList } = useAmenitiesQuery(currentLang);
  const { data: featuresList } = useRoomFeaturesQuery(currentLang);
  const { data: roomTypes } = useRoomTypeQuery({});
  const { data: appConfig } = useAppConfigQuery();
  const { mutate } = useRoomTypeMutation({ onSuccess: remove });

  const memoizedData = useMemo(() => data, [data?.data]);

  useEffect(() => {
    if (!isLoading) {
      loadRoomState(memoizedData?.data);
      handleLanguageChange(currentLang);
    }
    setPageLoading(isLoading);
  }, [memoizedData]);

  const handleCheckAmenities = (feature, isChecked) => {
    setAmenities(
      isChecked ? [...state.amenities, feature] : state.amenities.filter(f => f.id !== feature.id)
    );
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList);

  const singleUploader = async () => {
    const imagesUploaded = [];
    const [lastImage] = state.physicalRoomGalleries.sort((a, b) => a.order - b.order);
    const lastorder = lastImage?.order || 0;

    for (const [index, file] of fileList.entries()) {
      const formData = new FormData();
      formData.append(
        'image',
        file.originFileObj,
        generateUniqueImageName(file.originFileObj.name)
      );

      const result = await uploadImages('images/upload', formData);
      const response = await result.json();
      imagesUploaded.push({ path: response.url, order: index + lastorder });
    }

    return imagesUploaded;
  };

  const handleCheckFeatures = (feature, isChecked) => {
    setRoomFeatures(
      isChecked
        ? [...state.roomFeatures, feature]
        : state.roomFeatures.filter(f => f.id !== feature.id)
    );
  };
  const handleConfirmationChange = value => {
    setConfirmationType(value);
  };
  const handleClickBack = () => {
    history.goBack();
  };

  const handleClickSaveRoom = async () => {
    if (!validateRoomData(state, appActions.translate)) {
      return;
    }
    setPageLoading(true);
    const uploadedImages = await singleUploader();

    const roomData = {
      ...state,
      chainId: state.chainId || state.chain?.id,
      physicalRoomGalleries: [...state.physicalRoomGalleries, ...uploadedImages],
    };
    delete roomData.currentTexts;
    delete roomData.currentName;
    delete roomData.currentUpselOptions;
    delete roomData.selectedLanguage;

    const isRoomBeingUpdated = !!state.id;

    mutate(
      { ...roomData },
      {
        onSuccess: e => {
          e.data && setRoomId(e.data.id);
          if (!isRoomBeingUpdated) {
            createRoomAlert('create', appActions.translate);
            history.push(`/upsell/rooms/upsert/${e.data.id}`);
          } else {
            createRoomAlert('update', appActions.translate);
          }
        },
        onError: error => {
          notification.warning({
            message: appActions.translate(error.message),
          });
        },
      }
    );

    setFileList([]);
    setAmenities([]);
    setRoomFeatures([]);
    setPageLoading(false);
  };

  const handleAddUpsellToOptions = () => {
    dispatch({ type: actionTypes.ADD_UPSELL_TO_OPTIONS, payload: state.currentUpselOptions });
  };
  const handleSetEditUpsell = record => {
    dispatch({ type: actionTypes.SET_EDIT_UPSELL_TO_OPTIONS, payload: record });
  };
  const handleUpdateUpsellToOptions = options => {
    dispatch({ type: actionTypes.UPDATE_UPSELL_TO_OPTIONS, payload: options });
  };
  const handleEditUpsellToOptions = () => {
    dispatch({ type: actionTypes.EDIT_UPSELL_TO_OPTIONS, payload: state.currentUpselOptions });
  };
  const handleDeleteUpsellToOptions = id => {
    dispatch({ type: actionTypes.DELTETE_UPSELL_TO_OPTIONS, payload: id });
  };
  const setPMSId = pmsCode => {
    dispatch({ type: actionTypes.SET_PMS_ID, payload: { value: pmsCode, type: 'code' } });
  };
  const setAmenities = amenities => {
    dispatch({ type: actionTypes.SET_AMENITIES, payload: { value: amenities, type: 'amenities' } });
  };
  const setImages = imageList => {
    dispatch({
      type: actionTypes.SET_IMAGE_LIST,
      payload: { value: imageList, type: 'physicalRoomGalleries' },
    });
  };
  const setRoomId = id => {
    dispatch({
      type: actionTypes.SET_ROOM_ID,
      payload: { value: id, type: 'id' },
    });
  };
  const setRoomFeatures = features => {
    dispatch({
      type: actionTypes.SET_ROOM_FEATURES,
      payload: { value: features, type: 'roomFeatures' },
    });
  };
  const setConfirmationType = confirmationType => {
    dispatch({
      type: actionTypes.SET_CONFIRMATION_TYPE,
      payload: { value: confirmationType, type: 'confirmationType' },
    });
  };
  const handleLanguageChange = newLanguage => {
    dispatch({ type: actionTypes.SET_LANGUAGE, payload: newLanguage });
  };
  const handleTitleChange = value => {
    dispatch({ type: actionTypes.UPDATE_ROOM_TYPE_NAME, payload: { value, type: 'name' } });
  };
  const setRoomTypeTexts = (value, type) => {
    dispatch({ type: actionTypes.UPDATE_ROOM_TYPE_TEXTS, payload: { value, type } });
  };
  const loadRoomState = state => {
    dispatch({ type: actionTypes.LOAD_ROOM_INFO, payload: state });
  };
  const handlePriceCHange = price => {
    dispatch({
      type: actionTypes.SET_UPSELL_PRICE,
      payload: { value: Number(price), type: 'price' },
    });
  };
  const handleIdCHange = id => {
    dispatch({
      type: actionTypes.SET_UPSELL_ID,
      payload: { value: id, type: 'id' },
    });
  };
  const handleTypePriceCHange = type => {
    dispatch({
      type: actionTypes.SET_UPSELL_TYPE_PRICE,
      payload: { value: type, type: 'priceType' },
    });
  };
  const handleRoomTypeChange = roomType => {
    dispatch({
      type: actionTypes.SET_UPSELL_TYPE_ROOM,
      payload: { value: roomType, type: 'toPhysicalRoomTypeId' },
    });
  };
  const handleDeleteImage = imageId => {
    dispatch({ type: actionTypes.DELETE_IMAGE, payload: imageId });
  };
  const handleFromRoomTypeChange = roomType => {
    dispatch({
      type: actionTypes.SET_UPSELL_FROM_TYPE_ROOM,
      payload: { value: roomType, type: 'fromPhysicalRoomTypeId' },
    });
  };
  const handleOnPhotoDragEnd = photos => {
    dispatch({ type: actionTypes.REORDER_IMAGE, payload: photos });
  };

  return {
    dataSource: {
      state,
      amenitiesList,
      featuresList,
      currentLang,
      roomTypes,
      isLoading: pageLoading,
      fileList,
      currency: appConfig?.currency,
      hotelLanguages
    },
    setPMSId,
    setImages,
    setConfirmationType,
    setRoomTypeTexts,
    handleClickBack,
    handleClickSaveRoom,
    handleLanguageChange,
    handleConfirmationChange,
    handleTitleChange,
    handleCheckAmenities,
    handleCheckFeatures,
    handleFileChange,
    handleAddUpsellToOptions,
    handleUpdateUpsellToOptions,
    handleDeleteUpsellToOptions,
    handleEditUpsellToOptions,
    handleSetEditUpsell,
    handleIdCHange,
    handlePriceCHange,
    handleTypePriceCHange,
    handleFromRoomTypeChange,
    handleRoomTypeChange,
    handleDeleteImage,
    handleOnPhotoDragEnd,
  };
};

export default useRoomTypeManager;
