import React, { useContext } from 'react';
import { AppContext } from 'App.context';
import { Button } from 'antd';
import AppSelector from 'Components/common/AppSelector/AppSelector';
import AppInput from 'Components/common/AppInput/AppInput.jsx';
import typeOfPriceOptions from 'Sell/Upsell/fixtures/typeOfPriceOptions.js';
import { ReactComponent as PlusIcon } from 'Sell/assets/icon/plus.svg';
import { ReactComponent as EditIcon } from 'Sell/assets/icon/edit-icon.svg';
import styles from './FormRoomUpsellToOption.styles.js';
import './FormRoomUpsellToOption.css';

export default function FormRoomUpsellToOption({
  roomId,
  onAdd,
  onRoomTypeChange,
  onTypeOfPriceChange,
  onPriceChange,
  roomUpsellToOptions,
  editMode,
  currentUpsell,
  onEdit,
}) {
  const {
    appActions: { translate, getSelectedLanguage },
  } = useContext(AppContext);

  const { id: langId } = getSelectedLanguage();

  return (
    <div style={styles.container}>
      <AppSelector
        options={roomUpsellToOptions
          ?.filter(room => room.id !== roomId)
          .map(room => ({
            value: room.id,
            label: room.texts?.find(text => text.lang === langId && text.type === 'name')?.value,
          }))}
        value={currentUpsell.toPhysicalRoomTypeId ? currentUpsell?.toPhysicalRoomTypeId : null}
        title={translate('upsell.roomType')}
        style={styles.roomType}
        onOptionSelected={onRoomTypeChange}
      />
      <AppSelector
        options={typeOfPriceOptions}
        value={currentUpsell.priceType ? currentUpsell?.priceType : null}
        title={translate('upsell.typeOfPrice')}
        style={styles.typeOfPrice}
        onOptionSelected={onTypeOfPriceChange}
      />
      <div className="specific-input-wrapper">
        <AppInput
          value={currentUpsell.price ? currentUpsell?.price : null}
          title={translate('upsell.price')}
          style={styles.price}
          bordered={false}
          onChange={onPriceChange}
        />
      </div>
      <Button
        icon={editMode ? <EditIcon style={styles.icon} /> : <PlusIcon style={styles.icon} />}
        type="primary"
        style={styles.button}
        onClick={editMode ? onEdit : onAdd}
      />
    </div>
  );
}
