import React from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';

function getPMSUDFConfigColumns({
  translate,
  onClickEdit,
  onClickDelete,
  getHotelUdfField,
  isLoadingForm,
}) {
  return [
    {
      title: translate('pmsUdf.table.id'),
      key: 'id',
      dataIndex: 'id',
      align: 'left',
      render: (_, row) => <div>{row.id}</div>,
    },
    {
      title: translate('pmsUdf.table.name'),
      key: 'name',
      dataIndex: 'name',
      align: 'left',
      render: (_, row) => <div>{row.name}</div>,
    },
    {
      title: translate('pmsUdf.table.referenceField'),
      key: 'referenceField',
      dataIndex: 'referenceField',
      align: 'left',
      render: (_, row) => <div>{getHotelUdfField(row.validate)}</div>,
    },
    {
      title: translate('pmsUdf.table.edit'),
      key: 'edit',
      dataIndex: 'edit',
      align: 'left',
      width: 100,
      render: (_, row) => (
        <div style={{ display: 'flex' }}>
          <Button
            icon={<EditOutlined />}
            size="small"
            style={{ borderRadius: 5 }}
            onClick={() => onClickEdit(row.id)}
            disabled={isLoadingForm}
          ></Button>
          <Button
            icon={<DeleteOutlined />}
            size="small"
            style={{ borderRadius: 5, marginLeft: 2 }}
            onClick={() => onClickDelete(row.id)}
            disabled={isLoadingForm}
          ></Button>
        </div>
      ),
    },
  ];
}

export default { getPMSUDFConfigColumns };
