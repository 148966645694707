import checkinValidationRequest from './checkin.service';
import confirmPDFGenerationRequest from './confirmPDFGeneration.service';
import connectionValidationRequest from './connection.service';
import generateEmailValidationRequest from './generateEmail.service';
import guestValidationRequest from './guest.service';
import guestDataValidationRequest from './guestData.service';
import onboardingValidationRequest from './onboarding.service';
import paperlessValidationRequest from './paperless.service';
import paymentValidationRequest from './payment.service';
import searchesValidationRequest from './searches.service';

export default {
  checkinValidationRequest,
  confirmPDFGenerationRequest,
  connectionValidationRequest,
  generateEmailValidationRequest,
  guestValidationRequest,
  guestDataValidationRequest,
  onboardingValidationRequest,
  paperlessValidationRequest,
  paymentValidationRequest,
  searchesValidationRequest,
};
