import axios from 'axios';
import apiConfig from '../api.config';

export default function updateHotelPaymentConfig({ hotelId, platform, parameters }) {
  const {
    host,
    URIs: { updateHotelPaymentConfig },
  } = apiConfig;

  const uri = `${host}${updateHotelPaymentConfig.replace(':hotelId', hotelId)}`;

  return axios
    .put(uri, JSON.stringify({ platform, parameters }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
