export default {
  tesa: {
    operatorName: 'Nombre operador',
    operatorPassword: 'Contraseña operador',
    endpoint: 'Endpoint',
  },
  generic: {},
  ttlock: {
    username: 'Cuenta',
    password: 'Contraseña',
    clientId: 'Client ID',
    clientSecret: 'Client Secret',
  },
  'assa abloy': {
    token: 'Token',
  },
  yacan: {
    user: 'Usuario',
    password: 'Contraseña',
  },
  akiles: {
    apiKey: 'API Key',
  },
  'salto space': {
    address: 'Dirección',
    port: 'Puerto',
  },
  'salto ks': {
    username: 'Usuario',
    password: 'Contraseña',
    siteId: 'Site ID',
  },
  'omnitec rent&pass': {
    username: 'Nombre de usuario',
    password: 'Contraseña',
    clientId: 'Client ID',
    clientSecret: 'Client Secret'
  },
  'omnitec os access': {
    instance: 'Instancia',
    apikey: 'API Key',
    accessRightsPerArea: 'Derechos por área',
    timezone: 'Zona horaria'
  },
  'padword': {
    username: 'Usuario',
    password: 'Contraseña',
  },
};
