export default [
  { step: 'GuestFormGuestData', literal: 'guestData' },
  { step: 'GuestFormSurveys', literal: 'surveys' },
  { step: 'GuestFormUpgrade', literal: 'upgrade' },
  { step: 'GuestFormUpsell', literal: 'upsell' },
  { step: 'GuestFormPayments', literal: 'payments' },
  { step: 'GueststepPaymentResult', literal: 'confirmPayment' },
  { step: 'GuestFormSeeContracts', literal: 'contracts' },
  { step: 'GuestFormConfirm', literal: 'confirm' },
];
