import axios from 'axios';
import apiConfig from '../api.config';

export default function salesChannelsRequest(page, size, filters, sort, hotel) {
  const {
    host,
    URIs: { salesChannels },
  } = apiConfig;

  var filter = { page, size, ...filters };
  if (hotel && hotel.key != 'null') filter.hotel = hotel.key;
  if (sort) filter.order = sort;

  var uri = `${host}${salesChannels}?filter=${JSON.stringify(filter)}`;

  return axios
    .get(uri, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
