import { Input } from 'antd';
import { AppContext } from 'App.context';
import CustomButton from 'Components/common/CustomButton/CustomButton';
import Colors from 'constants/Colors';
import React, { useContext } from 'react';
import styles from './CreatePms.styles';

export default function CreatePms({ onClick, showButton, onChange, value }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <div style={styles.wrapper}>
      <div style={styles.description}>{translate('selectPMS.createDescription')}</div>
      <div style={styles.container}>
        <div style={styles.inputContainer}>
          <div style={styles.title}>{translate('selectPMS.yourPMS')}</div>
          <Input
            value={value}
            style={styles.createInput}
            placeholder={translate('selectPMS.pmsInput')}
            bordered={false}
            onChange={onChange}
          />
        </div>

        {showButton && (
          <CustomButton
            background={Colors.blueColor}
            color={'white'}
            text={translate('selectPMS.create')}
            onClick={() => onClick && onClick(value)}
          />
        )}
      </div>
    </div>
  );
}
