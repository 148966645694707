import axios from 'axios';
import apiConfig from '../api.config';
import constants from '../../constants';
import storage from '../../storage';

export default function findHotelInvoiceByInvoiceId({ hotelId, invoiceId }) {
  const {
    billingHost,
    URIs: { findHotelInvoiceByInvoiceId },
  } = apiConfig;

  const uri = `${billingHost}${findHotelInvoiceByInvoiceId}`.replace(':invoiceId', invoiceId);

  const storedCredentials = JSON.parse(storage.get(constants.AUTH.USER_KEY));
  
  return axios
    .post(uri, {
      data: {
        hotelId: hotelId || 0,
        hotelReference: 'civitfun',
        accessToken: storedCredentials.accessToken,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
