import React, { useContext } from 'react';
import styles from './Checkin.styles';
import DefaultLayout from '../../DefaultLayout/DefaultLayout';
import ContentBox from '../../common/ContentBox/ContentBox';
import { AppContext } from '../../../App.context';
import useCheckinConfigHook from './CheckIn.hook';
import Loading from '../../common/Loader/Loading';
import CheckinConfigSelector from './CheckinConfigSelector/CheckinConfigSelector';
import InfoView from '../../common/InfoView/InfoView';
import { SettingOutlined, InfoCircleOutlined, InfoCircleFilled } from '@ant-design/icons';
import SaveButton from 'Components/common/SaveButton/SaveButton';
import CheckBoxList from '../LiteralsScreen/CheckBoxList/CheckBoxList';

export default function CheckIn() {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useCheckinConfigHook();
  const popconfirmText = state.selectedHotel
    ? translate('checkinConfig.checkinWarning')
    : translate('checkinConfig.chainCheckinWarning');

  return (
    <DefaultLayout title={translate('layout.configuration')}>
      <ContentBox title={translate('setUp.searchFieldsTitle')}>
        {state.isLoading || !state.bookingSearchConfig ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {state.isLoading ? (
              <Loading />
            ) : (
              <InfoView text={translate('emptyHotelListError')} icon={<InfoCircleOutlined />} />
            )}
          </div>
        ) : (
          <div style={styles.formContainer}>
            {state.bookingSearchConfig?.map((configOption, index) => {
              return (
                <CheckinConfigSelector
                  key={index}
                  configOption={configOption?.name}
                  title={translate(`checkinConfig.${configOption?.name}`)}
                  initialValue={configOption?.state}
                  options={state.searchConfigOptions}
                  disabled={configOption.required === 1}
                  setValue={actions.setBookingSearchValue}
                  hotel={state.selectedHotel}
                />
              );
            })}

            <InfoView text={translate('setUp.searchFieldInfo')} icon={<SettingOutlined />} />

            <div style={styles.buttonContainer}>
              <SaveButton
                text={translate('save')}
                action={actions.onSaveBookingClicked}
                showPopConfirm
              />
            </div>
          </div>
        )}
      </ContentBox>

      <ContentBox title={translate('setUp.bookingSummary')}>
        {state.isLoading || !state.configOptions ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {state.isLoading ? (
              <Loading />
            ) : (
              <InfoView text={translate('emptyHotelListError')} icon={<InfoCircleOutlined />} />
            )}
          </div>
        ) : (
          <>
            <CheckBoxList
              checkBoxList={state.bookingSummary}
              selectedCheckBox={state.selectedBookingSummary}
              onCheckboxPress={actions.onCheckboxPress}
              type={"bookingSummary"}
            />
            <InfoView style={styles.info} icon={<InfoCircleFilled />}>
              <div style={styles.infoTitle}>{translate('bookingSummary.infoText').split(/"([^"]+)"/g).map((part, index) => index % 2 === 1 ? <b key={index}>{part}</b> : part)}</div>
              <div style={{ height: 16 }} />
            </InfoView>
            <div style={styles.buttonContainer}>
              <SaveButton
                text={translate('save')}
                action={actions.onSaveBookingSummary}
                showPopConfirm
              />
            </div>
          </>
        )}
      </ContentBox>

      <ContentBox title={translate('setUp.checkInRequirement')}>
        {state.isLoading || !state.configOptions ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {state.isLoading ? (
              <Loading />
            ) : (
              <InfoView text={translate('emptyHotelListError')} icon={<InfoCircleOutlined />} />
            )}
          </div>
        ) : (
          <div style={styles.formContainer}>
            {Object.keys(state.configOptions).map((configOption, index) => {
              return (
                <CheckinConfigSelector
                  key={index}
                  configOption={configOption}
                  title={translate(`checkinConfig.${configOption}`)}
                  description={translate(`checkinConfig.${configOption}_desc`)}
                  initialValue={state.tempValues[configOption]}
                  options={actions.getSelectorOptions(configOption)}
                  setValue={actions.setValue}
                  value={state.tempValues[configOption]}
                  hotel={state.selectedHotel}
                  disabledList={state.disabledSelectors}
                />
              );
            })}

            <div style={styles.buttonContainer}>
              <SaveButton text={translate('save')} action={actions.onSaveClicked} showPopConfirm />
            </div>
          </div>
        )}
      </ContentBox>

      <ContentBox title={translate('appearence.languageTitle')}>
        {state.isLoading || !state.configOptions ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {state.isLoading ? (
              <Loading />
            ) : (
              <InfoView text={translate('emptyHotelListError')} icon={<InfoCircleOutlined />} />
            )}
          </div>
        ) : (
          <>
            {/*<LanguageList
              languages={state.languages}
              selectedLanguages={state.selectedLanguages}
              onCheckboxPress={actions.onCheckboxPress}
            />*/}
            <CheckBoxList
              checkBoxList={state.languages}
              selectedCheckBox={state.selectedLanguages}
              onCheckboxPress={actions.onCheckboxPress}
              type={"languages"}
            />
            <div style={styles.buttonContainer}>
              <SaveButton
                text={translate('save')}
                action={actions.updateLanguages}
                showPopConfirm
              />
            </div>
          </>
        )}
      </ContentBox>
    </DefaultLayout>
  );
}
