import { useContext, useState } from 'react';
import moment from 'moment';

import Routes from 'Sell/CrossSell/Router/routes';
import { useCrossSellHistory } from 'Sell/CrossSell/Router/useHistory';
import { useReportsQuery, useCountersQuery, useTopSellsQuery } from './Reports.service';
import constants from 'utils/constants';
import { sumArray } from 'Sell/utils/utils';
import { useAppConfigQuery } from 'Sell/CrossSell/API/queries';
import { AppContext } from 'App.context';

const { dateFormat } = constants;

const defaultStartDate = moment().subtract(30, 'days');
const defaultEndDate = moment();

const initialState = {
  where: {
    created_at: {
      from: defaultStartDate.format(dateFormat),
      to: defaultEndDate.format(dateFormat),
    },
  },
};

const generateDateLabels = dates => {
  if (!dates || dates.length < 2) return [];
  let start = moment(dates[0]).startOf('day');
  const end = moment(dates[1]).startOf('day');
  const labels = [];

  while (start.isSameOrBefore(end)) {
    labels.push(start.format('DD'));
    start = start.add(1, 'day');
  }

  return labels;
};

export function useReports() {
  const { push } = useCrossSellHistory();
  const [state, setState] = useState(initialState);
  const {
    appState: { selectedHotel },
  } = useContext(AppContext);

  const { data: reportsData, isLoading: isRerportsLoading } = useReportsQuery(state, selectedHotel);
  const { data: countersData, isLoading: isCountersLoading } = useCountersQuery(
    state,
    selectedHotel
  );
  const { data: topSells, isLoading: isTopSellsLoading } = useTopSellsQuery(state, selectedHotel);
  const { data: appConfig, isLoading: isAppConfigLoading } = useAppConfigQuery();
  const [selectedCounter, setSelectedCounter] = useState(0);
  const labels = generateDateLabels([state.where.created_at.from, state.where.created_at.to]);

  const actions = {
    handleViewDetailsClickCrossSell: () => {
      push(`${Routes.crossSellsList}?filters=${encodeURIComponent(JSON.stringify(state))}`);
    },

    setSelectedDates: newDates => {
      const [from, to] = newDates;
      const adjustedTo = moment(to).add(1, 'days').startOf('day');
      const newState = {
        ...state,
        where: {
          ...state.where,
          created_at: {
            from: from?.format(dateFormat),
            to: adjustedTo.format(dateFormat),
          },
        },
      };
      setState(newState);
    },
  };

  const { data: { revenue } = {} } = reportsData || {};
  const { data: { total: countPerDay, approved, pending, rejected } = {} } = countersData || {};

  const totalApproved = sumArray(approved);
  const totalPending = sumArray(pending);
  const totalRejected = sumArray(rejected);

  const data = {
    approved,
    pending,
    rejected,
    total: totalApproved + totalPending + totalRejected,
    totalApproved,
    totalPending,
    totalRejected,
    revenue,
    countPerDay,
    currency: appConfig?.data.currency,
  };

  return {
    defaultDates: [defaultStartDate, defaultEndDate],
    labels,
    selectedCounter,
    setSelectedCounter,
    actions,
    data,
    topSells,
    isLoading: isRerportsLoading || isCountersLoading || isAppConfigLoading || isTopSellsLoading,
  };
}
