import React, { useContext } from 'react';
import { Select } from 'antd';
import SupportedLanguages from '../../../modules/localization/SupportedLanguages';
import { AppContext } from '../../../App.context';

export default function LanguageSelector() {
  const { appState, appActions } = useContext(AppContext);

  const menu = SupportedLanguages.map(language => {
    return (
      <Select.Option key={language.id} value={language.id}>
        {language.name}
      </Select.Option>
    );
  });

  return (
    <div
      style={{
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
      }}
    >
      <Select
        defaultValue={appState.currentLanguage.id}
        style={{ width: 120 }}
        onChange={appActions.setSelectedLanguage}
        bordered={false}
      >
        {menu}
      </Select>
    </div>
  );
}
