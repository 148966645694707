import axios from 'axios';
import moment from 'moment';
import apiConfig from '../api.config';

export default function checkinsRequest({ hotelId, datesRange }) {
  const {
    host,
    URIs: { checkinStats },
  } = apiConfig;

  var filter = {
    from: moment(datesRange[0]).format('YYYY-MM-DD'),
    to: moment(datesRange[1]).format('YYYY-MM-DD'),
  };

  if (hotelId && hotelId != 'null') filter.hotel = hotelId;
  const uri = `${host}${checkinStats}?filter=${JSON.stringify(filter)}`;

  return axios
    .get(uri, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
