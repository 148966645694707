import axios from 'axios';
import apiConfig from '../api.config';

export default function pmsOauthHotelRequest({ hotelId, pmsId, pmsName, code }) {
  const {
    host,
    URIs: { pmsOauth },
  } = apiConfig;

  const uri = `${host}${pmsOauth}/${hotelId}`;

  return axios
    .post(
      uri,
      JSON.stringify({
        pmsName,
        pmsId,
        code,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(res => {
      return res.data;
    });
}
