import React from 'react';
import { ReactComponent as EditIcon } from 'Sell/assets/icon/edit-icon.svg';
import { ReactComponent as DeleteIcon } from 'Sell/assets/icon/delete-icon.svg';
import styles from './Actions.styles.js';

export default function Actions({ record, onEdit, onDelete, editMode }) {
  return (
    <div style={styles.container}>
      <EditIcon
        onClick={onEdit}
        style={{ ...styles.icon.edit, visibility: record.id === editMode.id ? 'hidden' : null }}
      />
      <DeleteIcon onClick={onDelete} style={styles.icon.delete} />
    </div>
  );
}
