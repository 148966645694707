import Colors from 'constants/Colors.js';
import React from 'react';
import DynamicFormsHeader from '../DynamicFormsHeader/DynamicFormsHeader.jsx';
import DynamicFormsRowType from '../DynamicFormsRowType.js';
import DynamicFormsValue from '../DynamicFormsValue/DynamicFormsValue.jsx';
import styles from './DynamicFormsRow.styles.js';

const { HEADER, SECTION, FIELD, FIELD_GROUP } = DynamicFormsRowType;
const ROW_WIDTH = 158;

export default function DynamicFormsRow({ rowType, item, setFieldValue }) {
  switch (rowType) {
    case HEADER:
      return item?.guestTypes.map((guestType, i) => (
        <DynamicFormsHeader
          guestType={guestType}
          size={ROW_WIDTH}
          first={i === 0}
          last={i === item?.guestTypes.length - 1}
        />
      ));

    case FIELD:
    case FIELD_GROUP:
      return item?.guestTypes.map((guestType, i) => {
        const value = item?.values.find(v => v.guest === guestType);

        return value ? (
          <DynamicFormsValue
            size={ROW_WIDTH}
            value={value}
            isLastItem={i === item.values.length - 1}
            isGroup={rowType === FIELD_GROUP}
            setFieldValue={setFieldValue}
            guestType={guestType}
            field={item?.field}
            disabled={value.mandatory || item?.group}
            rowType={rowType}
          />
        ) : (
          <div
            style={{
              minWidth: ROW_WIDTH,
              maxWidth: ROW_WIDTH,
              borderLeft: `1px solid ${Colors.lightGray}`,
              borderBottom: `1px solid ${Colors.lightGray}`,
            }}
          />
        );
      });

    case SECTION:
      return item?.guestTypes.map((_, i) => (
        <div
          style={{
            height: 54,
            minWidth: ROW_WIDTH,
            maxWidth: ROW_WIDTH,
            backgroundColor: Colors.dynamicFormColor,
            borderTopRightRadius: i === item?.guestTypes.length - 1 ? 10 : 0,
            borderBottomRightRadius: i === item?.guestTypes.length - 1 ? 10 : 0,
          }}
        />
      ));

    default:
      return null;
  }
}
