import { useQuery, useMutation } from 'react-query';
import { findAll, upsert } from 'Sell/CrossSell/API/api.config';

export const useCrossSellQuery = (filters, selectedHotel) => {
  const defaultFilters = {};

  return useQuery(['cross-sells', JSON.stringify(filters), selectedHotel], () =>
    findAll('cross-sells', { ...defaultFilters, ...filters })
  );
};

export const useCrossSellMutation = options => {
  const mutation = useMutation({
    mutationFn: data => {
      return upsert('cross-sells', {
        id: data.id,
        bookingId: data.bookingId,
        status: data.status,
        hotelId: data.hotelId,
        itemId: data.itemId,
        itemSlotId: data.itemSlotId,
        itemSlotDayId: data.itemSlotDayId,
      });
    },
    ...options,
  });

  return mutation;
};
