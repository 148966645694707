import React from 'react';
import CheckInBookingDetail from './CheckInBookingDetail/CheckInBookingDetail';
import useCheckInDetailHook from './CheckInDetail.hook';
import CheckInGuestDetail from './CheckInGuestDetail/CheckInGuestDetail';
import CheckInPaymentDetail from './CheckInPaymentDetail/CheckInPaymentDetail';
import styles from './CheckInDetail.styles';

export default function CheckInDetail({ checkinId }) {
  const { state, actions } = useCheckInDetailHook(checkinId);

  return (
    <div style={styles.root}>
      <CheckInBookingDetail
        checkinId={state.data?.code}
        isLoading={state.isLoading}
        data={state.data}
        hotelName={actions.getHotelName(state.data?.hotelId)}
      />
      <CheckInGuestDetail isLoading={state.isLoading} data={state.data} />
      <CheckInPaymentDetail isLoading={state.isLoading} data={state.data} />
    </div>
  );
}
