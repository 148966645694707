export const mapTextsToArray = texts =>
  Object.entries(texts)
    .map(([lang, langTexts]) =>
      Object.entries(langTexts)
        .map(([type, value]) => ({ lang, type, value }))
        .flat()
    )
    .flat();

export const mapTextsToObject = texts =>
  texts.reduce((acc, { lang, type, value }) => {
    acc[lang] = { ...acc[lang], [type]: value };
    return acc;
  }, {});
