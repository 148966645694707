import React, { useContext } from 'react';
import { AppContext } from '../../../../App.context';
import WelcomeLayout from '../WelcomeLayout/WelcomeLayout';
import WelcomeInput from '../WelcomeLayout/WelcomeInput/WelcomeInput';
import { EyeOutlined } from '@ant-design/icons';
import Space from '../../Space/Space';
import WelcomeButton from '../WelcomeLayout/WelcomeButton/WelcomeButton';
import useChangePasswordHook from './ChangePassword.hook';

export default function ChangePassword() {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useChangePasswordHook();

  return (
    <WelcomeLayout title={translate('changePassword.header')}>
      <WelcomeInput
        icon={<EyeOutlined />}
        value={state.password}
        placeholder={translate('register.password')}
        onChange={value => {
          actions.setField('password', value);
        }}
        type={'password'}
      />
      <Space height={16} />
      <WelcomeInput
        icon={<EyeOutlined />}
        value={state.repeatPassword}
        placeholder={translate('register.repeatPassword')}
        onChange={value => {
          actions.setField('repeatPassword', value);
        }}
        type={'password'}
      />
      <Space height={32} />
      <WelcomeButton
        text={translate('recover.send')}
        onClick={() => actions.validatePasswordsAndChange()}
      />
    </WelcomeLayout>
  );
}
