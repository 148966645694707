export default `<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:21px;font-family:&quot;Calibri&quot;,sans-serif;">Termos e condições&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:18px;font-family:&quot;Calibri&quot;,sans-serif;">INFORMAÇÕES LEGAIS&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;">
    <span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">
        Para sua segurança, garantimos que os dados do seu cartão de crédito serão tratados de acordo com os padrões internacionais PCI-DSS. Autorizo o hotel a cobrar os gastos totais da minha estadia no hotel no cartão de crédito mencionado acima. Caso eu deixe o hotel em uma data anterior à indicada na reserva que consta neste documento, autorizo que o valor total das noites contratadas, incluindo as não utilizadas, seja cobrado no cartão de crédito.<br><br>
        No [hotel_name], estamos comprometidos com a proteção da sua privacidade. Você pode entrar em contato conosco em [address] ou pelo e-mail [hotel_email]. [hotel_name] processará seus dados para gerenciar sua estadia no hotel e melhorar sua experiência como hóspede. Além disso, [hotel_name] processará seus dados para fornecer serviços de hospedagem e serviços complementares. Poderemos precisar processar seus dados para cumprir com as obrigações legais às quais estamos sujeitos (incluindo a prevenção de fraudes). [hotel_name] poderá comunicar seus dados a terceiros, como bancos ou outras instituições financeiras para processar pagamentos ou a agências de segurança para cumprir com as obrigações impostas pela lei.<br><br>
        Se você se inscrever, [hotel_name] processará seus dados pessoais para oferecer os respectivos serviços de fidelidade. Nesse sentido, processaremos os pontos gerados e podemos enviar informações sobre seus pontos e os benefícios do programa, ajustados ao seu perfil.<br><br>
        Você pode exercer seus direitos de acesso, retificação, exclusão, oposição e/ou limitação do tratamento, bem como a portabilidade dos seus dados, escrevendo para [hotel_name] no endereço postal ou de e-mail indicado acima. Você deve comprovar sua identidade e indicar em ambos os casos (a) "Proteção de Dados" como assunto e (b) se sua solicitação é direcionada a [hotel_name]. Além disso, você pode entrar em contato a qualquer momento com a autoridade nacional competente de supervisão.<br><br>
        Para mais informações, acesse [hotel_web] ou consulte na recepção do hotel.
    </span>
</p>`;
