import React, { useState } from 'react';
import { Badge } from 'antd';
import styles from './FilterComponents.styles';
import CustomButton from 'Components/common/CustomButton/CustomButton';
import Colors from 'constants/Colors';

const FilterComponent = ({ onFilterChange, counters }) => {
  const [selectedButton, setSelectedButton] = useState('all');
  const { all = 0, rejected = 0, approved = 0, pending = 0 } = counters;
  const onHandleButton = option => {
    onFilterChange(option);
    setSelectedButton(option);
  };

  return (
    <div style={styles.container}>
      <CustomButton
        text="Pending"
        background={selectedButton === 'pending' ? Colors.blueColor : '#fff'}
        color={selectedButton === 'pending' ? '#fff' : '#000'}
        onClick={() => onHandleButton('pending')}
        rightComponent={<Badge count={pending} showZero color="#FF7F37" />}
      />
      <CustomButton
        text="Approved"
        background={selectedButton === 'approved' ? Colors.blueColor : '#fff'}
        color={selectedButton === 'approved' ? '#fff' : '#000'}
        onClick={() => onHandleButton('approved')}
        rightComponent={<Badge count={approved} showZero color="#FF7F37" />}
      />
      <CustomButton
        text="Rejected"
        background={selectedButton === 'rejected' ? Colors.blueColor : '#fff'}
        color={selectedButton === 'rejected' ? '#fff' : '#000'}
        onClick={() => onHandleButton('rejected')}
        rightComponent={<Badge count={rejected} showZero color="#FF7F37" />}
      />
      <CustomButton
        text="All"
        background={selectedButton === 'all' ? Colors.blueColor : '#fff'}
        color={selectedButton === 'all' ? '#fff' : '#000'}
        onClick={() => onHandleButton('all')}
        rightComponent={<Badge count={all} showZero color="#FF7F37" />}
      />
    </div>
  );
};

export default FilterComponent;
