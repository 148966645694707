import React, { useEffect, useState } from 'react';
import styles from './BaseTable.module.css';
import { Button, Input, Table, ConfigProvider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const defaultFilter = {
  limit: 10,
  page: 1,
};

export default function BaseTable(props) {
  const {
    columns,
    filter: customFilter,
    data,
    loading,
    onChange,
    onRowClicked,
    localPagination,
    bordered,
    defaultLimit = 10,
    showSizeChanger = true,
    removeCellPadding = false,
    currentPage = 0,
    rowSelection,
    ...moreProps
  } = props;

  const dataFilter = {
    ...defaultFilter,
    ...customFilter,
  };
  
  const [filter, setFilter] = useState({
    pagination: {
      current: currentPage + 1,
      pageSize: defaultLimit,
      page: dataFilter.page,
      showSizeChanger: showSizeChanger,
    },
    sort: {},
    searchParams: {
      searchColumn: '',
      searchText: '',
    },
    where: {},
  });

  const [filteredData, setFilteredData] = useState(data.items);
  
  useEffect(() => {
    const pagination = {
      ...filter.pagination,
      total: data.totalItems,
    };
    setFilter({ ...filter, pagination });
    applyFilters();
  }, [data, filter.searchParams]);
  useEffect(() => {
  }, [filteredData]);
  

  function handleTableChange(pager, filters, sorter) {
    let columnFilters = getColumnFilters(filters);

    var sortOrderFormatted;
    if (sorter.order === 'ascend') sortOrderFormatted = 'ASC';
    if (sorter.order === 'descend') sortOrderFormatted = 'DESC';

    const newFilter = {
      ...filter,
      pagination: {
        pageSize: pager.pageSize,
        page: pager.current - 1,
        total: data.totalItems,
        showSizeChanger: showSizeChanger,
      },
      sort: {
        sortField: sorter.field,
        sortOrder: sortOrderFormatted,
      },
      ...columnFilters,
    };

    setFilter(newFilter);
    onChange && onChange(newFilter);
  }

  // Función para aplicar el filtro de búsqueda sobre los datos
  const applyFilters = () => {
    const { searchColumn, searchText } = filter.searchParams;
    if (!searchText || !searchColumn) {
        setFilteredData(data.items);
        return;
    }
    const filteredItems = data.items.filter(item => {
        const value = item[searchColumn]; 
        return value && value.toString().toLowerCase().trim().includes(searchText.toLowerCase().trim());
    });
    setFilteredData(filteredItems);  
};

  return (
    <div className={styles.container}>
      <ConfigProvider renderEmpty={props?.EmptyTable}>
        <Table
          childrenColumnName="antdChildren"
          className={removeCellPadding ? 'remove-padding' : styles.table}
          columns={columns.map(column => {
            if (column.searchable) {
              column = {
                ...column,
                ...getColumnSearchProps({
                  title: column.title,
                  dataIndex: column.dataIndex,
                  searchParams: filter.searchParams,
                  setSearchParams: searchParams => {
                    const newSearchParams = {
                      ...filter.searchParams,
                      ...searchParams,
                    };
                    setFilter({ ...filter, searchParams: newSearchParams });
                  },
                }),
              };
            }
            return column;
          })}
          rowKey={record => {
            return record.id}
          }
          dataSource={data.items || filteredData}  
          pagination={localPagination || filter.pagination}
          loading={loading}
          bordered={bordered}
          onChange={handleTableChange}
          rowSelection={rowSelection}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                onRowClicked && onRowClicked(record, rowIndex);
              },
            };
          }}
          {...moreProps}
        />
      </ConfigProvider>
    </div>
  );
}

function getColumnSearchProps({ title, dataIndex, searchParams, setSearchParams }) {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className={styles.searchPopup}>
        <Input
          ref={node => {
            searchParams.searchInput = node;
          }}
          placeholder={`Buscar ${title}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => {
            confirm();
            setSearchParams({
              searchText: selectedKeys[0],
              searchColumn: dataIndex, 
            });
          }}
          className={styles.searchInput}
        />
        <div>
          <Button
            type="primary"
            size="small"
            className={styles.searchButton}
            onClick={() => {
              confirm();
              setSearchParams({
                searchText: selectedKeys[0],
                searchColumn: 'typeName',  
              });
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              setSearchParams({
                searchText: '',
                searchColumn: '',  
              });
            }}
            size="small"
            className={styles.searchButton}
          >
            Limpiar
          </Button>
        </div>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ fontSize: '14px', color: filtered ? '#2bad74' : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchParams.searchInput.select(), 100);
      }
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
  };
}
function getColumnFilters(filters) {
  let columnFilters = {};
  if (filters && Object.keys(filters).length) {
    const propertiesToFilter = {};
    Object.keys(filters).forEach(filterKey => {
      if (filters[filterKey] && filters[filterKey].length) {
        propertiesToFilter[filterKey] = `${filters[filterKey][0]}`;
      }
    });
    columnFilters = {
      where: {
        ...propertiesToFilter,
      },
    };
  }
  return columnFilters;
}