import axios from 'axios';
import apiConfig from '../api.config';

export default function forcePoliceReportDownloadTrigger({ hotelId, date }) {
  const {
    policeReportHost,
    URIs: { policeReportDownloadTrigger },
  } = apiConfig;

  const uri = `${policeReportHost}${policeReportDownloadTrigger}`;

  return axios
    .post(uri, JSON.stringify({ hotel: { id: hotelId }, date }), {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => {
      return res.data;
    });
}
