import Colors from 'constants/Colors';

export default {
  rowStyle: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    padding: 5,
    alignItems: 'flex-start',
    gap: 4,
    flexShrink: 0,
    borderRadius: 8,
    backgroundColor: 'var(--light-greyscale-bg-darken, #F8F8F8)',
  },
  selectedStyle: {
    width: 'fit-content',
    backgroundColor: '#E7E7E7',
    color: Colors.blueColor,
    border: 'none',
    textAlign: 'center',
    borderRadius: 8,
    border: 'none',
    zIndex: 1,
    boxShadow: 'none',
  },

  deselectedStyle: {
    width: 'fit-content',
    color: Colors.black,
    textAlign: 'center',
    backgroundColor: 'transparent',
    border: 'none',
  },
};
