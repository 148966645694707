import React from 'react';
import styles from './PhotoBox.styles.js';

export default function Photo({ path }) {
  return (
    <>
      <img style={styles.image} src={path} alt="Hotel Picture" />
    </>
  );
}
