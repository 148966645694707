import React, { useContext } from 'react';
import { AppContext } from '../../../App.context';
import Colors from '../../../constants/Colors';
import constants from '../../../utils/constants';
import styles from './IHClientTag.styles';
import bookingLogoCheckin from '../../../assets/images/bookingLogoCheckin.png';
import logoCivitfunCheckin from '../../../assets/images/logoCivitfunCheckin.png';

export default function IHClientTag({ ihClientId }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const bgColor = getBackgroundColor(ihClientId);
  const name = getClientName(translate, ihClientId);

  const getImage = () => {
    if (Colors.bookingColorCheckinList == bgColor) {
      return bookingLogoCheckin;
    } else if (Colors.blueStrongColor == bgColor) {
      return logoCivitfunCheckin;
    }
  };
  const imageSrc = getImage();

  return ihClientId ? (
    <div style={{ ...styles.container, backgroundColor: bgColor, display: 'flex', alignItems: 'center', margin: '5px' }}>
      {imageSrc && <img src={imageSrc} style={{ width: '20px', height: '20px' }} />}
      {name}</div>
  ) : null;
}



function getBackgroundColor(ihClientId) {
  switch (ihClientId) {
    case constants.IH_CLIENT.CIVITFUN:
    case constants.IH_CLIENT.KIOSK:
    case constants.IH_CLIENT.RECEPTION:
      return Colors.blueStrongColor;
    case constants.IH_CLIENT.MTS:
      return Colors.yellowPill;
    case constants.IH_CLIENT.ROOMMATIK:
      return Colors.yellowPill;
    case constants.IH_CLIENT.BOOKINGDOTCOM:
      return Colors.bookingColorCheckinList;
    default:
      return null;
  }
}

function getClientName(translate, ihClientId) {
  switch (ihClientId) {
    case constants.IH_CLIENT.CIVITFUN:
      return translate('ihClient.CIVITFUN');
    case constants.IH_CLIENT.MTS:
      return translate('ihClient.MTS');
    case constants.IH_CLIENT.ROOMMATIK:
      return translate('ihClient.ROOMMATIK');
    case constants.IH_CLIENT.BOOKINGDOTCOM:
      return translate('ihClient.BOOKINGDOTCOM');
    case constants.IH_CLIENT.KIOSK:
      return translate('ihClient.KIOSK');
    case constants.IH_CLIENT.RECEPTION:
      return translate('ihClient.RECEPTION');
    default:
      return null;
  }
}
