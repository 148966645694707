import React, { useContext } from 'react';
import { List, Typography, Button, Card } from 'antd';
import { AppContext } from 'App.context';
import styles from './Breakdown.styles';
import './Breakdown.css';
const { Title, Paragraph } = Typography;

export default function ListaComponente({ onViewDetailsClick, breakdown }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <Card
      style={styles.card}
      title={
        <div style={styles.title}>
          <div>
            <Title style={styles.title} level={4}>
              {breakdown.title}
            </Title>
            <Paragraph style={styles.subtitle}>
              {translate('upsell.groupBy')} {breakdown.groupBy}
            </Paragraph>
          </div>
          <Button style={styles.link} type="link" onClick={onViewDetailsClick}>
            {translate('upsell.viewDetails')}
          </Button>
        </div>
      }
    >
      <List
        itemLayout="horizontal"
        dataSource={breakdown.list}
        renderItem={item => (
          <List.Item style={styles.list}>
            <List.Item.Meta title={item.title} />
            <div style={styles.numbers}>{item.number}</div>
          </List.Item>
        )}
      />
    </Card>
  );
}
