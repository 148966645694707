import React, { useContext } from 'react';
import { Card, Tag, Table } from 'antd';
import styles from './CheckInPaymentDetail.styles';
import { Row } from '../../../../common/Forms/Row/Row';
import PriceIndicator from './PriceIndicator/PriceIndicator';
import useCheckInPaymentHook from './CheckInPaymentDetail.hook';
import { AppContext } from '../../../../../App.context';

export default function CheckInPaymentDetail({ isLoading, data }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const chargesColumns = [
    {
      title: translate('checkInList.detail.payment.concept'),
      dataIndex: 'concept',
      key: 'concept',
      searchable: false,
      sorter: false,
    },
    {
      title: translate('checkInList.detail.payment.operation'),
      dataIndex: 'process',
      key: 'process',
    },
    {
      title: translate('checkInList.detail.payment.amount'),
      dataIndex: 'amount',
      key: 'amount',
      searchable: false,
      sorter: false,
      align: 'right',
      render: (text, object) => {
        return actions.getColumnPrice(object?.amount, object?.currency);
      },
    },
  ];

  const operationsColumns = [
    {
      title: translate('checkInList.detail.payment.locator'),
      dataIndex: 'locator',
      key: 'locator',
    },
    {
      title: translate('checkInList.detail.payment.operation'),
      dataIndex: 'operation',
      key: 'operation',
      render: text => {
        return actions.getOperationLiteral(text);
      },
    },
    {
      title: translate('checkInList.detail.payment.amount'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (text, object) => {
        return actions.getColumnPrice(object?.amount, object?.currency);
      },
    },
  ];

  const { state, actions } = useCheckInPaymentHook();
  const totalCharges = actions.getTotalAmount(data?.payments?.charges);
  const totalOperations = actions.getTotalAmount(data?.payments?.operations);
  const totalBalanceString = `${translate(
    'checkInList.detail.payment.totalBalance'
  )}: ${actions.getTotalBalance(data?.payments?.charges, data?.payments?.operations)}`;

  return (
    <Card
      type={'inner'}
      loading={isLoading}
      title={translate('checkInList.detail.paymentDetail')}
      extra={!isLoading && <b>{totalBalanceString}</b>}
    >
      <div>
        <div style={styles.container}>
          <div style={styles.tableContainer}>
            <Row>
              <h3>{translate('checkInList.detail.payment.charges')}</h3>
              <PriceIndicator price={totalCharges} />
            </Row>

            <Table
              dataSource={data?.payments?.charges}
              columns={chargesColumns}
              loading={isLoading}
              pagination={false}
            />
          </div>
          <div style={styles.tableContainer}>
            <Row>
              <h3>{translate('checkInList.detail.payment.operations')}</h3>
              <PriceIndicator price={totalOperations} />
            </Row>

            <Table
              dataSource={data?.payments?.operations}
              columns={operationsColumns}
              loading={isLoading}
              pagination={false}
            />
          </div>
        </div>
        <div style={styles.divider}>
          <div>{translate('checkInList.detail.payment.statusDivider')}</div>
          <div style={styles.dividerBar} />
        </div>
        <div style={styles.tagsContainer}>
          <Tag color={actions.getTagColor(data?.paymentStatus)}>{`${translate(
            'checkInList.detail.payment.paymentStatus'
          )} ${data?.paymentStatus}`}</Tag>
          <Tag color={actions.getTagColor(data?.warrantyStatus)}>{`${translate(
            'checkInList.detail.payment.warrantyStatus'
          )} ${data?.warrantyStatus}`}</Tag>
        </div>
      </div>
    </Card>
  );
}
