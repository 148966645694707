import { useContext, useState } from 'react';
import { notification } from 'antd';
import { AppContext } from 'App.context';
import changePasswordRequest from '../../../../../src/utils/networking/api/auth/changePassword.service';
import { useHistory } from 'react-router-dom';
import loginRequest from 'utils/networking/api/auth/login.service';
import activateOtaAccountRequest from 'utils/networking/api/auth/activateOtaAccount.service';

export default function useChangePasswordHook() {
  const {
    appState: {
      currentLanguage: { id: currentLanguage },
    },
    appActions,
  } = useContext(AppContext);
  const { translate } = appActions;
  const history = useHistory();

  const [state, setState] = useState({
    password: null,
    repeatPassword: null,
  });

  const params = new URLSearchParams(window.location.search);

  const actions = {
    validatePasswordsAndChange: () => {
      if (state.password && state.password == state.repeatPassword) {
        const email = params.get('email');
        const token = params.get('token');

        changePasswordRequest({
          token,
          email,
          password: state.password,
          lang: currentLanguage,
        })
          .then(_ => {
            notification.success({
              message: translate('changePassword.success'),
            });

            actions.loginUser({ email });
          })
          .catch(err => {
            notification.error({
              message: err?.message,
            });
          });
      } else {
        notification.error({
          message: translate('register.matchPasswords'),
        });
      }
    },

    loginUser: ({ email }) => {
      const ota = params.get('ota');

      if (ota) {
        actions.activateOtaAccount({ email, ota });
      } else {
        actions.doLogin(email, state.repeatPassword, ota);
      }
    },

    activateOtaAccount: ({ email, ota }) => {
      activateOtaAccountRequest({ email, hotelSlug: params.get('hotelSlug') })
        .then(_ => {
          actions.doLogin(email, state.repeatPassword, ota);
        })
        .catch(err => {
          console.warn(err);
        });
    },

    doLogin: (email, password, ota) => {
      loginRequest(email, password)
        .then(data => {
          appActions.saveAuthToken({
            email: email,
            accessToken: data.token,
            superUser: false,
            ownerId: data?.ownerId,
            userId: data?.userId,
            hasSeenQuickguide: data?.hasSeenQuickguide,
            userType: data?.type,
          });
          appActions.setChain(data?.chain);

          if (ota && params.get('newHotel') === 'true') {
            history.push({
              pathname: '/select-pms',
              state: {
                ota,
                hotelSlug: params.get('hotelSlug'),
              },
            });
          } else if (ota) {
            history.push({
              pathname: '/channels-pms',
              state: {
                ota,
                hotelSlug: params.get('hotelSlug'),
              },
            });
          } else {
            history.push('/hotels');
          }
        })
        .catch(error => {
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    },

    setField: (fieldKey, value) => {
      updateState({ [fieldKey]: value });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
