import axios from 'axios';
import apiConfig from '../api.config';

export default function addOtaMarketing({ pmsName, hotelSlug }) {
  const {
    host,
    URIs: { createOtaMarketing },
  } = apiConfig;

  const uri = `${host}${createOtaMarketing}`;

  return axios
    .post(uri, JSON.stringify({ pmsName, hotelSlug }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
