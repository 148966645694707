import { BankOutlined, EyeOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import React, { useContext } from 'react';
import Space from '../../Space/Space';
import WelcomeButton from '../WelcomeLayout/WelcomeButton/WelcomeButton';
import WelcomeInput from '../WelcomeLayout/WelcomeInput/WelcomeInput';
import WelcomeLayout from '../WelcomeLayout/WelcomeLayout';
import Loading from '../../../common/Loader/Loading';
import useRegisterHook from './Register.hook';
import { Checkbox } from 'antd';
import { AppContext } from '../../../../App.context';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import phoneStyles from './PhoneInput.styles';

export default function Register() {
  const { state, actions } = useRegisterHook();
  const {
    appState: { currentLanguage },
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <WelcomeLayout title={translate('register.title')}>
      {state.showRegisterInfo ? (
        <div>
          <span>{state.showRegisterInfo}</span>
          <Space height={48} />
          <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
            <WelcomeButton
              text={translate('register.goLogin')}
              onClick={() => actions.goBack()}
              secondary={true}
            />
            <WelcomeButton
              style={{ flex: 1 }}
              text={translate('register.addPaymentMethod')}
              onClick={() => actions.redirectToAddPaymentMethod()}
            />
          </div>
        </div>
      ) : (
        <div>
          <WelcomeInput
            icon={<BankOutlined />}
            value={state.chainName}
            placeholder={translate('register.chain')}
            onChange={value => {
              actions.setField('chainName', value);
            }}
          />
          <Space height={16} />
          <WelcomeInput
            icon={<UserOutlined />}
            value={state.name}
            placeholder={translate('register.name')}
            onChange={value => {
              actions.setField('name', value);
            }}
          />
          <Space height={16} />
          <PhoneInput
            inputStyle={phoneStyles.input}
            buttonStyle={phoneStyles.button}
            country={'es'}
            value={state.phone}
            onChange={phone => actions.setField('phone', phone)}
            placeholder={translate('register.phone')}
          />
          <Space height={16} />
          <WelcomeInput
            icon={<MailOutlined />}
            value={state.email}
            placeholder={translate('register.mail')}
            onChange={value => {
              actions.setField('email', value);
            }}
          />
          <Space height={16} />
          <WelcomeInput
            icon={<EyeOutlined />}
            value={state.password}
            placeholder={translate('register.password')}
            onChange={value => {
              actions.setField('password', value);
            }}
            type={'password'}
          />
          <Space height={16} />
          <WelcomeInput
            icon={<EyeOutlined />}
            value={state.repeatPassword}
            placeholder={translate('register.repeatPassword')}
            onChange={value => {
              actions.setField('repeatPassword', value);
            }}
            type={'password'}
          />
          <Space height={32} />
          <div style={{ display: 'flex', flexDirection: 'row', gap: 6 }}>
            <Checkbox
              value={state.termsConditions}
              onClick={() => actions.setField('termsConditions', !state.termsConditions)}
            />
            <a href={currentLanguage.termsAndConditionsUrl} target="_blank">
              {translate('register.termsConditions')}
            </a>
          </div>
          <Space height={32} />
          <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
            <WelcomeButton
              text={translate('register.goBack')}
              onClick={() => actions.goBack()}
              secondary={true}
            />
            <WelcomeButton
              style={{ flex: 1 }}
              text={translate('register.button')}
              onClick={() => actions.validateFieldsAndRegister()}
            />
          </div>
        </div>
      )}

      <Space height={16} />
      {state.loading && <Loading></Loading>}
    </WelcomeLayout>
  );
}
