import Colors from 'constants/Colors';

export default {
  container: {
    borderRadius: 8,
    backgroundColor: Colors.joditBg,
    padding: 16,
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    color: Colors.darkText,
    marginTop: 4,
  },
  input: {
    borderRadius: 6,
    backgroundColor: 'white',
    marginBottom: 8,
  },
};
