export default {
  menu: {
    roomType: 'Rooms',
    status: 'Upsellings Status',
    report: 'Reports'
  },
  pmsIdentifier: 'PMS ID',
  pmsIdentifierInfo: 'Indicate the room ID in your PMS for this room',
  status: 'Status',
  picture: 'Picture',
  roomType: 'Room Type',
  roomTypeInfo: 'Add the room name',
  hotelName: 'Hotel Name',
  createRoomType: 'Create Room',
  confirmationType: 'Confirmation Type',
  automatic: 'Automatic',
  manual: 'Manual',
  description: 'Description',
  descriptionIn: 'Add the room description',
  photoGallery: 'Photo Gallery',
  amenities: 'Amenities',
  roomFeatures: 'Room Features',
  dragAndDrop: 'Drag your images here or click to select them',
  maxFileSize: 'Maximum file size: 2.5MB',
  maxFileNumber: 'Maximum allowed is 5 images.',
  uploadsuccess: 'Upload successful',
  uploaderror: 'Error uploading',
  dealId: 'ID',
  locator: 'Locator',
  guestName: 'Guest Name',
  date: 'Date',
  typesOfRoomsCreated: 'Types of Rooms Created',
  typeOfPrice: 'Type of Price',
  price: 'Price',
  deleteRoomType: {
    title: 'Are you sure you want to delete this room type?',
    content: 'The selected room type will be deleted',
    okText: 'Delete Room Type',
  },
  createRoomDescription:
    'Create all the types of rooms available in your establishment to offer an upgrade to your guests',
  groupBy: 'Grouped by',
  viewDetails: 'View Details',
  roomDetail: {
    back: 'Back',
    saveRoom: 'Save Room',
  },
  active: 'Active',
  removed: 'Removed',
  disabled: 'Disabled',
  maxFileUploadReached: 'You can upload a maximum of 5 images, easily arranging them with the drag-and-drop feature or removing them as needed. The first image will serve as the primary one, displayed in a larger format.',
  totalUpsell: 'Total Upsellings',
  upsellRevenue: 'Upselling Revenue',
  upsellList: 'Upselling List',
  upsell: 'Upselling',
  last7Days: 'Last 7 days',
  lastMonth: 'Last month',
  last30Days: 'Last 30 days',
  days: 'Days',
  month: 'Month',
  accepted: 'Accepted',
  rejected: 'Rejected',
  approved: 'Approved',
  pending: 'Pending',
  activate: 'Activate',
  delete: 'Delete',
  seeDetails: 'See Details',
  changeToRejected: 'Change to Rejected',
  changeToApproved: 'Change to Approved',
  disable: 'Disable',
  paymentStatus: 'Payment status',
  transactionCode: 'Transaction',
  succeed: 'Approved',
  previousRoom: 'Previous room',
  newRoom: 'New room',
  addUpsellWarning: 'Before implementing business logic for room upgrades, you need to ensure all rooms are created.',
  upsertValidations: {
    name: 'Room name is required',
    description: 'Room description is required',
    missingTranslation: 'Translation is missing in another language',
    createRoom: 'Room created successfully',
    modifiedRoom: 'Room modified successfully',
  },
  fromRoom: 'Previous room',
  toRoom: 'New room',
  pmsTooltip: 'It is irreversible to correct the residential ID in PMS. If a home has a residence reserved that is not correctly registered in our system, we cannot recommend a better place to live.',
  confirmationTypeTooltip: 'Choose between automatic or manual confirmation of Upsellings. Automatic: Upsells realized by customers are carried out automatically, without any intervention from the hotel. Manual: Upsells requested by guests will be created as pending. You will need to approve or reject them manually from our Dashboard.'
};
