export default {
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    paddingTop: 16
  },
  
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    flexWrap: 'wrap',
  },
};
