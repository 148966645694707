import React from 'react';
import { Tag } from 'antd';
import iconMap from 'Sell/assets/icon/iconMap';
import styles from './Tag.styles';

const { CheckableTag } = Tag;

export default function TagCheckbox({ item, checked, onCheck }) {
  const Icon = iconMap[item.icon];
  const containerStyles = checked ? styles.containerChecked : styles.container;
  const iconStyles = checked ? styles.iconChecked : styles.icon;

  return (
    <CheckableTag
      key={`${item.id}-${item.name}`}
      checked={checked}
      onChange={checked => {
        onCheck(item, checked);
      }}
      style={containerStyles}
    >
      {/* {Icon && <Icon style={iconStyles} />} */}
      <span className={`icon icon-${item.icon}`} style={{ fontSize: 11 }}></span>
      <span style={styles.text}>{item.name}</span>
    </CheckableTag>
  );
}
