import Colors from 'constants/Colors';

export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    gap: '20px',
    paddingRight: '20px',
  },
  container: {
    padding: 24,
    backgroundColor: Colors.white,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    gap: 18,
    flex: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'sticky',
    top: '80px',
    zIndex: 200,
    margin: '20px 0 20px 0'
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    gap: 15
  },
  row: { display: 'flex', flexDirection: 'column', gap: 15, zIndex: 100 },
};
