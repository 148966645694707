import { notification } from 'antd';
import { AppContext } from 'App.context';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';

const LANGUAGE_ORDER = ['en', 'es', 'de', 'ca', 'fr', 'pt', 'it', 'jp', 'cn', 'ru', 'nl'];

export default function useFormsHook() {
  const { API } = useContext(ApiContext);
  const { appState, appActions } = useContext(AppContext);
  const { translate, getSelectedHotel } = appActions;

  const [state, setState] = useState({
    isLoading: false,
    languages: null,
    selectedLanguage: null,
    form: null,
    isDynamicForm: false,
  });

  useEffect(() => {
    actions.setSelectedHotel(getSelectedHotel());
  }, [getSelectedHotel()?.key]);

  const actions = {
    onLanguageSelected: option => {
      updateState({ selectedLanguage: option });
    },

    orderLanguages: languages => {
      return LANGUAGE_ORDER.reduce((ordered, lang) => {
        const found = languages.find(l => l.code === lang);
        if (found) ordered.push(found);
        return ordered;
      }, []);
    },

    setSelectedHotel: hotel => {
      if (hotel && hotel?.key != 'null') {
        actions.loadGuestFormData(hotel.key);
      }
    },

    loadGuestFormData: hotelId => {
      updateState({ isLoading: true, form: null });

      API.guestFormsRequest(hotelId)
        .then(res => {
          const parsedFormItems = res.isDynamicForm
            ? actions.getSortedFormByGuest(res?.hotelFields)
            : actions.parseFormItems(res?.hotelFields);
          const languages = actions.orderLanguages(res?.languages);

          updateState({
            isLoading: false,
            form: parsedFormItems,
            languages: languages,
            isDynamicForm: res.isDynamicForm,
          });

          if (
            !state.selectedLanguage ||
            !languages.map(lang => lang.code).includes(state.selectedLanguage)
          ) {
            actions.onLanguageSelected(languages[0].code);
          }
        })
        .catch(err => {
          updateState({ isLoading: false });
          console.warn(err);
        });
    },

    parseFormItems: formItems => {
      return formItems.map(item => {
        if (item.onlyItaly) {
          if (item.name === 'province' || item.name === 'city') {
            item.desc = translate('guestTable.italyResidencyDesc');
          } else {
            item.desc = translate('guestTable.italyDesc');
          }
          return item;
        } else return item;
      });
    },

    getSortedFormByGuest: hotelFields => {
      return {
        holder_1: hotelFields['holder_1'],
        holder_0: hotelFields['holder_0'],
        adult_1: hotelFields['adult_1'],
        adult_0: hotelFields['adult_0'],
        child_1: hotelFields['child_1'],
        child_0: hotelFields['child_0'],
      };
    },

    saveForm: (formData, applyToAllHotels) => {
      const customFields = JSON.parse(
        JSON.stringify(state.isDynamicForm ? formData.fields : formData)
      ).filter(formItem => formItem?.id);

      const hotelFields = state.isDynamicForm
        ? actions.getFieldsFromDynamicForm(formData.fields, formData.modifiedFieldHotels)
        : JSON.parse(JSON.stringify(formData)).filter(formItem => formItem?.fieldHotelId);

      const formattedFields = customFields.map(field => {
        delete field?.id;
        delete field?.key;
        delete field?.defaultTexts;
        if (!field.customText) delete field?.formTexts;

        return field;
      });

      const customTranslations = state.isDynamicForm ? formData.customTranslations : null;

      updateState({ isLoading: true });
      API.updateGuestFormRequest({
        hotelId: !applyToAllHotels ? getSelectedHotel().key : null,
        data: formattedFields,
        hotelFields,
        customTranslations,
      })
        .then(res => {
          updateState({ isLoading: false });
          notification.success({
            message: translate('guestTable.success'),
          });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
          notification.error({
            message: translate('guestTable.error'),
          });
        });
    },

    getFieldsFromDynamicForm: (form, modifiedFieldHotels) => {
      const hotelFields = form.reduce((acc, field) => {
        if (field.values) {
          const filteredValues = field.values.filter(value =>
            modifiedFieldHotels.includes(value.fieldHotelId)
          );
          acc.push(...filteredValues);
        }
        return acc;
      }, []);

      return hotelFields;
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
