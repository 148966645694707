import { useContext, useState } from 'react';
import { validateEmail } from 'utils/TextUtils';
import { notification } from 'antd';
import { AppContext } from 'App.context';
import { useHistory } from 'react-router-dom';
import recoverPasswordRequest from '../../../../../src/utils/networking/api/auth/recoverPassword.service';

export default function useRecoverPasswordHook() {
  const {
    appState: {
      currentLanguage: { id: currentLanguage },
    },
    appActions: { translate },
  } = useContext(AppContext);
  const history = useHistory();

  const [state, setState] = useState({
    isLoading: false,
    email: null,
  });

  const actions = {
    validateEmailAndRecover: () => {
      if (validateEmail(state.email)) {
        updateState({ isLoading: true });
        recoverPasswordRequest({ email: state.email, lang: currentLanguage })
          .then(_ => {
            updateState({ isLoading: false });
            notification.success({
              message: translate('recover.success'),
            });
          })
          .catch(err => {
            updateState({ isLoading: false });
            notification.error({
              message: err?.message,
            });
          });
      } else {
        notification.error({
          message: translate('recover.invalidEmail'),
        });
      }
    },

    goBack: () => {
      history.goBack();
    },

    setEmail: value => {
      updateState({ email: value });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
