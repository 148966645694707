import axios from 'axios';
import apiConfig from 'utils/networking/api.config';

/**
 * @param {*} action
 * undefined == testAll { bookingIdentifier }
 * finalize { bookingIdentifier }
 * status { bookingIdentifier }
 * @returns
 */
export default async function checkinRequest({
  hotelId = null,
  action = null,
  authorization = null,
  bookingIdentifier = null,
}) {
  const {
    hostValidation,
    URIs: {
      validation: { checkin },
    },
  } = apiConfig;

  const uri = `${hostValidation}${checkin}`
    .replace(':hotelId', hotelId)
    .replace(':action', action || '');

  return axios
    .get(uri, {
      params: { bookingIdentifier },
      headers: { Authorization: authorization },
    })
    .then(res => {
      return res.data;
    });
}
