import { RightOutlined, SearchOutlined } from '@ant-design/icons';
import { DatePicker, Input } from 'antd';
import React from 'react';
import styles from './BookingTestInput.styles';

export default function BookingTestInput({ id, title, value, onValueChanged, type }) {
  const icon =
    id === 'bookingCode' ? (
      <SearchOutlined style={styles.icon} />
    ) : (
      <RightOutlined style={styles.icon} />
    );

  return (
    <div>
      <div style={styles.title}>{title}</div>
      <div style={styles.inputContainer}>
        {icon}
        {type === 'date' ? (
          <DatePicker
            style={{ width: '100%' }}
            bordered={false}
            placeholder={title}
            onChange={(e, stringValue) => {
              onValueChanged && onValueChanged(id, stringValue);
            }}
          />
        ) : (
          <Input
            style={{ width: '100%' }}
            placeholder={title}
            bordered={false}
            value={value}
            onChange={e => {
              onValueChanged && onValueChanged(id, e.target.value);
            }}
          />
        )}
      </div>
    </div>
  );
}
