import React, { useContext, useState } from 'react';
import ContentBox from 'Components/common/ContentBox/ContentBox';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout';
import ButtonGenerator from './ButtonGenerator/ButtonGenerator';
import Space from 'Components/common/Space/Space';
import { Table, Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import useMarketingHook from './Marketing.hook';
import styles from './Marketing.styles';
import { copyTextToClipboard } from 'utils/TextUtils';
import { AppContext } from 'App.context';
import LinkGenerator from './LinkGenerator/LinkGenerator';
import InfoView from 'Components/common/InfoView/InfoView';
import { InfoCircleFilled } from '@ant-design/icons';
import Colors from 'constants/Colors';

export default function Marketing() {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useMarketingHook();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: 'Copy',
      dataIndex: 'copy',
      key: 'copy',
      render: (_, row) => {
        return (
          <div>
            <Button
              type="primary"
              shape="square"
              ghost
              style={{ color: Colors.superUserButton, borderColor: Colors.lightGray }}
              icon={<CopyOutlined />}
              onClick={() => copyTextToClipboard(row.url)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <DefaultLayout>
      <ContentBox noPadding title={translate('marketing.buttonGenerator')}>
        <ButtonGenerator
          title={translate('marketing.hotelCheckin')}
          url={`https://checkin.civitfun.com/hotel/${actions.getSelectedHotelSlug()}`} 
        />
        <Space height={48} />
        <ButtonGenerator
          title={translate('marketing.chainCheckin')}
          url={`https://checkin.civitfun.com/chain/${actions.getChainSlug()}`}
        />
      </ContentBox>

      <ContentBox title={translate('marketing.links')} noPadding>
        <Table dataSource={state.data} columns={columns} pagination={{ defaultPageSize: 5 }} />
      </ContentBox>

      <ContentBox title={translate('marketing.deeplink')}>

        <div style={styles.desc}>{`${translate('marketing.deeplink1desc')}`}</div>
        <LinkGenerator url={actions.getHotelDeeplink()} />
        <InfoView style={styles.info} icon={<InfoCircleFilled />}>
          <div style={styles.infoTitle}>{translate('marketing.bookingCodeTitle')}</div>
          <div>{translate('marketing.bookingCodeDesc')}</div>
          <div style={{ height: 16 }} />
          <div style={styles.infoTitle}>{translate('marketing.entryDateTitle')}</div>
          <div>{translate('marketing.entryDateDesc')}</div>
        </InfoView>
      </ContentBox>

      {/* //Aqui empieza el content box de booking */}
      <ContentBox title={(<>{translate('marketing.deeplinkBooking')}</>)}>
        <img style={{ height:"22px", width:"110px"}} src={require('assets/images/ota/booking.png')} />
        <div style={styles.desc}>{`${translate('marketing.deeplink1descBooking')}`}</div>
        <LinkGenerator url={actions.getHotelDeeplinkBooking()} />

        <InfoView style={styles.info} icon={<InfoCircleFilled />}>
          <div style={styles.infoTitle}>{translate('marketing.bookingCodeTitleBooking')}</div>
          <div> <a href="https://academy.civitfun.com/hc/es/articles/18859415118237-Sending-your-Civitfun-Check-in-Link-to-Guests-via-Booking-com-Messages" target='_blank' style={{ textDecoration: "none" }}>{translate('marketing.bookingCodeDescBookingLink')}</a>{translate('marketing.bookingCodeDescBooking')}</div>
          <div style={{ height: 16 }} />
        </InfoView>
      </ContentBox>


    </DefaultLayout>


  );
}
