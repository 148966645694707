import errorStyles from './errorDisplay.styles';
import React from 'react';

export default function ErrorDisplay({ errors = [] }) {
  if (!Array.isArray(errors)) {
    if (typeof errors === 'string') errors = [{ message: errors }];
    else errors = [errors];
  }

  return (
    <div style={errorStyles.container}>
      <h4 style={{ margin: 0 }}>Error</h4>
      {errors.length > 0 ? (
        <ul style={errorStyles.list}>
          {errors.map((error, index) => (
            <li key={index} style={errorStyles.listItem}>
              {error.message}
            </li>
          ))}
        </ul>
      ) : (
        <p>No errors to display.</p>
      )}
    </div>
  );
}
