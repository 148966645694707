import React, { useContext, useState } from 'react';
import useMapHook from './Map.hook';
import constants from '../../../utils/constants';
import { GoogleMap, StandaloneSearchBox, Marker, LoadScript } from '@react-google-maps/api';
import { HotelsContext } from '../../Dashboard/Hotels/Hotels.context';
import { AppContext } from '../../../App.context';

export default function Map() {
  const { hotelsState } = useContext(HotelsContext);
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { actions } = useMapHook();
  const containerStyle = {
    height: '240px',
    borderRadius: '4px',
  };

  const options = {
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: true,
    fullscreenControl: false,
  };

  const inputStyle = {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `340px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: 'absolute',
    margin: 8,
    left: 0,
  };
  const [searchBox, setSearchBox] = useState(null);
  const onSearchBoxLoad = React.useCallback(ref => {
    setSearchBox(ref);
  }, []);

  return (
    <div>
      <LoadScript
        id="script-loader"
        googleMapsApiKey={constants.GOOGLE_MAPS_KEY}
        libraries={constants.MAP_LIBRARIES}
      >
        <GoogleMap
          id="map"
          mapContainerStyle={containerStyle}
          center={hotelsState?.mapPosition}
          zoom={15}
          options={options}
          onUnmount={actions.onUnmount}
        >
          <StandaloneSearchBox onLoad={onSearchBoxLoad} onPlacesChanged={onPlacesChanged}>
            <input type="text" placeholder={translate('search')} style={inputStyle} />
          </StandaloneSearchBox>
          <Marker position={hotelsState.mapPosition} />
        </GoogleMap>
      </LoadScript>
    </div>
  );

  function onPlacesChanged() {
    const places = searchBox.getPlaces();
    actions.handlePlaceSelected(places);
  }
}
