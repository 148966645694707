export default {
  icon: {
    delete: {
      fill: '#64B5FF',
      width: 20,
      height: 20,
      cursor: 'pointer',
      margin: 8,
    },
    edit: {
      width: 20,
      height: 20,
      cursor: 'pointer',
      margin: 8,
      fill: '#0094FF',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    flex: 1,
  },
};
