import Colors from '../../../../../constants/Colors';

export default {
  button: {
    color: Colors.white,
    borderRadius: 8,
    backgroundColor: Colors.exportButton,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 6,
    alignItems: 'center',
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 12,
    paddingLeft: 12,
    '&:hover': {
      cursor: 'pointer',
    },
    fontWeight: 'bold',
    fontSize: 12,
  },
  divider: {
    marginTop: 16,
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    alignItems: 'center',
  },
  dividerBar: {
    flex: 1,
    marginLeft: 8,
    height: 0.5,
    backgroundColor: Colors.lightGray,
  },
};
