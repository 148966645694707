import React from 'react';
import { Image } from 'antd';

const Visor = ({ room }) => {
  if (!room) return null;

  // const { data: appConfig } = useAppConfigQuery();

  // const lang = room.selectedLanguage;

  // const title = room.texts.find(text => text.type === 'name' && text.lang === lang)?.value ?? '';
  // const description =
  //   room.texts.find(text => text.type === 'desc' && text.lang === lang)?.value ?? '';

  // const priceButtonProps = {
  //   htmlType: 'button',
  //   text: `+10${appConfig?.data?.currency || ''} / Night`,
  //   small: true,
  //   secondaryColor: '#2D5BFF',
  // };

  // const amenities = room.amenities;
  // const roomFeatures = room.roomFeatures;

  return (
    <Image src={require("../../../assets/images/visor-preview.png")} preview={false}></Image>
    /* <div style={styles.container}>
      <div style={styles.innerComponent}>
        {<VisorRoom
          datasource={{
            data: {
              physicalRoomGalleries: room?.physicalRoomGalleries?.length
                ? room.physicalRoomGalleries
                : [
                    { path: 'https://placehold.co/350x300' },
                    { path: 'https://placehold.co/90x80' },
                  ],
              amenities,
              roomFeatures,
              title,
              description,
            },
          }}
          footerButtons={
            [
            ]
          }
        /> }
      </div>
    </div>*/
  );
};

export default Visor;
