import React, { useState } from 'react';
import { Button, Spin, message, Modal } from 'antd';
import useGenerateFormButtonHook from './GenerateFormButton.hook';
import generateFormButtonStyles from './GenerateFormButton.styles';
import Colors from 'constants/Colors';
import CustomButton from 'Components/common/CustomButton/CustomButton';
import AppButton from 'Components/common/Forms/AppButton/AppButton';

function GenerateFormButton({ translate, isLoading }) {
  const [modalVisible, setModalVisible] = useState(false);
  const { generateFormFields, loading } = useGenerateFormButtonHook();

  const buttonText = translate('formFieldsInference.button');
  const modalDescription = translate('formFieldsInference.description');

  const handleAction = async () => {
    try {
      await generateFormFields();
      message.success('Operación completada con éxito!');
      window.location.reload();
    } catch (error) {
      message.error('Hubo un problema con la operación');
    }
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const handleOk = () => {
    setModalVisible(false);
    handleAction();
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <div style={generateFormButtonStyles.container}>
      <div style={{ ...generateFormButtonStyles.button, marginLeft: '1rem' }}>
        <AppButton text={buttonText} action={() => showModal()} secondary />
      </div>

      <Modal title={buttonText} open={modalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>{modalDescription}</p>
      </Modal>
      {loading && (
        <div style={generateFormButtonStyles.spinContainer}>
          <Spin size="large" tip="Cargando..." />
        </div>
      )}
    </div>
  );
}

export default GenerateFormButton;
