import axios from 'axios';
import apiConfig from '../api.config';

export default function uploadSliderImage({ hotelId, file, currentAmount }) {
  const {
    host,
    URIs: { uploadSliderImage },
  } = apiConfig;

  const uri = `${host}${uploadSliderImage}`.replace(':hotelId', hotelId);

  const formData = new FormData();
  formData.append('file', file);
  formData.append('hotelId', hotelId);
  formData.append('currentAmount', currentAmount);

  return axios
    .post(uri, formData)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.error(error);
    });
}
