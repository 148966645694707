export default {
  fieldsGrid: {
    display: 'grid',
    gridTemplateColumns: '33% 33% 33%',
    padding: '0px',
    margin: '0px',
    boxSizing: 'border-box',
    gap: 8,
  },
  timePicker: {
    width: '100%',
  },
};
