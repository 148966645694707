export default `<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:21px;font-family:&quot;Calibri&quot;,sans-serif;">Условия и положения&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:18px;font-family:&quot;Calibri&quot;,sans-serif;">ЮРИДИЧЕСКАЯ ИНФОРМАЦИЯ&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;">
    <span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">
        Для вашей безопасности мы гарантируем, что данные вашей кредитной карты будут обрабатываться в соответствии с международными стандартами PCI-DSS. Я даю разрешение отелю списывать все расходы за мою поездку по кредитной карте, указанной выше. В случае, если я покину отель на дату, предшествующую дате окончания бронирования, указанной в этом документе, я разрешаю списание полной суммы за забронированные ночи, включая неиспользованные.<br><br>
        В [hotel_name] мы привержены защите вашей конфиденциальности. Вы можете связаться с нами по адресу [address] или по электронной почте [hotel_email]. [hotel_name] будет обрабатывать ваши данные для управления вашим пребыванием в отеле и для улучшения вашего опыта как нашего гостя, в то время как [hotel_name] также будет обрабатывать ваши данные для предоставления услуг размещения и дополнительных услуг. Мы можем быть обязаны обрабатывать ваши данные для соблюдения юридических обязательств, которые на нас возложены (включая предотвращение мошенничества). [hotel_name] может передавать ваши данные третьим лицам, таким как банки или другие финансовые учреждения для обработки платежей, или правоохранительным органам для соблюдения обязательств, наложенных на нас законом.<br><br>
        Если вы зарегистрируетесь, [hotel_name] будет обрабатывать ваши персональные данные для предоставления соответствующих услуг по программе лояльности. В этом смысле мы будем обрабатывать любые накопленные баллы и можем отправлять вам информацию о ваших баллах и преимуществах программы, адаптированных к вашему профилю.<br><br>
        Вы можете реализовать свои права на доступ, исправление, удаление, возражение и/или ограничение обработки, а также на перенос ваших данных, написав в [hotel_name] по указанному выше адресу электронной почты или почтовому адресу, подтвердив свою личность и указав в обоих случаях (a) «Защита данных» в качестве темы и (b) на кого направлен ваш запрос – [hotel_name]. Кроме того, вы можете в любой момент связаться с компетентным национальным надзорным органом. <br><br>
        Для получения дополнительной информации, пожалуйста, посетите [hotel_web] или проконсультируйтесь на стойке регистрации отеля.
    </span>
</p>`;
