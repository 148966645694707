import axios from 'axios';
import apiConfig from '../api.config';

export default function updateLegalTextRequest({
  hotelId,
  lang,
  value,
  type,
  label,
  //customFileName,
}) {
  const {
    host,
    URIs: { updateLegalText },
  } = apiConfig;

  const uri = `${host}${updateLegalText}`;

  return axios
    .put(uri, JSON.stringify({ hotelId, lang, value, type, label /*, customFileName*/ }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
