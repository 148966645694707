import React, { useContext } from 'react';
import useBillingListingHook from './BillingListing.hook';
import { AppContext } from 'App.context.js';
import { BillingListingContext } from './BillingListing.context.js';
import ContentBox from 'Components/common/ContentBox/ContentBox.jsx';
import BillingContract from './BillingContract/BillingContract'
import BillingLicence from './BillingLicence/BillingLicence'
import BillingInvoice from './BillingInvoice/BillingInvoice'
import styles from './BillingContract/BillingContract.styles';
import AppButton from 'Components/common/Forms/AppButton/AppButton';

import BillingModal from './BillingModal/BillingModal';

export default function BillingInfo() {
  const { state, contract, invoice, actions } = useBillingListingHook();
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <BillingListingContext.Provider value={{ state, contract, invoice, actions }}>
      <>
        <div style={styles.uploadButtonBox}>
        {state.isUserAdmin &&
          <AppButton
            action={() => actions.showBillingModal()}
            text={translate('billingConfig.billingContract.uploadButton')}
            disabled={state.isLoading}
          />
        }
        </div>
        <ContentBox noPadding={true} title={translate('billingConfig.billingContract.title')}>
          <BillingContract />
        </ContentBox>
        <ContentBox noPadding={true} title={translate('billingConfig.invoice.title')}>
          <BillingInvoice />
        </ContentBox>
        <ContentBox singleBox={true} noPadding={true} title={translate('billingConfig.licence.title')}>
          <BillingLicence />
        </ContentBox>
        <BillingModal />
      </>
    </BillingListingContext.Provider>
  );
}
