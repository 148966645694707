import React from 'react';
import styles from './HoverWrapper.module.css';

export default function HoverWrapper({ children, onClick }) {
  return (
    <div className={styles.view} onClick={onClick && onClick}>
      {children}
    </div>
  );
}
