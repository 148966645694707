import Colors from '../../../../../constants/Colors';

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: 52,
    marginTop: 8,
  },
  title: {
    marginTop: 28,
    fontSize: 14,
    fontWeight: '700',
    color: Colors.darkText,
  },
};
