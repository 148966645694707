import { Image } from 'antd';
import React from 'react';

export default function IconHolder({ color, icon, size }) {
  const SIZE = size || 32;
  return (
    <div
      style={{
        width: SIZE,
        height: SIZE,
        borderRadius: SIZE / 2,
        backgroundColor: color,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 6,
      }}
    >
      <img style={{ width: SIZE, height: SIZE, padding: 6 }} src={icon} />
    </div>
  );
}
