export const HOST = 'https://upsell-api.civitfun.com';

export const request = (uri, options = { headers: {} }, queryParams = {}) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const selectedHotel = JSON.parse(localStorage.getItem('selectedHotel'));
  const params = new URLSearchParams({ ...queryParams, hotelId: selectedHotel.id });
  return fetch(`${HOST}/${uri}?${params}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.accessToken}`,
      ...options.headers,
    },
    ...options,
  }).then(res => {
    if (res.status === 422) {
      return res.json().then(data => {
        const { message, path } = data.message[0];
        throw new Error(`error.${path.join('.')}.${message}`.toLowerCase());
      });
    }

    if (options.method !== 'DELETE') {
      return res.json();
    }
  });
};

export const deleteResource = (resource, data) => {
  return request(`${resource}/${data}`, {
    method: 'DELETE',
  });
};

export const findAll = (resource, filters = {}) => {
  return request(
    resource,
    {
      method: 'GET',
    },
    { filters: JSON.stringify(filters) }
  );
};

export const upsert = (resource, data) => {
  if (data.id) {
    return request(`${resource}/${data.id}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    });
  }
  return request(`${resource}`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const findById = (resource, data) =>
  request(`${resource}/${data.id}`, {
    method: 'GET',
  });

export const findHotelConfig = () =>
  request(`hotels/config`, {
    method: 'GET',
  });

export const createRoomType = (resource, data) => {
  return request(`${resource}`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const updateRoomType = (resource, data) => {
  return request(`${resource}/${data.id}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
};

export const uploadImages = async (resource, formData, queryParams = {}) => {
  const headers = new Headers();
  const user = JSON.parse(localStorage.getItem('user'));
  const selectedHotel = JSON.parse(localStorage.getItem('selectedHotel'));
  headers.append('Authorization', `Bearer ${user.accessToken}`);
  const params = new URLSearchParams({ ...queryParams, hotelId: selectedHotel.id });
  const response = await fetch(`${HOST}/${resource}?${params}`, {
    method: 'POST',
    body: formData,
    headers,
  });
  return response;
};

export const reports = (filters = {}) => {
  return request(
    `upsells/report`,
    {
      method: 'GET',
    },
    { filters: JSON.stringify(filters) }
  );
};

export const counters = filters => {
  return request(
    'upsells/counters',
    {
      method: 'GET',
    },
    { filters: JSON.stringify(filters) }
  );
};
