import Colors from 'constants/Colors';

export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
  selector: {
    border: `1px solid ${Colors.lightGray}`,
    borderRadius: 6,
    fontSize: 14,
    width: 172,
  },
};
