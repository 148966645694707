import React, { useContext } from 'react';
import styles from './TestEmailModal.styles.js';
import Input from 'antd/lib/input/Input';
import { AppContext } from 'App.context.js';

export default function TestEmailModal({ setMail }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <div>
      <div style={styles.title}>{translate('mailTemplates.testButton')}</div>
      <div style={styles.desc}>{translate('mailTemplates.testMailDesc')}</div>
      <Input
        onChange={e => {
          setMail(e.target.value);
        }}
        loading={true}
        placeholder={translate('mailTemplates.emailListHint')}
      />
    </div>
  );
}
