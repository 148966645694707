import { useEffect, useState } from 'react';

export default function useDoorOpeningConfigFormHook({
  configData,
  platform,
  checkinStatusOptions,
  paymentStatusOptions,
  onValueChanged,
}) {
  const [state, setState] = useState({
    checkinStatusOptions,
    checkinStatusDisabled: false,
    paymentStatusOptions,
    paymentStatusDisabled: false,
    configData,
  });

  const platformConstraints = {
    all: [
      {
        keyType: 'app',
        actions: ['required-checked-in'],
      },
    ],
    // //  Example
    // tesa: [
    //   {
    //     keyType: 'app',
    //     actions: ['required-paid', 'indifferent-checkin'],
    //   },
    // ],
  };

  const constraintsActions = {
    'required-checked-in': () => {
      onValueChanged('checkinStatus', 'checkedin');
      updateState({ checkinStatusDisabled: true });
    },
    'required-paid': () => {
      onValueChanged('paymentStatus', 'paid');
      updateState({ paymentStatusDisabled: true });
    },
    'indifferent-checkin': () => {
      updateState({ checkinStatusDisabled: false });
    },
    'indifferent-payment': () => {
      updateState({ paymentStatusDisabled: false });
    },
  };

  useEffect(() => {
    updateState({
      checkinStatusDisabled: false,
      paymentStatusDisabled: false,
    });
    platformConstraints.all.forEach(actions.executeConstraint);
    platformConstraints[platform] &&
      platformConstraints[platform].forEach(actions.executeConstraint);
  }, [, configData.keyType, platform]);

  const actions = {
    executeConstraint: constraint => {
      if (configData.keyType === constraint.keyType) {
        constraint.actions.forEach(action => {
          constraintsActions[action]();
        });
      }
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
