import React, { useContext } from 'react';
import BaseTable from '../../common/BaseTable/BaseTable';
import CustomButton from '../../common/CustomButton/CustomButton';
import InfoPill from '../../common/InfoPill/InfoPill';
import DefaultLayout from '../../DefaultLayout/DefaultLayout';
import useSalesChannelsHook from './SalesChannels.hook';
import { Image, Input, Modal, Popover } from 'antd';
import styles from './SalesChannels.styles';
import constants from '../../../utils/constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import Colors from '../../../constants/Colors';
import { AppContext } from '../../../App.context';
import FeatModal from 'Components/common/FeatModal/FeatModal';

export default function SalesChannels() {
  const {
    appState: { superUser },
    appActions: { translate },
  } = useContext(AppContext);

  const { state, actions } = useSalesChannelsHook();
  const popoverImage = require('../../../../src/assets/images/booking-popover.png');
  const urlSmtp = "https://academy.civitfun.com/hc/es/articles/18889029491485-Customize-Guestlink-Email-Sender-by-Configuring-Your-SMTP";
  const statePopoverContent = (
    <div style={styles.popoverContainer}>
      {translate('salesChannels.activeGuestlink1')} <a href={urlSmtp} target='_blank'>{translate('salesChannels.activeGuestlink2')}</a>
      <Image style={styles.popoverImage} src={popoverImage} />
    </div>
  );

  const redirectToAcademyLink = () => {
    window.open(
      'https://academy.civitfun.com/hc/en-gb/articles/18811595957789-Authorize-Civitfun-Emails-On-Booking-com',
      '_blank'
    );
  };

  const columns = [
    {
      title: translate('salesChannels.stateCol'),
      key: 'client',
      dataIndex: 'client',
      align: 'center',
      width: '12%',
      render: (_, row) => (
        <div>
          {actions.getClientStateTextColor(row.client) == Colors.greenHotelIntegratedBg ||
          row?.client?.client_id != constants.IH_CLIENT.BOOKINGDOTCOM ? (
            <InfoPill
            backgroundColor={actions.getClientStateBackgroundColor(row.client)}
              color={actions.getClientStateTextColor(row.client)}
              text={actions.getClientStateString(row.client)}
            />
          ) : (
            <Popover trigger="click">
              <Popover content={statePopoverContent}>
                <InfoPill
                  color={actions.getClientStateTextColor(row.client)}
                  backgroundColor={actions.getClientStateBackgroundColor(row.client)}
                  text={actions.getClientStateString(row.client)}
                />{' '}
              </Popover>
            </Popover>
          )}
        </div>
      ),
    },
    {
      title: translate('salesChannels.hotelCol'),
      dataIndex: 'hotel',
      key: 'hotel',
      render: (_, row) => {
        return <div style={{ fontWeight: 500 }}>{row.hotel}</div>;
      },
    },
    {
      title: translate('salesChannels.channelCol'),
      key: 'client.name',
      dataIndex: 'client.name',
      searchable: 'true',
      render: (_, row) => {
        const style = { width: 90 };
        if (row?.client?.client_id == constants.IH_CLIENT.BOOKINGDOTCOM) {
          return <img style={style} src={require('assets/images/ota/booking.png')} />;
        } else if (row?.client?.client_id == constants.IH_CLIENT.MTS) {
          return <img style={style} src={require('assets/images/ota/mts.png')} />;
        } else if (row?.client?.client_id == constants.IH_CLIENT.RECEPTION) {
          return <img style={style} src={require('assets/images/logoPaperless.png')} />;
        } else if (row?.client?.client_id == constants.IH_CLIENT.KIOSK) {
          return <img style={style} src={require('assets/images/logoKiosk.png')} />;
        } else {
          return <div>{row?.client?.name}</div>;
        }
      },
    },
    {
      title: translate('salesChannels.identifierCol'),
      key: 'client',
      dataIndex: 'client',
      render: (_, row) => {
        const clientId = row?.client?.client_id;
        const hotelId = row.hotelId;
        const showInput =
          // client.clientId === constants.IH_CLIENT.BOOKINGDOTCOM ||
          (clientId === constants.IH_CLIENT.RECEPTION && row.client.custom_hotel_id) ||
          (clientId === constants.IH_CLIENT.KIOSK && row.client.custom_hotel_id);
        const disabled =
          row.client.custom_hotel_id ||
          clientId === constants.IH_CLIENT.RECEPTION ||
          clientId === constants.IH_CLIENT.KIOSK;
        const showError = actions.shouldShowInputIdentifierAsError(hotelId, clientId);

        return showInput ? (
          <Input
            style={{ border: showError ? '1px solid #E54646' : '1px solid #C8CED2' }}
            placeholder={translate('salesChannels.bookingIdHint')}
            value={disabled ? row.client.custom_hotel_id : actions.getIdentifier(hotelId, clientId)}
            onChange={event => actions.setIdentifier(hotelId, clientId, event.target.value, row)}
            disabled={disabled}
            suffix={
              clientId === constants.IH_CLIENT.BOOKINGDOTCOM && (
                <Popover
                  trigger="click"
                  visible={showError}
                  onVisibleChange={() => actions.handlePopoverVisibility()}
                  content={statePopoverContent}
                >
                  <Popover content={statePopoverContent}>
                    <InfoCircleOutlined style={{ color: Colors.tranparentBlack }} />
                  </Popover>
                </Popover>
              )
            }
          />
        ) : null;
      },
    },
    {
      title: translate('salesChannels.actionCol'),
      key: 'key',
      dataIndex: 'key',
      align: 'center',
      render: (_, row) => (
        <div>
          {renderActionCol(row)}

          <div style={{ display: 'none' }}>
            <form method="POST" id="StripeBookingPayment">
              <input id="hotelId" type="hidden" name="hotelId" />
              <input id="clientId" type="hidden" name="clientId" />
              <input id="customHotelId" type="hidden" name="customHotelId" />
              <input id="hotelFilter" type="hidden" name="hotelFilter" />
              <input id="type" type="hidden" name="type" />
              <button type="submit">{translate('salesChannels.tryFreeButton')}</button>
            </form>
          </div>
        </div>
      ),
    },
  ];

  return (
    <DefaultLayout singleBox title={translate('layout.sales')} enableAllHotels={true}>
      <div style={styles.tableContainer}>
        <div style={styles.table}>
          <BaseTable
            columns={columns}
            data={state.dataSource}
            loading={state.isLoading}
            onChange={actions.onChange}
            localPagination
          />
        </div>
      </div>
      <FeatModal
        title={translate('salesChannels.otaPopup.title')}
        info={translate('salesChannels.otaPopup.text').replace('$OTA', state.ota)}
        buttonText={translate('salesChannels.otaPopup.button').replace('$OTA', state.ota)}
        visible={state.showOTAPopup}
        onCancel={() => actions.setShowOTAPopup(false)}
        buttonAction={() => actions.ctaChangeOTAState()}
        popUpImage={require('assets/images/civitfun_mts_logo.png')}
      />
    </DefaultLayout>
  );

  function renderActionCol(row) {
    switch (row.client.client_id) {
      case constants.IH_CLIENT.BOOKINGDOTCOM:
        // TODO
        /**
         * Booking.com doesn't require a Stripe suscription in order to be connected till end of august.
           It will be treated as a premium suscription.
        */

        /* if (!row.client.suscribed && !row.premium) {
          return (
            <div>
              <CustomButton
                background={Colors.tryFreeButton}
                color={'white'}
                text={translate('salesChannels.tryFreeButton')}
                onClick={() => actions.onConfigureChannelPressed(row)}
              />
            </div>
          );
        } else */ if (!row.client.enabled) {
          return (
            <CustomButton
              background={Colors.bookingColorCheckinList}
              color={'white'}
              text={translate('setup')}
              // onClick={() => actions.connectBooking(row)}
              onClick={() => redirectToAcademyLink()}
            />
          );
        } else {
          return null;
        }
      case constants.IH_CLIENT.MTS:
        if (row.client.enabled) {
          return (
            <CustomButton
              background={Colors.disabledButton}
              color={'white'}
              text={'Desactivar'}
              onClick={() => actions.changeOTAState(row)}
            />
          );
        } else if (row.client.exists) {
          return (
            <CustomButton
              background={Colors.bookingColorCheckinList}
              color={'white'}
              text={'Activar'}
              onClick={() => actions.changeOTAState(row)}
            />
          );
        } else {
          return null;
        }
      case constants.IH_CLIENT.RECEPTION:
      case constants.IH_CLIENT.KIOSK:
        if (superUser && !row.client.enabled) {
          return (
            <CustomButton
              background={Colors.blueStrongColor}
              color={'white'}
              text={translate('registerPaperless.createButton')}
              onClick={() => actions.addPaperlessUser(row.hotelSlug, row.client.client_id)}
            />
          );
        } else return null;
      default:
        return null;
    }
  }
}
