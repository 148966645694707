import React, { useContext, useState } from 'react';
import Loading from 'Components/common/Loader/Loading';
import Colors from 'constants/Colors';
import configStyles from '../../../Dashboard/PMS/PMSConfig/PMSConfig.styles';
import CustomButton from 'Components/common/CustomButton/CustomButton';
import { AppContext } from 'App.context';
import AppButton from 'Components/common/Forms/AppButton/AppButton';

export default function AutomaticConfigurationTest({ buttonAction, loadingPosition = 'before' }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const [isTesting, setIsTesting] = useState(false);

  const handleButtonClick = async () => {
    setIsTesting(true); // Activar el estado de testing

    try {
      await buttonAction();
    } catch (error) {
      console.error('Error executing button action:', error);
    } finally {
      setIsTesting(false); // Desactivar el estado de testing
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      {loadingPosition === 'before' && isTesting && <Loading size={'small'} />}
      <AppButton
        text={translate('pmsScreen.testPms')}
        action={handleButtonClick} // Usar la función que maneja el click
        secondary
        disabled={isTesting}
      />

      {loadingPosition === 'after' && isTesting && <Loading size={'small'} />}
    </div>
  );
}
