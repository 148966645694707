import axios from 'axios';
import apiConfig from '../api.config';

export default function pmsUpdateUdfConfigRequest({ hotelId, udfId, name, field }) {
  const {
    host,
    URIs: { pmsConfigHotel },
  } = apiConfig;
  const uri = `${host}${pmsConfigHotel}/${hotelId}/udf/${udfId}`;

  return axios
    .put(
      uri,
      { name, field },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(res => {
      return res.data;
    });
}
