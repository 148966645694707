import BaseTable from 'Components/common/BaseTable/BaseTable';
import CustomButton from 'Components/common/CustomButton/CustomButton';
import Colors from 'constants/Colors';
import React, { useContext } from 'react';
import { AppContext } from '../../../../App.context';
import WelcomeLayout from '../WelcomeLayout/WelcomeLayout';
import useSuperUserAccountsHook from './SuperUserAccounts.hook';

export default function SuperUserAccounts() {
  const { state, actions } = useSuperUserAccountsHook();
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const columns = [
    {
      title: translate('superUser.accountName'),
      dataIndex: ['chain', 'name'],
      key: 'chain.name',
      searchable: true,
      render: (_, hotel) => {
        return <div>{hotel.chain.name}</div>;
      },
    },
    {
      title: translate('superUser.hotelName'),
      dataIndex: 'name',
      key: 'name',
      searchable: true,
      render: (_, hotel) => {
        return <div>{hotel.name}</div>;
      },
    },
    {
      key: 'key',
      dataIndex: 'key',
      align: 'center',
      render: (_, hotel) => (
        <div style={{ display: 'flex', height: 30, justifyContent: 'center' }}>
          <CustomButton
            background={Colors.blueColor}
            color={'white'}
            text={translate('superUser.access')}
            onClick={() => actions.impersonateLogin(hotel.chain_id)}
          />
        </div>
      ),
    },
  ];

  return (
    <WelcomeLayout title={translate('superUser.selectAccount')} showBack>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <BaseTable
          columns={columns}
          data={state.dataSource}
          loading={state.isLoading}
          defaultLimit={5}
          showSizeChanger={false}
          onChange={filters => actions.onChange(filters)}
        />
      </div>
    </WelcomeLayout>
  );
}
