import React from 'react';
import { TimePicker, Checkbox } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import CustomButton from 'Components/common/CustomButton/CustomButton';
import { useApp } from 'Sell/hooks/useApp';

function SlotDayForm({ days, handlers }) {
  const {
    appActions: { translate },
  } = useApp();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
      {days.map(day => {
        return (
          <div
            key={day.id}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 30 }}
          >
            <Checkbox
              checked={day.enabled}
              onChange={() => handlers.handleSlotDayStatusChange(day)}
              style={{ width: '100px' }}
            >
              {translate(`crossSell.itemDetail.slot.days.${day.name}`)}
            </Checkbox>
            <TimePicker
              format="HH:mm"
              value={day.startTime}
              onChange={time => handlers.handleSlotDayTimeChange(day, [time])}
            />
            <CustomButton
              onClick={() => handlers.handleSlotAddDay(day)}
              background="#2D5BFF"
              color="white"
            >
              <PlusOutlined />
            </CustomButton>
          </div>
        );
      })}
    </div>
  );
}

export default SlotDayForm;
