import React, { useContext } from 'react';
import { Select } from 'antd';
import { AppContext } from '../../../../App.context';
import styles from './DoorOpeningSelector.styles';
import getDoorOpeningLogo from 'utils/getDoorOpeningLogo';

export default function DoorOpeningSelector({
  row,
  doorOpeningIntegrations,
  selectedDoorOpening,
  setSelectedDoorOpening,
  disabled,
}) {
  const { Option } = Select;
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const selectedDoorOpeningName = selectedDoorOpening?.name;

  return (
    <div style={styles.container}>
      <div style={styles.selector}>
        <Select
          onChange={option => setSelectedDoorOpening(row.id, option)}
          bordered={false}
          style={{ width: '100%' }}
          disabled={disabled}
          value={
            selectedDoorOpening ? selectedDoorOpeningName : translate('doorOpening.defaultSelector')
          }
        >
          {doorOpeningIntegrations?.map((doorOpening, index) => {
            return (
              <Option key={index} value={doorOpening.id}>
                {doorOpening.name}
              </Option>
            );
          })}
        </Select>
      </div>
      {selectedDoorOpening && (
        <img
          src={getDoorOpeningLogo(selectedDoorOpening?.name)}
          style={{ alignSelf: 'center', height: 36 }}
        />
      )}
    </div>
  );
}
