import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import './Sortable.css';

export function SortableItem({ id, children }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const Item = React.cloneElement(children, {
    ref: setNodeRef,
    style: { ...children.props.style, ...style },
    className: `sortable-item ${children.props.className}`,
    ...attributes,
    ...listeners,
  });

  return Item;
}
