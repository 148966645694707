export default {
  container: {
    display: 'flex',
    gap: 8,
    justifyContent: 'flex-end',
    position: 'relative',
  },
  button: {
    marginRight: '7%',
    marginBottom: '13px',
  },
  spinContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
  },
};
