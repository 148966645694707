import React, { useContext } from 'react';
import AppButton from '../Forms/AppButton/AppButton.jsx';
import Colors from '../../../constants/Colors.js';
import useRequestSuscriptionHook from './RequestSuscription.hook.js';
import { AppContext } from '../../../App.context.js';

export default function RequestSuscription({
  suscription,
}) {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useRequestSuscriptionHook();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          backgroundColor: Colors.white,
          borderRadius: 16,
          padding: 16,
          maxWidth: 600,
        }}
      >
        <div style={{ fontWeight: 'bold' }}>
          {actions.getTitleModule(suscription)}
        </div>
        <div>{actions.getDescriptionModule(suscription)}</div>
        {state.sendSuscriptionRequestEnabled && (
          <div style={{ display: 'flex', justifyContent: 'right', marginTop: 8 }}>
            <AppButton
              text={translate('requestSuscription.send')}
              action={() => actions.requestModuleFunctionality({ suscription })}
            />
          </div>
        )}
      </div>
    </div>
  )
}
