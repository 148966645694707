import React, { useContext } from 'react';
import styles from './PoliceSFTP.styles.js';
import MailTemplateInput from 'Components/Dashboard/Guestlink/MailTemplateDetail/MailTemplateInput/MailTemplateInput.jsx';
import AppButton from 'Components/common/Forms/AppButton/AppButton.jsx';
import { AppContext } from 'App.context.js';
import Loading from 'Components/common/Loader/Loading.jsx';
import { PoliceReportConfigContext } from '../PoliceReportConfig.context.js';
import Space from 'Components/common/Space/Space.jsx';

export default function PoliceSFTP() {
  const { state, actions } = useContext(PoliceReportConfigContext);
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <div>
      <div style={styles.credentialsContainer}>
        <MailTemplateInput
          value={state.ftpHost}
          name={translate('policeReport.ftpHost')}
          onChange={value => actions.setField('ftpHost', value)}
          disabled={state.isLoading}
        />
        <div style={styles.port}>
          <MailTemplateInput
            value={state.ftpPort}
            name={translate('policeReport.ftpPort')}
            onChange={value => actions.setField('ftpPort', value)}
            disabled={state.isLoading}
          />
        </div>
      </div>
      <div style={styles.credentialsContainer}>
        <MailTemplateInput
          value={state.ftpUser}
          name={translate('policeReport.ftpUser')}
          onChange={value => actions.setField('ftpUser', value)}
          disabled={state.isLoading}
        />
        <MailTemplateInput
          value={state.ftpPass}
          name={translate('policeReport.ftpPass')}
          onChange={value => actions.setField('ftpPass', value)}
          disabled={state.isLoading}
          password
        />
      </div>
      <MailTemplateInput
        value={'YYYY-MM-DD.txt'}
        name={translate('policeReport.fileFormat')}
        onChange={value => actions.setField('fileFormat', value)}
        disabled={true}
      />
      <Space height={16} />
      <MailTemplateInput
        value={state.ftpDirPath}
        name={translate('policeReport.ftpDirPath')}
        onChange={value => actions.setField('ftpDirPath', value)}
        disabled={state.isLoading}
      />
      <div style={styles.buttonContainer}>
        {state.isLoading && <Loading size={'small'} />}
        <AppButton
            text={translate('policeReport.checkConnection')}
            secondary
            action={() => actions.onCheckConnection()}
            disabled={state.isLoading}
          />
        <AppButton
          text={translate('policeReport.save')}
          action={() => actions.onSaveFTPConfig()}
          disabled={state.isLoading}
        />
      </div>
    </div>
  );
}
