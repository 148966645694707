import { useState } from 'react';
import paymentService from '../../../../utils/networking/api/validations/payment.service';

const usePaymentDataHook = () => {
  const [loading, setLoading] = useState(false);

  const sendPayment = async ({ hotelId, bookingIdentifier, amount }) => {
    setLoading(true);

    try {
      return await paymentService({
        hotelId,
        action: 'paymentData',
        authorization: process.env.REACT_APP_AUTH,
        bookingIdentifier,
      });
    } catch (error) {
      console.error('Error Sending Payment:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { sendPayment, loading };
};

export default usePaymentDataHook;