import Colors from 'constants/Colors';

const SIDE_ITEM_INDICATOR_SIZE = 20;

export default {
  sideMenuItem: {
    padding: 16,
    borderRadius: 16,
    backgroundColor: Colors.white,
  },
  sideItemTitle: {
    color: Colors.darkText,
    fontWeight: 'bold',
  },
  sideItemHeader: {
    color: Colors.darkText,
    fontWeight: 'bold',
    fontSize: 18,
  },
  sideItemIndicator: {
    marginTop: 16,
    marginBottom: 4,
    borderRadius: 6,
    width: SIDE_ITEM_INDICATOR_SIZE,
    height: SIDE_ITEM_INDICATOR_SIZE,
  },
  sideItemContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: Colors.darkText,
  },
  sideItemNumber: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  desc: {
    fontSize: 12,
  },
};
