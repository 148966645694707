import AppButton from 'Components/common/Forms/AppButton/AppButton';
import Colors from 'constants/Colors';
import React, { useContext } from 'react';
import { Input, Button } from 'antd';
import styles from './ButtonGenerator.styles';
import { CopyOutlined } from '@ant-design/icons';
import { copyTextToClipboard } from 'utils/TextUtils';
import { AppContext } from 'App.context';

export default function ButtonGenerator({ title, url }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { TextArea } = Input;

  const code = `<a href="${url}" target="_blank"><style>#T1{all: unset; padding: 10px; background-color: ${
    Colors.bookingColor
  }; color:white; border-radius: 12px; font-size: 14px; border-color: ${
    Colors.bookingColor
  }; font-family: Arial, sans-serif; font-weight: bold}#T1:hover{background-color:${
    Colors.bookingTransparent
  }}</style><button id=T1>${translate('marketing.buttonText')}</button></a>`;

  return (
    <div style={styles.buttonsContainer}>
      <div>
        <Button
          type="primary"
          shape="square"
          ghost
          icon={<CopyOutlined />}
          style={{ color: Colors.superUserButton, borderColor: Colors.lightGray }}
          onClick={() => copyTextToClipboard(url)}
        />
      </div>
      <div style={{ flex: 1 }}>
        <div style={{ fontWeight: 'bold', paddingBottom: 4, color: Colors.darkText }}>{title}</div>
        <div style={styles.codeContainer}>
          <TextArea disabled rows={4} value={code} autoSize={{ minRows: 4, maxRows: 4 }} />
        </div>
      </div>
      <div style={styles.exampleContainer}>
        <div>{translate('marketing.example')}</div>
        <div dangerouslySetInnerHTML={{ __html: code }} />
      </div>
    </div>
  );
}
