import React from 'react';
import { Modal, notification } from 'antd';
import { AppContext } from 'App.context';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApiContext } from 'utils/networking/Api.context';
import { getEmailScheduleCode, validateEmail } from 'utils/TextUtils';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { getTemplatesAndSubjectFromLangs } from '../Guestlink.utils';
import POLICE_CONSTANTS from 'Components/Dashboard/PoliceReport/civitfun-police-report-utils/police.constants';
import AppStorage from 'utils/AppStorage';
import constants from 'utils/constants';
import { decompress } from '../../../../utils/Compression';

export default function useMailTemplateDetailHook(
  templateId,
  POLICE_REPORT_ENABLED,
  defaultSender
) {
  const { warning } = Modal;
  const MAX_FILE_SIZE_MB = 20;
  const { API } = useContext(ApiContext);
  const history = useHistory();
  const {
    appActions: { translate, getSelectedHotel },
  } = useContext(AppContext);

  const SELECTED_HOTEL_ID = getSelectedHotel().key;

  const SHOW_RECIPIENT_TEMPLATES = [
    'pre-stay-upsell-hotel-requested',
    'pre-stay-upsell-hotel-accepted',
    'pre-stay-upsell-hotel-rejected',
    'pre-stay-cross-sell-hotel-requested',
    'pre-stay-cross-sell-hotel-accepted',
    'pre-stay-cross-sell-hotel-rejected',
  ];

  const [state, setState] = useState({
    isLoading: true,
    template: null,
    insertTag: null,
    selectedLanguage: null,
    availableLanguages: null,
    applyToAllLanguages: false,
    showSendTestEmailModal: null,
    isSendingTestEmail: false,
    testEmailAddress: null,
    scheduleTypes: [],
    selectedScheduleType: null,
    hotelTagValues: [],
    oldTemplateType: null,
    guestlinkSuscription: null,
    selectedCategories: ['transactional', 'marketing'],
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    if (templateId) {
      actions.loadTemplateDetail(templateId);
    } else {
      actions.loadOptions();
    }

    return () => {
      AppStorage.removeItem(AppStorage.KEYS.GUESTLINK_SELECTED_LANGUAGE);
    };
  }, []);

  useEffect(() => {

    if (!state.selectedScheduleType || (templateId && !!state?.template?.texts[0]?.value)) return;
    const textModified = false;
    if (textModified) return;

    // reset templates
    const blankTemplate = state.template;
    blankTemplate.texts = [];
    updateState({ template: blankTemplate });

    const callback = (response) => {
      const templateCp = state.template;
      if (state.selectedLanguage === response?.content?.lang) {
        templateCp.texts = templateCp.texts.filter((item) => item.lang !== state.selectedLanguage);
      }

      templateCp.texts.push({
        lang: response?.content?.lang,
        subject: response?.content?.subject,
        value: response?.content?.body,
      })

      updateState({ template: templateCp });
    }

    const queueStop = getTemplatesAndSubjectFromLangs({
      schedule: state.selectedScheduleType,
      languages: state.availableLanguages,
      callback,
      API,
      hotelId: SELECTED_HOTEL_ID,
    });

    return () => {
      queueStop();
    }
  }, [state.selectedScheduleType]);

  const actions = {
    loadTemplateDetail: templateId => {
      updateState({ isLoading: true });
      API.getMailTemplateDetail({ templateId, hotelId: SELECTED_HOTEL_ID })
        .then(res => {
          const template = actions.replaceAllTags(res?.item, res?.hotelTagValues);

          if (!defaultSender) template.sendFrom = constants.NOREPLY_CIVITFUN_EMAIL;
          else if (defaultSender && !template.sendFrom) template.sendFrom = defaultSender;

          updateState({
            isLoading: false,
            template,
            availableLanguages: res?.languages,
            selectedLanguage: res?.languages[0].code,
            scheduleTypes: res?.scheduleTypes,
            selectedScheduleType: res?.item.scheduleType,
            activeScheduleTypes: res?.activeScheduleTypes,
            hotelTagValues: res?.hotelTagValues,
            oldTemplateType: res?.item.scheduleType.id,
            guestlinkSuscription: res?.guestlinkSuscription,
          });

          AppStorage.saveItem(AppStorage.KEYS.GUESTLINK_SELECTED_LANGUAGE, res?.languages[0].code);
        })
        .catch(_ => {
          updateState({ isLoading: false });
        });
    },

    loadOptions: () => {
      updateState({ isLoading: true });
      API.getGuestlinkOptions({ hotelId: SELECTED_HOTEL_ID })
        .then(res => {
          updateState({
            isLoading: false,
            availableLanguages: res?.languages,
            selectedLanguage: res?.languages[0].code,
            scheduleTypes: res?.scheduleTypes,
            selectedScheduleType: res?.scheduleTypes[0].schedules[0],
            activeScheduleTypes: res?.activeScheduleTypes,
            hotelTagValues: res?.hotelTagValues,
            guestlinkSuscription: res?.guestlinkSuscription,
            template: {
              name: null,
              sendFrom: defaultSender ? defaultSender : constants.NOREPLY_CIVITFUN_EMAIL,
              ccList: null,
              bccList: null,
              texts: [],
              recipient: null,
            },
          });

          AppStorage.saveItem(AppStorage.KEYS.GUESTLINK_SELECTED_LANGUAGE, res?.languages[0].code);
        })
        .catch(_ => {
          updateState({
            isLoading: false,
          });
        });
    },

    onBackClicked: () => {
      history.goBack();
    },

    onSaveClicked: async applyToAllHotels => {
      updateState({ isLoading: true });

      if (actions.validateFields()) {
        if (state.applyToAllLanguages) {
          actions.applyTextToAllLanguages();
        }
        const cp = state.template;
        cp.attachments = await actions.uploadAllAttachments();
        if (state.applyToAllLanguages && cp.attachments.length) {
          const attachments = [];
          const selectedLangMedia = cp.attachments.find(
            m => m.lang === actions.getSelectedLanguage()
          );
          for (const language of state.availableLanguages) {
            attachments.push({
              ...selectedLangMedia,
              lang: language.code,
            });
          }
          cp.attachments = attachments;
        }

        for (const text of cp.texts) {
          if (!text.subject) {
            text.subject = state.template.texts
              .find((t) => t.lang === text.lang).subject;
          }
        }

        updateState({ template: cp });

        const template = actions.replaceValuesWithTags(JSON.parse(JSON.stringify(state.template)));

        if (!defaultSender) template.sendFrom = constants.NOREPLY_CIVITFUN_EMAIL;
        else if (defaultSender && !template.sendFrom) template.sendFrom = defaultSender;

        if (state.template.id) {
          actions.doUpdate(template, applyToAllHotels);
        } else {
          actions.createNewGuestlink(template, applyToAllHotels);
        }
      } else {
        updateState({ isLoading: false });
      }

      return null;
    },

    onFieldChanged: (key, value) => {
      const copy = state.template;
      copy[key] = value;
      updateState({ template: copy });
    },

    validateFields: () => {
      if (!state.selectedScheduleType?.id) {
        notification.error({
          message: translate('mailTemplates.scheduleEmptyError'),
        });
        return false;
      } else if (actions.isScheduleTypeNotValid()) {
        notification.error({
          message: translate('mailTemplates.scheduleError'),
        });
        return false;
      } else if (
        !state.template?.sendFrom
          ? false
          : !validateEmail(state.template?.sendFrom) || state.template?.sendFrom.includes(',')
      ) {
        notification.error({
          message: translate('mailTemplates.errorSendFrom'),
        });
        return false;
      } else if (!state.template.name) {
        notification.error({
          message: translate('mailTemplates.nameError'),
        });
        return false;
      } else if (!actions.validateEmailList(state.template?.ccList)) {
        notification.error({
          message: translate('mailTemplates.errorCCList'),
        });
        return false;
      } else if (!actions.validateEmailList(state.template?.bccList)) {
        notification.error({
          message: translate('mailTemplates.errorBCCList'),
        });
        return false;
      } else {
        return true;
      }
    },

    isScheduleTypeNotValid: () => {
      const prestaySelected = state.selectedScheduleType.emailType === 'pre-stay';

      if (state.guestlinkSuscription !== 'active') {
        if (!prestaySelected || state.selectedScheduleType.category !== 'transactional') {
          return true;
        }
      }
    },

    validateEmailList: list => {
      if (list) {
        for (const mail of list.trim().split(',')) {
          if (!validateEmail(mail.trim())) {
            return false;
          }
        }
        return true;
      } else {
        return true;
      }
    },

    checkIfTypeIsAlreadyInUse: applyToAllHotels => {
      const matchingEnabledTemplate = state.activeScheduleTypes.find(
        g => g.scheduleTypeId === state.selectedScheduleType.id
      );

      if (
        state.template.id &&
        state.template.enabled &&
        matchingEnabledTemplate &&
        matchingEnabledTemplate.templateId !== state.template.id
      ) {
        actions.showTemplateTypeAlreadyEnabledWarning();
      } else {
        actions.onSaveClicked(applyToAllHotels);
      }
    },

    showTemplateTypeAlreadyEnabledWarning: () => {
      warning({
        title: translate('mailTemplates.saveErrorSameTypeTitle'),
        icon: <ExclamationCircleFilled />,
        content: translate('mailTemplates.saveErrorSameTypeInfo'),
      });
    },

    doUpdate: (template, applyToAllHotels) => {
      updateState({ isLoading: true });
      template.scheduleTypeId = state.selectedScheduleType.id;
      API.updateMailTemplate({
        hotelId: SELECTED_HOTEL_ID,
        guestlink: template,
        applyToAllHotels,
      })
        .then(_ => {
          notification.success({
            message: translate('mailTemplates.saveSuccess'),
          });
          updateState({ isLoading: false });
        })
        .catch(err => {
          console.log(err);
          notification.error({
            message: translate('mailTemplates.saveError'),
          });
          updateState({ isLoading: false });
        });
    },

    createNewGuestlink: (template, applyToAllHotels) => {
      updateState({ isLoading: true });
      template.scheduleTypeId = state.selectedScheduleType.id;

      API.createGuestlink({ hotelId: SELECTED_HOTEL_ID, item: template, applyToAllHotels })
        .then(_ => {
          updateState({ isLoading: false });
          notification.success({
            message: translate('mailTemplates.createSuccess'),
          });
          history.goBack();
        })
        .catch(_ => {
          updateState({ isLoading: false });
          notification.error({
            message: translate('mailTemplates.createError'),
          });
        });
    },

    onInsertClicked: tag => {
      updateState({ insertTag: tag });
    },

    onLanguageSelected: lang => {
      updateState({ selectedLanguage: lang });
      AppStorage.saveItem(AppStorage.KEYS.GUESTLINK_SELECTED_LANGUAGE, lang);
    },

    setApplyToAllLanguages: () => {
      updateState({ applyToAllLanguages: !state.applyToAllLanguages });
    },

    getEmailTypeOptions: () => {
      return state.scheduleTypes.map(st => {

        if (st.type === 'pre-stay') {
          const indexTemplate30Day = st.schedules.findIndex(
            template => template.value === '30' && template.timeUnit === 'day'
          );
        
          const indexTemplate2Week = indexTemplate30Day !== -1 
            ? st.schedules.findIndex(
              template => template.value === '2' && template.timeUnit === 'week'
            ) 
            : -1;
        
          if (indexTemplate30Day !== -1 && indexTemplate2Week !== -1) {
            const [item] = st.schedules.splice(indexTemplate30Day, 1);
            st.schedules.splice(indexTemplate2Week + 1, 0, item);
          }
        }
        

        return {
          value: st.type,
          label: translate(`mailTemplates.templateType.${st.type}`),
        };
      });
    },

    getSchedulingOptions: () => {
      if (!state.selectedScheduleType) return [];
      const selectedType = state.selectedScheduleType?.emailType;
      const schedulesForType = state.scheduleTypes?.find(st => st.type === selectedType);

      return schedulesForType?.schedules
        ?.filter(s => state.selectedCategories.includes(s.category))
        .reduce((result, s) => {
          if (
            getEmailScheduleCode(s) !== POLICE_CONSTANTS.POLICE_REPORT_SCHEDULE_TYPE ||
            POLICE_REPORT_ENABLED
          ) {
            result.push(s);
          }
          return result;
        }, [])
        .map(s => ({
          value: s.id,
          label: translate(`mailTemplates.templateType.${getEmailScheduleCode(s)}`),
        }));
    },

    setSelectedType: type => {
      const schedulesForType = state.scheduleTypes?.find(st => st.type === type)?.schedules || [];

      if (schedulesForType.length) {
        const filteredByCategory = schedulesForType.filter(st =>
          state.selectedCategories.includes(st.category)
        );

        if (filteredByCategory.length) {
          updateState({ selectedScheduleType: filteredByCategory[0] });
        } else {
          updateState({ selectedScheduleType: { emailType: type } });
        }
      }
    },

    setSelectedSchedule: option => {
      for (const type of state.scheduleTypes) {
        for (const schedule of type.schedules) {
          if (schedule.id === option) {
            updateState({ selectedScheduleType: schedule });
            return;
          }
        }
      }
    },

    setShowTestEmailModal: show => {
      updateState({ showSendTestEmailModal: show });
    },

    setTestEmailAddress: email => {
      updateState({ testEmailAddress: email });
    },

    onSendTestEmail: () => {
      if (!state.testEmailAddress || !actions.validateEmailList(state.testEmailAddress)) {
        notification.error({
          message: translate('mailTemplates.errorTestEmailValidation'),
        });
      } else {
        actions.doSendTestEmail(state.testEmailAddress);
      }
    },

    doSendTestEmail: async testEmailAddress => {
      updateState({ isSendingTestEmail: true });

      const attachmentsUploaded = await actions.uploadAllAttachments();
      const attachments = [];
      if (attachmentsUploaded && attachmentsUploaded.length) {
        const file = attachmentsUploaded.find(m => m.lang === actions.getSelectedLanguage());
        if (file && file.status !== 'local') {
          attachments.push(file.value);
        }
      }

      const data = {
        from: state.template?.sendFrom,
        recipients: testEmailAddress.split(',').map(email => email.trim()),
        cc: state.template?.ccList,
        bcc: state.template?.bccList,
        recipient: state.template?.recipient,
        subject:
          actions.replaceAllTagsInText(
            actions.getSelectedEmailText()?.subject)
          || state.template.texts
            .find((t) => t.lang === actions.getSelectedLanguage()).subject,
        body: actions.getSelectedEmailText()?.value,
        attachments,
        tags: ['guestlink-tests'],
        hotelSlug: getSelectedHotel()?.slug,
      };

      API.sendTestEmail({ data })
        .then(_ => {
          updateState({ isSendingTestEmail: false, showSendTestEmailModal: false });
          notification.success({
            message: translate('mailTemplates.successTestEmail'),
          });
        })
        .catch(_ => {
          updateState({ isSendingTestEmail: false });
          notification.error({
            message: translate('mailTemplates.errorTestEmail'),
          });
        });
    },

    getSelectedEmailText: () => {
      if (state.template && state.template.texts) {
        const selectedText = state.template.texts.find(
          t => t.lang === actions.getSelectedLanguage()
        );

        if (selectedText) {
          return selectedText;
        } else {
          const newText = {
            lang: actions.getSelectedLanguage(),
            subject: '',
            value: '',
          };

          const templateCp = state.template;
          templateCp.texts.push(newText);

          updateState({ template: templateCp });

          return newText;
        }
      }
    },

    setSelectedEmailText(key, text) {
      if (state.template && state.template?.texts.length) {
        const textsCopy = state.template.texts;
        const selectedText = textsCopy.find(t => t.lang === actions.getSelectedLanguage());
        selectedText[key] = text;

        if (key === 'subject') {
          const templateCopy = state.template;
          templateCopy.texts = textsCopy;
          updateState({ template: templateCopy });
        }
      }
    },

    onFileAdded: file => {
      const selectedFile = file.file;

      const isPNG = selectedFile.type === 'image/png';
      const isJPG = selectedFile.type === 'image/jpg' || selectedFile.type === 'image/jpeg';
      const isPDF = selectedFile.type === 'application/pdf';

      if (!isPNG && !isJPG && !isPDF) {
        notification.warning({
          message: `${translate('mailTemplates.invalidFormat')} (png, jpg, pdf)`,
        });
        return false;
      }

      const isCorrectSize = selectedFile.size / 1024 / 1024 < MAX_FILE_SIZE_MB;
      if (!isCorrectSize) {
        notification.warning({
          message: `${translate('mailTemplates.invalidSize')} ${MAX_FILE_SIZE_MB}MB`,
        });
        file.onError();
        return false;
      }

      const templateCopy = state.template;
      if (!templateCopy.attachments) templateCopy.attachments = [];
      const exists = templateCopy.attachments.find(f => f.lang === actions.getSelectedLanguage());
      if (!exists) {
        templateCopy.attachments.push({
          name: selectedFile.name,
          lang: actions.getSelectedLanguage(),
          file: selectedFile,
          status: 'local',
        });
      } else {
        exists.file = selectedFile;
        exists.status = 'local';
      }

      updateState({ template: templateCopy });
      file.onSuccess();

      return true;
    },

    onRemoveFile: removedFile => {
      const template = state.template;
      template.attachments = template.attachments.filter(file => {
        const fileName = file.name ? file.name : file.value.split('_').pop();

        return removedFile.name !== fileName;
      });
      updateState({ template });
    },

    uploadAllAttachments: async () => {
      if (!state.template.attachments || !state.template.attachments.length) return [];
      const attachments = state.template.attachments;
      for (const localFile of attachments) {
        if (localFile.file && localFile.status === 'local') {
          const uploadResponse = await Promise.resolve(actions.uploadFile(localFile.file));

          localFile.value = uploadResponse?.path;
          localFile.status = 'uploaded';
        }
      }

      updateState({ isLoading: false });
      return attachments;
    },

    uploadFile: async file => {
      return await API.uploadMailAttachment({
        file,
        hotelId: SELECTED_HOTEL_ID,
      });
    },

    applyTextToAllLanguages: () => {
      const subject =
        actions.getSelectedEmailText()?.subject
        || state.template.texts
          .find((t) => t.lang === actions.getSelectedLanguage()).subject;
      const value = actions.getSelectedEmailText()?.value;

      const templateCp = state.template;
      for (const language of state.availableLanguages) {
        const exists = templateCp.texts.find(text => text.lang === language.code);

        if (!exists) {
          templateCp.texts.push({
            lang: language.code,
            subject,
            value,
          });
        } else {
          exists.subject = subject;
          exists.value = value;
        }
      }

      updateState({ template: templateCp });
    },

    replaceValuesWithTags: template => {
      const tagsWithValues = state.hotelTagValues.filter(t => t.value);
      for (const tag of tagsWithValues) {
        for (const text of template.texts) {
          text.value = text?.value?.replaceAll(tag.value, `[${tag.key}]`);
        }
      }

      return template;
    },

    replaceAllTags: (template, hotelTagValues = state.hotelTagValues) => {
      const tagsWithValues = hotelTagValues.filter(t => t.value);
      for (const tag of tagsWithValues) {
        for (const text of template.texts) {
          text.value = text.value.replaceAll(`[${tag.key}]`, tag.value) || `<p></p>`;
        }
      }

      return template;
    },

    replaceAllTagsInText: (text, hotelTagValues = state.hotelTagValues) => {
      const tagsWithValues = hotelTagValues.filter(t => t.value);
      for (const tag of tagsWithValues) {
        text = text.replaceAll(`[${tag.key}]`, tag.value);
      }

      return text;
    },

    getSelectedLanguageAttachments: (
      template = state?.template,
      lang = actions.getSelectedLanguage()
    ) => {
      const ret = [];
      if (!template || !lang || !template.attachments || !template.attachments.length) return ret;

      for (const [index, attachment] of template?.attachments.entries()) {
        if (attachment.lang === lang) {
          ret.push({
            uid: index,
            name:
              attachment.status === 'local'
                ? attachment.file.name
                : attachment.value.split('_').pop(),
            status: 'done',
            url: attachment.value,
          });
        }
      }

      return ret;
    },

    onCategoryClicked: (category, checked) => {
      let cat = state.selectedCategories || [];
      if (checked) {
        cat.push(category);
      } else {
        cat = cat.filter(c => c !== category);
      }

      updateState({ selectedCategories: cat });

      if (!state.selectedScheduleType?.id) {
        actions.setSelectedType(state.selectedScheduleType?.emailType);
      }
    },

    getSelectedLanguage: () => {
      return (
        AppStorage.getItem(AppStorage.KEYS.GUESTLINK_SELECTED_LANGUAGE) || state?.selectedLanguage
      );
    },

    shouldShowRecipient: () => {
      const isPoliceReportTemplate =
        POLICE_CONSTANTS.POLICE_REPORT_SCHEDULE_TYPE ===
          getEmailScheduleCode(state.selectedScheduleType) && POLICE_REPORT_ENABLED;

      return (
        isPoliceReportTemplate ||
        SHOW_RECIPIENT_TEMPLATES.includes(getEmailScheduleCode(state.selectedScheduleType))
      );
    },

    loadDefaultTemplate: () => {
      const texts = state?.template?.texts;
      const lang = state?.selectedLanguage;
      if (!texts) return '';

      return texts.find(t => t.lang === lang)?.value || '';
    },

    loadDefaultSubject: () => {
      const texts = state?.template?.texts;
      const lang = state?.selectedLanguage;
      if (!texts) return '';

      return texts.find(t => t.lang === lang)?.subject || '';
    }
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
