import axios from 'axios';
import apiConfig from 'utils/networking/api.config';

export default async function guestDataRequest({
  hotelId = null,
  authorization = null,
  bookingIdentifier = null,
}) {
  const {
    hostValidation,
    URIs: {
      validation: { guestData },
    },
  } = apiConfig;

  const uri = `${hostValidation}${guestData}`.replace(':hotelId', hotelId);

  return axios
    .get(uri, {
      params: { bookingIdentifier },
      headers: { Authorization: authorization },
    })
    .then(res => {
      return res.data;
    });
}
