import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import CustomButton from 'Components/common/CustomButton/CustomButton';
import Colors from 'constants/Colors';
import styles from 'Sell/Upsell/Shell/UpsellShell.styles';

const MenuUpsellButton = ({ path, text, IconComponent }) => {
  const { url } = useRouteMatch();

  return (
    <Link to={`${url}${path}`}>
      <CustomButton
        background={Colors.white}
        color={Colors.darkText}
        text={text}
        fontSize={12}
        leftComponent={<IconComponent style={styles.svgIcon} />}
      />
    </Link>
  );
};

export default MenuUpsellButton;
