import { notification } from 'antd';
import { saveAs } from 'file-saver';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App.context';
import { ApiContext } from '../networking/Api.context';

export default function usePaymentsExportHook() {
  const { appActions } = useContext(AppContext);
  const { API } = useContext(ApiContext);

  const [state, setState] = useState({
    loading: false,
  });

  useEffect(() => {
    appActions.setExportingInProgress(state.loading);
  }, [, state.loading]);

  const paymentsExportActions = {
    export: (hotel, dateFrom, dateTo) => {
      updateState({ loading: true });
      API.paymentsExportRequest({
        hotel,
        dateFrom,
        dateTo,
        lang: appActions.getSelectedLanguage().id,
      })
        .then(data => {
          notification.success({
            message: 'Exportación de pagos completada!',
          });
          saveAs(
            new Blob([data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
            'payments_export.xlsx'
          );
        })
        .catch(error => {
          console.warn(error);
        })
        .finally(() => {
          updateState({ loading: false });
        });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { paymentsExportActions };
}
