import moment from 'moment';
import 'moment/locale/es';

function getFormattedDate(input) {
  return moment(input).format('YYYY-MM-DD');
}

function getDefaultDateRange() {
  return [
    moment().subtract(0, 'months').startOf('month'),
    moment().subtract(0, 'months').endOf('month'),
  ];
}

export { getFormattedDate, getDefaultDateRange };
