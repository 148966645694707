import React, { forwardRef, useImperativeHandle } from 'react';
import GuestTable from '../../CheckIn/GuestTable/GuestTable';
import useGuestFormHook from './GuestForms.hook';

function GuestForms({ formRef, selectedLanguage, selectedHotelId, languages, isLoading, onSave }) {
  const { state, actions } = useGuestFormHook({
    selectedLanguage,
    selectedHotelId,
  });

  useImperativeHandle(formRef, () => ({ saveClicked }));

  const saveClicked = applyToAllHotels => {
    onSave(state.guestForm, applyToAllHotels);
  };

  return (
    <div>
      <GuestTable
        isLoading={state.isLoading || isLoading}
        data={state.guestForm}
        onFieldStateChecked={actions.onFieldStateChecked}
        onFieldRequiredChecked={actions.onFieldRequiredChecked}
        onFieldTextChanged={actions.onFieldTextChanged}
        selectedLanguage={selectedLanguage}
        hotelId={selectedHotelId}
        supportedLanguages={languages}
      />
    </div>
  );
}

export default forwardRef(GuestForms);
