import Colors from 'constants/Colors';
import React, { useContext } from 'react';
import { AppContext } from '../../../../App.context';
import CustomButton from '../../../common/CustomButton/CustomButton';

export default function PMSButton({ row, actions }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const pmsSelected = actions.getSelectedPms(row.id) || row.pmsIdSuscribed;
  const showActionButton = row.pmsCheckin == 'NoIntegration';

  return pmsSelected && showActionButton ? (
    <div>
      <div>
        {row.pmsCheckin == 'NoIntegration' ? (
          <CustomButton
            background={Colors.pmsConnect}
            color={'white'}
            text={translate('setup')}
            onClick={() =>
              row.premium
                ? actions.onConfigurePMSAsPremium(row.id, pmsSelected)
                : actions.onConfigurePMS(row.id, pmsSelected)
            }
          />
        ) : (
          <CustomButton
            background={Colors.tryFreeButton}
            color={'white'}
            text={translate('salesChannels.tryFreeButton')}
            onClick={() => actions.onSuscribePMS(row.id, pmsSelected)}
          />
        )}
      </div>
      <div style={{ display: 'none' }}>
        <form method="POST" id="StripePMSPayment">
          <input id="hotelId" type="hidden" name="hotelId" />
          <input id="pmsId" type="hidden" name="pmsId" />
          <input id="type" type="hidden" name="type" />
          <button type="submit">{translate('salesChannels.tryFreeButton')}</button>
        </form>
      </div>
    </div>
  ) : null;
}
