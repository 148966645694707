import React, { useContext } from 'react';
import styles from './BillingStatusBox.module.css';
import { AppContext } from 'App.context.js';
import { Col } from 'antd';
import { BillingInfoContext } from '../BillingInfo.context';

export default function BillingStatus() {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state } = useContext(BillingInfoContext);

  const moneyImage = require('../../../../../../assets/images/billing/money.png');
  const infoOKImage = require('../../../../../../assets/images/billing/ok-info-icon.png');
  const infoKOImage = require('../../../../../../assets/images/billing/ko-info-icon.png');

  const contentPadding = {
    paddingLeft: '48px',
    paddingRight: '48px',
    paddingTop: '16px',
    paddingBottom: '16px',
  };

  return (
    <Col xxl={12} xl={12} lg={24} xs={24} style={{marginTop:32}}>
      <div className={styles.container}>
        <div className={styles.content} style={contentPadding}>
          <div className={styles.box}>
            <p><img src={ state.isInfoComplete ? infoOKImage : infoKOImage } alt="info-icon" /></p>
            <p><b>{ state.isInfoComplete ? translate('billingConfig.billingStatus.title.OK') : translate('billingConfig.billingStatus.title.KO') }{}</b></p>
            <p>{ state.isInfoComplete ? translate('billingConfig.billingStatus.text.OK') : translate('billingConfig.billingStatus.text.KO') }{}</p>
          </div>
          <div>
            <img src={moneyImage} alt="money" />
          </div>
        </div>
      </div>
    </Col>
  );
}
