import axios from 'axios';
import apiConfig from '../../api.config';

export default function paymentMethodConfiguredRequest({ email, lang }) {
  const {
    host,
    URIs: { auth },
  } = apiConfig;

  const uri = `${host}${auth.paymentMethodConfigured}`;

  return axios
    .post(
      uri,
      { email, lang },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(res => {
      return res.data;
    });
}
