import { DeleteFilled, DeleteOutlined, EditFilled, EditOutlined, EyeOutlined } from '@ant-design/icons';
import InfoPill from 'Components/common/InfoPill/InfoPill';
import InfoView from 'Components/common/InfoView/InfoView';
import React, { useContext } from 'react';
import constants from 'utils/constants';
import getPMSLogo from 'utils/getPMSLogo';
import { AppContext } from '../../../../App.context';
import Colors from '../../../../constants/Colors';
import AppModal from '../../../common/AppModal/AppModal';
import BaseTable from '../../../common/BaseTable/BaseTable';
import { HotelsContext } from '../Hotels.context';
import useHotelListHook from './HotelList.hook';
import styles from './HotelList.module.css';
import { EnvironmentOutlined } from '@ant-design/icons';
import AppButton from 'Components/common/Forms/AppButton/AppButton';
import { Tooltip, Tag } from 'antd';
import Icon from "@ant-design/icons";
import { ReactComponent as activeDot } from "assets/images/activeDot.svg";
import { ReactComponent as inactiveDot } from "assets/images/inactiveDot.svg";
import tagStyles from './HotelList.styles.js';

export default function HotelList() {
  const {
    appState,
    appActions: { translate },
  } = useContext(AppContext);

  const { hotelsState, hotelsActions } = useContext(HotelsContext);
  const { state, actions } = useHotelListHook();

  const columns = [
    {
      title: translate('hotels.nameCol'),
      dataIndex: 'name',
      key: 'name',
      searchable: true,
      render: (_, row) => {
        return <div style={{ fontWeight: 500 }}>{row.name}</div>;
      },
    },
    {
      title: translate('status'),
      dataIndex: 'key',
      key: 'key',
      align: 'center',
      render: (_, hotel) => {
        const integrated = actions.isPMSIntegrated(hotel.pmsApiConfig?.pms);
        console.log(integrated);
        return (
          <div>
            <Tag style={integrated?tagStyles.tagActive:tagStyles.tagInactive} icon={<Icon component={integrated?activeDot:inactiveDot} />}>{actions.getTextStateFromPMS(hotel.pmsApiConfig?.pms)}</Tag>
          </div>
        );
      },
    },
    {
      title: translate('view'),
      key: 'key',
      dataIndex: 'key',
      align: 'center',
      render: (_, hotel) => {
        if (hotel.pmsApiConfig?.pms !== constants.PMS.NO_INTEGRATION) {
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Tooltip
                mouseEnterDelay={0.2}
                title={<span>{translate('hotels.previewCheckin')}</span>}
              >
                <div>
                  <AppButton
                    text={<EyeOutlined />}
                    action={() => {
                      actions.previewCheckin(hotel.slug);
                    }}
                  />
                </div>
              </Tooltip>
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: translate('hotels.editCol'),
      key: 'key',
      dataIndex: 'key',
      align: 'center',
      render: (_, hotel) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button hotel={hotel} action={hotelsActions.onSelectHotel} />
        </div>
      ),
    },
    {
      title: translate('hotels.deleteCol'),
      key: 'key',
      dataIndex: 'key',
      align: 'center',
      render: (_, hotel) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button hotel={hotel} action={hotelsActions.onDeleteHotel} isDelete />
        </div>
      ),
    },
  ];

  const superUserColumns = [
    {
      title: translate('id'),
      dataIndex: 'id',
      key: 'id',
      searchable: true,
      render: (_, row) => {
        return <div style={{ fontWeight: 500 }}>{row.id}</div>;
      },
    },
  ];

  return (
    <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
      {!appState?.hotelList || (!appState?.hotelList.length && !state.isLoading) ? (
        <InfoView
          icon={<EnvironmentOutlined />}
          title={translate('hotels.emptyTitle')}
          text={translate('hotels.emptyDescription')}
          img={require('../../../../assets/images/empty_hotel.png')}
        />
      ) : (
        <BaseTable
          columns={!appState.superUser ? columns : superUserColumns.concat(columns)}
          data={state.dataSource}
          loading={state.isLoading}
          onChange={filters => actions.onChange(filters)}
        />
      )}

      {hotelsState.showDeleteWarning && (
        <div className={styles.alertContainer}>
          <AppModal
            show={hotelsState.showDeleteWarning}
            title={translate('hotels.deleteWarningTitle')}
            description={translate('hotels.deleteWarning')}
            okText={translate('ok')}
            okAction={hotelsActions.deleteSelectedHotel}
            cancelAction={hotelsActions.cancelDelete}
          />
        </div>
      )}
    </div>
  );

  function Button({ action, hotel, isDelete }) {
    return (
      <div
        onClick={() => action && action(hotel)}
        className={styles.button}
        style={
          hotel?.id == hotelsState.hotelId && hotelsState.showDeleteWarning && isDelete
            ? { backgroundColor: Colors.deleteButton, borderRadius: '6px', color: Colors.redIcon }
            : hotel?.id == hotelsState.hotelId && !hotelsState.showDeleteWarning && !isDelete
            ? { backgroundColor: Colors.blueIconBg, borderRadius: '6px', color: Colors.blueIcon}
            : null
        }
      >
        {isDelete ? <DeleteOutlined/> : <EditFilled />}
      </div>
    );
  }
}
