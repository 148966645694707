import axios from 'axios';
import apiConfig from 'utils/networking/api.config';

export default async function confirnPDFGenerationRequest({
  hotelId = null,
  action = null,
  authorization = null,
  bookingIdentifier = null,
}) {
  const {
    hostValidation,
    URIs: {
      validation: { PDFGeneration },
    },
  } = apiConfig;

  const uri = `${hostValidation}${PDFGeneration}`
    .replace(':hotelId', hotelId)
    .replace(':action', action);

  return axios
    .get(uri, { params: { bookingIdentifier }, headers: { Authorization: authorization } })
    .then(res => {
      return res.data;
    });
}
