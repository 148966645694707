import React from 'react';
import styles from './InfoView.styles';

export default function InfoView({ text, img, icon, title, children, style }) {
  return (
    <div style={{ ...styles.container, ...style }}>
      <div style={styles.icon}>{icon}</div>
      <div>
        {title && <div style={styles.title}>{title}</div>}
        <div style={styles.text}>{text}</div>
        {children}
      </div>
      {img ? <img style={styles.image} src={img} /> : null}
    </div>
  );
}
