export default {
  opera: {
    checkin_module_url: 'URL/IP API',
    adults_code: 'Adults code',
    children_code: 'Children code',
    origin_entity_id: 'Origin entity id',
    origin_system_type: 'Origin system type',
    destination_entity_id: 'Destination entity id',
    destination_system_type: 'Destination system type',
    user_name: 'Name',
    user_password: 'Password',
    domain: 'Domain',
    hotel_code: 'Hotel code',
    chain_code: 'Chain code',
    cloud: 'Cloud',
    cloud_user_token: 'Cloud user token',
    cloud_user_name: 'Cloud user name',
    cloud_user_password: 'Cloud user password',
    register_payment_ws: 'WS Register payment',
    station_id: 'Station id',
    passport_code: 'Passport code',
    dni_code: 'DNI Code',
    idcard_code: 'Id card code',
    credit_card_code: 'Credit card code',
    currency_code: 'Currency code',
    nationality_code: 'Nationality code',
    country_code: 'Country code',
    i_checkin_module_url: 'URL/IP API',
    i_adults_code: 'Adults code',
    i_children_code: 'Children code',
    i_cloud: 'Cloud integration',
    i_cloud_user_token: 'Cloud user token',
    i_cloud_user_name: 'Interface ID',
    i_cloud_user_password: 'Interface Key',
    i_register_payment_ws: 'Defines which WS is used to record the payment',
    i_station_id: 'Defines which "station" is used to make the payment',
    i_passport_code: 'Code for passport',
    i_dni_code: 'Code for DNI',
    i_idcard_code: 'Code for identity card',
    i_credit_card_code: 'Credit card code',
    i_currency_code: 'Code for the currency',
    i_nationality_code: 'Format for nationality',
    i_country_code: 'Format for country (of residence)',
  },
  apaleo: {
    custom_pms_id: 'Property identifier in the PMS',
    checkin_module_http_user: 'HTTP user',
    checkin_module_http_password: 'HTTP password',
    oauth_access_token: 'Oauth access token',
    oauth_refresh_token: 'Oauth refresh token',
    hard_checkin: 'Hard checkin',
    i_custom_pms_id: 'Property identifier in the PMS',
    i_hard_checkin:
      'If 1 => Room will be assigned and hard check-in will be done. If 0 => Soft check-in will be done (write a comment indicating that a pre check-in has been done)',
  },
  avalon: {
    checkin_module_url: 'URL/IP API',
    custom_pms_id: 'Property identifier in the PMS',
    document_type_code: 'Document type',
    i_checkin_module_url: 'URL/IP API',
    i_custom_pms_id: 'Property identifier in the PMS',
    i_document_type:
      'We use a string of "key=value" pairs separated by ",". Where key is one of the following values: idCard, passport, dni, residencePermit, drivingLicense, other, and value is the value corresponding to each key in that property. For example: passport=PAS,idCard=CARD,dni=DNI,residencePermit=NIE. It is possible that a value corresponding to a key does not exist in the property, in this case the pair is not completed',
  },
  avalon2: {
    checkin_module_url: 'URL/IP API',
    custom_pms_id: 'Property identifier in the PMS',
    i_checkin_module_url: 'URL/IP API',
    i_custom_pms_id: 'Property identifier in the PMS',
  },
  catalonia: {
    checkin_booking_url: 'Checkin booking url',
    checkin_data_url: 'Checkin data url',
    checkin_confirm_url: 'Checkin confirm url',
    checkin_payment_balance_url: 'Checkin payment balance url',
    checkin_payment_register_url: 'Checkin payment register url',
    checkin_find_booking_url: 'Checkin find booking url',
    checkin_contract_url: 'Checkin contract url',
    checkin_module_format: 'API module format',
    checkin_module_http_user: 'API User',
    checkin_module_http_password: 'Password API',
    custom_pms_id: 'Identificador PMS',
    i_checkin_booking_url: 'URL/IP API',
    i_checkin_data_url: 'URL/IP API',
    i_checkin_confirm_url: 'URL/IP API',
    i_checkin_payment_balance_url: 'URL/IP API',
    i_checkin_payment_register_url: 'URL/IP API',
    i_checkin_find_booking_url: 'URL/IP API',
    i_checkin_contract_url: 'URL/IP API',
    i_checkin_module_format: 'API Format',
    i_checkin_module_http_user: 'API User',
    i_checkin_module_http_password: 'Password API',
    i_custom_pms_id: 'Property identifier in the PMS',
  },
  guestportal: {
    checkin_module_url: 'URL/IP API',
    checkin_module_http_user: 'Username',
    checkin_module_http_password: 'Password',
    nationality_code: 'NationalityCode',
    custom_pms_id: 'Provider Hotel Code',
    i_checkin_module_url: 'URL/IP API (Deben finalizar con la /)',
    i_checkin_module_http_user: 'API User',
    i_checkin_module_http_password: 'Password API',
    i_nationality_code: 'Formato de la nacionalidad, ISO3166-1_Alpha-2 / ISO3166-1_Alpha-3',
    i_custom_pms_id: 'Property identifier in the PMS',
  },
  mews: {
    custom_pms_id: 'AccessToken',
    i_custom_pms_id: 'Property identifier in the PMS',
  },
  navision: {
    checkin_module_url: 'URL/IP API',
    checkin_module_http_user: 'Username',
    checkin_module_http_password: 'Password',
    i_checkin_module_url: 'URL/IP API',
    i_checkin_module_http_user: 'API User',
    i_checkin_module_http_password: 'Contraseña API',
  },
  noray: {
    checkin_booking_url: 'Booking url',
    checkin_data_url: 'Data url',
    checkin_confirm_url: 'Confirm url',
    checkin_payment_balance_url: 'Payment balance url',
    checkin_payment_register_url: 'Payment register url',
    checkin_find_booking_url: 'Find booking url',
    checkin_module_http_user: 'Http user',
    checkin_module_http_password: 'Http password',
    pms_key: 'Pms key',
    pms_url: 'Pms url',
    i_checkin_booking_url: 'URL/IP API',
    i_checkin_data_url: 'URL/IP API',
    i_checkin_confirm_url: 'URL/IP API',
    i_checkin_payment_balance_url: 'URL/IP API',
    i_checkin_payment_register_url: 'URL/IP API',
    i_checkin_find_booking_url: 'URL/IP API',
    i_checkin_module_http_user: 'API User',
    i_checkin_module_http_password: 'Password API',
    i_pms_key: 'PMS Key',
    i_pms_url:
      'Url with all params (important that starts with "https://api.businesscentral.dynamics.com"): https://api.businesscentral.dynamics.com/v2.0/{{tenant}}/{{environment}}/ODataV4/{{webService}}_{{method}}?company={{hotelName}}',
  },
  ohip: {
    pms_key: 'Username and Password',
    custom_pms_id: 'Custom pms id',
    checkin_module_url: 'HostName/Gateway URL',
    checkin_module_http_user: 'Client ID',
    checkin_module_http_password: 'Client Secret',
    nationality_code: 'Country code format',
    document_type_code: 'Custom document type codes',
    language_code: 'Custom language codes',
    username: 'Username',
    password: 'Password',
    cashier_id: 'Cashier id',
    credit_card_code: 'Credit card',
    guarantee_code: 'Guarantee code',
    i_pms_key:
      'Concatenation of Username(Interface Id) and Password(Interface Key) separated by "/civitfun/". It would look like this: Username/civitfun/Password    i_custom_pms_id: "Property identifier in the PMS"',
    i_checkin_module_url: 'URL API',
    i_checkin_module_http_user: 'Client ID generated when creating the chain environment',
    i_checkin_module_http_password: 'Client Secret generated when creating the chain environment',
    i_nationality_code:
      'Nationality / IssuedCountry / BirthCountry codes, ISO3166-1_Alpha-2 / ISO3166-1_Alpha-3',
    i_document_type_code: 'Null/Empty -> Standard values',
    i_language_code: 'Null/Empty -> Standard values',
    i_credit_card_code: 'Only required if the payment module is active',
    i_guarantee_code: 'Only required if the payment module is active',
  },
  porthotels: {
    pms_url: 'URL/IP API',
    pms_key: 'PMS Key',
    pms_format: 'API Format',
    custom_pms_id: 'Property identifier in the PMS',
    i_pms_url: 'URL/IP API',
    i_pms_key: 'PMS Key',
    i_pms_format: 'API Format',
    i_custom_pms_id: 'Property identifier in the PMS',
  },
  poseidon: {
    checkin_module_url: 'Module url',
    i_checkin_module_url: 'URL/IP API',
  },
  protel: {
    checkin_module_url: 'URL/IP API',
    payment_code: 'PMSRevenueCode',
    document_type_code: 'Document type',
    custom_pms_id: 'RequestorID / @ID',
    pms_key: 'Auth Token',
    passport: 'Passport',
    idCard: 'Id card',
    drivingLicense: 'Driving license',
    residencePermit: 'Residence permit',
    dni: 'DNI',
    visa: 'Visa',
    mastercard: 'Mastercard',
    diners: 'Diners',
    amex: 'Amex',
    jcb: 'Jcb',
    upi: 'Upi',
    cartesbancaires: 'Cartes Bancaires',
    discover: 'Discover',
    unionpay: 'Unionpay',
    default: 'Default',
    i_checkin_module_url: 'URL/IP API',
    i_payment_code:
      'Payment method, supported values: visa, mastercard, diners, amex, jcb, upi, cartesbancaires, discover, unionpay, default*. It will have the following format (the numbers are the codes to be provided by the customer): visa=2,mastercard=3,default=4',
    i_document_type_code:
      ' If you use the default API values this field must be NULL. Otherwise we must include the relationship between our document type identifier and the PMS identifier. For example: "passport=422,idCard=432,drivingLicense=433,residencePermit=434,dni=435"',
    i_custom_pms_id: 'Property identifier in the PMS',
    i_pms_key: 'Access token',
    i_passport: 'Passport type code',
  },
  roomcloud: {
    checkin_module_http_user: 'User',
    checkin_module_http_password: 'Password',
    custom_pms_id: 'Pms id',
    i_checkin_module_http_user: 'API User',
    i_checkin_module_http_password: 'Password API',
    i_custom_pms_id: 'Property identifier in the PMS',
  },
  sihot: {
    checkin_module_url: 'Module url',
    checkin_module_http_user: 'User',
    checkin_module_http_password: 'Password',
    adults_code: 'Adults code',
    children_code: 'Children code',
    pms_key: 'Pms key',
    checkout_module: 'Check-out module of the hotel',
    checkout_module_url: 'Checkout module url',
    i_checkin_module_url: 'URL/IP API',
    i_adults_code: 'PMS codes that define a host as an adult.',
    i_children_code: 'PMS codes defining a host as a child',
    i_pms_key: 'Api key',
    i_checkout_module: 'Sihot (matches checkin_module)',
    i_checkout_module_url: 'URL/IP API',
  },
  standard3: {
    checkin_module_url: 'Checkin module url',
    checkin_booking_url: 'Booking url',
    checkin_data_url: 'Data url',
    checkin_confirm_url: 'Confirm url',
    checkin_payment_balance_url: 'Payment balance url',
    checkin_payment_register_url: 'Payment register url',
    checkin_find_booking_url: 'Find booking url',
    checkin_bookings_of_day_url: 'Bookings of day url',
    checkin_contract_url: 'Contract url',
    checkin_module_format: 'Checkout module format',
    checkout_module_url: 'Module url',
    checkout_booking_url: 'Booking url',
    checkout_find_booking_url: 'Find booking url',
    checkout_payment_balance_url: 'Payment balance url',
    checkout_payment_register_url: 'Payment register url',
    checkout_confirm_url: 'Confirm url',
    checkout_module_format: 'Module format',
    checkin_module_http_password: 'Http password',
    pms_key: 'Pms key',
    custom_pms_id: 'Pms id',
    i_checkin_module_url: 'URL/IP API (One endpoint for all services)',
    i_checkin_booking_url: 'URL/IP API (Specific endpoint for each service)',
    i_checkin_data_url: 'URL/IP API',
    i_checkin_confirm_url: 'URL/IP API',
    i_checkin_payment_balance_url: 'URL/IP API',
    i_checkin_payment_register_url: 'URL/IP API',
    i_checkin_find_booking_url: 'URL/IP API',
    i_checkin_bookings_of_day_url: 'URL/IP API',
    i_checkin_contract_url: 'URL/IP API',
    i_checkin_module_format: 'Message format, soap/json',
    i_checkout_module_url: 'URL/IP API (One endpoint for all services)',
    i_checkout_booking_url: 'URL/IP API',
    i_checkout_find_booking_url: 'URL/IP API',
    i_checkout_payment_balance_url: 'URL/IP API',
    i_checkout_payment_register_url: 'URL/IP API',
    i_checkout_confirm_url: 'URL/IP API',
    i_checkout_module_format: 'Message format, soap/json',
    i_checkin_module_http_password:
      'API Secret Key, 32 length. Include: symbols, numbers, lowercase and uppercase. Exclude: similar, ambiguous and Generate On Your Device. https://passwordsgenerator.net/',
    i_pms_key: 'API Public Key, Version 4 UUID, https://www.uuidgenerator.net/version4',
    i_custom_pms_id: 'Property Identifier, Version 4 UUID',
  },
  timon: {
    checkin_module_url: 'Checkin module url',
    checkin_module_http_user: 'User',
    checkin_module_http_password: 'Password',
    custom_pms_id: 'Establishment',
    payment_code: 'Payment codes',
    i_payment_code:
      'It should follow the next pattern: diario_anticipo=X,tesoreria_modo_anticipo=Y',
    i_checkin_module_url: 'URL/IP API',
    i_custom_pms_id:
      'Property identifier in the PMS. If a hotel in Civitfun groups more than one TimonHotel establishment, do not fill this field with any data',
  },
  totvs: {
    checkin_module_http_password: 'Password',
    i_checkin_module_http_password: 'Token client',
  },
  winhotel: {
    checkin_module_url: 'Checkin module url',
    adults_code: 'Adults code',
    children_code: 'Children code',
    custom_pms_id: 'Property identifier in the PMS.',
    hotelSourceCode: 'Hotel Source Code',
    hotelTargetCode: 'Hotel Target Code',
    i_checkin_module_url: 'URL/IP API',
    i_custom_pms_id: 'HotelSourceCode-TRINCHERA-HotelTargetCode',
  },
  bookipro: {
    checkin_module_http_user: 'API Url',
    checkin_module_http_password: 'Token',
    i_checkin_module_http_user: 'API Url',
    i_checkin_module_http_password: 'API Secret Key',
  },
  hoteliga: {
    checkin_module_http_user: 'API User',
    checkin_module_http_password: 'API Password',
    token: 'API Auth token',
    custom_pms_id: 'API Domain',
    i_checkin_module_http_user: 'API User',
    i_checkin_module_http_password: 'API Password',
    i_token: 'API Auth token',
    i_custom_pms_id: 'API Domain',
  },
  guestline: {
    checkin_module_http_user: 'Operator Code',
    checkin_module_http_password: 'Password',
    custom_pms_id: 'Site ID',
    pms_key: 'Interface ID',
  },
  stelle: {
    checkin_module_http_user: 'Hotel code',
    checkin_module_http_password: 'Client token',
  },
  tecsoft: {
    checkin_module_url: 'URL/IP API',
    checkin_module_http_user: 'API User',
    checkin_module_http_password: 'API Password',
    custom_pms_id: 'Property identifier in the PMS',
    pms_key: 'PMS Key',
    pms_format: 'PMS Format',
    i_checkin_module_http_user: 'CIVITFUN',
    i_checkin_module_http_password: 'CIVITFUN',
    i_custom_pms_id: 'hotel.id (Civitfun hotel identifier)',
  },
  ulysescloud: {
    checkin_module_url: 'URL/IP API',
    checkin_module_http_user: 'API User',
    checkin_module_http_password: 'API Password',
    custom_pms_id: 'Identifier in the PMS',
  },
  rms: {
    checkin_module_url: 'URL/IP API',
    checkin_module_http_user: 'API User',
    checkin_module_http_password: 'API Password',
  },
  tms: {
    checkin_module_url: 'URL/IP API',
    checkin_module_http_user: 'API User',
    checkin_module_http_password: 'API Password',
    custom_pms_id: 'ID hotel in the PMS',
  },
  minihotel: {
    checkin_module_http_user: 'API User',
    checkin_module_http_password: 'API Password',
    custom_pms_id: 'ID hotel in the PMS',
  },
  guestpro: {
    checkin_module_http_user: 'API User',
    checkin_module_http_password: 'API Password',
    custom_pms_id: 'ID hotel in the PMS',
  },
  octorate: {
    checkin_module_http_user: 'API User (public)',
    checkin_module_http_password: 'API Password (secret)',
    custom_pms_id: 'Hotel ID in PMS',
  },
  elektraweb: {
    token: 'Token',
    custom_pms_id: 'Hotel ID in PMS',
  },
  standard2: {
    checkin_module_url: 'Checkin url',
    checkin_booking_url: 'Booking url ',
    checkin_data_url: 'Data url',
    checkin_confirm_url: 'Confirm url',
    checkin_payment_balance_url: 'Payment balance url',
    checkin_payment_register_url: 'Payment register url',
    checkin_find_booking_url: 'Find booking url',
    checkin_bookings_of_day_url: 'Bookings of day url',
    checkin_contract_url: 'Contract url',
    checkin_module_format: 'Module format',
    checkout_module_url: 'Checkout Module url',
    checkout_booking_url: 'Checkout Booking url',
    checkout_find_booking_url: 'Checkout Find booking url',
    checkout_payment_balance_url: 'Checkout Payment balance url',
    checkout_payment_register_url: 'Checkout Payment register url',
    checkout_confirm_url: 'Checkout Confirm',
    checkout_module_format: 'Checkout Module format',
    pms_key: 'PMS Key',
  },
  host: {
    checkin_booking_url: 'API Url',
    checkin_module_http_user: 'Subscription key',
    payment_code: 'Payment code',
    document_type_code: 'Document type code',
  },
  verial: {
    checkin_module_url: 'URL/IP',
    checkin_module_http_user: 'API User',
  },
  ericsoft: {
    checkin_module_http_user: 'API User',
    checkin_module_http_password: 'API Password',
    custom_pms_id: 'Hotel ID in PMS',
  },
  avirato: {
    checkin_module_http_user: 'API User',
    checkin_module_http_password: 'API Password',
    custom_pms_id: 'Hotel ID in PMS',
  },
  noray_new: {
    checkin_booking_url: 'Booking url',
    checkin_data_url: 'Data url',
    checkin_confirm_url: 'Confirm url',
    checkin_payment_balance_url: 'Payment balance url',
    checkin_payment_register_url: 'Payment register url',
    checkin_find_booking_url: 'Find booking url',
    checkin_module_http_user: 'Http user',
    checkin_module_http_password: 'Http password',
    pms_key: 'Pms key',
    pms_url: 'Pms url',
    i_checkin_booking_url: 'URL/IP API',
    i_checkin_data_url: 'URL/IP API',
    i_checkin_confirm_url: 'URL/IP API',
    i_checkin_payment_balance_url: 'URL/IP API',
    i_checkin_payment_register_url: 'URL/IP API',
    i_checkin_find_booking_url: 'URL/IP API',
    i_checkin_module_http_user: 'API User',
    i_checkin_module_http_password: 'Password API',
    i_pms_key: 'PMS Key',
    i_pms_url:
      'Url with all params (important that starts with "https://api.businesscentral.dynamics.com"): https://api.businesscentral.dynamics.com/v2.0/{{tenant}}/{{environment}}/ODataV4/{{webService}}_{{method}}?company={{hotelName}}',
  },
  hestia: {
    checkin_module_http_user: 'User',
    checkin_module_http_password: 'Password',
  },
  winhotel_new: {
    checkin_module_url: 'Checkin module url',
    adults_code: 'Adults code',
    children_code: 'Children code',
    custom_pms_id: 'Property identifier in the PMS.',
    hotelSourceCode: 'Hotel Source Code',
    hotelTargetCode: 'Hotel Target Code',
    i_checkin_module_url: 'URL/IP API',
    i_custom_pms_id: 'HotelSourceCode-TRINCHERA-HotelTargetCode',
  },
  ohipdynamic: {
    pms_key: 'Username and Password',
    custom_pms_id: 'Custom pms id',
    checkin_module_url: 'HostName/Gateway URL',
    checkin_module_http_user: 'Client ID',
    checkin_module_http_password: 'Client Secret',
    nationality_code: 'Country code format',
    document_type_code: 'Custom document type codes',
    language_code: 'Custom language codes',
    username: 'Username',
    password: 'Password',
    i_pms_key:
      'Concatenation of Username(Interface Id) and Password(Interface Key) separated by "/civitfun/". It would look like this: Username/civitfun/Password    i_custom_pms_id: "Property identifier in the PMS"',
    i_checkin_module_url: 'URL API',
    i_checkin_module_http_user: 'Client ID generated when creating the chain environment',
    i_checkin_module_http_password: 'Client Secret generated when creating the chain environment',
    i_nationality_code:
      'Nationality / IssuedCountry / BirthCountry codes, ISO3166-1_Alpha-2 / ISO3166-1_Alpha-3',
    i_document_type_code: 'Null/Empty -> Standard values',
    i_language_code: 'Null/Empty -> Standard values',
  },
  ohip_new: {
    pms_key: 'Username and Password',
    custom_pms_id: 'Custom pms id',
    checkin_module_url: 'HostName/Gateway URL',
    checkin_module_http_user: 'Client ID',
    checkin_module_http_password: 'Client Secret',
    nationality_code: 'Country code format',
    document_type_code: 'Custom document type codes',
    language_code: 'Custom language codes',
    username: 'Username',
    password: 'Password',
    cashier_id: 'Cashier id',
    credit_card_code: 'Credit card',
    guarantee_code: 'Guarantee code',
    i_pms_key:
      'Concatenation of Username(Interface Id) and Password(Interface Key) separated by "/civitfun/". It would look like this: Username/civitfun/Password    i_custom_pms_id: "Property identifier in the PMS"',
    i_checkin_module_url: 'URL API',
    i_checkin_module_http_user: 'Client ID generated when creating the chain environment',
    i_checkin_module_http_password: 'Client Secret generated when creating the chain environment',
    i_nationality_code:
      'Nationality / IssuedCountry / BirthCountry codes, ISO3166-1_Alpha-2 / ISO3166-1_Alpha-3',
    i_document_type_code: 'Null/Empty -> Standard values',
    i_language_code: 'Null/Empty -> Standard values',
    i_credit_card_code: 'Only required if the payment module is active',
    i_guarantee_code: 'Only required if the payment module is active',
  },
  sihot360: {
    checkin_module_url: 'API Url',
    checkin_module_http_user: 'User',
    checkin_module_http_password: 'Password',
    payment_code: 'Payment code',
    custom_pms_id: 'Custom PMS ID',
  },
  ohtels: {
    checkin_module_url: 'API Url',
    checkin_module_http_user: 'User',
    checkin_module_http_password: 'Password',
    custom_pms_id: 'Custom PMS ID',
  },
  othello: {
    chain_custom_pms_id: 'Chain PMS identifier',
    custom_pms_id: 'Hotel custom PMS identifier',
    pms_key: 'Authorization key',
  },
  passepartout: {
    checkin_module_url: 'URL',
    custom_pms_id: 'Azienda',
    pms_key: 'Api Key',
  },
};
