import Colors from 'constants/Colors';

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    color: Colors.darkText,
    fontSize: 12,
    fontWeight: 'bold',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    justifyContent: 'left',
    alignItems: 'center',
  },
  selectorContainer: {
    borderRadius: 8,
  },
  selector: {
    width: 300,
    backgroundColor: 'white',
    borderRadius: 8,
  },
};
