import axios from 'axios';
import apiConfig from '../api.config';

export default function crossSellSendEmailToHotelRequest({
  hotelSlug,
  pmsId,
  crossSellState,
  customTags,
  schedule,
  options
}) {
  const {
    guestlinkHost,
    URIs: { crossSellSendEmail },
  } = apiConfig;

  // workaround: Guestlink does not support htts rigth now on PROD
  const uri = `https://cross-sell-api.civitfun.com/${crossSellSendEmail}`;

  return axios
    .post(uri, JSON.stringify({ hotelSlug, pmsId, schedule, crossSellState, customTags }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
