import axios from 'axios';
import apiConfig from '../api.config';

export default function updateConfig({ hotelId, values }) {
  const {
    host,
    URIs: { updateCheckinConfig },
  } = apiConfig;

  const uri = `${host}${updateCheckinConfig}`;

  const data = JSON.parse(JSON.stringify(values));
  if (hotelId) {
    data.hotelId = hotelId;
  }

  return axios
    .put(uri, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
