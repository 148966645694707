import axios from 'axios';
import apiConfig from 'utils/networking/api.config';

export default function impersonateLoginRequest(token, userId, chainId) {
  const {
    host,
    URIs: { auth },
  } = apiConfig;

  const uri = `${host}${auth.superUser.impersonateLogin}`.replace(':userId', userId);

  return axios
    .post(uri, JSON.stringify({ chainId }), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res => {
      return res.data;
    });
}
