import axios from 'axios';
import apiConfig from 'utils/networking/api.config';

/**
 * @param {*} action
 * undefined == test all { bookingIdentifier }
 * getCharges { bookingIdentifier }
 * sendPayment {bookingIdentifier, amount}
 * paymentData { bookingIdentifier }
 * @returns
*/
export default async function paymentRequest({
  hotelId = null,
  action = null,
  authorization = null,
  bookingIdentifier = null,
  amount = null,
}) {
  const {
    validationsHost,
    URIs: {
      validations: { payments },
    },
  } = apiConfig;

  const uri = `${validationsHost}${payments}`
    .replace(':hotelId', hotelId)
    .replace(':action', action || '');

  return axios
    .get(uri, {
      params: { bookingIdentifier, amount },
      headers: { Authorization: authorization },
    })
    .then(res => {
      return res.data;
    });
}
