export default `<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:21px;font-family:&quot;Calibri&quot;,sans-serif;">利用規約&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:18px;font-family:&quot;Calibri&quot;,sans-serif;">法的情報&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;">
    <span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">
        安全のために、クレジットカードのデータは国際的なPCI-DSS基準に従って取り扱われることを保証します。上記のクレジットカードに、ホテル滞在の総費用を請求することをホテルに許可します。この文書に示された予約終了日より前にホテルを退去する場合、契約されたすべての夜の総額（使用しなかったものを含む）がクレジットカードに請求されることを許可します。<br><br>
        [hotel_name]では、あなたのプライバシーを保護することにコミットしています。[address]またはメールアドレス [hotel_email] でご連絡いただけます。[hotel_name] は、ホテルでの滞在を管理し、ゲストとしての体験を向上させるためにあなたのデータを処理します。また、宿泊や付帯サービスを提供するためにあなたのデータも処理します。法的義務（詐欺防止を含む）を遵守するために、あなたのデータを処理する必要がある場合があります。[hotel_name]は、支払い処理のために銀行や他の金融機関、または法執行機関にデータを提供することがあります。<br><br>
        登録すると、[hotel_name]は、あなたにロイヤリティサービスを提供するために、あなたの個人データを処理します。この意味で、生成されたポイントを処理し、あなたのプロフィールに基づいてポイントおよびプログラムの利益に関する情報を送信することがあります。<br><br>
        [hotel_name] に書くことで、アクセス、訂正、削除、異議、処理の制限、およびデータの移転権を行使できます。上記のメールまたは郵送先にて、身元を証明し、両方の場合において (a) "データ保護" を件名として記載し、 (b) そのリクエストが [hotel_name] に向けられているかどうかを示してください。また、いつでも国の監視当局に連絡できます。<br><br>
        詳細については、[hotel_web] にアクセスするか、ホテルのフロントデスクでご確認ください。
    </span>
</p>`;
