import { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import { AppContext } from 'App.context';
import { ApiContext } from 'utils/networking/Api.context';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import usePaymentsExportHook from '../../../utils/export/PaymentsExport.hook';
import isValidDateRange from '../../../utils/export/isValidDateRange';

export default function usePaymentListHook() {
  const { API } = useContext(ApiContext);
  const history = useHistory();
  const {
    appState,
    appActions: { getSelectedHotel },
  } = useContext(AppContext);
  const { paymentsExportActions } = usePaymentsExportHook();

  const [state, setState] = useState({
    isLoading: false,
    datesRange: getDefaultDateRange(),
    dataSource: {
      items: [],
      totalItems: 0,
      totalPages: 0,
      currentPage: 0,
      pageSize: 10,
    },
    filters: {},
  });

  useEffect(() => {
    actions.loadPayments();
  }, [state.dataSource.currentPage, state.filters, state.datesRange, appState.selectedHotel]);

  const actions = {
    onDateChange: (_date, dateString) => {
      if (isValidDateRange(dateString)) {
        updateState({ datesRange: dateString });
      }
    },

    loadPayments: () => {
      updateState({ isLoading: true });
      API.purchasesList(
        state.dataSource?.currentPage,
        state.dataSource?.pageSize,
        state.filters,
        state.datesRange,
        actions.getSelectedHotel()
      )
        .then(res => {
          updateState({ dataSource: res, isLoading: false });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },

    onRowClicked: (item, _index) => {
      history.push({
        pathname: '/checkin',
        state: { bookingId: item?.id },
      });
    },

    onChange: filters => {
      var newPage,
        newPageSize = -1;
      var searchFilters;
      if (state.dataSource?.currentPage !== filters?.pagination.page) {
        newPage = filters?.pagination.page;
      }

      if (state.dataSource?.pageSize !== filters?.pagination.pageSize) {
        newPageSize = filters?.pagination.pageSize;
      }

      if (filters?.where) {
        const bookingLocator = filters?.where['code'];
        const purchaseLocator = filters?.where['purchaseLocator'];
        const purchaseToken = filters?.where['purchaseToken'];

        searchFilters = { bookingLocator, purchaseLocator, purchaseToken };
      }

      updateState({
        dataSource: {
          ...state.dataSource,
          currentPage: newPage >= 0 ? newPage : state.dataSource?.currentPage,
          pageSize: newPageSize >= 0 ? newPageSize : state.dataSource?.pageSize,
        },
        filters: searchFilters,
      });
    },

    export: () => {
      const dateFrom = state.datesRange[0];
      const dateTo = state.datesRange[1];

      if (!dateFrom || !dateTo) {
        notification.info({
          message: 'Debe seleccionar un rango de fechas para realizar la exportación',
        });
        return;
      }
      if (isValidDateRange(state.datesRange)) {
        paymentsExportActions.export(actions.getSelectedHotel(), dateFrom, dateTo);
      }
    },

    getSelectedHotel: () => {
      const hotel = getSelectedHotel();
      if ((hotel && hotel.key) || hotel.id) {
        return hotel.key.toString() || hotel.id.toString();
      }
    },
  };

  function getDefaultDateRange() {
    return [moment().subtract(1, 'day').startOf('day'), moment().subtract(0, 'day').endOf('day')];
  }

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
