export default `<p align="center"><img src="[chain_logo]" width="auto" height="100"></p>

<p style="font-family: &quot;Times New Roman&quot;, serif; margin: 0cm; text-align: center;"><span style="font-family: Calibri, sans-serif;"><span style="font-size: 21px;"><strong>TOEGANGSFORMULIER</strong></span><span style="font-size: 16px;">&nbsp;</span></span></p>
&nbsp;
<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">GEGEVENS VAN DE VERHUURDER&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 95%; border-collapse: collapse; margin-left: 2.32975%; margin-right: -2.25pt;">
<tbody>
    <tr>
        <td style="width: 148.6pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">NDeel nr./Cardex nr.:&nbsp;</span></p>
        </td>
        <td style="width: 250.55pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[police_report_number]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Naam of handelsnaam van de houder:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_business_name]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">CIF/VAT/NIF:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Gemeente:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_city]&nbsp;</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Provincie:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_province]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Vast telefoonnummer en/of mobiel:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_phone]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">E-mailadres:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_business_email]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Website van het bedrijf:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_web]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">URL voor identificatie van de advertentie:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_ad_url]</span></p><br>
        </td>
    </tr>
</tbody>
</table>

&nbsp;

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">GEGEVENS VAN DE INRICHTING&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 94.8208%; border-collapse: collapse; margin-left: 2.50896%; margin-right: -2.25pt;">
<tbody>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Type inrichting:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[establishment_type]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Naam:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_name]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Volledig adres:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[address]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Postcode:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_postal_code]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Plaats en provincie:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_city], [CIF_province]</span></p><br>
        </td>
    </tr>
</tbody>
</table>

&nbsp;

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">GEGEVENS VAN DE REIZIGER&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 94.4624%; border-collapse: collapse; margin-left: 2.86738%; margin-right: -2.25pt;">
<tbody>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Voornaam:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_first_name]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Achternaam:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_surname]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Tweede achternaam:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_second_surname]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Geslacht:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_gender]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Documentnummer:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_document_number]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Nummer van het documentondersteuning:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_document_support_number]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Type document:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_document_type]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Datum van uitgifte:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_expedition_date]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Vervaldatum:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_expiration_date]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Land van het document:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_nationality]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Nationaliteit:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_nationality]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Geboortedatum:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_birth_date]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Gewone woonplaats:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Adres:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_address]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Postcode:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_postal_code]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Stad:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_city]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Provincie:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_province]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Land:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_country]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Vast telefoonnummer:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_landline_phone]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Mobiel:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_phone]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">E-mail:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_email]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Aantal reizigers:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[num_guests]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Familierelatie:</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_family_relationship]</span></p><br>
        </td>
    </tr>
</tbody>
</table>

<div style="page-break-before:always;margin-top:5cm"></div>&nbsp;

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">TRANSACTIEGEGEVENS&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 94.1239%; border-collapse: collapse; margin-left: 2.86738%; margin-right: -2.25pt;">
<tbody>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Contractgegevens:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Referentienummer:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[reference_number]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Datum:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[entrance_date]</span></p><br>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Gegevens over de uitvoering van het contract:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Ingangsdatum en -tijd:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[entrance_date],&nbsp;[entrance_time]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Vertrekdatum en -tijd:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[departure_date],&nbsp;[departure_time]</span></p><br>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Gegevens van het onroerend goed:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Volledig adres:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[address]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Aantal kamers:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_room_count]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Internetverbinding:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_has_internet]</span></p><br>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Betaalgegevens:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Type:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_payment_type]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Identificatie van de betaalmethode:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Houder van de betaalmethode:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Vervaldatum van de kaart:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Betalingsdatum:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p><br><br>
        </td>
    </tr>
</tbody>
</table>

&nbsp;

<p>[legal_cardex]<br><br></p>

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Handtekening</span></strong></p>
<p><img src="[guest_signature]" width="auto" height="200"></p>
<p></p>`;