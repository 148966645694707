import axios from 'axios';
import apiConfig from 'utils/networking/api.config';

/**
 * @param {*} action
 * checkin { bookingCode, entranceDate, payments }
 * payments { bookingIdentifier }
 * @returns
 */
export default async function onboardingRequest({
  hotelId = null,
  action = null,
  authorization = null,
  bookingCode = null,
  entranceDate = null,
  payments = null,
}) {
  const {
    hostValidation,
    URIs: {
      validation: { onboarding },
    },
  } = apiConfig;

  const uri = `${hostValidation}${onboarding}`
    .replace(':hotelId', hotelId)
    .replace(':action', action || '');

  return axios
    .get(uri, {
      params: { bookingCode, entranceDate, payments },
      headers: { Authorization: authorization },
    })
    .then(res => {
      return res.data;
    });
}
