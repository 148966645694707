export const actionTypes = {
  SET_AMENITIES: 'SET_AMENITIES',
  SET_ROOM_FEATURES: 'SET_ROOM_FEATURES',
  SET_PMS_ID: 'SET_PMS_ID',
  LOAD_ROOM_INFO: 'LOAD_ROOM_INFO',
  SET_CONFIRMATION_TYPE: 'SET_CONFIRMATION_TYPE',
  UPDATE_ROOM_TYPE_TEXTS: 'UPDATE_ROOM_TYPE_TEXTS',
  UPDATE_ROOM_TYPE_NAME: 'UPDATE_ROOM_TYPE_NAME',
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_ROOM_NAME: 'SET_ROOM_NAME',
  SET_UPSELL_OPTIONS: 'SET_UPSELL_OPTIONS',
  SET_IMAGE_LIST: 'SET_IMAGE_LIST',
  SET_ROOM_ID: 'SET_ROOM_ID',
  ADD_UPSELL_TO_OPTIONS: 'ADD_UPSELL_TO_OPTIONS',
  SET_UPSELL_PRICE: 'SET_UPSELL_PRICE',
  SET_UPSELL_TYPE_PRICE: 'SET_UPSELL_TYPE_PRICE',
  SET_UPSELL_ID: 'SET_UPSELL_ID',
  SET_UPSELL_TYPE_ROOM: 'SET_UPSELL_TYPE_ROOM',
  DELETE_IMAGE: 'DELETE_IMAGE',
  REORDER_IMAGE: 'REORDER_IMAGE',
  SET_UPSELL_FROM_TYPE_ROOM: 'SET_UPSELL_FROM_TYPE_ROOM',
  SET_EDIT_UPSELL_TO_OPTIONS: 'SET_EDIT_UPSELL_TO_OPTIONS',
  UPDATE_UPSELL_TO_OPTIONS: 'UPDATE_UPSELL_TO_OPTIONS',
  EDIT_UPSELL_TO_OPTIONS: 'EDIT_UPSELL_TO_OPTIONS',
  DELTETE_UPSELL_TO_OPTIONS: 'DELTETE_UPSELL_TO_OPTIONS',
};

export const roomReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_CONFIRMATION_TYPE:
    case actionTypes.SET_ROOM_FEATURES:
    case actionTypes.SET_ROOM_NAME:
    case actionTypes.SET_PMS_ID:
    case actionTypes.SET_AMENITIES:
    case actionTypes.SET_ROOM_ID:
    case actionTypes.SET_IMAGE_LIST:
      return {
        ...state,
        [action.payload?.type]: action.payload?.value,
      };
    case actionTypes.ADD_UPSELL_TO_OPTIONS:
      const existingUpsellOption = state.upsellOptionsFrom.find(upsell => {
        return upsell.toPhysicalRoomTypeId === action.payload.toPhysicalRoomTypeId;
      });

      const remainingUpsellOptions = state.upsellOptionsFrom.filter(upsell => {
        return upsell.toPhysicalRoomTypeId !== action.payload.toPhysicalRoomTypeId;
      });

      return {
        ...state,
        currentUpselOptions: {},
        upsellOptionsFrom: [
          ...remainingUpsellOptions,
          {
            ...existingUpsellOption,
            ...action.payload,
            order: action.payload.order || remainingUpsellOptions.length + 1,
          },
        ],
      };
    case actionTypes.UPDATE_UPSELL_TO_OPTIONS:
      const newOrdered = action.payload.map((item, index) => {
        delete item.name;
        delete item.desc;
        delete item.image;
        return { ...item, order: index + 1 };
      });
      return { ...state, upsellOptionsFrom: newOrdered };
    case actionTypes.DELTETE_UPSELL_TO_OPTIONS:
      const newUsells = state.upsellOptionsFrom.filter(item => item.id !== action.payload);
      return { ...state, upsellOptionsFrom: newUsells };
    case actionTypes.EDIT_UPSELL_TO_OPTIONS:
      const upsells = state.upsellOptionsFrom.filter(item => item.id !== action.payload.id);
      return {
        ...state,
        currentUpselOptions: {},
        upsellOptionsFrom: [...upsells, action.payload],
      };
    case actionTypes.SET_EDIT_UPSELL_TO_OPTIONS:
      return {
        ...state,
        currentUpselOptions: action.payload,
      };
    case actionTypes.SET_UPSELL_PRICE:
    case actionTypes.SET_UPSELL_TYPE_PRICE:
    case actionTypes.SET_UPSELL_TYPE_ROOM:
    case actionTypes.SET_UPSELL_FROM_TYPE_ROOM:
    case actionTypes.SET_UPSELL_ID:
      return {
        ...state,
        currentUpselOptions: {
          ...state.currentUpselOptions,
          [action.payload?.type]: action.payload?.value,
        },
      };
    case actionTypes.UPDATE_ROOM_TYPE_NAME:
      const nameIndex = state.texts.findIndex(
        text => text.lang === state.selectedLanguage && text.type === action.payload.type
      );
      const newName = state.texts.map((text, index) => {
        if (index === nameIndex) {
          return {
            ...text,
            type: action.payload.type,
            value: action.payload.value,
          };
        }
        return text;
      });
      if (nameIndex === -1) {
        newName.push({
          lang: state.selectedLanguage,
          type: action.payload.type,
          value: action.payload.value,
        });
      }
      return {
        ...state,
        currentName: {
          ...state.currentName,
          type: action.payload.type,
          value: action.payload.value,
        },
        texts: newName,
      };

    case actionTypes.UPDATE_ROOM_TYPE_TEXTS:
      const textIndex = state.texts.findIndex(
        text => text.lang === state.selectedLanguage && text.type === action.payload.type
      );
      const newTexts = state.texts.map((text, index) => {
        if (index === textIndex) {
          return {
            ...text,
            type: action.payload.type,
            value: action.payload.value,
          };
        }
        return text;
      });
      if (textIndex === -1) {
        newTexts.push({
          lang: state.selectedLanguage,
          type: action.payload.type,
          value: action.payload.value,
        });
      }
      return {
        ...state,
        currentTexts: {
          ...state.currentTexts,
          type: action.payload.type,
          value: action.payload.value,
        },
        texts: newTexts,
      };

    case actionTypes.SET_LANGUAGE:
      const textsForNewLanguage = state.texts.find(
        text => text.lang === action.payload && text.type === 'desc'
      );
      const nameForNewLanguage = state.texts.find(
        text => text.lang === action.payload && text.type === 'name'
      );
      return {
        ...state,
        selectedLanguage: action.payload,
        currentTexts: textsForNewLanguage || { type: 'desc', value: '' },
        currentName: nameForNewLanguage || { type: 'name', value: '' },
      };
    case actionTypes.DELETE_IMAGE:
      const newImageList = state.physicalRoomGalleries.filter(e => e.id !== action.payload);
      return {
        ...state,
        physicalRoomGalleries: newImageList,
      };
    case actionTypes.LOAD_ROOM_INFO:
      if (action.payload?.upsellOptionsFrom && Array.isArray(action.payload.upsellOptionsFrom)) {
        action.payload.upsellOptionsFrom = action.payload.upsellOptionsFrom.map((item, index) => ({
          ...item,
          order: item.order || index + 1,
        }));
      }
      const nextState = {
        ...state,
        ...action.payload,
      };
      return nextState;
    case actionTypes.REORDER_IMAGE:
      const physicalRoomGalleries = action.payload.map((item, index) => {
        return { ...item, order: index + 1 };
      });

      return { ...state, physicalRoomGalleries };
    default:
      return state;
  }
};
