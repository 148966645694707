import React from 'react';
import styles from './CustomButton.module.css';

export default function CustomButton({
  text,
  leftComponent,
  rightComponent,
  background,
  color,
  onClick,
  fontSize,
  children,
  ...props
}) {
  return (
    <div
      className={styles.button}
      style={{ background, color }}
      onClick={onClick && onClick}
      {...props}
    >
      {leftComponent && leftComponent}
      <b style={{ fontSize }}>{text || children}</b>
      {rightComponent && rightComponent}
    </div>
  );
}
