import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCrossSellHistory } from 'Sell/CrossSell/Router/useHistory';
import Routes from 'Sell/CrossSell/Router/routes';
import {
  useCrossSellMutation,
  useCrossSellQuery,
} from 'Sell/CrossSell/CrossSell/CrossSell.service';
import { getDefaultDateRange } from 'utils/DateUtils';
import constants from 'utils/constants';
import { useCountersQuery } from 'Sell/CrossSell/Reports/Reports.service';
import { addLikeToWhere, sumArray } from 'Sell/utils/utils';
import moment from 'moment';
import { AppContext } from 'App.context';
import { useAppConfigQuery } from 'Sell/CrossSell/API/queries';
import sellAddPMSNoteRequest from 'utils/networking/api/sellAddPMSNote.service';
import crossSellSendEmailToHotelRequest from 'utils/networking/api/crossSellSendEmailToHotel.service';
import crossSellSendEmailToGuestRequest from 'utils/networking/api/crossSellSendEmailToGuest.service';
import crossSellUpdateChargesRequest from 'utils/networking/api/crossSellUpdateCharges.service';

const { dateFormat } = constants;

const useQueryParams = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};
const [defaultStartDate, defaultEndDate] = getDefaultDateRange();

export default function useUpsellList() {
  const {
    appActions: { getSelectedHotel },
  } = useContext(AppContext);
  const { push } = useCrossSellHistory();
  const { data: appConfig } = useAppConfigQuery();
  const queryParams = useQueryParams();
  const filters = JSON.parse(decodeURIComponent(queryParams.get('filters')));
  const initialState = {
    limit: 10,
    offset: 0,
    where: {
      created_at: {
        from: filters?.where?.created_at?.from || defaultStartDate.format(dateFormat),
        to: filters?.where?.created_at?.to || defaultEndDate.format(dateFormat),
      },
    },
  };

  const [state, setState] = useState(initialState);
  const [filterState, setFilterState] = useState(initialState);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { data: countersData, refetch: refetchCounters } = useCountersQuery(state);
  const { isLoading, data, refetch } = useCrossSellQuery(state, getSelectedHotel());
  const { data: filterData, refetch: updateFilters } = useCrossSellQuery(
    filterState,
    getSelectedHotel()
  );
  const { mutate } = useCrossSellMutation({
    onSuccess: refetch,
    refetchCounters,
  });

  function updateState(nextState) {
    setState(previousState => ({ ...previousState, ...nextState }));
  }

  function updateStatus(object, ids, action) {
    object.forEach(item => {
      if (ids.includes(item.id)) {
        switch (action) {
          case 'status_approved':
            mutate(
              { ...item, status: 'approved' },
              {
                onSuccess: e => updateFilters(),
              }
            );
            break;
          case 'status_rejected':
            mutate(
              { ...item, status: 'rejected' },
              {
                onSuccess: e => updateFilters(),
              }
            );
            break;
          default:
            return;
        }
      }
    });
  }

  const actions = {
    onChange: ({ where, pagination }) => {
      const limit = pagination.pageSize;
      updateState({ offset: pagination.page * limit, limit, where: addLikeToWhere(where) });
    },
    onSelectChange: newSelectedRowKeys => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    handleActionClick: item => async action => {
      switch (action) {
        case 'detail':
          push(Routes.upsellDetail.replace(':id', item.id));
        case 'status_approved':
          mutate(
            { ...item, status: 'approved' },
            {
              onSuccess: () => {
                refetch();
                refetchCounters();
                updateFilters();
                actions.notifyHotel(action, item);
                actions.notifyGuest(action, item);
                actions.addPMSNote(action, item);
                actions.updateCrossSellCharges(action, item);

              },
            }
          );
          break;
        case 'status_rejected':
          mutate(
            { ...item, status: 'rejected' },
            {
              onSuccess: () => {
                refetch();
                refetchCounters();
                updateFilters();
                actions.notifyHotel(action, item);
                actions.notifyGuest(action, item);
                actions.addPMSNote(action, item);
                actions.updateCrossSellCharges(action, item);
              },
            }
          );
          break;
        default:
          return;
      }
    },
    handleBulkActionClick: async action => {
      updateStatus(data?.data, selectedRowKeys, action);
    },
    onDateChange: (_date, dateString) => {
      const newState = {
        ...state,
        where: {
          ...state.where,
          created_at: {
            from: dateString[0] || defaultStartDate.format(dateFormat),
            to: dateString[1] || defaultEndDate.format(dateFormat),
          },
        },
      };

      updateState(newState);
      setFilterState({ ...newState, where: { ...newState.where, status: undefined } });
    },
    onFilterChange: status => {
      const newState = {
        ...state,
        where: {
          ...state.where,
          status,
        },
      };
      if (status === 'all') delete newState.where.status;
      updateState(newState);
    },

    multiply: (...args) => args.reduce((acc, val) => acc * (val ? parseFloat(val) : 1), 1),

    getCurrency: () => appConfig?.data?.currency,

    notifyHotel: (status, item) => {
      const { crossSellState, tags } = buildGuestlinkCrossSellCustomTags(item, status);
      crossSellSendEmailToHotelRequest({
        hotelSlug: getSelectedHotel()?.slug,
        pmsId: item?.booking?.pmsIdentifier,
        crossSellState,
        options: { tags },
        schedule: `hotel-${crossSellState}`
      });
    },

    notifyGuest: (status, item) => {
      const { crossSellState, tags } = buildGuestlinkCrossSellCustomTags(item, status);
      const data = {
        hotelSlug: getSelectedHotel()?.slug,
        pmsId: item?.booking?.pmsIdentifier,
        crossSellState,
        schedule: `guest-${crossSellState}`,
        options: { tags },
      }
      crossSellSendEmailToGuestRequest(data);
    },

    addPMSNote: (status, item) => {
      const itemName = item?.item?.texts?.find(t => t.type == 'name');
      const price = actions.multiply(item?.item?.price, item.quantity, item.quantityReturn)

      const upsellPMSNoteData = {
        bookingCode: item?.booking?.pmsIdentifier,
        crossSellStatus: status == 'status_approved' ? 'accepted' : 'requested',
        paymentStatus: 'no-paid',
        itemName: itemName?.value,
        price: `${price} ${actions.getCurrency()}`,
      };
      sellAddPMSNoteRequest({ hotelId: item.hotelId, data: upsellPMSNoteData });
    },

    updateCrossSellCharges: (status, item) => {
      crossSellUpdateChargesRequest({ 
        hotelId: item.hotelId,
        bookingIdentifier: item?.booking?.pmsIdentifier,
        data: {status: status == 'status_approved' ? 'accepted' : 'rejected', bookingIdentifier: item?.booking?.pmsIdentifier }
      });
    },
  };

  const approved = sumArray(countersData?.data?.approved);
  const rejected = sumArray(countersData?.data?.rejected);
  const pending = sumArray(countersData?.data?.pending);
  const all = approved + rejected + pending;

  const counters = {
    approved,
    rejected,
    pending,
    all,
  };

  const startDate = moment(state.where.created_at?.from, dateFormat);
  const endDate = moment(state.where.created_at?.to, dateFormat);

  return {
    dataSource: {
      items: data?.data,
      totalItems: data?.count,
      totalPages: Math.round(data?.count / state.limit),
      currentPage: state.offset / state.limit,
      pageSize: state.limit,
      filterData,
      selectedRowKeys,
      counters,
    },
    dates: [startDate, endDate],
    isLoading,
    actions,
  };

  function buildGuestlinkCrossSellCustomTags(item, status) {
    const itemName = item?.item?.texts?.find(t => t.type == 'name');
    const price = actions.multiply(item?.item?.price, item.quantity, item.quantityReturn)

    const crossSellState = status == 'status_approved' ? 'accepted' : 'rejected';
    const tags = {
      cross_sell_id: item?.id,
      cross_sell_request_date: new Date().toISOString().split('T')[0],
      cross_sell_state: crossSellState,
      item_name: itemName?.value,
      payment_status: 'no-paid',
      cross_sell_price: `${price} ${actions.getCurrency()}`,
    };
    return { crossSellState, tags };
  }
}
