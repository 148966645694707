import Colors from 'constants/Colors';

export default {
  container: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 16,
    padding: '4%',
    margin: '6%',
    backgroundColor: Colors.blueBoxColor,
    borderRadius: 12,
    border: `1px solid ${Colors.blueIntense}`,
  },
  title: {
    color: Colors.blueIntense,
    fontSize: 16,
    fontWeight: 'bold',
  },
  text: {
    color: Colors.blueIntense,
    fontSize: 14,
  },
  icon: {
    color: Colors.blueIntense,
    marginRight: 6,
    fontSize: 16,
  },
  image: {
    maxHeight: 160,
    aspectRatio: 1,
    marginLeft: 16,
  },
};
