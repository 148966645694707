import React, { useEffect, useState } from 'react';
import styles from './AppInput.styles.js';
import { DatePicker, Input, Tooltip } from 'antd';
import InputTypes from './AppInputTypes';
import Colors from 'constants/Colors.js';
import { InfoCircleOutlined } from '@ant-design/icons';

const { NUMERIC, DATE } = InputTypes;

export default function AppInput({
  title,
  value,
  onChange,
  disabled,
  type,
  hint,
  notEditable,
  hasError,
  style,
  bgColor,
  disabledDate,
  forceValue = null,
  tooltipInfo,
  addonAfter,
  inputComponent,
  ...rest
}) {
  const [error, setError] = useState(hasError);
  useEffect(() => {
    setError(hasError);
  }, [hasError]);

  const valueProps = {};
  if ( forceValue ) {
    valueProps.value = forceValue;
  }

  return (
    <div style={{ width: '100%', ...style }}>
      <div style={{ width: '100%', display: 'flex', gap: 8 }}>
        {title && <div style={styles.title}>{title}</div>}
        {tooltipInfo && <Tooltip title={tooltipInfo} color={Colors.black}>
          <InfoCircleOutlined/>
        </Tooltip>}
      </div>
      <div
        style={{
          ...styles.container,
          boxShadow: notEditable
            ? styles.shadow.boxShadow
            : error
            ? styles.errorShadow.boxShadow
            : null,
          backgroundColor: bgColor ? bgColor : Colors.inputBg,
          color: disabled ? Colors.grayText : Colors.darkText,
        }}
      >
        {inputComponent ? (
          inputComponent
        ) : type === DATE ? (
          <DatePicker
            defaultValue={value}
            {...valueProps}
            style={{ width: '100%' }}
            placeholder={hint || title}
            disabled={disabled}
            bordered={false}
            format={'DD-MM-YYYY'}
            onChange={(date, stringValue) => {
              error && setError(false);
              onChange(stringValue, date);
            }}
            disabledDate={disabledDate}
          />
        ) : (
          <Input
            style={{
              color: disabled ? Colors.grayText : Colors.darkText,
            }}
            bordered={false}
            disabled={disabled}
            placeholder={hint || title}
            value={value}
            onChange={e => {
              error && setError(false);
              onInput(e.target.value);
            }}
            addonAfter={addonAfter}
            {...rest}
          />
        )}
      </div>
    </div>
  );

  function onInput(input) {
    switch (type) {
      case NUMERIC:
        if (isNaN(input)) return;
    }

    onChange && onChange(input);
  }
}
