import { AppContext } from 'App.context';
import Colors from 'constants/Colors';
import { useEffect } from 'react';
import { useContext, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import constants from '../../../utils/constants'
export default function useDoorOpeningHook() {
  const { API } = useContext(ApiContext);
  const {
    appState,
    appActions: { translate, getSelectedHotel },
  } = useContext(AppContext);

  const [state, setState] = useState({
    isLoading: false,
    integrations: null,
    allHotels: null,
    hotels: null,
    enabledDoorOpening: [],
    selectedDoorOpening: [],
    expandedRow: null,
    suscription: null,
  });
  
  useEffect(() => {
    actions.loadHotels();
    actions.loadIntegrations();
    actions.loadDoorOpeningHotel();
    actions.hasOauthAsParameter();
  }, []);

  useEffect(() => {
    actions.getDoorOpeningSuscriptionStatus();
    actions.filterByHotel();
  }, [state.allHotels, appState.selectedHotel]);

  const actions = {
    loadHotels: () => {
      API.pmsHotelsRequest()
        .then(res => {
          updateState({ allHotels: res });
        })
        .catch(err => {
          console.warn(err);
        });
    },

    getDoorOpeningSuscriptionStatus: () => {
      if (!getSelectedHotel()?.key) updateState({ suscription: true });
      else {
        API.getDoorOpeningSuscriptionStatus({ hotelId: getSelectedHotel()?.key }).then(res => {
          updateState({ suscription: res });
        });
      }
    },

    loadIntegrations: () => {
      updateState({ isLoading: true });
      API.getDoorOpeningList()
        .then(res => {
          updateState({
            isLoading: false,
            integrations: res.filter(integration => integration.keyTypes),
          });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false, integrations: null });
        });
    },

    loadDoorOpeningHotel: () => {
      updateState({ isLoading: true });
      API.getDoorOpeningHotels()
        .then(res => {
          let selectedDoorOpening = {};
          for (const doorOpening of res) {
            selectedDoorOpening[doorOpening.hotelId] = doorOpening.platform;
          }

          const enabledDoorOpening = res.map(d => d.hotelId);

          updateState({
            isLoading: false,
            enabledDoorOpening: enabledDoorOpening,
            selectedDoorOpening,
          });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },

    getColorStateFromPMS: hotel => {
      if (actions.isEnabled(hotel.id)) {
        return Colors.greenHotelIntegratedText;
      } else if (hotel.id in state.selectedDoorOpening) {
        return Colors.yellowHotelNoIntegratedText;
      } else {
        return Colors.redDesactivedText;
      }
    },

    getBackgroundColorStateFromPMS: hotel => {
      if (actions.isEnabled(hotel.id)) {
        return Colors.greenHotelIntegratedBg;
      } else if (hotel.id in state.selectedDoorOpening) {
        return Colors.yellowHotelNoIntegratedBg;
      } else {
        return Colors.redDesactivedBg;
      }
    },

    getTextStateFromPMS: hotel => {
      if (actions.isEnabled(hotel.id)) {
        return translate('states.enabled');
      } else if (hotel.id in state.selectedDoorOpening) {
        return translate('states.pending');
      } else {
        return translate('states.disabled');
      }
    },

    getSelectedDoorOpeningForHotel: hotelId => {
      const selectedDoorOpening = state.selectedDoorOpening[hotelId];
      const fullData = state.integrations?.find(
        integration => integration.name?.toUpperCase() == selectedDoorOpening?.toUpperCase() || integration.code?.toUpperCase() == selectedDoorOpening?.toUpperCase()
      );

      return fullData || null;
    },

    setSelectedDoorOpening: (rowID, doorOpeningId) => {
      const selectedIntegration = state.integrations?.find(
        integration => integration.id === doorOpeningId
      );
      const currentlySelected = state.selectedDoorOpening;
      currentlySelected[rowID] = selectedIntegration.name;
      updateState({ selectedDoorOpening: currentlySelected });
    },

    isRowExpandable: hotelId => {
      return actions.isEnabled(hotelId) || hotelId in state.selectedDoorOpening;
    },

    filterByHotel: () => {
      if (!state.allHotels) return;
      let dataSet;
      if (getSelectedHotel()?.key == 'null') {
        dataSet = state.allHotels;
      } else {
        const filteredHotels = state.allHotels?.filter(
          hotel => hotel.name === getSelectedHotel()?.value
        );
        dataSet = filteredHotels;
      }

      dataSet.forEach(element => {
        element.key = element.id;
      });

      updateState({ hotels: dataSet });
    },

    onExpanded: (rowId, expanded) => {
      if (state.expandedRow === rowId) {
        updateState({ expandedRow: null });
      } else {
        updateState({ expandedRow: rowId });
      }
    },

    updateEnabledHotels: () => {
      updateState({ isLoading: true });
      API.getDoorOpeningHotels()
        .then(res => {
          const enabledDoorOpening = res.map(d => d.hotelId);

          updateState({
            isLoading: false,
            enabledDoorOpening: enabledDoorOpening,
          });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },

    isEnabled: hotelId => {
      return state.enabledDoorOpening.includes(hotelId);
    },

    hasOauthConfig: () => {
      const selectedHotel = getSelectedHotel();
      const hotelId = selectedHotel?.key;
    
      if (!hotelId) {
        return Promise.resolve(true); 
      }
    
      const name = state.selectedDoorOpening[hotelId];  
      if (name && constants.KEY_WITH_OAUTH.includes(name.toLowerCase())) {
        return API.getOauth({ hotelId, name })
          .then(oauth => {
            return true; 
          })
          .catch(error => {
            return false; 
          });
      } else {        
        return Promise.resolve(true);
      }
    },
    
    hasOauthAsParameter: () => {
      const selectedHotel = getSelectedHotel();
      const hotelId = selectedHotel?.key; 
    
      const doorOpeningUrl = window.location.origin + window.location.pathname; 
      const currentUrl = window.location.href;
      const searchParams = new URLSearchParams(window.location.search);
    
      if (currentUrl.includes("state=yacan") && hotelId) {
        const code = searchParams.get('code');
        const keyType = searchParams.get('state');
    
        API.setDoorOpeningOauth({ hotelId, code, keyType, doorOpeningUrl })
          .then(() => {
            
           window.location.replace(doorOpeningUrl);
          })
          .catch(error => {
          });
          
      }
    },
    

    generateOauth: () => {
      const selectedHotel = getSelectedHotel();
      const hotelId = selectedHotel?.key;
      let keyType;
      let name;
    
      if (hotelId) {
        
        name = state.selectedDoorOpening[hotelId];
    
        if (name) {
          let platform;
          let doorOpening;
          if (name.toLowerCase() === "yacan") {
            keyType = "pin";
            doorOpening = {
              code: "yacan",
              name: "Yacan",
              keyTypes: "pin",
              id: 3,
              config: {}
            }
          }
    
          const parameters = {};
          const doorOpeningHotelConfig = {
            checkinStatus: 'indifferent',
            paymentStatus: 'indifferent',
            checkinHour: null,
            checkoutHour: null,
            keyType,
          };
    
          API.updateHotelDoorOpeningConfig({
            platform: doorOpening,
            hotelId,
            parameters,
            doorOpeningHotelConfig,
          });
        }
      }
    
      if (constants.KEY_WITH_OAUTH.includes(name)) {
       // const redirect_uri = "https://dashboard.pre.civitfun.com/dooropening"; TEST
        const redirect_uri = window.location.origin + window.location.pathname; 
        
        window.location.replace(
          `${constants.YACAN_OAUTH_LINK}${constants.YACAN_CLIENT_ID}&redirect_uri=${redirect_uri}&state=yacan`
        );
      }
    },
    
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
