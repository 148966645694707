import React, { useState, useEffect } from 'react';
import { Button, message, Spin } from 'antd';
import guestStyles from './Guest.styles';
import useSendDataHook from './hooks/SendData.hook';
import useGuestHook from './hooks/Guest.hook';
import { guestsMock, italianGuestsMock } from './mocks';
import ResponseTable from './ResponseTable';
import DynamicFormTable from './DynamicFormTable';
import CustomButton from '../../../../common/CustomButton/CustomButton';
import ErrorDisplay from '../ErrorDisplay';
import useFormsHook from '../../../Forms/Forms.hook.js';

export default function Guest({ hotelId, translate, selectedPms, API }) {
  const selectorTabsValidationSendData = translate('selector.tabs.validationSendData');
  const validationGuestDescription = translate('validation.guest.description');
  const validationGuestBookingIdentifier = translate('validation.guest.bookingIdentifier');
  const validationGuestActivateCustomMock = translate('validation.guest.activateCustomMock');
  const validationGuestButton = translate('validation.guest.button');

  const { sendData, loading } = useSendDataHook();
  const { snakeToCamel } = useGuestHook();

  const [isChecked, setIsChecked] = useState(false);
  const [bookingIdentifier, setBookingIdentifier] = useState('');
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [mock, setMock] = useState(guestsMock[0]);
  const [pmsConfig, setPmsConfig] = useState(null);
  const [hotelConfig, setHotelConfig] = useState(null);

  const { state, actions } = useFormsHook();
  useEffect(() => {
    const fetchPmsConfig = async () => {
      try {
        const config = await API.pmsConfigDynamicFormRequest({ pmsId: selectedPms.id });
        setPmsConfig(config);
      } catch (error) {
        console.error('Error loading PMS config:', error);
      }
    };

    const fetchHotelConfig = async () => {
      try {
        const config = await API.getOneHotel({ hotelId: hotelId });
        setHotelConfig(config);
      } catch (error) {
        console.error('Error loading Hotel config:', error);
      }
    };

    fetchPmsConfig();
    fetchHotelConfig();
  }, [selectedPms.id, hotelId]);

  useEffect(() => {
    if (pmsConfig && hotelConfig && state.form) {
      const pos = getRandomInt(0, guestsMock.length - 1);
      const defaultMock = guestsMock[pos];

      let supportedFields = pmsConfig.config_dynamic_form?.supportedFields?.map(
        field => field.civitfun
      );

      if (supportedFields) {
        let newMock = defaultMock;
        if (hotelConfig.country === 'IT') {
          const pos = getRandomInt(0, italianGuestsMock.length - 1);
          newMock = italianGuestsMock[pos];
          //TODO active in the future version when we use the pms support fields
          // supportedFields = [
          //   ...supportedFields,
          //   'country',
          //   'province',
          //   'city',
          //   'birthCountry',
          //   'birthProvince',
          //   'birthCity',
          //   'documentIssuanceCountry',
          //   'documentIssuanceProvince',
          //   'documentIssuanceCity',
          // ];
        }

        //Filter by the pms support fields future version
        // const camelCaseSupportedFields = supportedFields.map(snakeToCamel);

        // newMock.fields = newMock.fields.filter(field =>
        //   camelCaseSupportedFields.includes(snakeToCamel(field.key))
        // );

        // Filter by Form
        if (state.isDynamicForm) {
          const formsFieldsActive = state.form.holder_1.filter(field => field.state == 'active');
          const camelCaseFormsFieldsActive = formsFieldsActive.map(form => snakeToCamel(form.name));
          newMock.fields = newMock.fields.filter(field => {
            return camelCaseFormsFieldsActive.includes(snakeToCamel(field.key));
          });
        } else {
          const formsFieldsActive = state.form.filter(field => field.state == 'active');
          const camelCaseFormsFieldsActive = formsFieldsActive.map(form => snakeToCamel(form.name));
          newMock.fields = newMock.fields.filter(field => {
            return camelCaseFormsFieldsActive.includes(snakeToCamel(field.key));
          });
        }

        setMock(newMock);
      } else {
        setMock(defaultMock);
      }
    }
  }, [pmsConfig, hotelConfig, state.form]);

  const handleformChange = () => {
    setIsChecked(!isChecked);
  };

  const handleInputBookingIdentifierChange = event => {
    setBookingIdentifier(event.target.value);
  };

  const sendDataGuest = async () => {
    try {
      const response = await sendData({ hotelId, bookingIdentifier, mockGuests: mock });
      if (!response.success) message.error('Hubo un problema con la operación');
      else message.success('Operación completada con éxito!');
      setData(response);
    } catch (error) {
      message.error('Hubo un problema con la operación');
      setError(error);
    }
  };

  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  return (
    <div style={guestStyles.stepDetails}>
      <div style={guestStyles.container}>
        <strong>{selectorTabsValidationSendData}</strong>
        <p style={guestStyles.subtitle}>{validationGuestDescription}</p>
        <div style={guestStyles.content}>
          <div style={guestStyles.leftSection}>
            <label htmlFor="bookingIdentifier" style={guestStyles.subtitle}>
              {validationGuestBookingIdentifier}
            </label>
            <input
              type="text"
              id="bookingIdentifier"
              style={guestStyles.inputBox}
              onChange={handleInputBookingIdentifierChange}
            />
            <div style={guestStyles.toggleContainer}>
              <label style={guestStyles.switchText}>{validationGuestActivateCustomMock}</label>
              <label style={guestStyles.switch}>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleformChange}
                  style={guestStyles.switchInput}
                />
                <span
                  style={{
                    ...guestStyles.slider,
                    ...(isChecked ? guestStyles.inputCheckedSlider : {}),
                  }}
                >
                  <span
                    style={{
                      ...guestStyles.sliderBefore,
                      ...(isChecked ? guestStyles.inputCheckedSliderBefore : {}),
                    }}
                  ></span>
                </span>
              </label>
            </div>
            <div style={guestStyles.buttonContainer}>
              <CustomButton
                style={guestStyles.button}
                text={validationGuestButton}
                onClick={sendDataGuest}
              />
            </div>
          </div>
          <div style={guestStyles.rightSection}>
            {isChecked && (
              <div style={guestStyles.dynamicFormContainer}>
                <DynamicFormTable mock={mock} setMock={setMock} translate={translate} />
              </div>
            )}
            {data && data.success == true
              ? data.mockGuests &&
                data.mockGuests[0].fields.length > 0 && (
                  <div style={guestStyles.responseTableContainer}>
                    <ResponseTable data={data} translate={translate} />
                  </div>
                )
              : data && data.error && data.error.length > 0 && <ErrorDisplay errors={data.error} />}
          </div>
        </div>
      </div>
      {loading && (
        <div style={guestStyles.spinContainer}>
          <Spin size="large" tip="Cargando..." />
        </div>
      )}
    </div>
  );
}
