import React, { useState } from 'react';
import { Input } from 'antd';
import styles from './LimitedText.styles.js';

const { TextArea } = Input;

export default function LimitedText({ text, title, placeholder, limit = 0, onChange }) {
  const isMaxLength = text?.length >= limit;

  return (
    <div style={styles.container}>
      <div styles={styles.title}>{title}</div>
      <TextArea
        rows={4}
        style={styles.container}
        placeholder={placeholder}
        value={text}
        maxLength={limit}
        onChange={e => {
          onChange(e.target.value);
        }}
      />
      <div style={styles.counter(isMaxLength)}>
        {text?.length || 0}/{limit}
      </div>
    </div>
  );
}
