import { UndoOutlined } from '@ant-design/icons';
import React, { useContext } from 'react';
import AppTextInput from '../../../common/AppTextInput/AppTextInput';
import AppButton from '../../../common/Forms/AppButton/AppButton';
import Map from '../../../common/Map/Map';
import { HotelsContext } from '../Hotels.context';
import styles from './HotelDetail.styles.js';
import { Button } from 'antd';
import { AppContext } from '../../../../App.context';
import AppSelector from 'Components/common/AppSelector/AppSelector';
import AppInput from 'Components/common/AppInput/AppInput';
import AppInputTypes from 'Components/common/AppInput/AppInputTypes';
import Space from 'Components/common/Space/Space';

export default function HotelDetail() {
  const {
    appActions: { translate, isUserAdmin },
  } = useContext(AppContext);

  const { hotelsState, hotelsActions } = useContext(HotelsContext);

  return (
    <div className={styles.container}>
      <div style={styles.inputRow}>
        <AppInput
          title={translate('hotels.nameCol')}
          value={hotelsState?.name}
          onChange={text => hotelsActions.onNameChange(text)}
        />
      </div>
      <div style={styles.inputRow}>
        <AppInput
          value={hotelsState?.designation}
          title={translate('hotels.designation')}
          onChange={value => hotelsActions.updateField('designation', value)}
        />
        <AppInput
          value={hotelsState?.nif}
          title={translate('hotels.nif')}
          onChange={value => hotelsActions.updateField('nif', value)}
        />
      </div>
      <div style={styles.inputRow}>
        <AppSelector
          title={translate('hotels.establishmentType')}
          value={hotelsState.establishmentType}
          options={hotelsActions.getEstablishmentTypeOptions()}
          onOptionSelected={option => hotelsActions.updateField('establishmentType', option)}
        />
        <AppSelector
          title={translate('hotels.hasInternet')}
          value={hotelsState.hasInternet}
          options={hotelsActions.getHasInternetOptions()}
          onOptionSelected={option => hotelsActions.updateField('hasInternet', option)}
        />
        <AppInput
          title={translate('hotels.roomCount')}
          value={hotelsState.roomCount}
          onChange={value => hotelsActions.updateField('roomCount', value)}
        />
      </div>

      <Space height={16} />
      <b className={styles.mapHeader}>{translate('hotels.mapHint')}</b>
      <Map />
      <div style={{ height: 16 }} />
      <div style={styles.inputRow}>
        <AppInput
          value={hotelsState?.country}
          title={translate('hotels.country')}
          disabled
          notEditable
        />
        <AppInput
          value={hotelsState?.province}
          title={translate('hotels.province')}
          disabled
          notEditable
        />
      </div>
      <div style={styles.inputRow}>
        <AppInput value={hotelsState?.city} title={translate('hotels.city')} disabled notEditable />
        <AppInput
          value={hotelsState?.postalCode}
          title={translate('hotels.postalCode')}
          type={AppInputTypes.NUMERIC}
          disabled
          notEditable
        />
      </div>
      <div style={styles.inputRow}>
        <AppInput
          value={hotelsState?.address}
          title={translate('hotels.address')}
          disabled
          notEditable
        />
      </div>
      <Space height={16} />
      <div style={styles.inputRow}>
        <AppInput
          title={translate('hotels.phone')}
          value={hotelsState?.phone}
          onChange={value => hotelsActions.updateField('phone', value)}
        />
        <AppInput
          value={hotelsState?.email}
          title={translate('hotels.email')}
          onChange={value => hotelsActions.updateField('email', value)}
        />
      </div>
      <div style={styles.inputRow}>
        <AppInput
          value={hotelsState?.website}
          title={translate('hotels.website')}
          onChange={value => hotelsActions.updateField('website', value)}
        />
        <AppInput
          value={hotelsState?.adUrl}
          title={translate('hotels.adUrl')}
          onChange={value => hotelsActions.updateField('adUrl', value)}
        />
      </div>
      {isUserAdmin() && hotelsState.hotelId ? (
        <div style={styles.inputRow}>
          <AppSelector
            style={{width: '100%'}}
            title={translate('hotels.hotelStatusId')}
            value={hotelsState.hotelStatusId}
            options={hotelsActions.getHotelStatusIdOptions()}
            onOptionSelected={option => hotelsActions.updateField('hotelStatusId', option)}
          />
        </div>
      ) : null}
      <div style={styles.buttonsContainer}>
        {hotelsState.hotelId ? (
          <Button icon={<UndoOutlined />} onClick={hotelsActions.reset} />
        ) : null}
        <AppButton
          text={hotelsState.hotelId ? translate('save') : translate('add')}
          action={hotelsActions.onButtonClicked}
        />
      </div>
    </div>
  );
}
