import axios from 'axios';
import apiConfig from '../api.config';

export default function updateConfig({ hotelId, values }) {
  const {
    host,
    URIs: { updateBookingSearch },
  } = apiConfig;

  const uri = `${host}${updateBookingSearch}`;

  const data = {};
  data.fields = values.map(value => {
    return {
      name: value.name,
      state: value.state,
    };
  });

  if (hotelId) {
    data.hotelId = hotelId;
  }

  return axios
    .put(uri, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
