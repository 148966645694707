import Colors from 'constants/Colors';

export default {
  container: {
    display: 'flex',
    padding: 16,
    justifyContent: 'space-around',
    alignItems: 'center',
    flex: 1,
  },
};
