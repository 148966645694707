export default {
  button: {
    backgroundColor: '#F8F8F8',
    borderRadius: 8,
    fontWeight: 700,
  },
  icon: {
    color: '#2D5BFF',
  },
};
