import { AppContext } from 'App.context';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from '../../../../utils/networking/Api.context';

const useCheckInDetailHook = checkinId => {
  const { API } = useContext(ApiContext);
  const { appState } = useContext(AppContext);
  const lang = appState.currentLanguage.id;

  const [state, setState] = useState({
    data: null,
    isLoading: true,
  });

  useEffect(() => {
    actions.getCheckinDetail(checkinId);
  }, []);

  const actions = {
    getCheckinDetail: checkinId => {
      updateState({ isLoading: true });
      API.checkinDetailRequest({ id: checkinId, lang: lang })
        .then(response => {
          updateState({ data: response, isLoading: false });
        })
        .catch(err => {
          //todo handle error
          console.warn('Checkin Detail error: ', err);
        });
    },

    getHotelName: hotelId => {
      if (appState.hotelList && appState.hotelList.length) {
        const hotel = appState.hotelList.find(hotel => hotel.id == hotelId);
        if (hotel) {
          return hotel?.name;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
};

export default useCheckInDetailHook;
