import React from 'react';
import { ReactComponent as Icon } from 'Sell/assets/icon/drag-icon.svg';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

function renderChildren(children, setActivatorNodeRef, listeners) {
  return React.Children.map(children, child => {
    if (child.key === 'sort') {
      return React.cloneElement(child, {
        children: (
          <Icon
            ref={setActivatorNodeRef}
            style={{
              touchAction: 'none',
              cursor: 'move',
              fill: 'black',
            }}
            {...listeners}
          />
        ),
      });
    }
    return child;
  });
}

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '14px',
    border: 'none',
    justifyContent: 'space-between',
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {renderChildren(children, setActivatorNodeRef, listeners)}
    </tr>
  );
};

export default Row;
