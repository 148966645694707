import Colors from 'constants/Colors';

export default {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'right',
    flex: 1,
    marginTop: 32,
  },
};
