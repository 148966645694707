import Colors from 'constants/Colors';

export default {
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 32,
    marginLeft: 32,
  },
  tableContainer: {
    display: 'flex',
    width: '100%',
    flex: 1,
    marginTop: 16,
    marginBottom: 32,
  },
  table: {
    backgroundColor: 'white',
    marginLeft: 32,
    marginRight: 32,
    width: '100%',
    borderRadius: 14,
    padding: 14,
    minHeight: '70vh',
  },
  rowStyle: {
    flex: 1,
    display: 'flex',
    alignSelf: 'top',
    padding: 8,
    height: 82,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
  },
  exampleIcon: {
    color: Colors.white,
    fontSize: 18,
    paddingTop: 6,
    paddingBottom: 6,
  },
  copyIcon: {
    fontSize: 16,
    color: Colors.disableButton,
  },
  disabledSuscription: {
    color: Colors.grayText,
    flex: 1,
  },
  disabledSuscriptionContainer: {
    gap: 16,
    alignItems: 'center',
  },
};
