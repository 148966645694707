import Colors from 'constants/Colors';

export default {
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 4,
    padding: 8,
    borderRadius: 4,
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: Colors.lightGray,
  },
  containerChecked: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 4,
    padding: 8,
    borderRadius: 4,
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: Colors.blueColor,
  },
  icon: {
    fill: 'black',
    width: 20,
  },
  iconChecked: {
    fill: 'white',
    width: 20,
  },
  text: {
    color: 'FFF',
    fontSize: 11,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    textTransform: 'capitalize',
  },
};
