export default [
  {
    name: 'chain',
    tags: ['chain_logo', 'chain_name'],
  },

  {
    name: 'hotel',
    tags: [
      'hotel_logo',
      'hotel_name',
      'address',
      'hotel_phone',
      'CIF',
      'CIF_postal_code',
      'CIF_address',
      'CIF_city',
      'CIF_province',
      'CIF_country',
      'CIF_business_name',
      'CIF_business_email',
      'establishment_type',
      'hotel_designation',
      'hotel_room_count',
      'hotel_has_internet',
      'legal_cardex',
      'legal_terms_and_conditions',
      'legal_data_privacy_policy',
      'legal_data_commercial_use',
      'legal_loyalty_program',
      'link_ios',
      'link_android',
      'hotel_web',
      'hotel_ad_url',
    ],
  },

  {
    name: 'booking',
    tags: [
      'reference_number',
      'booking_identifier',
      'entrance_date',
      'departure_date',
      'entrance_time',
      'departure_time',
      'nights',
      'adults',
      'children',
      'num_guests',
      'email',
      'balance',
      'agreed_price',
      'booking_room',
      'room_number',
      'room_number_name',
      'customer_notes',
      'agency_code',
      'regime_stay',
      'guest_name',
      'guest_nationality',
      'document_number',
      'holder_signature',
      'police_report_number',
    ],
  },
  {
    name: 'guest',
    tags: [
      'guest_first_name',
      'guest_surname',
      'guest_second_surname',
      'guest_gender',
      'guest_birth_date',
      'guest_nationality',
      'guest_document_type',
      'guest_document_number',
      'guest_document_support_number',
      'guest_expedition_date',
      'guest_expiration_date',
      'guest_email',
      'guest_phone',
      'guest_landline_phone',
      'guest_address',
      'guest_postal_code',
      'guest_city',
      'guest_country',
      'guest_province',
      'guest_signature',
      'guest_family_relationship',
      'guest_payment_type',
      'data_privacy_policy',
      'data_commercial_use',
      'loyalty_program',
      'bracelet_id',
      'signer',
      'guest_allow_telephone_contact',
      'guest_signature_date',
    ],
  },
  {
    name: 'credit_card',
    tags: ['card_type', 'card_number'],
  },
];
