import React, { useContext } from 'react';
import styles from './SmtpConfig.styles.js';
import MailTemplateInput from 'Components/Dashboard/Guestlink/MailTemplateDetail/MailTemplateInput/MailTemplateInput.jsx';
import { AppContext } from 'App.context.js';
import Loading from 'Components/common/Loader/Loading.jsx';
import AppButton from 'Components/common/Forms/AppButton/AppButton.jsx';
import AppSelector from 'Components/common/AppSelector/AppSelector.jsx';
import { GuestlinkConfigContext } from '../GuestlinkConfig.context.js';
import SaveButton from 'Components/common/SaveButton/SaveButton.jsx';
import Modal from 'antd/lib/modal/Modal';
import TestEmailModal from '../../MailTemplateDetail/TestEmailModal/TestEmailModal.jsx';

export default function SmtpConfig() {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useContext(GuestlinkConfigContext);

  return (
    <div>
      <div style={styles.rowContainer}>
        <MailTemplateInput
          value={state.host}
          placeholder={translate('guestlink.hostPlaceholder')}
          name={translate('guestlink.host')}
          onChange={value => actions.setField('host', value)}
          disabled={state.isLoading}
        />
        <div style={styles.port}>
          <MailTemplateInput
            value={state.port}
            name={translate('guestlink.port')}
            onChange={value => actions.setField('port', value)}
            disabled={state.isLoading}
          />
        </div>
      </div>
      <div style={styles.rowContainer}>
        <AppSelector
          style={styles.selector}
          title={translate('guestlink.securityLayer')}
          value={state.security_layer}
          options={actions.getSecurityLayerOptions()}
          onOptionSelected={value => actions.setField('security_layer', value)}
          disabled={state.isLoading}
        />
        <AppSelector
          style={styles.selector}
          title={translate('guestlink.smtpAuth')}
          value={state.smtpAuth}
          options={actions.getSmtpAuthOptions()}
          onOptionSelected={value => actions.setField('smtpAuth', value)}
          disabled={state.isLoading}
        />
      </div>
      <div style={styles.rowContainer}>
        <MailTemplateInput
          value={state.user}
          placeholder={translate('guestlink.userPlaceholder')}
          name={translate('guestlink.user')}
          onChange={value => actions.setField('user', value)}
          disabled={state.isLoading}
        />
        <MailTemplateInput
          value={state.password}
          placeholder={translate('guestlink.passwordPlaceholder')}
          name={translate('guestlink.password')}
          onChange={value => actions.setField('password', value)}
          disabled={state.isLoading}
          password
        />
      </div>
      <div style={styles.singleRowContainer}>
        <MailTemplateInput
          value={state.default_sender}
          placeholder={translate('guestlink.defaultSenderPlaceholder')}
          name={translate('guestlink.defaultSender')}
          onChange={value => actions.setField('default_sender', value)}
          disabled={state.isLoading}
        />
      </div>
      <div style={styles.buttonContainer}>
        {state.isLoading && <Loading size={'small'} />}
        <SaveButton
          text={translate('guestlink.save')}
          action={(applyToAllHotels) => actions.onSaveSmtpConfig(applyToAllHotels)}
          disabled={state.isLoading}
        />
        <div style={styles.extraButtonsContainer}>
          <AppButton
            text={translate('guestlink.checkConnection')}
            secondary
            action={() => actions.onCheckConnection()}
            disabled={state.isLoading}
          />
          <AppButton
            text={translate('guestlink.sendTestEmail')}
            action={() => actions.setShowTestEmailModal(true)}
            secondary
            disabled={state.isLoading}
          />
        </div>
      </div>
      <Modal
        open={state.showSendTestEmailModal}
        onCancel={() => actions.setShowTestEmailModal(false)}
        okText={translate('mailTemplates.sendButton')}
        confirmLoading={state.isSendingTestEmail}
        onOk={() => actions.onSendTestEmail()}
      >
        <TestEmailModal
          setShowModal={actions.setShowTestEmailModal}
          setMail={actions.setTestEmailAddress}
        />
      </Modal>

      {/* <style jsx>{`
        .rc-virtual-list-scrollbar-thumb {
          display: none;
        }
      `}</style> */}
    </div>
  );
}
