import React, { useState } from 'react';
import styles from './ColorInput.module.css';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import { FormatPainterOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';

export default function ColorInput({ name, description, value, id, setValue }) {
  const [pickerVisible, setPickerVisible] = useState(false);

  const colorPicker = (
    <div>
      <HexColorPicker color={value} onChange={color => setValue(id, color.substring(1))} />
      <HexColorInput color={value} onChange={color => setValue(id, color.substring(1))} />
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <b className={styles.title}>{name}</b>
        {description && <div className={styles.description}>{description}</div>}
      </div>
      <Dropdown
        overlay={colorPicker}
        trigger={['click']}
        className={styles.colorContainer}
        visible={pickerVisible}
        onVisibleChange={flag => setPickerVisible(flag)}
      >
        <div>
          <div className={styles.color} style={{ backgroundColor: `#${value}` }}></div>
          <div className={styles.pickerIcon}>
            <FormatPainterOutlined />
          </div>
        </div>
      </Dropdown>
    </div>
  );
}
