import { notification } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import superUserChainsRequest from 'utils/networking/api/auth/superuser/chains.service';
import impersonateLoginRequest from 'utils/networking/api/auth/superuser/impersonateLogin.service';
import { AppContext } from '../../../../App.context';
import { useHistory } from 'react-router-dom';
import storage from '../../../../utils/storage';

export default function useSuperUserAccountsHook() {
  const { appState, appActions } = useContext(AppContext);
  const location = useLocation();
  const history = useHistory();

  const [state, setState] = useState({
    loading: false,
    dataSource: {
      items: [],
      totalItems: 0,
      totalPages: 0,
      currentPage: 0,
      pageSize: 5,
    },
    filters: {},
    userId: location.state.userId || appState.userId,
    token: location.state.token || appState.accessToken,
    email: location.state.email || appState.email,
    ownerId: location.state.ownerId,
    userType: location.state.userType || appState.userType,
  });

  useEffect(() => {
    actions.loadUserChains();
  }, [state.dataSource.currentPage, state.filters]);

  const actions = {
    loadUserChains: () => {
      updateState({ loading: true });
      superUserChainsRequest(
        state.token,
        state.userId,
        state.dataSource?.currentPage,
        state.dataSource?.pageSize,
        state.filters
      )
        .then(data => {
          updateState({ loading: false, dataSource: data });
        })
        .catch(error => {
          updateState({ loading: false });
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    },

    impersonateLogin: chainId => {
      impersonateLoginRequest(state.token, state.userId, chainId)
        .then(data => {
          updateState({ loading: false });
          appActions.saveAuthToken({
            email: state.email,
            accessToken: data.token,
            superUser: true,
            userId: data.userId,
            ownerId: data?.ownerId,
            hasSeenQuickguide: data?.hasSeenQuickguide,
            userType: state.userType,
          });
          appActions.setChain(data?.chain);

          if (appState) {
            storage.add('slug', JSON.stringify(data?.chain));
          }

          history.push('/hotels');
        })
        .catch(error => {
          updateState({ loading: false });
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    },

    onChange: filters => {
      var newPage,
        newPageSize = -1;
      var searchFilters;
      if (state.dataSource?.currentPage !== filters?.pagination.page) {
        newPage = filters?.pagination.page;
      }
      if (state.dataSource?.pageSize !== filters?.pagination.pageSize) {
        newPageSize = filters?.pagination.pageSize;
      }
      if (filters?.where) {
        const chain = filters?.where['chain.name'];
        const hotel = filters?.where['name'];
        searchFilters = { chain, hotel };
      }
      updateState({
        dataSource: {
          ...state.dataSource,
          currentPage: newPage >= 0 ? newPage : state.dataSource?.currentPage,
          pageSize: newPageSize >= 0 ? newPageSize : state.dataSource?.pageSize,
        },
        filters: searchFilters,
      });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
