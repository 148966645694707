import React from 'react';
import { Typography } from 'antd';
import styles from './Title.styles.js';

export default function TitleComponent({ text }) {
  const { Title } = Typography;

  return (
    <Title style={styles.textComponent} level={2}>
      {text}
    </Title>
  );
}
