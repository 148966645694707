import axios from 'axios';
import apiConfig from '../api.config';

export default function upsellSendEmailToGuestApprovedRequest({ hotelSlug, pmsId, tags }) {
  const {
    guestlinkHost,
    URIs: { upsellSendEmailToGuestApproved },
  } = apiConfig;

  // workaround: Guestlink does not support htts rigth now on PROD
  const uri = `https://upsell-api.civitfun.com/${upsellSendEmailToGuestApproved}`;

  return axios
    .post(uri, JSON.stringify({ hotelSlug, pmsId, schedule: 'guest-accepted', options: { tags } }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
