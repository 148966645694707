import { SearchOutlined } from '@ant-design/icons';
import { Pagination, Input, Empty } from 'antd';
import { AppContext } from 'App.context';
import Loading from 'Components/common/Loader/Loading';
import Colors from 'constants/Colors';
import React, { useContext } from 'react';
import WelcomeLayout from '../WelcomeLayout/WelcomeLayout';
import useSelectPMSHook from './SelectPMS.hook';
import styles from './SelectPMS.styles';
import PMSListItem from './PMSListItem/PMSListItem';
import CreatePms from './CreatePms/CreatePms';

export default function SelectPMS() {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useSelectPMSHook();
  const pageItems = state.pmsList?.slice(state.minValue, state.maxValue);

  return (
    <WelcomeLayout title={translate('selectPMS.header')}>
      <div style={styles.wrapper}>
        <div
          style={{
            ...styles.container,
            justifyContent: state.isLoading ? 'center' : 'flex-start',
          }}
        >
          <CreatePms
            onClick={actions.onPmsSelected}
            onChange={actions.handleSearch}
            value={state.searchInput}
            showButton={!state.isLoading && state.pmsList && state.pmsList.length == 0}
          />
          {!state.isLoading && state.pmsList && state.pmsList.length ? (
            <div>
              {pageItems.map((val, index) => {
                return (
                  <PMSListItem
                    key={index}
                    pms={val}
                    isLastItem={index + 1 == pageItems.length}
                    onItemSelected={actions.onPmsSelected}
                  />
                );
              })}
            </div>
          ) : (
            <>
              {!state.isLoading ? (
                <div style={styles.emptyContainer}>
                  <Empty description={false} />
                </div>
              ) : null}
            </>
          )}

          {state.isLoading && <Loading />}
        </div>
        {!state.isLoading && state.pmsList && state.pmsList.length ? (
          <Pagination
            style={{ alignSelf: 'flex-end' }}
            defaultPageSize={actions.getPmsPerPage()}
            onChange={actions.onPageChanged}
            total={state.pmsList.length}
            current={state.currentPage}
            showSizeChanger={false}
          />
        ) : null}
      </div>
    </WelcomeLayout>
  );
}
