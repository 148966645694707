import { useEffect, useRef } from 'react';

export default function useTemplateEditorHook({ tagValues }) {
  const editor = useRef(null);
  const textArea = useRef(null);
  const IMAGE_TAGS = ['chain_logo', 'hotel_logo', 'guest_signature', 'holder_signature'];
  const EDITOR_HEIGHT = 512;

  const JODIT_CODE_MODE = 2;

  useEffect(() => {
    if (tagValues) {
      let text = textArea.current.value;
      for (const tag of tagValues) {
        if (tag.value) {
          text = text.replaceAll(`[${tag.key}]`, tag.value);
        }
      }

      editor.current.setEditorValue(text);
    }
  }, [tagValues]);

  const actions = {
    setEditorRef: ref => {
      editor.current = ref;

      editor.current.events.on('beforeSetMode', data => {
        let text = textArea.current.value;

        if (data.mode === JODIT_CODE_MODE) {
          for (const tag of tagValues) {
            if (tag.value) {
              text = text.replaceAll(tag.value, `[${tag.key}]`);
            }
          }
        } else {
          for (const tag of tagValues) {
            if (tag.value) {
              text = text.replaceAll(`[${tag.key}]`, tag.value);
            }
          }
        }

        editor.current.setEditorValue(text);
      });
    },

    setTextAreaRef: ref => {
      textArea.current = ref;
    },

    insertTag: tag => {
      if (!tag) return;
      let tagData;
      const tagValue = tagValues.find(item => item.key === tag);

      if (!tagValue || !tagValue.value || editor.current.getMode() === JODIT_CODE_MODE) {
        tagData = `[${tag}]`;
      } else {
        tagData = tagValue.value;
      }

      if (IMAGE_TAGS.includes(tag)) {
        editor.current.selection.insertHTML(
          `<img src="${tagData}" width="auto" height="100"></img>`
        );
      } else {
        editor.current.selection.insertHTML(`${tagData}`);
      }
    },

    getEditorConfig: disabled => {
      return {
        height: EDITOR_HEIGHT,
        maxHeight: EDITOR_HEIGHT,
        minHeight: EDITOR_HEIGHT,
        removeButtons: ['fullsize', 'print', 'dots', 'copyformat', 'image'],
        showCharsCounter: false,
        showWordsCounter: false,
        sourceEditorNativeOptions: { theme: 'white' },
        placeholder: '',
        readonly: disabled,
        cleanHTML: {
          fillEmptyParagraph: false,
        },
        style: {
          backgroundColor: 'white',
        },
        iframe: true,
      };
    },
  };

  return { actions };
}
