import React, { useContext } from 'react';
import BaseTable from 'Components/common/BaseTable/BaseTable';
import Colors from 'constants/Colors';
import { Button } from 'antd';
import { DownloadOutlined, EditFilled } from '@ant-design/icons';
import styles from './BillingContract.styles';
import { AppContext } from 'App.context.js';
import Loading from 'Components/common/Loader/Loading.jsx';
import { BillingListingContext } from '../BillingListing.context';
import moment from 'moment';
import { Tag } from 'antd';
import Icon from "@ant-design/icons";
import { ReactComponent as activeDot } from "assets/images/billing/activeDot.svg";
import { ReactComponent as inactiveDot } from "assets/images/billing/inactiveDot.svg";

export default function BillingContract() {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  
  const { state, actions } = useContext(BillingListingContext);

  const contractListColumns = [
    {
      title: translate('billingConfig.billingContract.table.id'),
      dataIndex: 'id',
      key: 'id',
      searchable: false,
      render: (_, row) => {
        return <div style={{ height: 20 }}>C{row.id}</div>;
      },
    },
    {
      title: translate('billingConfig.billingContract.table.name'),
      dataIndex: 'name',
      key: 'name',
      searchable: false,
      render: (_, row) => {
        return <div style={{ height: 20 }}>{row.name}</div>;
      },
    },
    {
      title: translate('billingConfig.billingContract.table.signatureDate'),
      dataIndex: 'signatureDate',
      key: 'signatureDate',
      searchable: false,
      render: (_, row) => {
        return <div style={{ height: 20 }}>{moment(row.signatureDate).utc().format('DD-MM-YYYY')}</div>;
      },
    },
    {
      title: translate('billingConfig.billingContract.table.status'),
      dataIndex: 'status',
      key: 'status',
      searchable: false,
      render: (_, row) => {
        return <div style={{ height: 20 }}>
          {row.active ? (
            <Tag style={styles.tagActive} icon={<Icon component={activeDot} />}>{translate('billingConfig.billingContract.active')}</Tag>
          ) : (
            <Tag style={styles.tagInactive} icon={<Icon component={inactiveDot} />}>{translate('billingConfig.billingContract.inactive')}</Tag>
          )}
          
        </div>;
      },
    },
    {
      title: translate('billingConfig.billingContract.table.actions'),
      dataIndex: 'actions',
      key: 'actions',
      searchable: false,
      render: (_, row) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: 8,
            }}
          >
            {state.isUserAdmin &&
              <Button
                style={{ width: 32, height: 32, color: Colors.actionColor }}
                type="text"
                icon={<EditFilled />}
                onClick={() => actions.onEditContractClicked(row)}
              />
            }
            <Button
              style={{ width: 32, height: 32, color: Colors.actionColor }}
              type="text"
              icon={<DownloadOutlined />}
              onClick={() => actions.onDownloadContractClicked(row)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <BaseTable
      columns={contractListColumns}
      data={state.contractList}
      loading={state.isLoading}
      locale={{ emptyText: (
        <div class="ant-empty ant-empty-normal">
          <div class="ant-empty-image">
            <svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse class="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g class="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" class="ant-empty-img-simple-path"></path></g></g></svg>
          </div>
          <div class="ant-empty-description" style={styles.noDataDiv}><div style={styles.noDataText}>{translate('billingConfig.billingContract.table.empty_contract')}. <a href="mailto:support-team@civitfun.com">{translate('billingConfig.billingContract.table.contact_support')}</a>.</div></div>
        </div>)}}
      defaultLimit={5}
      showSizeChanger={false}
      onChange={filters => actions.onChangePageContract(filters)}
    />
);
}
