import { useState } from 'react';
import moment from 'moment';

const useAutomaticBookingTest = inputConfig => {
  const [state, setState] = useState({
    inputs: inputConfig,
  });

  const actions = {
    getInputValue: key => {
      const input = state.inputs.find(item => item.key === key);
      return input.value || null;
    },

    setInputValue: (key, value) => {
      const updatedInputs = state.inputs.map(item => {
        if (item.key === key) {
          item.value = value;
        }

        return item;
      });

      updateState({ inputs: updatedInputs });
    },

    getBookingData: () => {
      let bookingData = {};

      for (const input of state.inputs) {
        bookingData[input.key] = input.value;
      }
      return bookingData;
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
};

export default useAutomaticBookingTest;
