import Colors from 'constants/Colors';

export default {
  title: {
    fontWeight: 'bold',
  },
  icon: {
    fontSize: 18,
    color: Colors.inputColor,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 10,
    border: `1px solid ${Colors.lightGray}`,
    alignItems: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  },
};
