import Colors from '../../../../constants/Colors';

export default {
  container: {
    borderRadius: 6,
    border: `1px solid ${Colors.lightGray}`,
  },

  header: {
    fontSize: 12,
    fontWeight: 'bold',
    color: Colors.darkText,
  },

  inputContainer: {
    borderRadius: 6,
  },

  input: {
    width: '100%',
  },
};
