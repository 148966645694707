export default {
  header: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    justifyContent: 'space-between',
    gap: 8,
    paddingRight: 32,
  },
  langSelectorContainer: {
    display: 'flex',
    marginLeft: 32,
    gap: 8,
    alignItems: 'center',
  },
  selector: {
    width: 152,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
  },
  editorContainer: {
    display: 'flex',
    gap: 32,
    flexDirection: 'column',
  },
};
