import React from 'react';
import { Input } from 'antd';
import styles from './MailTemplateInput.styles';

export default function MailTemplateInput({
  index,
  name,
  value,
  onChange,
  password,
  placeholder,
  ...props
}) {
  return (
    <div style={styles.container}>
      <b style={styles.header}>{name}</b>
      <div style={styles.inputContainer}>
        {password ? (
          <Input.Password
            bordered={false}
            value={value}
            onChange={e => {
              onChange && onChange(e.target.value);
            }}
            placeholder={placeholder}
            {...props}
          />
        ) : (
          <Input
            bordered={false}
            value={value}
            onChange={e => {
              onChange && onChange(e.target.value);
            }}
            placeholder={placeholder || name}
            {...props}
          />
        )}
      </div>
    </div>
  );
}
