import axios from 'axios';
import apiConfig from '../api.config';

export default function createGuestlink({ hotelId, item, applyToAllHotels }) {
  const {
    host,
    URIs: { createGuestlink },
  } = apiConfig;

  let uri = `${host}${createGuestlink}`.replace(':hotelId', hotelId);

  if (applyToAllHotels) {
    uri = `${uri}?applyToChain=true`;
  }

  return axios
    .post(
      uri,
      { item },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(res => {
      return res.data;
    });
}
