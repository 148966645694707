export default {
  tesa: {
    operatorName: 'Operator name',
    operatorPassword: 'Operator password',
    endpoint: 'Endpoint',
  },
  generic: {},
  ttlock: {
    username: 'Account',
    password: 'Password',
    clientId: 'Client ID',
    clientSecret: 'Client Secret',
  },
  'assa abloy': {
    token: 'Token',
  },
  yacan: {
    user: 'User',
    password: 'Password',
  },
  akiles: {
    apiKey: 'API Key',
  },
  'salto space': {
    address: 'Address',
    port: 'Port',
  },
  'salto ks': {
    username: 'User',
    password: 'Password',
    siteId: 'Site ID',
  },
  'omnitec rent&pass': {
    username: 'Username',
    password: 'Password',
    clientId: 'Client ID',
    clientSecret: 'Client Secret'
  }, 
  'omnitec os access': {
    instance: 'Instance',
    apikey: 'API Key',
    accessRightsPerArea: 'Rights per area',
    timezone: 'Timezone'
  },
  'padword': {
    username: 'User',
    password: 'Password',
  }, 
};
