import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button, Select } from 'antd';
import styles from './Onboarding.styles';
import featuredIcon from '../../../assets/images/onboarding/featuredIcon.png';
import iconConnectPMS from '../../../assets/images/onboarding/iconConnectPMS.png';
import useOnboarding from './Onboarding.hook';

import validationStyles from './validation.styles';
import guestSevice from '../../../utils/networking/api/validations/guest.service';

const { Option } = Select;

const Onboarding = ({ visible, onCancel, buttonAction, popUpImage, translate, ...props }) => {
  const [step, setStep] = useState(2);
  const [selectedValue, setSelectedValue] = useState('');
  const [pmsValues, setPmsValues] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const { getPms } = useOnboarding();

  const title = translate('onboarding.pms.title');
  const subtitle = translate('onboarding.pms.subtitle');
  const pmsConfiguration = translate('onboarding.pms.pmsConfiguration');
  const checkinForm = translate('onboarding.pms.checkinForm');
  const bookingSearch = translate('onboarding.pms.bookingSearch');
  const selectConfPms = translate('onboarding.pms.selectConfPms');
  const selectConfPmsDescription = translate('onboarding.pms.selectConfPmsDescription');
  const buttonText = translate('onboarding.pms.continue');

  useEffect(() => {
    if (!isDataFetched) {
      const fetchPMSValues = async () => {
        try {
          const values = await getPms();
          setPmsValues(values);
          setIsDataFetched(true);
        } catch (error) {
          console.error('Error fetching PMS values:', error);
        }
      };
      fetchPMSValues();
    }
  }, [isDataFetched, getPms]);

  const handleSelectChange = value => {
    setSelectedValue(value);
    // cargar el formulario basado en el valor seleccionado
  };

  const handleStepChange = stepNumber => {
    setStep(stepNumber);
  };

  return (
    <Modal open={visible} footer={null} onCancel={onCancel} width={'50%'} style={{}}>
      <div style={styles.modalContent}>
        <div style={styles.headerContainer}>
          <img src={featuredIcon} alt="conect" style={styles.stepImage} />
          <div>
            <h1 style={styles.title}>{title}</h1>
            <h2 style={styles.subtitle}>{subtitle}</h2>
          </div>
        </div>

        <div style={styles.stepsContainer}>
          <div
            style={step === 1 ? { ...styles.step, ...styles.stepActive } : styles.step}
            onClick={() => handleStepChange(1)}
          >
            <div
              style={
                step === 1
                  ? { ...styles.stepIcon, ...styles.stepIconActive }
                  : { ...styles.stepIcon, ...styles.stepIconInactive }
              }
            >
              1
            </div>
            {pmsConfiguration}
          </div>
          <div
            style={step === 2 ? { ...styles.step, ...styles.stepActive } : styles.step}
            onClick={() => handleStepChange(2)}
          >
            <div
              style={
                step === 2
                  ? { ...styles.stepIcon, ...styles.stepIconActive }
                  : { ...styles.stepIcon, ...styles.stepIconInactive }
              }
            >
              2
            </div>
            {checkinForm}
          </div>
          <div
            style={step === 3 ? { ...styles.step, ...styles.stepActive } : styles.step}
            onClick={() => handleStepChange(3)}
          >
            <div
              style={
                step === 3
                  ? { ...styles.stepIcon, ...styles.stepIconActive }
                  : { ...styles.stepIcon, ...styles.stepIconInactive }
              }
            >
              3
            </div>
            {bookingSearch}
          </div>
        </div>

        <div style={styles.stepContent}>
          {step === 1 && (
            <div style={styles.stepDetails}>
              <img src={iconConnectPMS} alt="conect" style={styles.stepImage} />
              <h3 style={styles.stepTitle}>{selectConfPms}</h3>
              <p style={styles.stepSubtitle}>{selectConfPmsDescription}</p>
              <div style={{ position: 'relative' }}>
                <Select
                  style={{
                    ...styles.selector,
                    ...(selectedValue ? styles.selectWithValue : {}),
                  }}
                  placeholder={<span style={styles.placeholder}>PMS</span>}
                  onChange={handleSelectChange}
                >
                  {pmsValues.map(pms => (
                    <Option
                      key={pms.id}
                      value={pms.id}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <img src={pms.logo} alt="logo" style={styles.logoPMS} />
                      <span style={{ marginLeft: '10px' }}>{pms.name}</span>
                    </Option>
                  ))}
                </Select>
              </div>
              <div style={styles.formContainer}>
                {selectedValue && <form>{/* Formulario aquí */}</form>}
              </div>
              <Button
                type="primary"
                onClick={() => handleStepChange(2)}
                style={styles.continueButton}
              >
                {buttonText}
              </Button>
            </div>
          )}

          {step === 2 && (
            <div style={styles.stepDetails}>
              {/* Contenido para el Paso 2 */}
              <h3 style={styles.stepTitle}>Paso 2</h3>
              <p style={styles.stepSubtitle}>Descripción del Paso 2</p>
              <Button
                type="primary"
                onClick={() => handleStepChange(3)}
                style={styles.continueButton}
              >
                {buttonText}
              </Button>
            </div>
          )}

          {step === 3 && (
            <div style={styles.stepDetails}>
              {/* Contenido para el Paso 3 */}
              <h3 style={styles.stepTitle}>Paso 3</h3>
              <p style={styles.stepSubtitle}>Descripción del Paso 3</p>
              <Button type="primary" onClick={onCancel} style={styles.continueButton}>
                Finalizar
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Onboarding;
