import axios from 'axios';
import apiConfig from '../api.config';

export default function doorOpeningImportToHotelRequest({ hotelId, file }) {
  const {
    host,
    URIs: { importDoorOpeningToHotel },
  } = apiConfig;

  const uri = `${host}${importDoorOpeningToHotel.replace(':hotelId', hotelId)}`;

  const data = new FormData();
  data.append('file', file);

  return axios
    .post(uri, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
