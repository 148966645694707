import React, { useContext } from 'react';
import styles from './PaymentSelector.style';
import { Select } from 'antd';
import { AppContext } from '../../../../App.context';
import getPMSLogo from 'utils/getPMSLogo';
import getPaymentLogo from 'utils/getPaymentLogo';

export default function PaymentSelector({
  row,
  paymentsList,
  selectedPayment,
  setSelectedPayment,
  disabled,
}) {
  const { Option } = Select;
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const selectedPaymentName = selectedPayment?.name;

  return (
    <div style={styles.container}>
      <div style={styles.selector}>
        <Select
          onChange={option => setSelectedPayment(row.id, option)}
          bordered={false}
          style={{ width: '100%' }}
          disabled={disabled}
          value={selectedPayment ? selectedPaymentName : translate('paymentScreen.defaultSelector')}
        >
          {paymentsList?.map((payment, index) => {
            const paymentTranslation = translate(`paymentName.${payment.name.toLowerCase()}`);
            const paymentName = paymentTranslation.includes('.')
              ? payment.name
              : paymentTranslation;
            return (
              <Option key={index} value={payment.name}>
                {paymentName}
              </Option>
            );
          })}
        </Select>
      </div>
      <img
        src={getPaymentLogo(selectedPayment?.name)}
        style={{ alignSelf: 'center', height: 36, borderRadius: 6 }}
      />
    </div>
  );
}
