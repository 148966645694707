import React, { useContext } from 'react';
import CustomButton from 'Components/common/CustomButton/CustomButton';
import getPMSLogo from 'utils/getPMSLogo';
import Colors from 'constants/Colors';
import styles from './PMSListItem.styles';
import { AppContext } from 'App.context';

export default function PMSListItem({ pms, isLastItem, onItemSelected }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const logo = getPMSLogo(pms?.name);

  return (
    <div
      style={{
        ...styles.pmsItemContainer,
        borderBottom: isLastItem ? null : `1px solid ${Colors.lightGray}`,
      }}
    >
      {logo ? (
        <img src={logo} style={styles.logo} />
      ) : (
        <div style={styles.pmsName}>{pms?.name}</div>
      )}
      <CustomButton
        background={Colors.pmsConnect}
        color={'white'}
        text={translate('selectPMS.button')}
        onClick={() => onItemSelected(pms?.name)}
      />
    </div>
  );
}
