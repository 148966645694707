import { notification } from 'antd';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../App.context';
import { ApiContext } from '../../../utils/networking/Api.context';

export default function useAppearenceHook() {
  const { API } = useContext(ApiContext);
  const { appState, appActions } = useContext(AppContext);
  const { translate, getSelectedHotel } = appActions;
  const [state, setState] = useState({
    hotel: null,
    isLoading: false,
    logo: null,
    bgColor: null,
    color1: null,
    color2: null,
    font: null,
    kioskBgColor: null,
    kioskTitleColor: null,
    kioskLogo: null,
    chainLogo: null,
    chainKioskLogo: null,
    chainId: null,
    sliderImages: [],
  });

  useEffect(() => {
    actions.onHotelSelected(getSelectedHotel());
  }, [appState.selectedHotel]);

  const actions = {
    loadAppearence: hotelId => {
      updateState({ isLoading: true });
      API.appearenceRequest(hotelId)
        .then(res => {
          updateState({
            isLoading: false,
            color1: res?.color1 || 'fff',
            color2: res?.color2 || 'fff',
            bgColor: res['bg_color'] || 'fff',
            logo: res?.logo,
            kioskBgColor: res['kiosk_bg_color'] || 'fff',
            kioskTitleColor: res['kiosk_title_color'] || 'fff',
            kioskLogo: res['kiosk_logo'],
            chainLogo: res['chain_logo'],
            chainKioskLogo: res['chain_kiosk_log'],
            chainId: res['chain_id'],
            sliderImages: res.sliderImages,
          });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
          notification.error({
            message: err?.response?.data?.message,
          });
        });
    },

    updateApearence: applyToAllHotels => {
      updateState({ isLoading: true });
      API.updateAppearenceRequest({
        hotelId: applyToAllHotels ? null : state.hotel?.key,
        bgColor: state.bgColor,
        color1: state.color1,
        color2: state.color2,
        font: state.font,
        selectedHotelId: state.hotel?.key,
      })
        .then(_ => {
          notification.success({
            message: translate('appearence.success'),
          });
          updateState({ isLoading: false });
        })
        .catch(error => {
          updateState({ isLoading: false });
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    },

    updateKiosk: applyToAllHotels => {
      updateState({ isLoading: true });
      API.updateAppearenceRequest({
        hotelId: applyToAllHotels ? null : state?.hotel?.key,
        kioskBgColor: state.kioskBgColor,
        kioskTitleColor: state.kioskTitleColor,
        selectedHotelId: state.hotel?.key,
        isKiosk: true,
      })
        .then(_ => {
          notification.success({
            message: translate('appearence.success'),
          });
          updateState({ isLoading: false });
        })
        .catch(error => {
          notification.error({
            message: error?.response?.data?.message,
          });
          updateState({ isLoading: false });
        });
    },

    updateChain: () => {
      updateState({ isLoading: true });
      API.updateChainAppearenceRequest({
        chainId: state.chainId,
        logo: state.chainLogo,
      })
        .then(_ => {
          notification.success({
            message: translate('appearence.success'),
          });
          updateState({ isLoading: false });
        })
        .catch(error => {
          updateState({ isLoading: false });
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    },

    onHotelSelected: hotel => {
      updateState({ hotel: hotel });
      if (hotel?.key && hotel?.key != 'null') {
        actions.loadAppearence(hotel.key);
      }
    },

    updateValue: (answerId, value) => {
      updateState({ [answerId]: value });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
