import { Select } from 'antd';
import Colors from 'constants/Colors.js';
import React, { useEffect, useState } from 'react';
import { removeAccents } from 'utils/TextUtils.js';
import styles from './AppSelector.styles.js';

export default function AppSelector({
  title,
  value,
  options,
  onOptionSelected,
  isLoading,
  menu,
  hint,
  style,
  disabled,
  searchable,
  hasError,
  mode
}) {
  const [error, setError] = useState(hasError);

  useEffect(() => {
    setError(hasError);
  }, [hasError]);

  return (
    <div style={style}>
      {title && <div style={styles.title}>{title}</div>}
      <div style={{ ...styles.container, boxShadow: error ? styles.errorShadow.boxShadow : null }}>
        <Select
          value={value}
          style={{ ...styles.select, color: disabled ? Colors.grayText : Colors.darkText }}
          options={options}
          onChange={value => {
            error && setError(false);
            onOptionSelected(value);
          }}
          bordered={false}
          loading={isLoading}
          placeholder={hint || title}
          disabled={disabled}
          showSearch={searchable}
          mode={mode}
          filterOption={(inputValue, option) => {
            const normalizedInput = removeAccents(inputValue).toLowerCase();
            const normalizedOption = removeAccents(option.label).toLowerCase();
            return normalizedOption.includes(normalizedInput);
          }}
        >
          {menu && menu}
        </Select>
      </div>
    </div>
  );
}
