import React, { useContext, useState, useEffect } from 'react';
import styles from './BillingInfoBox.styles.js';
import classStyles from './BillingInfoBox.module.css';
import AppInput from 'Components/common/AppInput/AppInput.jsx';
import { AppContext } from 'App.context.js';
import Loading from 'Components/common/Loader/Loading.jsx';
import AppButton from 'Components/common/Forms/AppButton/AppButton.jsx';
import { BillingInfoContext } from '../BillingInfo.context.js';
import AppSelector from 'Components/common/AppSelector/AppSelector.jsx';
import { AutoComplete } from 'antd';

export default function BillingInfo() {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useContext(BillingInfoContext);

  const [searchText, setSearchText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    const options = actions.getCountryCodesOptions();
  
    if (initialLoad) {
      setFilteredOptions(
        options.filter(option => option.label.toLowerCase().includes(searchText.toLowerCase()))
      );      
    } else {
      setFilteredOptions(
        options.filter(option => option.label.toLowerCase().includes(state.companyCountryCode)))
    }

    if (state.companyCountryCode && !initialLoad) {
      const newCountry = options.find(option => option.value === state.companyCountryCode);
      if (newCountry && newCountry.label !== searchText) {
        setSearchText(newCountry.label);
      } 
    }
  
    if (initialLoad && state.companyCountryCode) {
      const initialCountry = options.find(option => option.value === state.companyCountryCode);
      if (initialCountry) {
        setSearchText(initialCountry.label);
      }
      setInitialLoad(false);  
    }

    if (state.companyCountryCode === '' && !initialLoad) {
      setSearchText('');
    }

  }, [state.companyCountryCode, initialLoad]);  
  
  const handleCountrySelect = (value, option) => {
    setSearchText(option.label);  
    actions.onChangeField('companyCountryCode', value); 
  };
  
  const handleInputChange = value => {
    setSearchText(value);
    const options = actions.getCountryCodesOptions();
    setFilteredOptions(
      options.filter(option => option.label.toLowerCase().includes(value.toLowerCase()))
    );
  };
  
  
  
  

  return (
    <div style={styles.boxSize}>
      <div style={styles.formData}>
        <div style={styles.formSection}>
          <div
            style={styles.sectionTitle}
            className={
              state.isEditing && state.errors.includes('companyLegalName')
                ? classStyles.titleWithError
                : ''
            }
          >
            {translate('billingConfig.billingInfo.companyLegalName')}
          </div>
          <div style={styles.inputContainer}>
            <AppInput
              value={state.companyLegalName}
              name={translate('billingConfig.billingInfo.companyLegalName')}
              hint={translate('billingConfig.billingInfo.companyLegalName_hint')}
              onChange={value => actions.onChangeField('companyLegalName', value)}
              disabled={actions.isFormFieldDisabled()}
              hasError={state.isEditing && state.errors.includes('companyLegalName')}
            />
          </div>
        </div>
        <div style={styles.formSection}>
          <div
            style={styles.sectionTitle}
            className={
              state.isEditing && state.errors.includes('companyCommercialName')
                ? classStyles.titleWithError
                : ''
            }
          >
            {translate('billingConfig.billingInfo.companyCommercialName')}
          </div>
          <div style={styles.inputContainer}>
            <AppInput
              value={state.companyCommercialName}
              name={translate('billingConfig.billingInfo.companyCommercialName')}
              hint={translate('billingConfig.billingInfo.companyCommercialName_hint')}
              onChange={value => actions.onChangeField('companyCommercialName', value)}
              disabled={actions.isFormFieldDisabled()}
              hasError={state.isEditing && state.errors.includes('companyCommercialName')}
            />
          </div>
        </div>
      </div>

      <div style={styles.formData}>
        <div style={styles.formSection}>
          <div
            style={styles.sectionTitle}
            className={
              state.isEditing && state.errors.includes('billingAddress')
                ? classStyles.titleWithError
                : ''
            }
          >
            {translate('billingConfig.billingInfo.billingAddress')}
          </div>
          <div style={styles.inputContainer}>
            <AppInput
              value={state.billingAddress}
              name={translate('billingConfig.billingInfo.billingAddress')}
              hint={translate('billingConfig.billingInfo.billingAddress_hint')}
              onChange={value => actions.onChangeField('billingAddress', value)}
              disabled={actions.isFormFieldDisabled()}
              hasError={state.isEditing && state.errors.includes('billingAddress')}
            />
          </div>
        </div>

        <div style={styles.formSection}>
          <div
            style={styles.sectionTitle}
            className={
              state.isEditing && state.errors.includes('billingMunicipality')
                ? classStyles.titleWithError
                : ''
            }
          >
            {translate('billingConfig.billingInfo.billingMunicipality')}
          </div>
          <div style={styles.inputContainer}>
            <AppInput
              value={state.billingMunicipality}
              name={translate('billingConfig.billingInfo.billingMunicipality')}
              hint={translate('billingConfig.billingInfo.billingMunicipality_hint')}
              onChange={value => actions.onChangeField('billingMunicipality', value)}
              disabled={actions.isFormFieldDisabled()}
              hasError={state.isEditing && state.errors.includes('billingMunicipality')}
            />
          </div>
        </div>
      </div>

      <div style={styles.formData}>
      <div style={styles.formSection}>
          <div
            style={styles.sectionTitle}
            className={
              state.isEditing && state.errors.includes('billingProvince')
                ? classStyles.titleWithError
                : ''
            }
          >
            {translate('billingConfig.billingInfo.billingProvince')}
          </div>
          <div style={styles.inputContainer}>
            <AppInput
              value={state.billingProvince}
              name={translate('billingConfig.billingInfo.billingProvince')}
              hint={translate('billingConfig.billingInfo.billingProvince_hint')}
              onChange={value => actions.onChangeField('billingProvince', value)}
              disabled={actions.isFormFieldDisabled()}
              hasError={state.isEditing && state.errors.includes('billingProvince')}
            />
          </div>
        </div>

        <div style={styles.formSection}>
          <div
            style={styles.sectionTitle}
            className={
              state.isEditing && state.errors.includes('companyCountryCode')
                ? classStyles.titleWithError
                : ''
            }
          >
            {translate('billingConfig.billingInfo.companyCountryCode')}
          </div>
          <div style={styles.inputContainer}>
            <AutoComplete
              style={styles.companyCountrySelector}
              placeholder={translate('billingConfig.billingInfo.companyCountryCode')}
              hint={translate('billingConfig.billingInfo.companyCountryCode_hint')}
              onChange={handleInputChange}
              value={searchText}
              options={filteredOptions}
              onSelect={handleCountrySelect}
              disabled={actions.isFormFieldDisabled()}
            >
              <input
                style={{
                  ...styles.inputCompanyCountry,
                  color: actions.isFormFieldDisabled() ? '#6b6b6b' : 'inherit', 
                }}
                disabled={actions.isFormFieldDisabled()}
              />
            </AutoComplete>
          </div>
        </div>
      </div>

      <div style={styles.formData}>
        <div style={styles.formSection}>
          <div
            style={styles.sectionTitle}
            className={
              state.isEditing && state.errors.includes('taxIdentificationNumber')
                ? classStyles.titleWithError
                : ''
            }
          >
            {translate('billingConfig.billingInfo.taxIdentificationNumber')}
          </div>
          <div style={styles.inputContainer}>
            <AppInput
              value={state.taxIdentificationNumber}
              name={translate('billingConfig.billingInfo.taxIdentificationNumber')}
              hint={translate('billingConfig.billingInfo.taxIdentificationNumber_hint')}
              onChange={value => actions.onChangeField('taxIdentificationNumber', value)}
              disabled={actions.isFormFieldDisabled()}
              hasError={state.isEditing && state.errors.includes('taxIdentificationNumber')}
            />
          </div>
        </div>

        <div style={styles.formSection}>
          <div
            style={styles.sectionTitle}
            className={
              state.isEditing && state.errors.includes('billingContactName')
                ? classStyles.titleWithError
                : ''
            }
          >
            {translate('billingConfig.billingInfo.billingContactName')}
          </div>
          <div style={styles.inputContainer}>
            <AppInput
              value={state.billingContactName}
              name={translate('billingConfig.billingInfo.billingContactName')}
              hint={translate('billingConfig.billingInfo.billingContactName_hint')}
              onChange={value => actions.onChangeField('billingContactName', value)}
              disabled={actions.isFormFieldDisabled()}
              hasError={state.isEditing && state.errors.includes('billingContactName')}
            />
          </div>
        </div>
      </div>

      <div style={styles.formData}>
        <div style={styles.formSection}>
          <div
            style={styles.sectionTitle}
            className={
              state.isEditing && state.errors.includes('billingContactEmail')
                ? classStyles.titleWithError
                : ''
            }
          >
            {translate('billingConfig.billingInfo.billingContactEmail')}
          </div>
          <div style={styles.inputContainer}>
            <AppSelector
              mode="tags"
              style={styles.selector}
              name={translate('billingConfig.billingInfo.billingContactEmail')}
              hint={translate('billingConfig.billingInfo.billingContactEmail_hint')}
              value={actions.getBillingContactEmailOptions()}
              options={actions.getBillingContactEmailOptions()}
              onOptionSelected={value => actions.onChangeField('billingContactEmail', value)}
              disabled={actions.isFormFieldDisabled()}
              hasError={state.isEditing && state.errors.includes('billingContactEmail')}
            />
            {state.isEditing && state.errors.includes('billingContactEmail') ? (
              <div style={styles.errorSubmessage}>
                *{translate('billingConfig.billingInfo.invalidEmailError')}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div style={styles.formSection}>
          <div
            style={styles.sectionTitle}
            className={
              state.isEditing && state.errors.includes('billingPhoneNumber')
                ? classStyles.titleWithError
                : ''
            }
          >
            {translate('billingConfig.billingInfo.billingPhoneNumber')}
          </div>
          <div style={styles.inputContainer}>
            <AppInput
              value={state.billingPhoneNumber}
              name={translate('billingConfig.billingInfo.billingPhoneNumber')}
              hint={translate('billingConfig.billingInfo.billingPhoneNumber_hint')}
              onChange={value => actions.onChangeField('billingPhoneNumber', value)}
              disabled={actions.isFormFieldDisabled()}
              hasError={state.isEditing && state.errors.includes('billingPhoneNumber')}
            />
          </div>
        </div>
      </div>

      <div style={styles.buttonContainer}>
        {state.isLoading && <Loading size={'small'} />}
        {state.isEditing ? (
          <>
            {!actions.isFormEmpty() && (
              <AppButton
                text={translate('billingConfig.billingInfo.cancelButton')}
                action={() => actions.editCancel()}
                disabled={state.isLoading}
                secondary={true}
              />
            )}
            <AppButton
              text={translate('billingConfig.billingInfo.saveButton')}
              action={() => actions.onSaveBillingInfo()}
              disabled={state.isLoading || !state.selectedHotel}
            />
          </>
        ) : (
          <AppButton
            text={translate('billingConfig.billingInfo.editButton')}
            action={() => actions.onEditBillingInfo()}
            disabled={state.isLoading}
          />
        )}
      </div>
    </div>
  );
}
