import axios from 'axios';
import apiConfig from '../api.config';

export default function policeReportCheckSESConnection(data) {
  const {
    policeReportIntegrationHost,
    URIs: { policeReportCheckSESConnection },
  } = apiConfig;

  const uri = `${policeReportIntegrationHost}${policeReportCheckSESConnection}`;

  const reportData = {
    "type": "SPAINPOLICE",
    "country": "ES",
    "hotelId": data.hotelId,
    "headers": {
      "codigoArrendador": data.sesCredentials.lessorCode,
      "aplicacion": "CIVITFUN TOURISM S.L.",
      "codigoEstablecimiento": data.sesCredentials.establishmentCode,
    },
    "credentials": {
      "username": data.sesCredentials.username,
      "password": data.sesCredentials.password,
    },
    "bookings": [
      {
        "pms_identifier": "1001",
        "code": "1001",
        "status": 1,
        "guest": "John Doe",
        "email": "jcabrera+4@civitfun.com",
        "phone": "34 123123123",
        "countryCode": "ES",
        "entrance": "2024-07-01",
        "entranceTime": "14:00",
        "departure": "2024-07-07",
        "departureTime": "12:00",
        "adults": 1,
        "children": 0,
        "babies": 0,
        "amount": 0,
        "depositAmount": 0,
        "holderName": "John",
        "holderSurname": "Doe",
        "guests": [
          {
            "PmsIdentifier": "9009",
            "Position": 1,
            "Email": "john@email.com",
            "Language": "ES",
            "Name": "John",
            "Surname": "Doe",
            "SecondSurname": "",
            "Gender": "M",
            "BirthDate": "1978-06-11",
            "DocumentType": "NIE",
            "Nationality": "ES",
            "DocumentNumber": "123456789",
            "ExpeditionDate": "2022-01-01",
            "ExpirationDate": "2025-01-01",
            "AssignedRoom": "room-code",
            "PersonType": "person-type-sample",
            "CustomFields": {
              "address": "my-adress",
              "municipalityCode": "12345",
              "zipCode": "12345",
              "country": "ESP"
            },
            "LegalFiedls": []
          }
        ]
      }
    ]
  };

  const uninterceptedAxiosInstance = axios.create();
  return uninterceptedAxiosInstance
    .post(uri, JSON.stringify(reportData), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: '+t7wIM6!-d7SEz$/?IG&7aHRkZvN#9',
      },
    })
    .then(res => {
      console.log(res);
      return res.data;
    });
}
