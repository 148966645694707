export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
    padding: 0,
    marginRight: 44,
    marginLeft: 44,
  },
  icon: {
    fill: '#2D5BFF',
  },
  text: {
    fontSize: 13,
    fontWeight: 400,
    color: '#181818',
  },
  subText: {
    fontSize: 13,
    fontWeight: 400,
    color: '#979797',
  },
};
