export default `<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:21px;font-family:&quot;Calibri&quot;,sans-serif;">Termini e condizioni&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:18px;font-family:&quot;Calibri&quot;,sans-serif;">INFORMAZIONI LEGALI&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;">
    <span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">
        Per la tua sicurezza, garantiamo che i dati della tua carta di credito saranno trattati in conformità agli standard internazionali PCI-DSS. Autorizzo l'hotel a addebitare le spese totali del mio soggiorno all'hotel sulla carta di credito sopra menzionata. Nel caso in cui lasci l'hotel in una data precedente a quella indicata nella prenotazione presente in questo documento, autorizzo l'addebito dell'importo totale delle notti contrattate, comprese quelle non utilizzate, sulla carta di credito.<br><br>
        Presso [hotel_name], ci impegniamo a proteggere la tua privacy. Puoi contattarci a [address] o all'indirizzo email [hotel_email]. [hotel_name] elaborerà i tuoi dati per gestire il tuo soggiorno in hotel e migliorare la tua esperienza come ospite. Inoltre, [hotel_name] elaborerà i tuoi dati per fornire servizi di alloggio e servizi accessori. Potremmo dover elaborare i tuoi dati per rispettare gli obblighi legali a cui siamo soggetti (inclusa la prevenzione delle frodi). [hotel_name] potrebbe comunicare i tuoi dati a terzi, come banche o altre istituzioni finanziarie per elaborare pagamenti o a enti di sicurezza per ottemperare agli obblighi imposti dalla legge.<br><br>
        Se ti registri, [hotel_name] elaborerà i tuoi dati personali per fornirti i rispettivi servizi di fedeltà. In questo senso, elaboreremo i punti generati e potremmo inviarti informazioni sui tuoi punti e sui benefici del programma, adattati al tuo profilo.<br><br>
        Puoi esercitare i tuoi diritti di accesso, rettifica, cancellazione, opposizione e/o limitazione del trattamento, nonché la portabilità dei tuoi dati, scrivendo a [hotel_name] all'indirizzo postale o email sopra indicato. Dovrai dimostrare la tua identità e indicare in entrambi i casi (a) "Protezione dei dati" come oggetto e (b) se la tua richiesta è indirizzata a [hotel_name]. Inoltre, puoi contattare in qualsiasi momento l'autorità nazionale di controllo competente.<br><br>
        Per ulteriori informazioni, visita [hotel_web] o chiedi alla reception dell'hotel.
    </span>
</p>`;
