import axios from 'axios';
import apiConfig from '../api.config';

export default function getOneHotel({ hotelId }) {
  const {
    host,
    URIs: { getOneHotel },
  } = apiConfig;

  const uri = `${host}${getOneHotel}`;

  return axios
    .get(`${uri}${hotelId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
