import React, { useContext, useState } from 'react';
import styles from './ImageInput.module.css';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { translate } from '../../../../../modules/localization/Translations';
import constants from '../../../../../utils/constants';
import { ApiContext } from '../../../../../utils/networking/Api.context';
import { AppContext } from '../../../../../App.context';

export default function ImageInput({
  name,
  description,
  value,
  id,
  setValue,
  hotelId,
  type,
  chainId,
}) {
  const [loading, setLoading] = useState(false);
  const { API } = useContext(ApiContext);
  const {
    appState: { hotelList },
  } = useContext(AppContext);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{translate('upload')}</div>
    </div>
  );

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  function handleChange(info) {
    setLoading(true);

    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      if (chainId) {
        setValue(id, `${constants.IMAGE_HOST}?chain=${chainId}`);
      } else {
        var fileName = `${constants.IMAGE_HOST}?hotel=${hotelId}`;
        if (type === 'kiosk') fileName = `${fileName}&type=kioskLogo`;
        setValue(id, fileName);
      }
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <b className={styles.title}>{name}</b>
        {description && <div className={styles.description}>{description}</div>}
      </div>
      <div>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          accept="image/*"
          customRequest={API.uploadImageRequest}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          data={{ hotelId, type, chainId, hotelList }}
        >
          {value ? (
            <img src={`${value}&${performance.now()}`} alt="" style={{ width: '100%' }} />
          ) : (
            uploadButton
          )}
        </Upload>
      </div>
    </div>
  );
}
