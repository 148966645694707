import defaultRenderEmpty from 'antd/lib/config-provider/defaultRenderEmpty';
import { useState, useContext } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import { groupByKey } from 'utils/TextUtils';

export default function usePaymentFormHook(updateEnabledHotels) {
  const [state, setState] = useState({
    isLoading: false,
    data: null,
    orderedConfig: {},
  });

  const { API } = useContext(ApiContext);

  const actions = {
    loadPaymentConfig: (payment, hotelId) => {
      console.log(payment, hotelId);
      updateState({ isLoading: true });

      API.getHotelPaymentConfig({ platform: payment.name, hotelId })
        .then(res => {
          const orderedConfig = groupByKey(res, 'category');
          updateState({ data: res, orderedConfig, isLoading: false });
        })
        .catch(err => {
          updateState({ isLoading: false });
        });
    },

    onValueChanged: (key, text, splitValues) => {
      const dataCopy = state.data;
      for (const field of dataCopy) {
        if (field.key === key) {
          field.value = text;
          if (splitValues) {
            field.splitValues = splitValues;
          }
        }
      }

      updateState({ data: dataCopy });
    },

    getFieldValue: key => {
      const index = state.data.findIndex(x => x.key === key);
      return state.data[index]?.value;
    },

    onSave: (payment, hotelId) => {
      updateState({ isLoading: true });
      const parameters = actions.formatParameters(state.data);
      API.updateHotelPaymentConfig({ hotelId, platform: payment.name, parameters })
        .then(res => {
          updateState({ isLoading: false });
          updateEnabledHotels && updateEnabledHotels();
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },

    formatParameters: data => {
      const parameters = {};
      for (const field of data) {
        parameters[field.key] = field.value;
      }

      return parameters;
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
