import React, { useContext } from 'react';
import DefaultLayout from '../../DefaultLayout/DefaultLayout';
import useHotels from './Hotels.hook';
import ContentBox from '../../common/ContentBox/ContentBox';
import HotelList from './HotelList/HotelList';
import HotelDetail from './HotelDetail/HotelDetail';
import { HotelsContext } from './Hotels.context';
import { AppContext } from '../../../App.context';
import FeatModal from 'Components/common/FeatModal/FeatModal';
import Onboarding from 'Components/Dashboard/Onboarding/Onboarding';

export default function Hotels() {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const { hotelsState, hotelsActions } = useHotels();
  const popUpImage = require('../../../assets/images/conecta_pms.png');

  return (
    <DefaultLayout title={translate('layout.hotels')} showHotelSelector={false}>
      <HotelsContext.Provider value={{ hotelsState, hotelsActions }}>
        <ContentBox title={translate('hotels.addNewHotel')}>
          <HotelDetail />
        </ContentBox>
        <ContentBox title={translate('hotels.title')} noPadding>
          <HotelList />
        </ContentBox>
        <FeatModal
          title={translate('pmsPopup.title')}
          subtitle={translate('pmsPopup.subtitle')}
          info1={translate('pmsPopup.legalTextNewHotel')}
          info2={translate('pmsPopup.text')}
          buttonText={translate('pmsPopup.button')}
          visible={hotelsState.showPmsPopup}
          onCancel={() => hotelsActions.setShowPmsPopup(false)}
          buttonAction={() => hotelsActions.goToPmsScreen()}
        />
        {/*
<Onboarding
          translate={translate}
          visible={hotelsState.showPmsPopup}
          onCancel={() => hotelsActions.setShowPmsPopup(false)}
          buttonAction={() => hotelsActions.goToPmsScreen()}
          popUpImage={popUpImage}
        />
       */}
      </HotelsContext.Provider>
    </DefaultLayout>
  );
}
