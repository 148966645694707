import axios from 'axios';
import apiConfig from '../api.config';
import constants from '../../constants';
import storage from '../../storage';

export default function editHotelContract({ hotelId, contract }) {
  const {
    billingHost,
    URIs: { editHotelContract },
  } = apiConfig;

  const uri = `${billingHost}${editHotelContract}`.replace(':hotelContractId', contract.id);

  const storedCredentials = JSON.parse(storage.get(constants.AUTH.USER_KEY));
  
  const form = new FormData();
  if ( contract.fileToUpload ) {
    form.append('contract_file', contract.fileToUpload);
  }
  form.append('data[name]', contract.name);
  form.append('data[hotelId]', hotelId);
  form.append('data[hotelReference]', 'civitfun');
  if ( contract.orderNumber ) {
    form.append('data[orderNumber]', contract.orderNumber);
  }
  form.append('data[signatureDate]', contract.signatureDate.format('YYYY-MM-DD'));
  form.append('data[accessToken]', storedCredentials.accessToken);

  return axios
    .put(uri, form)
    .then(res => {
      return res.data;
    });
}
