import * as zlib from 'zlib';

function compress(data) {
  if(data == null) { return null; }
  const input =  Buffer.from(JSON.stringify(data), 'utf8');
  return zlib.deflateSync(input).toString('base64');
}

function decompress(input) {
  if(str == null) { return null; }
  const str = zlib.inflateSync(Buffer.from(str, 'base64')).toString('utf8');
  return JSON.parse(str);
}

export {compress, decompress};