export default {
  PAYMENT_TYPE: ['EFECT', 'TARJT', 'TRANS'],
  ROLE_TYPE: ['TI', 'VI'],
  CONTACT_TYPE: ['email', 'telefono'],
  DOCUMENT_TYPE: ['dni', 'passport', 'idCard', 'residencePermit', 'drivingLicence', 'other'],
  MUNICIPALITIES: require('./municipalities.json'),
  FAMILY_RELATIONSHIP: require('./relationship.json'),
  COUNTRIES: require('./countries.json'),
  ADULT_AGE: 14,
  API_DATE_FORMAT: 'YYYY-MM-DD',
  POLICE_REPORT_SCHEDULE_TYPE: 'pre-stay-police-report-notify-report-error',
};
