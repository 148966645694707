import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Image, Spin } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../App.context';
import styles from './DefaultHeader.module.css';
import CustomButton from '../../common/CustomButton/CustomButton';
import LanguageSelector from '../../common/LanguageSelector/LanguageSelector';
import Colors from 'constants/Colors';
import HotelSelector from 'Components/common/HotelSelector/HotelSelector';
import QuickguideButton from './QuickguideButton/QuickguideButton';

export default function DefaultHeader({
  sliderCollapsed,
  setSliderCollapsed,
  breadCrumb,
  showHotelSelector = true,
  enableAllHotels,
}) {
  const { appState, appActions } = useContext(AppContext);

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <img
          src={require('../../../../src/assets/images/logo.png')}
          style={{ height: 38, paddingRight: 16 }}
        />
        <Button
          type={'text'}
          icon={
            sliderCollapsed ? (
              <MenuUnfoldOutlined style={{ fontSize: 20, color: Colors.white }} />
            ) : (
              <MenuFoldOutlined style={{ fontSize: 20, color: Colors.white }} />
            )
          }
          onClick={() => setSliderCollapsed(!sliderCollapsed)}
        />
      </div>
      <div className={styles.rightContainer}>
        {/* <p className={styles.pageTitle}>{breadCrumb}</p> */}
        {showHotelSelector ? (
          <HotelSelector enableAllHotels={enableAllHotels}></HotelSelector>
        ) : (
          <div
            style={{
              display: 'flex',
              marginRight: 16,
              marginLeft: 32,
              gap: 8,
            }}
          />
        )}
        <div className={styles.buttonsContainer}>
          {/* {appState.chain?.name ? (
            <div className={styles.chainName}>{appState.chain.name}</div>
          ) : null} */}
          {/* <QuickguideButton /> */}
          <LanguageSelector />
          {appState.exporting && (
            <CustomButton text={`Generando exportación...  `} rightComponent={<Spin />} />
          )}

          {appState.superUser && (
            <CustomButton
              text={appActions.translate('superUser.changeAccount')}
              background={Colors.superUserButton}
              color={'white'}
              onClick={() => {
                appActions.superUserChangeChain();
              }}
            />
          )}

          <CustomButton
            text={appActions.translate('logout')}
            background={'#e44646'}
            color={'white'}
            onClick={() => {
              appActions.logout();
            }}
          />
        </div>
      </div>
    </div>
  );
}
