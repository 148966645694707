import React, { useContext } from 'react';
import styles from './IPWhitelistInput.styles.js';
import { Input } from 'antd';
import { AppContext } from 'App.context.js';

const { TextArea } = Input;

export default function IPWhitelistInput({ item, setIpWhitelist, isValidIpList, disabled }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <TextArea
        rows={5}
        style={{ resize: 'none' }}
        value={item.ipWhitelist}
        onChange={e => {
          setIpWhitelist(item.hotelId, e.target.value);
        }}
        disabled={disabled}
        status={isValidIpList(item.ipWhitelist) ? null : 'error'}
        placeholder={translate('paperlessPush.ipWhitelistHint')}
      />
    </div>
  );
}
