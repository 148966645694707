export default {
  stripe: {
    pkey: 'Public key',
    skey: 'Secret key',
    i_pkey: 'In Stripe Dashboard: Developers / API Keys',
    i_skey: 'In Stripe Dashboard: Developers / API Keys',
  },
  redsys: {
    merchantName: 'Merchant name',
    merchantCode: 'Merchant code',
    terminal: 'Terminal',
    shaKey: 'Sha Key',
    encryptionKey: 'Encryption key',
  },
  addonpayments: {
    merchantId: 'Merchant ID',
    account: 'Account',
    secureaccount: 'Secure account',
    secret: 'Secret',
    rebatePassword: 'Rebate password',
  },
  mercadopago: {
    accessToken: 'Access Token',
    i_accessToken:
      'In the Mercado Pago panel: Your Business / Configuration. In the "Credentials" section, click on "access". Now, click on "Production Credentials".',
  },
  sipay: {
    key: 'Key',
    resource: 'Resource',
    secret: 'Secret',
    dataBusinessTitle: 'Data Business Title',
  },
  trustpayments: {
    siteReference: 'Site Reference',
    hashPass: 'Hash Password',
    webservicesUser: 'Webservice User',
    webservicesPass: 'Webservice Password',
    alias: 'Alias',
  },
  generic: {
    payment: 'Activate payments',
    payment_credit_card: 'Payment with card',
    warranty_credit_card: 'Warranty card',
    currency: 'Currency',
    testEnvironment: 'Test environment enabled',
    o_yes: 'Yes',
    o_no: 'No',
    o_0: 'Disabled',
    o_1: 'Required',
    o_1_taxes: 'Required + taxes',
    o_payment: 'Payment',
    o_payment_warranty: 'Payment + warranty',
    o_warranty: 'Warranty',
    o_optional: 'Optional',
    o_optional_taxes: 'Optional + taxes',
    o_required: 'Required',
  },
  paytef: {
    company: 'Company',
    username: 'Username',
    password: 'Password',
  },
  planet: {
    eMerchantID: 'E-Merchant ID',
    validationCode: 'Validation code',
    template: 'Template',
  },
  paycomet: {
    apiKey: 'Api Key',
    terminal: 'Terminal',
  },
  adyen: {
    apiKey: 'Api Key',
    merchantAccount: 'Merchant Account',
    baseUrl: 'Base URL',
  },
  montevideocomm: {
    baseUrl: 'Base URL',
    PSP_AppId: 'PSP AppId',
    payMethod: 'PayMethod',
    encryptionKey: 'EncryptionKey',
    encryptionIV: 'EncryptionIV',
    initializationVector: 'InitializationVector',
  },
  transbankwebpay: {
    token: 'Transaction token',
  },
};
