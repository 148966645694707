import { AppContext } from 'App.context.js';
import React, { useContext } from 'react';
import styles from './DynamicFormsHeader.styles.js';

export default function DynamicFormsHeader({ guestType, first, last, size }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const borderRadius = 8;
  const border = {
    borderTopLeftRadius: first ? borderRadius : 0,
    borderBottomLeftRadius: first ? borderRadius : 0,
    borderTopRightRadius: last ? borderRadius : 0,
    borderBottomRightRadius: last ? borderRadius : 0,
  };

  return (
    <div style={{ ...styles.wrapper, minWidth: size, maxWidth: size }}>
      <div
        style={{
          ...styles.guestType,
          ...border,
        }}
      >
        {translate(`dynamicForms.guestType.${guestType}`)}
      </div>
      <div
        style={{
          ...styles.container,
          ...border,
        }}
      >
        <div style={{ marginLeft: 8 }}>{translate(`dynamicForms.visible`)}</div>
        <div>{translate(`dynamicForms.required`)}</div>
      </div>
    </div>
  );
}
