import React from 'react';
import { Button, Spin, Typography, InputNumber } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import FormRow from 'Sell/Components/Upsert/FormRow/FormRow.jsx';
import LangSelector from 'Sell/Components/Upsert/LangSelector/LangSelector.jsx';
import LimitedText from 'Sell/Components/Upsert/Description/LimitedText.jsx';
import SupportedLanguages from 'modules/localization/SupportedLanguages';
import styles from './FormItem.styles.js';
import PhotoGallery from 'Sell/Components/PhotoGallery/PhotoGallery.jsx';
import CustomButton from 'Components/common/CustomButton/CustomButton.jsx';
import Colors from 'constants/Colors.js';
import useItemManager from './FormItem.hook.js';
import Visor from '../Visor/Visor.jsx';
import ItemTypeSelector from './Components/ItemTypeSelector/ItemTypeSelector.jsx';
import AppInput from 'Components/common/AppInput/AppInput.jsx';
import { useApp } from 'Sell/hooks/useApp.js';
import CustomSelector from 'Sell/Components/CustomSelector/CustomSelector.jsx';
import { useAppConfigQuery } from 'Sell/CrossSell/API/queries.js';
import SlotForm from './Components/SlotForm/SlotForm.jsx';
import { days } from 'Sell/CrossSell/utils.js';

export default function FormItem() {
  const {
    appState,
    appActions: { translate },
  } = useApp();
  const { data: appConfig } = useAppConfigQuery();
  const { dataSource, handlers } = useItemManager();
  const formLang = dataSource.state.language;
   
  const correctedLanguages = dataSource?.hotelLanguages?.map(language => {
    if (language.name === 'Holandés' && (language.id === 'de' || language.id === 'en')) {
      return { ...language, id: 'nl' };
    }
    return language;
  });

  return (
    <div style={styles.wrapper}>
      <div style={{ flex: 1 }}>
        <div>
          <div style={styles.header}>
            <div style={styles.buttonsContainer}>
              <CustomButton
                text={translate('crossSell.itemDetail.back')}
                background={Colors.white}
                color={Colors.exportButton}
                onClick={handlers.handleClickBack}
              />
              <CustomButton
                disabled={dataSource.isLoading}
                text={
                  <>
                    {dataSource.isLoading ? <Spin style={{ marginRight: 10 }} /> : null}
                    {translate('crossSell.itemDetail.saveItem')}
                  </>
                }
                background={Colors.exportButton}
                color={'white'}
                onClick={handlers.handleSubmit}
              />
            </div>
          </div>

          <div style={styles.container}>
            <FormRow>
              <LangSelector
                suportedlanguages={correctedLanguages}
                defaultLang={appState.currentLanguage.id}
                setSelectedLanguage={handlers.handleLanguageChange}
              />
            </FormRow>
            <FormRow title={translate('crossSell.itemType')}>
              <ItemTypeSelector
                value={dataSource.state.type}
                onClick={handlers.handleItemTypeChange}
              />
            </FormRow>
            <FormRow style={{ display: 'flex', gap: 30, alignItems: 'center' }}>
              <div style={{ flex: 1 }}>
                <AppInput
                  title={translate('crossSell.itemDetail.title')}
                  value={dataSource.state.texts?.[formLang]?.name}
                  onChange={value => handlers.handleTextChange({ type: 'name', value })}
                  hint={translate('crossSell.itemDetail.titleHint')}
                />
              </div>
              {dataSource.state.type === 'good' ? (
                <div style={{ flex: 1 }}>
                  <AppInput
                    title={translate('crossSell.itemDetail.availableQuantity')}
                    value={dataSource.state.quantity}
                    onChange={handlers.handleQuantityChange}
                    inputComponent={
                      <InputNumber
                        min={0}
                        value={dataSource.state.quantity}
                        onChange={handlers.handleQuantityChange}
                      />
                    }
                  />
                </div>
              ) : null}
            </FormRow>

            <FormRow>
              <LimitedText
                title={translate('crossSell.description')}
                placeholder={translate('crossSell.itemDetail.itemDescription')}
                limit={200}
                text={dataSource.state.texts?.[formLang]?.description}
                onChange={value => handlers.handleTextChange({ type: 'description', value })}
              />
            </FormRow>
            <FormRow>
              <LimitedText
                title={translate('crossSell.itemDetail.termsAndPolicies')}
                placeholder={translate('crossSell.itemDetail.termsAndPoliciesPlaceholder')}
                limit={5000}
                text={dataSource.state.texts?.[formLang]?.terms}
                onChange={value => handlers.handleTextChange({ type: 'terms', value })}
              />
            </FormRow>
            <FormRow title={translate('crossSell.photoGallery')}>
              <PhotoGallery
                photoGallery={dataSource.state.itemGalleries}
                handleFileChange={handlers.handleFileChange}
                handleDeleteImage={handlers.handleDeleteImage}
                fileList={dataSource.files}
                handleOnDragEnd={handlers.handleOnPhotoPositionChange}
              />
            </FormRow>
            <SlotForm type={dataSource.state.type} dataSource={dataSource} handlers={handlers} />
            {dataSource.state.type !== 'good' ? (
              <FormRow title={translate('crossSell.itemDetail.listSlotTime')}>
                {dataSource.state.slots.map(slot => (
                  <div key={slot.id} style={styles.slotContainer}>
                    <Typography.Text strong>{slot.texts[formLang]?.name || translate('crossSell.itemDetail.slot.untitled')}</Typography.Text>
                    <Typography.Text>
                      {slot.startDate?.format('DD/MM/YYYY')} - {slot.endDate?.format('DD/MM/YYYY')}
                    </Typography.Text>
                    <Typography.Text>{slot.quantity}</Typography.Text>
                    <div style={styles.slotDaysContainer}>
                      {days.map(day => (
                        <Typography.Text
                          key={day}
                          style={{
                            color: slot.days?.find(d => d.name === day && d.enabled)
                              ? '#2D5BFF'
                              : '#A0A0A0',
                          }}
                        >
                          {translate(`crossSell.itemDetail.slot.daysShort.${day}`)}
                        </Typography.Text>
                      ))}
                    </div>
                    <div>
                      <Button
                        type="link"
                        onClick={() => handlers.handleSlotEdit(slot)}
                        icon={<EditOutlined />}
                      />
                      <Button
                        type="link"
                        onClick={() => handlers.handleSlotDelete(slot)}
                        icon={<DeleteOutlined />}
                      />
                    </div>
                  </div>
                ))}
              </FormRow>
            ) : null}
            <FormRow
              style={styles.priceAndPaymentSettingsContainer}
              title={translate('crossSell.itemDetail.priceAndPaymentSettings')}
            >
              {/* <div style={styles.priceTypeContainer}>
                <CustomSelector
                  confirmationType={dataSource.state.confirmationType}
                  onConfirmationTypeChange={handlers.handleConfirmationChange}
                  style={{ flexShrink: 0 }}
                />
                <Typography.Text>
                  {translate('crossSell.itemDetail.priceExplanation')}
                </Typography.Text>
              </div> */}
              <AppInput
                title={translate(`crossSell.itemDetail.${dataSource.state.type}Price`)}
                value={dataSource.state.price}
                onChange={handlers.handlePriceChange}
                style={{ maxWidth: 200 }}
                suffix={appConfig?.data?.currency}
              />
            </FormRow>
          </div>
        </div>
      </div>
      <Visor item={dataSource.state} lang={formLang} />
    </div>
  );
}
