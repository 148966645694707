import { notification } from 'antd';
import { useContext, useState } from 'react';
import { AppContext } from '../../../../App.context';
import { validateEmail } from '../../../../utils/TextUtils';
import registerRequest from '../../../../../src/utils/networking/api/auth/register.service';
import { useHistory } from 'react-router-dom';
import { trackEvent } from 'utils/GtmUtils';
import GtmConstants from 'utils/GtmConstants';

export default function useRegisterHook() {
  const {
    appState,
    appActions: { translate },
  } = useContext(AppContext);

  const history = useHistory();

  const [state, setState] = useState({
    chainName: null,
    name: null,
    phone: null,
    email: null,
    password: null,
    repeatPassword: null,
    termsConditions: false,
    loading: false,
  });

  const actions = {
    setEmail: value => {
      updateState({ email: value });
    },
    setPassword: value => {
      updateState({ password: value });
    },
    setField: (fieldKey, value) => {
      updateState({ [fieldKey]: value });
    },

    validateFieldsAndRegister: () => {
      if (
        !state.chainName ||
        !state.name ||
        !state.email ||
        !state.password ||
        !state.repeatPassword
      ) {
        notification.error({
          message: translate('register.emptyFields'),
        });
      } else {
        if (state.password !== state.repeatPassword) {
          notification.error({
            message: translate('register.matchPasswords'),
          });
        } else {
          if (!validateEmail(state.email)) {
            notification.error({
              message: translate('register.invalidMail'),
            });
          } else {
            if (!state.termsConditions) {
              notification.error({
                message: translate('register.termsError'),
              });
            } else {
              actions.register();
            }
          }
        }
      }
    },

    register: () => {
      updateState({ loading: true });

      registerRequest({
        email: state.email,
        password: state.password,
        name: state.name,
        phone: state.phone,
        chain_name: state.chainName,
        lang: appState.currentLanguage.id,
      })
        .then(data => {
          trackEvent({ event: GtmConstants.REGISTER_EVENT });
          updateState({
            loading: false,
            showRegisterInfo: translate('register.registerOk').replace('#', state.email),
            paymentMethodUrl: data.registerPaymentMethodUrl,
          });
        })
        .catch(error => {
          updateState({ loading: false });
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    },

    goBack: () => {
      history.goBack();
    },

    redirectToAddPaymentMethod: () => {
      window.location.href = state.paymentMethodUrl;
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
