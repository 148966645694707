export default {
    header: {
        display: 'flex',
        alignItems: 'left',
        flexDirection: 'column',
    },
    headerTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    headerSubtitle: {
        marginTop: 10,
        fontSize: 13,
        fontWeight: 'regular',
    },
    body: {
        marginTop: 10,
        marginBottom: 10,
    },
    licenceBody: {
        maxHeight: 401,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        marginTop: 20,
        marginBottom: 20,
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 18,
    },
    modalFooterButton: {
        width: '100%',
    },
    modalFooterButtonRed: {
        width: '100%',
        color: '#B42318',
        backgroundColor: 'white',
        borderColor: '#FDA29B',
    },
    modalRounded: {
        minHeight: 100,
    },
    normalRadio: {
        flex: 1,
        width: '100%',
        padding: 18,
        marginBottom: 10,
        border: '1px solid #EAECF0',
        borderRadius: 8,
    },
    selectedRadio: {
        flex: 1,
        width: '100%',
        padding: 18,
        marginBottom: 10,
        backgroundColor: '#E4F6FF',
        border: '1px solid #93D3F4',
        borderRadius: 8,
    },
    formData: {
        display: 'flex',
        gap: 16,
        marginTop: 16,
        marginBottom: 16,
    },
    formSection: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'right',
        flex: 1,
        marginTop: 16,
        gap: 16,
    },
    inputContainer: {
        flexWrap: 'wrap',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        gap: 8,
        marginTop: 8,
    },
    maxInputContainer: {
        maxWidth: 248,
    },
    sectionTitleWithErrors: {
        color: 'red',
    },
    sectionTitle: {
        color: 'black',
        fontWeight: 500,
    },
    titleWithError: {
        color: 'rgba(172, 21, 24, 1)',
    },
    dragger: {
        flex: 1,
        marginTop: 10,
        marginBottom: 26,
    },
    addButtonDiv: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row-reverse',
        marginTop: -50,
    },
    addButton: {
        flexDirection: 'row-reverse',
    },
    trashIcon: {
        borderRadius: 4,
        backgroundColor: '#FFDCDC',
        height: 40,
        alignContent: 'center',
        paddingBottom: 10,
        borderColor: 'rgba(0,0,0,0)',
    },
    trashContainer: {
        alignSelf: 'end',
    },
    licenceElement: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20,
        alignSelf: 'start',
        width: 480,
        marginLeft: 5,
    },
    selector: {
        flex: 1,
        maxWidth: 248,
    },
    currencySelect: {
        border: '0px !important',
    },
    errorSubmessage: {
        marginTop: 5,
        color: 'rgba(172, 21, 24, 1)',
        fontSize: 12,
    },
}