import { AppContext } from 'App.context';
import CustomButton from 'Components/common/CustomButton/CustomButton';
import Loading from 'Components/common/Loader/Loading';
import Colors from 'constants/Colors';
import React, { useState, useContext, useEffect } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import { Spin } from 'antd';
import { notification } from 'antd';
import styles from './PaymentConfigurationForm.styles';
import ConfigurationForm from 'Components/common/ConfigurationForm/ConfigurationForm';
import usePaymentFormHook from './PaymentConfigurationForm.hook';
import usePaymentDataHook from './paymentData.hook';

export default function PaymentConfigurationForm({
  rowData,
  selectedPayment,
  updateEnabledHotels,
}) {
  const {
    appState: { currentLanguage },
    appActions: { translate },
  } = useContext(AppContext);

  const { state, actions } = usePaymentFormHook(updateEnabledHotels);
  const { sendPayment, loading } = usePaymentDataHook();

  const [bookingIdentifier, setBookingIdentifier] = useState('');
  const [paymentUrl, setPaymentUrl] = useState('');
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    actions.loadPaymentConfig(selectedPayment, rowData.id);
  }, [selectedPayment]);

  const literals = {
    ...currentLanguage.paymentConfig[selectedPayment?.name?.toLowerCase()],
    ...currentLanguage.paymentConfig.generic,
  };

  const handleInputBookingIdentifierChange = event => {
    setBookingIdentifier(event.target.value);
  };

  const hotelId = "" + rowData.id;

  const sendPaymentData = async () => {
    try {
      const response = await sendPayment({ hotelId, bookingIdentifier });
      if (response.success == true) {
        notification.success({ message: translate('paymentScreen.connectSuccess') });
        setData(response);
        setPaymentUrl(response.response.url);
      } else {
        notification.error({ message: translate('paymentScreen.connectError') });
      }
    } catch (error) {
      notification.error({ message: translate('paymentScreen.connectError') });
      setError(error);
    }
  };

  // Check if the payment gateway is valid.
  const isValidPlatform = ['paytef', 'adyen', 'paycomet', 'montevideocomm', 'transbankwebpay'].includes(selectedPayment?.name?.toLowerCase());

  return !state.isLoading && state.orderedConfig ? (
    <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
      {Object.keys(state.orderedConfig)?.map((fieldCategory, index) => {
        return (
          <div key={index}>
            <div style={styles.category}>{translate(`paymentCategory.${fieldCategory}`)}</div>

            <ConfigurationForm
              fields={state.orderedConfig[fieldCategory]}
              literals={literals}
              getFieldValue={actions.getFieldValue}
              onValueChanged={actions.onValueChanged}
            />
          </div>
        );
      })}

      <div style={{ justifyContent: 'right', display: 'flex' }}>
        <CustomButton
          style={styles.button}
          background={Colors.blueColor}
          text={translate('save')}
          onClick={() => actions.onSave(selectedPayment, rowData.id)}
        />
      </div>

      {/* Mostrar el formulario solo si la pasarela es "paytef" o "ayden" */}
      {isValidPlatform && (
        <div>
          <h1 style={styles.title}>{translate('validation.payment.paymentTitle')}</h1>
          <p style={styles.subtitle}>{translate('validation.payment.description')}</p>

          <div style={styles.content}>
            <div style={styles.leftSection}>
              <label htmlFor="bookingIdentifier" style={styles.subtitleBooking}>
                {translate('validation.payment.bookingIdentifier')}
              </label>
              <input
                type="text"
                id="bookingIdentifier"
                style={styles.inputBox}
                onChange={handleInputBookingIdentifierChange}
              />
              <div style={styles.buttonContainer}>
                <CustomButton style={styles.buttonTestPayment} text={translate('validation.payment.testPayment')} onClick={sendPaymentData} />
              </div>
            </div>
            {paymentUrl && (
              <div style={styles.urlContainer}>
                <p>{translate('validation.payment.url')}</p>
                <a href={paymentUrl} target="_blank" rel="noopener noreferrer">
                  {paymentUrl}
                </a>
              </div>
            )}
          </div>
        </div>
      )}
      {loading && (
        <div style={styles.spinContainer}>
          <Spin size="large" tip="Cargando..." />
        </div>
      )}
    </div>
  ) : (
    <div style={styles.loadingContainer}>
      <Loading />
    </div>
  );
}
