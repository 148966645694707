import { notification, Upload } from 'antd';
import { AppContext } from 'App.context';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import validator from 'validator';
import moment from 'moment';
import storage from 'utils/storage';
import constants from 'utils/constants';

export default function useBillingListingHook() {
  const {
    appState,
    appActions: { getSelectedHotel, translate, getSelectedLanguage },
  } = useContext(AppContext);
  const { API } = useContext(ApiContext);

  const [fileList, setFileList] = useState([]);

  const uploadContractProps = {
    name: 'file',
    multiple: false,
    beforeUpload: file => {
      // Validate mimetype of file
      const types = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
      const isValid = types.includes(file.type);
      if (!isValid) {
        notification.error({
          message: `${file.name} ${translate('billingConfig.billingContract.fileUpload.invalid')}.`,
        });
      } else {
        updateContract({ fileToUpload: file });
        setFileList([file]);
      }

      return false;
    },
    onChange(info) {
      // Remove file from HotelContract
      if (info.fileList.length == 0) {
        updateContract({ fileToUpload: null });
        setFileList([]);
      }
    },
    fileList,
  };

  const uploadInvoiceProps = {
    name: 'file',
    multiple: false,
    beforeUpload: file => {
      // Validate mimetype of file
      const types = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
      const isValid = types.includes(file.type);
      if (!isValid) {
        notification.error({
          message: `${file.name} ${translate('billingConfig.invoice.fileUpload.invalid')}.`,
        });
      } else {
        updateInvoice({ fileToUpload: file });
        setFileList([file]);
      }

      return false;
    },
    onChange(info) {
      // Remove file from HotelInvoice
      if (info.fileList.length == 0) {
        updateInvoice({ fileToUpload: null });
        setFileList([]);
      }
    },
    fileList,
  };

  const PAGE_SIZE = 5;

  const emptyContract = {
    id: null,
    name: '',
    signatureDate: null,
    file: '',
    orderNumber: '',
    fileToUpload: null,
  };

  const initialLicenceKey = 0;

  const emptyLicence = {
    key: initialLicenceKey,
    id: null,
    name: null,
    contractId: null,
    startDate: null,
    type: null,
    currency: null,
    price: '',
  };

  const emptyInvoice = {
    id: null,
    invoiceId: '',
    invoiceInternalId: '',
    invoiceDate: null,
    contractId: null,
    status: null,
    currency: null,
    amount: '',
    fileToUpload: null,
    file: '',
  };

  const emptyInvoiceList = {
    items: [],
    totalItems: 0,
    totalPages: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
  };

  const emptyContractList = {
    items: [],
    totalItems: 0,
    totalPages: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
  };

  const emptyLicenceList = {
    items: [],
    totalItems: 0,
    totalPages: 0,
    currentPage: 0,
    pageSize: 9999,
  };

  const [contract, setContract] = useState(Object.assign({}, emptyContract));
  const [invoice, setInvoice] = useState(Object.assign({}, emptyInvoice));

  const [state, setState] = useState({
    isModalOpen: false,
    isLoading: false,
    isUserAdmin: isUserAdmin(),
    billingFormMode: null,
    modalStatus: 'main',
    contractErrors: [],
    contractList: emptyContractList,
    licenceList: emptyLicenceList,
    filters: null,
    reloadTables: 0,
    currentLanguage: getSelectedLanguage(),
    licenceForms: [emptyLicence],
    licenceErrors: [],
    lastLicenceKey: initialLicenceKey,
    isEditLicence: false,
    hotelName: getSelectedHotel().name,
    licenceTypes: [],
    periodity: [],
    currencies: [],
    invoiceErrors: [],
    invoiceList: emptyInvoiceList,
    invoiceStatus: [],
  });

  const [reloadTables, setReloadTables] = useState(0);

  useEffect(() => {
    actions.getBillingConfiguration();
  }, []);

  useEffect(() => {
    getSelectedHotel()?.id && actions.loadContractList(getSelectedHotel().id);
    getSelectedHotel()?.id && actions.loadLicencesList(getSelectedHotel().id);
    getSelectedHotel()?.id && actions.loadInvoicesList(getSelectedHotel().id);
    updateState({ hotelName: getSelectedHotel().name });
  }, [appState.selectedHotel]);

  useEffect(() => {
    getSelectedHotel()?.id && actions.loadContractList(getSelectedHotel().id);
    getSelectedHotel()?.id && actions.loadLicencesList(getSelectedHotel().id);
    getSelectedHotel()?.id && actions.loadInvoicesList(getSelectedHotel().id);
  }, [reloadTables]);

  useEffect(() => {
    updateState({ currentLanguage: getSelectedLanguage() });
  }, [appState.currentLanguage]);

  const actions = {
    loadInvoicesList: hotelId => {
      updateState({ isLoading: true, errors: [], invoiceErrors: [] });
      API.getHotelInvoicesList(hotelId)
        .then(response => {
          const list = Object.assign({}, emptyInvoiceList);
          if (response.items && response.items.length > 0) {
            list.items = response.items;
            list.totalItems = response.items.length;
            list.totalPages = Math.ceil(response.items.length / PAGE_SIZE);
            list.currentPage = 0;
          }
          updateState({ isLoading: false, invoiceList: list });
        })
        .catch(err => {
          updateState({ isLoading: false });

          notification.error({
            message: err?.message || 'Unknown error',
          });
        });
    },

    loadLicencesList: hotelId => {
      updateState({ isLoading: true, errors: [], licenceErrors: [] });
      API.getHotelLicencesList(hotelId)
        .then(response => {
          const list = Object.assign({}, emptyLicenceList);
          if (response.items && response.items.length > 0) {
            list.items = response.items;
            list.totalItems = response.items.length;
            list.totalPages = 0;
            list.currentPage = 0;
          }
          updateState({ isLoading: false, licenceList: list });
        })
        .catch(err => {
          updateState({ isLoading: false });

          notification.error({
            message: err?.message || 'Unknown error',
          });
        });
    },

    loadContractList: hotelId => {
      updateState({ isLoading: true, errors: [], contractErrors: [] });
      API.getHotelContractList(hotelId)
        .then(response => {
          const list = Object.assign({}, emptyContractList);
          if (response.items && response.items.length > 0) {
            list.items = response.items;
            list.totalItems = response.items.length;
            list.totalPages = Math.ceil(response.items.length / PAGE_SIZE);
            list.currentPage = 0;
          }
          updateState({ isLoading: false, contractList: list });
        })
        .catch(err => {
          updateState({ isLoading: false });

          notification.error({
            message: err?.message || 'Unknown error',
          });
        });
    },

    onChangePageContract: filters => {
      var newPage,
        newPageSize = -1;
      var searchFilters;

      if (state.contractList?.currentPage !== filters?.pagination.page) {
        newPage = filters?.pagination.page;
      }

      if (state.contractList?.pageSize !== filters?.pagination.pageSize) {
        newPageSize = filters?.pagination.pageSize;
      }

      updateState({
        contractList: {
          ...state.contractList,
          currentPage: newPage >= 0 ? newPage : state.contractList?.currentPage,
          pageSize: newPageSize >= 0 ? newPageSize : state.contractList?.pageSize,
        },
        filters: searchFilters,
      });
    },

    onChangePageLicence: filters => {
      var newPage,
        newPageSize = -1;
      var searchFilters;

      if (state.licenceList?.currentPage !== filters?.pagination.page) {
        newPage = filters?.pagination.page;
      }

      if (state.licenceList?.pageSize !== filters?.pagination.pageSize) {
        newPageSize = filters?.pagination.pageSize;
      }

      updateState({
        licenceList: {
          ...state.licenceList,
          currentPage: newPage >= 0 ? newPage : state.licenceList?.currentPage,
          pageSize: newPageSize >= 0 ? newPageSize : state.licenceList?.pageSize,
        },
        filters: searchFilters,
      });
    },

    onChangePageInvoice: filters => {
      var newPage,
        newPageSize = -1;
      var searchFilters;

      if (state.invoiceList?.currentPage !== filters?.pagination.page) {
        newPage = filters?.pagination.page;
      }

      if (state.invoiceList?.pageSize !== filters?.pagination.pageSize) {
        newPageSize = filters?.pagination.pageSize;
      }

      updateState({
        invoiceList: {
          ...state.invoiceList,
          currentPage: newPage >= 0 ? newPage : state.invoiceList?.currentPage,
          pageSize: newPageSize >= 0 ? newPageSize : state.invoiceList?.pageSize,
        },
        filters: searchFilters,
      });
    },

    onDownloadContractClicked: contract => {
      API.getHotelContractFileURL(contract.id)
        .then(response => {
          const link = document.createElement('a');
          link.href = response.url;
          link.target = '_blank';
          link.setAttribute('download', `${contract.file}`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch(err => {
          notification.error({
            message: err?.message || 'Unknown error',
          });
        });
    },

    onEditContractClicked: contract => {
      setFileList([
        {
          name: contract.file,
          status: 'done',
        },
      ]);

      contract.signatureDate = moment(contract.signatureDate).utc();

      updateContract(Object.assign({}, contract));
      updateState({ isModalOpen: true, modalStatus: 'contract', billingFormMode: null });
    },

    showBillingModal: () => {
      updateState({ isModalOpen: true });
    },

    closeBillingModal: () => {
      setFileList([]);
      updateState({ isModalOpen: false });
      updateState({ modalStatus: 'main', billingFormMode: null });

      updateState({ contractErrors: [] });
      updateContract(Object.assign({}, emptyContract));

      updateState({
        isEditLicence: false,
        licenceForms: [emptyLicence],
        lastLicenceKey: initialLicenceKey,
        licenceErrors: [],
      });

      updateState({ invoiceErrors: [] });
      updateInvoice(Object.assign({}, emptyInvoice));
    },

    selectBillingFormMode: ev => {
      updateState({ billingFormMode: ev.target.value });
    },

    changeModalForm: () => {
      state.billingFormMode && updateState({ modalStatus: state.billingFormMode });
    },

    getUploadContractProps: () => uploadContractProps,
    getUploadInvoiceProps: () => uploadInvoiceProps,

    deleteHotelContract: contract => {
      updateState({ isLoading: true });

      API.deleteHotelContract(contract)
        .then(_result => {
          notification.success({
            message: translate('billingConfig.billingContract.deleteHotelContractMessageOK'),
          });

          updateState({
            isLoading: false,
            isModalOpen: false,
            modalStatus: 'main',
            billingFormMode: null,
            contractErrors: [],
          });
          updateContract(Object.assign({}, emptyContract));
          setReloadTables(reloadTables + 1);
          setFileList([]);
        })
        .catch(err => {
          notification.error({
            message: err?.message || 'Unknown error',
          });

          updateState({
            isLoading: false,
            isModalOpen: false,
            modalStatus: 'main',
            billingFormMode: null,
            contractErrors: [],
          });
          updateContract(Object.assign({}, emptyContract));
          setFileList([]);
        });
    },

    editContractForm: () => {
      updateState({ isLoading: true });
      const valid = isValidEditContractForm(contract);

      if (valid) {
        API.editHotelContract({ hotelId: getSelectedHotel().id, contract })
          .then(_result => {
            notification.success({
              message: translate('billingConfig.billingContract.editContractMessageOK'),
            });

            updateState({
              isLoading: false,
              isModalOpen: false,
              modalStatus: 'main',
              billingFormMode: null,
              contractErrors: [],
            });
            updateContract(Object.assign({}, emptyContract));
            setReloadTables(reloadTables + 1);
            setFileList([]);
          })
          .catch(err => {
            notification.error({
              message: err?.message || 'Unknown error',
            });

            updateState({
              isLoading: false,
              isModalOpen: false,
              modalStatus: 'main',
              billingFormMode: null,
              contractErrors: [],
            });
            updateContract(Object.assign({}, emptyContract));
            setFileList([]);
          });
      } else {
        notification.error({
          message: translate('billingConfig.billingContract.formHasErrors'),
        });

        updateState({ isLoading: false });
      }
    },

    saveContractForm: () => {
      updateState({ isLoading: true });

      const valid = isValidSaveContractForm(contract);

      if (valid) {
        API.saveHotelContract({ hotelId: getSelectedHotel().id, contract })
          .then(_result => {
            notification.success({
              message: translate('billingConfig.billingContract.saveContractMessageOK'),
            });

            updateState({
              isLoading: false,
              isModalOpen: false,
              modalStatus: 'main',
              billingFormMode: null,
              contractErrors: [],
            });
            updateContract(Object.assign({}, emptyContract));
            setReloadTables(reloadTables + 1);
            setFileList([]);
          })
          .catch(err => {
            notification.error({
              message: err?.message || 'Unknown error',
            });

            updateState({
              isLoading: false,
              isModalOpen: false,
              modalStatus: 'main',
              billingFormMode: null,
              contractErrors: [],
            });
            updateContract(Object.assign({}, emptyContract));
            setFileList([]);
          });
      } else {
        notification.error({
          message: translate('billingConfig.billingContract.formHasErrors'),
        });

        updateState({ isLoading: false });
      }
    },

    onChangeContractField: (fieldKey, value) => {
      if (fieldKey == 'signatureDate' && !value.isValid()) {
        value = null;
      }
      updateContract({ [fieldKey]: value });
    },

    editInvoiceForm: async () => {
      updateState({ isLoading: true });
      const valid = await isValidEditInvoiceForm(invoice);

      if (valid) {
        API.editHotelInvoice({ hotelId: getSelectedHotel().id, hotelName: getSelectedHotel().name, invoice }).then(_result => {
          notification.success({
            message: translate('billingConfig.invoice.editInvoiceMessageOK'),
          });
          updateState({ isLoading: false, isModalOpen: false, modalStatus: 'main', billingFormMode: null, invoiceErrors: [] });
          updateInvoice(Object.assign({}, emptyInvoice));
          setReloadTables(reloadTables + 1);
          setFileList([]);
        }).catch(err => {
          notification.error({
            message: err?.message || "Unknown error",
          });
          updateState({ isLoading: false, isModalOpen: false, modalStatus: 'main', billingFormMode: null, invoiceErrors: [] });
          updateInvoice(Object.assign({}, emptyInvoice));
          setFileList([]);
        });
      } else {
        notification.error({
          message: translate('billingConfig.invoice.formHasErrors'),
        });

        updateState({ isLoading: false });
      }
    },

    saveInvoiceForm: async () => {
      updateState({ isLoading: true });

      const valid = await isValidSaveInvoiceForm(invoice);

      if (valid) {
        API.saveHotelInvoice({
          hotelId: getSelectedHotel().id,
          hotelName: getSelectedHotel().name,
          invoice,
        })
          .then(_result => {
            notification.success({
              message: translate('billingConfig.invoice.saveInvoiceMessageOK'),
            });

            updateState({
              isLoading: false,
              isModalOpen: false,
              modalStatus: 'main',
              billingFormMode: null,
              invoiceErrors: [],
            });
            updateInvoice(Object.assign({}, emptyInvoice));
            setReloadTables(reloadTables + 1);
            setFileList([]);
          })
          .catch(err => {
            notification.error({
              message: err?.message || 'Unknown error',
            });

            updateState({
              isLoading: false,
              isModalOpen: false,
              modalStatus: 'main',
              billingFormMode: null,
              invoiceErrors: [],
            });
            updateInvoice(Object.assign({}, emptyInvoice));
            setFileList([]);
          });
      } else {
        notification.error({
          message: translate('billingConfig.invoice.formHasErrors'),
        });

        updateState({ isLoading: false });
      }
    },

    deleteHotelInvoice: invoice => {
      updateState({ isLoading: true });

      API.deleteHotelInvoice(invoice)
        .then(_result => {
          notification.success({
            message: translate('billingConfig.invoice.deleteHotelInvoiceMessageOK'),
          });

          updateState({
            isLoading: false,
            isModalOpen: false,
            modalStatus: 'main',
            billingFormMode: null,
            invoiceErrors: [],
          });
          updateInvoice(Object.assign({}, emptyInvoice));
          setReloadTables(reloadTables + 1);
          setFileList([]);
        })
        .catch(err => {
          notification.error({
            message: err?.message || 'Unknown error',
          });

          updateState({
            isLoading: false,
            isModalOpen: false,
            modalStatus: 'main',
            billingFormMode: null,
            invoiceErrors: [],
          });
          updateInvoice(Object.assign({}, emptyInvoice));
          setFileList([]);
        });
    },

    onEditInvoiceClicked: invoice => {
      setFileList([
        {
          name: invoice.file,
          status: 'done',
        },
      ]);

      invoice.invoiceDate = moment(invoice.invoiceDate).utc();
      invoice.contractId = invoice.hotelContract.id;

      updateInvoice(Object.assign({}, invoice));
      updateState({ isModalOpen: true, modalStatus: 'invoice', billingFormMode: null });
    },

    onChangeInvoiceField: (fieldKey, value) => {
      if (fieldKey == 'invoiceDate' && !value.isValid()) {
        value = null;
      }
      updateInvoice({ [fieldKey]: value });
    },

    addLicenceFormBlock: () => {
      const newLicence = Object.assign({}, emptyLicence);
      const newKey = state.lastLicenceKey + 1;
      newLicence.key = newKey;
      updateState({ licenceForms: [...state.licenceForms, newLicence], lastLicenceKey: newKey });
    },

    removeLicenceFormBlock: licence => {
      if (state.licenceForms.length > 1) {
        updateState({ licenceForms: state.licenceForms.filter(e => e.key != licence.key) });
      }
    },

    onDownloadInvoiceClicked: licence => {
      API.getHotelInvoiceFileURL(licence.id)
        .then(response => {
          const link = document.createElement('a');
          link.href = response.url;
          link.target = '_blank';
          link.setAttribute('download', `${licence.file}`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch(err => {
          notification.error({
            message: err?.message || 'Unknown error',
          });
        });
    },

    onEditLicenceClicked: licence => {
      const editLicence = Object.assign({}, licence);

      editLicence.startDate = moment(editLicence.startDate).utc();
      editLicence.contractId = licence.hotelContract.id;
      editLicence.key = initialLicenceKey;
      editLicence.currency = licence.currency;

      updateState({
        isEditLicence: true,
        licenceForms: [editLicence],
        isModalOpen: true,
        modalStatus: 'licence',
        billingFormMode: null,
      });
    },

    saveLicenceForms: () => {
      updateState({ isLoading: true });

      let valid = true;
      let licenceErrors = [];

      state.licenceForms.forEach(licence => {
        let errorItem = {
          key: licence.key,
          errors: errorsInSaveLicenceFormElement(licence),
        };

        licenceErrors.push(errorItem);

        if (errorItem.errors.length > 0) {
          valid = false;
        }
      });

      if (valid) {
        const newLicence = Object.assign({}, emptyLicence);

        API.saveBulkHotelLicence({ hotelId: getSelectedHotel().id, licences: state.licenceForms })
          .then(_result => {
            notification.success({
              message: translate('billingConfig.licence.saveLicenceMessageOK'),
            });

            updateState({
              isLoading: false,
              isModalOpen: false,
              modalStatus: 'main',
              billingFormMode: null,
              licenceErrors: [],
              licenceForms: [newLicence],
              isEditLicence: false,
            });
            setReloadTables(reloadTables + 1);
          })
          .catch(err => {
            notification.error({
              message: err?.message || 'Unknown error',
            });

            updateState({
              isLoading: false,
              isModalOpen: false,
              modalStatus: 'main',
              billingFormMode: null,
              licenceErrors: [],
              licenceForms: [newLicence],
              isEditLicence: false,
            });
          });
      } else {
        notification.error({
          message: translate('billingConfig.billingContract.formHasErrors'),
        });

        updateState({ licenceErrors: licenceErrors, isLoading: false });
      }
    },

    editLicenceForm: () => {
      updateState({ isLoading: true });

      let valid = true;
      let licenceErrors = [];

      state.licenceForms.forEach(licence => {
        let errorItem = {
          key: licence.key,
          errors: errorsInSaveLicenceFormElement(licence),
        };

        licenceErrors.push(errorItem);

        if (errorItem.errors.length > 0) {
          valid = false;
        }
      });

      if (valid) {
        const newLicence = Object.assign({}, emptyLicence);
        const licence = state.licenceForms[0];

        API.editHotelLicence({ hotelId: getSelectedHotel().id, licence })
          .then(_result => {
            notification.success({
              message: translate('billingConfig.licence.editLicenceMessageOK'),
            });

            updateState({
              isLoading: false,
              isModalOpen: false,
              modalStatus: 'main',
              billingFormMode: null,
              licenceErrors: [],
              licenceForms: [newLicence],
              isEditLicence: false,
            });
            setReloadTables(reloadTables + 1);
          })
          .catch(err => {
            notification.error({
              message: err?.message || 'Unknown error',
            });

            updateState({
              isLoading: false,
              isModalOpen: false,
              modalStatus: 'main',
              billingFormMode: null,
              licenceErrors: [],
              licenceForms: [newLicence],
              isEditLicence: false,
            });
          });
      } else {
        notification.error({
          message: translate('billingConfig.billingContract.formHasErrors'),
        });

        updateState({ licenceErrors: licenceErrors, isLoading: false });
      }
    },

    deleteHotelLicence: () => {
      updateState({ isLoading: true });

      const licence = state.licenceForms[0];
      const newLicence = Object.assign({}, emptyLicence);

      API.deleteHotelLicence(licence)
        .then(_result => {
          notification.success({
            message: translate('billingConfig.licence.deleteHotelLicenceMessageOK'),
          });

          updateState({
            isLoading: false,
            isModalOpen: false,
            modalStatus: 'main',
            billingFormMode: null,
            licenceErrors: [],
            licenceForms: [newLicence],
            isEditLicence: false,
          });
          setReloadTables(reloadTables + 1);
        })
        .catch(err => {
          notification.error({
            message: err?.message || 'Unknown error',
          });

          updateState({
            isLoading: false,
            isModalOpen: false,
            modalStatus: 'main',
            billingFormMode: null,
            licenceErrors: [],
            licenceForms: [newLicence],
            isEditLicence: false,
          });
        });
    },

    getBillingConfiguration: () => {
      API.getHotelBillingConfiguration()
        .then(response => {
          updateState({
            periodity: response.data.PERIODITY_VALUES,
            licenceTypes: response.data.LICENCE_TYPES_VALUES,
            currencies: response.data.CURRENCY_VALUES,
            invoiceStatus: response.data.INVOICE_STATUS_VALUES,
            countryCodes: response.data.COUNTRY_CODES_VALUES,
          });
        })
        .catch(err => {
          notification.error({
            message: err?.message || 'Unknown error',
          });
        });
    },

    getLicenceTypesOptions: () => {
      return state.licenceTypes.map(item => {
        return { value: item, label: translate('billingConfig.licence.types.' + item) };
      });
    },

    getContractOptions: () => {
      return state.contractList.items.map(item => {
        return { value: item.id, label: item.name };
      });
    },

    getPeriodityOptions: (licence, selectables) => {
      let states = state.periodity.map(item => {
        return { value: item, label: translate('billingConfig.licence.table.type_' + item) };
      });

      if ( licence && licence.type && !selectables.includes(licence.type) ) {
        states.push({ value: licence.type, label: translate('billingConfig.licence.table.type_' + licence.type) })
      }

      return states;
    },

    getInvoiceStatusOptions: () => {
      return state.invoiceStatus.map(item => {
        return {
          value: item,
          label: translate('billingConfig.invoice.table.invoice_status_' + item),
        };
      });
    },

    getCurrencyOptions: () => {
      return state.currencies.map(item => {
        return { value: item, label: translate('billingConfig.licence.form.currency_' + item) };
      });
    },

    onChangeLicenceFormField: (fieldKey, value, licenceKey) => {
      const licenceForms = state.licenceForms.map(item => {
        if (item.key == licenceKey) {
          if (fieldKey == 'contractId') {
            let contract = state.contractList.items.find(element => element.id == value);
            item['startDate'] =
              (contract?.signatureDate && moment(contract?.signatureDate)) || null;
          }

          item[fieldKey] = value;
        }
        return item;
      });

      updateState({ licenceForms: licenceForms });
    },
  };

  async function findInvoiceWithInvoiceId(invoiceId) {
    const res = await API.findHotelInvoiceByInvoiceId({ hotelId: getSelectedHotel().id, invoiceId})
      .then(response => {
        return response.data;
      })
      .catch(err => {
        notification.error({
          message: err?.message || 'Unknown error',
        });
      });

    return res;
  }

  async function findInvoiceWithInvoiceInternalId(invoiceInternalId) {
    const res = await API.findHotelInvoiceByInvoiceInternalId({ hotelId: getSelectedHotel().id, invoiceInternalId})
      .then(response => {
        return response.data;
      })
      .catch(err => {
        notification.error({
          message: err?.message || 'Unknown error',
        });
      });

    return res;
  }

  function isValidFieldLicenceForm(key, value, object) {
    switch (key) {
      case 'name':
        let exceptions = ['setup','integration_cost'];
        let includes = state.licenceTypes.includes(value);
        let repeated = state.licenceForms.find(
          item => item.name == value && !exceptions.includes(value) && item.key !== object.key
        );
        let repeatedOutside = state.licenceList.items.find(
          item => item.name == value && !exceptions.includes(value) && (!object.id || (object.id && object.id != item.id))
        );
        return includes && !repeated && !repeatedOutside;
      case 'contractId':
        return state.contractList.items.find(item => item.id == value);
      case 'type':
        let typeExceptions = ['monthly', 'bimonthly', 'quarterly', 'four_monthly', 'biannual', 'two_yearly' ];
        return state.periodity.includes(value) || typeExceptions.includes(value);
      case 'currency':
        return state.currencies.includes(value);
      case 'price':
        return value != '' && parseFloat(value) >= 0;
      default:
        return false;
    }
  }

  function errorsInSaveLicenceFormElement(object) {
    let errors = [];

    if (!isValidFieldLicenceForm('name', object.name, object)) {
      errors.push('name');
    }

    if (!isValidFieldLicenceForm('contractId', object.contractId, object)) {
      errors.push('contractId');
    }

    if (!isValidFieldLicenceForm('type', object.type, object)) {
      errors.push('type');
    }

    if (!isValidFieldLicenceForm('currency', object.currency, object)) {
      errors.push('currency');
    }

    if (!isValidFieldLicenceForm('price', object.price, object)) {
      errors.push('price');
    }

    return errors;
  }

  function isValidFieldContractForm(key, value) {
    switch (key) {
      case 'name':
        return !validator.isEmpty(value);
      case 'file':
        return value !== null;
      case 'signatureDate':
        const date = value instanceof moment ? value : moment(value, 'DD-MM-YYYY');
        return date.isValid();
      case 'orderNumber':
        return true;
      default:
        return false;
    }
  }

  function isValidEditContractForm(object) {
    let valid = true;
    let errors = [];

    if (!isValidFieldContractForm('name', object.name)) {
      valid = false;
      errors.push('name');
    }

    if (!isValidFieldContractForm('signatureDate', object.signatureDate)) {
      valid = false;
      errors.push('signatureDate');
    }

    updateState({ contractErrors: errors });
    return valid;
  }

  function isValidSaveContractForm(object) {
    let valid = true;
    let errors = [];

    if (!isValidFieldContractForm('name', object.name)) {
      valid = false;
      errors.push('name');
    }

    if (!isValidFieldContractForm('signatureDate', object.signatureDate)) {
      valid = false;
      errors.push('signatureDate');
    }

    if (!isValidFieldContractForm('file', object.fileToUpload)) {
      valid = false;
      errors.push('file');
    }

    updateState({ contractErrors: errors });
    return valid;
  }

  function isValidFieldInvoiceForm(key, value) {
    switch (key) {
      case 'invoiceId':
        return !validator.isEmpty(value);
      case 'invoiceInternalId':
        return !validator.isEmpty(value);
      case 'file':
        return value !== null;
      case 'invoiceDate':
        const date = value instanceof moment ? value : moment(value, 'DD-MM-YYYY');
        return date.isValid();
      case 'contractId':
        return state.contractList.items.find(item => item.id == value);
      case 'status':
        return state.invoiceStatus.includes(value);
      case 'currency':
        return state.currencies.includes(value);
      case 'amount':
        return value != '' && parseFloat(value) >= 0;
      default:
        return false;
    }
  }

  async function isValidEditInvoiceForm(object) {
    let valid = true;
    let errors = [];

    if (!isValidFieldInvoiceForm('invoiceId', object.invoiceId)) {
      valid = false;
      errors.push('invoiceId');
    }

    const invoice = object.invoiceId && await findInvoiceWithInvoiceId(object.invoiceId);
    if ( invoice?.id && invoice.id != object.id ) {
      valid = false;
      errors.push('invoiceId');
    }

    if (!isValidFieldInvoiceForm('invoiceInternalId', object.invoiceInternalId)) {
      valid = false;
      errors.push('invoiceInternalId');
    }

    const invoiceByInternal = object.invoiceInternalId && await findInvoiceWithInvoiceInternalId(object.invoiceInternalId);
    if ( invoiceByInternal?.id && invoiceByInternal.id != object.id ) {
      valid = false;
      errors.push('invoiceInternalId');
    }

    if (!isValidFieldInvoiceForm('invoiceDate', object.invoiceDate)) {
      valid = false;
      errors.push('invoiceDate');
    }

    if (!isValidFieldInvoiceForm('contractId', object.contractId)) {
      valid = false;
      errors.push('contractId');
    }

    if (!isValidFieldInvoiceForm('status', object.status)) {
      valid = false;
      errors.push('status');
    }

    if (!isValidFieldInvoiceForm('amount', object.amount)) {
      valid = false;
      errors.push('amount');
    }

    if (!isValidFieldInvoiceForm('currency', object.currency)) {
      valid = false;
      errors.push('currency');
    }

    updateState({ invoiceErrors: errors });
    return valid;
  }

  async function isValidSaveInvoiceForm(object) {
    let valid = true;
    let errors = [];

    if (!isValidFieldInvoiceForm('invoiceId', object.invoiceId)) {
      valid = false;
      errors.push('invoiceId');
    }

    if (!isValidFieldInvoiceForm('invoiceInternalId', object.invoiceInternalId)) {
      valid = false;
      errors.push('invoiceInternalId');
    }

    if (!isValidFieldInvoiceForm('invoiceDate', object.invoiceDate)) {
      valid = false;
      errors.push('invoiceDate');
    }

    if (!isValidFieldInvoiceForm('file', object.fileToUpload)) {
      valid = false;
      errors.push('file');
    }

    if (!isValidFieldInvoiceForm('contractId', object.contractId)) {
      valid = false;
      errors.push('contractId');
    }

    if (!isValidFieldInvoiceForm('status', object.status)) {
      valid = false;
      errors.push('status');
    }

    if (!isValidFieldInvoiceForm('amount', object.amount)) {
      valid = false;
      errors.push('amount');
    }

    if (!isValidFieldInvoiceForm('currency', object.currency)) {
      valid = false;
      errors.push('currency');
    }

    const invoice = object.invoiceId && await findInvoiceWithInvoiceId(object.invoiceId);
    if ( invoice?.id ) {
      valid = false;
      errors.push('invoiceId');
    }

    const invoiceByInternal = object.invoiceInternalId && await findInvoiceWithInvoiceId(object.invoiceInternalId);
    if ( invoiceByInternal?.id ) {
      valid = false;
      errors.push('invoiceInternalId');
    }

    updateState({ invoiceErrors: errors });
    return valid;
  }

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  function updateContract(object) {
    setContract(previousContract => ({ ...previousContract, ...object }));
  }

  function updateInvoice(object) {
    setInvoice(previousInvoice => ({ ...previousInvoice, ...object }));
  }

  function isUserAdmin() {
    const storedCredentials = JSON.parse(storage.get(constants.AUTH.USER_KEY));
    return storedCredentials.userType === 'admin' ? true : false;
  }

  return { state, contract, invoice, actions };
}
