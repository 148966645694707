export default {
  stripe: {
    pkey: 'Clave publicable',
    skey: 'Clave secreta',
    i_pkey: 'En el panel de Stripe: Desarrolladores / Claves de API',
    i_skey: 'En el panel de Stripe: Desarrolladores / Claves de API',
  },
  redsys: {
    merchantName: 'Merchant name',
    merchantCode: 'Merchant code',
    terminal: 'Terminal',
    shaKey: 'Sha Key',
    encryptionKey: 'Encryption key',
  },
  addonpayments: {
    merchantId: 'Merchant ID',
    account: 'Account',
    secureaccount: 'Secure account',
    secret: 'Secret',
    rebatePassword: 'Rebate password',
  },
  mercadopago: {
    accessToken: 'Access Token',
    i_accessToken:
      'En el panel de Mercado Pago: Tu Negocio / Configuración. En el apartado "Credenciales" hacer click en "acceder". Ahora, click en "Credenciales de producción".',
  },
  sipay: {
    key: 'Key',
    resource: 'Resource',
    secret: 'Secret',
    dataBusinessTitle: 'Nombre del cliente',
  },
  trustpayments: {
    siteReference: 'Site Reference',
    hashPass: 'Hash Password',
    webservicesUser: 'Webservice User',
    webservicesPass: 'Webservice Password',
    alias: 'Alias',
  },
  generic: {
    payment: 'Activar pagos',
    payment_credit_card: 'Pago con tarjeta',
    warranty_credit_card: 'Tarjeta de garantía',
    currency: 'Moneda',
    testEnvironment: 'Entorno de test activo',
    o_yes: 'Sí',
    o_no: 'No',
    o_0: 'Desactivado',
    o_1: 'Requerido',
    o_1_taxes: 'Requerido + tasas',
    o_payment: 'Pago',
    o_payment_warranty: 'Pago + garantía',
    o_warranty: 'Garantía',
    o_optional: 'Opcional',
    o_optional_taxes: 'Opcional + tasas',
    o_required: 'Obligatorio',
  },
  paytef: {
    company: 'Compañía',
    username: 'Usuario',
    password: 'Contraseña',
  },
  planet: {
    eMerchantID: 'E-Merchant ID',
    validationCode: 'Código validación',
    template: 'Plantilla',
  },
  paycomet: {
    apiKey: 'Api Key',
    terminal: 'Terminal',
  },
  adyen: {
    apiKey: 'Api Key',
    merchantAccount: 'Merchant Account',
    baseUrl: 'Base URL',
  },
  montevideocomm: {
    baseUrl: 'Base URL',
    PSP_AppId: 'PSP AppId',
    payMethod: 'PayMethod',
    encryptionKey: 'EncryptionKey',
    encryptionIV: 'EncryptionIV',
    initializationVector: 'InitializationVector',
  },
  transbankwebpay: {
    token: 'Token transacciones',
  },
};
