import React, { useContext } from 'react';
import styles from './PaymentConfiguration.styles';
import { AppContext } from '../../../App.context';
import ContentBox from '../../common/ContentBox/ContentBox';
import InfoPill from '../../common/InfoPill/InfoPill';
import Loading from '../../common/Loader/Loading';
import DefaultLayout from '../../DefaultLayout/DefaultLayout';
import { Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import PaymentSelector from './PaymentSelector/PaymentSelector';
import usePaymentConfigurationHook from './PaymentConfiguration.hook';
import Colors from 'constants/Colors';
import PaymentConfigurationForm from './PaymentConfigurationForm/PaymentConfigurationForm';
import RequestSuscription from 'Components/common/RequestSuscription/RequestSuscription.jsx';

export default function PaymentConfiguration() {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const { state, actions } = usePaymentConfigurationHook();

  const columns = [
    {
      title: translate('state'),
      key: 'name',
      dataIndex: 'name',
      width: '15%',
      align: 'center',
      render: (_, row) => (
        <InfoPill
          color={actions.getColorStateFromPayment(row)}
          backgroundColor={actions.getBackgroundColorStateFromPayment(row)}
          text={actions.getTextStateFromPayment(row)}
        />
      ),
    },
    {
      title: translate('hotel'),
      dataIndex: 'name',
      render: (_, row) => {
        return <div style={{ fontWeight: 500 }}>{row.name}</div>;
      },
    },
    {
      title: translate('paymentScreen.platform'),
      dataIndex: 'id',
      width: '30%',
      align: 'left',
      render: (_, row) => {
        return (
          <PaymentSelector
            row={row}
            paymentsList={state.paymentsList}
            selectedPayment={actions.getSelectedPayment(row.id)}
            setSelectedPayment={actions.setSelectedPayment}
            disabled={state.enabledHotels.includes(row.id)}
          />
        );
      },
    },
    Table.EXPAND_COLUMN,
  ];

  return (
    <DefaultLayout singleBox title={translate('layout.payment')} enableAllHotels={true}>
      <div style={{ paddingLeft: 32, paddingRight: 32 }}>
        {state.suscription ? (
          <ContentBox noPadding singleBox>
            {state.isLoading ? (
              <div style={styles.loadingContainer}>
                <Loading />
              </div>
            ) : (
              <div>
                {state.hotels && state.paymentsList && (
                  <Table
                    columns={columns}
                    dataSource={state.hotels}
                    pagination={state.hotels?.length > 10}
                    expandable={{
                      rowExpandable: record => actions.getSelectedPayment(record.id) != null,
                      expandedRowRender: record => {
                        return (
                          <PaymentConfigurationForm
                            rowData={record}
                            selectedPayment={actions.getSelectedPayment(record.id)}
                            updateEnabledHotels={actions.updateEnabledHotels}
                          />
                        );
                      },
                      expandedRowKeys: [state.expandedRow],
                      onExpand: (expanded, record) => {
                        actions.onExpanded(record.id, expanded);
                      },
                      expandIcon: ({ expanded, onExpand, record }) =>
                        actions.getSelectedPayment(record.id) != null ? (
                          expanded ? (
                            <ExpandIcon
                              bg={Colors.blueIntense}
                              onClick={onExpand}
                              record={record}
                              color={Colors.blueBoxColor}
                            />
                          ) : (
                            <ExpandIcon
                              bg={Colors.blueBoxColor}
                              onClick={onExpand}
                              record={record}
                              color={Colors.grayText}
                            />
                          )
                        ) : null,
                    }}
                  />
                )}
              </div>
            )}
          </ContentBox>
        ) : (
          <RequestSuscription suscription='payment' />
          // <div
          //   style={{
          //     display: 'flex',
          //     justifyContent: 'center',
          //     flexDirection: 'column',
          //     width: '100%',
          //     alignItems: 'center',
          //   }}
          // >
          //   <div
          //     style={{
          //       backgroundColor: Colors.white,
          //       borderRadius: 16,
          //       padding: 16,
          //       maxWidth: 600,
          //     }}
          //   >
          //     <div style={{ fontWeight: 'bold' }}>
          //       {translate('policeReport.missingSuscription.title')}
          //     </div>
          //     <div>{translate('policeReport.missingSuscription.description')}</div>
          //     {state.sendSuscriptionRequestEnabled && (
          //       <div style={{ display: 'flex', justifyContent: 'right', marginTop: 8 }}>
          //         <AppButton
          //           text={translate('policeReport.missingSuscription.send')}
          //           action={() => actions.requestGuestlinkFunctionality()}
          //         />
          //       </div>
          //     )}
          //   </div>
          // </div>
        )}
      </div>
    </DefaultLayout>
  );
}

function ExpandIcon({ bg, onClick, record, color }) {
  return (
    <div
      style={{ ...styles.expandIcon, backgroundColor: bg }}
      onClick={event => onClick(record, event)}
    >
      <EditOutlined style={{ color }} />
    </div>
  );
}
