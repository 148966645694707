import { notification } from 'antd';
import { saveAs } from 'file-saver';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App.context';
import { ApiContext } from '../networking/Api.context';

export default function useCheckOutExportHook() {
  const {
    appActions,
    appActions: { translate }
  } = useContext(AppContext);
  const { API } = useContext(ApiContext);

  const [state, setState] = useState({
    loading: false,
  });

  useEffect(() => {
    appActions.setExportingInProgress(state.loading);
  }, [, state.loading]);

  const checkoutExportActions = {
    export: (hotel, dateFrom, dateTo) => {
      updateState({ loading: true });
      API.getCheckOutExport({
        hotel: hotel?.key && hotel?.key !== 'null' ? hotel.key : null,
        dateFrom,
        dateTo,
        lang: appActions.getSelectedLanguage().id,
      })
        .then(data => {
          notification.success({
            message: translate('checkOutModule.exportOk'),
          });
          saveAs(
            new Blob([data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
            'checkins_export.xlsx'
          );
        })
        .catch(error => {
          console.warn(error);
        })
        .finally(() => {
          updateState({ loading: false });
        });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { checkoutExportActions };
}
