import axios from 'axios';
import apiConfig from '../api.config';

export default function doorOpeningAddToHotelRequest({ hotelId, name, pmsId, openingId }) {
  const {
    host,
    URIs: { addDoorOpeningToHotel },
  } = apiConfig;

  const uri = `${host}${addDoorOpeningToHotel.replace(':hotelId', hotelId)}`;

  return axios
    .post(uri, JSON.stringify({ name, pmsId, openingId }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
