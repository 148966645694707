import React, { useContext } from 'react';
import styles from './CheckinStats.styles';
import DefaultLayout from '../../DefaultLayout/DefaultLayout';
import { Line } from 'react-chartjs-2';
import { DatePicker } from 'antd';
import { AppContext } from '../../../App.context';
import useCheckinStatsHook from './CheckinStats.hook';
import constants from '../../../utils/constants';
import { Table } from 'antd';
import Colors from 'constants/Colors';
import CustomButton from 'Components/common/CustomButton/CustomButton';
import StatsContainer from './StatsContainer/StatsContainer';
import IconHolder from './IconHolder/IconHolder';
import DevicesGraph from './DevicesGraph/DevicesGraph';
import StepsGraph from './StepsGraph/StepsGraph';

export default function CheckinStats() {
  const { RangePicker } = DatePicker;
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useCheckinStatsHook();
  const advanceTime = actions.getAverageAdvanceCheckinTime();
  const mostCheckins = actions.getMostCheckinsDateTime();

  const columns = [
    {
      title: translate('channel'),
      dataIndex: 'name',
      key: 'name',
      render: (_, row) => {
        const rowName = row.name === 'BOOKING.COM' ? 'BOOKINGDOTCOM' : row.name;
        return rowName === 'Total' ? rowName : translate(`ihClient.${rowName}`);
      },
    },
    {
      title: translate('checkins'),
      dataIndex: 'count',
      key: 'count',
      align: 'center',
    },
  ];

  return (
    <DefaultLayout
      enableAllHotels={true}
      title={translate('layout.stats')}
      singleBox
      contentHeader={
        <div style={styles.header}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
            <RangePicker
              onChange={actions.onDateChange}
              defaultValue={state.datesRange}
              format={constants.dateFormat}
              disabled={state.isLoading}
            />
            <CustomButton
              text={translate('checkInList.export')}
              background={Colors.exportButton}
              color={'white'}
              onClick={() => {
                actions.export();
              }}
            />
          </div>
        </div>
      }
    >
      <div style={styles.mainContainer}>
        <div style={styles.content}>
          <div style={styles.boxesContainer}>
            <StatsContainer
              isLoading={state.isLoading}
              flex={1 / 5}
              color={Colors.greenPill}
              title={translate('stats.avgDuration.title')}
              subtitle={translate('stats.avgDuration.subtitle')}
            >
              <div style={styles.boxStatContainer}>
                <IconHolder
                  color={Colors.greenPill}
                  icon={require('../../../assets/images/icons/white/clock.png')}
                />
                <div>{actions.getFormattedAvgDuration()}</div>
              </div>
            </StatsContainer>
            <StatsContainer
              isLoading={state.isLoading}
              flex={1 / 5}
              color={Colors.red}
              title={translate('stats.conversion.title')}
              subtitle={translate('stats.conversion.subtitle')}
            >
              <div style={styles.boxStatContainer}>
                <IconHolder
                  color={Colors.red}
                  icon={require('../../../assets/images/icons/white/conversion.png')}
                />
                <div>{actions.getConversionDuration()}</div>
              </div>
            </StatsContainer>
            <StatsContainer
              isLoading={state.isLoading}
              flex={1 / 5}
              color={Colors.yellowButton}
              title={translate('stats.mostCheckins.title')}
              subtitle={translate('stats.mostCheckins.subtitle')}
            >
              <div style={styles.boxStatContainer}>
                {state.mostCheckinsDay !== null &&
                !isNaN(state.mostCheckinsDay) &&
                state.mostCheckinsDay <= 6 ? (
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    <div style={styles.boxStatContainer}>
                      <IconHolder
                        color={Colors.yellowButton}
                        icon={require('../../../assets/images/icons/white/calendar.png')}
                      />
                      <div>{mostCheckins.date}</div>
                    </div>
                    <div style={styles.boxStatContainer}>
                      <IconHolder
                        color={Colors.yellowButton}
                        icon={require('../../../assets/images/icons/white/clock.png')}
                      />
                      <div>{mostCheckins.time}</div>
                    </div>
                  </div>
                ) : (
                  <div style={styles.boxStatContainer}>
                    <IconHolder
                      color={Colors.yellowButton}
                      icon={require('../../../assets/images/icons/white/bullseye.png')}
                    />
                    <div>{mostCheckins}</div>
                  </div>
                )}
              </div>
            </StatsContainer>
            <StatsContainer
              isLoading={state.isLoading}
              flex={1 / 5}
              color={Colors.lightBlue}
              title={translate('stats.advance.title')}
              subtitle={translate('stats.advance.subtitle')}
            >
              <div style={styles.boxStatContainer}>
                {state.averageAdvanceCheckinTime ? (
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    <div style={styles.boxStatContainer}>
                      <IconHolder
                        color={Colors.lightBlue}
                        icon={require('../../../assets/images/icons/white/calendar.png')}
                      />
                      <div>{advanceTime.days}</div>
                    </div>
                    <div style={styles.boxStatContainer}>
                      <IconHolder
                        color={Colors.lightBlue}
                        icon={require('../../../assets/images/icons/white/clock.png')}
                      />
                      <div>{advanceTime.hours}</div>
                    </div>
                  </div>
                ) : (
                  <div style={styles.boxStatContainer}>
                    <IconHolder
                      color={Colors.lightBlue}
                      icon={require('../../../assets/images/icons/white/bullseye.png')}
                    />
                    <div>{advanceTime}</div>
                  </div>
                )}
              </div>
            </StatsContainer>
            <StatsContainer
              isLoading={state.isLoading}
              flex={1 / 5}
              color={Colors.exportButton}
              isDevices
            >
              <DevicesGraph devices={state.devices} checkinTotal={state.totalCheckins} />
            </StatsContainer>
          </div>
          <div style={styles.tableContainer}>
            <Table
              columns={columns}
              dataSource={state.tableDataset}
              pagination={false}
              loading={state.isLoading}
            />
            <div style={{ flex: 1 }}>{state.data && <Line data={state.data} />}</div>
          </div>

          {state.lastStepsData && (
            <div style={styles.tableContainer}>
              <StepsGraph steps={state.lastStepsData}></StepsGraph>
            </div>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
}
