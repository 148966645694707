import { useQuery, useMutation, QueryCache } from 'react-query';
import {
  createRoomType,
  deleteResource,
  findAll,
  findById,
  uploadImages,
  upsert,
} from 'Sell/Upsell/API/api.config';

const queryCache = new QueryCache({});

export const useRoomTypeQuery = (filters, selectedHotel) => {
  const defaultFilters = {};

  return useQuery(['rooms', JSON.stringify(filters), selectedHotel], () =>
    findAll('rooms', { ...defaultFilters, ...filters })
  );
};

export const useAmenitiesQuery = lang => {
  const data = useQuery(['amenities', lang], () =>
    findAll('amenities', { lang })
  );
  if (data.isSuccess) {
    updateAmenityNames(data.data);
  }
  return data;

  function updateAmenityNames(data) {
    const amenities = data.data;
    for (const amenity of amenities) {
      const firstTranslation = amenity.amenityTexts[0];
      amenity.name = firstTranslation?.value || amenity.name;
    }
  
    return data;
  }
};

export const useRoomFeaturesQuery = lang => {
  const data = useQuery(['room-features', lang], () =>
    findAll('room-features', { lang })
  );
  if (data.isSuccess) {
    updateRoomFeaturesNames(data.data);
  }
  return data;

  function updateRoomFeaturesNames(data) {
    const roomFeatures = data.data;
    for (const roomFeature of roomFeatures) {
      const firstTranslation = roomFeature.roomFeatureTexts[0];
      roomFeature.name = firstTranslation?.value || roomFeature.name;
    }
  
    return data;
  }
};

export const useRoomTypeFind = data => {
  const result = useQuery(['room', data.id], () => findById('rooms', data), {
    enabled: !!data.id,
    refetchOnWindowFocus: false,
  });
  if (!data.id) {
    return { isLoading: false, data: null, remove: () => {} };
  }

  return result;
};

export const useRoomCreation = options =>
  useMutation({ mutationFn: data => createRoomType('rooms', data), ...options });

export const useRoomTypeMutation = options => {
  queryCache.clear();
  return useMutation({ mutationFn: data => upsert('rooms', data), ...options });
};

export const useDeleteRoomType = options =>
  useMutation({ mutationFn: data => deleteResource('rooms', data), ...options });

export const useImageUploader = () => {
  const uploadFiles = async data => await uploadImages('images/upload', data);

  return { uploadFiles };
};
