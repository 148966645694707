export default {
  // totalContainer: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   gap: 8,
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // },
  container: {
    display: 'flex',
    paddingRight: 32,
    paddingLeft: 32,
    flexDirection: 'column',
  },
  whiteBackground: {
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 14,
    padding: 14,
    minHeight: '70vh',
    marginBottom: 32,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'right',
    marginBottom: 16,
    gap: '24px',
  },
};
