import axios from 'axios';
import apiConfig from '../api.config';

export default function doorOpeningDownloadTemplateRequest({ lang = 'en', fileName = 'template' }) {
  const {
    host,
    URIs: { doorOpeningDownloadTemplate },
  } = apiConfig;

  const uri = `${host}${doorOpeningDownloadTemplate}?lang=${lang}`;

  return axios.get(uri, { responseType: 'blob' }).then(res => {
    const href = URL.createObjectURL(res.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
}
