import axios from 'axios';
import apiConfig from '../api.config';

export default function updateAppearenceRequest({
  hotelId,
  bgColor,
  color1,
  color2,
  kioskBgColor,
  kioskTitleColor,
  font,
  languages,
  selectedHotelId,
  isKiosk = false,
}) {
  const {
    host,
    URIs: { appearenceUpdate },
  } = apiConfig;

  const uri = `${host}${appearenceUpdate}`;

  return axios
    .put(
      uri,
      JSON.stringify({
        bgColor,
        mainColor: color1,
        secondaryColor: color2,
        kioskBgColor,
        kioskTitleColor,
        font,
        languages,
        hotelId,
        selectedHotelId,
        isKiosk,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(res => {
      return res.data;
    });
}
