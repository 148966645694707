import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { notification } from 'antd';
import useCheckInExportHook from '../../../utils/export/CheckInExport.hook';
import { ApiContext } from '../../../utils/networking/Api.context';
import { useLocation } from 'react-router-dom';
import { AppContext } from 'App.context';
import moment from 'moment';

export default function useCheckInListHook() {
  const { API } = useContext(ApiContext);
  const { checkinExportActions } = useCheckInExportHook();
  const location = useLocation();
  const {
    appState,
    appActions: { getSelectedHotel },
  } = useContext(AppContext);

  const [state, setState] = useState({
    loading: true,
    dataSource: {
      items: [],
      totalItems: 0,
      totalPages: 0,
      currentPage: 0,
      pageSize: 10,
    },
    filters: location?.state?.bookingId ? { booking_Id: location?.state.bookingId } : {},
    sort: null,
    datesRange: getDefaultDateRange(),
    checkinId: null,
  });

  var activeRequests = 0;

  useEffect(() => {
    actions.loadCheckins();
  }, [state.dataSource.currentPage, state.filters, state.datesRange, appState.selectedHotel]);

  const actions = {
    loadCheckins: () => {
      activeRequests = activeRequests++;
      updateState({ loading: true });
      API.checkinsRequest(
        state.dataSource?.currentPage,
        state.dataSource?.pageSize,
        state.filters,
        state.sort,
        state.datesRange,
        getSelectedHotel()
      )
        .then(data => {
          activeRequests = activeRequests--;
          updateState({ dataSource: data, loading: false });
        })
        .catch(error => {
          console.warn(error);
          if (activeRequests == 1) {
            updateState({ loading: false });
          }
          activeRequests--;
        });
    },

    onChange: filters => {
      var newPage,
        newPageSize = -1;
      var searchFilters;
      var sortInfo;
      if (state.dataSource?.currentPage !== filters?.pagination.page) {
        newPage = filters?.pagination.page;
      }
      if (state.dataSource?.pageSize !== filters?.pagination.pageSize) {
        newPageSize = filters?.pagination.pageSize;
      }
      if (filters?.where) {
        const code = filters?.where['booking.code'];
        const hotel = filters?.where['hotel'];
        searchFilters = { code, hotel };
      }
      if (filters?.sort) {
        const sortField =
          typeof filters.sort?.sortField === 'string'
            ? filters.sort?.sortField
            : filters.sort?.sortField?.join('.');
        const order = filters.sort?.sortOrder;
        var code;

        switch (sortField) {
          case 'booking.entrance':
            code = 'entrance';
            break;
          case 'booking.departure':
            code = 'departure';
            break;
          case 'sent_to_pms':
            code = 'sent_to_pms';
            break;
          case 'hotel':
            code = 'hotel';
            break;
          default:
            break;
        }

        sortInfo = code && order ? `${code} ${order}` : null;
      }
      updateState({
        dataSource: {
          ...state.dataSource,
          currentPage: newPage >= 0 ? newPage : state.dataSource?.currentPage,
          pageSize: newPageSize >= 0 ? newPageSize : state.dataSource?.pageSize,
        },
        filters: searchFilters,
        sort: sortInfo,
      });
    },

    onDateChange: (_date, dateString) => {
      updateState({ datesRange: dateString });
    },

    export: () => {
      const dateFrom = state.datesRange[0];
      const dateTo = state.datesRange[1];

      if (!dateFrom || !dateTo) {
        notification.info({
          message: 'Debe seleccionar un rango de fechas para realizar la exportación',
        });
        return;
      }
      checkinExportActions.export(appState.selectedHotel, dateFrom, dateTo);
    },

    onRowClicked: (item, _index) => {
      if (!item?.uuid) {
        notification.warn({
          message: 'No es posible visualizar la información en detalle',
        });
      }
      updateState({ checkinId: item?.uuid });
    },

    resetCheckinDetail: () => {
      updateState({ checkinId: null });
    },
  };

  function getDefaultDateRange() {
    return [moment().subtract(1, 'day').startOf('day'), moment().subtract(0, 'day').endOf('day')];
  }

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
