import { Checkbox } from 'antd';
import Colors from 'constants/Colors';
import React from 'react';
import styles from './CustomCheckbox.styles';

export default function CustomCheckbox({ text, onClick, value, disabled, subtitle }) {
  return (
    <div style={styles.container} onClick={() => onClick && !disabled && onClick()}>
      <Checkbox checked={value} disabled={disabled} />
      <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 8 }}>
        <div style={{ color: Colors.titleColor }}>{text}</div>
        <div style={{ fontWeight: 'bold', color: Colors.titleColor }}>{subtitle}</div>
      </div>
    </div>
  );
}
