import Colors from 'constants/Colors';

export default {
  mainContainer: {
    width: '100%',
    marginTop: 16,
    paddingRight: 32,
    paddingLeft: 32,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: Colors.titleColor,
    fontWeight: 'bold',
  },
  buttonsContainer: { display: 'flex', flexDirection: 'row', gap: '24px' },
  tableContainer: {
    display: 'flex',
    width: '100%',
    flex: 1,
    marginTop: 16,
    marginBottom: 32,
  },
  table: {
    backgroundColor: 'white',
    marginLeft: 32,
    marginRight: 32,
    width: '100%',
    borderRadius: 14,
    padding: 14,
    minHeight: '70vh',
  },
  popoverContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400,
    gap: 8,
    alignItems: 'center',
  },
  popoverImage: {
    maxWidth: 200,
  },
};
