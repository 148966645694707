import axios from 'axios';
import apiConfig from 'utils/networking/api.config';

/**
 * @param {*} action
 * undefined == testAll { mockGuests, bookingIdentifier }
 * sendData { idCheckinGuestList, mockGuests, supportedFields }
 * resendGuestData { bookingIdentifier, idCheckinGuestList }
 * @returns
 */
export default async function guestRequest({
  hotelId = null,
  action = null,
  authorization = null,
  mockGuests = null,
  bookingIdentifier = null,
  idCheckinGuestList = null,
  supportedFields = null,
}) {
  const {
    validationsHost,
    URIs: {
      validations: { guest },
    },
  } = apiConfig;

  let uri = `${validationsHost}${guest}`
    .replace(':hotelId', hotelId)
    .replace(':action', action || '');

  if (!action) uri = uri.slice(0, -1);

  return axios
    .post(
      uri,
      { mockGuests, bookingIdentifier, idCheckinGuestList, supportedFields },
      {
        headers: { Authorization: authorization },
      }
    )
    .then(res => {
      return res.data;
    });
}
