import { DownloadOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import React, { useContext } from 'react';
import { buildAuthMediaUrl } from 'utils/networking/oauth/authMedias';
import { AppContext } from '../../../../../App.context';
import { Row } from '../../../../common/Forms/Row/Row';
import CheckInInput from '../CheckInInput/CheckInInput';
import styles from './CheckInBookingDetail.styles';

export default function CheckInBookingDetail({ checkinId, isLoading, data, hotelName }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const entrance = `${data?.entrance}`;
  const departure = `${data?.departure}`;
  const uploadDocument1 = data?.guest?.documents?.uploadDocument1;
  const uploadDocument2 = data?.guest?.documents?.uploadDocument2;

  return (
    <Card type={'inner'} loading={isLoading} title={translate('checkInList.detail.bookingDetail')}>
      <Row>
        {hotelName && <CheckInInput title={translate('hotel')} value={hotelName} />}
        <CheckInInput title={translate('checkInList.detail.locator')} value={checkinId} />
        <CheckInInput title={translate('checkInList.detail.checkin')} value={entrance} />
        <CheckInInput title={translate('checkInList.detail.checkout')} value={departure} />
      </Row>
      {data?.cardexUrl || data?.contractUrl || uploadDocument1 || uploadDocument2 ? (
        <div>
          <div style={styles.divider}>
            <div>{translate('checkInList.detail.signedDocuments')}</div>
            <div style={styles.dividerBar} />
          </div>
          <Row>
            {data?.cardexUrl && (
              <div style={styles.button} onClick={() => downloadFile(data?.cardexUrl)}>
                <DownloadOutlined />
                {translate('checkInList.detail.cardex')}
              </div>
            )}
            {data?.contractUrl && (
              <div style={styles.button} onClick={() => downloadFile(data?.contractUrl)}>
                <DownloadOutlined />
                {translate('checkInList.detail.termsConditions')}
              </div>
            )}
            {uploadDocument1 && uploadDocument1.url && (
              <div style={styles.button} onClick={() => downloadFile(uploadDocument1.url)}>
                <DownloadOutlined />
                {translate(uploadDocument1.text)}
              </div>
            )}
            {uploadDocument2 && uploadDocument2.url && (
              <div style={styles.button} onClick={() => downloadFile(uploadDocument2.url)}>
                <DownloadOutlined />
                {translate(uploadDocument2.text)}
              </div>
            )}
          </Row>
        </div>
      ) : null}
    </Card>
  );

  function downloadFile(link) {
    window.open(buildAuthMediaUrl(link));
  }
}
