import { AppContext } from 'App.context';
import { useState, useEffect, useContext } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import { Modal, Button, Space, notification } from 'antd';
import { validateEmail } from 'utils/TextUtils';

export default function useUsersHook() {
  const { confirm } = Modal;
  const [state, setState] = useState({
    users: null,
    isLoading: false,
    name: null,
    email: null,
  });
  const { API } = useContext(ApiContext);
  const {
    appState,
    appActions: { translate },
  } = useContext(AppContext);

  useEffect(() => {
    actions.loadUsers();
  }, []);

  const actions = {
    loadUsers: () => {
      updateState({ isLoading: true });
      API.userListRequest({ ownerId: appState.ownerId })
        .then(res => {
          updateState({ users: res, isLoading: false });
        })
        .catch(_ => {
          updateState({ isLoading: false });
        });
    },

    validateFieldsAndSend: () => {
      if (!state.name || !state.email) {
        notification.error({ message: translate('user.emptyFields') });
      } else {
        if (!validateEmail(state.email)) {
          notification.error({
            message: translate('recover.invalidEmail'),
          });
        } else {
          actions.showSendInvitationWarning();
        }
      }
    },

    showSendInvitationWarning: () => {
      confirm({
        title: translate('user.sendInvitationWarning').replace('#', state?.email),
        onOk() {
          actions.sendInvitation();
        },
        onCancel() {
          return;
        },
      });
    },

    sendInvitation: () => {
      updateState({ isLoading: true });
      API.sendUserInvitationRequest({
        name: state.name,
        email: state.email,
        ownerId: appState.ownerId,
      })
        .then(res => {
          updateState({ isLoading: false });
          notification.success({ message: translate('user.sendInviteSuccess') });
          actions.loadUsers();
        })
        .catch(err => {
          updateState({ isLoading: false });
          notification.error({
            message: err?.message,
          });
        });
    },

    setField: (fieldKey, value) => {
      updateState({ [fieldKey]: value });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
