import Colors from 'constants/Colors';

export default {
  upload: {
    border: 0,
    background: 'none',
    cursor: 'pointer',
  },
  uploadText: {
    marginTop: 8,
  },
  description: {
    flex: 1,
    fontSize: 14,
    marginTop: 8,
    marginBottom: 8,
    color: Colors.darkText,
  },
};
