import Colors from 'constants/Colors';

export default {
  mainContainer: {
    width: '100%',
    marginTop: 16,
    paddingRight: 32,
    paddingLeft: 32,
    display: 'flex',
    justifyContent: 'center',
  },
  header: {
    height: 32,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 8,
    marginRight: 52,
    marginBottom: 16,
    float: 'right',
  },
  boxesContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: 16,
    gap: 8,
    height: 172,
  },
  boxStatContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    color: Colors.titleColor,
  },
  content: {
    backgroundColor: 'white',
    marginRight: 16,
    width: '100%',
    borderRadius: 14,
    padding: 14,
    minHeight: '70vh',
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
};
