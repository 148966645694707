import React, { useContext } from 'react';
import { AppContext } from 'App.context';
import { Card, Typography, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styles from './EmptyTable.styles.js';
import { ReactComponent as Icon } from 'Sell/assets/icon/emptyItemList.svg';

const { Title, Paragraph } = Typography;

export default function EmptyTable({ onButtonClick }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <Card style={styles.tableCard}>
      <div style={styles.tableBox}>
        <div style={styles.emptyIcon}>{<Icon />}</div>
        <Title style={styles.tableTitle} level={4}>
          {translate('crossSell.createItem')}
        </Title>
        <Paragraph style={styles.tableText}>
          {translate('crossSell.createItemDescription')}
        </Paragraph>
        <Button
          style={styles.tableButton}
          type="primary"
          icon={<PlusOutlined />}
          onClick={onButtonClick}
        >
          {translate('crossSell.createItem')}
        </Button>
      </div>
    </Card>
  );
}
