export const slotTempId = `temp-id-`;

export const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const itemTypes = [
  { id: 'service', name: 'crossSell.itemTypes.service' },
  { id: 'good', name: 'crossSell.itemTypes.good' },
  { id: 'transfer', name: 'crossSell.itemTypes.transfer' },
];

export const tripTypes = [
  {
    id: 'one-way',
    name: 'crossSell.itemDetail.slot.tripType.oneWay',
  },
  {
    id: 'return',
    name: 'crossSell.itemDetail.slot.tripType.return',
  },
  {
    id: 'round-trip',
    name: 'crossSell.itemDetail.slot.tripType.roundTrip',
  },
];
