import Colors from 'constants/Colors';

export default {
  container: {
    display: 'flex',
    padding: 32,
    flexDirection: 'column',
  },
  whiteBackground: {
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 14,
    padding: 14,
    minHeight: '70vh',
    marginBottom: 32,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
    gap: 16,
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  datePicker: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${Colors.lightGray}`,
  },
  header: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
};
