export default {
  tableContainer: {
    backgroundColor: 'white',
    marginLeft: 32,
    marginRight: 32,
    width: '100%',
    borderRadius: 14,
    padding: 14,
    minHeight: '70vh',
    marginBottom: 32,
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusIndicator: {
    borderRadius: 32,
    height: 18,
    width: 18,
  },
  layoutContainer: {
    display: 'flex',
    width: '100%',
    flex: 1,
    marginTop: 16,
  },
  mainContainer: {
    width: '100%',
    paddingRight: 32,
    paddingLeft: 32,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    justifyContent: 'flex-end',
    width: '100%',
  },
};
