export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    gap: 22,
  },
  deleteIcon: {
    fill: '#fff',
    width: 20,
    height: 20,
    cursor: 'pointer',
    margin: 8,
    position: 'absolute',
    filter: 'drop-shadow(2px 2px 2px #000)',
    right: 0,
  },
  galleryContainer: {
    display: 'grid',
    gridTemplateAreas: `
      'item0 item0 item0 item0'
      'item1 item2 item3 item4'
    `,
    gridColumnGap: 20,
    gridRowGap: 10,
    flexBasis: '60%',
    flexGrow: 1,
  },
  item0: { position: 'relative', gridArea: 'item0', width: '100%' },
  item1: { position: 'relative', gridArea: 'item1', width: 110, height: 60 },
  item2: { position: 'relative', gridArea: 'item2', width: 110, height: 60 },
  item3: { position: 'relative', gridArea: 'item3', width: 110, height: 60 },
  item4: { position: 'relative', gridArea: 'item4', width: 110, height: 60 },
  uploadContainer: {},
};
