import axios from 'axios';
import apiConfig from '../../api.config';

export default function setDoorOpeningOauth({ hotelId, code, keyType}) {
  const {
    host,
    integrationHubHost,
    URIs: {doorOpening:{
        setDoorOpeningOauth,
    }},
  } = apiConfig;

  const uri = `${host}${setDoorOpeningOauth}`;

  const data = {
    code,
    keyType,
    hotelId
  }
  return axios
    .post(uri, JSON.stringify({ data }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
