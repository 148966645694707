import { useContext, useEffect } from 'react';
import { useState } from 'react';
import Colors from '../../../constants/Colors';
import bookingOauthRequest from '../../../utils/networking/oauth/booking.com/oauthBooking.service.js';
import constants from '../../../utils/constants';
import { notification } from 'antd';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../../App.context';
import apiConfig from '../../../utils/networking/api.config';
import { ApiContext } from '../../../utils/networking/Api.context';
import { useHistory } from 'react-router-dom';

export default function useSalesChannelsHook() {
  const {
    appState,
    appActions: { translate, setSelectedHotel, getSelectedHotel },
  } = useContext(AppContext);
  const history = useHistory();

  const { API } = useContext(ApiContext);
  const location = useLocation();

  const [state, setState] = useState({
    isLoading: true,
    dataSource: {
      items: [],
      totalItems: 0,
      totalPages: 0,
      currentPage: 0,
      pageSize: 10,
    },
    filters: {},
    identifiers: {},
    identifierError: {},
    showOTAPopup: false,
    ota: location?.state?.ota || null,
    hotelSlug: location?.state?.hotelSlug || null,
  });

  var activeRequests = 0;

  useEffect(() => {
    if (appState.hotelList) {
      if (location?.state?.ota && location?.state?.hotelSlug) {
        var selectedHotel = appState.hotelList.find(
          hotel => hotel.slug == location?.state?.hotelSlug
        );
        const firstHotel = {
          key: selectedHotel.id,
          value: selectedHotel.name,
        };
        setSelectedHotel(firstHotel);
        updateState({ showOTAPopup: true });
      }
    }
  }, [appState.hotelList]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const callback = params.get('callback');
    if (callback) {
      switch (callback) {
        case constants.STRIPE.BOOKING_SUCCESS:
          actions.connectBooking(params.get('hotelId'), params.get('hotelFilter'));
          break;
        case constants.BOOKINGDOTCOM.REDIRECT_PATH:
          actions.authBooking(params.get('code'), params.get('hotelId'), params.get('hotelFilter'));
          break;
        default:
          actions.loadSalesChannels();
          break;
      }
    } else {
      actions.loadSalesChannels();
    }
  }, [state.dataSource.currentPage, state.filters, appState.selectedHotel]);

  const actions = {
    loadSalesChannels: () => {
      activeRequests = activeRequests++;
      updateState({ isLoading: true });

      API.salesChannelsRequest(
        state.dataSource?.currentPage,
        state.dataSource?.pageSize,
        state.filters,
        state.sort,
        getSelectedHotel()
      )
        .then(data => {
          activeRequests = activeRequests--;
          const parsedChannels = [];
          data.items.forEach(item => {
            item.clients.forEach(client => {
              const row = {
                id: client.id,
                hotelId: item.id,
                hotel: item.name,
                hotelSlug: item.slug,
                client: client,
                premium: item.premium,
                smtpCredentials: item.smtpCredentials,
              };

              if (client?.enabled || client?.suscribed) {
                parsedChannels.unshift(row);
              } else if (client.name === 'MTS' && !client.exists) {
                // no-op
              } else {
                parsedChannels.push(row);
              }
            });
          });
          data.items = parsedChannels;

          let otaHotelItem;
          if (location?.state?.ota && location?.state?.hotelSlug) {
            otaHotelItem = data.items.find(item => item.hotelSlug == location?.state?.hotelSlug);
          }

          updateState({ dataSource: data, isLoading: false, otaHotelItem });
        })
        .catch(error => {
          console.warn(error);
          if (activeRequests == 1) {
            updateState({ isLoading: false });
          }
          activeRequests--;
        });
    },

    onChange: filters => {
      var newPage,
        newPageSize = -1;
      var searchFilters;
      if (state.dataSource?.currentPage !== filters?.pagination.page) {
        newPage = filters?.pagination.page;
      }
      if (state.dataSource?.pageSize !== filters?.pagination.pageSize) {
        newPageSize = filters?.pagination.pageSize;
      }
      if (filters?.where) {
        const hotel = filters?.where['hotel'];
        const channel = filters?.where['client.name'];
        searchFilters = { hotel, channel };
      }

      updateState({
        dataSource: {
          ...state.dataSource,
          currentPage: newPage >= 0 ? newPage : state.dataSource?.currentPage,
          pageSize: newPageSize >= 0 ? newPageSize : state.dataSource?.pageSize,
        },
        filters: searchFilters,
      });
    },
    

    getClientStateString: client => {

      if (client.client_id === constants.IH_CLIENT.BOOKINGDOTCOM) {
        if (
          state.dataSource.items.some(
            item => item.smtpCredentials && Object.keys(item.smtpCredentials).length > 0
          )
        ) {
          return translate('states.enabled');
        }
      }

      if (client.enabled) {
        return translate('states.enabled');
      } else if (client.suscribed) {
        return translate('states.pending');
      } else {
        return translate('states.disabled');
      }
    },

    getClientStateTextColor: client => {

      if (client.client_id === constants.IH_CLIENT.BOOKINGDOTCOM) {
        if (
          state.dataSource.items.some(
            item => item.smtpCredentials && Object.keys(item.smtpCredentials).length > 0
          )
        ) {
          return Colors.greenHotelIntegratedText;
        }
      }

      if (client.enabled) {
        return Colors.greenHotelIntegratedText;
      } else if (client.suscribed) {
        return Colors.yellowHotelNoIntegratedText;
      } else {
        return Colors.redDesactivedText;
      }
    },

    getClientStateBackgroundColor: client => {

      if (client.client_id === constants.IH_CLIENT.BOOKINGDOTCOM) {
        if (
          state.dataSource.items.some(
            item => item.smtpCredentials && Object.keys(item.smtpCredentials).length > 0
          )
        ) {
          return Colors.greenHotelIntegratedBg;
        } 
      }

      if (client.enabled) {
        return Colors.greenHotelIntegratedBg;
      } else if (client.suscribed) {
        return Colors.yellowHotelNoIntegratedBg;
      } else {
        return Colors.redDesactivedBg;
      }
    },

    getIdentifier: channel => {
      const valuesForHotel = state.identifiers[channel?.hotelId];
      return valuesForHotel
        ? valuesForHotel[channel?.client.client_id]
        : channel?.client?.custom_hotel_id;
    },

    setIdentifier: (hotelId, channelId, value) => {
      const values = state.identifiers;
      if (values[hotelId]) {
        values[hotelId][channelId] = value;
      } else {
        values[hotelId] = [];
        values[hotelId][channelId] = value;
      }

      updateState({ identifiers: values, identifierError: {} });
    },

    onConfigureChannelPressed: channel => {
      const customIdentifier = actions.getIdentifier(channel);
      switch (channel.client.client_id) {
        case constants.IH_CLIENT.BOOKINGDOTCOM:
          if (customIdentifier) {
            actions.startStripeSuscription(
              channel.hotelId,
              channel?.client?.client_id,
              customIdentifier,
              channel.hotel
            );
          } else {
            actions.setInputIdentifierAsError(channel.hotelId, channel.client.client_id);
          }
          break;
        default:
          notification.warning({ message: 'Canal de venta no implementado!' });
          break;
      }
    },

    startStripeSuscription: (hotelId, clientId, customHotelId, filter) => {
      const { host } = apiConfig;

      document.getElementById('hotelId').value = hotelId;
      document.getElementById('clientId').value = clientId;
      document.getElementById('customHotelId').value = customHotelId;
      document.getElementById('hotelFilter').value = filter;
      document.getElementById('type').value = constants.SUSCRIPTION_TYPE.IH_CLIENT;
      const stripePayment = document.getElementById('StripeBookingPayment');
      stripePayment.action = `${host}stripe/create-checkout-session`;
      stripePayment.submit();
    },

    /* connectBooking: channel => {
      const customIdentifier = actions.getIdentifier(channel);
      if (customIdentifier) {
        // TODO
        
         * Booking.com doesn't require a Stripe suscription in order to be connected till end of august.
           It will be treated as a premium suscription.
        

        actions.createPremiumSuscription(
          channel.hotelId,
          channel?.client?.client_id,
          customIdentifier
        );

        /* if (channel.premium) {
          actions.createPremiumSuscription(
            channel.hotelId,
            channel?.client?.client_id,
            customIdentifier
          );
        } else {
          actions.redirectToBookingAuth(channel.hotelId, channel.hotelFilter);
        } 
      } else {
        actions.setInputIdentifierAsError(channel.hotelId, channel.client.client_id);
      }
    },

    authBooking: (code, hotelId, hotelFilter) => {
      bookingOauthRequest(code, hotelId)
        .then(data => {
          window.location.replace(`${constants.DOMAIN_URL}/channels`);
        })
        .catch(error => {
          window.location.replace(`${constants.DOMAIN_URL}/channels`);
        });
    },

    redirectToBookingAuth: (hotelId, hotelFilter) => {
      window.location.replace(
        `${constants.BOOKINGDOTCOM.OAUTH_URL}?response_type=code&client_id=${constants.BOOKINGDOTCOM.CLIENT_ID}&redirect_uri=${constants.DOMAIN_URL}/booking-oauth?hotelId=${hotelId}&hotelFilter=${hotelFilter}`
      );

      // TEST Booking.com in local
      // window.location.replace(
      //   'http://localhost:3000/booking-oauth?hotelId=757&code=JTkq1FP1mCV7MmxLDtW9h6SMqcCTjZ624L44LAJhu2t'
      // );
    },*/

    shouldShowInputIdentifierAsError: (hotelId, channelId) => {
      return state.identifierError[hotelId] == [channelId];
    },

    setInputIdentifierAsError: (hotelId, channelId) => {
      const error = {};
      error[hotelId] = channelId;

      updateState({ identifierError: error });
    },

    handlePopoverVisibility: () => {
      updateState({ identifierError: {} });
    },

    createPremiumSuscription: (hotelId, clientId, customIdentifier) => {
      API.createPremiumSuscription({
        hotelId,
        clientId,
        customIdentifier,
        type: constants.SUSCRIPTION_TYPE.IH_CLIENT,
      })
        .then(data => {
          // actions.redirectToBookingAuth(hotelId);
        })
        .catch(err => console.error(err));
    },

    changeOTAState: channel => {
      const enabled = channel.client.enabled ? 0 : 1;
      API.updateSalesChannelRequest(channel.client.id, enabled, channel.id)
        .then(() => {
          notification.success({
            message: translate('salesChannels.success'),
          });
          actions.loadSalesChannels();
        })
        .catch(error => console.error(error));
    },

    ctaChangeOTAState: () => {
      actions.setShowOTAPopup(false);
      actions.changeOTAState(state.otaHotelItem);
    },

    setShowOTAPopup: value => {
      updateState({ showOTAPopup: value });
    },

    addPaperlessUser: (hotelSlug, IH_CLIENT_ID) => {
      history.push({
        pathname: '/register-paperless',
        search: `?hotel_slug=${hotelSlug}&ih_client=${IH_CLIENT_ID}`,
      });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  console.log(state);
  return { state, actions };
}
