import { AppContext } from 'App.context';
import React, { useContext } from 'react';
import styles from './StepsGraph.styles';

export default function StepsGraph({ steps }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <div style={{ flex: 1, width: '100%' }}>
      <div style={styles.title}>{translate(`steps.title`)}</div>
      <div style={styles.wrapper}>
        {steps.map((step, index) => {
          return <StepItem key={index} step={step} />;
        })}
      </div>
    </div>
  );

  function StepItem({ step }) {
    return (
      <div style={{ ...styles.item, flex: 1 / steps.length }}>
        <div style={styles.textContainer}>
          <div style={styles.name}>{step.name}</div>
          <div style={styles.percentage}>{`${step.percentage}%`}</div>
        </div>
        <div
          style={{
            ...styles.columnContainer,
            flexGrow: step.percentage / 100,
          }}
        >
          <div style={styles.column}></div>
        </div>
      </div>
    );
  }
}
