import { notification } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../../App.context';
import Colors from '../../../constants/Colors';
import constants from '../../../utils/constants';
import apiConfig from '../../../utils/networking/api.config';
import { ApiContext } from '../../../utils/networking/Api.context';

const usePMSHook = () => {
  const {
    appState,
    appActions: { translate, getSelectedHotel },
  } = useContext(AppContext);
  const history = useHistory();
  const { API } = useContext(ApiContext);

  const [state, setState] = useState({
    hotels: null,
    pmsList: null,
    selectedHotel: null,
    isLoading: true,
    formInputs: null,
    isLoadingForm: false,
    pmsSelected: [],
    expandedRow: null,
  });

  useEffect(() => {
    actions.loadHotels();
    actions.loadPMSList();
  }, []);

  useEffect(() => {
    actions.filterByHotel();
  }, [state.allHotels, appState.selectedHotel]);

  useEffect(() => {
    if (state.hotels && state.pmsList) {
      updateState({ isLoading: false });

      const params = new URLSearchParams(window.location.search);
      const callback = params.get('callback');
      if (callback) {
        switch (callback) {
          case constants.STRIPE.PMS_SUCCESS:
            const hotelId = params.get('hotelId');
            const pmsId = params.get('pmsId');
            actions.onConfigurePMS(hotelId, pmsId);
            break;
          case constants.PMS.OAUTH_SUCCESS:
            actions.setPMSOauth(params.get('code'), params.get('state'));
            params.delete('code');
            params.delete('state');
            history.replace({
              code: params.toString(),
              state: params.toString(),
            });
            break;
          default:
            break;
        }
      }
    }
  }, [state.hotels, state.pmsList]);

  const actions = {
    loadHotels: () => {
      API.pmsHotelsRequest()
        .then(res => {
          updateState({ allHotels: res });
        })
        .catch(err => {
          console.warn(err);
        });
    },

    loadPMSList: () => {
      API.pmsListRequest()
        .then(res => {
          updateState({ pmsList: res });
        })
        .catch(err => {
          console.warn(err);
        });
    },

    getColorStateFromPMS: hotel => {
      if (hotel.pmsCheckin && hotel.pmsCheckin != 'NoIntegration') {
        return Colors.greenHotelIntegratedText;
      } else if (hotel.pmsIdSuscribed) {
        return Colors.yellowHotelNoIntegratedText;
      } else {
        return Colors.redDesactivedText;
      }
    },

    getBackgroundColorStateFromPMS: hotel => {
      if (hotel.pmsCheckin && hotel.pmsCheckin != 'NoIntegration') {
        return Colors.greenHotelIntegratedBg;
      } else if (hotel.pmsIdSuscribed) {
        return Colors.yellowHotelNoIntegratedBg;
      } else {
        return Colors.redDesactivedBg;
      }
    },

    getTextStateFromPMS: hotel => {
      if (hotel.pmsCheckin && hotel.pmsCheckin != 'NoIntegration') {
        return translate('states.enabled');
      } else if (hotel.pmsIdSuscribed) {
        return translate('states.pending');
      } else {
        return translate('states.disabled');
      }
    },

    setPmsSelected: (rowID, pms) => {
      const currentlySelected = state.pmsSelected;
      currentlySelected[rowID] = pms;
      updateState({ pmsSelected: currentlySelected });
    },

    getSelectedPms: rowID => {
      return state.pmsSelected[rowID];
    },

    onSuscribePMS: (hotelId, pmsId) => {
      const { host } = apiConfig;

      document.getElementById('hotelId').value = hotelId;
      document.getElementById('pmsId').value = pmsId;
      document.getElementById('type').value = constants.SUSCRIPTION_TYPE.PMS;
      const stripePayment = document.getElementById('StripePMSPayment');
      stripePayment.action = `${host}stripe/create-checkout-session`;
      stripePayment.submit();
    },

    setHotelPmsApiConfig: stateParam => {
      const { hotelId, pmsId } = JSON.parse(stateParam);
      const pmsName = state.pmsList.find(pms => pms.id == pmsId)?.name;

      API.pmsConfigHotelRequest(hotelId, pmsName)
        .then(res => actions.onPmsConfigSuccess())
        .catch(err => console.error(err));
    },

    onConfigurePMSAsPremium: (hotelId, pmsId) => {
      actions.createPremiumSuscription(hotelId, pmsId);
    },

    onConfigurePMS: (hotelId, pmsId) => {
      var redirect_uri = window.location.origin;
      //FIXME: test
      // if (process.env.REACT_APP_ENV == 'development') {
      //   redirect_uri = 'http://localhost:3000';
      // }

      switch (parseInt(pmsId)) {
        case constants.PMS.CLOUDBEDS:
          window.location.replace(
            `${constants.CLOUDBEDS.OAUTH_URL}?client_id=civitfun&redirect_uri=${redirect_uri}/pms?callback=${constants.PMS.OAUTH_SUCCESS}&response_type=code&state={"hotelId":${hotelId},"pmsId":${pmsId}}&scope=${constants.CLOUDBEDS.SCOPE_PARAMS}`
          );
          break;
        case constants.PMS.APALEO:
          window.location.replace(
            `${constants.APALEO.OAUTH_URL}?client_id=RHFS-AC-CIVITFUN&response_type=code&state={"hotelId":${hotelId},"pmsId":${pmsId}}&scope=${constants.APALEO.SCOPE_PARAMS}&redirect_uri=${redirect_uri}/pms?callback=${constants.PMS.OAUTH_SUCCESS}`
          );
          break;
        default:
          console.warn('Configure PMS not implemented!');
          break;
      }
    },

    setPMSOauth: (code, stateParams) => {
      const { hotelId, pmsId } = JSON.parse(stateParams);
      const pms = state.pmsList?.find(pms => pms.id == pmsId);
      const params = {
        hotelId,
        pmsId,
        pmsName: pms.name,
        code,
        mode: pms.connectionType,
      };
      updateState({ isLoading: true });
      API.pmsConfigHotelRequest(params)
        .then(res => {
          updateState({ isLoading: false });
          notification.success({ message: translate('pmsScreen.connectSuccess') });
          actions.loadHotels();
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
          notification.warning({ message: translate('pmsScreen.connectError') });
        });
    },

    createPremiumSuscription: (hotelId, pmsId) => {
      API.createPremiumSuscription({
        hotelId,
        pmsId,
        type: constants.SUSCRIPTION_TYPE.PMS,
      })
        .then(_data => {
          actions.onConfigurePMS(hotelId, pmsId);
        })
        .catch(_err => notification.warning({ message: translate('pmsScreen.connectError') }));
    },

    filterByHotel: () => {
      if (!state.allHotels) return;
      let dataSet;
      if (!getSelectedHotel()?.key) {
        dataSet = state.allHotels;
      } else {
        const filteredHotels = state.allHotels?.filter(
          hotel => hotel.name === getSelectedHotel()?.value
        );
        dataSet = filteredHotels;
      }

      dataSet.forEach(element => {
        element.key = element.id;
      });

      updateState({ hotels: dataSet });
    },

    onExpanded: (rowId, expanded) => {
      if (state.expandedRow === rowId) {
        updateState({ expandedRow: null });
      } else {
        updateState({ expandedRow: rowId });
      }
    },

    getPmsMode: (rowId, name) => {
      const pmsId = actions.getSelectedPms(rowId);

      let pms;
      if (pmsId) {
        pms = state.pmsList.find(pms => pms.id === pmsId);
      } else if (name) {
        pms = state.pmsList.find(pms => pms?.name.toLowerCase() === name.toLowerCase());
      }

      return pms?.connectionType;
    },

    setPmsCheckin: (hotelId, name) => {
      const updatedData = state.hotels;
      for (const hotel of updatedData) {
        if (hotel.id === hotelId) {
          hotel.pmsCheckin = name;
        }
      }

      updateState({ hotel: updatedData });
    },

    getPmsFromName: name => {
      const pms = state.pmsList.find(pms => pms.name.toLowerCase() === name.toLowerCase());
      return pms;
    },

    isRowExpandable: record => {
      if (actions.getPmsMode(record.id, record.pmsCheckin) === 'form') {
        return true;
      } else {
        const pms = state.pmsList.find(
          pms => pms?.name?.toLowerCase() === record?.pmsCheckin?.toLowerCase()
        );

        return pms?.healthCheckBooking === 1 || pms?.healthCheckConnection === 1;
      }
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
};

export default usePMSHook;
