import { Image } from 'antd';
import React from 'react';
import styles from './Visor.styles';

const Visor = ({ item }) => {
  let type;

  switch (item.type) {
    case 'service':
      type = 'service';
      break;
    case 'transfer':
      type = 'transfer';
      break;
    case 'good':
    default:
      type = 'product';
      break;
  }

  return (
    <div style={styles.container}>
      <div style={styles.innerComponent}>
        <Image src={require(`../../../assets/images/visor-preview.${type}.png`)} preview={false}></Image>
      </div>
    </div>
  );
};

export default Visor;
