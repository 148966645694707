const useGuestHook = () => {
  const snakeToCamel = snakeValue => {
    return snakeValue.replace(/_([a-z])/g, function (match, group1) {
      return group1.toUpperCase();
    });
  };

  const snakeToCapitalizedWithSpace = snakeStr => {
    return snakeStr
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .replace(/^./, str => str.toUpperCase());
  };

  return { snakeToCamel, snakeToCapitalizedWithSpace };
};

export default useGuestHook;
