import React from 'react';
import { Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { copyTextToClipboard } from 'utils/TextUtils';
import Colors from 'constants/Colors';

export default function LinkGenerator({ url }) {
  const formattedUrl = url
    .replaceAll(
      '[',
      `<span style='color:${Colors.blueIntense};display:inline-block;font-weight: bold;padding:4px;margin-top:2px;background-color:#fff;text-transform: uppercase';>[`
    )
    .replaceAll(']', `]</span>`);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8, marginBottom: 16, gap: 16 }}>
      <div
        style={{
          backgroundColor: Colors.grayBackground,
          border: `1px solid ${Colors.lightGray}`,
          flex: 1,
          padding: 8,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{ color: Colors.darkText }}
          dangerouslySetInnerHTML={{ __html: formattedUrl }}
        />
      </div>
      <div style={{ alignSelf: 'center' }}>
        <Button
          type="primary"
          shape="square"
          ghost
          icon={<CopyOutlined />}
          style={{ color: Colors.superUserButton, borderColor: Colors.lightGray }}
          onClick={() => copyTextToClipboard(url)}
        />
      </div>
    </div>
  );
}
