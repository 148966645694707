import React, { useContext } from 'react';
import usePoliceReportConfigHook from './PoliceReportConfig.hook.js';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout.jsx';
import { AppContext } from 'App.context.js';
import ContentBox from 'Components/common/ContentBox/ContentBox.jsx';
import PoliceSFTP from './PoliceSFTP/PoliceSFTP.jsx';
import PoliceSES from './PoliceSES/PoliceSES.jsx';
import { PoliceReportConfigContext } from './PoliceReportConfig.context';
import AppModal from 'Components/common/AppModal/AppModal.jsx';
import Colors from 'constants/Colors.js';
import AppButton from 'Components/common/Forms/AppButton/AppButton.jsx';

export default function PoliceReportConfig() {
  const { state, actions } = usePoliceReportConfigHook();
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <DefaultLayout title={translate('layout.policeReportConfig')} enableAllHotels={false}>
      <PoliceReportConfigContext.Provider value={{ state, actions }}>
        {state.suscription ? (
          <>
            <ContentBox title={translate('policeReport.sftpConfig')}>
              <PoliceSFTP />
            </ContentBox>
            <ContentBox title={translate('policeReport.sesConfig')}>
              <PoliceSES />
            </ContentBox>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                backgroundColor: Colors.white,
                borderRadius: 16,
                padding: 16,
                maxWidth: 600,
              }}
            >
              <div style={{ fontWeight: 'bold' }}>
                {translate('policeReport.missingSuscription.title')}
              </div>
              <div>{translate('policeReport.missingSuscription.description')}</div>
              {state.sendSuscriptionRequestEnabled && (
                <div style={{ display: 'flex', justifyContent: 'right', marginTop: 8 }}>
                  <AppButton
                    text={translate('policeReport.missingSuscription.send')}
                    action={() => actions.requestGuestlinkFunctionality()}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </PoliceReportConfigContext.Provider>
    </DefaultLayout>
  );
}
