import React, { useContext } from 'react';
import styles from './MailSubjectEditor.styles.js';
import useMailSubjectEditorHook from './MailSubjectEditor.hook.js';
import TagInserter from '../../TagInserter/TagInserter.jsx';
import { AppContext } from 'App.context.js';
import Input from 'antd/lib/input/Input';

export default function MailSubjectEditor({ value, onValueChange }) {
  const { actions } = useMailSubjectEditorHook(onValueChange);
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <div>
      <div style={styles.title}>{translate('mailTemplates.subject')}</div>
      <div style={styles.container}>
        <div style={styles.input}>
          <Input
            ref={ref => actions.setInputRef(ref)}
            bordered={false}
            value={value}
            placeholder={translate('mailTemplates.subject')}
            onChange={e => {
              actions.onTextChange(e.target.value);
            }}
          />
        </div>
        <TagInserter
          onInsertClicked={actions.onInsertClicked}
          hint={translate('tagInserter.subjectHint')}
          scope={'email_subject'}
        />
      </div>
    </div>
  );
}
