import axios from 'axios';
import apiConfig from '../api.config';
import constants from '../../constants';
import storage from '../../storage';

export default function deleteHotelLicence(licence) {
  const {
    billingHost,
    URIs: { deleteHotelLicence },
  } = apiConfig;

  const uri = `${billingHost}${deleteHotelLicence}`.replace(':hotelLicenceId', licence.id);

  const storedCredentials = JSON.parse(storage.get(constants.AUTH.USER_KEY));

  return axios
    .delete(uri, {
      data: {
        accessToken: storedCredentials.accessToken,
        hotelReference: 'civitfun',
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
