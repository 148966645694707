import DefaultLayout from 'Components/DefaultLayout/DefaultLayout';
import React, { useContext } from 'react';
import styles from './CommunicationChannels.styles';
import useCommunicationChannelsHook from './CommunicationChannels.hook';
import { Select } from 'antd';
import { GlobalOutlined, FileSearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Loading from 'Components/common/Loader/Loading';
import { AppContext } from 'App.context';
import ContentBox from 'Components/common/ContentBox/ContentBox';
import TextEditor from 'utils/TextEditor/TextEditor';
import SupportedCommunicationChannels from './SupportedCommunicationChannels';
import InfoView from 'Components/common/InfoView/InfoView';
import EditableTagGroup from 'Components/common/EditableTagGroup/EditableTagGroup';
import AppButton from 'Components/common/Forms/AppButton/AppButton';

export default function CommunicationChannels() {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useCommunicationChannelsHook();

  const languagesMenu = state.languages?.map(language => {
    return (
      <Select.Option key={language?.code} value={language?.code}>
        {state.selectedLanguage == language?.code && <GlobalOutlined />}
        {`  ${language?.label}`}
      </Select.Option>
    );
  });

  return (
    <DefaultLayout
      contentHeader={
        <div style={styles.header}>
          <div style={styles.langSelectorContainer}>
            <div>
              {state.languages && (
                <Select
                  defaultValue={state.languages[0].code}
                  style={styles.selector}
                  onChange={option => actions.onLanguageSelected(option)}
                >
                  {languagesMenu}
                </Select>
              )}
              {translate('legalDocuments.langSelectorHint')}
            </div>
            {/* <div style={styles.switchContainer}>
                <CustomSwitch text={'Mail'} icon={<MailOutlined />} />
                <CustomSwitch text={'Whatsapp'} icon={<MessageOutlined />} />
                <CustomSwitch text={'SMS'} icon={<PhoneOutlined />} />
              </div> */}
          </div>
        </div>
      }
    >
      <ContentBox noPadding title={translate('communicationChannels.documentsBoxTitle')}>
        {!(!state.isLoading && state.legalDocuments) ? (
          <div style={{ display: 'flex', justifyConten: 'center' }}>
            {state.isLoading ? (
              <Loading />
            ) : (
              <InfoView text={translate('emptyHotelListError')} icon={<InfoCircleOutlined />} />
            )}
          </div>
        ) : (
          <div style={styles.editorContainer}>
            {state.legalDocuments.map((legalDocumentSet, index) => {
              const mainLegalDocument = legalDocumentSet?.find(
                document => document.lang == state.selectedLanguage
              );
              const typeConfig = SupportedCommunicationChannels.find(
                legalType => legalType.id == mainLegalDocument?.type
              );

              return typeConfig?.isVisible ? (
                <TextEditor
                  key={index}
                  isFirst={index == 0}
                  type={mainLegalDocument.type}
                  onPreviewPressed={actions.onPreviewPressed}
                  onSavePressed={actions.onSavePressed}
                  documentDefaultValue={mainLegalDocument.value}
                  supportedTypes={SupportedCommunicationChannels}
                  selectedLanguage={state.selectedLanguage}
                  disabled={typeConfig.disabled}
                />
              ) : null;
            })}
          </div>
        )}
      </ContentBox>

      <ContentBox noPadding title={translate('legalDocuments.preview')}>
        {state.previewText ? (
          <div
            style={{ textAlign: 'justify', minHeight: 480 }}
            dangerouslySetInnerHTML={{ __html: state.previewText }}
          />
        ) : (
          <div style={{ minHeight: 480 }}>
            <InfoView
              text={translate('legalDocuments.previewPlaceholder')}
              icon={<FileSearchOutlined />}
            />
          </div>
        )}

        <div>
          {state.isLoading || !state.legalDocuments ? null : (
            <>
              <div style={styles.separator}></div>
              <div style={styles.addressTitle}>
                {translate('communicationChannels.senderCheckinEmailHeader')}
              </div>
              <EditableTagGroup
                addLabel={translate('communicationChannels.addNewMail')}
                tags={state.senderCheckinEmail}
                onTagsChange={actions.setSenderCheckinEmail}
                max={1}
              />
              <div style={styles.addressTitle}>
                {translate('communicationChannels.emailCheckinHeader')}
              </div>
              <EditableTagGroup
                addLabel={translate('communicationChannels.addNewMail')}
                tags={state.emailCheckin}
                onTagsChange={actions.setEmailCheckin}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  gap: 8,
                  marginTop: 16,
                }}
              >
                <AppButton action={() => actions.onSaveEmailsPressed()} text={translate('save')} />
              </div>
            </>
          )}
        </div>
      </ContentBox>
    </DefaultLayout>
  );
}
