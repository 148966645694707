import { AppContext } from 'App.context';
import React, { useContext } from 'react';
import useDevicesGraph from './DevicesGraph.hook';
import styles from './DevicesGraph.styles';

export default function DevicesGraph({ devices, checkinTotal }) {
  const { state, actions } = useDevicesGraph(devices, checkinTotal);
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <div style={styles.container}>
      <div style={styles.title}>{`${translate('stats.devices.title')}:`}</div>
      <div style={styles.graphContainer}>
        {state.dataset?.map((item, index) => {
          return <GraphItem key={index} item={item} />;
        })}
      </div>
    </div>
  );

  function GraphItem({ item }) {
    return (
      <div style={{ ...styles.item, flex: 1 / state.dataset.length }}>
        <div
          style={{ ...styles.column, backgroundColor: item.color, flexGrow: item.percentage / 100 }}
        />
        <div style={{ ...styles.iconContainer, backgroundColor: item.color }}>
          <img
            style={{ height: 10, width: 10 }}
            src={require(`../../../../assets/images/icons/white/${item.type}.png`)}
          />
        </div>
        <div style={styles.typeName}>{translate(`stats.devices.${item.type}`)}</div>
        <div style={styles.percentage}>{`${item.percentage}%`}</div>
      </div>
    );
  }
}
