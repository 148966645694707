import axios from 'axios';
import apiConfig from '../api.config';

var CancelToken = axios.CancelToken;
var cancel;

export default function checkinsRequest(page, size, filters, sort, datesRange, hotel) {
  const {
    host,
    URIs: { checkins },
  } = apiConfig;

  var filter = { page, size, ...filters };
  if (sort) filter.order = sort;
  if (datesRange[0] && datesRange[1]) filter.datesRange = datesRange;
  if (hotel && hotel.key != 'null') filter.hotel = hotel.key;

  const uri = `${host}${checkins}?filter=${JSON.stringify(filter)}`;

  if (cancel) cancel();

  return axios
    .get(uri, {
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      }),
    })
    .then(res => {
      return res.data;
    });
}
