export default {
  AUTH: {
    USER_KEY: 'user',
    VALIDATE_USER_PATH: 'validate-user',
  },
  CHECKIN_MODULE: [
    'NoIntegration',
    'Phantom',
    'Timon',
    'GuestPortal',
    'Avalon2',
    'Standard2',
    'Poseidon',
    'API',
    'Civitfun',
    'Cloudbeds',
    'Noray',
    'Porthotels',
    'Mews',
    'Sihot',
    'Avalon',
    'WinhotelTest',
    'Tecsoft',
    'Winhotel',
    'Opera',
    'Excellence',
    'UlysesCloud',
  ],
  PMS_FORMAT: ['soap', 'json', 'soap12'],
  FORM_FIELDS: {
    STATE: ['active', 'disabled', 'removed'],
    TYPE: ['email', 'text', 'date', 'numeric', 'select', 'checkbox'],
    FORM: ['checkin', 'guest', 'noShow', 'noShowGuest'],
  },
  GOOGLE_MAPS_KEY: 'AIzaSyDZBd2AENTXf1aiDRPTm4NNR1olCtxu8YU',
  MAP_LIBRARIES: ['places'],
  DEFAULT_MAP_POSITION: {
    lat: 28.46459560988099,
    lng: -16.254673871899968,
  },
  BOOKINGDOTCOM: {
    IH_CLIENT_NAME: 'BOOKING.COM',
    OAUTH_URL: 'https://hub-api.booking.com/v1/oauth2/authorize',
    REDIRECT_PATH: 'booking-oauth',
    ADMIN_LINK: 'https://admin.booking.com/',
    CLIENT_ID:
      process.env.REACT_APP_ENV === 'production'
        ? 'd037ccd43d4c0788fa7d04f7d0b0b248'
        : 'e07ac10564a932cee13cb7aeb24ab82b',
  },
  IH_CLIENT: {
    CIVITFUN: 1,
    MTS: 2,
    ROOMMATIK: 3,
    BOOKINGDOTCOM: 4,
    KIOSK: 6,
    RECEPTION: 7,
  },
  STRIPE: {
    BOOKING_SUCCESS: 'booking-stripe-success',
    PMS_SUCCESS: 'pms-stripe-success',
  },
  SUSCRIPTION: {
    BOOKINGDOTCOM: 1,
  },
  DOMAIN_URL:
    process.env.REACT_APP_ENV === 'production'
      ? 'https://dashboard.civitfun.com'
      : 'https://dashboard.pre.civitfun.com',
  API_URL:
    process.env.REACT_APP_ENV === 'production'
      ? 'https://dashboard-api.civitfun.com'
      : 'https://dashboard-api.pre.civitfun.com',
  IMAGE_HOST:
    process.env.REACT_APP_ENV === 'production'
      ? 'https://integration-hub.civitfun.com/medias/v1/public/logo'
      : 'https://integration-hub.pre.civitfun.com/medias/v1/public/logo',
  dateFormat: 'YYYY/MM/DD',
  SUSCRIPTION_TYPE: {
    IH_CLIENT: 'ihclient',
    PMS: 'pms',
  },
  CLOUDBEDS: {
    OAUTH_URL: 'https://hotels.cloudbeds.com/api/v1.1/oauth',
    ACCESS_TOKEN_URL:
      'https://hotels.cloudbeds.com/api/v1.1/access_token?grant_type=authorization_code&client_id=civitfun&client_secret=MyAlsZey9lEyH3wkvXt9NgfRQzSWz9vK',
    SCOPE_PARAMS:
      'read%3Aadjustment+write%3Aadjustment+read%3Aitem+write%3Aitem+read%3AcustomFields+write%3AcustomFields+read%3Aguest+write%3Aguest+read%3Ahotel+read%3AhouseAccount+read%3Ahousekeeping+read%3Apayment+write%3Apayment+read%3Areservation+write%3Areservation+read%3Aroom+write%3Aroom',
  },
  APALEO: {
    OAUTH_URL: 'https://identity.apaleo.com/connect/authorize',
    SCOPE_PARAMS:
      'offline_access integration:ui-integrations.manage folios.read folios.manage reservations.manage reservations.read',
  },
  PMS: {
    OAUTH_SUCCESS: 'pms-oauth-success',
    CLOUDBEDS: 8,
    APALEO: 26,
    NO_INTEGRATION: 'NoIntegration',
  },
  AWS: {
    ACCESS_KEY_ID: '',
    SECRET_ACCESS_KEY: '',
    QUICKGUIDE_BUCKET: '',
  },
  GUESTLINK_SUSCRIPTION: {
    ACTIVE: 'active',
    REQUESTED: 'requested',
  },
  LEGAL_DOCUMENTS: {
    DEFAULT_FILE_NAME_FORMAT: '[checkin_date]_[hotel_slug]_[booking_identifier]_[document_number]',
  },
  NOREPLY_CIVITFUN_EMAIL: 'noreply@civitfun.com',
  KEY_WITH_OAUTH: ['yacan'],
  YACAN_OAUTH_LINK: `https://core.yacan.pro/oauth/authorize?response_type=code&client_id=`, 
  YACAN_CLIENT_ID: "9c657451-52a4-49c2-a771-6899515e58d1"// clientID always the same
};
