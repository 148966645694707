import Colors from 'constants/Colors';

export default {
  wrapper: {
    padding: 8,
    backgroundColor: Colors.createPMSBg,
    color: Colors.titleColor,
  },
  description: {
    marginBottom: 8,
    fontSize: 12,
    textAlign: 'left',
    flexWrap: 'wrap',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    marginRight: 8,
    fontSize: 12,
  },
  createInput: {
    flex: 0.5,
    fontSize: 12,
    backgroundColor: 'white',
    padding: 8,
    borderRadius: 8,
    border: `1px solid ${Colors.lightGray}`,
  },
};
