import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import CustomButton from 'Components/common/CustomButton/CustomButton';
import Colors from 'constants/Colors';

const MenuButton = ({ path, text, IconComponent }) => {
  const { url } = useRouteMatch();

  return (
    <Link to={`${url}${path}`}>
      <CustomButton
        background={Colors.white}
        color={Colors.darkText}
        text={text}
        fontSize={12}
        leftComponent={<IconComponent style={{ fill: 'black' }} />}
      />
    </Link>
  );
};

export default MenuButton;
