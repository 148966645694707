import React from 'react';
import styles from './AppButton.module.css';

export default function AppButton({
  text,
  action,
  secondary,
  setShowPopConfirm,
  applyToAllHotels = false,
  icon,
  disabled,
  secondayIcon,
  redButton = null,
  ...props
}) {
  let styleProp = {};
  let textStyleProp = {
    style: {
      textAlign: 'center',
      color: 'white',
    }
  };

  if (redButton) {
    styleProp = {
      style: { backgroundColor: '#B42318', borderColor: '#FDA29B' },
    };
    textStyleProp.style.color = 'white';
  }

  if (secondary) {
    styleProp = {
      style: { backgroundColor: '#F0F3F5' },
    };
    textStyleProp.style.color = '#708393';
  }

  return (
    <div
      className={styles.buttonContainer}
      {...styleProp}
      style={disabled ? { opacity: '0.5', pointerEvents: 'none' } : secondary ? { backgroundColor: '#F0F3F5' } : null}
      onClick={() => {
        if (applyToAllHotels) {
          setShowPopConfirm ? setShowPopConfirm(true) : action(applyToAllHotels);
        } else {
          action && !disabled && action(applyToAllHotels);
        }
      }}
    >
      {icon && icon}
      {text && (
        <b
          {...textStyleProp}
          unselectable="true"
        >
          {text}
        </b>
      )}
      {secondayIcon && secondayIcon}
    </div>
  );
}
