import Colors from 'constants/Colors';

export default {
  container: {
    backgroundColor: Colors.joditBg,
    padding: 16,
    paddingBottom: 16,
    borderRadius: 8,
    marginBottom: -2,
  },
};
