import defaultRenderEmpty from 'antd/lib/config-provider/defaultRenderEmpty';
import { AppContext } from 'App.context';
import { useState, useContext, useEffect } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import { groupByKey } from 'utils/TextUtils';
import moment from 'moment';

export default function useDoorOpeningFormHook(updateEnabledHotels, keyTypes) {
  const {
    appActions: { translate },
    appState,
  } = useContext(AppContext);
  const [state, setState] = useState({
    isLoading: false,
    data: null,
    doorOpeningConfigData: {},
    doorOpeningConfig: {},
  });

  useEffect(() => {
    updateState({
      configCheckinStatusOptions: [
        { key: translate('doorOpening.checkinStatusOptions.indifferent'), value: 'indifferent' },
        { key: translate('doorOpening.checkinStatusOptions.checkedIn'), value: 'checkedin' },
      ],
      configPaymentStatusOptions: [
        { key: translate('doorOpening.paymentStatusOptions.indifferent'), value: 'indifferent' },
        { key: translate('doorOpening.paymentStatusOptions.paid'), value: 'paid' },
      ],
      configKeyTypes:
        keyTypes?.split(',').map(keyType => ({
          key: translate(`doorOpening.keyTypes.${keyType}`),
          value: keyType,
        })) ?? [],
    });
  }, [, appState.currentLanguage, keyTypes]);

  const { API } = useContext(ApiContext);

  const actions = {
    loadDoorOpeningConfig: async (doorOpening, hotelId) => {
      updateState({ isLoading: true });
      try {
        let doorOpeningConfigData = {
          checkinStatus: 'indifferent',
          paymentStatus: 'indifferent',
          checkinHour: null,
          checkoutHour: null,
          keyType: null,
          checkinHourGMT: null,
          checkoutHourGMT: null,
        };
        const { fields, config } = await API.getHotelDoorOpeningConfig({
          platform: doorOpening.code,
          doorOpeningId: doorOpening.id,
          hotelId,
        });
        let hourCheckinValid = "";
        let hourCheckoutValid = "";

        if (config.configCheckinHourGMT) {
          hourCheckinValid = moment.utc(config.configCheckinHourGMT, 'H:mm');
      }
      
      if (config.configCheckoutHourGMT) {
          hourCheckoutValid = moment.utc(config.configCheckoutHourGMT, 'H:mm');
      }

        

        doorOpeningConfigData = {
          checkinHour: hourCheckinValid,
          checkoutHour: hourCheckoutValid,
          checkinStatus: config.configCheckinStatus,
          paymentStatus: config.configPaymentStatus,
          keyType: config.keyType ?? keyTypes?.split(',')[0],
        };
       
        updateState({ data: fields, doorOpeningConfigData, isLoading: false });
      } catch (err) {
        updateState({ isLoading: false });
      }
    },

    onValueChanged: (key, text, splitValues) => {
      const dataCopy = state.data;
      for (const field of dataCopy) {
        if (field.key === key) {
          field.value = text;
          if (splitValues) {
            field.splitValues = splitValues;
          }
        }
      }

      updateState({ data: dataCopy });
    },

    onConfigValueChanged: (key, value) => {
      if (key === 'checkinHour' || key === 'checkoutHour') {
        value = value ? moment(value) : null;
      }
      setState(previousState => {
        const doorOpeningConfigData = { ...previousState.doorOpeningConfigData, [key]: value };
        return { ...previousState, doorOpeningConfigData };
      });
    },

    getFieldValue: key => {
      const index = state.data.findIndex(x => x.key === key);
      return state.data[index]?.value;
    },

    onSave: async (doorOpening, hotelId) => {
      updateState({ isLoading: true });
      const parameters = actions.formatParameters(state.data);
      const doorOpeningConfig = { ...state.doorOpeningConfigData };

      const hotel = await API.getOneHotel({
        hotelId,
      });
  
    
      doorOpeningConfig.checkinHour = updateDoorOpeningConfigHour(doorOpeningConfig.checkinHour) || null;
      doorOpeningConfig.checkoutHour = updateDoorOpeningConfigHour(doorOpeningConfig.checkoutHour) || null;
    
      API.updateHotelDoorOpeningConfig({
        hotelId,
        platform: doorOpening,
        parameters,
        doorOpeningHotelConfig: doorOpeningConfig,
      })
        .then(res => {
          updateState({ isLoading: false });
          updateEnabledHotels && updateEnabledHotels();
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },

    formatParameters: data => {
      const parameters = {};
      for (const field of data) {
        parameters[field.key] = field.value;
      }

      return parameters;
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  function formatHour(hour, minute) {
    const validHour = Number.isInteger(hour) ? hour : 0;
    const validMinute = Number.isInteger(minute) ? minute : 0;
    return `${validHour.toString().padStart(2, '0')}:${validMinute.toString().padStart(2, '0')}`;
  }
  
  function updateDoorOpeningConfigHour(time) {
    if (time) {
      const momentTime = moment.isMoment(time) ? time : moment(time);
      const [hour, minute] = momentTime.format('H:mm').split(':').map(Number);
      return formatHour(hour, minute);
    }
    return null;
  }

  

  return { state, actions };
}
