import axios from 'axios';
import apiConfig from '../api.config';

export default function policeReportCheckSFTPConnection(data) {
  const {
    policeReportIntegrationHost,
    URIs: { policeReportCheckSFTPConnection },
  } = apiConfig;

  const uri = `${policeReportIntegrationHost}${policeReportCheckSFTPConnection}`;

  const uninterceptedAxiosInstance = axios.create();
  return uninterceptedAxiosInstance
    .post(uri, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: '+t7wIM6!-d7SEz$/?IG&7aHRkZvN#9',
      },
    })
    .then(res => {
      return res.data;
    });
}
