import React, { useContext, useRef, useEffect } from 'react';
import ContentBox from '../../common/ContentBox/ContentBox';
import DefaultLayout from '../../DefaultLayout/DefaultLayout';
import { AppContext } from '../../../App.context';
import styles from './LegalDocuments.styles';
import useLegalDocumentsHook from './LegalDocuments.hook';
import Loading from 'Components/common/Loader/Loading';
import TextEditor from 'utils/TextEditor/TextEditor';
import { Select } from 'antd';
import { GlobalOutlined, FileSearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import InfoView from 'Components/common/InfoView/InfoView';
import FileNameEditor from './FileNameEditor/FileNameEditor';

export default function LegalDocuments() {
  const iframeRef = useRef(null);

  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useLegalDocumentsHook();

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      const adjustIframeHeight = () => {
        const iframeHeight = iframe.contentWindow.document.body.scrollHeight + 45;
        iframe.style.height = `${iframeHeight}px`;
      };

      iframe.addEventListener("load", adjustIframeHeight);

      return () => iframe.removeEventListener("load", adjustIframeHeight);
    }
  }, [state.previewText]); // Reajusta la altura cuando cambie previewText

  const languagesMenu = state.languages?.map(language => {
    return (
      <Select.Option key={language?.code} value={language?.code}>
        {state.selectedLanguage == language?.code && <GlobalOutlined />}
        {`  ${language?.label}`}
      </Select.Option>
    );
  });

  const CURRENT_DOCUMENTS = actions.getCurrentSupportedDocuments();

  return (
    <DefaultLayout
      contentHeader={
        <div style={styles.header}>
          <div style={styles.langSelectorContainer}>
            {state.languages && (
              <Select
                value={state.selectedLanguage}
                style={styles.selector}
                onChange={option => actions.onLanguageSelected(option)}
              >
                {languagesMenu}
              </Select>
            )}
            {translate('legalDocuments.langSelectorHint')}
          </div>
        </div>
      }
    >
      <ContentBox noPadding title={'Editor'}>
        {!(!state.isLoading && state.legalDocuments) ? (
          <div style={{ display: 'flex', justifyConten: 'center' }}>
            {state.isLoading ? (
              <Loading />
            ) : (
              <InfoView text={translate('emptyHotelListError')} icon={<InfoCircleOutlined />} />
            )}
          </div>
        ) : (
          <div style={styles.editorContainer}>
            {state.legalDocuments.map((legalDocumentSet, index) => {
              const mainLegalDocument = legalDocumentSet?.find(
                document => document.lang == state.selectedLanguage
              );
              const typeConfig = CURRENT_DOCUMENTS.find(
                legalType => legalType.id == mainLegalDocument.type
              );
              const labelSet = state.legalDocuments.find(
                set => set[0].type == `${mainLegalDocument.type}-label`
              );
              const labelDocument = labelSet?.find(
                document => document.lang == state.selectedLanguage
              );

              return typeConfig.isVisible ? (
                <div>
                  {/* typeConfig.customFileName */}
                  <TextEditor
                    key={index}
                    isFirst={index == 0}
                    type={mainLegalDocument.type}
                    onPreviewPressed={actions.onPreviewPressed}
                    onSavePressed={actions.onSavePressed}
                    documentDefaultValue={mainLegalDocument.value}
                    labelDefaultValue={labelDocument?.value}
                    supportedTypes={CURRENT_DOCUMENTS}
                    selectedLanguage={state.selectedLanguage}
                  />
                </div>
              ) : null;
            })}
          </div>
        )}
      </ContentBox>

      <ContentBox title={translate('legalDocuments.preview')} noPadding>
        {state.previewText ? (
          <iframe
            title={translate('legalDocuments.preview')}
            style={{ width: '100%', border: 'none' }}
            srcDoc={state.previewText}
            ref={iframeRef}
            onLoad={() => {
              const iframe = iframeRef.current;
              if (iframe && iframe.contentWindow) {
                const iframeHeight = iframe.contentWindow.document.body.scrollHeight;
                iframe.style.height = `${iframeHeight}px`;
              }
            }}
          />
        ) : (
          <div style={{ minHeight: 480 }}>
            <InfoView
              text={translate('legalDocuments.previewPlaceholder')}
              icon={<FileSearchOutlined />}
            />
          </div>
        )}
      </ContentBox>
    </DefaultLayout>
  );
}
