import React, { useContext, useEffect, useState } from 'react';
import { Layout, Menu, Typography, notification } from 'antd';
import { QueryClient, QueryClientProvider } from 'react-query';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout';
import styles from 'Sell/CrossSell/Shell/Shell.styles';
import NestedRoutes from 'Sell/CrossSell/Shell/NestedRoutes';
import MenuButton from 'Sell/Components/MenuButton';
import { ReactComponent as RoomTypeIcon } from 'Sell/assets/icon/room-type-icon.svg';
import { ReactComponent as CrossSellStatusIcon } from 'Sell/assets/icon/upsell-status-icon.svg';
import { ReactComponent as ReportsIcon } from 'Sell/assets/icon/reports-icon.svg';
import Routes from 'Sell/CrossSell/Router/routes';
import { AppContext } from 'App.context';
import Colors from 'constants/Colors';
import AppButton from 'Components/common/Forms/AppButton/AppButton';
import { ApiContext } from 'utils/networking/Api.context';
import Loading from 'Components/common/Loader/Loading';

const { Sider, Content } = Layout;

const queryClient = new QueryClient();

export default function CrossSellShell() {
  const {
    appState: {selectedHotel},
    appActions: { translate, getSelectedHotel, setHotelLanguages },
  } = useContext(AppContext);

  const { API } = useContext(ApiContext);

  const [state, setState] = useState({
    isLoading: true,
    suscription: null,
    sendSuscriptionRequestEnabled: true,
    hotelLanguages: []
  });

  useEffect(() => {
    getSuscription();
    loadHotelLanguages(getSelectedHotel()?.key);
  }, [, selectedHotel]);

  const CrossSellView = (
    <Layout style={styles.transparentSider}>
      {' '}
      <Sider width={200} style={styles.transparentSider}>
        <Menu mode="vertical" theme="light" style={styles.transparentSider}>
          <Menu.Item style={styles.customMenuItem} key="1">
            <MenuButton
              path={Routes.itemsList}
              text={translate('crossSell.menu.items')}
              IconComponent={RoomTypeIcon}
            />
          </Menu.Item>
          <Menu.Item style={styles.customMenuItem} key="2">
            <MenuButton
              path={Routes.crossSellsList}
              text={translate('crossSell.menu.status')}
              IconComponent={CrossSellStatusIcon}
            />
          </Menu.Item>
          <Menu.Item style={styles.customMenuItem} key="3">
            <MenuButton
              path="/reports"
              text={translate('crossSell.menu.report')}
              IconComponent={ReportsIcon}
            />
          </Menu.Item>
        </Menu>
      </Sider>
      <Content>
        <NestedRoutes />
      </Content>{' '}
    </Layout>
  );

  const LicenseView = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          backgroundColor: Colors.white,
          borderRadius: 16,
          padding: 16,
          maxWidth: 600,
        }}
      >
        <div style={{ fontWeight: 'bold' }}>
          {translate('missingSuscription.title').replace('$1', 'CrossSelling')}
        </div>
        <div>{translate('missingSuscription.description').replaceAll('$1', 'CrossSelling')}</div>
        {state.sendSuscriptionRequestEnabled && (
          <div style={{ display: 'flex', justifyContent: 'right', marginTop: 8 }}>
            <AppButton
              text={translate('missingSuscription.send')}
              action={() => requestFunctionality()}
            />
          </div>
        )}
      </div>
    </div>
  );
  return (
    <QueryClientProvider client={queryClient}>
      <DefaultLayout>
        {state.isLoading ? <Loading></Loading> : state.suscription ? CrossSellView : LicenseView}
      </DefaultLayout>
    </QueryClientProvider>
  );

  function getSuscription() {
    API.getAccountFunctionalities({ hotelId: getSelectedHotel()?.key })
      .then(res => {
        const crossSellModule = res.find(item => item.key == 'cross-sell_module');
        updateState({ isLoading: false, suscription: crossSellModule.active }); // FIXME: replace to force show
      })
      .catch(err => {
        console.warn(err);
        updateState({ isLoading: false });
      });
  }

  function requestFunctionality() {
    updateState({ isLoading: true });
    API.requestFunctionalityContract({
      hotelId: getSelectedHotel().key,
      suscription: 'cross-sell_module',
      functionality: 'CrossSell Module',
    })
      .then(_ => {
        sendRequestFunctionalityEmail();
      })
      .catch(_ => {
        updateState({ isLoading: false });
      });
  }

  function sendRequestFunctionalityEmail() {
    updateState({ isLoading: true });
    API.sendRequestFuncionalityEmail({
      hotelId: getSelectedHotel().key,
      functionality: 'CrossSell Module',
    })
      .then(_ => {
        notification.success({
          message: translate('missingSuscription.requestSent'),
        });
        updateState({ isLoading: false, sendSuscriptionRequestEnabled: false });
      })
      .catch(_ => {
        updateState({ isLoading: false });
      });
  }

  function loadHotelLanguages(hotelId ) {
    if (!hotelId) return;
    updateState({ isLoading: true });
    API.appearenceRequest(hotelId)
      .then(res => {
        const mapIdToCode = id => {
          const map = {
            1: "en",
            2: "es",
            3: "de",
            4: "ca",
            5: "fr",
            6: "pt",
            7: "it",
            8: "ja",
            9: "zh",
            10: "pt_BR",
            11: "ru",
            12: "de"
          };
          return map[id] || "en";
        }

        const selectedLanguages = res.languages.map(language => {
          return {id: mapIdToCode(language.id), name: language.label};
        });

        setHotelLanguages(selectedLanguages);
      })
      .catch(err => {
        console.warn(err);
        updateState({ isLoading: false });
        notification.error({
          message: err?.response?.data?.message,
        });
      });
  }

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }
}
