export default {
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    gap: 24,
    padding: '17px 13px',
  },
  datePicker: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 12,
  },

  countersBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 27,
  },
  breakdownBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    justifyContent: 'space-between',
  },
};
