import React from 'react';
import styles from './WelcomeInput.module.css';
import { Input } from 'antd';

export default function WelcomeInput({ icon, onChange, type, ...props }) {
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>{icon}</div>
      <div className={styles.textContainer}>
        {type === 'password' ? (
          <Input.Password
            bordered={false}
            onChange={e => {
              onChange && onChange(e.target.value);
            }}
            {...props}
          />
        ) : (
          <Input
            bordered={false}
            onChange={e => {
              onChange && onChange(e.target.value);
            }}
            {...props}
          />
        )}
      </div>
    </div>
  );
}
