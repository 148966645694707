export default `<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:21px;font-family:&quot;Calibri&quot;,sans-serif;">Termes i condicions&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:18px;font-family:&quot;Calibri&quot;,sans-serif;">INFORMACIÓ LEGAL&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;">
    <span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">
        Per la seva seguretat, garantim que les dades de la seva targeta de crèdit seran tractades d’acord amb els estàndards internacionals PCI-DSS. Autoritzo l’hotel a carregar les despesses totals de la meva estada a l’hotel a la targeta de crèdit mencionada anteriorment. En cas que deixi l’hotel en una data anterior a la indicada en la reserva que figura en aquest document, autoritzo que l’import total de les nits contractades, incloent les no utilitzades, sigui carregat a la targeta de crèdit.<br><br>
        A [hotel_name], estem compromesos amb la protecció de la seva privadesa. Pot contactar amb nosaltres a [address] o a l’adreça de correu electrònic [hotel_email]. [hotel_name] processarà les seves dades per gestionar la seva estada a l’hotel i millorar la seva experiència com a hoste. A més, [hotel_name] processarà les seves dades per proporcionar serveis d’allotjament i serveis complementaris. Podríem necessitar processar les seves dades per complir amb les obligacions legals a les quals estem subjectes (incloent la prevenció de fraus). [hotel_name] podrà comunicar les seves dades a tercers, com bancs o altres institucions financeres per processar pagaments o a agències de seguretat per complir amb les obligacions imposades per la llei.<br><br>
        Si s’inscriu, [hotel_name] processarà les seves dades personals per oferir-li els serveis de fidelització corresponents. En aquest sentit, processarem els punts generats i és possible que li enviem informació relacionada amb els seus punts i els beneficis del programa, ajustats al seu perfil.<br><br>
        Pot exercir els seus drets d'accés, rectificació, supressió, oposició i/o limitació del tractament, així com la portabilitat de les seves dades, escrivint a [hotel_name] a l’adreça postal o de correu electrònic indicada anteriorment. Ha d’acreditar la seva identitat i indicar en ambdós casos (a) "Protecció de Dades" com a assumpte i (b) si la seva sol·licitud va dirigida a [hotel_name]. A més, pot contactar en qualsevol moment amb l'autoritat nacional de supervisió competent.<br><br>
        Per a més informació, accedeixi a [hotel_web] o consulti a la recepció de l’hotel.
    </span>
</p>`;
