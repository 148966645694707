import Checkbox from 'antd/lib/checkbox/Checkbox';
import Colors from 'constants/Colors.js';
import React from 'react';
import GuestFormFieldState from '../../GuestForms/GuestFormFieldState.js';
import DynamicFormsRowType from '../DynamicFormsRowType.js';
import styles from './DynamicFormsValue.styles.js';

const { FIELD } = DynamicFormsRowType;

export default function DynamicFormsValue({
  disabled,
  isLastItem,
  isGroup,
  size,
  setFieldValue,
  guestType,
  field,
  rowType,
  value,
}) {
  return (
    <div
      style={{
        ...styles.container,
        borderLeft: !isGroup ? `1px solid ${Colors.lightGray}` : null,
        borderBottom: !isGroup ? `1px solid ${Colors.lightGray}` : null,
        borderRight: isLastItem ? `1px ${Colors.lightGray} solid` : null,
        borderBottomRightRadius: isLastItem && rowType !== FIELD ? 8 : 0,
        borderTopRightRadius: isLastItem && rowType !== FIELD ? 8 : 0,
        backgroundColor: isGroup ? Colors.dynamicFormColor : Colors.white,
        minWidth: size,
        maxWidth: size,
      }}
    >
      <Checkbox
        checked={value.mandatory || value.state === GuestFormFieldState.ACTIVE}
        disabled={disabled}
        onClick={() =>
          setFieldValue({
            guestType,
            fieldName: field,
            active:
              value.state === GuestFormFieldState.ACTIVE
                ? GuestFormFieldState.DISABLED
                : GuestFormFieldState.ACTIVE,
            rowType,
          })
        }
      />
      <Checkbox
        checked={value.mandatory || value.required}
        disabled={disabled}
        onClick={() => {
          setFieldValue({
            guestType,
            fieldName: field,
            required: value.required === 1 ? 0 : 1,
            rowType,
          });
        }}
      />
    </div>
  );
}
