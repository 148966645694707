export const roomsList = '/rooms';
export const roomDetail = '/rooms/view/:id';
export const roomCreate = '/rooms/create';
export const roomUpsert = '/rooms/upsert/:id';

export const upsellsList = '/upsells';
export const upsellDetail = '/upsells/view/:id';

export const reports = '/reports';

export default {
  roomsList,
  roomDetail,
  roomCreate,
  roomUpsert,
  upsellsList,
  upsellDetail,
  reports,
};
