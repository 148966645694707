import Colors from 'constants/Colors';

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
  },
  counter: isMaxLength => ({
    display: 'flex',
    alignItems: 'flexStart',
    color: isMaxLength ? 'red' : Colors.darkText,
    fontSize: 12,
  }),
};
