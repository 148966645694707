export default {
    menu: {
        items: 'Items',
        status: 'Cross-Selling status',
        report: 'Reports',
    },
    type: {
        service: 'Service',
        good: 'Product',
        transfer: 'Transfer',
    },
    items: 'Items',
    pax: 'Pax',
    to: 'to',
    itemId: 'Item Id',
    status: 'Status',
    picture: 'Image',
    itemType: 'Item type',
    itemTitle: 'Item title',
    hotelName: 'Hotel name',
    createItem: 'Create item',
    confirmationType: 'Confirmation type',
    automatic: 'Automatic',
    manual: 'Manual',
    description: 'Description',
    photoGallery: 'Photo gallery',
    dragAndDrop: 'Drag your files here or click to select them',
    maxFileSize: 'Maximum file size: 2.5MB',
    maxFileNumber: 'The maximum allowed is 5 images.',
    uploadsuccess: 'Uploaded successfully',
    uploaderror: 'Upload error',
    dealId: 'ID',
    locator: 'Locator',
    guestName: 'Guest name',
    itemName: 'Item name',
    slot: 'Slot',
    date: 'Date',
    typesOfRoomsCreated: 'Tipos de habitaciones creadas',
    typeOfPrice: 'Price type',
    price: 'Price',
    totalPrice: 'Total price',
    deleteItem: {
        title: 'Are you sure you want to delete this item?',
        content: 'The selected item will be deleted',
        okText: 'Delete item',
    },
    createItemDescription: 'Create all available products and services at your establishment to enhance cross-selling opportunities for your guests',
    groupBy: 'Grouped by',
    viewDetails: 'View details',
    itemDetail: {
        back: 'Back',
        saveItem: 'Save item',
        itemInformation: 'Item information',
        itemInformationDescription: 'Enter the main information about the item that will be displayed on the cards.',
        title: 'Title',
        titleHint: 'Enter the item title',
        availableQuantity: 'Transaction limit',
        itemDescription: 'Item description',
        termsAndPolicies: 'Terms and policies',
        termsAndPoliciesPlaceholder: 'Enter the item terms and policies',
        priceAndPaymentSettings: 'Price',
        servicePrice: 'Price per person',
        goodPrice: 'Price',
        transferPrice: 'Price per person and trip',
        priceExplanation: 'Select the price type and define how much your guests will pay for the offer.',
        serviceAvailability: 'Service availability',
        transferAvailability: 'Transfer availability',
        listSlotTime: 'Slot list',
        saveSuccess: 'Item created successfully!',
        updateSuccess: 'Item updated successfully!',
        saveError: 'Error saving the item.',
        slot: {
        title: 'Title',
        titleHint: 'Add a name for this set of availability days and times',
        quantity: 'Total available persons per slot',
        daysAvailability: 'Days of availability',
        createSlot: 'Create slot',
        updateSlot: 'Update slot',
        tripType: {
            name: 'Trip type',
            oneWay: 'One way',
            return: 'Return only',
            roundTrip: 'Round trip',
        },
        days: {
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
        },
        daysShort: {
            sunday: 'Sun',
            monday: 'Mon',
            tuesday: 'Tue',
            wednesday: 'Wed',
            thursday: 'Thu',
            friday: 'Fri',
            saturday: 'Sat',
        },
        },
    },
    itemTypes: {
        service: 'Service',
        good: 'Product',
        transfer: 'Transfer',
    },
    active: 'Active',
    removed: 'Removed',
    disabled: 'Disabled',
    maxFileUploadReached: 'You can upload a maximum of 5 images',
    totalCrossSell: 'Total Cross-sellings',
    crossSellRevenue: 'Cross-Selling Revenue',
    crossSellList: 'Cross-Selling list',
    crossSell: 'Cross-Sell',
    last7Days: 'Last 7 days',
    lastMonth: 'Last month',
    last30Days: 'Last 30 days',
    days: 'Days',
    month: 'Month',
    accepted: 'Accepted',
    rejected: 'Rejected',
    approved: 'Approved',
    pending: 'Pending',
    activate: 'Activate',
    delete: 'Delete',
    seeDetails: 'See details',
    changeToRejected: 'Change to rejected',
    changeToApproved: 'Change to approved',
    disable: 'Disable',
    paymentStatus: 'Payment status',
    transactionCode: 'Transaction',
    succeed: 'Approved',
    upsertValidations: {
        name: 'Item name is required',
        description: 'Item description is required',
        missingPmsId: 'PMS ID is required',
        missingTranslation: 'Missing translation in another language',
        createItem: 'Item created successfully',
        modifiedItem: 'Item modified successfully.',
    },
    reports: {
        itemNumbers: 'Units sold',
        revenue: 'Generated revenue',
        transactionCount: 'Number of transactions',
    },
    validation: {
        quantityRequired: 'Quantity is required and must be greater than 0.',
        slotQuantityRequired: 'Each slot must have a quantity greater than 0.',
        priceRequired: 'Price is required',
    },
};