import Colors from 'constants/Colors';

export default {
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 16,
    marginBottom: 32,
    height: 400,
  },
  title: {
    marginTop: 32,
    fontSize: 16,
    color: Colors.titleColor,
    fontWeight: 'bold',
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 16,
    paddingRight: 16,
    height: 720,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderLeft: `1px dashed ${Colors.lightGray}`,
    borderRight: `1px dashed ${Colors.lightGray}`,
    borderBottom: `1px solid ${Colors.lightGray}`,
  },
  name: {
    fontSize: 10,
  },
  percentage: {
    fontWeight: 'bold',
  },
  columnContainer: {
    width: '100%',
    padding: 12,
  },
  column: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: Colors.stepsGraph,
  },
  textContainer: {
    borderBottom: `1px solid ${Colors.lightGray}`,
    borderTop: `1px solid ${Colors.lightGray}`,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 8,
    alignItems: 'center',
  },
};
