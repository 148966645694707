import Colors from 'constants/Colors';

export default {
  container: {
    display: 'flex',
    marginRight: 16,
    marginLeft: 0,
    gap: 8,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  text: {
    marginRight: 8,
    fontWeight: 'bold',
    color: Colors.white,
  },
  selector: {
    minWidth: 248,
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
  },
  autocomplete: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderRadius: 8,
  },
};
