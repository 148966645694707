import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import React, { useState, useContext, useEffect } from 'react';
import { Table } from 'antd';
import Row from './DragableRow.js';
import './RoomUpsellToOption.css';
import PhotoBox from 'Sell/Components/PhotoGallery/PhotoBox/PhotoBox.jsx';
import Actions from './Components/Actions.jsx';
import styles from './RoomUpsellToOption.styles.js';
import { findById } from 'Sell/Upsell/API/api.config.js';
import { AppContext } from 'App.context';
import { useAppConfigQuery } from 'Sell/Upsell/API/queries.js';


export default function RoomUpsellToOption({
  data,
  onDelete,
  handleOrderChange,
  onEdit,
  editMode,
}) {
  const {
    appState: {
      currentLanguage: { id: currentLang },
    },
  } = useContext(AppContext);
  const { data: appConfig } = useAppConfigQuery();
  const [dataSource, setDataSource] = useState([]);

  const getRoomTypeData = async id => await findById('rooms', { id });

  const getParsedData = async data => {
    const promises = data.map(async el => {
      const data = await getRoomTypeData(el.toPhysicalRoomTypeId);
      return {
        ...el,
        image: data.data?.physicalRoomGalleries[0]?.path,
        desc: (
          data.data?.texts.find(text => text.type === 'desc' && text.lang === currentLang) || ''
        ).value,
        name: (
          data.data?.texts.find(text => text.type === 'name' && text.lang === currentLang) || ''
        ).value,
      };
    });
    return await Promise.all(promises);
  };
  useEffect(() => {
    getParsedData(data).then(res => setDataSource(res));
  }, [data]);

  const columns = [
    {
      key: 'sort',
    },
    {
      render: record => (
        <div style={styles.image}>
          <PhotoBox path={record?.image} />
        </div>
      ),
    },
    {
      render: record => <div>{record.name}</div>,
    },
    {
      render: async record => {
        return <div>{record.desc}</div>;
      },
    },
    {
      render: record => (
        <div
          style={styles.priceType}
        >{`${record.price}${appConfig?.data.currency} / ${record.priceType}`}</div>
      ),
    },
    {
      title: 'icons',
      render: record => (
        <Actions
          editMode={editMode}
          onEdit={() => onEdit(record)}
          onDelete={() => onDelete(record.id)}
          record={record}
        />
      ),
    },
  ];

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource(previous => {
        const activeIndex = previous.findIndex(i => i.order === active.id);
        const overIndex = previous.findIndex(i => i.order === over?.id);
        const newArray = arrayMove(previous, activeIndex, overIndex);
        handleOrderChange(newArray);
        return newArray;
      });
    }
  };

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext items={dataSource.map(i => i.order)} strategy={verticalListSortingStrategy}>
        <Table
          components={{
            body: {
              row: Row,
            },
          }}
          rowKey="order"
          columns={columns}
          dataSource={dataSource}
          showHeader={false}
        />
      </SortableContext>
    </DndContext>
  );
}
