import React, { useContext } from 'react';
import usePMSUDFConfigHook from './PMSUDFConfig.hook';
import { Form, Input, Select, Table } from 'antd';
import { AppContext } from 'App.context';
import PMSUDFConfigTable from './PMSUDFConfig.table';
import styles from '../../../../Components/common/ConfigurationForm/ConfigurationFormInput/ConfigurationFormInput.styles';
import pmsUdfStyles from './PMSUDFConfig.styles';
import Colors from 'constants/Colors';
import CustomButton from 'Components/common/CustomButton/CustomButton';
import Loading from 'Components/common/Loader/Loading';
import AppModal from 'Components/common/AppModal/AppModal';

import './PMSUDF.css';

const { FORM_MODES } = require('./PMSUDF.constants');

export default function PMSUDFConfig() {
  const {
    appActions: { translate, getSelectedLanguage },
    appState,
  } = useContext(AppContext);
  const { state, actions } = usePMSUDFConfigHook();

  const selectedLanguage = getSelectedLanguage()?.id;
  const defaultLanguage = 'en';

  const columns = PMSUDFConfigTable.getPMSUDFConfigColumns({
    translate,
    onClickDelete: actions.onClickDelete,
    onClickEdit: actions.onClickEdit,
    getHotelUdfField: actions.getHotelUdfField,
    isLoadingForm: state.isLoadingForm,
  });
  return (
    <>
      <strong>{translate('pmsUdf.title')}</strong>
      <div style={pmsUdfStyles.container}>
        <div style={pmsUdfStyles.formContainer}>
          <strong>
            {state.formMode === FORM_MODES.CREATE
              ? translate('pmsUdf.form.addUdf')
              : translate('pmsUdf.form.editUdf')}
          </strong>
          <Form name="udf" layout="vertical" form={state.form}>
            <Form.Item label={translate('pmsUdf.form.name')} name="udfName">
              <Input
                name="udfName"
                value={actions.getFieldValue('udfName')}
                bordered={false}
                style={styles.borderStyle}
                onChange={event => {
                  actions.onInputChange(event.target.name, event.target.value);
                }}
                maxLength={255}
              />
            </Form.Item>
            <Form.Item label={translate('pmsUdf.form.field')} name="udfField">
              <Select
                value={actions.getFieldValue('udfField')}
                loading={state.isLoadingFields}
                style={{ ...styles.borderStyle }}
                bordered={false}
                onChange={actions.onFieldSelected}
              >
                {state.hotelFields.map((hotelField, i) => {
                  const defaultText =
                    hotelField?.defaultTexts?.find(text => text.lang === selectedLanguage)?.value ||
                    hotelField?.defaultTexts?.find(text => text.lang === defaultLanguage)?.value;
                  const formText =
                    hotelField?.formTexts?.find(formText => formText.lang === selectedLanguage)
                      ?.value ||
                    hotelField?.formTexts?.find(formText => formText.lang === defaultLanguage)
                      ?.value;
                  return <Select.Option key={i}>{defaultText || formText}</Select.Option>;
                })}
              </Select>
            </Form.Item>
            <div style={pmsUdfStyles.buttonsContainer}>
              {state.formMode === FORM_MODES.EDIT && (
                <CustomButton
                  background={Colors.lightGray}
                  color={'black'}
                  text={translate('pmsUdf.form.cancel')}
                  onClick={!state.isLoadingForm ? actions.onCancelEdit : null}
                />
              )}
              <CustomButton
                style={pmsUdfStyles.button}
                background={Colors.blueIntense}
                color={'white'}
                text={
                  state.formMode === FORM_MODES.CREATE
                    ? translate('pmsUdf.form.add')
                    : translate('pmsUdf.form.edit')
                }
                rightComponent={state.isLoadingForm ? <Loading size="small" /> : null}
                onClick={!state.isLoadingForm ? actions.onButtonClicked : null}
              />
            </div>
          </Form>
        </div>
        <div style={pmsUdfStyles.tableContainer}>
          <strong>{translate('pmsUdf.table.title')}</strong>
          <Table
            id="udfs-table"
            loading={state.isLoading}
            columns={columns}
            dataSource={state.hotelUdfs}
            rowClassName={row => (row.id === state.udfId ? 'selected-row' : '')}
            pagination={{ pageSize: 4 }}
            size="small"
          />
        </div>
        {state.showDeleteWarning && (
          <div className={styles.alertContainer}>
            <AppModal
              show={state.showDeleteWarning}
              title={translate('pmsUdf.deleteWarning.title')}
              description={translate('pmsUdf.deleteWarning.description')}
              okText={translate('pmsUdf.deleteWarning.remove')}
              okAction={actions.onConfirmDelete}
              cancelText={translate('pmsUdf.deleteWarning.cancel')}
              cancelAction={actions.onCancelModal}
              okType="danger"
            />
          </div>
        )}
      </div>
    </>
  );
}
