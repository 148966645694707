import axios from 'axios';
import apiConfig from '../api.config';

export default function updateChainAppearenceRequest({ chainId, logo }) {
  const {
    host,
    URIs: { updateAppearenceChain },
  } = apiConfig;

  const uri = `${host}${updateAppearenceChain.replace(':chainId', chainId)}`;

  return axios
    .put(
      uri,
      JSON.stringify({
        logo,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(res => {
      return res.data;
    });
}
