import { AppContext } from 'App.context';
import Colors from 'constants/Colors';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { saveAs } from 'file-saver';
import { getParsedReports } from '../civitfun-police-report-utils/PoliceReportParse.utils';
import { capitalize } from 'utils/TextUtils';

export default function usePoliceReportListHook() {
  const {
    appState,
    appActions: { getSelectedHotel, translate, setPoliceReportFilters },
  } = useContext(AppContext);
  const history = useHistory();

  const { API } = useContext(ApiContext);

  const savedPaging = appState.policeReportFilters?.dataSource;

  const [state, setState] = useState({
    isLoading: false,
    dataSource: {
      items: [],
      totalItems: savedPaging?.totalItems || 0,
      totalPages: savedPaging?.totalPages || 0,
      page: savedPaging?.page || 0,
      size: savedPaging?.size || 10,
    },
    date: appState.policeReportFilters?.date || moment(),
    filters: appState.policeReportFilters?.filters || null,
    datesRange: getDefaultDateRange(),
    enableFilters: true,
    initialLoad: true,
    suscription: null,
    sendSuscriptionRequestEnabled: true,
    isLoadingForcePoliceReport: false,
    isForcedPoliceReport: false,
  });

  useEffect(() => {
    actions.loadPoliceReportConfig();
    actions.getPoliceReports();
  }, [state.dataSource.page, state.filters, appState.selectedHotel, state.date]);

  const actions = {
    loadPoliceReportConfig: () => {
      updateState({ isLoading: true });
      API.getPoliceConfig({ hotelId: getSelectedHotel()?.key })
        .then(res => {
          updateState({
            suscription: res?.suscription === 'active',
          });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },

    getPoliceReports: () => {
      // const parsedReports = getParsedReports(JSON.parse(JSON.stringify(mock)));
      // updateState({ isLoading: false, dataSource: parsedReports });
      // return;

      // updateState({ isLoading: true });
      const hotel = getSelectedHotel();
      API.getPoliceReportList({
        hotelId: hotel.id || hotel.key,
        date: moment(state.date).format('YYYY-MM-DD'),
        page: state.dataSource.page,
        size: state.dataSource.size,
        status: state.filters?.status,
        reference: state.filters?.reference,
        guestName: state.filters?.guestName,
        batch: state.filters?.batch,
      })
        .then(res => {
          const parsedReports = getParsedReports(res);
          updateState({ isLoading: false, dataSource: parsedReports });
        })
        .catch(err => {
          console.log(err);

          updateState({ isLoading: false });
        });
    },

    requestGuestlinkFunctionality: () => {
      updateState({ isLoading: true });
      API.requestFunctionalityContract({
        hotelId: getSelectedHotel().key,
        suscription: 'police_report',
        functionality: 'Police Report Module',
      })
        .then(_ => {
          actions.sendRequestPoliceFunctionalityEmail();
        })
        .catch(_ => {
          updateState({ isLoading: false });
        });
    },

    sendRequestPoliceFunctionalityEmail: () => {
      updateState({ isLoading: true });
      API.sendRequestFuncionalityEmail({
        hotelId: getSelectedHotel().key,
        functionality: 'Police Report Module',
      })
        .then(_ => {
          notification.success({
            message: translate('policeReport.missingSuscription.requestSent'),
          });
          updateState({ isLoading: false, sendSuscriptionRequestEnabled: false });
        })
        .catch(_ => {
          updateState({ isLoading: false });
        });
    },

    forcePoliceReportDownloadTrigger: async () => {
      const selectedHotel = getSelectedHotel();
      const hotelId = selectedHotel?.id ?? selectedHotel?.key;
      const date = moment(state.date).format('YYYY-MM-DD');
      updateState({ isLoadingForcePoliceReport: true });
      try {
        await API.forcePoliceReportDownloadTrigger({ hotelId, date });
        notification.success({
          message: translate('policeReport.forceDownloadTriggerInfo'),
        });
        updateState({ isForcedPoliceReport: true });
      } catch (error) {
        notification.error({
          message: translate('policeReport.forceDownloadTriggerError'),
        });
      }
      updateState({ isLoadingForcePoliceReport: false });
    },

    getColorForStatus: status => {
      switch (status) {
        case 'ok':
          return Colors.testOk;
        case 'error':
          return Colors.red;
        case 'pending':
          return Colors.yellowPill;
        default:
          return Colors.lightGray;
      }
    },

    onDateChange: (_date, dateString) => {
      updateState({ datesRange: dateString });
    },

    onDateSet: date => {
      updateState({ date, isForcedPoliceReport: false });
    },

    onRowClicked: data => {
      history.push({
        pathname: `/police-report/${data.id}`,
        state: { report: data },
      });

      setPoliceReportFilters(state);
    },

    onChange: filters => {
      var newPage,
        newPageSize = -1;
      var searchFilters;

      if (state.dataSource?.page !== filters?.pagination.page) {
        newPage = filters?.pagination.page;
      }
      if (state.dataSource?.size !== filters?.pagination.pageSize) {
        newPageSize = filters?.pagination.pageSize;
      }

      if (filters?.where) {
        const reference = filters?.where['reference'];
        const guestName = filters?.where['surname'] || filters?.where['name'];
        const status = filters?.where['status'];
        const batch = filters?.where['batch'];

        searchFilters = { status, reference, guestName, batch };
      }

      updateState({
        dataSource: {
          ...state.dataSource,
          page: newPage >= 0 ? newPage : state.dataSource?.page,
          size: newPageSize >= 0 ? newPageSize : state.dataSource?.size,
          items: [],
        },
        filters: searchFilters,
      });
    },

    parseError: error => {
      return error;
    },

    downloadPoliceReports: () => {
      updateState({ isLoading: true });
      API.downloadPoliceReports()
        .then(res => {
          saveAs(
            new Blob([res], {
              type: 'application/xml',
            }),
            'police_report_export.xml'
          );
          notification.success({
            message: translate('policeReport.downloadSuccess'),
          });
          updateState({ isLoading: false });
        })
        .catch(err => {
          notification.error({
            message: translate('policeReport.downloadError'),
          });
          updateState({ isLoading: false });
        });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}

function getDefaultDateRange() {
  return [moment().subtract(1, 'day').startOf('day'), moment().subtract(0, 'day').endOf('day')];
}

const mock = {
  totalItems: 1,
  totalPages: 1,
  pageSize: 10,
  currentPage: 1,
  items: [
    {
      id: 'd85e6ac7-7cb4-47b9-9b8e-6a6212d1f251',
      report: {
        status: 'error',
        message: 'email required',
      },
      guest: {
        holder: true,
        name: 'Marcos',
        surname: 'Rubio',
        secondSurname: 'Torres',
        contact: {
          type: 'email',
          value: 'teste-addres.com',
        },
        documentType: 'NIF',
        documentNumber: '82738273G',
        supportDocument: 'B34827',
        customFields: {
          country: 'ESP',
          municipalityCode: '07014',
          zipCode: '07570',
          address: 'Avda. Calaberas 23',
          relationship: 'ABU',
        },
      },
      payment: {
        type: 'TARJT',
      },
      contract: {
        ref: '32VA6AF7S6',
        contractDate: '2023-04-25',
        entrance: '2023-04-25',
        departure: '2023-04-28',
        pax: 2,
      },
    },
    {
      id: '37bf1943-9f4c-4dbd-98ea-8db121695c6a',
      report: {
        status: 'success',
        message: 'booking confirmed',
      },
      guest: {
        holder: true,
        name: 'Sophia',
        surname: 'Martinez',
        secondSurname: 'Lopez',
        contact: {
          type: 'email',
          value: 'sophia@email.com',
        },
        documentType: "Driver's License",
        documentNumber: 'DL4567890',
        supportDocument: 'A9876543',
        customFields: {
          country: 'CAN',
          municipalityCode: 'M5V 2V4',
          zipCode: 'M1B 3C2',
          address: '456 Maple Avenue',
          relationship: 'FAMILY',
        },
      },
      payment: {
        type: 'PAYPAL',
      },
      contract: {
        ref: '9R6T4P1S2L',
        contractDate: '2023-06-10',
        entrance: '2023-06-10',
        departure: '2023-06-15',
        pax: 1,
      },
    },
    {
      id: 'f60f5a1d-0b05-4ebc-8c38-6f5ae8f09d13',
      report: {
        status: 'success',
        message: 'missing phone number',
      },
      guest: {
        holder: true,
        name: 'Emily',
        surname: 'Johnson',
        secondSurname: 'Smith',
        contact: {
          type: 'phone',
          value: '+1 123-456-7890',
        },
        documentType: 'Passport',
        documentNumber: 'AB1234567',
        supportDocument: 'D98765',
        customFields: {
          country: 'USA',
          municipalityCode: '90210',
          zipCode: '10001',
          address: '123 Elm Street',
          relationship: 'FRIEND',
        },
      },
      payment: {
        type: 'CREDIT',
      },
      contract: {
        ref: '5G8H9K2P3Q',
        contractDate: '2023-05-15',
        entrance: '2023-05-15',
        departure: '2023-05-20',
        pax: 3,
      },
    },
    {
      id: '8a2f62a2-9dcd-4be2-b69e-9f135a4ea54d',
      report: {
        status: 'error',
        message: 'invalid credit card',
      },
      guest: {
        holder: true,
        name: 'Michael',
        surname: 'Brown',
        secondSurname: 'Miller',
        contact: {
          type: 'phone',
          value: '+44 20 1234 5678',
        },
        documentType: 'ID Card',
        documentNumber: 'ID7890123',
        supportDocument: 'S6543210',
        customFields: {
          country: 'GBR',
          municipalityCode: 'SW1A 1AA',
          zipCode: 'W1B 3HH',
          address: '789 Park Lane',
          relationship: 'COLLEAGUE',
        },
      },
      payment: {
        type: 'CREDITCARD',
      },
      contract: {
        ref: '1C3R5E7D9I',
        contractDate: '2023-07-20',
        entrance: '2023-07-20',
        departure: '2023-07-25',
        pax: 1,
      },
    },
  ],
};
