import { Radio } from 'antd';
import Colors from 'constants/Colors';
import React from 'react';
import styles from './CustomRadioButton.styles';

export default function CustomRadioButton({ text, onClick, value, disabled, subtitle }) {
  return (
    <div style={styles.container} onClick={() => onClick && !disabled && onClick()}>
      <Radio checked={value} disabled={disabled} />
      <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', gap: 4 }}>
        <div style={{ color: Colors.titleColor, fontSize: 12, minWidth: '36px' }}>{text}</div>
        <div style={{ fontWeight: 'bold', color: Colors.titleColor, fontSize: 12 }}>{subtitle}</div>
      </div>
    </div>
  );
}
