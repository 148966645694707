import axios from 'axios';
import apiConfig from 'utils/networking/api.config';

export default async function generateEmailRequest({
  hotelId = null,
  authorization = null,
  entranceFrom = null,
  entranceTo = null,
  bookingCode = null,
}) {
  const {
    hostValidation,
    URIs: {
      validation: { generateEmail },
    },
  } = apiConfig;

  const uri = `${hostValidation}${generateEmail}`.replace(':hotelId', hotelId);

  return axios
    .get(uri, {
      params: { entranceFrom, entranceTo, bookingCode },
      headers: { Authorization: authorization },
    })
    .then(res => {
      return res.data;
    });
}
