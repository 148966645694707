import { CopyOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd'; 
import { AppContext } from 'App.context';
import AppButton from 'Components/common/Forms/AppButton/AppButton';
import Loading from 'Components/common/Loader/Loading';
import Colors from 'constants/Colors';
import React, { useContext } from 'react';
import { copyTextToClipboard } from 'utils/TextUtils';
import TestResult from '../AutomaticConfigurationTest/TestResult/TestResult';
import useAutomaticBookingTest from './AutomaticBookingTest.hook';
import styles from './AutomaticBookingTest.styles';
import BookingTestInput from './BookingTestInput/BookingTestInput';

export default function AutomaticBookingTest({
  icon,
  serviceName,
  buttonText,
  buttonAction,
  testResult,
  description,
  isTesting,
  inputs,
}) {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useAutomaticBookingTest(inputs);
  const errorMsg = `${testResult?.message} \n${
    testResult?.data?.response?.stack ? testResult?.data?.response?.stack : ''
  }`;
  const log = testResult ? (testResult.success ? testResult?.message || 'Ok' : errorMsg) : null;

  // Función para validar los campos requeridos
  const handleTestBooking = () => {
    const bookingData = actions.getBookingData();
    const { bookingCode, bookingIdentifier, entranceDate } = bookingData;

    //Validar si los campos están vacíos
    if (!bookingCode || !entranceDate) {
      notification.warning({
        message:translate('selector.tabs.warning'),
        description: translate('selector.tabs.warningDescription'),
      });
      return; 
    }

    // Si los campos están llenos, continuar con la acción de testeo
    buttonAction(bookingData);
  };

  return (
    <div style={styles.container}>
      <div style={{ ...styles.buttonContainer, width: 200 }}>
        <div style={styles.title}>{buttonText}</div>
        <div style={styles.description}>{description}</div>
        {state.inputs &&
          state.inputs.length &&
          state.inputs.map((input) => {
            return (
              <BookingTestInput
                key={input.key}
                id={input.key}
                title={translate(`checkinConfig.${input.key}`)}
                type={input.type}
                value={actions.getInputValue(input.key)}
                onValueChanged={actions.setInputValue}
              />
            );
          })}
        <div style={{ height: 8 }} />
        <AppButton text={buttonText} action={handleTestBooking} /> {/* Usar la función de validación */}
      </div>

      <div style={{ width: '100%', marginTop: 70, marginLeft: 200 }}>
        {/* Container with margin-top */}
        {isTesting ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '150px' }}>
            <Loading size={'large'} />
          </div>
        ) : testResult != null ? (
          <div style={styles.testMessageContainer}>
            <TestResult success={testResult?.success} />
          </div>
        ) : null}
      </div>

      {testResult ? (
        <div style={styles.logBox}>
          <pre>{log}</pre>
          {testResult && !testResult?.success && (
            <div style={styles.copyButton}>
              <Button
                type="primary"
                shape="square"
                ghost
                style={{ color: Colors.superUserButton, borderColor: Colors.lightGray }}
                icon={<CopyOutlined />}
                onClick={() => copyTextToClipboard(log)}
              />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}
