import axios from 'axios';

export default function uploadImageRequest(options) {
  const {
    onSuccess,
    onError,
    file,
    onProgress,
    data: { hotelId, type, chainId, hotelList },
  } = options;

  var baseUrl =
    process.env.REACT_APP_ENV == 'production'
      ? 'https://integration-hub.civitfun.com'
      : 'https://integration-hub.pre.civitfun.com';

  const hotelUri = `${baseUrl}/dashboard/v1/upload-hotel-logo`;
  const chainUri = `${baseUrl}/dashboard/v1/upload-chain-logo`;

  const formData = new FormData();
  formData.append('file', file);

  let uri = hotelUri;
  if (chainId) {
    uri = chainUri;
    formData.append('chainId', chainId);
  } else if (hotelId) {
    formData.append('hotels', hotelId);
    formData.append('type', type);
  } else {
    if (hotelList) {
      formData.append('type', type);
      formData.append(
        'hotels',
        hotelList.map(hotel => hotel.id)
      );
    }
  }

  return axios
    .post(uri, formData)
    .then(res => {
      onSuccess('Ok');
      return res.data;
    })
    .catch(error => {
      console.error(error);
      onError({ error });
    });
}
