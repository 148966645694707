import Colors from '../../../../../constants/Colors';

export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 22,
  },
  tableContainer: {
    flex: 1,
  },
  tagsContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 8,
  },
  divider: {
    marginTop: 16,
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    alignItems: 'center',
  },
  dividerBar: {
    flex: 1,
    marginLeft: 8,
    height: 0.5,
    backgroundColor: Colors.lightGray,
  },
};
