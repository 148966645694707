import { AppContext } from 'App.context';
import CustomButton from 'Components/common/CustomButton/CustomButton';
import Loading from 'Components/common/Loader/Loading';
import Colors from 'constants/Colors';
import React, { useContext, useEffect } from 'react';
import ConfigurationForm from 'Components/common/ConfigurationForm/ConfigurationForm';
import useDoorOpeningFormHook from './DoorOpeningForm.hook';
import styles from './DoorOpeningForm.styles';
import AppButton from 'Components/common/Forms/AppButton/AppButton';
import DoorOpeningConfigForm from './DoorOpeningConfigForm/DoorOpeningConfigForm';
import constants from '../../../../utils/constants';
import getDoorOpeningLogo from 'utils/getDoorOpeningLogo';

export default function DoorOpeningForm({
  rowData,
  selectedDoorOpening,
  updateEnabledHotels,
  enabled,
}) {
  const {
    appState: { currentLanguage },
    appActions: { translate },
  } = useContext(AppContext);

  const { state, actions } = useDoorOpeningFormHook(
    updateEnabledHotels,
    selectedDoorOpening.keyTypes
  );

  useEffect(() => {
    actions.loadDoorOpeningConfig(selectedDoorOpening, rowData.id);
  }, [selectedDoorOpening]);

  const literals = {
    ...currentLanguage.doorOpeningConfig[selectedDoorOpening.name.toLowerCase()],
    ...currentLanguage.doorOpeningConfig.generic,
  };

  let connectionData = true;
  if (constants.KEY_WITH_OAUTH.includes(selectedDoorOpening.code)) {
    connectionData = false;
  }

  return !state.isLoading && state.data ? (
    <div style={{ display: 'flex', gap: 8, flexDirection: 'column', flex: 1 }}>
      {connectionData && ( // not showing this for keys with oauth
        <div style={{ fontWeight: 'bold' }}>
          {translate('doorOpening.connectionData')}
        </div>
      )}
      <ConfigurationForm
        fields={state.data}
        literals={literals}
        getFieldValue={actions.getFieldValue}
        onValueChanged={actions.onValueChanged}
      />
      <div style={{ fontWeight: 'bold', marginTop: '20px' }}>
        {translate('doorOpening.configuration')}
      </div>
      <DoorOpeningConfigForm
        checkinStatusOptions={state.configCheckinStatusOptions}
        paymentStatusOptions={state.configPaymentStatusOptions}
        keyTypesOptions={state.configKeyTypes}
        configData={state.doorOpeningConfigData}
        platform={selectedDoorOpening.code}
        onValueChanged={actions.onConfigValueChanged}
      />
      <div style={{ justifyContent: 'right', display: 'flex' }}>
        <CustomButton
          style={styles.button}
          background={Colors.blueColor}
          text={translate('save')}
          onClick={() => actions.onSave(selectedDoorOpening, rowData.id)}
        />
      </div>
    </div>
  ) : (
    <div style={styles.loadingContainer}>
      <Loading />
    </div>
  );
}
