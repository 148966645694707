import Colors from 'constants/Colors';

export default {
  container: {
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'column',
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    color: Colors.titleColor,
    marginBottom: 4,
  },
  graphContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 16,
    paddingRight: 16,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  typeName: {
    fontSize: 6,
  },
  column: {
    width: '100%',
  },
  iconContainer: {
    height: 20,
    width: 20,
    borderRadius: 10,
    marginTop: 4,
    marginBottom: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  percentage: {
    fontSize: 10,
    fontWeight: 'bold',
    color: Colors.lightBlue,
  },
};
