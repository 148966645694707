import Colors from 'constants/Colors';

export default {
  wrapper: {
    minHeight: 420,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${Colors.lightGray}`,
    borderRadius: 8,
    overflow: 'hidden',
    marginBottom: 16,
  },
  input: {
    borderBottom: `1px solid ${Colors.lightGray}`,
    padding: 8,
  },
  emptyContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  },
};
