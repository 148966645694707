import Colors from 'constants/Colors';

export default {
  pmsItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 8,
    alignItems: 'center',
    height: 62,
  },
  logo: {
    alignSelf: 'center',
    height: 42,
  },
  pmsName: {
    marginLeft: 16,
    color: Colors.pmsLogo,
    fontSize: 12,
    fontWeight: 'bold',
  },
};
