import { useContext, useState } from 'react';
import moment from 'moment';

import Routes from 'Sell/Upsell/Router/routes';
import { useUpsellHistory } from 'Sell/Upsell/Router/useHistory';
import { useReportsQuery, useCountersQuery } from './Reports.service';
import constants from 'utils/constants';
import { sumArray } from 'Sell/utils/utils';
import { useAppConfigQuery } from 'Sell/Upsell/API/queries';
import { AppContext } from 'App.context';

const { dateFormat } = constants;

const defaultStartDate = moment().subtract(30, 'days');
const defaultEndDate = moment();

const initialState = {
  where: {
    created_at: {
      from: defaultStartDate.format(dateFormat),
      to: defaultEndDate.format(dateFormat),
    },
  },
};

const generateDateLabels = dates => {
  if (!dates || dates.length < 2) return [];
  let start = moment(dates[0]).startOf('day');
  const end = moment(dates[1]).startOf('day');
  const labels = [];

  while (start.isSameOrBefore(end)) {
    labels.push(start.format('DD'));
    start = start.add(1, 'day');
  }

  return labels;
};

export function useReports() {
  const { push } = useUpsellHistory();
  const [state, setState] = useState(initialState);
  const {
    appState: { selectedHotel },
  } = useContext(AppContext);

  const {
    data: reportsData,
    isLoading: isRerportsLoading,
    refetch: refetchReports,
  } = useReportsQuery(state, selectedHotel);
  const {
    data: countersData,
    isLoading: isCountersLoading,
    refetch: refetchCounters,
  } = useCountersQuery(state, selectedHotel);
  const { data: appConfig, isLoading: isAppConfigLoading } = useAppConfigQuery();
  const [selectedCounter, setSelectedCounter] = useState(0);
  const selectedDates = [defaultStartDate, defaultEndDate];
  const [labels, setLabels] = useState(generateDateLabels(selectedDates));

  const actions = {
    handleViewDetailsClickUpsell: () => {
      push(`${Routes.upsellsList}?filters=${encodeURIComponent(JSON.stringify(state))}`);
    },

    handleViewDetailsClickRoom: () => {
      push(Routes.roomsList);
    },

    setSelectedDates: newDates => {
      const [from, to] = newDates;
      const adjustedTo = moment(to).add(1, 'days').startOf('day');
      const newState = {
        ...state,
        where: {
          ...state.where,
          created_at: {
            from: from?.format(dateFormat),
            to: adjustedTo.format(dateFormat),
          },
        },
      };
      setState(newState);
      setLabels(generateDateLabels([from, adjustedTo]));
      refetchCounters();
      refetchReports();
    },
  };

  const { data: { revenue, upsellsCountPerDay, topUpsells } = {} } = reportsData || {};
  const { data: { accepted, pending, rejected } = {} } = countersData || {};
  const totalUpsell = sumArray(upsellsCountPerDay);
  const totalAccepted = sumArray(accepted);
  const totalPending = sumArray(pending);
  const totalRejected = sumArray(rejected);

  const data = {
    accepted,
    pending,
    rejected,
    totalUpsell,
    totalAccepted,
    totalPending,
    totalRejected,
    revenue,
    upsellsCountPerDay,
    topUpsells,
    currency: appConfig?.data.currency,
  };

  return {
    defaultValue: selectedDates,
    labels,
    selectedCounter,
    setSelectedCounter,
    actions,
    data,
    isLoading: isRerportsLoading || isCountersLoading || isAppConfigLoading,
  };
}
