import Loading from 'Components/common/Loader/Loading';
import React from 'react';
import styles from './StatsContainer.styles';

export default function StatsContainer({
  isLoading,
  flex,
  color,
  title,
  subtitle,
  children,
  isDevices,
}) {
  return (
    <div
      style={{ ...styles.container, borderColor: color, flex: flex, padding: isDevices ? 8 : 16 }}
    >
      {isLoading ? (
        <Loading color={color} customIndicator />
      ) : (
        <div style={isDevices ? { flex: 1, height: '100%' } : {}}>
          {children}
          {!isDevices && (
            <div>
              {title && <div style={styles.title}>{title}</div>}
              {subtitle && <div style={styles.subtitle}>{subtitle}</div>}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
