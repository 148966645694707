import axios from 'axios';
import apiConfig from '../api.config';
import constants from '../../constants';
import storage from '../../storage';

export default async function editHotelLicence({ hotelId, licence }) {
  const {
    billingHost,
    URIs: { editHotelLicence },
  } = apiConfig;

  const uri = `${billingHost}${editHotelLicence}`.replace(':hotelLicenceId', licence.id);

  const storedCredentials = JSON.parse(storage.get(constants.AUTH.USER_KEY));

  let typeExceptions = ['monthly', 'bimonthly', 'quarterly', 'four_monthly', 'biannual', 'two_yearly' ];

  const data = {
    hotelId: hotelId || 0,
    hotelReference: 'civitfun',
    accessToken: storedCredentials.accessToken,
    name: licence.name,
    contractId: licence.contractId,
    currency: licence.currency,
    price: licence.price,
  };

  // FIXME: temporal patch
  if ( !typeExceptions.includes(licence.type) ) {
    data.type = licence.type;
  };

  return axios
  .put(uri, {
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      return res.data;
    });
}
