export default {
    tagActive: {
        color: '#027A48',
        backgroundColor: '#ECFDF3',
        borderRadius: 16,
        border: 0,
        width: 77,
        height: 22,
        paddingLeft: 10,
    },
    tagInactive: {
        color: '#344054',
        backgroundColor: '#F2F4F7',
        borderRadius: 16,
        border: 0,
        width: 82,
        height: 22,
        paddingLeft: 10,
    },
    tagRenewal: {
        color: '#095BBD',
        backgroundColor: 'white',
        borderColor: '#095BBD',
        borderRadius: 9,
        fontWeight: '700',
        width: 85,
        height: 22,
        paddingLeft: 10,
    },
    uploadButtonBox: {
        position: 'absolute',
        right: '1%',
        top: 120,
    },
    noDataText: {
        color: 'black',
        width: 150,
    },
    noDataDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}