import React, { useContext } from 'react';
import { Table } from 'antd';
import { Checkbox } from 'antd';
import { AppContext } from '../../../../App.context';
import Colors from 'constants/Colors';
import EditableInput from './EditableInput/EditableInput';
import GuestFormFieldState from 'Components/Dashboard/Forms/GuestForms/GuestFormFieldState';
import { Popover } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

export default function GuestTable({
  isLoading,
  data,
  onFieldStateChecked,
  onFieldRequiredChecked,
  selectedLanguage,
  onFieldTextChanged,
  supportedLanguages,
}) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const columns = [
    {
      title: translate('guestTable.fieldCol'),
      dataIndex: 'name',
      key: 'name',
      render: (_, item) => {
        const defaultText = item?.defaultTexts?.find(text => text.lang == selectedLanguage)?.value;
        const formText = item?.formTexts?.find(formText => formText.lang == selectedLanguage)?.value;
  
        if (item.name === 'family_relationship' || item.name === 'document_support_number') {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>{defaultText || formText}</div>
              <div style={{ marginLeft: 'auto', paddingLeft: '10px' }}>
                <Popover content={translate(item.name)} trigger="hover">
                  <WarningOutlined
                    style={{
                      color: Colors.yellowButton,
                      marginRight: 8,
                      padding: 4,
                      border: `1px solid ${Colors.yellowButton}`,
                      borderRadius: 2,
                      fontSize: 14,
                    }}
                  />
                </Popover>
              </div>
            </div>
          );
        }
  
        if (item.name.includes('uploadDocument')) {
          return getEditableInput({
            item,
            onValueChanged: onFieldTextChanged,
            supportedLanguages,
            formText,
            defaultText,
          });
        }
  
        return <div>{formText || defaultText}</div>;
      },
    },
    {
      title: null,
      dataIndex: 'desc',
      key: 'desc',
      render: (_, item) => {
        if (item?.desc) {
          return <div style={{ color: Colors.red }}>{item.desc}</div>;
        }
        return null;
      },
    },
    {
      title: translate('guestTable.visibleCol'),
      dataIndex: 'visible',
      key: 'visible',
      align: 'center',
      render: (_, item) => (
        <Checkbox
          disabled={item?.mandatory}
          checked={item?.state == GuestFormFieldState.ACTIVE}
          onClick={() =>
            onFieldStateChecked({
              id: item?.id,
              fieldHotelId: item?.fieldHotelId,
            })
          }
        />
      ),
    },
    {
      title: translate('guestTable.mandatoryCol'),
      dataIndex: 'required',
      key: 'required',
      align: 'center',
      render: (_, item) => (
        item.onlyItaly ? null : (
          <Checkbox
            disabled={item?.mandatory}
            checked={item?.required == 1}
            onClick={() =>
              onFieldRequiredChecked({ id: item?.id, fieldHotelId: item?.fieldHotelId })
            }
          />
        )
      ),
    },
  ];
  console.log("🚀 ~ columns:", columns)
  
  

  return (
    <div>
      <Table loading={isLoading} columns={columns} dataSource={data} pagination={false} />
    </div>
  );
}

function getEditableInput({ item, onValueChanged, formText, defaultText, supportedLanguages }) {
  const isCustomText = formText?.value !== defaultText?.value || formText?.value == null;

  let hasMissingTranslations = false;
  if (!supportedLanguages || !supportedLanguages.length) return;
  for (const lang of supportedLanguages?.map(item => item.code)) {
    const formText = item?.formTexts?.find(formText => formText.lang == lang);
    const defaultText = item?.defaultTexts?.find(text => text.lang == lang);

    if (!formText || formText?.value === defaultText?.value) {
      hasMissingTranslations = true;
    }
  }

  return (
    <EditableInput
      item={item}
      hint={defaultText?.value}
      value={isCustomText ? formText?.value : ''}
      onValueChanged={onValueChanged}
      hasMissingTranslations={hasMissingTranslations}
    />
  );
}
