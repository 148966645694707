import Colors from 'constants/Colors';

export default {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: 24,
    alignItems: 'flex-end',
    flexGrow: 1,
  },
  roomType: {
    flexGrow: 1,
  },
  typeOfPrice: {
    width: 146,
  },
  price: {
    width: 140,
    input: {
      textAlign: 'right',
    },
  },
  icon: {
    fill: 'white',
  },
  button: {
    borderRadius: 8,
    margin: 4,
  },
};
