import { AppContext } from 'App.context';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import { capitalize } from 'utils/TextUtils';

export default function useTagInserterHook({ scope }) {
  const { API } = useContext(ApiContext);
  const {
    appState: { currentLanguage },
    appActions: { translate },
  } = useContext(AppContext);
  const [state, setState] = useState({
    isLoading: false,
    tags: null,
    selectedTag: null,
    tagsApiResponse: null,
  });

  useEffect(() => {
    actions.loadTags();
  }, []);

  useEffect(() => {
    if (state.tagsApiResponse) {
      updateState({ tags: actions.formatTags(state.tagsApiResponse) });
    }
  }, [currentLanguage]);

  const actions = {
    loadTags: () => {
      updateState({ isLoading: true });
      API.getTags({ scope })
        .then(res => {
          updateState({ tags: actions.formatTags(res), isLoading: false, tagsApiResponse: res });
        })
        .catch(err => {
          updateState({ isLoading: false });
        });
    },

    formatTags: tags => {
      return tags.map(category => {
        return {
          label: translate(`legalDocuments.tags.${category.name}`),
          options: category.tags.map(tag => {
            return {
              label: capitalize(translate(`legalDocuments.tags.${tag}`)),
              value: tag,
            };
          }),
        };
      });
    },

    onTagSelected: tag => {
      updateState({ selectedTag: tag });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
