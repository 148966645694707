import { AppContext } from 'App.context';
import Colors from 'constants/Colors';
import { useContext, useEffect, useState } from 'react';
import SupportedDevices from './SupportedDevices';

export default function useDevicesGraph(devices, checkinTotal) {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const [state, setState] = useState({
    dataset: null,
  });

  useEffect(() => {
    actions.parseData(devices);
  }, []);

  const actions = {
    parseData: devices => {
      const deviceData = [];

      SupportedDevices.forEach(deviceType => {
        const item = devices?.find(deviceObj => deviceObj.device == deviceType.name);
        const percentage = !item?.checkins
          ? 0
          : actions.roundPercentage((item?.checkins / checkinTotal) * 100);

        deviceData.push({
          type: deviceType.name,
          count: item?.checkins || 0,
          color: deviceType.color,
          percentage,
        });
      });

      const unknown = devices?.find(deviceObj => !deviceObj.device);
      const unknownPercentage = !unknown?.checkins
        ? 0
        : actions.roundPercentage(unknown?.checkins / checkinTotal) * 100;
      deviceData.push({
        type: 'unknown',
        count: unknown?.checkins || 0,
        color: Colors.unknownDevice,
        percentage: unknownPercentage,
      });

      updateState({ dataset: deviceData });
    },

    roundPercentage: input => {
      return Math.round(input * 10) / 10;
    },

    getOptions: () => {
      return {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            display: false,
          },
          title: {
            display: true,
            text: translate('stats.devices.title'),
          },
        },
      };
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
