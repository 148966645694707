import { notification } from 'antd';
import { useContext, useState } from 'react';
import { AppContext } from '../../../../App.context';
import { validateEmail } from '../../../../utils/TextUtils';
import { useHistory } from 'react-router-dom';
import { ApiContext } from 'utils/networking/Api.context';
import constants from 'utils/constants';

export default function useRegisterPaperlessHook() {
  const {
    appState,
    appActions: { translate },
  } = useContext(AppContext);
  const { API } = useContext(ApiContext);

  const history = useHistory();

  const [state, setState] = useState({
    email: null,
    password: null,
    repeatPassword: null,
    loading: false,
    termsConditions: false,
  });

  const actions = {
    setField: (fieldKey, value) => {
      updateState({ [fieldKey]: value });
    },

    validateFieldsAndRegister: () => {
      if (!state.email || !state.password || !state.repeatPassword) {
        notification.error({
          message: translate('register.emptyFields'),
        });
      } else {
        if (state.password !== state.repeatPassword) {
          notification.error({
            message: translate('register.matchPasswords'),
          });
        } else {
          if (!validateEmail(state.email)) {
            notification.error({
              message: translate('register.invalidMail'),
            });
          } else {
            if (!state.termsConditions) {
              notification.error({
                message: translate('register.termsError'),
              });
            } else {
              actions.registerPaperlessUser();
            }
          }
        }
      }
    },

    registerPaperlessUser: () => {
      const params = new URLSearchParams(window.location.search);

      updateState({ loading: true });
      API.registerPaperlessRequest({
        email: state.email,
        password: state.password,
        hotelSlug: params.get('hotel_slug'),
        ownerId: appState.ownerId,
        type: params.get('ih_client') == constants.IH_CLIENT.RECEPTION ? 'reception' : 'kiosk',
      })
        .then(res => {
          updateState({ loading: false });
          notification.success({
            message: translate('registerPaperless.registerOk'),
          });
          history.push('/channels');
        })
        .catch(err => {
          updateState({ loading: false });
          console.log(err);
          notification.error({
            message: err?.response?.data?.error,
            description: err?.response?.data?.message,
          });
        });
    },

    goBack: () => {
      history.goBack();
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
