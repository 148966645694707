import React from 'react';
import Colors from '../../../../../constants/Colors';
import styles from './WelcomeButton.module.css';

export default function WelcomeButton({ text, action, secondary, ...props }) {
  const color = secondary ? Colors.disabledButton : Colors.blue;

  return (
    <div style={{ backgroundColor: color }} className={styles.container} {...props}>
      {text}
    </div>
  );
}
