import axios from 'axios';
import apiConfig from '../api.config';

export default function doorsOpeningByHotelListRequest(hotelId) {
  const {
    host,
    URIs: { doorsOpeningByHotelList },
  } = apiConfig;

  const uri = `${host}${doorsOpeningByHotelList.replace(':hotelId', hotelId)}`;

  return axios
    .get(uri, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
