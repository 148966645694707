import React from 'react';
import { Input } from 'antd';
import styles from './AppTextInput.module.css';

export default function AppTextInput({
  index,
  name,
  value,
  onChange,
  password,
  removeSpace,
  ...props
}) {
  return (
    <div className={styles.container} style={{ marginBottom: removeSpace ? 0 : 12 }}>
      <b className={styles.header}>{name}</b>
      <div className={styles.inputContainer}>
        {password ? (
          <Input.Password
            bordered={false}
            value={value}
            onChange={e => {
              onChange && onChange(e.target.value);
            }}
            {...props}
          />
        ) : (
          <Input
            bordered={false}
            value={value}
            onChange={e => {
              onChange && onChange(e.target.value);
            }}
            {...props}
          />
        )}
      </div>
    </div>
  );
}
