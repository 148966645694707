export default {
    defaultLayout: {
        position: 'relative',
    },
    invoicePaid: {
        color: '#027A48',
        backgroundColor: '#ECFDF3',
        borderRadius: 16,
        border: 0,
        width: 88,
        height: 22,
        paddingLeft: 10,
        alignSelf: 'center',
    },
    invoicePending: {
        color: '#B54708',
        backgroundColor: '#FFEFC1',
        borderRadius: 16,
        border: 0,
        width: 92,
        height: 22,
        paddingLeft: 10,
        alignSelf: 'center',
    },
    invoiceOverdue: {
        color: '#B42318',
        backgroundColor: '#FEF3F2',
        borderRadius: 16,
        border: 0,
        width: 84,
        height: 22,
        paddingLeft: 10,
        alignSelf: 'center',
    },
    tagInactive: {
        color: '#344054',
        backgroundColor: '#F2F4F7',
        borderRadius: 16,
        border: 0,
        width: 82,
        height: 22,
        paddingLeft: 10,
    },
    uploadButtonBox: {
        position: 'absolute',
        right: '2%',
        top: '120px',
    },
    noDataText: {
        color: 'black',
        width: 150,
    },
    noDataDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    noDataContentDiv: {
        height: 136,
    }
}