import React, { useContext } from 'react';
import { AppContext } from 'App.context';
import { Card, Spin } from 'antd';
import { Line } from 'react-chartjs-2';

import Contador from 'Sell/Upsell/Reports/Components/Contador.jsx';
import Breakdown from 'Sell/Upsell/Reports/Components/Breakdown/Breakdown.jsx';
import RangePicker from 'Sell/Components/RangePicker.jsx';
import { useReports } from './Reports.hook.js';
import styles from './Reports.styles.js';
import './Reports.css';

export default function Reports() {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const { defaultValue, labels, selectedCounter, setSelectedCounter, actions, data, isLoading } =
    useReports();
  const {
    upsellsCountPerDay,
    accepted,
    pending,
    rejected,
    totalUpsell,
    totalPending,
    totalRejected,
    totalAccepted,
    topUpsells,
    revenue,
    currency,
  } = data;

  const { handleViewDetailsClickUpsell, handleViewDetailsClickRoom, setSelectedDates } = actions;

  const upsellListStatus = {
    title: translate('upsell.upsellList'),
    groupBy: translate('upsell.status'),
    list: [
      {
        title: translate('upsell.pending'),
        number: totalPending,
      },
      {
        title: translate('upsell.approved'),
        number: totalAccepted,
      },
      {
        title: translate('upsell.rejected'),
        number: totalRejected,
      },
    ],
  };

  const upsellListFromandTo = {
    title: translate('upsell.upsellList'),
    groupBy: translate('upsell.upsell'),
    list:
      topUpsells?.map(upsell => ({
        title: `${upsell.from} - ${upsell.to}`,
        number: upsell.count,
      })) || [],
  };

  const counters = [
    { titleKey: 'upsell.totalUpsell', index: 0, number: totalUpsell },
    { titleKey: 'upsell.approved', index: 1, number: totalAccepted },
    { titleKey: 'upsell.pending', index: 2, number: totalPending },
    { titleKey: 'upsell.rejected', index: 3, number: totalRejected },
    { titleKey: 'upsell.upsellRevenue', index: 4, number: `${revenue} ${currency}` },
  ];
  const datasetOptions = {
    totalUpsell: {
      color: 'blue',
      visible: true,
      data: upsellsCountPerDay,
    },
    accepted: {
      color: 'grey',
      visible: selectedCounter === 0 || selectedCounter === 3,
      data: accepted,
    },
    pending: {
      color: 'grey',
      visible: selectedCounter === 1,
      data: pending,
    },
    rejected: {
      color: 'grey',
      visible: selectedCounter === 2,
      data: rejected,
    },
    upsellRevenue: {
      visible: false,
    },
  };

  const createDataset = counterKey => {
    const options = datasetOptions[counterKey];
    return options.visible
      ? {
          id: counters.findIndex(counter => counter.titleKey === counterKey),
          label: translate('upsell.' + counterKey),
          data: options.data,
          borderColor: options.color,
          hidden: !options.visible,
          lineTension: 0.3,
          pointBackgroundColor: options.color,
          pointBorderColor: options.color,
        }
      : null;
  };

  if (isLoading) {
    return (
      <Card style={{ margin: 30 }}>
        <div style={styles.container}>
          <Spin />
        </div>
      </Card>
    );
  }

  return (
    <Card style={{ height: '100%', margin: 30 }}>
      <div style={styles.container}>
        <div style={styles.datePicker}>
          <RangePicker defaultValue={defaultValue} onChange={setSelectedDates} />
        </div>
        <div style={styles.countersBox}>
          {counters.map(counter => (
            <Contador
              key={counter.index}
              title={translate(counter.titleKey)}
              number={counter.number}
              isSelected={selectedCounter === counter.index}
              onClick={() => setSelectedCounter(counter.index)}
            />
          ))}
        </div>
        <div>
          <Line
            datasetIdKey="id"
            data={{
              labels: labels,
              datasets: Object.keys(datasetOptions)
                .map(createDataset)
                .filter(dataset => dataset !== null),
            }}
          />
        </div>
        <div style={styles.breakdownBox}>
          <Breakdown
            onViewDetailsClick={handleViewDetailsClickUpsell}
            breakdown={upsellListStatus}
          />
          <Breakdown
            onViewDetailsClick={handleViewDetailsClickRoom}
            breakdown={upsellListFromandTo}
          />
        </div>
      </div>
    </Card>
  );
}
