import React, { useContext } from 'react';
import DefaultLayout from '../../DefaultLayout/DefaultLayout';
import ContentBox from '../../common/ContentBox/ContentBox';
import useUsersHook from './Users.hook';
import { Table, Modal, Button, Space } from 'antd';
import { AppContext } from 'App.context';
import { capitalize } from 'utils/TextUtils';
import AppTextInput from 'Components/common/AppTextInput/AppTextInput';
import AppButton from 'Components/common/Forms/AppButton/AppButton';
import styles from './Users.styles';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export default function Users() {
  const { state, actions } = useUsersHook();
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (_, row) => {
        return <div>{capitalize(row.name)}</div>;
      },
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    // {
    //   title: 'Editar',
    //   key: 'key',
    //   dataIndex: 'key',
    //   render: (text, hotel) => null,
    //   // <Button icon={<EditOutlined />} hotel={hotel} action={hotelsActions.onSelectHotel} />
    // },
    // {
    //   title: 'Borrar',
    //   key: 'key',
    //   dataIndex: 'key',
    //   render: (text, hotel) => null,
    //   // <Button icon={<DeleteOutlined />} hotel={hotel} action={hotelsActions.onDeleteHotel} />
    // },
  ];
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <DefaultLayout title={translate('layout.users')} showHotelSelector={false}>
      <ContentBox title={'Añadir un nuevo usuario'}>
        <AppTextInput
          value={state.name}
          name={translate('register.name')}
          onChange={value => actions.setField('name', value)}
        />
        <AppTextInput
          value={state.email}
          name={translate('register.mail')}
          onChange={value => actions.setField('email', value)}
        />
        <div style={styles.buttonContainer}>
          <AppButton text={translate('invite')} action={() => actions.validateFieldsAndSend()} />
        </div>
      </ContentBox>
      <ContentBox noPadding title={'Usuarios'}>
        <Table
          isLoading={state.isLoading}
          dataSource={state.users}
          columns={columns}
          pagination={4}
        />
      </ContentBox>
    </DefaultLayout>
  );
}
