import axios from 'axios';
import apiConfig from '../api.config';

export default function crossSellUpdateChargesRequest({ hotelId, bookingIdentifier, data }) {
  const {
    integrationHubHost,
    URIs: { crossSellUpdateCharges },
  } = apiConfig;

  const uri = `${integrationHubHost}${crossSellUpdateCharges}?bookingIdentifier=${bookingIdentifier}`.replace(':hotelId', hotelId);

  return axios
    .put(uri, JSON.stringify({ data }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
