import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import Routes from 'Sell/Upsell/Router/routes';
import RoomTypeList from 'Sell/Upsell/RoomType/List/RoomTypeList';
import UpsertRoomType from 'Sell/Upsell/RoomType/Upsert/UpsertRoomType';
import UpsellList from 'Sell/Upsell/Upsell/List/UpsellList';
import Reports from 'Sell/Upsell/Reports/Reports';

export default function NestedRoutes() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}${Routes.roomsList}`}>
        <RoomTypeList />
      </Route>
      <Route path={`${path}${Routes.roomCreate}`}>
        <UpsertRoomType />
      </Route>
      <Route path={`${path}${Routes.roomUpsert}`}>
        <UpsertRoomType />
      </Route>
      <Route exact path={`${path}${Routes.upsellsList}`}>
        <UpsellList />
      </Route>
      <Route exact path={`${path}${Routes.reports}`}>
        <Reports />
      </Route>
      <Redirect to={`${path}${Routes.roomsList}`} />
    </Switch>
  );
}
