import { useState } from 'react';
import guestSevice from '../../../../../../utils/networking/api/validations/guest.service';

const useSendDataHook = () => {
  const [loading, setLoading] = useState(false);

  const sendData = async ({ hotelId, bookingIdentifier, mockGuests }) => {
    setLoading(true);

    try {
      return await guestSevice({
        hotelId,
        bookingIdentifier,
        authorization: process.env.REACT_APP_AUTH,
        mockGuests,
      });
    } catch (error) {
      console.error('Error Send Guest:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { sendData, loading };
};

export default useSendDataHook;
