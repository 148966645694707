export default {
    tagActive: {
        color: '#027A48',
        backgroundColor: '#ECFDF3',
        borderRadius: 16,
        border: 0,
        width: 110,
        height: 22,
        paddingLeft: 0,
    },
    tagInactive: {
        color: '#B54708',
        backgroundColor: '#FFEFC1',
        borderRadius: 16,
        border: 0,
        width: 110,
        height: 22,
        paddingLeft: 5,
    },
}