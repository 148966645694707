import Colors from 'constants/Colors';

export default {
  codeContainer: {
    backgroundColor: Colors.lightGray,
    color: Colors.darkText,
    fontFamily: 'courier',
    borderRadius: 8,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
    justifyContent: 'space-between',
  },
  exampleContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    gap: 16,
    width: '30%',
  },
};
