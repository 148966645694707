import React, { useContext } from 'react';
import styles from './AccountOwnerDetail.style';
import { AppContext } from 'App.context';
import AppTextInput from 'Components/common/AppTextInput/AppTextInput';
import PhoneInput from 'react-phone-input-2';
import Space from 'Components/common/Space/Space';
import useAccountOwnerDetailHook from './AccountOwnerDetail.hook';
import AppButton from 'Components/common/Forms/AppButton/AppButton';

export default function AccountOwnerDetail() {
  const {
    appState,
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useAccountOwnerDetailHook();
  const isOwnerUser = appState.userId === appState.ownerId;

  return (
    <div>
      {appState.chain?.name && (
        <>
          <AppTextInput value={appState.chain.name} name={translate('register.chain')} disabled />
          <Space height={8} />
        </>
      )}
      <AppTextInput
        value={state.name}
        name={translate('register.name')}
        onChange={value => actions.setField('name', value)}
        disabled={!isOwnerUser}
      />
      <Space height={8} />
      <AppTextInput
        value={state.email}
        name={translate('register.mail')}
        onChange={value => actions.setField('email', value)}
        disabled
      />
      <Space height={8} />
      <div style={styles.inputTitle}>{translate('register.phone')}</div>
      <PhoneInput
        inputStyle={styles.input}
        buttonStyle={styles.button}
        country={'es'}
        value={state.phone}
        placeholder={translate('register.phone')}
        onChange={phone => actions.setField('phone', phone)}
        disabled={!isOwnerUser}
      />

      {isOwnerUser && (
        <div style={styles.buttonContainer}>
          <AppButton text={translate('save')} action={() => actions.validateFormAndUpdateUser()} />
        </div>
      )}
    </div>
  );
}
