import React, { useContext, useState } from 'react';
import AppInput from 'Components/common/AppInput/AppInput';
import { AppContext } from 'App.context';
import FormRow from 'Sell/Components/Upsert/FormRow/FormRow.jsx';
import LangSelector from 'Sell/Components/Upsert/LangSelector/LangSelector.jsx';
import LimitedText from 'Sell/Components/Upsert/Description/LimitedText.jsx';
import SupportedLanguages from 'modules/localization/SupportedLanguages';
import styles from './FormRoomType.styles.js';
import CustomSelector from 'Sell/Components/CustomSelector/CustomSelector.jsx';
import PhotoGallery from 'Sell/Components/PhotoGallery/PhotoGallery.jsx';
import RoomUpsellToOption from 'Sell/Upsell/RoomType/Upsert/FormRoomType/Components/RoomUpsellToOption/RoomUpsellToOption.jsx';
import FormRoomUpsellToOption from './Components/FormRoomUpsellToOption/FormRoomUpsellToOption.jsx';
import CustomButton from 'Components/common/CustomButton/CustomButton.jsx';
import Colors from 'constants/Colors.js';
import useRoomTypeManager from './FormRoomType.hook.js';
import { RoomDetails } from './Components/RoomDetails/RoomDetails';
import { Alert, Spin } from 'antd';
import Visor from '../Visor/Visor.jsx';

export default function FormRoomType() {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const {
    dataSource,
    handleCheckAmenities,
    handleCheckFeatures,
    handleClickBack,
    handleClickSaveRoom,
    handleLanguageChange,
    handleConfirmationChange,
    handleTitleChange,
    handleFileChange,
    handleAddUpsellToOptions,
    handleUpdateUpsellToOptions,
    handleDeleteUpsellToOptions,
    handleEditUpsellToOptions,
    handlePriceCHange,
    handleTypePriceCHange,
    handleRoomTypeChange,
    handleDeleteImage,
    handleSetEditUpsell,
    setRoomTypeTexts,
    setPMSId,
    handleOnPhotoDragEnd,
  } = useRoomTypeManager();

  const [editMode, setEditMode] = useState(false);
  const onEdit = record => {
    handleSetEditUpsell(record);
    setEditMode(record);
  };
  const saveEdit = () => {
    handleEditUpsellToOptions();
    setEditMode(false);
  };

  const formOptions = {
    roomId: dataSource.state.id,
    upsellOptionsFrom: dataSource.state.upsellOptionsFrom,
    roomUpsellToOptions: dataSource.roomTypes?.data,
    currentUpsell: dataSource.state.currentUpselOptions,
    price: 10,
    onAdd: handleAddUpsellToOptions,
    onEdit: saveEdit,
    onRoomTypeChange: handleRoomTypeChange,
    onTypeOfPriceChange: handleTypePriceCHange,
    onPriceChange: handlePriceCHange,
    editMode,
  };

  const correctedLanguages = dataSource?.hotelLanguages?.map(language => {
    if (language.name === 'Holandés' && (language.id === 'de' || language.id === 'en')) {
      return { ...language, id: 'nl' };
    }
    return language;
  });

  return (
    <div style={styles.wrapper}>
      <div style={{ flex: 1 }}>
        <div>
          <div style={styles.header}>
            <div style={styles.buttonsContainer}>
              <CustomButton
                text={translate('upsell.roomDetail.back')}
                background={Colors.white}
                color={Colors.exportButton}
                onClick={handleClickBack}
              />
              <CustomButton
                disabled={dataSource.isLoading}
                text={
                  <>
                    {dataSource.isLoading ? <Spin style={{ marginRight: 10 }} /> : null}
                    {translate('upsell.roomDetail.saveRoom')}
                  </>
                }
                background={Colors.exportButton}
                color={'white'}
                onClick={handleClickSaveRoom}
              />
            </div>
          </div>

          <div style={styles.container}>
            <FormRow>
              <LangSelector
                suportedlanguages={correctedLanguages}
                defaultLang={dataSource.currentLang}
                setSelectedLanguage={handleLanguageChange}
              />
            </FormRow>
            <FormRow>
              <AppInput
                title={translate('upsell.roomType')}
                hint={translate('upsell.roomTypeInfo')}
                value={dataSource.state.currentName.value}
                onChange={value => handleTitleChange(value)}
              />
            </FormRow>
            <FormRow>
              <LimitedText
                language={dataSource.state.selectedLanguage}
                text={dataSource.state.currentTexts.value}
                limit={200}
                onChange={value => setRoomTypeTexts(value, 'desc')}
              />
            </FormRow>
            <FormRow title={translate('upsell.photoGallery')}>
              <PhotoGallery
                photoGallery={dataSource.state.physicalRoomGalleries}
                handleFileChange={handleFileChange}
                handleDeleteImage={handleDeleteImage}
                fileList={dataSource.fileList}
                handleOnDragEnd={handleOnPhotoDragEnd}
              />
            </FormRow>
            <FormRow>
              <AppInput
                title={translate('upsell.pmsIdentifier')}
                hint={translate('upsell.pmsIdentifierInfo')}
                onChange={setPMSId}
                value={dataSource.state.code}
                tooltipInfo={translate('upsell.pmsTooltip')}
              />
            </FormRow>
            <FormRow title={translate('upsell.amenities')}>
              {dataSource.amenitiesList && (
                <RoomDetails
                  data={dataSource.amenitiesList.data}
                  onCheck={handleCheckAmenities}
                  selectedFeatures={dataSource.state.amenities}
                />
              )}
            </FormRow>
            <FormRow title={translate('upsell.roomFeatures')}>
              {dataSource.featuresList && (
                <RoomDetails
                  data={dataSource.featuresList.data}
                  onCheck={handleCheckFeatures}
                  selectedFeatures={dataSource.state.roomFeatures}
                />
              )}
            </FormRow>
            {/* <FormRow title={'Upsells'}>
              <CustomSelector
                confirmationType={
                  dataSource.state.confirmationType ? dataSource.state.confirmationType : 'manual'
                }
                onConfirmationTypeChange={handleConfirmationChange}
                tooltipInfo={translate('upsell.confirmationTypeTooltip')}
              />
            </FormRow> */}
            <FormRow title={'Upsells'} style={styles.row}>
              <FormRoomUpsellToOption {...formOptions} />
            </FormRow>
            <Alert message={translate('upsell.addUpsellWarning')} type="info" />
            <FormRow title={translate('upsell.typesOfRoomsCreated')} style={styles.row}>
              <RoomUpsellToOption
                data={dataSource.state.upsellOptionsFrom.sort((a, b) => a.order - b.order)}
                handleOrderChange={newArray => handleUpdateUpsellToOptions(newArray)}
                onDelete={id => handleDeleteUpsellToOptions(id)}
                editMode={editMode}
                onEdit={onEdit}
              />
            </FormRow>
          </div>
        </div>
      </div>
      <Visor room={dataSource.state} lang={dataSource.currentLang} />
    </div>
  );
}
