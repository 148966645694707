import { InfoCircleOutlined } from '@ant-design/icons';
import { Input, Tooltip, Select } from 'antd';
import React from 'react';
import styles from './ConfigurationFormInput.styles';

const { Option } = Select;

export default function ConfigurationFormInput({
  field,
  value,
  onChange,
  tooltip,
  name,
  literals,
}) {
  const suffix = getSuffix(field.info, tooltip);

  return (
    <div>
      <div>{name}</div>
      <div style={field.type === 'split' ? null : styles.borderStyle}>
        {field.type === 'select' ? (
          <Select
            style={{ width: '100%' }}
            bordered={false}
            onChange={option => onChange(field.key, option)}
            value={value}
          >
            {field.options.map((option, index) => {
              const value = option;

              if (field.key === 'testEnvironment') {
                if (option === '1') option = 'yes';
                if (option === '0') option = 'no';
              }

              const text = literals[`o_${option.replace('+', '_')}`] || option;

              return (
                <Option key={index} value={value}>
                  {text}
                </Option>
              );
            })}
          </Select>
        ) : field.type === 'split' ? (
          <SplitInput field={field} literals={literals} onChange={onChange} />
        ) : (
          <Input
            bordered={false}
            value={value}
            {...suffix}
            onChange={text => onChange(field.key, text.target.value, null, field?.table)}
          />
        )}
      </div>
    </div>
  );
}

function SplitInput({ field, literals, onChange }) {
  return (
    <div style={styles.splitGrid}>
      {field.fields.map((subField, index) => {
        const suffix = getSuffix(subField.info, literals[`i_${subField.key}`] || subField.key);
        return (
          <div>
            <div style={styles.subField}>{literals[subField.key] || subField.key}</div>
            <Input
              key={index}
              placeholder={literals[subField.key] || subField.key}
              bordered={false}
              style={styles.borderStyle}
              value={field.splitValues[index]}
              {...suffix}
              onChange={text =>
                onChangeSplitField(field.splitValues, index, text.target.value, onChange)
              }
            />
          </div>
        );
      })}
    </div>
  );

  function onChangeSplitField(splitValues, position, value, onChange) {
    splitValues[position] = value;

    let format = field.format;
    for (const [index, _] of field.fields.entries()) {
      format = format.replace(`#${index}`, splitValues[index]);
    }

    onChange(field.key, format, splitValues);
  }
}

function getSuffix(info, tooltipText) {
  return info
    ? {
        suffix: (
          <Tooltip title={tooltipText}>
            <InfoCircleOutlined style={styles.tooltipIcon} />
          </Tooltip>
        ),
      }
    : null;
}
