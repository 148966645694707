import React from 'react';
import styles from './PriceIndicator.styles';

export default function PriceIndicator({ price }) {
  return (
    <div style={styles.container}>
      <div style={styles.line} />
      <div>{price}</div>
    </div>
  );
}
