import { notification } from 'antd';
import { AppContext } from 'App.context';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';

export default function usePoliceReportConfigHook() {
  const {
    appState,
    appActions: { getSelectedHotel, translate },
  } = useContext(AppContext);
  const { API } = useContext(ApiContext);

  const [state, setState] = useState({
    isLoading: false,
    ftpHost: null,
    ftpUser: null,
    ftpPass: null,
    fileFormat: null,
    ftpDirPath: null,
    sesUser: null,
    sesPass: null,
    lessorCode: null,
    establishmentCode: null,
    suscription: null,
    sendSuscriptionRequestEnabled: true,
  });

  useEffect(() => {
    actions.loadPoliceReportConfig();
  }, [appState.selectedHotel]);

  const actions = {
    loadPoliceReportConfig: () => {
      updateState({ isLoading: true });
      API.getPoliceConfig({ hotelId: getSelectedHotel()?.key })
        .then(res => {
          updateState({
            isLoading: false,
            ftpHost: res?.ftpHost,
            ftpUser: res?.ftpUser,
            ftpPass: res?.ftpPass,
            ftpPort: res?.ftpPort,
            fileFormat: res?.fileFormat,
            sesUser: res?.sesUser,
            sesPass: res?.sesPass,
            ftpDirPath: res?.ftpDirPath,
            lessorCode: res?.lessorCode,
            establishmentCode: res?.establishmentCode,
            suscription: res?.suscription === 'active',
          });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },

    onCheckConnection: () => {
      updateState({ isLoading: true });
      API.policeReportCheckSFTPConnection({
        hotelId: getSelectedHotel()?.key,
        host: state.ftpHost,
        username: state.ftpUser,
        password: state.ftpPass,
        port: parseInt(state.ftpPort),
        folder: state.ftpDirPath,
      })
      .then(_ => {
        updateState({ isLoading: false });
        notification.success({
          message: translate('policeReport.checkConnectionSuccess'),
        });
      })
      .catch(err => {
        console.warn(err);
        updateState({ isLoading: false });
        notification.error({
          message: translate('policeReport.checkConnectionError'),
        });
      });
    },

    onCheckSESConnection: () => {
      updateState({ isLoading: true });
      API.policeReportCheckSESConnection({
        hotelId: getSelectedHotel()?.key,
        sesCredentials: {
          username: state.sesUser,
          password: state.sesPass,
          lessorCode: state.lessorCode,
          establishmentCode: state.establishmentCode,
        },
      })
      .then(result => {
        updateState({ isLoading: false });
        
        if ( result.success ) {
          notification.success({
            message: translate('policeReport.checkSESConnectionSuccess'),
          });
        } else {
          notification.error({
            message: translate('policeReport.checkSESConnectionError'),
          });
        }
      })
      .catch(err => {
        console.warn(err);
        updateState({ isLoading: false });
        notification.error({
          message: translate('policeReport.checkSESConnectionError'),
        });
      });
    },

    onSaveFTPConfig: () => {
      updateState({ isLoading: true });
    
      API.updatePoliceSFTP({
        hotelId: getSelectedHotel()?.key,
        ftpConfig: {
          host: state.ftpHost,
          user: state.ftpUser,
          password: state.ftpPass,
          port: state.ftpPort,
        },
        fileFormat: state.fileFormat,
        ftpDirPath: state.ftpDirPath,
      })
      .then(_ => {
        API.policeReportCheckSFTPConnection({
          hotelId: getSelectedHotel()?.key,
          host: state.ftpHost,
          username: state.ftpUser,
          password: state.ftpPass,
          port: parseInt(state.ftpPort),
          folder: state.ftpDirPath,
        })
          .then(_ => {
            updateState({ isLoading: false });
            notification.success({
              message: translate('policeReport.checkConnectionSuccess'),
            });
          })
          .catch(err => {
            console.warn(err);
            updateState({ isLoading: false });
            notification.error({
              message: translate('policeReport.checkConnectionError'),
            });
          });
      })
      .catch(err => {
        // ERROR saving credentials
        console.warn(err);
        updateState({ isLoading: false });
        notification.error({
          message: translate('policeReport.ftpError'),
        });
      })
      
      },
      
      onSaveSESConfig: () => {
        updateState({ isLoading: true });
    

        API.updatePoliceSES({
          hotelId: getSelectedHotel()?.key,
          sesCredentials: {
            sesCredentials: {
              user: state.sesUser,
              password: state.sesPass,
            },
            lessorCode: state.lessorCode,
            establishmentCode: state.establishmentCode,
          },
        })
          .then(_ => {
            API.policeReportCheckSESConnection({
              hotelId: getSelectedHotel()?.key,
              sesCredentials: {
                username: state.sesUser,
                password: state.sesPass,
                lessorCode: state.lessorCode,
                establishmentCode: state.establishmentCode,
              },
            })
            .then(result => {
              updateState({ isLoading: false });
              
              if ( result.success ) {
                notification.success({
                  message: translate('policeReport.checkSESConnectionSuccess'),
                });
              } else {
                notification.error({
                  message: translate('policeReport.checkSESConnectionError'),
                });
              }
            })
            .catch(err => {
              console.warn(err);
              updateState({ isLoading: false });
              notification.error({
                message: translate('policeReport.checkSESConnectionError'),
              });
            });
          })
          .catch(err => {
            //ERROR saving credentials
            console.warn(err);
            updateState({ isLoading: false });
            notification.error({
              message: translate('policeReport.sesError'),
            });
          });
        
    },
    
    
    

    requestGuestlinkFunctionality: () => {
      updateState({ isLoading: true });
      API.requestFunctionalityContract({
        hotelId: getSelectedHotel().key,
        suscription: 'police_report',
        functionality: 'Police Report Module',
      })
        .then(_ => {
          actions.sendRequestPoliceFunctionalityEmail();
        })
        .catch(_ => {
          updateState({ isLoading: false });
        });
    },

    sendRequestPoliceFunctionalityEmail: () => {
      updateState({ isLoading: true });
      API.sendRequestFuncionalityEmail({
        hotelId: getSelectedHotel().key,
        functionality: 'Police Report Module',
      })
        .then(_ => {
          notification.success({
            message: translate('policeReport.missingSuscription.requestSent'),
          });
          updateState({ isLoading: false, sendSuscriptionRequestEnabled: false });
        })
        .catch(_ => {
          updateState({ isLoading: false });
        });
    },

    setField: (fieldKey, value) => {
      updateState({ [fieldKey]: value });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
