import React, { useContext } from 'react';
import { AppContext } from '../../../../../App.context';
import { getInputText } from '../../../../../utils/TextUtils';
import styles from './CheckInInput.styles';

export default function CheckInInput({ title, value }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const text = getInputText(value, translate);

  if (!title && value.includes('@')) title = translate('login.emailPlaceholder');

  return value ? (
    <div style={styles.container}>
      <div style={styles.title}>{title}</div>
      <div style={styles.inputContainer}>{text}</div>
    </div>
  ) : null;
}
