export default {
  container: {
    backgroundColor: 'white',
    display: 'flex',
    gap: '12px',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #979797',
    borderRadius: '8px',
  },
  select: {
    width: '140px',
  },
  option: {
    padding: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
  },
  flagIcon: {
    borderRadius: '50%',
    width: '19px',
    height: '19px',
    marginRight: '8px',
  },
};
