import upsell from './upsell/en';
import crossSell from './cross-sell/en';

export default {
  layout: {
    configuration: 'Configuration',
    dashboard: 'Dashboard',
    hotels: 'Hotels',
    forms: 'Forms',
    appearence: 'Appearence',
    pms: 'PMS',
    sales: 'Checkin channels',
    payment: 'Payment channels',
    documents: 'Legal Documents',
    language: 'Languages and literals',
    users: 'Team',
    checkin: 'Check-in',
    checkout: 'Check-out',
    booking: 'Reservations',
    paymentMethods: 'Payment Methods',
    billing: 'Billing info',
    subscriptions: 'Subscriptions',
    doorOpening: 'Door opening',
    doorOpeningConfig: 'Platform configuration',
    doorMapping: 'Door mapping',
    stats: 'Summary',
    account: 'My Account',
    communicationChannels: 'E-mail templates',
    accountDetail: 'Account details',
    marketing: 'Marketing',
    support: 'Support',
    quickguide: 'Start quickguide',
    guestlink: 'Guestlink',
    mailTemplates: 'Email templates',
    smtpConfig: 'SMTP configuration',
    paymentList: 'Payments',
    paperlessPush: 'Paperless push',
    cardex: 'Checkin report',
    custom_cardex_pdf: 'Entry part',
    terms: 'Terms and Conditions',
    'data-privacy-policy': 'Privacy Policy',
    'loyalty-program': 'Loyalty Program',
    'data-commercial-use': 'Commercial Use',
    policeReport: 'Police report',
    policeReportConfig: 'Police report configuration',
    billingConfig: 'Billing',
    moduleActivation: 'Module activation',
    upsell: 'Upselling',
  },
  save: 'Save',
  cancel: 'Cancel',
  search: 'Search',
  add: 'Add',
  yes: 'Yes',
  no: 'No',
  setup: 'Connect',
  checkinPreview: 'Checkin Preview',
  logout: 'Log out',
  state: 'State',
  hotel: 'Hotel',
  pms: 'PMS',
  id: 'ID',
  invite: 'Invite',
  family_relationship:
    'If marked as mandatory, the field will only be required for the reservation holder when there are children included.',
  document_support_number:
    'If marked, the field will only be required for DNI or Residence Permit; for other document types, it will be visible but optional.',
  pmsPopup: {
    title: '¡Perfect!',
    subtitle: 'You have just registered your property',
    legalTextNewHotel:
      'The Entry Part and the Checkin Report in English and Spanish have been successfully saved',
    text: 'Now you can optimize the process by connecting your PMS',
    button: 'PMS Setup',
  },
  hotelSelector: {
    onlyTo: 'Only to:',
    applyToAllHotels: 'All hotels',
    allHotels: 'All hotels',
    hint: 'Enter the name of the hotel',
    info: 'Select Hotel:',
  },
  channel: 'Channel',
  checkins: 'Checkins',
  appearence: {
    checkInTitle: 'Online Check-In Appearance',
    logo: 'Logo',
    mainColor: 'Main color',
    secondaryColor: 'Secondary color',
    bgColor: 'Background Color',
    kioskTitle: 'Kiosk Appearance',
    mainColorKiosk: 'Kiosk main color',
    kioskBg: 'Background color Kiosk',
    kioskLogo: 'Logo de Kiosk',
    success: 'Appearence updated successfully',
    languageSuccess: 'Languages updated successfully',
    apply: 'Apply styles',
    languageTitle: 'Select the languages you need',
    imageSliderTitle: 'T-paperless rest carousel',
    upload: 'Add',
    imageSliderDesc:
      'Here you can configure the images that will be displayed in the carousel. You can add up to 5 images',
  },
  login: {
    header: 'Please enter your login information',
    emailPlaceholder: 'Email',
    passwordPlaceholder: 'Password',
    loginButton: 'Login',
    registerButton: 'Create new account',
    forgotPassword: 'Forgot your password?',
  },
  register: {
    title: 'Account information',
    chain: 'Hotel Chain Name',
    name: 'Name',
    phone: 'Phone',
    mail: 'Email',
    password: 'Password',
    repeatPassword: 'Repeat password',
    termsConditions: 'I have read and accept the privacy policy and terms and conditions',
    button: 'Create account',
    emptyFields: 'You must fill in all fields in the form',
    matchPasswords: 'The passwords do not match',
    invalidMail: 'The email address is invalid',
    termsError: 'You must accept the terms and conditions',
    registerOk:
      'Welcome to Civitfun, we have sent you an email to # to validate your registration. By clicking on "Add payment method" you will be redirected to configure a payment method.',
    registerKO: 'Failed to add payment method successfully',
    goBack: 'Return to login',
    goLogin: 'Go to login',
    addPaymentMethod: 'Add payment method',
  },
  recover: {
    header:
      'Enter the email address associated with your account and we will send you an email to recover your password.',
    invalidEmail: 'Enter a valid email address',
    send: 'Send',
    success: 'We have e-mailed your password reset link!',
  },
  changePassword: {
    header: 'Enter your new password',
    success: 'Password has been changed successfully',
  },
  validateUser: {
    success: '# registration has been completed successfully!',
  },
  bookingList: {
    nameAndSurnameCol: 'Name and surname',
    locatorCol: 'Locator',
    nameCol: 'Name',
    surnameCol: 'Surname',
    checkinCol: 'Entrance',
    checkoutCol: 'Departure',
    adultCol: 'Adults',
    childrenCol: 'Children',
    documentCol: 'Document',
    export: 'Export data',
    import: 'Import reservations',
    title: 'Reservations',
    statusCol: 'State',
    actionPop: 'See related checkins',
  },
  checkOutModule: {
    locatorCol: 'Locator',
    nameCol: 'Name',
    surnameCol: 'Surname',
    entranceDate: 'CheckIn',
    departureDate: 'CheckOut',
    title: 'Check-Out',
    export: 'Export Data',
    actions: 'Details',
    channelCol: 'Channel',
    date: 'Check-out',
    exportOk: 'Export of checkouts completed!',
    missingSubscription: {
      title: 'Get license',
      description:
        "You can set up Check-Out for the hotel by activating the 'Check-Out' license. Click the button below, and the Customer Success team will get in touch with you.",
    },
  },
  bookingStatus: {
    BOOKED: 'Booked',
    UNCOMPLETED: 'Not completed',
    COMPLETED: 'Completed',
    CHECKEDOUT: 'Checked Out',
    CHECKEDIN: 'Checked In',
  },
  setUp: {
    searchFieldsTitle: 'Search form fields',
    searchFieldInfo:
      'If the check-in process is integrated with your PMS these fields will be configured automatically',
    checkInRequirement: 'Check-in process requirements',
    bookingSummary: 'Fields Displayed in Reservation Summary',
  },
  bookingSummary: {
    showBookingNumber: 'Booking Number',
    showHolderName: 'Holder',
    showArrivalDate: 'Arrival date',
    showDepartureDate: 'Departure date',
    showRoomName: 'Room',
    infoText:
      'The "room" field will show both the room number and type if available in the PMS at check-in. If only one is available, only that will be displayed.',
  },
  guestTable: {
    fieldCol: 'Field',
    visibleCol: 'Visible',
    mandatoryCol: 'Mandatory',
    success: 'The guest form configuration has been updated',
    error: 'Failed to update the guest form configuration',
    applyToAllWarning: 'The configuration will be applied to every hotel',
    languageSelector: 'Select the language of the fields',
    italyDesc:
      'This hotel is located in Italy, this field will be shown if the country of birth is Italy',
    italyResidencyDesc:
      'This hotel is located in Italy, this field will be shown if the country of residency is Italy',
    translationMissing: 'The translation for this field is missing for other languages',
  },
  checkInList: {
    stateCol: 'State',
    locatorCol: 'Locator',
    typeCol: 'Type',
    nameCol: 'Name',
    surnameCol: 'Surname',
    checkinCol: 'CheckIn',
    checkoutCol: 'CheckOut',
    documentCol: 'Document',
    title: 'Check-In',
    export: 'Export Data',
    statePending: 'Pending',
    stateCheckin: 'Check In',
    stateCheckout: 'Check Out',
    actions: 'Detail',
    channelCol: 'Channel',
    checkinDate: 'Check In',
    popUp: {
      processingTitle: 'Processing',
      processingMessage: 'We are processing your request. Please wait.',
    },
    detail: {
      bookingDetail: 'Booking details',
      informationTitle: 'Information',
      documentsTitle: 'Documents',
      locator: 'Locator',
      checkin: 'Checkin',
      checkout: 'Checkout',
      holder: 'Holder',
      signedDocuments: 'Signed documentss',
      termsConditions: 'Terms and Conditions',
      cardex: 'Checkin report',
      guestDetail: 'Holder data',
      paymentDetail: 'Payment details',
      payment: {
        charges: 'Charges',
        operations: 'Operations',
        locator: 'Locator',
        amount: 'Amount',
        operation: 'Type',
        concept: 'Concept',
        paymentStatus: 'Payment state',
        warrantyStatus: 'Warranty status:',
        statusDivider: 'Status of payments',
        totalBalance: 'Total balance',
        operationPayment: 'Payment',
        operationTokenize: 'Warranty',
        operationPaymentTokenize: 'Payment and Warranty',
      },
    },
  },
  ihClient: {
    CIVITFUN: 'Civitfun',
    MTS: 'MTS',
    ROOMMATIK: 'Roommatik',
    BOOKINGDOTCOM: 'Booking.com',
    KIOSK: 'Auto Check-In Terminal',
    RECEPTION: 'Paperless Terminal',
    ['BOOKING.COM']: 'Booking.com',
  },
  salesChannels: {
    stateCol: 'State',
    hotelCol: 'Hotel',
    channelCol: 'Channel',
    actionCol: 'Action',
    identifierCol: 'Identifier',
    bookingIdHint: 'Booking.com hotel Id.',
    bookingTooltip:
      'What is this? You can find the ID of your property on Booking.com in your administration panel',
    tryFreeButton: 'Try it for free',
    success: 'Check-in channel successfully updated!',
    otaPopup: {
      title: 'Perfect!',
      text: 'All you have to do is activate the $OTA channel to start receiving check-ins',
      button: 'Activate $OTA',
    },
    activeGuestlink1: 'To enable Booking.com integration, ',
    activeGuestlink2: 'you need to configure your SMTP settings',
  },
  guestForms: {
    holder: 'Holder',
    adults: 'Adults',
    children: 'Children',
    applyToAllHotels: 'Apply to all hotels',
  },
  errorMessage: {
    dateRangeExport: 'Please select a date range to perform the export',
    guestDetail: "It's not possible to view the detail information at the moment",
  },
  hotels: {
    addNewHotel: 'Add a new hotel',
    title: 'Hotels',
    nameCol: 'Name',
    editCol: 'Edit',
    deleteCol: 'Delete',
    deleteWarningTitle: 'Delete',
    deleteWarning: 'Do you want to eliminate the hotel?',
    mapHint: 'Find your hotel on the Map',
    country: 'Country',
    address: 'Address',
    province: 'Province',
    addSuccess: 'Hotel # has been added',
    updateSuccess: 'The hotel # has been successfully updated',
    deleteSuccess: 'The hotel # has been removed',
    previewCheckin: 'My Online Check-in',
    integrated: 'Integrated',
    noIntegrated: 'No integrated',
    emptyTitle: 'Add your hotels',
    emptyDescription: 'To do so, just fill in the name field and look for your address on the map.',
    emptyName: 'You must add a name for the hotel',
    emptyLocation: 'You must add a location for the hotel',
    nif: 'NIF',
    establishmentType: 'Type of Property',
    roomCount: 'Number of Rooms',
    hasInternet: 'Has Internet Connection',
    city: 'City',
    postalCode: 'Postal Code',
    phone: 'Phone Number',
    email: 'Email',
    website: 'Website',
    designation: 'Fiscal name',
    adUrl: 'URL to identify the advertisement',
    hotelStatusId: 'Status (admin only)',
    statusId: {
      label_1: 'Created',
      label_2: 'Onboarding',
      label_3: 'Live',
      label_4: 'Out of licence',
      label_5: 'Lost',
      label_6: 'Demo',
      label_7: 'Pending',
    },
  },
  legalDocuments: {
    termsConditions: 'Terms and Conditions',
    checkIn: 'Checkin report',
    commercialUse: 'Text check-box commercial use of data',
    preview: 'Preview',
    previewBtn: 'Preview',
    success: 'The text was successfully updated',
    error: 'An error occurred while updating the text.',
    warning: 'Templates loaded by default. They will not be registered until you save them',
    previewPlaceholder: 'Click on "preview" to see a preview of the document.',
    langSelectorHint: 'Select the language of the documents',
    terms: 'Terms and conditions',
    restoreDefault: 'Reset',
    restoreDefaultWarning: 'Unsaved data will be lost. Do you want to continue?',
    cardex: 'Checkin report',
    labelHint: 'The label or text next to the checkbox',
    'data-privacy-policy': 'Privacy Policy',
    'loyalty-program': 'Loyality program',
    'data-commercial-use': 'Commercial use data',
    custom_cardex_pdf: 'Entry part',
    checkin_template: 'Check-in confirmation mail template',
    checkout_template: 'Check-out confirmation mail template',
    fileNamePrefix: 'File name',
    tags: {
      chain: 'Chain',
      chain_logo: 'Logo of the chain',
      chain_name: 'Chain name',
      hotel: 'Hotel',
      hotel_logo: 'Hotel logo',
      hotel_name: 'Hotel name',
      address: 'Address',
      hotel_phone: 'Hotel phone number',
      CIF: 'Company CIF of the hotel',
      CIF_postal_code: 'Postal code of the hotel company',
      CIF_address: 'Company address of the hotel',
      CIF_city: 'City of the hotel company',
      CIF_province: 'Province company of the hotel',
      CIF_country: 'Country of the hotel company',
      CIF_business_name: 'Company name of the hotel',
      CIF_business_email: 'Contact email of the hotel company',
      legal_cardex: 'Legal text on the entry part',
      legal_terms_and_conditions: 'Legal text about the property contract',
      legal_data_privacy_policy: 'Legal text about the data privacy policies',
      legal_data_commercial_use: 'Legal text about commercial use of data',
      legal_loyalty_program: 'Legal text about loyalty program membership',
      booking: 'Booking',
      reference_number: 'locator/voucher/voucher',
      booking_identifier: 'unique identifier in the PMS',
      entrance_date: 'Entry date',
      departure_date: 'Departure date',
      entrance_time: 'Entry time',
      departure_time: 'Departure time',
      nights: 'Number of nights',
      adults: 'number of adults',
      children: 'number of children',
      num_guests: 'total number of guests',
      email: 'email of the reservation',
      balance: 'price',
      agreed_price: 'Agreed price',
      booking_room: 'number of rooms',
      room_number: 'name of the assigned room',
      room_number_name: 'name of the room',
      customer_notes: 'customer notes',
      agency_code: 'agency code',
      regime_stay: 'regime stay',
      guest_name: 'guest name',
      guest_nationality: 'guest nationality',
      document_number: 'guest document number',
      holder_signature: 'holder signature',
      police_report_number: 'Police report number',
      guest: 'guest',
      guest_first_name: 'First name',
      guest_surname: 'Last_name',
      guest_second_surname: 'Second Last Name',
      guest_gender: 'Gender',
      guest_birth_date: 'Date of birth',
      guest_nationality: 'Nationality',
      guest_document_type: 'Type of document',
      guest_document_number: 'Document number',
      guest_expedition_date: 'Date of expedition',
      guest_expiration_date: 'Expiration date',
      guest_email: 'Email',
      guest_phone: 'Phone',
      guest_address: 'Address',
      guest_postal_code: 'Postal Code',
      guest_city: 'City',
      guest_country: 'Country',
      guest_province: 'Province',
      guest_signature: 'Signature',
      data_privacy_policy: 'Privacy policy',
      data_commercial_use: 'Data usage',
      loyalty_program: 'Loyalty program',
      credit_card: 'Credit card',
      card_name: 'Card name',
      card_type: 'Card type',
      card_number: 'Card number (Last 4 digits)',
      card_expiration_date: 'Expiration date',
      link_ios: 'iOS Link',
      link_android: 'Android Link',
      guest_business_name: 'Business name',
      guest_business_number: 'Business number',
      guest_business_address: 'Business address',
      bracelet_id: 'Bracelet UDF',
      checkin_url: 'Checkin Link',
      establishment_type: 'Establishment type',
      hotel_designation: 'Fiscal name',
      hotel_room_count: 'Number of rooms',
      hotel_has_internet: 'Has internet',
      guest_landline_phone: 'Landline phone',
      guest_document_support_number: 'Document support number',
      guest_family_relationship: 'Family relationship',
      guest_payment_type: 'Payment type',
      hotel_web: 'Hotel website',
      hotel_ad_url: 'Ad url',
      bracelet_id: 'Bracelet UDF',
      hotel_slug: 'Hotel slug',
      checkin_date: 'Checkin date',
      confirmation_number: 'Confirmation number',
      signer: 'Name of legal guardian',
      payment_url: 'Payment url',
      guest_allow_telephone_contact: 'Telephone contact consent',
      door_opening_url: 'Door key generation url',
      upsell_id: 'Upselling ID',
      upsell_request_date: 'Upselling request date',
      upsell_state: 'Upselling state',
      original_room_type: 'Original room type',
      requested_room_type: 'Requested room type',
      upsell_price: 'Upselling price',
      payment_status: 'Payment status',
      payment_url: 'Payment Link',
      upsell_url: 'Upselling Link',
      guest_signature_date: 'Date of signature',
      cross_sell_url: 'Cross-sell Link',
      cross_sell_id: 'Cross-sell ID',
      cross_sell_request_date: 'Cross-sell request date',
      cross_sell_state: 'Cross-sell state',
      item_name: "Item's name",
      cross_sell_price: 'Cross-sell price',
      confirmation_number: 'Booking identifier',
    },
  },
  literals: {
    title: 'Fill in these fields to customize your texts',
  },
  stats: {
    total: 'Total',
    avgDuration: {
      title: 'Average checkin time',
      subtitle: 'Average time taken by guest to check in',
      noData: 'No data ',
    },
    conversion: {
      title: 'Conversion',
      subtitle: 'Started checkins VS finished checkins',
      noData: 'No data',
    },
    mostCheckins: {
      title: 'Day and hour with most checkins',
      subtitle: 'Users check in more frequently on the days and at the times of day',
      noData: 'No data',
    },
    advance: {
      title: 'Checkin advance',
      subtitle: 'Users check in an average of one day before the check-in date.',
      noData: 'No data',
      days: 'Days',
      hours: 'Hours',
    },
    devices: {
      title: 'Main devices',
      phone: 'Phone',
      tablet: 'Tablet',
      desktop: 'Computer',
      unknown: 'N/A',
    },
  },
  pmsScreen: {
    title: 'PMS CONFIGURATION',
    defaultSelectorValue: 'Select a PMS',
    connectSuccess: 'Connection to the PMS has been established successfully',
    connectError: 'Error connecting to the PMS',
    testPms: 'Check connection',
    testPmsDesc: 'You can check the state of the PMS connection clicking on the Test PMS button',
    testBooking: 'Test booking',
    testBookingDesc: 'Enter the data to check the reservation',
  },
  states: {
    active: 'Active',
    inactive: 'Inactive',
    enabled: 'Enabled',
    disabled: 'Disabled',
    pending: 'Pending',
  },
  view: 'View',
  status: 'Status',
  checkinConfig: {
    allowed_since: 'From how many hours before the check-in date is online check-in allowed?',
    allowed_since_desc: 'Leave it to 0 if you do not want to limit it',
    allowed_until: 'How many hours before the check-in date is online check-in allowed?',
    allowed_until_desc: 'Leave it to 0 if you do not want to limit it',
    required_documents: 'For whom is data capture required on a mandatory basis?',
    required_signatures: 'Who has to sign the property contract?',
    required_cardex_signatures: 'Who has to sign the checkin report?',
    capture_docs: 'Document scanner configuration: ',
    success: 'Configuration successfully updated',
    checkinWarning: 'The checkin configuration will be updated',
    chainCheckinWarning: 'The configuration will be updated for all the hotels in the chain.',
    bookingCode: 'Booking code',
    bookingHolder: 'Booking holder',
    entranceDate: 'Checkin date',
    departureDate: 'Departure date',
    nights: 'Nights',
    paperless_start_checkin_timeout_seconds:
      'Maximum display time (in seconds) on the T-Paperless reservation screen',
    paperless_start_checkin_timeout_seconds_desc: 'Leave it to 0 if you do not want to limit it',
    paperless_finalize_checkin_timeout_seconds:
      'Maximum time (in seconds) of display at the end of the reservation in T-Paperless',
    paperless_finalize_checkin_timeout_seconds_desc: 'Leave it to 0 if you do not want to limit it',
    save_cardex: 'Does the checkin report need to be saved?',
    send_cardex: 'For whom does the checkin report need to be sent in the confirmation email?',
    save_contract: 'Does the property contract need to be saved?',
    send_contract: 'For whom does the property contract need to be sent in the confirmation email?',
    options: {
      o_true: 'Yes',
      o_false: 'No',
      o_none: 'None',
      o_adults: 'All adults',
      o_all: 'All guests',
      o_0: 'None',
      o_1: 'One',
      o_1_in_last_process: 'One, in the last process',
      o_holder: 'Holder',
      o_num_adults: 'All adults',
      o_num_guests: 'All guests',
      o_guest_over_2: 'All adults and children over 2 years old',
      o_older_than_10: 'All adults and children over 10 years old',
      o_guest_over_12: 'All adults and children over 12 years old',
      o_guest_over_14: 'All adults and children over 14 years old',
      o_guest_over_16: 'All adults and children over 16 years old',
      o_optional: 'Optional',
      o_required: 'Mandatory',
      o_payment_warranty: 'Payment and warranty',
      o_payment: 'Payment',
      o_warranty: 'Warranty',
      o_1_taxes: 'Taxes',
      o_optional_taxes: 'Optional with fees',
      o_active: 'Yes',
      o_disabled: 'No',
      capture_docs_optional: 'Optional',
      capture_docs_disabled: 'Disabled',
      capture_docs_mandatory: 'Mandatory',
    },
  },
  superUser: {
    selectAccount: 'Select Account',
    changeAccount: 'Change Account',
    accountName: 'Account name',
    hotelName: 'Hotel name',
    access: 'Enter',
  },
  view: 'View',
  status: 'Status',
  communicationChannels: {
    documentsBoxTitle: 'You can customize your e-mails in a very simple way',
    emailsSuccess: 'Email addresses have been updated',
    senderCheckinEmailHeader: 'Sender:',
    emailCheckinHeader: 'Send as cc to:',
    addNewMail: 'Add mail',
  },
  emptyHotelListError:
    'In order to use this functionality you must have a hotel. You can add them from the "Hotels" section',
  user: {
    updateSuccess: 'The user data has been updated correctly.',
    updateError: 'Error updating user data',
    newPassword: 'New password',
    currentPassword: 'Current password',
    emptyFields: 'The fields cannot be empty',
    passwordSuccess: 'The password has been changed',
    sendInvitationWarning: 'An invitation email will be sent to #. Do you wish to continue?',
    sendInviteSuccess: 'An e-mail has been sent to change the password to the new user',
    emptyPhone: 'The phone number cannot be empty',
    accountDetailTitle: 'Account Owner',
    changePasswordTitle: 'Change password',
  },
  marketing: {
    buttonGenerator: 'Web button generator',
    hotelCheckin: 'Hotel Check-In',
    chainCheckin: 'Chain Check-In',
    links: 'Links',
    selectHotel: 'Select an hotel',
    example: 'Example button:',
    buttonText: 'Online Check-in',
    deeplink: 'DEEPLINK: Increase the conversion of your Online Check-ins',
    option: 'Option',
    comingSoon: 'Coming soon',
    deeplink1desc:
      'Use basic parameters in the URL of your guests online Check-in mails to avoid search actions. As soon as your hotel is active, your guests can check-in online without searching for the reservation. You can integrate the link in the pre-stay templates by adding the necessary parameters to perform the search',
    deeplink2desc: 'Use the [BOOKING_IDENTIFIER] to generate a unique link for each booking.',
    bookingCodeTitle: 'What is the BOOKING CODE?',
    bookingCodeDesc: 'It is the locator that your guest received at the time of booking',
    entryDateTitle: 'What is the ENTRANCE DATE?',
    entryDateDesc: 'The ENTRANCE DATE is the entry date of the booking. Format: YYYY-MM-DD',
    bookingIdentifierTitle: 'What is the BOOKING IDENTIFIER?',
    bookingIdentifierDesc:
      'The booking identifier is the unique identifier of a given booking in your PMS. Use it as a dynamic field in your pre-booking mails and increase the conversion of your online check-ins.',
    bookingIdentifierFindTitle: 'Where do I find the BOOKING IDENTIFIER?',
    bookingIdentifierFindDesc:
      'The booking identifier is a field in your PMS. Your CRM should be able to get it from your PMS, in the same way that it gets the name of the reservation holder, the check-in date and other details used in the booking or pre-booking communication.',

    //Booking.com
    deeplinkBooking: 'DEEPLINK: Increase your Online Check-ins conversion with booking.com',
    deeplink1descBooking:
      'Add the provided URL to your internal communications from Booking.com to impact the check-in process of reservations made by guests through the platform',
    bookingCodeTitleBooking: 'Where should I add the BOOKING.COM DEEPLINK?',
    bookingCodeDescBookingLink: 'Follow the detailed instructions in the following article ',
    bookingCodeDescBooking:
      'to configure Booking.com internal communications and add the deeplink.',
  },
  steps: {
    title: 'Conversion funnel:',
    guestData: 'Guest Form Data',
    surveys: 'Surveys',
    upgrade: 'Upgrade',
    upsell: 'Upsell',
    payments: 'Payment',
    confirmPayment: 'Payment Confirm',
    contracts: 'Contracts + Signature',
    confirm: 'Checkin Confirm',
  },
  quickguide: {
    next: 'Next',
    back: 'Back',
    skip: 'Skip Guide',
    end: 'End Guide',
  },
  registerPaperless: {
    title: 'Account Information',
    createAccount: 'Continue',
    registerOk: 'User registered successfully',
    createButton: 'Add user',
  },
  paymentMethods: {
    emptyTitle: 'Add you payment methods',
    emptyDescription:
      'You can add your credit or debit card or your bank account information (SEPA)',
    addedSuccess: 'Payment method added successfully',
    credit_card: 'Credit card',
    sepa: 'SEPA direct debit',
    expires: 'Expires',
  },
  pmsCategory: {
    pms_api: 'API Configuration',
    pms_hotel_data: 'Hotel data configuration',
    payments: 'Payment configuration',
    address: 'Address configuration',
    checkin: 'Checkin configuration',
    checkout: 'Checkout configuration',
  },
  pmsName: {
    avalon2: 'Antfor (Avalon Channel)',
    avalon: 'Antfor (Civitfun channel)',
    guestportal: 'Quohotel',
    navision: 'Suitech',
    timon: 'Timon Hotel',
    noray_new: 'Noray New',
  },
  paymentScreen: {
    connectSuccess: 'Connection to the payment gateway has been established successfully',
    connectError: 'Error connecting to the payment gateway',
    defaultSelector: 'Select',
    platform: 'Platform',
  },
  paymentName: {
    stripe: 'Stripe',
    trustpayments: 'Trust Payments',
    mercadopago: 'Mercado Pago',
    redsys: 'Redsys',
    addonpayments: 'Addon Payments',
    sipay: 'Sipay',
    paytef: 'Paytef',
    planet: 'Planet',
    paycomet: 'Paycomet',
    adyen: 'Adyen',
    montevideocomm: 'Montevideocomm',
    transbankwebpay: 'Transbank Webpay',
  },
  doorOpening: {
    add: 'Add door mapping',
    list: 'Door mapping list',
    remove: 'Do you want to delete the selected door opening?',
    roomName: 'Room Name',
    pmsId: 'ID PMS',
    idOpening: 'ID access system',
    import: 'Upload excel',
    downloadTemplate: 'Download sample excel',
    templateFileName: 'Door mapping sample excel',
    defaultSelector: 'Select',
    mantaintenanceTitle: 'Door maintenance',
    mantaintenanceButton: 'Manage',
    platform: 'Platform',
    connectionData: 'Connection data',
    configuration: 'Configuration',
    checkinStatusOptions: {
      indifferent: 'Indifferent',
      checkedIn: 'Done',
    },
    paymentStatusOptions: {
      indifferent: 'Indifferent',
      paid: 'Paid',
    },
    keyType: 'Lockflow',
    keyTypes: {
      app: 'Application',
      pin: 'Pin',
      qr: 'QR Code',
      link: 'Link',
    },
    checkinStatusLabel: 'Check-in state',
    checkinStatusPlaceholder: 'Select check-in state',
    paymentStatusLabel: 'Payment state',
    paymentStatusPlaceholder: 'Select payment state',
    checkinHourLabel: 'Check-in hour',
    checkoutHourLabel: 'Check-out hour',
    missingSuscription: {
      title: 'Get license',
      description:
        "You can set up Door opening for the hotel by activating the 'Door opening' license. Click the button below, and the Customer Success team will get in touch with you.",
    },
  },
  paymentCategory: {
    platform: 'Connection data',
    payment_config: 'Configuration',
  },
  testOK: 'TEST OK',
  testKO: 'TEST KO',
  accountDetail: {
    functionality: 'Functionality',
    requested: 'Requested',
    active: 'Active',
    activateDeactivate: 'Activate/Deactivate',
    billing: 'Functionalities',
    contract: 'Request',
    available: 'Available',
    activate: 'Activate',
    deactivate: 'Deactivate',
    o_billing: {
      checkin: 'Check-in',
      tpaperless: 'T-Paperless',
      paperless_push: 'Paperless Push',
      upsell: 'Upselling (Legacy)',
      upsell_module: 'Upselling 🆕',
      upgrade: 'Cross-Selling (Legacy)',
      'cross-sell_module': 'Cross-Selling 🆕',
      payment: 'Payment Protection',
      door_opening: 'Door Opening',
      communication_tool: 'Guestlink',
      police_report: 'Guest Registration',
      checkout: 'Check-out',
      pms: 'PMS integration',
      booking: 'Booking.com channel',
      roomAssignment: 'Room assignment',
      softCheckout: 'Soft checkout',
      kiosk: 'Auto Check-in',
      mts: 'MTS channel',
      hbx: 'HBX channel',
    },
    emailWarningTitle: 'Hire the functionality',
    emailWarning:
      'The request will be notified and handled as soon as possible by our Customer Success team.',
    emailSuccess:
      'We have received your request correctly. We will contact you as soon as possible.',
    dashboardWarning:
      'Our Customer Success team has been informed of your request and will contact you shortly. By clicking "OK" you will be redirected to the settings screen of the selected functionality for configuration and activation.',
  },
  mailTemplates: {
    template: 'Mail Template',
    enabled: 'Active',
    type: 'Template type',
    templateName: 'Template name',
    subject: 'Subject',
    scheduling: 'Scheduling',
    createTemplate: 'Create template',
    matchingTypeWarningTitle: 'There is already an active template of this type',
    matchingTypeWarning:
      'Only one template can be active for each template type. Do you still want to activate the selected one?',
    emailType: 'Email type',
    marketing: 'Marketing',
    transactional: 'Transactional',
    templateType: {
      ['pre-stay']: 'Pre-stay',
      ['intra-stay']: 'Intra-stay',
      ['post-stay']: 'Post-stay',

      ['pre-stay-checkin-partial']: 'Check-in partial (each guest)',
      ['pre-stay-checkin-final']: 'Check-in finalized (just holder)',
      ['pre-stay-before-entrance-24-hour']: '24h before entrance date',
      ['pre-stay-before-entrance-48-hour']: '48h before entrance date',
      ['pre-stay-before-entrance-72-hour']: '72h before entrance date',
      ['pre-stay-before-entrance-5-day']: '5 days before entrance date',
      ['pre-stay-before-entrance-1-week']: '1 week before entrance date',
      ['pre-stay-before-entrance-2-week']: '2 weeks before entrance date',
      ['pre-stay-before-entrance-30-day']: '30 days before entrance date',
      ['pre-stay-police-report-notify-report-error']: 'Police report summary',

      ['intra-stay-checkout-partial']: 'Check-out partial (each guest)',
      ['intra-stay-checkout-final']: 'Check-out finalized',
      ['intra-stay-on-entrance-0-hour']: 'On entrance date',
      ['intra-stay-after-entrance-24-hour']: '24h after entrance date',
      ['intra-stay-before-departure-24-hour']: '24h before departure date',
      ['intra-stay-on-departure-0-hour']: 'On departure date',

      ['post-stay-after-departure-24-hour']: '24h after departure date',
      ['post-stay-after-departure-48-hour']: '48h after departure date',
      ['post-stay-after-departure-72-hour']: '72h after departure date',

      ['pre-stay-payment-partial']: 'Payment confirmation',
      ['pre-stay-payment-before-entrance-48-hour']: 'Payment 48h before check-in date',

      ['pre-stay-door-opening-on-checkin']: 'Email for key generation upon completing check-in',
      ['pre-stay-door-opening-0-hour']: 'Email for key generation on the day of arrival',

      ['pre-stay-upsell-24-hour']: 'Upselling 24h before entrance date',
      ['pre-stay-upsell-48-hour']: 'Upselling 48h before entrance date',
      ['pre-stay-upsell-hotel-requested']: 'Upselling request for Hotelier',
      ['pre-stay-upsell-hotel-accepted']: 'Upselling confirmation for Hotelier',
      ['pre-stay-upsell-hotel-rejected']: 'Upselling rejection for Hotelier',
      ['pre-stay-upsell-guest-accepted']: 'Upselling confirmation for Guest',
      ['pre-stay-upsell-guest-rejected']: 'Upselling rejection for Guest',

      ['pre-stay-cross-sell-24-hour']: 'Cross-sell 24h before entrance date',
      ['pre-stay-cross-sell-48-hour']: 'Cross-sell 48h before entrance date',
      ['pre-stay-cross-sell-hotel-requested']: 'Cross-sell request for Hotelier',
      ['pre-stay-cross-sell-hotel-accepted']: 'Cross-sell confirmation for Hotelier',
      ['pre-stay-cross-sell-hotel-rejected']: 'Cross-sell rejection for Hotelier',
      ['pre-stay-cross-sell-guest-accepted']: 'Cross-sell confirmation for Guest',
      ['pre-stay-cross-sell-guest-rejected']: 'Cross-sell rejection for Guest',
    },
    suscriptionDisabledTitle: 'Get a Guestlink license',
    suscriptionDisabled:
      'You can enable the pre-stay guest communication activating the Guestlink license. Click the button bellow and the Customer Success team will get in touch with you',
    suscriptionCTA: 'Contact me about Guestlink',
    suscriptionRequestedTitle: 'Functionality pending activation',
    suscriptionRequested:
      'You have already made the request to activate the functionality. Our Customer Success team has been informed of your request and will contact you shortly.',
    errorSetState: 'An error occurred while updating the template status',
    successSetState: 'The status of the template has been updated',
    sendFrom: 'Send from',
    ccList: 'CC List',
    bccList: 'BCC List',
    recipient: 'Recipient',
    language: 'Language',
    autofillLanguages: 'Autofill all languages',
    back: 'Back',
    errorSendFrom: 'Sender address is invalid',
    errorCCList:
      'Mailing list validation error in CC. Addresses must be valid and separated by commas.',
    errorBCCList:
      'Mailing list validation error in BCC. Addresses must be valid and separated by commas.',
    addAttachment: 'Add attachment',
    testButton: 'Send test mail',
    testMailDesc:
      'Enter the address where you want to send the test email (you can send more than one separating the emails by commas)',
    sendButton: 'Send',
    errorTestEmailValidation: 'The sender address is invalid',
    errorTestEmail: 'Error sending test mail',
    successTestEmail: 'Test mail has been sent',
    invalidSize: 'The file exceeds the maximum allowed file size of ',
    invalidFormat: 'File extension not allowed',
    saveSuccess: 'Template changes have been saved',
    requestGuestlinkSuccess:
      'Our Customer Success team has been informed of your request and will contact you shortly.',
    saveError: 'Data could not be saved',
    emailListHint: 'Insert emails separated by commas',
    sendFromHint: 'Mailing address',
    createSuccess: 'Template created successfully',
    createError: 'Error while creating the template',
    deleteGuestlinkItemTitle: 'Are you sure you want to delete the template?',
    deleteGuestlinkItemDescription: 'The template for the selected hotel will be deleted',
    deleteGuestlinkItemCTA: 'Delete template',
    nameError: 'You must enter a valid name for the template',
    scheduleError:
      'It is not possible to change to the requested template type without an active Guestlink license.',
    saveErrorSameTypeTitle: 'There is an active template of the same type',
    saveErrorSameTypeInfo:
      'It is not possible to change to the requested template type as there is currently an active template of the same type and schedule',
    scheduleEmptyError: 'You must select a schedule for the template',
  },
  tagInserter: {
    hint: 'Select a tag for the template',
    button: 'Insert',
    subjectHint: 'Select a tag for the subject',
    nameTagHint: 'Select a tag',
    documentAddon: '.pdf',
  },
  paymentList: {
    locatorCol: 'Payment locator',
    bookingLocator: 'Booking locator',
    purchaseState: 'Payment state',
    dateCol: 'Date',
    tokenCol: 'Payment token',
    amountCol: 'Amount',
  },
  paperlessPush: {
    title: 'Push Configuration',
    pmsId: 'PMS ID',
    ipWhitelist: "Allowed IP's",
    apiKey: 'API Key',
    pmsIdHint: 'Insert the hotel ID in the PMS',
    ipWhitelistHint: 'Insert IP addresses separated by commas',
    example: 'Link Example',
    errorWhitelist: 'Error validating IP addresses',
    disabledSuscription:
      "This hotel doesn't have paperless push enabled. You can activate it from the 'Account Details' screen in the 'Functionality' section.",
    activateSuscription: 'Activate Functionality',
    updateSuccess: 'Configuration updated successfully',
    updateError: 'Error updating paperless push configuration',
  },
  dynamicForms: {
    group: {
      document_data: 'Document data',
      contact_data: 'Personal data',
      other: 'Fields',
      uploadDocument: 'Aditional documents',
    },
    guestType: {
      holder_1: 'Domestic holder',
      holder_0: 'No Domestic Holder',
      adult_1: 'Domestic Adult',
      adult_0: 'No Domestic Adult',
      child_1: 'Domestic Children',
      child_0: 'No Domestic Children',
    },
    visible: 'Visible',
    required: 'Required',
    field: 'Field',
  },
  requestSuscription: {
    requestSent:
      'We have received the request successfully. We will get in touch with you as soon as possible.',
    send: 'Send request',
  },
  payment: {
    missingSuscription: {
      title: 'Get license',
      description:
        "You can set up Payment protection for the hotel by activating the 'Payment protection' license. Click the button below, and the Customer Success team will get in touch with you.",
    },
  },
  policeReport: {
    captionError: 'Error detected',
    captionOk: 'Delivered',
    captionPending: 'Pending to be processed',
    dateCol: 'Check-in date',
    statusCol: 'Status / Error',
    hotelCol: 'Property Name',
    contractCol: 'Contract number',
    guestNameCol: 'Guest name',
    guestSurnameCol: 'Guest surname',
    batchCol: 'Batch',
    actionCol: 'Action',
    contractData: 'Contract data',
    paymentData: 'Payment data',
    guestData: 'Guest data',
    addressData: 'Address data',
    sendData: 'Save and send',
    showSendWarningTitle: 'Send',
    showSendWarning: 'The data will be sent permanently. Do you want to continue?',
    mandatoryFieldsError: 'You must fill in all the form fields',
    emailError: 'The email address format is incorrect',
    download: 'Download SES file',
    downloadSuccess: 'The download of the files has been initiated',
    downloadError: 'Error downloading the files',
    back: 'Back',
    sftpConfig: 'SFTP Configuration',
    ftpHost: 'URL',
    ftpUser: 'User',
    ftpPass: 'Password',
    ftpPort: 'Port',
    fileFormat: 'File format',
    ftpDirPath: 'File directory',
    ftpSuccess: 'SFTP configuration has been saved successfully',
    ftpError: 'SFTP configuration could not be saved correctly',
    save: 'Save',
    sesConfig: 'SES Configuration',
    sesUser: 'User',
    sesPass: 'Password',
    lessorCode: 'Lessor code',
    establishmentCode: 'Establishment code',
    sesSuccess: 'SES configuration has been saved successfully',
    sesError: 'SES configuration could not be saved correctly',
    checkConnection: 'Check connection',
    checkConnectionSuccess: 'Connection to the SFTP server has been established successfully',
    checkConnectionError: 'Error connecting to the SFTP server',
    checkSESConnectionSuccess: 'Connection to SES has been established successfully',
    checkSESConnectionError: 'Error connecting to SES',
    forceDownloadTrigger: 'Force submission',
    forcedDownloadTrigger: 'Forced submission',
    forceDownloadTriggerInfo: 'The request to send guest data to SES has been triggered',
    forceDownloadTriggerError: 'Error sending the request',
    missingSuscription: {
      title: 'Get a police report license',
      description:
        "You can set up the police report for the hotel by activating the 'Police Report Module' license. Click the button below, and the Customer Success team will get in touch with you.",
      requestSent:
        'We have received the request successfully. We will get in touch with you as soon as possible.',
      send: 'Send request',
    },
    edit: {
      error: 'An error occurred while sending the data',
      success: 'The data has been sent successfully',
    },
    status: {
      ok: 'Delivered',
      error: 'Error',
      pending: 'Pending',
    },
    detailFields: {
      reference: 'Contract / reservation reference',
      date: 'Contract date',
      entrance: 'Check-in date',
      departure: 'Check-out date',
      guests: 'Number of guests',
      paymentType: 'Payment type',
      role: 'Role',
      name: 'Name',
      surname: 'First name',
      secondSurname: 'Second surname',
      contactType: 'Contact data type',
      contact: 'Contact',
      documentType: 'Document type',
      documentNumber: 'Document number',
      country: 'Country',
      address: 'Address',
      municipality: 'Municipality',
      municipalityName: 'Municipality name',
      zipCode: 'Zip code',
      documentSupport: 'Document support number',
      familyRelationship: 'Family relationship',
      birthDate: 'Birth date',
    },
    paymentType: {
      EFECT: 'Cash',
      TARJT: 'Credit card',
      TRANS: 'Transaction',
    },
    role: {
      TI: 'Contract holder',
      VI: 'Traveler',
    },
    contactType: {
      telefono: 'Mobile / Landline',
      email: 'Email',
    },
    documentType: {
      passport: 'PASSPORT',
      dni: 'DNI',
      idCard: 'ID CARD',
      residencePermit: 'RESIDENT PERMIT',
      drivingLicence: 'DRIVING LICENCE',
      other: 'OTHER',
    },
    sideItem: {
      send: 'Send',
      booking: {
        title: 'Reservation status',
        header: 'Check-ins',
        desc: 'The number of guests who have checked-in',
      },
      checkin: {
        title: 'Police Report',
        header: 'Sent to the Police',
        desc: 'Number of online check-ins sent to the police',
      },
      send: {
        header: 'Send data manually',
        desc: 'You can manually send this information to SES after editing the missing fields',
      },
    },
  },
  missingSuscription: {
    title: 'Get $1 license',
    description:
      "You can set up $1 for the hotel by activating the '$1 Module' license. Click the button below, and the Customer Success team will get in touch with you.",
    requestSent:
      'We have received the request successfully. We will get in touch with you as soon as possible.',
    send: 'Send request',
  },
  upsell,
  guestlink: {
    host: 'SMTP host',
    hostPlaceholder: 'Enter the SMTP server URL example.domain.com',
    port: 'Port',
    securityLayer: 'Encryption',
    smtpAuth: 'SMTP Authentication',
    user: 'User',
    userPlaceholder: 'SMTP user',
    password: 'Password',
    passwordPlaceholder: 'SMTP password',
    defaultSender: 'Default sender',
    defaultSenderPlaceholder: 'Enter your email example@email.com',
    enabled: 'Enabled',
    disabled: 'Disabled',
    save: 'Save',
    sendTestEmail: 'Send test mail',
    checkConnection: 'Check connection',
    smtpSuccess: 'SMTP configuration has been saved successfully',
    smtpError: 'SMTP configuration could not be saved correctly',
    checkConnectionSuccess: 'Connection to the SMTP server has been established successfully',
    checkConnectionError: 'Error connecting to the SMTP server',
    testEmailSuccess: 'Test email has been sent successfully',
    testEmailError: 'Error sending test email',
    requiredFieldsError: 'You must fill in all the form fields',
    smtpNotConfigured: 'Should save SMTP configuration',
    missingSuscription: {
      title: 'Get license',
      description:
        "You can set up Guestlink for the hotel by activating the 'Guestlink' license. Click the button below, and the Customer Success team will get in touch with you.",
    },
  },
  billingConfig: {
    billingInfo: {
      title: 'Billing information',
      companyLegalName: 'Company legal name',
      companyCommercialName: 'Company commercial name',
      companyCountryCode: 'Company country',
      billingAddress: 'Billing address',
      billingMunicipality: 'Billing municipality',
      billingProvince: 'Billing province',
      taxIdentificationNumber: 'Tax identification number',
      billingContactName: 'Billing contact name',
      billingContactEmail: 'Billing contact email',
      billingPhoneNumber: 'Billing phone number',
      companyLegalName_hint: 'Company legal name',
      companyCommercialName_hint: 'Company commercial name',
      companyCountryCode_hint: 'Company country',
      billingAddress_hint: 'Address, zip/postal code',
      billingMunicipality_hint: 'Municipality',
      billingProvince_hint: 'Province',
      taxIdentificationNumber_hint: 'Tax identification number',
      billingContactName_hint: 'Name Surname',
      billingContactEmail_hint: 'mail@email.com',
      billingPhoneNumber_hint: '+34 666888999',
      saveButton: 'Save',
      editButton: 'Edit',
      cancelButton: 'Cancel',
      saveMessageOK: 'Billing information has been changed successfully',
      invalidEmailError: 'The email address is not valid',
      formHasErrors: 'The form has empty or invalid fields',
    },
    billingStatus: {
      title: {
        OK: 'Billing information completed',
        KO: 'Complete billing information',
      },
      text: {
        OK: 'Thank you for providing the necessary billing information. Your details have been successfully added. Once a new invoice needs to be generated, your information will be included, and the invoice will be promptly sent to the designated contact.',
        KO: 'To ensure accuracy in our invoices and proper delivery to the designated contact, please provide the necessary billing information.',
      },
    },
    billingContract: {
      title: 'Contract management',
      active: 'Active',
      inactive: 'Inactive',
      uploadButton: 'Billing Upload',
      modal: {
        confirm: 'Confirm',
        cancel: 'Cancel',
        title: 'Select category',
        subtitle: 'Select the category you want to create',
        title_contract: 'Contract',
        title_invoice: 'Invoice',
        title_license: 'Licence',
        option: {
          contract: 'Contract',
          license: 'Licence',
          invoice: 'Invoice',
        },
        contract: {
          save: 'Save',
          delete: 'Delete',
        },
      },
      table: {
        id: 'Contract ID',
        name: 'Contract name',
        signatureDate: 'Date of signature',
        status: 'Status',
        actions: 'Actions',
        empty_contract: 'You do not have any contract',
        contact_support: 'Please, contact our support team',
      },
      uploadContract: 'Upload contract',
      contractName: 'Contract name',
      contractName_hint: 'Enter the name of the contract',
      signatureDate: 'Signature date',
      signatureDate_hint: 'dd/mm/YYYY',
      orderNumber: 'Order number',
      orderNumber_hint: 'ORD-123456Z',
      fileUpload: {
        title: 'Select a file',
        hint: 'JPG, PNG or PDF, file size no more than 10MB',
        invalid: 'The file is not valid',
        OK: 'File uploaded successfully',
        KO: 'Upload failed, please try again',
      },
      saveContractMessageOK: 'The contract has been successfully created',
      formHasErrors: 'The form contains empty or incorrect fields',
      deleteHotelContractMessageOK: 'The contract has been successfully deleted',
      editContractMessageOK: 'The contract has been successfully updated',
    },
    licence: {
      title: 'Licence management',
      inactive: 'Inactive',
      active: 'Active',
      deleteHotelLicenceMessageOK: 'The licence has been successfully deleted',
      editLicenceMessageOK: 'The licence has been successfully updated',
      saveLicenceMessageOK: 'The licence has been successfully created',
      table: {
        id: 'Licence ID',
        name: 'Licence',
        contractId: 'Contract ID',
        hotelName: 'Hotel name',
        type: 'Type',
        type_annual: 'Annual',
        type_monthly: 'Monthly',
        type_bimonthly: 'Bimonthly',
        type_quarterly: 'Quarterly',
        type_four_monthly: 'Four monthly',
        type_biannual: 'Biannual',
        type_two_yearly: 'Two yearly',
        type_oneshot: 'One Shot',
        startDate: 'Start date',
        renewalDate: 'Renewal date',
        endDate: 'End date',
        status: 'Status',
        price: 'Price',
        actions: 'Actions',
        empty_licence: 'You do not have any licence',
        contact_support: 'Please, contact our support team',
      },
      modal: {
        title: 'Licence',
        delete: 'Delete',
        save: 'Save',
        cancel: 'Cancel',
      },
      types: {
        setup: 'Set Up',
        integration_cost: 'Integration Cost',
        checkin: 'Check-in',
        upselling: 'Upselling',
        crossselling: 'Cross-Selling',
        payment_protection: 'Payment Protection',
        door_opening: 'Door Opening',
        guestlink: 'Guestlink',
        guest_registration: 'Guest Registration',
        checkout: 'Check-out',
        push: 'Push Paperless',
        paperless: 'T-Paperless',
        ftp: 'FTP',
        multitablet_option: 'Multitablet',
        grs: 'Group Reservation System',
        booking_engine: 'Booking Engine',
      },
      form: {
        licence: 'Licence',
        licence_hint: 'Select the licence to create',
        contract: 'Contract',
        contract_hint: 'Contract associated',
        start_date: 'Start date',
        start_date_hint: 'dd-mm-yyyy',
        price: 'Price',
        price_hint: 'Add price',
        periodity: 'Periodity',
        periodity_hint: 'Select periodity',
        currency_hint: '-',
        currency_eur: '€',
        currency_usd: '$',
        invalidLicenceError: 'Empty field or licence repeated in another contract',
      },
    },
    invoice: {
      title: 'Invoice Management',
      pending: 'Pending',
      paid: 'Paid',
      overdue: 'Overdue',
      saveInvoiceMessageOK: 'The invoice has been successfully created',
      formHasErrors: 'The form contains empty or incorrect fields',
      deleteHotelInvoiceMessageOK: 'The invoice has been successfully deleted',
      editInvoiceMessageOK: 'The invoice has been successfully updated',
      form: {
        uploadInvoice: 'Upload invoice',
        invoiceId: 'Invoice ID',
        invoiceId_hint: 'Enter the invoice ID',
        invoiceInternalId: 'Holded ID',
        invoiceInternalId_hint: 'Enter the holded invoice ID',
        date: 'Invoice date',
        date_hint: 'dd-mm-yyyy',
        contractId: 'Contract',
        contractId_hint: 'Contract associated',
        amount: 'Amount',
        amount_hint: 'Add amount',
        currency_hint: '-',
        currency_eur: '€',
        currency_usd: '$',
        status: 'Invoice status',
        status_hint: 'Actual invoice status',
        invalidInvoiceIdError: 'The field is empty or another invoice exists with the same ID',
        invalidInvoiceInternalIdError:
          'The field is empty or another invoice exists with the same ID',
      },
      table: {
        id: 'Invoice ID',
        date: 'Invoice date',
        contractId: 'Contract ID',
        hotelName: 'Hotel name',
        status: 'Status',
        amount: 'Amount',
        actions: 'Actions',
        empty_invoice: 'You do not have any invoices yet',
        invoice_status_pending: 'Pending',
        invoice_status_paid: 'Paid',
        invoice_status_overdue: 'Overdue',
      },
      modal: {
        title: 'Invoice',
        cancel: 'Cancel',
        save: 'Save',
        delete: 'Delete',
      },
      fileUpload: {
        title: 'Select a file',
        hint: 'JPG, PNG or PDF, file size no more than 10MB',
        invalid: 'The file is not valid',
        OK: 'File uploaded successfully',
        KO: 'Upload failed, please try again',
      },
    },
    countryCodes: {
      CODE_AF: 'Afghanistan',
      CODE_AX: 'Åland Islands',
      CODE_AL: 'Albania',
      CODE_DZ: 'Algeria',
      CODE_AS: 'American Samoa',
      CODE_AD: 'Andorra',
      CODE_AO: 'Angola',
      CODE_AI: 'Anguilla',
      CODE_AQ: 'Antarctica',
      CODE_AG: 'Antigua and Barbuda',
      CODE_AR: 'Argentina',
      CODE_AM: 'Armenia',
      CODE_AW: 'Aruba',
      CODE_AU: 'Australia',
      CODE_AT: 'Austria',
      CODE_AZ: 'Azerbaijan',
      CODE_BS: 'Bahamas',
      CODE_BH: 'Bahrain',
      CODE_BD: 'Bangladesh',
      CODE_BB: 'Barbados',
      CODE_BY: 'Belarus',
      CODE_BE: 'Belgium',
      CODE_BZ: 'Belize',
      CODE_BJ: 'Benin',
      CODE_BM: 'Bermuda',
      CODE_BT: 'Bhutan',
      CODE_BO: 'Bolivia, Plurinational State of',
      CODE_BQ: 'Bonaire, Sint Eustatius and Saba',
      CODE_BA: 'Bosnia and Herzegovina',
      CODE_BW: 'Botswana',
      CODE_BV: 'Bouvet Island',
      CODE_BR: 'Brazil',
      CODE_IO: 'British Indian Ocean Territory',
      CODE_BN: 'Brunei Darussalam',
      CODE_BG: 'Bulgaria',
      CODE_BF: 'Burkina Faso',
      CODE_BI: 'Burundi',
      CODE_KH: 'Cambodia',
      CODE_CM: 'Cameroon',
      CODE_CA: 'Canada',
      CODE_CV: 'Cape Verde',
      CODE_KY: 'Cayman Islands',
      CODE_CF: 'Central African Republic',
      CODE_TD: 'Chad',
      CODE_CL: 'Chile',
      CODE_CN: 'China',
      CODE_CX: 'Christmas Island',
      CODE_CC: 'Cocos (Keeling) Islands',
      CODE_CO: 'Colombia',
      CODE_KM: 'Comoros',
      CODE_CG: 'Congo',
      CODE_CD: 'Congo, the Democratic Republic of the',
      CODE_CK: 'Cook Islands',
      CODE_CR: 'Costa Rica',
      CODE_CI: 'Côte dIvoire',
      CODE_HR: 'Croatia',
      CODE_CU: 'Cuba',
      CODE_CW: 'Curaçao',
      CODE_CY: 'Cyprus',
      CODE_CZ: 'Czech Republic',
      CODE_DK: 'Denmark',
      CODE_DJ: 'Djibouti',
      CODE_DM: 'Dominica',
      CODE_DO: 'Dominican Republic',
      CODE_EC: 'Ecuador',
      CODE_EG: 'Egypt',
      CODE_SV: 'El Salvador',
      CODE_GQ: 'Equatorial Guinea',
      CODE_ER: 'Eritrea',
      CODE_EE: 'Estonia',
      CODE_ET: 'Ethiopia',
      CODE_FK: 'Falkland Islands (Malvinas)',
      CODE_FO: 'Faroe Islands',
      CODE_FJ: 'Fiji',
      CODE_FI: 'Finland',
      CODE_FR: 'France',
      CODE_GF: 'French Guiana',
      CODE_PF: 'French Polynesia',
      CODE_TF: 'French Southern Territories',
      CODE_GA: 'Gabon',
      CODE_GM: 'Gambia',
      CODE_GE: 'Georgia',
      CODE_DE: 'Germany',
      CODE_GH: 'Ghana',
      CODE_GI: 'Gibraltar',
      CODE_GR: 'Greece',
      CODE_GL: 'Greenland',
      CODE_GD: 'Grenada',
      CODE_GP: 'Guadeloupe',
      CODE_GU: 'Guam',
      CODE_GT: 'Guatemala',
      CODE_GG: 'Guernsey',
      CODE_GN: 'Guinea',
      CODE_GW: 'Guinea-Bissau',
      CODE_GY: 'Guyana',
      CODE_HT: 'Haiti',
      CODE_HM: 'Heard Island and McDonald Islands',
      CODE_VA: 'Holy See (Vatican City State)',
      CODE_HN: 'Honduras',
      CODE_HK: 'Hong Kong',
      CODE_HU: 'Hungary',
      CODE_IS: 'Iceland',
      CODE_IN: 'India',
      CODE_ID: 'Indonesia',
      CODE_IR: 'Iran, Islamic Republic of',
      CODE_IQ: 'Iraq',
      CODE_IE: 'Ireland',
      CODE_IM: 'Isle of Man',
      CODE_IL: 'Israel',
      CODE_IT: 'Italy',
      CODE_JM: 'Jamaica',
      CODE_JP: 'Japan',
      CODE_JE: 'Jersey',
      CODE_JO: 'Jordan',
      CODE_KZ: 'Kazakhstan',
      CODE_KE: 'Kenya',
      CODE_KI: 'Kiribati',
      CODE_KP: 'Korea, Democratic Peoples Republic of',
      CODE_KR: 'Korea, Republic of',
      CODE_KW: 'Kuwait',
      CODE_KG: 'Kyrgyzstan',
      CODE_LA: 'Lao Peoples Democratic Republic',
      CODE_LV: 'Latvia',
      CODE_LB: 'Lebanon',
      CODE_LS: 'Lesotho',
      CODE_LR: 'Liberia',
      CODE_LY: 'Libya',
      CODE_LI: 'Liechtenstein',
      CODE_LT: 'Lithuania',
      CODE_LU: 'Luxembourg',
      CODE_MO: 'Macao',
      CODE_MK: 'North Macedonia',
      CODE_MG: 'Madagascar',
      CODE_MW: 'Malawi',
      CODE_MY: 'Malaysia',
      CODE_MV: 'Maldives',
      CODE_ML: 'Mali',
      CODE_MT: 'Malta',
      CODE_MH: 'Marshall Islands',
      CODE_MQ: 'Martinique',
      CODE_MR: 'Mauritania',
      CODE_MU: 'Mauritius',
      CODE_YT: 'Mayotte',
      CODE_MX: 'Mexico',
      CODE_FM: 'Micronesia, Federated States of',
      CODE_MD: 'Moldova, Republic of',
      CODE_MC: 'Monaco',
      CODE_MN: 'Mongolia',
      CODE_ME: 'Montenegro',
      CODE_MS: 'Montserrat',
      CODE_MA: 'Morocco',
      CODE_MZ: 'Mozambique',
      CODE_MM: 'Myanmar',
      CODE_NA: 'Namibia',
      CODE_NR: 'Nauru',
      CODE_NP: 'Nepal',
      CODE_NL: 'Netherlands',
      CODE_NC: 'New Caledonia',
      CODE_NZ: 'New Zealand',
      CODE_NI: 'Nicaragua',
      CODE_NE: 'Niger',
      CODE_NG: 'Nigeria',
      CODE_NU: 'Niue',
      CODE_NF: 'Norfolk Island',
      CODE_MP: 'Northern Mariana Islands',
      CODE_NO: 'Norway',
      CODE_OM: 'Oman',
      CODE_PK: 'Pakistan',
      CODE_PW: 'Palau',
      CODE_PS: 'Palestine',
      CODE_PA: 'Panama',
      CODE_PG: 'Papua New Guinea',
      CODE_PY: 'Paraguay',
      CODE_PE: 'Peru',
      CODE_PH: 'Philippines',
      CODE_PN: 'Pitcairn',
      CODE_PL: 'Poland',
      CODE_PT: 'Portugal',
      CODE_PR: 'Puerto Rico',
      CODE_QA: 'Qatar',
      CODE_RE: 'Réunion',
      CODE_RO: 'Romania',
      CODE_RU: 'Russian Federation',
      CODE_RW: 'Rwanda',
      CODE_BL: 'Saint Barthélemy',
      CODE_SH: 'Saint Helena, Ascension and Tristan da Cunha',
      CODE_KN: 'Saint Kitts and Nevis',
      CODE_LC: 'Saint Lucia',
      CODE_MF: 'Saint Martin (French part)',
      CODE_PM: 'Saint Pierre and Miquelon',
      CODE_VC: 'Saint Vincent and the Grenadines',
      CODE_WS: 'Samoa',
      CODE_SM: 'San Marino',
      CODE_ST: 'Sao Tome and Principe',
      CODE_SA: 'Saudi Arabia',
      CODE_SN: 'Senegal',
      CODE_RS: 'Serbia',
      CODE_SC: 'Seychelles',
      CODE_SL: 'Sierra Leone',
      CODE_SG: 'Singapore',
      CODE_SX: 'Sint Maarten (Dutch part)',
      CODE_SK: 'Slovakia',
      CODE_SI: 'Slovenia',
      CODE_SB: 'Solomon Islands',
      CODE_SO: 'Somalia',
      CODE_ZA: 'South Africa',
      CODE_GS: 'South Georgia and the South Sandwich Islands',
      CODE_SS: 'South Sudan',
      CODE_ES: 'Spain',
      CODE_ES_CANARY: 'Spain - Canary Islands',
      CODE_LK: 'Sri Lanka',
      CODE_SD: 'Sudan',
      CODE_SR: 'Suriname',
      CODE_SJ: 'Svalbard and Jan Mayen',
      CODE_SZ: 'Swaziland',
      CODE_SE: 'Sweden',
      CODE_CH: 'Switzerland',
      CODE_SY: 'Syrian Arab Republic',
      CODE_TW: 'Taiwan',
      CODE_TJ: 'Tajikistan',
      CODE_TZ: 'Tanzania, United Republic of',
      CODE_TH: 'Thailand',
      CODE_TL: 'Timor-Leste',
      CODE_TG: 'Togo',
      CODE_TK: 'Tokelau',
      CODE_TO: 'Tonga',
      CODE_TT: 'Trinidad and Tobago',
      CODE_TN: 'Tunisia',
      CODE_TR: 'Turkey',
      CODE_TM: 'Turkmenistan',
      CODE_TC: 'Turks and Caicos Islands',
      CODE_TV: 'Tuvalu',
      CODE_UG: 'Uganda',
      CODE_UA: 'Ukraine',
      CODE_AE: 'United Arab Emirates',
      CODE_GB: 'United Kingdom',
      CODE_US: 'United States',
      CODE_UM: 'United States Minor Outlying Islands',
      CODE_UY: 'Uruguay',
      CODE_UZ: 'Uzbekistan',
      CODE_VU: 'Vanuatu',
      CODE_VE: 'Venezuela, Bolivarian Republic of',
      CODE_VN: 'Viet Nam',
      CODE_VG: 'Virgin Islands, British',
      CODE_VI: 'Virgin Islands, U.S.',
      CODE_WF: 'Wallis and Futuna',
      CODE_EH: 'Western Sahara',
      CODE_YE: 'Yemen',
      CODE_ZM: 'Zambia',
      CODE_ZW: 'Zimbabwe',
      CODE_XI: 'Northern Ireland',
    },
  },
  crossSell,
  onboarding: {
    pms: {
      title: 'Connect with your PMS',
      subtitle: 'Select your PMS, configure it, and test your check-in flow in minutes!',
      pmsConfiguration: 'PMS configuration',
      checkinForm: 'Check-in form',
      bookingSearch: 'Booking search',
      selectConfPms: 'Select and configure your PMS',
      selectConfPmsDescription:
        'Choose your PMS to connect with form the list below and configure it.',
      continue: 'continue',
    },
  },
  validation: {
    guest: {
      description:
        'Verify that guest data is stored correctly in the PMS and can be retrieved for the Paperless module.',
      bookingIdentifier: 'Booking number',
      activateCustomMock: 'Customize guest data',
      button: 'Test',
      response: {
        variableName: 'Variable',
        civitfunData: 'Civitfun',
        pmsData: 'PMS',
      },
    },
    payment: {
      paymentTitle: 'Payment validation',
      description: 'Check the connection with the payment gateway:',
      bookingIdentifier: 'Booking number:',
      testPayment: 'Check connection',
      url: 'URL to access the payment gateway:',
    },
  },
  formFieldsInference: {
    button: 'Autocomplete field selection',
    description:
      'We will check your PMS for the fields you collect during guest check-in and automatically recommend them by selecting them in the form. This action will clear the current field selection.',
  },
  selector: {
    tabs: {
      onboardingTitle: 'Check-in Validation',
      onboardingDescription:
        'Validate the entire check-in process, ensuring the connection with the PMS, accurate reservation search, and data transfer between the PMS and Civitfun.',
      validationConection: 'Connection',
      validationSearches: 'Reservation Search',
      validationSendData: 'Data Transfer to PMS',
      validationPayments: 'Payments',
      validationTestAll: 'Complete Check-in Flow',
      description:
        'Validate the entire check-in process, ensuring the connection with the PMS, accurate reservation search, and data transfer between the PMS and Civitfun.',
      results: 'Searches results',
      warning: 'Incomplete fields',
      warningDescription: 'Please enter the missing value in Booking locator and Date of entry.',
    },
  },
  searchesResults: {
    searchByCodeResult: 'Search by Code',
    searchByEntranceDateResult: 'Search by Entrance date',
    searchByHolderNameResult: 'Search by Holder Name',
    searchByIdResult: 'Search by Id',
  },
  pmsUdf: {
    title: 'UDF Configuration',
    form: {
      addUdf: 'Add UDF',
      editUdf: 'Edit UDF',
      name: 'UDF Name',
      field: 'Field in Civitfun',
      add: 'Add',
      edit: 'Save Changes',
      cancel: 'Cancel',
    },
    table: {
      title: 'UDFs',
      id: 'ID',
      name: 'Name',
      referenceField: 'Reference Field',
      edit: 'Edit',
    },
    deleteWarning: {
      title: 'Do you want to delete the UDF?',
      description: 'This action cannot be undone. All data for the selected UDF will be lost',
      remove: 'Delete UDF',
      cancel: 'Cancel',
    },
    removeError: 'Error deleting UDF',
    removeSuccess: 'The UDF has been deleted',
    updateSuccess: 'The UDF has been updated',
    updateError: 'Error updating UDF',
    createSuccess: 'The UDF has been created',
    createError: 'Error creating UDF',
  },
};
