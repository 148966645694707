import React, { useContext } from 'react';
import { AppContext } from 'App.context';
import { Upload, Alert } from 'antd';
import styles from './UploadBox.styles';
import { ReactComponent as UploadIcon } from 'Sell/assets/icon/upload.svg';
import './UploadBox.css';

const { Dragger } = Upload;

export default function UploadBox({ handleFileChange, fileList, maxCount }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <div className="upload-box-container">
      <Dragger
        disabled={fileList.length >= maxCount}
        multiple
        maxCount={maxCount}
        accept="image/*"
        beforeUpload={() => false}
        onChange={handleFileChange}
        fileList={fileList}
        style={styles.container}
      >
        <div style={styles.textContainer}>
          <UploadIcon style={styles.icon} />
          <p style={styles.text}>{translate('upsell.dragAndDrop')}</p>
          <p style={styles.subText}>{translate('upsell.maxFileSize')}</p>
          <p style={styles.subText}>{translate('upsell.maxFileNumber')}</p>
        </div>
      </Dragger>
      <Alert message={translate('upsell.maxFileUploadReached')} type="info" />
    </div>
  );
}
