export default `<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:21px;font-family:&quot;Calibri&quot;,sans-serif;">条款和条件&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:18px;font-family:&quot;Calibri&quot;,sans-serif;">法律信息&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;">
    <span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">
        为了您的安全，我们保证您的信用卡数据将根据国际PCI-DSS标准处理。我授权酒店将我在酒店的总费用收取到上述信用卡上。如果我在本文件中显示的预订结束日期之前离开酒店，我授权将合同约定的所有夜晚的总金额（包括未使用的夜晚）收取到信用卡上。<br><br>
        在 [hotel_name]，我们致力于保护您的隐私。您可以通过 [address] 或电子邮件 [hotel_email] 联系我们。[hotel_name] 将处理您的数据，以管理您在酒店的住宿，并改善您作为我们的客人的体验。同时，[hotel_name] 还将处理您的数据，以提供住宿和附加服务。我们可能需要处理您的数据，以遵守我们所受的法律义务（包括防止欺诈）。[hotel_name] 可能会将您的数据传达给第三方，如银行或其他金融机构，以处理付款，或向执法机构报告以遵守法律规定的义务。<br><br>
        如果您注册， [hotel_name] 将处理您的个人数据，以为您提供相应的忠诚度服务。在这方面，我们将处理生成的积分，并可能根据您的个人资料向您发送有关积分和计划福利的信息。<br><br>
        您可以通过写信至 [hotel_name] 来行使您的访问、修改、删除、反对和/或限制处理的权利，以及数据的可携带性，邮寄或电子邮件地址如上所述。您必须证明您的身份，并在这两种情况下都指明 (a) “数据保护”为主题，(b) 您的请求是否针对 [hotel_name]。此外，您可以随时联系国家主管监管机构。<br><br>
        有关更多信息，请访问 [hotel_web] 或咨询酒店前台。
    </span>
</p>`;
