export default {
  name: 'Name',
  surname: 'Last name',
  second_surname: 'Second last name',
  document_number: 'Document number',
  sex: 'Gender (M/F)',
  nationality: 'Country of the document',
  birth_date: 'Date of birth',
  expedition_date: 'Date of issue',
  expiration_date: 'Valid until',
  document_type: 'Document type',
  male: 'Male',
  female: 'Female',
  email: 'E-mail',
  'data-privacy-policy-label': 'I have read and accept the data privacy policy.',
  'data-commercial-use-label': 'I want to receive commercial information.',
  'loyalty-program-label': 'I want to subscribe loyalty program.',
};
