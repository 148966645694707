import Colors from '../../../../../../constants/Colors';

export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignContent: 'flex-end',
  },

  line: {
    flex: 1,
    borderWidth: 0.2,
    borderStyle: 'dashed',
    borderColor: Colors.lightGray,
    alignSelf: 'center',
    marginLeft: 8,
    marginRight: 4,
  },
};
