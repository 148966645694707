import axios from 'axios';
import apiConfig from '../api.config';

export default function updateHotelStatusId(data) {
  const {
    host,
    URIs: { updateHotelStatusId },
  } = apiConfig;

  const uri = `${host}${updateHotelStatusId.replace(':hotelId', data?.hotelId)}`;

  return axios
    .post(uri, JSON.stringify({ statusId: data?.hotelStatusId }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
