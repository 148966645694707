import React, { useContext } from 'react';
import { Modal } from 'antd';
import styles from './BillingModal.styles';
import BillingSelectForm from '../BillingSelectForm/BillingSelectForm';
import BillingContractForm from '../BillingContractForm/BillingContractForm';
import BillingLicenceForm from '../BillingLicenceForm/BillingLicenceForm';
import BillingInvoiceForm from '../BillingInvoiceForm/BillingInvoiceForm';
import { BillingListingContext } from '../BillingListing.context';

export default function BillingModal() {
    const { state } = useContext(BillingListingContext);
    
    return (
        <div style={styles.modalRounded}>
            <Modal
                centered
                closable={false}
                title={''}
                footer={null}
                open={state.isModalOpen}
                width={560}
                bodyStyle={{minHeight: 400}}
            >
                { state.modalStatus == 'main' &&
                    <BillingSelectForm />
                }

                { state.modalStatus == 'contract' && 
                    <BillingContractForm />
                }

                { state.modalStatus == 'licence' && 
                    <BillingLicenceForm />
                }

                { state.modalStatus == 'invoice' && 
                    <BillingInvoiceForm />
                }
            </Modal>
        </div>
    );
}