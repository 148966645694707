import Colors from 'constants/Colors';

export default {
  container: {
    border: '1px solid red',
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    color: Colors.titleColor,
    marginTop: 8,
  },
  subtitle: {
    fontSize: 10,
    color: Colors.titleColor,
  },
};
