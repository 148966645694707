import Colors from 'constants/Colors';

const STATE_INDICATOR_SIZE = 18;

export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
  caption: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    fontSize: 14,
    color: Colors.darkText,
    alignItems: 'center',
  },
};
