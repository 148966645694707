import constants from 'utils/constants';
import storage from 'utils/storage';

function buildAuthMediaUrl(url) {
  var auth;
  const storedCredentials = storage.get(constants.AUTH.USER_KEY);
  if (storedCredentials) {
    const { accessToken } = JSON.parse(storedCredentials);
    auth = `Bearer ${accessToken}`;
  }

  return `${url}&Authorization=${auth}`;
}

export { buildAuthMediaUrl };
