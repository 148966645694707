import { EyeOutlined } from '@ant-design/icons';
import { Button, DatePicker, Modal } from 'antd';
import React, { useContext } from 'react';
import BaseTable from '../../common/BaseTable/BaseTable';
import DefaultLayout from '../../DefaultLayout/DefaultLayout';
import useCheckOutListHook from './CheckOutList.hook';
import styles from './CheckOutList.styles';
import IHClientTag from '../../common/IHClientTag/IHClientTag';
import { AppContext } from '../../../App.context';
import { getFormattedDate } from 'utils/DateUtils';
import CheckInDetail from '../CheckInList/CheckInDetail/CheckInDetail';
import RequestSuscription from 'Components/common/RequestSuscription/RequestSuscription.jsx';

const { RangePicker } = DatePicker;


export default function CheckOutList() {
  const { state, actions } = useCheckOutListHook();
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const columns = [
    {
      title: translate('checkOutModule.locatorCol'),
      dataIndex: ['booking', 'code'],
      key: 'booking.code',
      searchable: true,
      sorter: false,
      render: (_, row) => {
        return <div style={{ fontWeight: 500 }}>{row.booking.code}</div>;
      },
    },
    {
      title: translate('checkOutModule.nameCol'),
      dataIndex: ['guest', 'name'],
      key: 'guest.name',
      searchable: false,
      sorter: false,
    },
    {
      title: translate('checkOutModule.surnameCol'),
      dataIndex: ['guest', 'surname'],
      key: 'guest.surnname',
      searchable: false,
      sorter: false,
    },
    {
      title: 'Hotel',
      dataIndex: 'hotel',
      key: 'hotel',
      searchable: false,
      sorter: false,
    },
    {
      title: translate('checkOutModule.date'),
      dataIndex: ['updated_at'],
      key: 'updated_at',
      searchable: false,
      sorter: true,
      render: text => {
        return <div>{getFormattedDate(text)}</div>;
      },
    },
    {
      title: translate('checkOutModule.entranceDate'),
      dataIndex: ['booking', 'entrance'],
      key: 'booking.entrance',
      searchable: false,
      sorter: true,
    },
    {
      title: translate('checkOutModule.departureDate'),
      dataIndex: ['booking', 'departure'],
      key: 'booking.departure',
      searchable: false,
      sorter: true,
    },
    {
      title: translate('checkOutModule.channelCol'),
      dataIndex: 'clientId',
      key: 'clientId',
      align: 'center',
      width: '5%',
      render: text => {
        return <IHClientTag ihClientId={text} />;
      },
    },
    {
      title: translate('checkOutModule.actions'),
      dataIndex: null,
      key: null,
      align: 'center',
      width: '5%',
      render: (text, object) => {
        return <Button type="secondary" icon={<EyeOutlined />} />;
      },
    },
  ];

  return (
    <DefaultLayout singleBox title={translate('checkOutModule.title')} enableAllHotels={true}>
      { state.subscription ? (
        <>
          <div style={styles.mainContainer}>
            <div style={styles.header}>
              <div style={styles.buttonsContainer}>
                <RangePicker
                  defaultValue={state.datesRange}
                  onChange={actions.onDateChange}
                  disabled={state.loading}
                />
              </div>
            </div>
          </div>

          <div style={styles.tableContainer}>
            <div style={styles.table}>
              <BaseTable
                data={state.dataSource}
                columns={columns}
                loading={state.loading}
                onChange={filters => actions.onChange(filters)}
                onRowClicked={actions.onRowClicked}
              />
            </div>
          </div>
          <Modal
            open={state.checkinId !== null}
            centered
            width={'80%'}
            onCancel={actions.resetGuestDetail}
            footer={null}
            style={{ marginTop: '24px' }}
            destroyOnClose={true}
          >
            <CheckInDetail checkinId={state.checkinId} />
          </Modal>
        </>
      ) : (
        <RequestSuscription suscription='checkout' />
      )}
    </DefaultLayout>
  );
}
