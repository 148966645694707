import axios from 'axios';
import apiConfig from '../api.config';

export default function updatePoliceSES({
  hotelId,
  sesCredentials,
  lessorCode,
  establishmentCode,
}) {
  const {
    host,
    URIs: { policeReport, policeReportSES },
  } = apiConfig;

  const uri = `${host}${policeReport}/${policeReportSES.replace(':hotelId', hotelId)}`;

  return axios
    .put(uri, JSON.stringify(sesCredentials, lessorCode, establishmentCode), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
