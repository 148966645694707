import React, { useContext } from 'react';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout.jsx';
import { AppContext } from 'App.context.js';
import BillingInfo from './BillingInfo/BillingInfo.jsx';
import BillingListing from './BillingListing/BillingListing.jsx';
import styles from './BillingListing/BillingContract/BillingContract.styles';

export default function BillingConfig() {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <DefaultLayout style={styles.defaultLayout} title={translate('layout.billingConfig')} enableAllHotels={false}>
      <BillingInfo />
      <BillingListing />
    </DefaultLayout>
  );
}
