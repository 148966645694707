import axios from 'axios';
import apiConfig from 'utils/networking/api.config';

/**
 * @param {*} action
 * undefined == test all { bookingIdentifier, mockGuests }
 * guestData { bookingIdentifier, mockGuests, supportedFields }
 * guestForm { idCheckinGuestList, mockGuests, supportedFields }
 * @returns
 */
export default async function papperlessRequest({
  hotelId = null,
  action = null,
  authorization = null,
  bookingIdentifier = null,
  mockGuests = null,
  supportedFields = null,
  idCheckinGuestList = null,
}) {
  const {
    hostValidation,
    URIs: {
      validation: { papperless },
    },
  } = apiConfig;

  const uri = `${hostValidation}${papperless}`
    .replace(':hotelId', hotelId)
    .replace(':action', action || '');

  return axios
    .post(
      uri,
      { bookingIdentifier, mockGuests, supportedFields, idCheckinGuestList },
      {
        headers: { Authorization: authorization },
      }
    )
    .then(res => {
      return res.data;
    });
}
