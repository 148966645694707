import React, { useContext } from 'react';
import { Table } from 'antd';
import BaseTable from 'Components/common/BaseTable/BaseTable';
import useRoomTypeListHook from './RoomTypeList.hook';
import styles from './RoomTypeList.styles';
import { AppContext } from 'App.context';
import CustomButton from 'Components/common/CustomButton/CustomButton';
import Colors from 'constants/Colors';
import { EyeOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import TableActions from 'Sell/Components/TableActions';
import EmptyTable from './EmptyTableComponent/EmptyTable';

export default function RoomTypeTable() {
  const { dataSource, isLoading, actions } = useRoomTypeListHook();
  const {
    appActions: { translate, getSelectedLanguage },
  } = useContext(AppContext);
  const { id: langId } = getSelectedLanguage();

  const STATUS_MAPPING = {
    pending: [
      {
        label: <span style={styles.activate}>{translate('upsell.activate')}</span>,
        key: 'status_active',
        icon: <CheckOutlined style={styles.activate} />,
      },
      {
        label: <span style={styles.disable}>{translate('upsell.disable')}</span>,
        key: 'status_disabled',
        icon: <CloseOutlined style={styles.disable} />,
      },
    ],
  };

  const columns = [
    {
      title: translate('upsell.pmsIdentifier'),
      dataIndex: ['code'],
      key: 'code',
      searchable: true,
      sorter: false,
      render: code => <div style={styles.dealId}>{code}</div>,
    },
    {
      title: translate('upsell.status'),
      dataIndex: ['status'],
      key: 'status',
      searchable: false,
      sorter: false,
      render: status => <div style={styles.status(status)}>{translate(`upsell.${status}`)}</div>,
    },
    {
      title: translate('upsell.picture'),
      dataIndex: ['physicalRoomGalleries'],
      key: 'guest.surnname',
      searchable: false,
      sorter: false,
      render: images => {
        const imagePath = images?.[0]?.path || null;
        return imagePath ? <img src={imagePath} style={styles.image} /> : null;
      },
    },
    {
      title: translate('upsell.roomType'),
      dataIndex: ['texts'],
      key: 'texts',
      searchable: true,
      sorter: false,
      render: texts => texts?.find(text => text.lang === langId && text.type === 'name')?.value,
    },
    {
      title: translate('upsell.hotelName'),
      dataIndex: ['hotel', 'name'],
      key: 'hotel.name',
      searchable: false,
      sorter: false,
    },
    Table.SELECTION_COLUMN,
    {
      title: ({ selectedKeys, confirm }) => (
        <TableActions
          title="Bulk Actions" // texto para añadir a los ficheros de traducciones
          items={STATUS_MAPPING['pending']}
          onItemClick={actions.handleBulkActionClick}
        />
      ),
      dataIndex: 'icons',
      key: 'icons',
      searchable: false,
      sorter: false,
      render: (_, row) => {
        const items = [
          {
            label: <span style={styles.seeDetails}>{translate('upsell.seeDetails')}</span>,
            key: 'detail',
            icon: <EyeOutlined style={styles.seeDetails} />,
          },
          {
            label:
              row.status === 'active' ? (
                <span style={styles.disable}>{translate('upsell.disable')}</span>
              ) : (
                <span style={styles.activate}>{translate('upsell.activate')}</span>
              ),
            key: 'status',
            icon:
              row.status === 'active' ? (
                <CloseOutlined style={styles.disable} />
              ) : (
                <CheckOutlined style={styles.activate} />
              ),
          },
          {
            label: <span style={styles.delete}>{translate('upsell.delete')}</span>,
            key: 'delete',
            icon: <DeleteOutlined style={styles.delete} />,
          },
        ];

        if (row.status !== 'removed') {
          return (
            <TableActions
              title={translate('salesChannels.actionCol')}
              items={items}
              onItemClick={actions.handleActionClick(row)}
            />
          );
        }
      },
    },
  ];

  return (
    <>
      <div style={styles.mainContainer}>
        <div style={styles.header}>
          <div style={styles.buttonsContainer}>
            <CustomButton
              text={translate('upsell.createRoomType')}
              background={Colors.exportButton}
              color={'white'}
              onClick={actions.onCreateClicked}
            />
          </div>
        </div>
      </div>

      <div style={styles.tableContainer}>
        <div style={styles.table}>
          <BaseTable
            data={dataSource}
            columns={columns}
            loading={isLoading}
            rowSelection={{
              selectedRowKeys: dataSource.selectedRowKeys,
              onChange: actions.onSelectChange,
              getCheckboxProps: record => ({
                disabled: record.status === 'removed',
              }),
            }}
            onChange={filters => actions.onChange(filters)}
            onRowClicked={actions.onRowClicked}
            EmptyTable={() => <EmptyTable onButtonClick={actions.onCreateClicked} />}
          />
        </div>
      </div>
    </>
  );
}
