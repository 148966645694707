import axios from 'axios';
import apiConfig from '../api.config';
import constants from '../../constants';
import storage from '../../storage';

export default async function saveBulkHotelLicence({ hotelId, licences }) {
  const {
    billingHost,
    URIs: { saveBulkHotelLicence },
  } = apiConfig;

  const uri = `${billingHost}${saveBulkHotelLicence}`;

  const storedCredentials = JSON.parse(storage.get(constants.AUTH.USER_KEY));

  const postDataArray = [];
  
  for (let index = 0; index < licences.length; index++) {
    const licence = licences[index];
    
    postDataArray.push({
      hotelId: hotelId || 0,
      hotelReference: 'civitfun',
      name: licence.name,
      contractId: licence.contractId,
      type: licence.type,
      currency: licence.currency,
      price: licence.price,
    })
  }
  
  return axios
  .post(uri, {
    accessToken: storedCredentials.accessToken,
    hotelReference: 'civitfun',
    data: postDataArray,
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      return res.data;
    });
}
