import axios from 'axios';
import apiConfig from 'utils/networking/api.config';

export default async function onboardingRequest({
  hotelId,
  bookingData,
  action = null,
  authorization = null,
  bookingIdentifier = null,
  bookingCode = '',
  entranceDate = '',
  holderName = null,
}) {
  const {
    validationsHost,
    URIs: {
      validations: { searches },
    },
  } = apiConfig;
  const selectedHotel = JSON.parse(localStorage.getItem('selectedHotel'));
  hotelId = selectedHotel?.id;
  bookingCode = bookingData.bookingCode;
  entranceDate = bookingData.entranceDate;

  const uri = `${validationsHost}${searches}`
    .replace(':hotelId', hotelId)
    .replace(':action', action || '');

  return axios
    .get(uri, {
      params: { bookingIdentifier, bookingCode, entranceDate, holderName },
      headers: { Authorization: authorization },
    })
    .then(res => {
      return res.data;
    });
}
