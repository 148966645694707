export default {
  header: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 32,
    paddingLeft: 32,
    alignItems: 'center',
  },
  tableContainer: {
    display: 'flex',
    width: '100%',
    flex: 1,
    marginTop: 16,
    marginBottom: 32,
  },
  table: {
    backgroundColor: 'white',
    marginLeft: 32,
    marginRight: 32,
    width: '100%',
    borderRadius: 14,
    padding: 14,
  },
};
