const styles = {
  title: {
    fontSize: '24px',
    marginTop: '20px',
    fontWeight: 'bold',
    color: '#333',
  },
  subtitle: {
    marginBottom: '20px',
    color: '#666',
  },
  tabList: {
    display: 'flex',
    cursor: 'pointer',
    borderBottom: '2px solid #eee',
    marginBottom: '20px',
  },
  tab: {
    padding: '12px 24px',
    marginRight: '8px',
    borderRadius: '20px 20px 0 0', // Bordes redondeados en la parte superior
    borderBottom: '2px solid transparent',
    backgroundColor: '#f1f1f1',
    color: '#555',
    transition: 'all 0.3s ease',
  },
  activeTab: {
    backgroundColor: '#007bff',
    color: '#fff',
    borderBottom: '2px solid #007bff',
  },
  tabContent: {
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '10px', // Bordes redondeados para el contenido
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  textContent: {
    marginBottom: '20px',
  },
  testMessageContainer: {
    display: 'flex',
    flexDirection: 'column', // Stack the loading icon and text vertically
    alignItems: 'flex-center', // Align the text to the left
    gap: 2, // Space between the spinner and text
    width: '50%', // Ensure full width to prevent overlap
  },

  flexContainer: {
    display: 'flex',
    width: '100%',
  },
  leftPanel: {
    width: '400px',
    flexShrink: 0, // Prevents shrinking of the left panel
    boxSizing: 'border-box', // Ensure padding does not affect the width
  },
  rightPanel: {
    flexGrow: 5, // This makes the right panel take the remaining space
    paddingLeft: '30px',
    boxSizing: 'border-box', // Ensure padding does not affect the width
  },
};

export default styles;
