import React, { useContext, useRef } from 'react';
import { Layout, Menu, Row } from 'antd';
import styles from './DefaultLayout.module.css';
import { AppContext } from '../../App.context';
import { Link } from 'react-router-dom';
import DefaultHeader from './DefaultHeader/DefaultHeader';
import useDefaultLayoutHook from './DefaultLayout.hook';
import LayoutScreens from './LayoutScreens';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

export default function DefaultLayout({
  children,
  contentHeader,
  singleBox,
  showHotelSelector,
  enableAllHotels = false,
  ...moreProps
}) {
  const { appState, appActions } = useContext(AppContext);
  const { state, actions } = useDefaultLayoutHook();
  const layoutDiv = useRef(null);

  const defaultOpenKeys = ['configuration', 'dashboard', 'account'];
  const isInProduction = process.env.REACT_APP_ENV === 'production';
  const isSuperUser = appState?.email.includes('@civitfun') || appState.superUser;

  return (
    <div ref={layoutDiv} className={styles.container} {...moreProps}>
      {/* {appState.showQuickguide && (
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
            width: '100%',
            height: document.documentElement.scrollHeight,
            minHeight: 100,
            position: 'absolute',
            zIndex: 9,
          }}
        />
      )} */}

      <Layout style={{ minHeight: '100vh' }}>
        <Header className={styles.layoutHeader}>
          <DefaultHeader
            breadCrumb={appState.breadCrumb}
            sliderCollapsed={state.sliderCollapsed}
            setSliderCollapsed={actions.setSliderCollapsed}
            showHotelSelector={showHotelSelector}
            enableAllHotels={enableAllHotels}
          />
        </Header>
        <Layout>
          <Sider
            width={240}
            collapsible
            collapsed={state.sliderCollapsed}
            onCollapse={() => actions.setSliderCollapsed(!state.sliderCollapsed)}
            className={styles.layoutSider}
            trigger={null}
          >
            <Menu mode="inline" defaultOpenKeys={defaultOpenKeys} className={styles.layoutSider}>
              {LayoutScreens.map(route => {
                const showMenu =
                  !isInProduction ||
                  (isInProduction &&
                    route?.showInProduction &&
                    (!route?.onlySuperUser || isSuperUser));

                return showMenu ? (
                  <SubMenu
                    key={route.name}
                    title={appActions.translate(`layout.${route.name}`)}
                    className={styles.menuItem}
                  >
                    {route.children?.map((subMenu, key) => getItem(subMenu, key, route))}
                  </SubMenu>
                ) : null;
              })}
            </Menu>
          </Sider>
          <Content className={styles.layoutContent}>
            {appState.currentScreen && (
              <div className={styles.pageTitleContainer}>
                {appState?.currentScreen?.icon && (
                  <img
                    style={{ height: 14, aspectRatio: 1, marginRight: 4 }}
                    src={require(`../../assets/images/icons/blue/${appState.currentScreen.icon}.png`)}
                  />
                )}
                {appState?.currentScreen?.name && (
                  <div className={styles.pageTitle}>
                    {appActions.translate(`layout.${appState?.currentScreen?.name}`)}
                  </div>
                )}
              </div>
            )}
            {contentHeader}
            {singleBox ? (
              children
            ) : (
              <Row gutter={[32, 32]} style={{ padding: 32 }}>
                {children}
              </Row>
            )}
          </Content>
        </Layout>
      </Layout>
    </div>
  );

  function getItem(subMenu, key, route) {
    const hasSubItems = subMenu.children && subMenu.children.length;
    const showItem =
      !isInProduction ||
      (isInProduction && subMenu?.showInProduction && (!subMenu?.onlySuperUser || isSuperUser));

    return showItem ? (
      <div>
        <Link to={subMenu.path} key={key} onClick={() => appActions.setBreadCrumb(route, subMenu)}>
          <div
            className={`${styles.siderItem} ${
              subMenu.path === actions.getLocation().pathname ? styles.siderItemActive : ''
            }`}
          >
            {subMenu.icon && (
              <img
                className={styles.icon}
                src={require(`../../assets/images/icons/white/${subMenu.icon}.png`)}
              />
            )}
            {appActions.translate(`layout.${subMenu.name}`)}
          </div>
        </Link>
        {hasSubItems &&
          subMenu.children.map(subItem => {
            return !isInProduction || (isInProduction && subItem?.showInProduction) ? (
              <Link
                to={subItem.path}
                key={key}
                onClick={() => appActions.setBreadCrumb(route, subItem)}
              >
                <div
                  className={`${styles.subSiderItem} ${
                    subItem.path === actions.getLocation().pathname ? styles.siderItemActive : ''
                  }`}
                >
                  {appActions.translate(`layout.${subItem.name}`)}
                </div>
              </Link>
            ) : null;
          })}
      </div>
    ) : null;
  }
}
