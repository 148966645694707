import { useState } from 'react';
import { uploadImages } from 'Sell/CrossSell/API/api.config';
import { generateUniqueImageName } from 'Sell/utils/utils';

export const useImageUploader = () => {
  const [files, setFiles] = useState([]);

  const upload = async () => {
    const uploadedImages = [];

    for (const [, file] of files.entries()) {
      const formData = new FormData();
      formData.append(
        'image',
        file.originFileObj,
        generateUniqueImageName(file.originFileObj.name)
      );

      const result = await uploadImages('images/upload', formData);
      const response = await result.json();
      uploadedImages.push({ path: response.url });
    }

    return uploadedImages;
  };

  const addFiles = newFiles => {
    setFiles(currentFiles => {
      const fileMap = new Map();

      currentFiles.concat(newFiles).forEach(file => {
        fileMap.set(file.uid, file);
      });

      return Array.from(fileMap.values());
    });
  };

  return {
    files,
    addFiles,
    upload,
  };
};
