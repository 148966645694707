import Colors from '../../../../constants/Colors';

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  inputRow: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    gap: 16,
    marginTop: 8,
  },

  mapHeader: {
    color: Colors.darkText,
    marginBottom: 8,
  },

  buttonsContainer: {
    marginTop: 16,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 8,
  },
};
