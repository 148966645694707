import Colors from 'constants/Colors';

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'left',
    gap: 8,
  },
  option: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    color: Colors.darkText,
  },
  desc: {
    fontSize: 12,
    color: Colors.darkText,
  },
};
