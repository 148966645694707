import axios from 'axios';
import apiConfig from '../api.config';

var CancelToken = axios.CancelToken;
var cancel;

export default function getPoliceReportList(data) {
  const {
    policeReportHost,
    URIs: { policeReport },
  } = apiConfig;

  const uri = `${policeReportHost}${policeReport}?filter=${JSON.stringify(data)}`;

  if (cancel) cancel();

  return axios
    .get(uri, {
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      }),
    })
    .then(res => {
      return res.data;
    });
}
