import axios from 'axios';
import apiConfig from '../../api.config';

export default async function getOauth({ hotelId, name}) {
  const {
    host,
    integrationHubHost,
    URIs: {oauth:{
        getOauth,
    }},
  } = apiConfig;

  const uri = `${host}${getOauth}?name=${name}&hotelId=${hotelId}`;

  return axios
    .get(uri, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res;
    });
}
