import axios from 'axios';
import apiConfig from 'utils/networking/api.config';

export default function doPmsConnectionCheck(hotelId) {
  const {
    validationsHost,
    URIs: { validations },
  } = apiConfig;

  const uri = `${validationsHost}${validations.checkConnection}`.replace(':hotelId', hotelId);

  return axios
    .get(uri, {
      headers: {
        auth: 'cbmL!K4tns.DorqF1PyGHc^%r/#qAH',
      },
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.error('Error checking PMS connection:', error);
      return null;
    });
}
