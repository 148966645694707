export default {
  card: {
    color: 'red',
    borderRadius: 8,
    border: '1px solid var(--grayscale-divider, #DFE0EB)',
    background: 'var(--grayscale-white, #FFF)',
    flex: 1,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  list: {
    padding: '20px 32px',
  },
  numbers: {
    color: '#9FA2B4',
  },
};
