import axios from 'axios';
import apiConfig from '../api.config';

export default async function formFieldsInferenceRequest({
  entranceDate = null,
  isTest = false,
  authorization = null,
  hotelId = null,
}) {
  const {
    integrationHubHost,
    URIs: { formFieldsInference },
  } = apiConfig;

  const uri = `${integrationHubHost}${formFieldsInference}`.replace(':hotelId', hotelId);

  return axios
    .post(uri, {}, { params: { entranceDate, isTest }, headers: { Auth: authorization } })
    .then(res => {
      return res.data;
    });
}
