import axios from 'axios';
import apiConfig from '../api.config';

export default function updateMailTemplate({ guestlink, hotelId, applyToAllHotels }) {
  const {
    host,
    URIs: { updateMailTemplate },
  } = apiConfig;

  let uri = `${host}${updateMailTemplate
    .replace(':hotelId', hotelId)
    .replace(':guestlinkId', guestlink.id)}`;

  if (applyToAllHotels) {
    uri = `${uri}?applyToChain=true`;
  }

  return axios
    .put(
      uri,
      { guestlink },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(res => {
      return res.data;
    });
}
