import { AppContext } from 'App.context';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';

export default function useImageSliderHook({ images }) {
  const { API } = useContext(ApiContext);
  const { appActions } = useContext(AppContext);

  const [state, setState] = useState({
    previewOpen: false,
    previewImage: '',
    fileList: [],
  });

  useEffect(() => {
    const formattedImages = parseImages(images);
    updateState({ fileList: formattedImages });
  }, [images]);

  const actions = {
    handlePreview: async file => {
      if (!file.url && !file.preview) {
        file.preview = await actions.getBase64(file.originFileObj);
      }

      updateState({
        previewImage: file.url || file.preview,
        previewOpen: true,
      });
    },

    getBase64: file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }),

    setPreviewOpen: open => updateState({ previewOpen: open }),

    setPreviewImage: img => updateState({ previewImage: img }),

    onImageAdded: file => {
      const updatedFileList = [
        ...state.fileList,
        {
          uid: file.uid,
          name: file.name,
          status: 'uploading',
          url: null,
        },
      ];

      updateState({ fileList: updatedFileList });
      API.uploadSliderImage({
        hotelId: appActions.getSelectedHotel()?.key,
        file,
        currentAmount: state.fileList.length,
      })
        .then(res => {
          const updatedFileList = [
            ...state.fileList,
            {
              uid: file.uid,
              name: file.name,
              status: 'done',
              url: res.path,
            },
          ];

          updateState({ fileList: updatedFileList });
        })
        .catch(err => console.error(err));
    },

    removeImage: data => {
      const imageToRemove = state.fileList.find(f => f.uid === data.uid);
      updateState({ fileList: state.fileList.filter(f => f.url !== data.url) });
      API.removeSliderImage({
        hotelId: appActions.getSelectedHotel()?.key,
        uid: imageToRemove.uid,
        url: imageToRemove.url,
      })
        .then(_ => {})
        .catch(_ => {});
    },
  };

  function parseImages(images) {
    if (!images || images.length === 0) {
      return [];
    }

    return images.map((img, indx) => {
      return {
        uid: img.id,
        name: indx,
        status: 'done',
        url: img.path,
      };
    });
  }

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
