export default `<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:21px;font-family:&quot;Calibri&quot;,sans-serif;">Voorwaarden en condities&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:18px;font-family:&quot;Calibri&quot;,sans-serif;">JURIDISCHE INFORMATIE&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;">
    <span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">
        Voor uw veiligheid garanderen we dat uw creditcardgegevens worden behandeld volgens de internationale PCI-DSS-normen. Ik machtig het hotel om alle kosten van mijn verblijf op de hierboven vermelde creditcard te factureren. In het geval dat ik het hotel op een datum verlaat die vóór de einddatum van de reservering in dit document is aangegeven, machtig ik dat het totale bedrag van de geboekte nachten, inclusief de niet gebruikte, op de creditcard wordt afgeschreven.<br><br>
        Bij [hotel_name] zijn we toegewijd aan de bescherming van uw privacy. U kunt ons bereiken op [address] of op het e-mailadres [hotel_email]. [hotel_name] zal uw gegevens verwerken om uw verblijf in het hotel te beheren en uw ervaring als onze gast te verbeteren, terwijl [hotel_name] ook uw gegevens zal verwerken om accommodatie en aanvullende diensten te bieden. We moeten mogelijk uw gegevens verwerken om te voldoen aan de wettelijke verplichtingen waaraan we zijn onderworpen (inclusief fraude-preventie). [hotel_name] kan uw gegevens communiceren aan derden, zoals banken of andere financiële instellingen om betalingen te verwerken of aan wetshandhavingsinstanties om te voldoen aan de verplichtingen die door de wet aan ons zijn opgelegd.<br><br>
        Als u zich aanmeldt, zal [hotel_name] uw persoonsgegevens verwerken om u de respectieve loyaliteitsdiensten te bieden. In dit opzicht zullen we alle gegenereerde punten verwerken en kunnen we u informatie sturen over uw punten en de voordelen van het programma, aangepast aan uw profiel.<br><br>
        U kunt uw rechten op toegang, rectificatie, verwijdering, bezwaar en/of beperking van de verwerking en de overdraagbaarheid van uw gegevens uitoefenen door te schrijven naar [hotel_name] op het hierboven vermelde e-mailadres of postadres, waarbij u uw identiteit aantoont en in beide gevallen (a) "Gegevensbescherming" als onderwerp en (b) of uw verzoek is gericht aan [hotel_name]. U kunt ook op elk moment contact opnemen met de bevoegde nationale toezichthoudende autoriteit.<br><br>
        Voor meer informatie, ga naar [hotel_web] of vraag bij de receptie van het hotel.
    </span>
</p>`;
