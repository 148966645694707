import { useState } from 'react';
import formFieldsInferenceRequest from 'utils/networking/api/formFieldInference.service';

const useGenerateFormButtonHook = () => {
  const [loading, setLoading] = useState(false);
  const selectedHotel = localStorage.getItem('selectedHotel');
  const hotelId = JSON.parse(selectedHotel)?.id;

  const generateFormFields = async () => {
    setLoading(true);
    try {
      await formFieldsInferenceRequest({
        hotelId,
        authorization: process.env.REACT_APP_AUTH,
        isTest: false,
      });
    } catch (error) {
      console.error('Error generating form fields:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { generateFormFields, loading };
};

export default useGenerateFormButtonHook;
