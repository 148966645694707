import axios from 'axios';

/**
 * Request Queue V.0.1.0
 * Only accepts request from axios, uses the CancelToken to cancel petitions
 * */
export default class RequestQueue {
  constructor (maxConcurrency = 2, callback) {
    this.queue = [];
    this.inProgress = 0;
    this.callback = callback;
    this.maxConcurrency = maxConcurrency;
    this.controllers = [];
  }

  /**
   * Stops the queue an any active petitions
   * */
  stop() {
    this.queue = [];
    this.cancelRequest();
    this.inProgress = 0;
  }

  /**
   * Cancels any requests and clean the controllers
   * @private
   * */
  cancelRequest() {
    this.controllers.forEach((c) => c.cancel());
    this.controllers = [];
  }

  /**
   * Add a request in Function format to the queue
   * @param request{(cancelToken) => {}}
   * */
  addRequest(request) {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    this.controllers.push(source);

    this.queue.push(async () => {
     try {
       return await request(source.token);
     } finally {
       this.controllers = this.controllers.filter((c) => c !== source);
     }
    });

    this.processNext();
  }

  /**
   * Procces the request
   * @private
   * */
  async processNext() {
    if (this.inProgress >= this.maxConcurrency || this.queue.length === 0) {
      return;
    }

    this.inProgress++;
    const request = this.queue.shift();

    try {
      const result = await request();
      if (this.callback) {
        this.callback(result);
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.inProgress--;
      this.processNext();
    }
  }
}