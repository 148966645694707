import 'constants/Colors.css';

export default {
  transparentSider: {
    backgroundColor: 'transparent',
  },

  customMenuItem: {
    height: 60,
  },

  svgIcon: {
    fill: 'black',
  },

  container: {
    height: 100,
    display: 'flex',
  },

  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 21,
    paddingLeft: 72,
    paddingRight: 72,
    width: 335,
  },

  shellContainer: {
    flexGrow: 1,
  },
};
