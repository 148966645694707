import { useHistory } from 'react-router-dom';

export function useUpsellHistory() {
  const { push, ...rest } = useHistory();

  return {
    push: (path, state) => push(`/upsell${path}`, state),
    ...rest,
  };
}

