import TagManager from 'react-gtm-module';

const GTM_ID = 'GTM-T6QNCZS';
const CUSTOM_DATA_LAYER_NAME = 'PageDataLayer';
const CUSTOM_EVENT_CATEGORY = 'CustomEvents';

function initialize() {
  TagManager.initialize({
    gtmId: GTM_ID,
    dataLayerName: CUSTOM_DATA_LAYER_NAME,
  });
}

function trackEvent({ event }) {
  if (process.env.REACT_APP_ENV != 'production') return;

  TagManager.dataLayer({
    dataLayer: {
      event: event,
      category: CUSTOM_EVENT_CATEGORY,
      timeStamp: new Date().toLocaleString(),
    },
    dataLayerName: CUSTOM_DATA_LAYER_NAME,
  });
}

export { initialize, trackEvent };
