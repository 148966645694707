import React, { useContext } from 'react';
import { AppContext } from 'App.context.js';
import styles from '../BillingModal/BillingModal.styles';
import { Radio } from 'antd';
import AppButton from 'Components/common/Forms/AppButton/AppButton';
import { BillingListingContext } from '../BillingListing.context';

export default function BillingSelectForm() {
    const {
        appActions: { translate },
    } = useContext(AppContext);

    const { actions, state } = useContext(BillingListingContext);
    
    const modalFeatured = require('assets/images/billing/modal-featured.png');
    const licenseIcon = require('assets/images/billing/license-modal-icon.png');
    const invoiceIcon = require('assets/images/billing/invoice-modal-icon.png');
    const contractIcon = require('assets/images/billing/contract-modal-icon.png');
    
    return (
        <div>
            <div style={styles.header}>
                <div>
                    <img src={modalFeatured} alt="modal-featured" />
                </div>
                <div style={styles.headerTitle}>{translate('billingConfig.billingContract.modal.title')}</div>
                <div style={styles.headerSubtitle}>{translate('billingConfig.billingContract.modal.subtitle')}</div>
            </div>
            <div style={styles.body}>
                <Radio.Group onChange={(ev) => actions.selectBillingFormMode(ev)} value={state.billingFormMode}>
                    <Radio value={'contract'} style={state.billingFormMode == 'contract' ? styles.selectedRadio : styles.normalRadio}><img src={contractIcon} alt="modal-icon-contract" /> {translate('billingConfig.billingContract.modal.option.contract')}</Radio>
                    <Radio value={'licence'} style={state.billingFormMode == 'licence' ? styles.selectedRadio : styles.normalRadio}><img src={licenseIcon} alt="modal-icon-license" /> {translate('billingConfig.billingContract.modal.option.license')}</Radio>
                    <Radio value={'invoice'} style={state.billingFormMode == 'invoice' ? styles.selectedRadio : styles.normalRadio}><img src={invoiceIcon} alt="modal-icon-invoice" /> {translate('billingConfig.billingContract.modal.option.invoice')}</Radio>
                </Radio.Group>
            </div>
            <div style={styles.footer}>
                <div style={styles.modalFooterButton}>
                    <AppButton
                        secondary={true}
                        action={() => actions.closeBillingModal()}
                        text={translate('billingConfig.billingContract.modal.cancel')}
                    />
                </div>
                <div style={styles.modalFooterButton}>
                    <AppButton
                        action={() => actions.changeModalForm()}
                        text={translate('billingConfig.billingContract.modal.confirm')}
                    />
                </div>
            </div>
        </div>
    );
}