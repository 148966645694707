import Colors from 'constants/Colors';

export default {
  mainContainer: {
    width: '100%',
    paddingRight: 32,
    paddingLeft: 32,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    color: Colors.titleColor,
    fontWeight: 'bold',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  tableContainer: {
    display: 'flex',
    width: '100%',
    flex: 1,
    marginTop: 16,
    marginBottom: 32,
  },
  table: {
    backgroundColor: 'white',
    marginLeft: 32,
    marginRight: 32,
    width: '100%',
    borderRadius: 14,
    padding: 14,
    minHeight: '70vh',
  },
  dealId: {
    color: '#2D5BFF',
    background: 'var(--light-greyscale-bg-darken, #F8F8F8)',
    padding: '10px 12px',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 700,
  },
  status: status => {
    let backgroundColor;
    let color;
    switch (status) {
      case 'disabled':
        backgroundColor = '#E03F5C';
        color = '#FFECEC';
        break;
      case 'removed':
        backgroundColor = 'grey';
        color = '#FFECEC';
        break;
      default:
        backgroundColor = '#C2CEFF';
        color = '#2D5BFF';
        break;
    }
    return {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px 12px',
      borderRadius: 8,
      backgroundColor,
      color,
      fontWeight: 700,
    };
  },
  image: {
    width: 84,
    height: 48,
    borderRadius: 6,
  },
  activate: {
    color: '#20AB8A',
  },
  delete: {
    color: '#F98189',
  },
  seeDetails: {
    color: '#2D5BFF',
  },
  disable: {
    color: 'grey',
  },
};
