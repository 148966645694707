import React from 'react';
import UploadBox from 'Sell/Components/PhotoGallery/UploadBox/UploadBox';
import PhotoBox from 'Sell/Components/PhotoGallery/PhotoBox/PhotoBox';
import { ReactComponent as DeleteIcon } from 'Sell/assets/icon/delete-icon.svg';
import styles from './PhotoGallery.styles';
import SortableList from 'Sell/Components/Sortable/SortableList';

const MAX_PHOTOS = 5;

export default function PhotoGallery({
  photoGallery = [],
  handleFileChange,
  fileList,
  handleDeleteImage,
  handleOnDragEnd,
}) {
  const displayedPhotos = [...photoGallery].sort((a, b) => a.order - b.order).slice(0, MAX_PHOTOS);

  return (
    <div style={styles.container}>
      {displayedPhotos.length != 0 && (
        <div style={styles.galleryContainer}>
          <SortableList
            items={displayedPhotos}
            onDragEnd={handleOnDragEnd}
            keyExtractor={item => item.id}
            renderItem={(photo, index) => {
              return (
                <div className={`item${index}`} style={styles['item' + index]}>
                  <DeleteIcon
                    onClick={() => handleDeleteImage(photo.id)}
                    style={styles.deleteIcon}
                  />
                  <PhotoBox path={photo.path} />
                </div>
              );
            }}
          />
        </div>
      )}
      <UploadBox
        maxCount={MAX_PHOTOS - photoGallery.length}
        handleFileChange={handleFileChange}
        fileList={fileList}
      />
    </div>
  );
}
