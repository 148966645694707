import Colors from 'constants/Colors';

export default {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    marginTop: 22,
  },
  buttonContainer: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 48,
    gap: 8,
  },
  title: {
    fontWeight: 'bold',
  },
  description: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    marginBottom: 8,
  },
  logBox: {
    flex: 1,
    width: '100%',
    backgroundColor: Colors.grayBackground,
    padding: 16,
    paddingRight: 40,
    borderRadius: 8,
    height: 150,
    marginTop: 8,
    overflowY: 'scroll',
    position: 'relative',
  },
  copyButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 8,
  },

  testMessageText: {
    marginLeft: 0, // Remove left margin to align text with spinner
  },
  testResultContainer: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 16, // Ensure there is a gap between components
  },
};
