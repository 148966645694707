import React, { useContext } from 'react';
import { Typography, Button, Card, Table } from 'antd';
import { AppContext } from 'App.context';
import styles from './Breakdown.styles';
import './Breakdown.css';
const { Title, Paragraph } = Typography;

export default function Breakdown({ onViewDetailsClick, breakdown }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const { title, groupBy, dataSource, columns } = breakdown;

  return (
    <Card
      style={styles.card}
      title={
        <div style={styles.title}>
          <div>
            <Title style={styles.title} level={4}>
              {title}
            </Title>
            <Paragraph style={styles.subtitle}>
              {translate('upsell.groupBy')} {groupBy}
            </Paragraph>
          </div>
          <Button style={styles.link} type="link" onClick={onViewDetailsClick}>
            {translate('upsell.viewDetails')}
          </Button>
        </div>
      }
    >
      <Table dataSource={dataSource} columns={columns} pagination={false}/>
    </Card>
  );
}
