import { useContext, useState } from 'react';
import { getAdressFromString } from '../../../utils/TextUtils';
import constants from '../../../utils/constants';
import { notification } from 'antd';
import { AppContext } from '../../../App.context';
import { ApiContext } from '../../../utils/networking/Api.context';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { translate } from 'modules/localization/Translations';
import useLegalDocumentsHook from '../LegalDocuments/LegalDocuments.hook';

export default function useHotels() {
  const { API } = useContext(ApiContext);
  const { appActions } = useContext(AppContext);
  const { actions: legalActions } = useLegalDocumentsHook();
  const history = useHistory();

  const DEFAULT_HOTEL_STATE = {
    hotelId: null,
    name: '',
    country: null,
    province: null,
    city: null,
    postalCode: null,
    address: null,
    mapPosition: constants.DEFAULT_MAP_POSITION,
    nif: null,
    roomCount: null,
    designation: null,
    establishmentType: null,
    hasInternet: null,
    phone: null,
    email: null,
    website: null,
    adUrl: null,
    countryCode: null,
    hotelStatusId: null,
  };

  const [hotelsState, setState] = useState({
    establishmentTypes: null,
    showDeleteWarning: false,
    needsUpdateList: false,
    showPmsPopup: false,
    ...DEFAULT_HOTEL_STATE,
  });

  useEffect(() => {
    API.getEstablishmentTypes().then(res => {
      updateState({ establishmentTypes: res });
    });
  }, []);

  const hotelsActions = {
    onSelectHotel: hotel => {
      if (hotelsState.hotelId == hotel?.id) {
        hotelsActions.reset();
      } else {
        const taxData = hotel?.taxData?.length ? hotel?.taxData[0] : null;

        updateState({
          hotelId: hotel?.id,
          country: hotel?.country,
          province: hotel?.region,
          city: hotel?.city,
          postalCode: hotel?.postalCode,
          address: hotel?.address,
          mapPosition: { lat: parseFloat(hotel.latitude), lng: parseFloat(hotel.longitude) },
          name: hotel?.name,
          roomCount: hotel?.roomCount,
          designation: hotel?.designation,
          establishmentType: hotel?.establishmentTypeId,
          hasInternet: hotel?.hasInternet,
          nif: taxData?.taxIdNumber,
          phone: hotel?.phone,
          email: hotel?.email || taxData?.businessEmail,
          website: hotel?.web,
          adUrl: hotel?.adUrl,
          hotelStatusId: hotel?.statusId || 1,
        });
      }
    },

    onNameChange: text => {
      updateState({ name: text });
    },

    setNewLocation: (data, position) => {
      if (data.length < 4) return;

      updateState({
        country: data[0],
        province: data[1],
        address: data[2],
        countryCode: data[3],
        city: data[4],
        postalCode: data[5],
        mapPosition: position,
      });
    },

    onButtonClicked: () => {
      hotelsState.hotelId ? hotelsActions.updateHotel() : hotelsActions.addHotel();
    },

    addHotel: () => {
      if (!hotelsState.name) {
        notification.warning({
          message: appActions.translate('hotels.emptyName'),
        });
        return;
      }
      if (!hotelsState.countryCode) {
        notification.warning({
          message: appActions.translate('hotels.emptyLocation'),
        });
        return;
      }
      updateState({ isLoading: true });

      API.addHotelsRequest(hotelsActions.getStateHotelData())
        .then(res => {
          hotelsActions.updateHotelList();
          appActions.loadAllHotels();
          updateState({ isLoading: false });
          hotelsActions.setShowPmsPopup(true);
          hotelsActions.saveDefaultLegalTexts(res);
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },

    updateHotel: () => {
      updateState({ isLoading: true });

      // Update state (only admin)
      if ( appActions.isUserAdmin() ) {
        API.updateHotelStatusId(hotelsActions.getStateHotelData()).then(_ => {
        }).catch(err => {
            console.warn(err);
          });
      }

      API.updateHotelsRequest(hotelsActions.getStateHotelData())
        .then(_ => {
          notification.success({
            message: appActions.translate('hotels.updateSuccess').replace('#', hotelsState.name),
          });
          hotelsActions.updateHotelList();
          appActions.loadAllHotels();
          updateState({ isLoading: false });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },

    onDeleteHotel: hotel => {
      hotelsActions.onSelectHotel(hotel);
      updateState({ showDeleteWarning: true });
    },

    deleteSelectedHotel: () => {
      updateState({ isLoading: true });
      API.deleteHotelRequest({ hotelId: hotelsState.hotelId })
        .then(_ => {
          notification.success({
            message: appActions.translate('hotels.deleteSuccess').replace('#', hotelsState.name),
          });
          hotelsActions.updateHotelList();
          appActions.loadAllHotels();
          updateState({ isLoading: false });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });

      updateState({ showDeleteWarning: false, selectedHotel: null });
    },

    cancelDelete: () => {
      hotelsActions.reset();
      updateState({ showDeleteWarning: false });
    },

    updateHotelList: () => {
      hotelsActions.setNeedsUpdateList(true);
      hotelsActions.reset();
    },

    setNeedsUpdateList: status => {
      updateState({ needsUpdateList: status });
    },

    getFormattedAdress: () => {
      return `${hotelsState.address}, ${hotelsState.province}, ${hotelsState.country}`;
    },

    reset: () => {
      updateState({
        ...DEFAULT_HOTEL_STATE,
      });
    },

    setShowPmsPopup: value => {
      updateState({ showPmsPopup: value });
    },

    goToPmsScreen: () => {
      history.push('/pms');
    },

    updateField: (key, value) => {
      updateState({ [key]: value });
    },

    saveDefaultLegalTexts: res => {
      const cardexDefaultTextEs = require(`../LegalDocuments/DefaultTexts/es/cardex`).default;
      const cardexDefaultTextEn = require(`../LegalDocuments/DefaultTexts/en/cardex`).default;
      const customCardexDefaultTextEs = require(`../LegalDocuments/DefaultTexts/es/custom_cardex_pdf`).default;
      const customCardexDefaultTextEn = require(`../LegalDocuments/DefaultTexts/en/custom_cardex_pdf`).default;

      const saveDocuments = [];

      const defaultCardexTexts = [
        { lang: 'es', value: cardexDefaultTextEs },
        { lang: 'en', value: cardexDefaultTextEn },
      ];

      defaultCardexTexts.forEach(textData => {
        const { lang, value: defaultCardexText } = textData;
        const type = 'cardex';

        legalActions.updateLegalText(type, defaultCardexText, null, false, res, lang);

        saveDocuments.push({
          type,
          lang,
          value: defaultCardexText,
        });
      });

      const defaultCustomCardexTexts = [
        { lang: 'es', value: customCardexDefaultTextEs },
        { lang: 'en', value: customCardexDefaultTextEn },
      ];

      defaultCustomCardexTexts.forEach(textData => {
        const { lang, value: defaultCustomCardexText } = textData;
        const type = 'custom_cardex_pdf';

        legalActions.updateLegalText(type, defaultCustomCardexText, null, false, res, lang);

        saveDocuments.push({
          type,
          lang,
          value: defaultCustomCardexText,
        });
      });

      return saveDocuments;
    },

    getEstablishmentTypeOptions: () => {
      const lang = appActions.getSelectedLanguage().id;
      try {
        return hotelsState?.establishmentTypes?.map(type => {
          return {
            value: type?.id,
            label: type?.translations.find(t => t.lang === lang)?.description,
          };
        });
      } catch (error) {
        console.error(error);
        return [];
      }
    },

    getHasInternetOptions: () => {
      return [
        {
          value: 1,
          label: appActions.translate('yes'),
        },
        {
          value: 0,
          label: appActions.translate('no'),
        },
      ];
    },

    getStateHotelData: () => {
      const address = hotelsActions.getFormattedAdress();

      let ret = {};
      Object.keys(DEFAULT_HOTEL_STATE).forEach((key, index) => {
        ret[key] = hotelsState[key];
      });

      ret = {
        ...ret,
        ...{
          latitude: hotelsState.mapPosition.lat,
          longitude: hotelsState.mapPosition.lng,
          address,
        },
      };

      ret.country = ret.countryCode || hotelsState?.country;
      ret.countryCode = ret?.country;

      return ret;
    },

    getHotelStatusIdOptions: () => {
      return [
        {
          value: 1,
          label: appActions.translate('hotels.statusId.label_1'),
        },
        {
          value: 2,
          label: appActions.translate('hotels.statusId.label_2'),
        },
        {
          value: 3,
          label: appActions.translate('hotels.statusId.label_3'),
        },
        {
          value: 4,
          label: appActions.translate('hotels.statusId.label_4'),
        },
        {
          value: 5,
          label: appActions.translate('hotels.statusId.label_5'),
        },
        {
          value: 6,
          label: appActions.translate('hotels.statusId.label_6'),
        },
        {
          value: 7,
          label: appActions.translate('hotels.statusId.label_7'),
        },
      ];
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { hotelsState, hotelsActions };
}
