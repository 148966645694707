import React, { useContext } from 'react';
import styles from './ItemTypeSelector.styles.js';
import TagCheckbox from 'Sell/Components/Upsert/Tag/Tag.jsx';
import { AppContext } from 'App.context.js';
import { itemTypes } from 'Sell/CrossSell/utils.js';

export default function ItemTypeSelector({ value = itemTypes[0].id, onClick = () => {} }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <div style={styles.container}>
      {itemTypes.map(itemType => (
        <TagCheckbox
          item={{ ...itemType, name: translate(itemType.name) }}
          onCheck={onClick}
          checked={itemType.id === value}
        />
      ))}
    </div>
  );
}
