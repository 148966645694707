import { notification } from 'antd';
import { AppContext } from 'App.context';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApiContext } from 'utils/networking/Api.context';
import doorOpeningDownloadTemplateRequest from 'utils/networking/api/doorOpeningDownloadTemplate.service';

export default function useDoorOpeningManteinanceHoook() {
  const { API } = useContext(ApiContext);
  const {
    appState,
    appActions: { getSelectedHotel, getSelectedLanguage, translate },
  } = useContext(AppContext);
  const history = useHistory();

  const [state, setState] = useState({
    hotel: null,
    isLoading: true,
    doorsOpeningList: [],
    doorOpeningSelected: null,
    showDeleteWarning: false,
    suscription: null,
  });

  useEffect(() => {
    actions.onHotelSelected(getSelectedHotel());
    actions.getDoorOpeningSuscriptionStatus();
  }, [appState.selectedHotel]);

  const actions = {
    loadDoorOpeningList: hotelId => {
      updateState({ isLoading: true });
      API.doorsOpeningByHotelListRequest(hotelId)
        .then(res => {
          updateState({ isLoading: false, doorsOpeningList: res });
        })
        .catch(error => {
          console.error(error);
          updateState({ isLoading: false });
        });
    },

    getDoorOpeningSuscriptionStatus: () => {
      API.getDoorOpeningSuscriptionStatus({ hotelId: getSelectedHotel()?.key }).then(res => {
        updateState({ suscription: res });
      });
    },

    addDoorOpening: () => {
      updateState({ isLoading: true });
      API.doorOpeningAddToHotelRequest({
        hotelId: state.hotel?.key,
        name: state.name,
        pmsId: state.pmsId,
        openingId: state.openingId,
      })
        .then(res => {
          actions.loadDoorOpeningList(state.hotel?.key);
        })
        .catch(error => {
          console.error(error);
          updateState({ isLoading: false });
        });
    },

    editDoorOpening: () => {
      updateState({ isLoading: true });
      API.doorOpeningUpdateToHotelRequest({
        hotelId: state.hotel?.key,
        doorOpeningId: state.doorOpeningSelected,
        name: state.name,
        pmsId: state.pmsId,
        openingId: state.openingId,
      })
        .then(res => {
          actions.reset();
          actions.loadDoorOpeningList(state.hotel?.key);
        })
        .catch(error => {
          console.error(error);
          updateState({ isLoading: false });
        });
    },

    deleteDoorOpening: () => {
      updateState({ isLoading: true });
      API.doorOpeningDeleteToHotelRequest({
        hotelId: state.hotel?.key,
        doorOpeningId: state.doorOpeningSelected,
      })
        .then(res => {
          actions.loadDoorOpeningList(state.hotel?.key);
          updateState({ showDeleteWarning: false });
        })
        .catch(error => {
          console.error(error);
          updateState({ isLoading: false, showDeleteWarning: false });
        });
    },

    onHotelSelected: hotel => {
      updateState({ hotel: hotel });
      if (hotel?.key && hotel?.key != 'null') {
        actions.loadDoorOpeningList(hotel.key);
      }
    },

    onSelectDoorOpening: doorOpening => {
      updateState({
        doorOpeningSelected: doorOpening.id,
        name: doorOpening.typeName,
        pmsId: doorOpening.pmsId,
        openingId: doorOpening.openingId,
      });
    },

    reset: () => {
      updateState({
        doorOpeningSelected: null,
        name: null,
        pmsId: null,
        openingId: null,
      });
    },

    onNameChanged: text => {
      updateState({ name: text });
    },

    onPmsIdChanged: text => {
      updateState({ pmsId: text });
    },

    onOpeningIdChanged: text => {
      updateState({ openingId: text });
    },

    onDeleteOpeningDoorClicked: door => {
      updateState({ showDeleteWarning: true, doorOpeningSelected: door.id });
    },

    cancelDelete: () => {
      updateState({ showDeleteWarning: false, doorOpeningSelected: null });
    },

    importFromFile: file => {
      updateState({ isLoading: true });
      API.doorOpeningImportToHotelRequest({ hotelId: state.hotel?.key, file })
        .then(res => {
          notification.success({
            message: 'Importación realizada correctamente!',
          });
          actions.loadDoorOpeningList(state.hotel?.key);
        })
        .catch(error => {
          notification.error({
            message: 'No se han podido procesar los datos correctamente. Revisa el formato',
          });
          console.error(error);
          updateState({ isLoading: false });
        });
    },

    downloadTemplate: async () => {
      await doorOpeningDownloadTemplateRequest({
        lang: getSelectedLanguage()?.id,
        fileName: translate('doorOpening.templateFileName'),
      });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
