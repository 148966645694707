export default `<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:21px;font-family:&quot;Calibri&quot;,sans-serif;">Terms and conditions&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:18px;font-family:&quot;Calibri&quot;,sans-serif;">LEGAL INFORMATION&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;">
    <span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">
        For your security, we guarantee that your credit card data will be treated according to international PCI-DSS standards. I authorize the Hotel to charge my total Hotel stay expenses to the credit card mentioned above. In the event I leave the Hotel on a date prior to the reservation end date shown on this document, I authorize the total amount of nights contracted, including the ones not used, to be charged to the credit card.<br><br>
        At [hotel_name], we are committed to the protection of your privacy. You can reach us at [address] or at the email address [hotel_email]. [hotel_name] will process your data to manage your stay at the hotel and to improve your experience as our guest, while [hotel_name] will also process your data to provide lodging and ancillary services. We may need to process your data to comply with the legal obligations we are subject to (including fraud prevention). [hotel_name] may communicate your data to third parties, such as banks or other financial institutions to process payments or to law enforcement agencies for compliance with the obligations imposed on us by the law.<br><br>
        If you sign up, [hotel_name] will process your personal data to provide you with the respective loyalty services. In this sense, we will process any points generated and we may send you information regarding your points and the program benefits, adjusted to your profile.<br><br>
        You may exercise your rights of access, rectification, erasure, objection, and/or restriction of the processing and the portability of your data by writing to [hotel_name] at the email or postal address stated above, proving your identity and indicating in both cases (a) "Data Protection" as the subject and (b) whether your request is addressed to [hotel_name]. Furthermore, you may contact the competent national Supervisory Authority at any time.<br><br>
        For more information, please enter [hotel_web] or consult at the hotel reception desk.
    </span>
</p>`;
