import React from 'react';
import styles from './WelcomeHeader.styles';
import HoverWrapper from '../../../HoverWrapper/HoverWrapper';
import { useHistory } from 'react-router-dom';

export default function WelcomeHeader({ title, showBack, headerLogo }) {
  const history = useHistory();
  return (
    <div>
      {showBack ? (
        <HoverWrapper onClick={() => history.goBack()}>
          <img src={require('assets/images/ic_back.png')} style={{ height: 24, marginLeft: -40 }} />
        </HoverWrapper>
      ) : (
        <div style={{ height: 24 }} />
      )}
      <div style={styles.container}>
        <img
          src={headerLogo ? headerLogo : require('assets/images/logo_dark.png')}
          style={styles.image}
        />
        <p style={styles.title}>{title}</p>
      </div>
    </div>
  );
}
