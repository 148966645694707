import React, { useEffect, useState } from 'react';
import styles from './BaseTable.module.css';
import { Button, Input, Table, ConfigProvider } from 'antd';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';

const defaultFilter = {
  limit: 10,
  page: 1,
};

export default function BaseTable(props) {
  const {
    columns,
    filter: customFilter,
    data,
    loading,
    onChange,
    onRowClicked,
    localPagination,
    bordered,
    defaultLimit = 10,
    showSizeChanger = true,
    removeCellPadding = false,
    currentPage = 0,
    rowSelection,
    ...moreProps
  } = props;

  const dataFilter = {
    ...defaultFilter,
    ...customFilter,
  };
  const [filter, setFilter] = useState({
    pagination: {
      current: currentPage + 1,
      pageSize: defaultLimit,
      page: dataFilter.page,
      showSizeChanger: showSizeChanger,
    },
    sort: {},
    searchParams: {
      searchColumn: '',
      searchText: '',
    },
    where: {},
  });

  useEffect(() => {
    const pagination = {
      ...filter.pagination,
      total: data.totalItems,
    };
    setFilter({ ...filter, pagination });
  }, [data]);

  function handleTableChange(pager, filters, sorter) {
    let columnFilters = getColumnFilters(filters);

    var sortOrderFormatted;
    if (sorter.order === 'ascend') sortOrderFormatted = 'ASC';
    if (sorter.order === 'descend') sortOrderFormatted = 'DESC';

    const newFilter = {
      ...filter,
      pagination: {
        pageSize: pager.pageSize,
        page: pager.current - 1,
        total: data.totalItems,
        showSizeChanger: showSizeChanger,
      },
      sort: {
        sortField: sorter.field,
        sortOrder: sortOrderFormatted,
      },
      ...columnFilters,
    };
    setFilter(newFilter);
    onChange && onChange(newFilter);
  }
  return (
    <div className={styles.container}>
      <ConfigProvider renderEmpty={props?.EmptyTable}>
        <Table
          childrenColumnName="antdChildren"
          className={removeCellPadding ? 'remove-padding' : styles.table}
          columns={columns.map(column => {
            if (column.searchable) {
              column = {
                ...column,
                ...getColumnSearchProps({
                  title: column.title,
                  dataIndex: column.dataIndex,
                  searchParams: filter.searchParams,
                  setSearchParams: searchParams => {
                    // setFilter({ ...filter, searchParams });
                  },
                }),
              };
            }
            return column;
          })}
          rowKey={record => record.id}
          dataSource={data.items}
          pagination={localPagination || filter.pagination}
          loading={loading}
          bordered={bordered}
          onChange={handleTableChange}
          rowSelection={rowSelection}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                onRowClicked && onRowClicked(record, rowIndex);
              },
            };
          }}
          {...moreProps}
      />
      </ConfigProvider>
    </div>
  );
}

function getColumnSearchProps({ title, dataIndex, searchParams, setSearchParams }) {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className={styles.searchPopup}>
        <Input
          ref={node => {
            searchParams.searchInput = node;
          }}
          placeholder={`Buscar ${title}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => {
            confirm();
            setSearchParams({
              searchText: selectedKeys[0],
              searchedColumn: dataIndex,
            });
          }}
          className={styles.searchInput}
        />
        <div>
          <Button
            type="primary"
            size="small"
            className={styles.searchButton}
            onClick={() => {
              confirm();
              setSearchParams({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              setSearchParams({
                searchText: null,
              });
            }}
            size="small"
            className={styles.searchButton}
          >
            Limpiar
          </Button>
        </div>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ fontSize: '14px', color: filtered ? '#2bad74' : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchParams.searchInput.select());
      }
    },
  };
}

function getColumnFilters(filters) {
  let columnFilters = {};
  if (filters && Object.keys(filters).length) {
    const propertiesToFilter = {};
    Object.keys(filters).forEach(filterKey => {
      if (filters[filterKey] && filters[filterKey].length) {
        propertiesToFilter[filterKey] = `${filters[filterKey][0]}`;
      }
    });
    columnFilters = {
      where: {
        ...propertiesToFilter,
      },
    };
  }
  return columnFilters;
}
