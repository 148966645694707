import Colors from 'constants/Colors';

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    fontSize: 12,
    fontWeight: 'bold',
    color: Colors.darkText,
  },
  inputContainer: {
    borderRadius: 6,
    color: Colors.lightGray,
    backgroundColor: Colors.inputBg,
    padding: 4,
  },
};
