import spanish from './translations/es';
import english from './translations/en';
import italian from './translations/it';
import deutsch from './translations/de';
import guestFormSpanish from './translations/guestFormFields/es';
import guestFormEnglish from './translations/guestFormFields/en';
import guestFormItalian from './translations/guestFormFields/it';
import guestFormDeutsch from './translations/guestFormFields/de';
import pmsConfigSpanish from './translations/pmsConfig/es';
import pmsConfigEnglish from './translations/pmsConfig/en';
import paymentConfigSpanish from './translations/paymentConfig/es';
import paymentConfigEnglish from './translations/paymentConfig/en';
import doorOpeningConfigSpanish from './translations/doorOpeningConfig/es';
import doorOpeningConfigEnglish from './translations/doorOpeningConfig/en';

export default [
  {
    name: 'Español',
    id: 'es',
    translations: spanish,
    termsAndConditionsUrl: 'https://www.civitfun.com/terminos_y_condiciones/',
    guestFormFields: guestFormSpanish,
    pmsConfig: pmsConfigSpanish,
    paymentConfig: paymentConfigSpanish,
    doorOpeningConfig: doorOpeningConfigSpanish,
  },
  {
    name: 'English',
    id: 'en',
    translations: english,
    termsAndConditionsUrl: 'https://en.civitfun.com/terms_and_conditions/',
    guestFormFields: guestFormEnglish,
    pmsConfig: pmsConfigEnglish,
    paymentConfig: paymentConfigEnglish,
    doorOpeningConfig: doorOpeningConfigEnglish,
  },
];
