import axios from 'axios';
import apiConfig from '../api.config';

export default function pmsCreateUdfConfigRequest({ hotelId, name, field }) {
  const {
    host,
    URIs: { pmsConfigHotel },
  } = apiConfig;
  const uri = `${host}${pmsConfigHotel}/${hotelId}/udf`;

  return axios
    .post(
      uri,
      { name, field },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(res => {
      return res.data;
    });
}
