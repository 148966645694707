import Colors from 'constants/Colors';

export default {
  tableCard: {
    backgroundColor: Colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'center',
  },
  tableTitle: {
    margin: 0,
  },
  tableText: {
    margin: 0,
    textAlign: 'center',
  },
  tableButton: {},
  tableIcon: {},
};
