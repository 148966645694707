import axios from 'axios';
import apiConfig from '../api.config';
import constants from '../../constants';
import storage from '../../storage';

export default function saveHotelInvoice({ hotelId, hotelName, invoice }) {
  const {
    billingHost,
    URIs: { saveHotelInvoice },
  } = apiConfig;

  const uri = `${billingHost}${saveHotelInvoice}`;

  const storedCredentials = JSON.parse(storage.get(constants.AUTH.USER_KEY));
  
  const form = new FormData();
  
  form.append('invoice_file', invoice.fileToUpload);
  form.append('data[hotelId]', hotelId);
  form.append('data[hotelName]', hotelName);
  form.append('data[hotelReference]', 'civitfun');
  form.append('data[invoiceId]', invoice.invoiceId);
  form.append('data[invoiceInternalId]', invoice.invoiceInternalId);
  form.append('data[invoiceDate]', invoice.invoiceDate.format('YYYY-MM-DD'));
  form.append('data[status]', invoice.status);
  form.append('data[contractId]', invoice.contractId);
  form.append('data[amount]', invoice.amount);
  form.append('data[currency]', invoice.currency);
  form.append('data[accessToken]', storedCredentials.accessToken);

  return axios
    .post(uri, form)
    .then(res => {
      return res.data;
    });
}
