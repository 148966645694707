import React from 'react';
import { Tag, Input, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useEditableTagHook from './EditableTagGroup.hook';

export default function EditableTagGroup({ tags, onTagsChange, addLabel, max }) {
  const { state, actions } = useEditableTagHook({ tags, onTagsChange, max });
  const notExcededMax = max == null || tags.length < max;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        flexWrap: 'wrap',
      }}
    >
      {tags.map((tag, index) => {
        const isLongTag = tag.length > 20;

        return (
          <div key={index}>
            {state.editInputIndex === index ? (
              <Input
                ref={ref => actions.saveInputRef(ref)}
                key={tag}
                size="small"
                className="tag-input"
                value={state.editInputValue}
                onChange={actions.handleEditInputChange}
                onBlur={actions.handleEditInputConfirm}
                onPressEnter={actions.handleEditInputConfirm}
              />
            ) : null}
            <div>
              {isLongTag ? (
                <Tooltip title={tag} key={tag}>
                  <TagElement tag={tag} index={index} isLongTag={isLongTag} />
                </Tooltip>
              ) : (
                <TagElement tag={tag} index={index} isLongTag={isLongTag} />
              )}
            </div>
          </div>
        );
      })}

      {state.inputVisible && (
        <Input
          ref={ref => actions.saveInputRef(ref)}
          type="text"
          size="small"
          className="tag-input"
          value={state.inputValue}
          onChange={actions.handleInputChange}
          onBlur={actions.handleInputConfirm}
          onPressEnter={actions.handleInputConfirm}
          placeholder={addLabel}
        />
      )}

      {!state.inputVisible && notExcededMax ? (
        <Tag className={'site-tag-plus'} onClick={actions.showInput}>
          <PlusOutlined /> {addLabel}
        </Tag>
      ) : null}
    </div>
  );

  function TagElement({ tag, index, isLongTag }) {
    return (
      <Tag className={'edit-tag'} key={tag} closable onClose={() => actions.handleClose(tag)}>
        <span onDoubleClick={e => actions.onDoubleClick(index, tag, e)}>
          {isLongTag ? `${tag.slice(0, 20)}...` : tag}
        </span>
      </Tag>
    );
  }
}
