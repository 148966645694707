const Colors = {
  primaryGreen: '#53ACA2',
  disabledButton: '#708393',
  darkText: '#3d3d3d',
  lightGray: '#e6e6e6',
  black: '#101010',
  grayBackground: '#F0F3F5',
  exportButton: '#708392',
  disableButton: '#708392',
  white: '#fff',
  red: '#E54646',
  grayText: '#6B6B6B',
  deleteButton: '#ee8989',
  editButton: '#53ACA2',
  greyPill: '#C8CED3',
  greenPill: '#7FDCC5',
  yellowPill: '#FBBA7D',
  tranparentBlack: 'rgba(0,0,0,.45)',
  bookingColor: '#234cad',
  bookingTransparent: 'rgba(23,152,218, 0.4)',
  civitfunColor: '#53ACA2',
  civitfunTransparent: 'rgba(83, 172, 162, 0.4)',
  totalStats: '#b3b3b3',
  totalStatsTransparent: 'rgba(179, 179, 179, 0.4)',
  yellowButton: '#FBC106',
  roommatikColor: '#59CADA',
  mtsColor: '#FCBA00',
  mtsTransparent: 'rgba(252, 186, 0, 0.4)',
  pmsConnect: '#1798DA',
  tryFreeButton: '#53ACA2',
  titleColor: '#4E5B7C',
  superUserButton: '#969B9E',
  headerBackground: '#FAFAFA',
  lightGreen: '#EFFDFC',
  layoutContainerBg: '#e4e5e6',
  lightBlue: '#45A4D6',
  unknownDevice: '#92A1BF',
  createPMSBg: '#F2F7FB',
  pmsLogo: '#636363',
  stepsGraph: '#45A4D6',
  testOk: '#319A81',
  testKo: '#AC1618',
  inputColor: '#244FAE',
  joditBorder: '#DADADA',
  joditBg: '#F9F9F9',
  inputBg: '#F9F9F9',
  actionColor: '#1890ff',
  blueColor: '#009ae9',
  blueIntense: '#234cad',
  blueBoxColor: '#e6ebff',
  blueIcon: '#009ae9',
  blueIconBg: '#ccebfb',
  redIcon: '#e90000',
  greenHotelIntegratedBg: '#ecfdf3',
  greenHotelIntegratedText: '#12b76a',
  yellowHotelNoIntegratedBg: '#ffefc1',
  yellowHotelNoIntegratedText: '#F79009',
  transparentText: '#00000073',
  redDesactivedBg: '#ffe5e3',
  redDesactivedText: '#b42318',
  blueStrongColor: '#000c30',
  bookingColorCheckinList: '#253e7a',
  dynamicFormColor: '#000c30',
};

export default Colors;
