export default {
  name: 'Nombre',
  surname: 'Apellido',
  second_surname: 'Segundo Apellido',
  document_number: 'Número de documento',
  sex: 'Sexo (M/F)',
  nationality: 'País del documento',
  birth_date: 'Fecha de nacimiento',
  expedition_date: 'Fecha de expedición',
  expiration_date: 'Fecha de caducidad',
  document_type: 'Tipo de Documento',
  male: 'Masculino',
  female: 'Femenino',
  email: 'Correo electrónico',
  'data-privacy-policy-label': 'He leído y acepto la política de privacidad de datos.',
  'data-commercial-use-label': 'Deseo recibir información comercial.',
  'loyalty-program-label': 'Quiero suscribirme al programa de fidelización.',
};
