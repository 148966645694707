import React, { useContext } from 'react';
import { Input } from 'antd';
import { AppContext } from 'App.context.js';

export default function HotelPmsIDInput({ item, setPMSId, disabled }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <div>
      <Input
        disabled={disabled}
        placeholder={translate('paperlessPush.pmsIdHint')}
        defaultValue={item.pmsId}
        onChange={e => {
          setPMSId(item.hotelId, e.target.value);
        }}
      />
    </div>
  );
}
