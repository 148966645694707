export default `<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:21px;font-family:&quot;Calibri&quot;,sans-serif;">Geschäftsbedingungen&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;">
    <strong><span style="font-size:18px;font-family:&quot;Calibri&quot;,sans-serif;">RECHTLICHE INFORMATIONEN&nbsp;</span></strong>
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;">
    <span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">
        Zu Ihrer Sicherheit garantieren wir, dass Ihre Kreditkartendaten gemäß den internationalen PCI-DSS-Standards behandelt werden. Ich ermächtige das Hotel, die gesamten Kosten meines Aufenthalts der oben genannten Kreditkarte zu belasten. Falls ich das Hotel vor dem im Reservierungsdokument angegebenen Enddatum verlasse, ermächtige ich das Hotel, den Gesamtbetrag der gebuchten Nächte, einschließlich der nicht genutzten, der Kreditkarte zu belasten.<br><br>
        Im [hotel_name] setzen wir uns für den Schutz Ihrer Privatsphäre ein. Sie können uns unter [address] oder per E-Mail an [hotel_email] erreichen. [hotel_name] wird Ihre Daten verarbeiten, um Ihren Aufenthalt im Hotel zu verwalten und Ihre Erfahrung als unser Gast zu verbessern. Gleichzeitig wird [hotel_name] Ihre Daten verarbeiten, um Unterkünfte und ergänzende Dienstleistungen bereitzustellen. Wir müssen Ihre Daten möglicherweise verarbeiten, um den gesetzlichen Verpflichtungen nachzukommen, denen wir unterliegen (einschließlich der Betrugsprävention). [hotel_name] kann Ihre Daten an Dritte, wie Banken oder andere Finanzinstitute, zur Zahlungsabwicklung oder an Strafverfolgungsbehörden zur Einhaltung der uns auferlegten gesetzlichen Verpflichtungen weitergeben.<br><br>
        Wenn Sie sich anmelden, wird [hotel_name] Ihre personenbezogenen Daten verarbeiten, um Ihnen die jeweiligen Treueleistungen zu bieten. In diesem Zusammenhang verarbeiten wir die gesammelten Punkte und können Ihnen Informationen zu Ihren Punkten und den Programmvorteilen, angepasst an Ihr Profil, zusenden.<br><br>
        Sie können Ihre Rechte auf Zugang, Berichtigung, Löschung, Widerspruch und/oder Einschränkung der Verarbeitung sowie das Recht auf Datenübertragbarkeit ausüben, indem Sie [hotel_name] unter der oben genannten E-Mail- oder Postanschrift schreiben, Ihre Identität nachweisen und in beiden Fällen (a) „Datenschutz“ als Betreff angeben und (b) ob Ihre Anfrage an [hotel_name] gerichtet ist. Außerdem können Sie sich jederzeit an die zuständige nationale Aufsichtsbehörde wenden. <br><br>
        Für weitere Informationen besuchen Sie bitte [hotel_web] oder erkundigen Sie sich an der Hotelrezeption.
    </span>
</p>`;
