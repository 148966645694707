import Colors from 'constants/Colors';

export default {
  input: {
    width: '100%',
    border: `1px solid ${Colors.layoutContainerBg}`,
  },
  button: {
    backgroundColor: Colors.layoutContainerBg,
    border: `1px solid ${Colors.layoutContainerBg}`,
  },
};
