import Colors from 'constants/Colors';

export default {
  container: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    gap: '15px',
  },
  formContainer: {
    flexGrow: 0.25,
    maxWidth: '300px',
  },
  buttonsContainer: {
    display: 'flex',
    flexFlow: 'coumn',
    gap: '8px',
  },
  button: {
    flexGrow: 1,
    backgroundColor: Colors.blueIntense,
    color: Colors.white,
  },
  tableContainer: {
    flexGrow: 0.75,
  },
};
