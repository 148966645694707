import axios from 'axios';
import apiConfig from '../api.config';

export default function getHotelPaymentConfig({ platform, hotelId }) {
  const {
    host,
    URIs: { hotelPaymentConfig },
  } = apiConfig;

  const uri = `${host}${hotelPaymentConfig.replace(':platform', platform)}`;

  return axios
    .get(uri, {
      params: {
        hotelId: hotelId,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
