import React, { useContext } from 'react';
import Loading from '../../../common/Loader/Loading';
import CustomCheckbox from '../../../common/CustomCheckbox/CustomCheckbox';
import styles from './CheckBoxList.styles';
import { AppContext } from '../../../../App.context';
import {toCamelCase} from '../../../../utils/TextUtils';

export default function CheckBoxList({ checkBoxList, selectedCheckBox, onCheckboxPress, type }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <div style={styles.container}>
      {checkBoxList ? (
        checkBoxList.map((item, index) => {
          return <CheckBox key={index} item={item} />;
        })
      ) : (
        <Loading />
      )}
    </div>
  );

  function CheckBox({ item }) {
    const isSelected = selectedCheckBox.includes(item?.id);
    const isDisabled = type === 'languages' ? item?.code === 'en' : item?.disable;

    const setTextByType = () => {
      switch (type) {
        case 'languages':
          return item?.label;
        default:
          return translate(`${type}.${toCamelCase(item?.key)}`);
      }
    }

    return (
      <div style={styles.itemContainer}>
        <CustomCheckbox
          text={setTextByType()}
          onClick={() => onCheckboxPress({ selectedCheckBox, id: item?.id, type, isSelected})}
          value={isDisabled ? true : isSelected}
          disabled={isDisabled}
        />
      </div>
    );
  }
}
