import { useContext, useState } from 'react';
import { HotelsContext } from '../../Dashboard/Hotels/Hotels.context';

const useMapHook = () => {
  const { hotelsActions } = useContext(HotelsContext);

  const [state, setState] = useState({
    map: null,
  });

  const actions = {
    onLoad: () => {
      const bounds = new window.google.maps.LatLngBounds();
      state.map.fitBounds(bounds);
      updateState({ map: state.map });
    },

    onUnmount: () => {
      updateState({ map: null });
    },

    handlePlaceSelected: places => {
      const place = places[0];
      if (place) {
        const country = actions.getTypeFromAddressComponents(place.address_components, 'country');
        const province = actions.getTypeFromAddressComponents(
          place.address_components,
          'administrative_area_level_1'
        );
        let city = actions.getTypeFromAddressComponents(place.address_components, 'locality');
        if (!city) {
          city =
            actions.getTypeFromAddressComponents(
              place.address_components,
              'administrative_area_level_2'
            ) ||
            actions.getTypeFromAddressComponents(place.address_components, 'sublocality') ||
            actions.getTypeFromAddressComponents(place.address_components, 'sublocality_level_1');
        }
        const address = actions.getTypeFromAddressComponents(place.address_components, 'route');
        const number = actions.getTypeFromAddressComponents(
          place.address_components,
          'street_number'
        );
        const postalCode = actions.getTypeFromAddressComponents(
          place.address_components,
          'postal_code'
        );

        const data = [
          country?.long_name,
          province?.long_name,
          address?.long_name && number?.long_name
            ? `${address?.long_name}, ${number?.long_name}`
            : place.vicinity,
          country?.short_name,
          city?.long_name,
          postalCode?.short_name,
        ];

        hotelsActions.setNewLocation(data, {
          lat: place.geometry?.location.lat(),
          lng: place.geometry?.location.lng(),
        });
      }
    },

    getTypeFromAddressComponents: (components, componentType) => {
      return components?.find(component => {
        return component?.types.find(type => {
          return type == componentType;
        });
      });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
};

export default useMapHook;
