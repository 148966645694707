import { AppContext } from 'App.context';
import Colors from 'constants/Colors';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApiContext } from 'utils/networking/Api.context';
import { notification } from 'antd';

const useAccountFunctionalitiesHook = selectedHotel => {
  const {
    appState,
    appActions: { translate, getSelectedHotel },
  } = useContext(AppContext);
  const { API } = useContext(ApiContext);
  const history = useHistory();

  const [state, setState] = useState({
    isLoading: false,
    showEmailWarning: false,
    showDashboardWarning: false,
    clickedFunctionality: null,
  });

  useEffect(() => {
    actions.loadHotelFuncionalities();
  }, [selectedHotel]);

  const actions = {
    loadHotelFuncionalities: () => {
      updateState({ isLoading: true });
      API.getAccountFunctionalities({ hotelId: getSelectedHotel()?.key })
        .then(res => {
          if (!appState.superUser) {
            res = res.filter(i => i.key !== 'police_report');
          }
          const data = res.sort(moduleSorter);
          updateState({ isLoading: false, data });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },

    onContractClicked: (method, key) => {
      updateState({ clickedFunctionality: key });

      if (method === 'email') {
        actions.sendFunctionalityEmail(key);
        actions.showSendEmailWarning(true, key);
      } else if (method === 'dashboard') {
        actions.sendFunctionalityEmail(key);
        actions.showDashboardWarning(key);
      }
    },

    onHandleSuscriptionClicked: (module, activate) => {
      updateState({ isLoading: true });
      const action = activate ? 'deactivate' : 'activate';
      API.handleSuscriptionRequest({
        hotelId: selectedHotel?.key,
        module,
        action
      })
        .then(_ => {
          notification.success({
            message: 'Success!',
          });
          actions.loadHotelFuncionalities();
        })
        .catch(_ => {
          notification.error({
            message: `Error trying to ${action} this module.`,
          });
          updateState({ isLoading: false });
        });
    },

    sendToScreen: () => {
      history.push(`/${actions.getScreenName(state.showDashboardWarning)}`);
    },

    sendFunctionalityEmail: functionality => {
      const feature = functionality === 'Booking.com' ? 'booking' : functionality;
      API.sendRequestFuncionalityEmail({
        hotelId: selectedHotel?.key,
        functionality: translate(`accountDetail.o_billing.${feature}`),
      })
        .then(_ => { })
        .catch(_ => { });
    },

    requestFunctionality: isDashboard => {
      API.requestFunctionalityContract({
        hotelId: selectedHotel?.key,
        suscription: state.clickedFunctionality,
        functionality: translate(
          `accountDetail.o_billing.${state.clickedFunctionality === 'Booking.com' ? 'booking' : state.clickedFunctionality
          }`
        ),
      })
        .then(res => {
          actions.loadHotelFuncionalities();
          actions.showSuccessMessage(isDashboard);
        })
        .catch(err => { });
    },

    showSuccessMessage: isDashboard => {
      notification.success({
        message: translate('accountDetail.emailSuccess'),
      });
      if (isDashboard) {
        actions.sendToScreen();
      }
      updateState({ showEmailWarning: false, showDashboardWarning: false });
    },

    showSendEmailWarning: show => {
      updateState({ showEmailWarning: show });
    },

    showDashboardWarning: show => {
      updateState({ showDashboardWarning: show });
    },

    getScreenName: key => {
      switch (key) {
        case 'pms':
          return 'pms';
        case 'Booking.com':
        case 'mts':
          return 'channels';
        case 'payment':
          return 'payment';
        case 'door_opening':
          return 'dooropening';
        case 'communication_tool':
          return 'guestlink';
        case 'police_report':
          return 'police-report';
      }
    },
  };

  function moduleSorter(a, b) {
    const specificSort = ["pms", "checkin", "Booking.com", "mts", "hbx", "tpaperless", "paperless_push", "kiosk", "upsell", "upsell_module", "upgrade", "cross-sell_module", "payment", "door_opening", "communication_tool", "police_report", "checkout"];
    const indexA = specificSort.indexOf(a.key)
    const indexB = specificSort.indexOf(b.key)

    if (indexA < indexB) {
      return -1
    }
    if (indexA > indexB) {
      return 1
    }
    return 0
  }

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
};

export default useAccountFunctionalitiesHook;
