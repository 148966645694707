import React, { useContext } from 'react';
import styles from './PaperlessPush.styles.js';
import usePaperlessPushHook from './PaperlessPush.hook.js';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout.jsx';
import { AppContext } from 'App.context.js';
import AppButton from 'Components/common/Forms/AppButton/AppButton.jsx';
import BaseTable from 'Components/common/BaseTable/BaseTable.jsx';
import PaperlessPushTable from './PaperlessPush.table';
import './PaperlessPush.css';

export default function PaperlessPush() {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = usePaperlessPushHook();

  const COLUMNS = PaperlessPushTable.getPaperlessPushColumns({
    translate,
    setPMSId: actions.setPMSId,
    setIpWhitelist: actions.setHotelWhitelist,
    isValidIpList: actions.isValidIpList,
    getExampleLink: actions.getExampleLink,
    goToAccountDetail: actions.goToAccountDetail,
  });

  return (
    <DefaultLayout singleBox title={translate('paperlessPush.title')} showHotelSelector={false}>
      <div>
        <div style={styles.header}>
          <AppButton
            text={translate('save')}
            action={actions.onSaveClicked}
            disabled={state.isLoading}
          />
        </div>

        <div style={styles.tableContainer}>
          <div style={styles.table}>
            <BaseTable
              data={state.dataSource}
              columns={COLUMNS}
              loading={state.isLoading}
              onChange={filters => actions.onChange(filters)}
              onRowClicked={actions.onRowClicked}
              removeCellPadding={true}
            />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
