import Colors from 'constants/Colors';

export default {
  title: {
    fontSize: 18,
    color: Colors.darkText,
    fontWeight: 'bold',
  },
  desc: {
    fontSize: 14,
    marginBottom: 8,
  },
};
