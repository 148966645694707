import Colors from 'constants/Colors';

export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 'bold',
    fontSize: 14,
    alignItems: 'center',
    gap: 10,
  },
  title: {
    fontWeight: 'bold',
    color: Colors.darkText,
  },
  desc: {
    color: Colors.darkText,
  },
  info: {
    padding: 8,
    justifyContent: 'flex-start',
    color: Colors.blueIntense,
    fontSize: 14,
    margin: 0,
    width: '100%',
  },
  infoTitle: {
    fontWeight: 'bold',
  },
};
