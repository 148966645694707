import Colors from 'constants/Colors';

export default {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'right',
    flex: 1,
    marginTop: 32,
  },
  input: {
    color: Colors.darkText,
    width: '100%',
    border: `1px solid ${Colors.layoutContainerBg}`,
  },
  button: {
    backgroundColor: Colors.layoutContainerBg,
    border: `1px solid ${Colors.layoutContainerBg}`,
  },
  inputTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: Colors.darkText,
  },
};
