import React from 'react';
import { DatePicker, InputNumber, Typography } from 'antd';

import CustomButton from 'Components/common/CustomButton/CustomButton';
import AppInput from 'Components/common/AppInput/AppInput';
import FormRow from 'Sell/Components/Upsert/FormRow/FormRow';
import { useApp } from 'Sell/hooks/useApp';
import { tripTypes } from 'Sell/CrossSell/utils';
import TagCheckbox from 'Sell/Components/Upsert/Tag/Tag';
import SlotDayForm from '../SlotDayForm/SlotDayForm';

const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function SlotForm({ dataSource, handlers }) {
  const {
    appActions: { translate },
  } = useApp();

  const formLang = dataSource.state.language;

  if (dataSource.state.type === 'good') {
    return null;
  }

  const departureDays = dataSource.state.slot.days.sort((a, b) => a.order - b.order);
  const returnDays = dataSource.state.slot.returnDays.sort((a, b) => a.order - b.order);

  return (
    <FormRow
      style={{ display: 'flex', flexDirection: 'column', gap: 30 }}
      title={translate(`crossSell.itemDetail.serviceAvailability`)}
    >
      {dataSource.state.type === 'transfer' ? (
        <>
          <Text>{translate('crossSell.itemDetail.slot.tripType.name')}</Text>
          <div style={{ display: 'flex' }}>
            {tripTypes.map(tripType => (
              <TagCheckbox
                item={{ ...tripType, name: translate(tripType.name) }}
                onCheck={() => handlers.handleSlotTripTypeChange(tripType.id)}
                checked={tripType.id === dataSource.state.slot.tripType}
              />
            ))}
          </div>
        </>
      ) : null}
      <div>
        <AppInput
          title={translate('crossSell.itemDetail.slot.title')}
          value={dataSource.state.slot.texts?.[formLang]?.name}
          onChange={value => handlers.handleSlotTextChange({ type: 'name', value })}
          hint={translate('crossSell.itemDetail.slot.titleHint')}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 30 }}>
        <RangePicker
          value={[dataSource.state.slot.startDate, dataSource.state.slot.endDate]}
          onChange={handlers.handleSlotDateChange}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text>{translate('crossSell.itemDetail.slot.quantity')}</Text>
          <InputNumber
            value={dataSource.state.slot.quantity}
            onChange={handlers.handleSlotQuantityChange}
            min={0}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: 30,
        }}
      >
        <Text strong>{translate('crossSell.itemDetail.slot.daysAvailability')}</Text>
        <div style={{ display: 'flex', gap: 30, flexGrow: 1, justifyContent: 'center' }}>
          <SlotDayForm days={departureDays} handlers={handlers} />
          {dataSource.state.slot.tripType === 'round-trip' ? (
            <SlotDayForm
              days={returnDays}
              handlers={{
                handleSlotDayStatusChange: day =>
                  handlers.handleSlotDayStatusChange({ ...day, isReturn: true }),
                handleSlotDayTimeChange: (day, times) =>
                  handlers.handleSlotDayTimeChange({ ...day, isReturn: true }, times),
                handleSlotAddDay: day => handlers.handleSlotAddDay({ ...day, isReturn: true }),
              }}
            />
          ) : null}
        </div>
        {!dataSource.state.slot.id ? (
          <div style={{ margin: 'auto' }}>
            <CustomButton
              type="primary"
              onClick={handlers.handleSlotSubmit}
              background="#2D5BFF"
              color="white"
            >
              {translate('crossSell.itemDetail.slot.createSlot')}
            </CustomButton>
          </div>
        ) : null}
      </div>
    </FormRow>
  );
}
