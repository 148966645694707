function getAdressFromString(input) {
  try {
    const splitted = input.split(',');
    const reversedArray = splitted.reverse();

    const core = reversedArray.slice(0, 2);
    const rest = reversedArray.slice(2).join(',');
    const result = [...core, rest];
    return result;
  } catch (error) {
    console.warn('getAdressFromString', error);
    return [null, null, null];
  }
}

function toCamelCase(str) {
  try {
    return str.toLowerCase().replace(/_./g, match => match.charAt(1).toUpperCase());
  } catch (error) {
    console.warn('toCamelCase', error)
    return str;
  }
}

function getInputText(input, translate) {
  switch (typeof input) {
    case 'boolean':
      return input ? translate('yes') : translate('no');
    case 'number':
      return input == 1 ? translate('yes') : translate('no');
    case 'string':
      return input == 'true' || input == '1'
        ? translate('yes')
        : input == 'false' || input == '0'
        ? translate('no')
        : input;
    default:
      return input;
  }
}

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function capitalize(input) {
  return input?.charAt(0).toUpperCase() + input?.slice(1);
}

function isEmpty(str) {
  return !str || str.length === 0;
}

function prettifyHtml(html) {
  let indent = 0,
    mode = 'IDLE',
    inTag = false,
    tag = '',
    tagToCome = '',
    shouldBreakBefore = false,
    shouldBreakAfter = false,
    breakBefore = ['p', 'ul', 'li'],
    breakAfter = ['div', 'h1', 'h2', 'h3', 'h4', 'p', 'ul', 'li'];

  return html.split('').reduce((output, char, index) => {
    if (char === '<') {
      tagToCome = whichTag(html, index);
      shouldBreakBefore = tagToCome && breakBefore.indexOf(tagToCome) >= 0;
      mode = 'TAG';
      inTag = true;
      output += (shouldBreakBefore ? br(indent) : '') + '<';
    } else if (char === '/' && mode == 'TAG') {
      mode = 'CLOSING_TAG';
      inTag = true;
      output += '/';
    } else if (char === ' ') {
      inTag = false;
      output += ' ';
    } else if (char === '>') {
      if (mode === 'TAG' || mode === 'CLOSING_TAG') {
        indent += mode === 'TAG' ? +1 : -1;

        shouldBreakAfter = breakAfter.indexOf(tag) >= 0;
        inTag = false;
        tag = '';
      }
      output += '>';
      output += shouldBreakAfter ? br(indent) : '';
    } else {
      output += char;

      if (inTag) {
        tag += char;
      }
    }

    return output;
  }, '');

  function br(indent) {
    return '\n' + _tabs(indent);
  }

  function whichTag(html, index) {
    let inTag = true,
      tag = '';

    const arr = html.split('');

    for (let i = index + 1; i < index + 10; i++) {
      const char = arr[i];

      if (char >= 'a' && char <= 'z' && inTag) {
        tag += char;
      } else if (char !== '/') {
        inTag = false;
      }
    }

    return tag;
  }
}

function _tabs(number) {
  let output = '';

  for (let cnt = 0; cnt < number; cnt++) {
    output += '\t';
  }

  return output;
}

function camelToUnderscore(key) {
  var result = key.replace(/([A-Z])/g, ' $1');
  return result.split(' ').join('_').toUpperCase();
}

function copyTextToClipboard(text) {
  navigator.clipboard.writeText(text);
}

function groupByKey(list, key) {
  return list.reduce(
    (hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }),
    {}
  );
}

function getEmailScheduleCode(item) {
  let code = item?.emailType;
  if (item?.schedule) code = `${code}-${item.schedule}`;
  if (item?.value) code = `${code}-${item.value}`;
  if (item?.timeUnit) code = `${code}-${item.timeUnit}`;

  return code;
}

function removeAccents(input) {
  const accentsMap = {
    á: 'a',
    à: 'a',
    â: 'a',
    ä: 'a',
    é: 'e',
    è: 'e',
    ê: 'e',
    ë: 'e',
    í: 'i',
    ì: 'i',
    î: 'i',
    ï: 'i',
    ó: 'o',
    ò: 'o',
    ô: 'o',
    ö: 'o',
    ú: 'u',
    ù: 'u',
    û: 'u',
    ü: 'u',
    ç: 'c',
  };

  return input
    .split('')
    .map(char => accentsMap[char] || char)
    .join('');
}

export {
  getAdressFromString,
  getInputText,
  capitalize,
  validateEmail,
  prettifyHtml,
  copyTextToClipboard,
  camelToUnderscore,
  groupByKey,
  getEmailScheduleCode,
  isEmpty,
  removeAccents,
  toCamelCase,
};
