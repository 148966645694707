export default {
  content: {
    padding: 32,
  },
  formContainer: {
    gap: 10,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  hotelSelectorContainer: {
    marginTop: 16,
  },
  buttonContainer: {
    marginTop: 16,
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
};
