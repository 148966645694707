import axios from 'axios';
import apiConfig from '../api.config';

export default function sellAddPMSNoteRequest({ hotelId, data }) {
  const {
    integrationHubHost,
    URIs: { sellAddPMSNote },
  } = apiConfig;

  const uri = `${integrationHubHost}${sellAddPMSNote}`.replace(':hotelId', hotelId);

  return axios
    .post(uri, JSON.stringify({ data }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
