import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../App.context';
import { ApiContext } from '../../../utils/networking/Api.context';
import { capitalize } from '../../../utils/TextUtils';

const useHotelSelectorHook = (onHotelSelected, enableAllHotels, autoSelect) => {
  const { appState, appActions } = useContext(AppContext);
  const { API } = useContext(ApiContext);

  const [state, setState] = useState({
    hotels: null,
    applyToAllHotels: false,
    currentlySelectedHotel: appActions.getSelectedHotel(),
    selectedHotelLabel: null,
  });

  useEffect(() => {
    if (!appState.hotelList) {
      API.allHotelsRequest()
        .then(res => {
          appActions.setHotelList(res);
          const copy = JSON.parse(JSON.stringify(res));
          updateState({ hotels: parseHotelList(copy) });
        })
        .catch(err => {
          console.warn(err);
        });
    } else {
      const copy = JSON.parse(JSON.stringify(appState.hotelList));
      updateState({ hotels: parseHotelList(copy) });
    }
  }, []);

  useEffect(() => {
    if (appActions.getSelectedHotel()) {
      autoSelect && onHotelSelected(appActions.getSelectedHotel());
      actions.setCurrentlySelectedHotel(appActions.getSelectedHotel());
    } else if (state.hotels) {
      const firstHotel = actions.getFirstHotel();
      appActions.setSelectedHotel(firstHotel);
    }
  }, [state.hotels]);

  useEffect(() => {
    if (appState.hotelList && enableAllHotels != undefined) {
      if (appActions.getSelectedHotel()?.key == null && !enableAllHotels) {
        if (appState?.hotelList && appState?.hotelList.length) {
          const firstHotel = actions.getFirstHotel();
          appActions.setSelectedHotel(firstHotel);
        }
      }
      const copy = JSON.parse(JSON.stringify(appState.hotelList));
      updateState({ hotels: parseHotelList(copy) });
    }
  }, [appState.hotelList, appState.selectedHotel]);

  const actions = {
    handleSearch: value => {
      const copy = JSON.parse(JSON.stringify(appState.hotelList));
      const hotels = parseHotelList(copy);

      const filteredHotels = hotels.filter(hotel =>
        hotel?.name.toUpperCase().includes(value.toUpperCase())
      );

      updateState({ hotels: filteredHotels });
    },

    getDefaultValue: () => {
      if (appActions.getSelectedHotel()) {
        return capitalize(appActions.getSelectedHotel()?.value);
      }
      if (enableAllHotels) return appActions.translate('hotelSelector.allHotels');
      if (appState?.hotelList?.length) {
        return capitalize(appState?.hotelList[0].name);
      }

      return null;
    },

    setCurrentlySelectedHotel: id => {
      updateState({ currentlySelectedHotel: id });
    },

    getFirstHotel: () => {
      return {
        key: appState?.hotelList[0]?.id || state?.hotels[0]?.id || 'null',
        value: appState?.hotelList[0]?.name || state?.hotels[0]?.name,
        slug: appState?.hotelList[0]?.slug || state?.hotels[0]?.slug,
      };
    },
  };

  function parseHotelList(list) {
    const newList = list;
    if (newList && enableAllHotels) {
      newList.unshift({ id: null, name: appActions.translate('hotelSelector.allHotels') });
    }

    return newList;
  }

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
};

export default useHotelSelectorHook;
