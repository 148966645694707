import Colors from 'constants/Colors';

export default {
  popUpContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 20,
  },
  popupLeft: {
    fontSize: 34,
    fontWeight: 'bold',
    color: Colors.titleColor,
  },
  popupSubtitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: Colors.titleColor,
    marginTop: -4,
    lineHeight: 1,
  },
  popupText: {
    fontSize: 16,
    fontWeight: 'light',
    color: Colors.titleColor,
    lineHeight: 1,
  },
  buttonContainer: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  popupImage: {
    aspectRatio: 1,
    height: 200,
  },
};
