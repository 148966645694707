const STATE_INDICATOR_SIZE = 18;

export default {
  stateIndicator: {
    minWidth: STATE_INDICATOR_SIZE,
    minHeight: STATE_INDICATOR_SIZE,
    borderRadius: STATE_INDICATOR_SIZE / 2,
    aspectRatio: 1,
  },
};
