export default `<p align="center"><img src="[chain_logo]" width="auto" height="100"></p>

<p style="font-family: &quot;Times New Roman&quot;, serif; margin: 0cm; text-align: center;"><span style="font-family: Calibri, sans-serif;"><span style="font-size: 21px;"><strong>PARTE DE ENTRADA</strong></span><span style="font-size: 16px;">&nbsp;</span></span></p>
&nbsp;
<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">DATOS DE LA EMPRESA ARRENDADORA&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 95%; border-collapse: collapse; margin-left: 2.32975%; margin-right: -2.25pt;">
<tbody>
    <tr>
        <td style="width: 148.6pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Núm. de Parte/Cardex:&nbsp;</span></p>
        </td>
        <td style="width: 250.55pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[police_report_number]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Nombre o razón social del titular:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_business_name]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">CIF/VAT/NIF:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Municipio:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_city]&nbsp;</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Provincia:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_province]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Teléfono fijo y/o móvil:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_phone]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Dirección de correo electrónico:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_business_email]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Web de la empresa:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_web]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Url para identificar el anuncio:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_ad_url]</span></p><br>
        </td>
    </tr>
</tbody>
</table>

&nbsp;

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">DATOS DEL ESTABLECIMIENTO&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 94.8208%; border-collapse: collapse; margin-left: 2.50896%; margin-right: -2.25pt;">
<tbody>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Tipo de establecimiento:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[establishment_type]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Denominación:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_name]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Dirección completa:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[address]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Código postal:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_postal_code]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Localidad y provincia:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_city], [CIF_province]</span></p><br>
        </td>
    </tr>
</tbody>
</table>

&nbsp;

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">DATOS DEL VIAJERO&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 94.4624%; border-collapse: collapse; margin-left: 2.86738%; margin-right: -2.25pt;">
<tbody>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Nombre:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_first_name]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Primer apellido:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_surname]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Segundo apellido:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_second_surname]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Sexo:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_gender]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Número del documento de identidad:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_document_number]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Número de soporte del documento:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_document_support_number]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Tipo de documento:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_document_type]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Fecha de expedición:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_expedition_date]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Fecha de caducidad:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_expiration_date]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">País del documento:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_nationality]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Nacionalidad:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_nationality]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Fecha de nacimiento:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_birth_date]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Lugar de residencia habitual:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Dirección completa:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_address]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Codigo postal:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_postal_code]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Ciudad:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_city]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Provincia:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_province]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">País:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_country]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Teléfono fijo:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_landline_phone]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Teléfono móvil:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_phone]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Correo electrónico:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_email]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Número de viajeros:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[num_guests]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Relación de parentesco entre los viajeros:</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_family_relationship]</span></p><br>
        </td>
    </tr>
</tbody>
</table>

<div style="page-break-before:always;margin-top:5cm"></div>

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">DATOS DE LA TRANSACCIÓN&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 94.1239%; border-collapse: collapse; margin-left: 2.86738%; margin-right: -2.25pt;">
<tbody>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Datos del contrato:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Número de referencia:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[reference_number]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Fecha:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[entrance_date]</span></p><br>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Datos de la ejecución del contrato:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Fecha y hora de entrada:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[entrance_date],&nbsp;[entrance_time]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Fecha y hora de salida:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[departure_date],&nbsp;[departure_time]</span></p><br>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Datos del inmueble:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Dirección completa:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[address]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Número de habitaciones:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_room_count]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Conexión a internet:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_has_internet]</span></p><br>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Datos de pago:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Tipo:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_payment_type]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Identificación del medio de pago:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Titular del medio de pago:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Fecha de caducidad de la tarjeta:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Fecha de pago:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p><br><br>
        </td>
    </tr>
</tbody>
</table>

&nbsp;

<p>[legal_cardex]<br><br></p>

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Firma</span></strong></p>
<p><img src="[guest_signature]" width="auto" height="200"></p>
<p></p>`;