import axios from 'axios';
import apiConfig from 'utils/networking/api.config';

export default function superUserChainsRequest(token, userId, page, size, filters) {
  const {
    host,
    URIs: { auth },
  } = apiConfig;

  var filter = { page, size, ...filters };

  console.log(JSON.stringify(filter));
  const uri = `${host}${auth.superUser.chains}?filter=${JSON.stringify(filter)}`
    .replace(':userId', userId)
    .replace(/&/g, 'amp;')
    .replace(/#/g, 'hashtag;');

  return axios
    .get(uri, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res => {
      return res.data;
    });
}
