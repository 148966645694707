import React from 'react';
import { AppContext } from './App.context';
import { ApiContext } from './utils/networking/Api.context';
import useAppHook from './App.hook';
import 'antd/dist/antd.css';
import Routes from './Routes/Routes';
import styles from './App.module.css';
import useApi from './utils/networking/Api.hook';
import Colors from 'constants/Colors';

function App() {
  const { appState, appActions } = useAppHook();
  const { API } = useApi({ appState, appActions });

  return (
    <div>
      {appState.resourcesLoaded ? (
        <div className={styles.app}>
          <AppContext.Provider value={{ appState, appActions }}>
            <ApiContext.Provider value={{ API }}>
              <Routes />
            </ApiContext.Provider>
          </AppContext.Provider>
        </div>
      ) : null}
    </div>
  );
}

export default App;
