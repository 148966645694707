import { useEffect, useRef, useState } from 'react';

export default function useTextEditorHook(
  documentDefaultValue,
  labelDefaultValue,
  isFirst,
  onPreviewPressed,
  selectedLanguage
) {
  const editor = useRef(null);
  const textArea = useRef(null);
  const IMAGE_TAGS = ['chain_logo', 'hotel_logo', 'guest_signature', 'holder_signature'];
  const EDITOR_HEIGHT = 512;

  const [state, setState] = useState({
    showRecoverDefaultWarning: false,
    editorValue: documentDefaultValue,
    labelValue: labelDefaultValue,
  });

  useEffect(() => {
    updateState({ labelValue: labelDefaultValue });
  }, [labelDefaultValue]);

  useEffect(() => {
    if (isFirst) onPreviewPressed(documentDefaultValue);
  }, [selectedLanguage]);

  useEffect(() => {
    actions.forceEditorText(documentDefaultValue);
    updateState({ editorValue: documentDefaultValue });
  }, [documentDefaultValue]);

  const actions = {
    setEditorRef: ref => {
      editor.current = ref;
    },

    setTextAreaRef: ref => {
      textArea.current = ref;
    },

    insertTag: tag => {
      if (!tag) return;
      if (IMAGE_TAGS.includes(tag)) {
        editor.current.selection.insertHTML(`<img src="[${tag}]" width="auto" height="100"></img>`);
      } else {
        editor.current.selection.insertHTML(`[${tag}]`);
      }
    },

    onRecoverDefaultTextPressed: defaultValue => {
      actions.forceEditorText(defaultValue);
      actions.setShowRecoverDefaultWarning(false);
    },

    forceEditorText: text => {
      textArea.current.value = text;
    },

    setShowRecoverDefaultWarning: show => {
      updateState({ showRecoverDefaultWarning: show });
    },

    getText: () => {
      return textArea.current.value;
    },

    getEditorConfig: disabled => {
      return {
        maxHeight: EDITOR_HEIGHT,
        minHeight: EDITOR_HEIGHT,
        height: EDITOR_HEIGHT,
        removeButtons: ['fullsize', 'print', 'dots', 'copyformat', 'image'],
        showCharsCounter: false,
        showWordsCounter: false,
        sourceEditorNativeOptions: { theme: 'white' },
        placeholder: '',
        readonly: disabled,
        cleanHTML: {
          fillEmptyParagraph: false,
        },
        iframe: true,
      };
    },

    setLabelValue: text => {
      updateState({ labelValue: text });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
