import React, { useContext } from 'react';
import { AppContext } from 'App.context';
import { Card, Spin } from 'antd';
import { Line } from 'react-chartjs-2';

import Counter from 'Sell/CrossSell/Reports/Components/Counter.jsx';
import Breakdown from 'Sell/CrossSell/Reports/Components/Breakdown/Breakdown.jsx';
import RangePicker from 'Sell/Components/RangePicker.jsx';
import { useReports } from './Reports.hook.js';
import styles from './Reports.styles.js';
import './Reports.css';

export default function Reports() {
  const {
    appState,
    appActions: { translate },
  } = useContext(AppContext);
  const currentLanguage = appState.currentLanguage.id;

  const {
    defaultDates,
    labels,
    selectedCounter,
    setSelectedCounter,
    actions,
    data,
    topSells,
    isLoading,
  } = useReports();
  const {
    countPerDay,
    approved,
    pending,
    rejected,
    total,
    totalPending,
    totalRejected,
    totalApproved,
    revenue,
    currency,
  } = data;

  const { handleViewDetailsClickCrossSell, setSelectedDates } = actions;

  const crossSellListStatus = {
    title: translate('crossSell.crossSellList'),
    groupBy: translate('crossSell.status'),
    columns: [
      { title: translate('crossSell.items'), dataIndex: 'title' },
      { title: translate('crossSell.reports.transactionCount'), dataIndex: 'transactionCount' },
      { title: translate('crossSell.reports.itemNumbers'), dataIndex: 'number' },
      { title: translate('crossSell.reports.revenue'), dataIndex: 'revenue' },
    ],
    dataSource: topSells instanceof Array && topSells?.map(topSell => ({
      title: topSell.item.texts.find(text => text.lang === currentLanguage && text.type === 'name')
        ?.value,
      transactionCount: topSell.count,
      number: topSell.count * topSell.quantity,
      revenue: `${parseFloat(topSell.item.price) * topSell.quantity * topSell.count}${currency}`,
    })),
  };

  const counters = [
    { titleKey: 'crossSell.totalCrossSell', index: 0, number: total },
    { titleKey: 'crossSell.approved', index: 1, number: totalApproved },
    { titleKey: 'crossSell.pending', index: 2, number: totalPending },
    { titleKey: 'crossSell.rejected', index: 3, number: totalRejected },
    { titleKey: 'crossSell.crossSellRevenue', index: 4, number: `${revenue}${currency}` },
  ];

  const datasetOptions = {
    totalCrossSell: {
      color: 'blue',
      visible: true,
      data: countPerDay,
    },
    approved: {
      color: 'grey',
      visible: selectedCounter === 0 || selectedCounter === 1,
      data: approved,
    },
    pending: {
      color: 'grey',
      visible: selectedCounter === 2,
      data: pending,
    },
    rejected: {
      color: 'grey',
      visible: selectedCounter === 3,
      data: rejected,
    },
  };

  const createDataset = counterKey => {
    const options = datasetOptions[counterKey];
    return options.visible
      ? {
          id: counters.findIndex(counter => counter.titleKey === counterKey),
          label: translate('crossSell.' + counterKey),
          data: options.data,
          borderColor: options.color,
          hidden: !options.visible,
          lineTension: 0.3,
          pointBackgroundColor: options.color,
          pointBorderColor: options.color,
        }
      : null;
  };

  return (
    <Card style={{ height: '100%', margin: 30 }}>
      <div style={styles.container}>
        <div style={styles.datePicker}>
          <RangePicker defaultValue={defaultDates} onChange={setSelectedDates} />
        </div>
        {isLoading ? (
          <Card style={{ margin: 30 }}>
            <div style={styles.container}>
              <Spin />
            </div>
          </Card>
        ) : (
          <>
            <div style={styles.countersBox}>
              {counters.map(counter => (
                <Counter
                  key={counter.index}
                  title={translate(counter.titleKey)}
                  number={counter.number}
                  isSelected={selectedCounter === counter.index}
                  onClick={() => setSelectedCounter(counter.index)}
                />
              ))}
            </div>
            <div>
              <Line
                datasetIdKey="id"
                data={{
                  labels: labels,
                  datasets: Object.keys(datasetOptions)
                    .map(createDataset)
                    .filter(dataset => dataset !== null),
                }}
              />
            </div>
            <div style={styles.breakdownBox}>
              <Breakdown
                onViewDetailsClick={handleViewDetailsClickCrossSell}
                breakdown={crossSellListStatus}
              />
            </div>
          </>
        )}
      </div>
    </Card>
  );
}
