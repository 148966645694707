import axios from 'axios';
import apiConfig from '../api.config';
import constants from '../../constants';
import storage from '../../storage';

export default function saveBillingInformation({
  hotelId,
  companyLegalName,
  companyCommercialName,
  companyCountryCode,
  billingAddress,
  billingMunicipality,
  billingProvince,
  taxIdentificationNumber,
  billingContactName,
  billingContactEmail,
  billingPhoneNumber,
}) {
  const {
    billingHost,
    URIs: { saveBillingInformation },
  } = apiConfig;

  const uri = `${billingHost}${saveBillingInformation}`;

  const storedCredentials = JSON.parse(storage.get(constants.AUTH.USER_KEY));
  const fields = {
    hotelId: hotelId || 0,
    hotelReference: 'civitfun',
    companyLegalName,
    companyCommercialName,
    companyCountryCode,
    billingAddress,
    taxIdNumber: taxIdentificationNumber,
    billingContactName,
    billingContactEmail,
    billingPhoneNumber,
    accessToken: storedCredentials.accessToken,
  };

  if ( billingMunicipality ) {
    fields.billingMunicipality = billingMunicipality;
  }

  if ( billingProvince ) {
    fields.billingProvince = billingProvince;
  }
  
  return axios
    .put(uri, {
      data: fields,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
