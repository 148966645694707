import React, { useCallback } from 'react';
import TagCheckbox from 'Sell/Components/Upsert/Tag/Tag';

export const RoomDetails = ({ data = [], onCheck, selectedFeatures = [] }) => {
  const isSelected = useCallback(
    id => selectedFeatures.some(feature => feature.id === id),
    [selectedFeatures]
  );

  return (
    <>
      {data.map(feature => (
        <TagCheckbox
          item={feature}
          key={feature.id}
          onCheck={onCheck}
          checked={isSelected(feature.id)}
        />
      ))}
    </>
  );
};
